import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useUserService } from "../../api/ServiceContext";
import { LoadingWrapper } from "../../components/ProductionViews/LoadingWrapper";
import UserSettings from "../../components/Settings/ProfileManagement/UserSettings";
import { useDispatchAlert } from "../../components/UI/Alert";
import { SelectItem } from "../../components/UI/Form/Select";
import { UserResult } from "../../globalHooks/authorizationHooks";
import { LocalStorageLanguageKey } from "../../i18n";
import {
  GetAllLanguages,
  GetCurrentLanguage,
  LanguageMapping,
  t,
  useCurrentLanguage,
} from "../../utility/TranslateUtility";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  paper: {
    padding: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  mt4: {
    marginTop: "2rem",
  },
  mt2: {
    marginTop: "1rem",
  },
}));

function LanguageSettings() {
  let [selectedLanguage, setSelectedLanguage] = useState(GetCurrentLanguage());
  let [currentLanguage, setCurrentLanguage] = useCurrentLanguage(
    LocalStorageLanguageKey
  );
  const userService = useUserService();

  function onLanguageSelected(
    e: React.ChangeEvent<{ name?: string; value: any }>
  ) {
    setSelectedLanguage(e.target.value);

    setCurrentLanguage(e.target.value);

    userService.updateUserPreferences(e.target.value);
  }

  useEffect(() => {
    setSelectedLanguage(currentLanguage);
  }, [currentLanguage]);

  return (
    <>
      <Typography variant="h6">{t("settings.language.header")}</Typography>
      <Grid container>
        <Grid item xs={12} md={3} lg={2}>
          <p>{t("settings.language.defaultLanguage")}</p>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <FormControl>
            <InputLabel>{t("settings.language.language")}</InputLabel>
            <Select
              value={selectedLanguage}
              onChange={(e) => onLanguageSelected(e)}
            >
              {GetAllLanguages().map((lan) => (
                <SelectItem key={lan} value={lan}>
                  {LanguageMapping[lan]}
                </SelectItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={7}></Grid>
      </Grid>
    </>
  );
}

function PasswordUpdate({
  onChangePassword,
}: {
  onChangePassword: () => void;
}) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6">{t("settings.password.header")}</Typography>
      <Button
        color="primary"
        variant="contained"
        className={classes.mt2}
        onClick={onChangePassword}
      >
        {t("settings.password.update")}
      </Button>
    </>
  );
}

function SettingsPage() {
  const classes = useStyles();

  const userService = useUserService();
  const dispatchAlert = useDispatchAlert();

  const [currentUser, setCurrentUser] = useState<UserResult>();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [userUpToDate, setUserUpToDate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (!userUpToDate) {
      userService.getMyUser().then((response: IApiResponse<UserResult>) => {
        setCurrentUser(response.data);
        setUserUpToDate(true);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [userService, userUpToDate]);

  const updatePersonalInfo = (newInfo: any) => {
    setIsLoading(true);
    userService
      .updateMyUser(newInfo)
      .then(() => {
        setUserUpToDate(false);
        dispatchAlert({
          message: t("settings.user.updatesuccess"),
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: t("generic.errorMessage"),
          messageType: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChangePassword = () => {
    userService.getChangePasswordLink().then((response) => {
      window.open(response.data.changePasswordLink, "_self");
    });
  };

  return (
    <div className={classes.root}>
      <LoadingWrapper isLoading={isLoading}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <Typography variant="h4">{t("settings.header")}</Typography>
          </div>

          <div className={classes.mt4}>
            {currentUser && (
              <UserSettings
                user={currentUser}
                onUpdateInformation={updatePersonalInfo}
              />
            )}
          </div>

          <div className={classes.mt4}>
            <PasswordUpdate onChangePassword={handleClickOpen} />
          </div>

          <div className={classes.mt4}>
            <LanguageSettings />
          </div>
        </Paper>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="change-password-title"
          aria-describedby="terms-and-conditions-dialog-description"
          maxWidth="lg"
          style={{
            zIndex: 2500,
          }}
        >
          <DialogTitle id="change-password-title">
            {t("settings.password.dialogTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("settings.password.dialogContent")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              {t("settings.password.dialogBack")}
            </Button>
            <Button autoFocus onClick={handleChangePassword} color="primary">
              {t("settings.password.dialogOk")}
            </Button>
          </DialogActions>
        </Dialog>
      </LoadingWrapper>
    </div>
  );
}

export default SettingsPage;
