import { useEffect, useMemo, useState } from "react";
import { useManagementSettingsService } from "../../../api/ServiceContext";
import { MachineUnitSettings } from "../../../pages/management-settings/Common";
import { useSelectedMachine } from "../../../store/GlobalContext";
import {
  DefaultErrorHandler,
  GetCancellationToken,
} from "../../../utility/HttpServiceUtility";
import {
  areEggTypeTargetsEqual,
  areGeneralTargetsEqual,
  EggTypesWithTargets,
  EggTypeTargets,
  emptyEggTypeTargets,
  emptyTargets,
  GeneralTargets,
  TargetsView,
} from "./Types";

function cloneTargets(source: TargetsView): TargetsView {
  return {
    ...source,
    targets: {
      ...source.targets,
    },
    targetsPerEggtypes: source.targetsPerEggtypes.map((t) => ({ ...t })),
    units: {
      ...source.units,
    },
  };
}

function fillEmptyEggTypeTargets(targets: EggTypeTargets[]): EggTypeTargets[] {
  const emptyTypes = EggTypesWithTargets.filter(
    (et) => !targets.some((tpe) => tpe.eggType === et)
  );
  const empty = emptyTypes.map((et) => ({
    ...emptyEggTypeTargets,
    eggType: et,
  }));
  return [...targets].concat(empty);
}

export function useTargetSettings(): {
  generalTargets: GeneralTargets;
  setGeneralTargets: (gt: GeneralTargets) => void;
  eggTypeTargets: EggTypeTargets[];
  setEggTypeTargets: (t: EggTypeTargets) => void;
  units: MachineUnitSettings;
  setUnits: (u: MachineUnitSettings) => void;
  saveTargetSettings: () => Promise<any>;
  isDirty: boolean;
} {
  const machineId = useSelectedMachine().machineId;
  const cancellationToken = GetCancellationToken();
  const service = useManagementSettingsService(cancellationToken);

  const [targets, setTargets] = useState<TargetsView>(emptyTargets);
  const [savedTargets, setSavedTargets] = useState<TargetsView>(emptyTargets);

  useEffect(() => {
    service
      .getMachineTargetsSettings(machineId.toString(), new Date())
      .then((response: IApiResponse<TargetsView>) => {
        const newTargets = cloneTargets(response.data);
        newTargets.targetsPerEggtypes = fillEmptyEggTypeTargets(
          newTargets.targetsPerEggtypes
        );
        setTargets(newTargets);
        setSavedTargets(cloneTargets(newTargets));
      })
      .catch(DefaultErrorHandler);

    return () => {
      cancellationToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineId]);

  const saveTargetSettings = (): Promise<any> => {
    return service
      .updateMachineTargetsSettings(machineId.toString(), { ...targets })
      .then(() => setSavedTargets({ ...targets }));
  };

  const setGeneralTargets = (nv: GeneralTargets) => {
    setTargets((p) => ({
      ...p,
      targets: {
        ...p.targets,
        ...nv,
      },
    }));
  };

  const setEggTypeTargets = (t: EggTypeTargets) => {
    setTargets((p) => ({
      ...p,
      targetsPerEggtypes: [
        ...p.targetsPerEggtypes.filter((x) => x.eggType !== t.eggType),
        t,
      ],
    }));
  };

  const setUnits = (u: MachineUnitSettings) => {
    setTargets((p) => ({
      ...p,
      units: {
        ...u,
      },
    }));
  };

  const isEggTypeTargetsDirty = useMemo(() => {
    const a = targets.targetsPerEggtypes;
    const b = savedTargets.targetsPerEggtypes;

    return a.some((x) => {
      const other = b.find((xt) => xt.eggType === x.eggType);
      return !other || !areEggTypeTargetsEqual(other, x);
    });
  }, [targets.targetsPerEggtypes, savedTargets.targetsPerEggtypes]);

  const isGeneralTargetsDirty = useMemo(() => {
    return !areGeneralTargetsEqual(targets.targets, savedTargets.targets);
  }, [targets.targets, savedTargets.targets]);

  const isUnitsDirty = useMemo(() => {
    return (
      targets.units.eggUnit !== savedTargets.units.eggUnit ||
      targets.units.timezone !== savedTargets.units.timezone
    );
  }, [targets.units, savedTargets.units]);

  return {
    generalTargets: targets.targets,
    setGeneralTargets: setGeneralTargets,
    eggTypeTargets: targets.targetsPerEggtypes,
    setEggTypeTargets: setEggTypeTargets,
    units: targets.units,
    setUnits: setUnits,
    isDirty: isEggTypeTargetsDirty || isGeneralTargetsDirty || isUnitsDirty,
    saveTargetSettings: saveTargetSettings,
  };
}
