import { addHours, DatePeriod, formatWithLeadingZero } from "../../utility/DateUtility";
import { t } from "../../utility/TranslateUtility";

export type ITooltipPart = ITooltipFormatter | string;

export type ITooltipPartFormat = {
  label?: string;
  unit?: string;
  separator?: boolean;
};

export type ITooltipPartTransformationFormat = ITooltipPartFormat & {
  transformation: (value: any) => string;
};

export interface ITooltipFormatter {
  (point: any): string;
}

export type HoursAndMinutes = {
  hours: number;
  minutes: number;
};

export type DaysAndHoursAndMinutesAndSeconds = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export function getHoursAndMinutesLabel(
  hoursAndMinutes: HoursAndMinutes
): string {
  const minutesLabel = `${hoursAndMinutes.minutes} ${t(
    "performanceViews.productionTime.minutes"
  )}`;
  if (hoursAndMinutes.hours === 0) return minutesLabel;

  const hoursLabel = `${hoursAndMinutes.hours} ${t(
    "performanceViews.productionTime.hours"
  )}`;
  
  return `${hoursLabel} ${minutesLabel}`;
}

export function getDaysAndHoursAndMinutesAndSecondsLabel(
  daysAndHoursAndMinutesAndSeconds: DaysAndHoursAndMinutesAndSeconds
): string {
  const seconds = `${daysAndHoursAndMinutesAndSeconds.seconds} ${t(
    "uniqueViews.tooltipSeconds"
  )}`;
  const minutes = `${daysAndHoursAndMinutesAndSeconds.minutes} ${t(
    "uniqueViews.tooltipMinutes"
  )}`;
  const hours = `${daysAndHoursAndMinutesAndSeconds.hours} ${t(
    "uniqueViews.tooltipHours"
  )}`;
  const days = `${daysAndHoursAndMinutesAndSeconds.days} ${t(
    "uniqueViews.tooltipDays"
  )}`;

  if (daysAndHoursAndMinutesAndSeconds.days !== 0) {
    return `${days} ${hours} ${minutes} ${seconds}`
  }
  else if (daysAndHoursAndMinutesAndSeconds.hours !== 0) {
    return `${hours} ${minutes} ${seconds}`
  }
  else if (daysAndHoursAndMinutesAndSeconds.minutes !== 0) {
    return `${minutes} ${seconds}`
  }
  else {
    return `${seconds}`
  }
}

export function getHoursLabel(hoursAndMinutes: HoursAndMinutes): string {
  const hours = `${hoursAndMinutes.hours} ${t(
    "performanceViews.productionTime.hours"
  )}`;
  return `${hours}`;
}

export function getHoursAndMinutesFromHours(
  totalHours?: number
): HoursAndMinutes {
  const totalMinutes = totalHours! * 60;
  return {
    hours: Math.floor(totalMinutes / 60),
    minutes: Math.floor(totalMinutes % 60),
  };
}

export function getDaysAndHoursAndMinutesAndSecondsFromMinutes(
  totalMinutes?: number
): DaysAndHoursAndMinutesAndSeconds {
  let totalSeconds = totalMinutes! * 60;

  let days = Math.floor(totalSeconds / 86400);
  totalSeconds = totalSeconds - days * 86400;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds = totalSeconds - hours * 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = Math.floor(totalSeconds % 60);

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}

export function getHoursAndMinutesFromSeconds(
  totalSeconds?: number
): HoursAndMinutes {
  const totalMinutes = totalSeconds! / 60;
  return {
    hours: Math.floor(totalMinutes / 60),
    minutes: Math.floor(totalMinutes % 60),
  };
}

/**
 * Creates chart tooltip builder function
 */
export class TooltipBuilder {
  private formatters: Array<ITooltipPart> = [];

  add(part: ITooltipPart): TooltipBuilder {
    this.formatters.push(part);
    return this;
  }

  addXHourWithMinutes(label?: string): TooltipBuilder {
    this.formatters.push((data) => {
      return format({ label: label }, xHourWMinutes(data));
    });
    return this;
  }

  addXHourWithMinutesPeriod(label?: string): TooltipBuilder {
    this.formatters.push((data) => {
      return format({ label: label }, xHourWMinutesPeriod(data));
    });
    return this;
  }

  addXHourWithMinutesWithSecondsPeriod(label?: string): TooltipBuilder {
    this.formatters.push((data) => {
      return format({ label: label }, xHourWMinutesWSecondsPeriod(data));
    });
    return this;
  }

  addXProductionSubCharts(selectedDatePeriod: DatePeriod): TooltipBuilder {
    switch (selectedDatePeriod) {
      case DatePeriod.Day: {
        this.formatters.push((data) => {
          return format({ }, xDayWMonthWHourPeriod(data));
        });
        return this;
      }
      case DatePeriod.Year: {
        this.formatters.push((data) => {
          return format({ }, xMonthWYear(data));
        });
        return this;
      }
      default: {
        this.formatters.push((data) => {
          return format({ }, xDayWMonth(data));
        });
        return this;
      }
    }
  }

  addY(options: ITooltipPartFormat): TooltipBuilder {
    this.formatters.push((data) => {
      return format(options, data.point.y);
    });
    return this;
  }

  addYHourWithMinutes(label?: string): TooltipBuilder {
    this.formatters.push((data) => {
      return format(
        {},
        getHoursAndMinutesLabel(getHoursAndMinutesFromSeconds(data.point.y))
      );
    });
    return this;
  }

  addYWithTransfomation(
    options: ITooltipPartTransformationFormat
  ): TooltipBuilder {
    this.formatters.push((data) => {
      return format(options, options.transformation(data.point.y));
    });
    return this;
  }

  addMachineStateWithTransformation(
    options: ITooltipPartTransformationFormat
  ): TooltipBuilder {
    this.formatters.push((data) => {
      return format(options, options.transformation(data.point.machineState));
    });
    return this;
  }

  /**
   * Applies all added formatters to data point and returns the result
   * @param data
   */
  build(data: any): string {
    let tooltip = "";
    for (let part of this.formatters) {
      tooltip += typeof part === "function" ? part(data) : part;
    }
    return tooltip;
  }

  /**
   * Builds a tooltipformatter used in HighCharts.
   *
   * @returns An object containing the HighCharts formatter.
   */
  buildFormatter(): { formatter(data: any): string } {
    let currentBuilder = this;

    return {
      formatter: function (this: any) {
        return currentBuilder.build(this);
      },
    };
  }

  buildSharedFormatter(): { crosshairs: boolean, shared: boolean, formatter(data: any): string } {
    let currentBuilder = this;

    return {
      crosshairs: true,
      shared: true,
      formatter: function (this: any) {
        return currentBuilder.build(this);
      },
    };
  }
}

export function format(options: ITooltipPartFormat, value: string | number) {
  const { label, unit, separator } = options;
  const separatorPart = !!separator ? ": " : "";
  const prefix = label ? `<b>${label}${separatorPart}</b>` : "";
  const postfix = unit ? ` ${unit}` : "";

  return `${prefix}${value}${postfix}<br/>`;
}

export function xHourWMinutes(data: any): string {
  return (
    formatWithLeadingZero(new Date(data.point.x).getUTCHours()) +
    ":" +
    formatWithLeadingZero(new Date(data.point.x).getUTCMinutes())
  );
}

export function xHourWMinutesPeriod(data: any): string {
  return (
    formatWithLeadingZero(new Date(data.point.x).getUTCHours()) +
    ":" +
    formatWithLeadingZero(new Date(data.point.x).getUTCMinutes()) +
    " - " +
    formatWithLeadingZero(
      new Date(addHours(new Date(data.point.x), 1)).getUTCHours()
    ) +
    ":" +
    formatWithLeadingZero(new Date(data.point.x).getUTCMinutes())
  );
}

export function xHourWMinutesWSecondsPeriod(data: any): string {
  return (
    formatWithLeadingZero(new Date(data.point.x).getUTCHours()) +
    ":" +
    formatWithLeadingZero(new Date(data.point.x).getUTCMinutes()) +
    ":" +
    formatWithLeadingZero(new Date(data.point.x).getUTCSeconds()) +
    " - " +
    formatWithLeadingZero(new Date(data.point.x2).getUTCHours()) +
    ":" +
    formatWithLeadingZero(new Date(data.point.x2).getUTCMinutes()) +
    ":" +
    formatWithLeadingZero(new Date(data.point.x2).getUTCSeconds())
  );
}

export function xDayWMonthWHourPeriod(data: any): string {
  return (
    formatWithLeadingZero(new Date(data.x).getUTCDate()) +
    " " +
    t(`months.${new Date(data.x).getUTCMonth()}`) +
    " " +
    formatWithLeadingZero(new Date(data.x).getUTCHours()) +
    ":00-" +
    formatWithLeadingZero(new Date(data.x).getUTCHours() + 1) +
    ":00"
  );
}

export function xDayWMonth(data: any): string {
  return (
    formatWithLeadingZero(new Date(data.x).getUTCDate()) +
    " " +
    t(`months.${new Date(data.x).getUTCMonth()}`)
  );
}

export function xMonthWYear(data: any): string {
  return (
    t(`months.${new Date(data.x + 1).getUTCMonth()}`) +
    " " +
    new Date(data.x).getUTCFullYear().toString().substr(2, 4)
  );
}
