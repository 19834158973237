import { useCallback, useEffect, useState } from "react";
import packageJson from "../../../../package.json";
import { useInformationService } from "../../../api/ServiceContext";
import {
  useSelectedMachine,
  useUserLoggedIn,
} from "../../../store/GlobalContext";
import { getUtcDate } from "../../../utility/DateUtility";
import useRefresh from "../../Dashboard/useRefresh";

const version = packageJson.version;

export interface LastDataReceived {
  lastDataReceivedDate: Date;
  lastDataReceivedFound: boolean;
}

export function useInformationBarData(): {
  lastDataReceivedFound: boolean;
  lastDataReceived: Date;
  version: string;
} {
  const selectedMachine = useSelectedMachine();
  const [lastDataReceivedFound, setLastDataReceivedFound] = useState(false);
  const [lastDataReceived, setLastDataReceived] = useState(new Date());
  const informationService = useInformationService();
  const userLoggedIn = useUserLoggedIn();

  const fetchData = useCallback(() => {
    if (userLoggedIn && selectedMachine.machineId !== 0) {
      informationService
        .getLastDataReceived(selectedMachine.machineId)
        .then((response: IApiResponse<LastDataReceived>) => {
          setLastDataReceived(getUtcDate(response.data.lastDataReceivedDate));
          setLastDataReceivedFound(response.data.lastDataReceivedFound);
        });
    }
  }, [informationService, selectedMachine, userLoggedIn]);

  useRefresh(() => {
    fetchData();
  });

  useEffect(() => {
    fetchData();
  }, [selectedMachine, fetchData]);

  return {
    lastDataReceived: lastDataReceived,
    lastDataReceivedFound: lastDataReceivedFound,
    version: version,
  };
}
