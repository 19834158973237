export enum SubscriptionType {
  Unknown = 0,
  PerformancePro = 1,
  RealtimeDashboard = 2,
  Benchmark = 3,
}

export interface MachineSubscriptionApiData {
  machineSubscriptions: Array<MachineSubscription>;
}

export interface MachineSubscription {
  machineId: MachineId;
  subscriptionType: SubscriptionType;
  autoRenew: boolean;
  untilUtc: Date;
  isActive: boolean;
  pricePerDay: number;
}
