import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Widget } from "../../components/Dashboard/WidgetList";

/**
 * The Widget drawer is a permanent drawer that displays all widgets used in the
 * realtime dashboard.
 *
 * @param onItemSelect The callback that is called when the user selects a widget from the drawer.
 */
export default function WidgetDrawer({ widgetList, onItemSelect }: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editMode, setEditmode] = useState(true);

  const handleClick = (widget: any) => {
    widget.usedInDashboard = true;
    onItemSelect(widget);
  };

  const renderWidgetList = () => (
    <div role="presentation">
      <List>
        {widgetList.map((widget: Widget) => {
          return (
            <ListItem
              button
              key={widget.widgetName}
              disabled={widget.usedInDashboard}
              onClick={() => handleClick(widget)}
            >
              <ListItemAvatar>
                <img
                  src={widget.image}
                  alt="ListImage"
                  className={classes.listImage}
                />
              </ListItemAvatar>
              <ListItemText
                primary={widget.title}
                secondary={
                  <Typography variant="body2">{widget.description}</Typography>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  return (
    <Drawer
      anchor="right"
      open={editMode}
      onClose={() => setEditmode(false)}
      variant="persistent"
      classes={{
        paper: classes.toolbar,
      }}
    >
      <Typography variant="h5" className={classes.widgetListHeader}>
        {t("dashboardPage.realTimeDashboard.widgetDrawer.title")}
      </Typography>
      <Divider />
      {renderWidgetList()}
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    maxWidth: "30%",
  },
  widgetListHeader: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  listImage: {
    width: "150px",
    marginRight: theme.spacing(2),
  },
}));
