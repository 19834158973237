import { zonedTimeToUtc } from "date-fns-tz";
import {
  IAlarm,
  IAlarmCommand,
} from "../../components/Alarms/AddOrUpdateAlarm/Common";
import { AlarmsApiResponse } from "../../components/Alarms/AlarmList/AlarmListContainer";
import {
  AlarmEggsProdUnit,
  AlarmLevelUnit,
  AlarmPercentage,
  AlarmThroughputUnit,
  AlarmTimeUnit,
} from "../../components/Alarms/Types";
import { IHttpClient } from "../IHttpClient";
import { IAlarmService } from "./IAlarmService";

export class AlarmService implements IAlarmService {
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }

  getAlarms(machineId: MachineId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get(`v1/alarm/${machineId}`)
        .then((response) => {
          const mappedData = this.mapAlarmsResponse(response);
          resolve(mappedData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getMyAlarms(machineId: MachineId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get(`v1/alarm/${machineId}/my`)
        .then((response) => {
          const mappedData = this.mapAlarmsResponse(response);
          resolve(mappedData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addAlarm(alarm: IAlarm, timeZone: string): Promise<any> {
    const mappedAlarm: IAlarm = {
      ...alarm,
      levelUnit: this.mapDropdownToAlarmLevelUnit(alarm.levelUnit),
      startTimeUtc: zonedTimeToUtc(alarm.startTimeUtc, timeZone),
      endTimeUtc: zonedTimeToUtc(alarm.endTimeUtc, timeZone),
    };
    return this.post("v1/alarm", mappedAlarm);
  }

  removeAlarm(machineId: MachineId, alarmId: number): Promise<any> {
    return this.delete(`v1/alarm/${machineId}/${alarmId}`, {});
  }

  updateAlarm(
    machineId: MachineId,
    alarmId: number,
    alarmChanges: IAlarmCommand,
    timeZone: string
  ): Promise<any> {
    const mappedAlarmChanged: IAlarmCommand = {
      ...alarmChanges,
      levelUnit: this.mapDropdownToAlarmLevelUnit(alarmChanges.levelUnit),
      startTimeUtc: zonedTimeToUtc(alarmChanges.startTimeUtc, timeZone),
      endTimeUtc: zonedTimeToUtc(alarmChanges.endTimeUtc, timeZone),
    };
    return this.put(`v1/alarm/${machineId}/${alarmId}`, mappedAlarmChanged);
  }

  private get(url: string): Promise<any> {
    return this.httpclient.get(url);
  }

  private post(url: string, alarm: IAlarm): Promise<any> {
    return this.httpclient.post(url, alarm);
  }

  private delete(url: string, params?: any): Promise<any> {
    return this.httpclient.delete(url, params);
  }

  private put(url: string, alarmChanges: IAlarmCommand): Promise<any> {
    return this.httpclient.put(url, alarmChanges);
  }

  private mapAlarmsResponse(response: any): IApiResponse<AlarmsApiResponse> {
    return {
      data: {
        ...response.data,
        alarms: response.data.alarms.map((a: any) => ({
          ...a,
          levelUnit: this.mapAlarmLevelUnitToDropdown(a.levelUnit),
        })),
      },
    };
  }

  private mapAlarmLevelUnitToDropdown(levelUnit: AlarmLevelUnit) {
    switch (levelUnit) {
      case AlarmLevelUnit.Eggs:
        return AlarmEggsProdUnit.Eggs;
      case AlarmLevelUnit.Dozens:
        return AlarmEggsProdUnit.Dozens;
      case AlarmLevelUnit.Cases:
        return AlarmEggsProdUnit.Cases;

      case AlarmLevelUnit.EpH:
        return AlarmThroughputUnit.EpH;
      case AlarmLevelUnit.DpH:
        return AlarmThroughputUnit.DpH;
      case AlarmLevelUnit.CpH:
        return AlarmThroughputUnit.CpH;

      case AlarmLevelUnit.Minutes:
        return AlarmTimeUnit.Minutes;
      case AlarmLevelUnit.Hours:
        return AlarmTimeUnit.Hours;

      case AlarmLevelUnit.Percentage:
        return AlarmPercentage.Percentage;
    }
    return AlarmEggsProdUnit.Eggs;
  }

  private mapDropdownToAlarmLevelUnit(
    dropdownEnum:
      | AlarmEggsProdUnit
      | AlarmThroughputUnit
      | AlarmTimeUnit
      | AlarmPercentage
      | AlarmLevelUnit
  ) {
    switch (dropdownEnum) {
      case AlarmEggsProdUnit.Eggs:
        return AlarmLevelUnit.Eggs;
      case AlarmEggsProdUnit.Dozens:
        return AlarmLevelUnit.Dozens;
      case AlarmEggsProdUnit.Cases:
        return AlarmLevelUnit.Cases;

      case AlarmThroughputUnit.EpH:
        return AlarmLevelUnit.EpH;
      case AlarmThroughputUnit.DpH:
        return AlarmLevelUnit.DpH;
      case AlarmThroughputUnit.CpH:
        return AlarmLevelUnit.CpH;

      case AlarmTimeUnit.Minutes:
        return AlarmLevelUnit.Minutes;
      case AlarmTimeUnit.Hours:
        return AlarmLevelUnit.Hours;

      case AlarmPercentage.Percentage:
        return AlarmLevelUnit.Percentage;
    }
    return dropdownEnum;
  }
}
