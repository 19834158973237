import React from "react";
import { EventLog } from "../EventLogs/Common";
import { EventLogsContainer } from "../EventLogs/EventLogsContainer";

export function toPrettyModificationType(
  modificationType: string,
  t: any
): string {
  switch (modificationType) {
    case "AdminCreated": {
      return t("ownerPage.eventLogsModificationType.AdminCreated");
    }
    case "AdminRemoved": {
      return t("ownerPage.eventLogsModificationType.AdminRemoved");
    }
    case "MachineRenamed": {
      return t("ownerPage.eventLogsModificationType.MachineRenamed");
    }
    case "TermsAndConditionsApproved": {
      return t(
        "ownerPage.eventLogsModificationType.TermsAndConditionsApproved"
      );
    }
    default:
      return "";
  }
}

export interface EventLogsContainerProps {
  eventLogs: EventLog[];
  isLoading: boolean;
}

export function OwnerEventLogsContainer({
  eventLogs,
  isLoading,
}: EventLogsContainerProps) {
  return <EventLogsContainer eventLogs={eventLogs} isLoading={isLoading} />;
}
