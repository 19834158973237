import { makeStyles, Typography } from "@material-ui/core";
import { KpiMeasureType } from ".";
import { colors } from "../../colors";
import { IPerformanceProFilterOptions } from "../../store/GlobalContext";
import { DatePeriod } from "../../utility/DateUtility";
import { GetEggUnitTranslation } from "../../utility/EggUnitUtility";
import { FormatEggs } from "../../utility/EggUtility";
import { t } from "../../utility/TranslateUtility";
import {
  getHoursAndMinutesFromHours,
  getHoursAndMinutesLabel,
} from "../ProductionViews/Tooltip";
import { TargetsView } from "../Settings/Targets/Types";

const measuresWithTargets: KpiMeasureType[] = [
  KpiMeasureType.ProductionTime,
  KpiMeasureType.TotalEggs,
  KpiMeasureType.ProductionAvailability,
  KpiMeasureType.Throughput,
  KpiMeasureType.TableEggs,
  KpiMeasureType.InputOffgrades,
];

export function HasTargets(
  measure: KpiMeasureType,
  options: IPerformanceProFilterOptions,
  targets: TargetsView | null
): boolean {
  return (
    targets !== null &&
    (options.selectedDatePeriod === DatePeriod.Day ||
      options.selectedDatePeriod === DatePeriod.Sparkline) &&
    measuresWithTargets.includes(measure)
  );
}

export default function BarChartDisplayTarget({
  measure,
  targets,
  selectedEggType,
}: {
  measure: KpiMeasureType;
  targets: TargetsView;
  selectedEggType: number;
}) {
  const value = getTargetValue(targets, measure, selectedEggType);

  return <TargetDisplay>{value}</TargetDisplay>;
}

export function getTargetValue(
  targets: TargetsView,
  measure: KpiMeasureType,
  eggType: number
): string {
  let allEggTypeTargets = targets?.targetsPerEggtypes.find(
    (x) => x.eggType === 7
  );
  let eggTypeTargets = targets?.targetsPerEggtypes.find(
    (x) => x.eggType === eggType
  );

  switch (measure) {
    case KpiMeasureType.TotalEggs: {
      return `${FormatEggs(
        targets?.targets.totalEggs,
        targets?.targets.totalEggsEggUnit,
        false
      )} ${GetEggUnitTranslation(targets?.targets.totalEggsEggUnit)}`;
    }
    case KpiMeasureType.ProductionAvailability: {
      return `${targets?.targets.availability} %`;
    }
    case KpiMeasureType.ProductionTime: {
      return `${getHoursAndMinutesLabel(
        getHoursAndMinutesFromHours(targets?.targets.productionTime)
      )}`;
    }
    case KpiMeasureType.Throughput: {
      const perHourPostfix = "pH";
      let throughputEggUnit =
        eggTypeTargets?.throughputEggUnit === undefined
          ? allEggTypeTargets?.throughputEggUnit
          : eggTypeTargets?.throughputEggUnit;
      let throughputPostFix =
        throughputEggUnit?.toString().substring(0, 1) + perHourPostfix;

      return `${
        eggTypeTargets?.throughput === undefined
          ? allEggTypeTargets?.throughput
          : eggTypeTargets?.throughput
      } ${throughputPostFix}`;
    }
    case KpiMeasureType.TableEggs: {
      let tableEggs =
        eggTypeTargets?.tableEggs === undefined
          ? allEggTypeTargets?.tableEggs
          : eggTypeTargets?.tableEggs;
      return `${tableEggs} %`;
    }
    case KpiMeasureType.InputOffgrades: {
      let inputOffgrades =
        eggTypeTargets?.inputOffgrades === undefined
          ? allEggTypeTargets?.inputOffgrades
          : eggTypeTargets?.inputOffgrades;
      return `${inputOffgrades} %`;
    }
  }

  return "";
}

export function TargetDisplay({ children }: any): any {
  const classes = useStyles();

  return (
    <Typography variant="body2" className={classes.display}>
      {t("performanceViews.displayTargetPrefix")} {children}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  display: {
    color: "gray",
    height: "30px",
    backgroundColor: colors.whitesmoke,
    marginTop: "5px",
    marginBottom: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "auto",
    display: "inline-block",
    right: "inherit",
    borderRadius: "10px",
  },
}));
