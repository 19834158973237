import { IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import clsx from "clsx";
import React, { createRef, useCallback, useEffect } from "react";
import { useFullScreen } from "react-browser-hooks";
import { colors } from "../../../colors";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { ConditionalWrapper } from "../../../utility/ConditionalWrapper";
import SkeletonCardWrapper, { SkeletonVariant } from "./SkeletonCardWrapper";

export enum FullscreenVariant {
  Kpi,
  Graph,
}

/**
 * Generic wrapper that displays Kpi's and Graphs in Fullscreen
 *
 * @param children The component to display fullscreen
 * @param awaitingResponse Determines whether to display a loading component
 * @param onFullscreen The callback when the Fullscreen is initiated
 * @param onExitFullscreen The callback when Fullscreen is deactivated
 * @param title The card title
 * @param fullscreenVariant Determines which fullscreen variant is used
 * @param cardAction The card action buttons
 */
export default function FullscreenCardWrapper({
  children,
  awaitingResponse,
  onFullscreen,
  onExitFullscreen,
  title,
  fullscreenVariant,
  disableFullscreen,
  cardAction,
  isPerformancePro,
}: any) {
  const classes = useStyles();

  const maximizableElement: any = createRef();
  const backgroundColor = colors.white;
  const timeoutDelayInMs = 100;
  const isMobileSize = useIsMobileSize();
  const isKpiVeriant = fullscreenVariant === FullscreenVariant.Kpi;
  const skeletonVariant = isKpiVeriant
    ? SkeletonVariant.Kpi
    : SkeletonVariant.Graph;

  const { fullScreen, open, close } = useFullScreen({
    element: maximizableElement,
  });

  /**
   * The browser renders an animation when fullscreen is triggered.
   * Because of this, the element to render won't always render properly,
   * as the element is rendered while the animation is being rendered as well.
   *
   * Using a timeout delays the rendering of said element and ensures proper rendering.
   */
  const handleFullscreenClick = () => {
    open();
    if (onFullscreen) {
      setTimeout(() => onFullscreen(), timeoutDelayInMs);
    }
  };

  const handleExitFullscreenButton = () => {
    if (fullScreen && document.fullscreenElement) {
      close();
    }

    if (onExitFullscreen && fullScreen) {
      setTimeout(() => onExitFullscreen(), timeoutDelayInMs);
    }
  };

  /**
   * Only execute this method when fullscreen is actually exited. E.g.: document.fullscreenElement is false
   */
  const handleFullscreenChange = useCallback(() => {
    if (document.fullscreenElement || fullScreen) {
      handleExitFullscreenButton();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreen, document.fullscreenElement, onExitFullscreen]);

  // Listen to fullscreenchange in order to detect the deactivation of fullscreen
  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [handleFullscreenChange]);

  return (
    <div
      ref={maximizableElement}
      className={`maximizable-container ${fullScreen ? "fullscreen" : "default"
        }`}
      style={{
        backgroundColor: fullScreen ? backgroundColor : undefined,
      }}
    >
      {awaitingResponse === true ? (
        <SkeletonCardWrapper variant={skeletonVariant} />
      ) : (
        <ConditionalWrapper
          condition={fullScreen && isKpiVeriant}
          wrapper={(wrappedChildren: any) => (
            <div className={classes.centered}>{wrappedChildren}</div>
          )}
        >
          <FullScreenCard
            title={title}
            isPerformancePro={isPerformancePro}
            isFullScreen={fullScreen}
            cardAction={cardAction}
            disableChange={isMobileSize || isKpiVeriant || disableFullscreen}
            onExitFullscreenClick={handleFullscreenChange}
            onFullscreenClick={handleFullscreenClick}
          >
            {children}
          </FullScreenCard>
        </ConditionalWrapper>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingTop: 0,
  },
  cardHeader: {
    paddingBottom: 0,
  },
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fullscreenCard: {
    height: "100%",
    width: "100%",
  },
  regularCard: {
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  performanceProCard: {
    height: "50%",
    width: "100%",
    position: "absolute",
  },
}));

interface FullScreenCardProps {
  children: any;
  title: string | React.ReactNode;
  isFullScreen: boolean;
  isPerformancePro: boolean;
  cardAction: React.ReactNode;
  disableChange?: boolean;
  onExitFullscreenClick: () => void;
  onFullscreenClick: () => void;
}

export function FullScreenCard({
  children,
  title,
  isFullScreen,
  isPerformancePro,
  cardAction,
  disableChange,
  onExitFullscreenClick,
  onFullscreenClick,
}: FullScreenCardProps) {
  const classes = useStyles();

  const fullscreenChangeButton = isFullScreen ? (
    <IconButton onClick={() => onExitFullscreenClick()}>
      <FullscreenExitIcon />
    </IconButton>
  ) : (
    <IconButton onClick={() => onFullscreenClick()}>
      <FullscreenIcon />
    </IconButton>
  );

  const actionNode = (
    <>
      {cardAction}
      {!disableChange && fullscreenChangeButton}
    </>
  );

  return (
    <Card
      className={clsx({
        [classes.fullscreenCard]: isFullScreen,
        [classes.regularCard]: !isFullScreen,
        [classes.performanceProCard]: isPerformancePro
      })}
    >
      <CardHeader
        action={actionNode}
        title={title}
        titleTypographyProps={{
          color: "textSecondary",
          gutterBottom: true,
          variant: "body1",
        }}
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        <div className="maximizable-content">{children}</div>
      </CardContent>
    </Card>
  );
}
