import { useMemo } from "react";
import SparklineWidget from "../../components/Charts/SparklineWidget";
import { getLastElement } from "../../utility/ArrayUtility";
import { EggUnit, GetEggUnitTranslation } from "../../utility/EggUnitUtility";
import {
  createUnitsConverter,
  EggType,
  FormatEggs,
} from "../../utility/EggUtility";
import { FormatWithTwoDecimals } from "../../utility/NumberFormatUtility";
import { t } from "../../utility/TranslateUtility";
import {
  getHoursAndMinutesFromHours,
  getHoursAndMinutesFromSeconds,
  getHoursAndMinutesLabel,
  TooltipBuilder,
} from "../ProductionViews/Tooltip";
import { TargetsView } from "../Settings/Targets/Types";
import { ChartMapping } from "./ChartMapping";
import { PerformancePeriod } from "./Types";

export default function PeriodViewSparklineChart({
  periods,
  chartMapping,
  awaitingResponse,
  targets,
  selectedEggType,
}: {
  periods: Array<PerformancePeriod>;
  chartMapping: ChartMapping;
  awaitingResponse: boolean;
  targets: TargetsView | null;
  selectedEggType: number;
}) {
  const { lastValue, newPeriods, displayValue, throughputPostFix, eggUnit } =
    useMemo(() => {
      let memoNewPeriods = periods;

      let lastElement = getLastElement(periods);
      let memoLastValue = lastElement ? lastElement.value : 0;
      let lastNumerator = lastElement ? lastElement.numerator : 0;

      let sparklineName = chartMapping.headerTitle.substring(
        chartMapping.headerTitle.indexOf(".") + 1,
        chartMapping.headerTitle.lastIndexOf(".")
      );

      let memoDisplayValue;
      let allEggTypeTargets = targets?.targetsPerEggtypes.find(
        (x) => x.eggType === Number(EggType.AllEggTypes)
      );
      let eggTypeTargets = targets?.targetsPerEggtypes.find(
        (x) => x.eggType === selectedEggType
      );

      const eggUnitToReturn = targets?.units.eggUnit ?? null;

      let memoThroughputPostFix =
        eggUnitToReturn?.toString().substring(0, 1) + "pH";

      switch (sparklineName) {
        case "totalEggs": {
          memoDisplayValue = `${t("performanceViews.displayTargetPrefix")} ${
            targets?.targets.totalEggs
          } ${targets?.targets.totalEggsEggUnit}`;

          memoNewPeriods = new Array<PerformancePeriod>();
          const inUnits = createUnitsConverter(targets?.units.eggUnit ?? null);
          periods.forEach(function (_, index, array) {
            addNewValue(memoNewPeriods, array, index, inUnits);
          });
          lastElement = getLastElement(memoNewPeriods);
          memoLastValue = lastElement ? lastElement.value : 0;

          break;
        }
        case "productionAvailability": {
          memoDisplayValue = `${t("performanceViews.displayTargetPrefix")} ${
            targets?.targets.availability
          } %`;
          break;
        }
        case "productionTime": {
          memoDisplayValue = `${t("performanceViews.displayTargetPrefix")} 
          ${getHoursAndMinutesLabel(
            getHoursAndMinutesFromHours(targets?.targets.productionTime)
          )}`;
          break;
        }
        case "throughput": {
          memoDisplayValue = `${t("performanceViews.displayTargetPrefix")} ${
            eggTypeTargets?.throughput === undefined
              ? allEggTypeTargets?.throughput
              : eggTypeTargets?.throughput
          } ${memoThroughputPostFix}`;

          memoNewPeriods = new Array<PerformancePeriod>();

          const inUnits = createUnitsConverter(targets?.units.eggUnit ?? null);
          periods.forEach(function (_, index, array) {
            addNewValue(memoNewPeriods, array, index, inUnits);
          });

          lastElement = getLastElement(memoNewPeriods);
          memoLastValue = lastElement ? lastElement.value : 0;

          break;
        }
        case "fillRateProduction": {
          memoDisplayValue = "";
          break;
        }
        case "tableEggs": {
          memoDisplayValue = `${t("performanceViews.displayTargetPrefix")} ${
            eggTypeTargets?.tableEggs === undefined
              ? allEggTypeTargets?.tableEggs
              : eggTypeTargets?.tableEggs
          } %`;
          break;
        }
        case "inputOffgrade": {
          memoDisplayValue = `${t("performanceViews.displayTargetPrefix")} ${
            eggTypeTargets?.inputOffgrades === undefined
              ? allEggTypeTargets?.inputOffgrades
              : eggTypeTargets?.inputOffgrades
          } %`;
          break;
        }
        case "upgrade":
        case "outputOffgrade":
        case "bypass":
        case "bucketEggs": {
          lastNumerator = lastElement
            ? FormatEggs(
                lastElement.numerator,
                targets?.targets.totalEggsEggUnit ?? null
              )
            : 0;

          memoDisplayValue = `${lastNumerator} ${targets?.targets.totalEggsEggUnit}`;

          break;
        }
        default: {
          memoDisplayValue = "";
          break;
        }
      }
      return {
        lastValue: memoLastValue,
        newPeriods: memoNewPeriods,
        displayValue: memoDisplayValue,
        throughputPostFix: memoThroughputPostFix,
        eggUnit: eggUnitToReturn,
      };
    }, [chartMapping.headerTitle, periods, selectedEggType, targets]);

  const percentageTooltipFormatter = new TooltipBuilder()
    .addYWithTransfomation({
      transformation: (value: number) => FormatWithTwoDecimals(value),
    })
    .buildFormatter();

  const timeTooltipFormatter = new TooltipBuilder()
    .addYHourWithMinutes(t(chartMapping.tooltipYLabel))
    .buildFormatter();

  const countTooltipFormatter = new TooltipBuilder()
    .addYWithTransfomation({
      transformation: (value: number) => FormatEggs(value, eggUnit, false),
    })
    .buildFormatter();

  return (
    <PeriodViewSparklineChartPresentation
      valueType={chartMapping.valueType}
      titleTranslationLabel={chartMapping.headerTitle}
      lastValue={lastValue}
      newPeriods={newPeriods}
      displayValue={displayValue}
      awaitingResponse={awaitingResponse}
      percentageTooltipFormatter={percentageTooltipFormatter}
      timeTooltipFormatter={timeTooltipFormatter}
      countTooltipFormatter={countTooltipFormatter}
      eggUnit={eggUnit}
      throughputPostFix={throughputPostFix}
    />
  );
}

function PeriodViewSparklineChartPresentation({
  valueType,
  titleTranslationLabel,
  lastValue,
  newPeriods,
  displayValue,
  awaitingResponse,
  percentageTooltipFormatter,
  timeTooltipFormatter,
  countTooltipFormatter,
  eggUnit,
  throughputPostFix,
}: {
  valueType: string;
  titleTranslationLabel: string;
  lastValue: any;
  newPeriods: PerformancePeriod[];
  displayValue: string;
  awaitingResponse: boolean;
  percentageTooltipFormatter: { formatter(data: any): string };
  timeTooltipFormatter: { formatter(data: any): string };
  countTooltipFormatter: { formatter(data: any): string };
  eggUnit: EggUnit | null;
  throughputPostFix: string;
}) {
  if (valueType === "Percentage") {
    return (
      <SparklineWidget
        title={t(titleTranslationLabel)}
        measure={`${FormatWithTwoDecimals(lastValue)} %`}
        data={newPeriods}
        tooltip={percentageTooltipFormatter}
        yAxisKey={"value"}
        awaitingResponse={awaitingResponse}
        display={displayValue}
      />
    );
  }

  if (valueType === "Time") {
    return (
      <SparklineWidget
        title={t(titleTranslationLabel)}
        measure={`${getHoursAndMinutesLabel(
          getHoursAndMinutesFromSeconds(lastValue)
        )}`}
        data={newPeriods}
        tooltip={timeTooltipFormatter}
        yAxisKey={"value"}
        awaitingResponse={awaitingResponse}
        display={displayValue}
      />
    );
  }

  if (valueType === "Count") {
    return (
      <SparklineWidget
        title={t(titleTranslationLabel)}
        measure={`${FormatEggs(lastValue, eggUnit, false)} ${t(
          GetEggUnitTranslation(eggUnit)
        )}`}
        data={newPeriods}
        tooltip={countTooltipFormatter}
        yAxisKey={"value"}
        awaitingResponse={awaitingResponse}
        display={displayValue}
      />
    );
  }

  return (
    <SparklineWidget
      title={t(titleTranslationLabel)}
      measure={`${FormatEggs(lastValue, eggUnit, false)} ${throughputPostFix}`}
      data={newPeriods}
      tooltip={countTooltipFormatter}
      yAxisKey={"value"}
      awaitingResponse={awaitingResponse}
      display={displayValue}
    />
  );
}

function addNewValue(
  memoNewPeriods: PerformancePeriod[],
  array: PerformancePeriod[],
  index: number,
  inUnits: (value: number) => number
) {
  let newValue = inUnits(array[index].value);
  memoNewPeriods.push({
    dateTime: array[index].dateTime,
    eggType: array[index].eggType,
    value: newValue,
  });
}
