import { Button, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useMemo } from "react";
import {
  useBenchmarkActions,
  useBenchmarkState
} from "../../../../store/GlobalContext";
import { t } from "../../../../utility/TranslateUtility";
import SelectDisplayedWidgetsDialog from "../../SelectDisplayedWidgetsDialog";
import {
  useBenchmarkModuleLoading,
  useComparedMachineCount,
  useMachineMeasureComparisonAppliedFilters
} from "../machineMeasureComparisonHooks";
import ComparedMachineCountSection from "./ComparedMachineCount/ComparedMachineCountSection";
import MachineMeasureComparisonFilters from "./MachineMeasureComparisonFilters";

const useStyles = makeStyles((theme) => ({
  filtersTitle: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    flex: 1,
  },
  titleSection: {
    display: "flex",
    flexDirection: "row",
  },
  spacer: {
    flex: 1,
  },
  comparedMachineCount: {
    alignSelf: "center",
  },
  buttonSection: {
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    margin: theme.spacing(3),
    marginLeft: 0,
    marginRight: 0,
  },
  applyFilterButton: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      bottom: "64px",
      marginLeft: -theme.spacing(2),
      borderRadius: 0,
      width: "100%",
    },
  },
}));

export default function MachineMeasureComparisonFiltersContainer() {
  const {
    comparedToCount,
    minimumComparedToCount,
  } = useMachineMeasureComparisonAppliedFilters();
  const { applyBenchmarkFilterState } = useBenchmarkActions();
  const { editingFilterState } = useBenchmarkState();
  const awaitingResponse = useComparedMachineCount();

  const handleApply = () => {
    applyBenchmarkFilterState(editingFilterState);
  };

  const machineCountToLow = useMemo(() => {
    return comparedToCount! < minimumComparedToCount!;
  }, [comparedToCount]);

  return (
    <MachineMeasureComparisonFiltersPresentation
      comparedToCount={comparedToCount}
      machineCountToLow={machineCountToLow}
      onApply={handleApply}
      awaitingResponse={awaitingResponse}
    />
  );
}

interface MachineMeasureComparisonFiltersProps {
  comparedToCount: number | null;
  machineCountToLow: boolean;
  awaitingResponse: boolean;
  onApply: () => void;
}

function MachineMeasureComparisonFiltersPresentation({
  comparedToCount,
  machineCountToLow,
  awaitingResponse,
  onApply,
}: MachineMeasureComparisonFiltersProps) {
  const classes = useStyles();

  return (
    <>
      <MachineMeasureComparisonHeader
        comparedToCount={comparedToCount}
        machineCountToLow={machineCountToLow}
        awaitingResponse={awaitingResponse}
        onApply={onApply}
      />
      <Divider className={classes.divider} />
      <MachineMeasureComparisonFilters />
      <Divider className={classes.divider} />
      <ApplyButton onApply={onApply} machineCountToLow={machineCountToLow} />
    </>
  );
}

function MachineMeasureComparisonHeader({
  comparedToCount,
  machineCountToLow,
  awaitingResponse,
}: MachineMeasureComparisonFiltersProps) {
  const classes = useStyles();

  return (
    <>
      <b className={classes.titleSection}>
        <Typography className={classes.filtersTitle} variant="h5">
          {t("benchmark.filters.title")}
        </Typography>
        <div className={classes.comparedMachineCount}>
          <ComparedMachineCountSection
            comparedToCount={comparedToCount}
            comparedCountToLow={machineCountToLow}
            awaitingResponse={awaitingResponse}
          />
        </div>
      </b>
      <div className={classes.buttonSection}>
        <SelectDisplayedWidgetsDialog />
      </div>
    </>
  );
}

function ApplyButton({
  onApply,
  machineCountToLow,
}: {
  onApply: () => void;
  machineCountToLow: boolean;
}) {
  const { isDisabled } = useBenchmarkModuleLoading();
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onApply}
      disabled={isDisabled || machineCountToLow}
      fullWidth
      size="large"
      className={classes.applyFilterButton}
    >
      {t("benchmark.applyFilter")}
    </Button>
  );
}
