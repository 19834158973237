import { getZonedTime } from "timezone-support";

export enum ModificationType {
  AdminCreated,
  AdminRemoved,
  MachineRenamed,
  TermsAndConditionsApproved,
  UserAssignedToMachine,
  UserRemovedFromMachine,
  ModuleAssignedToUser,
  ModuleRevokedFromUser,
  AutoRenewEnabled,
  AutoRenewDisabled,
}

export interface EventLogsApiResponse {
  eventLogs: ApiEventLog[];
}

export interface ApiEventLog {
  machineId: number;
  eventType: string;
  modificationType: ModificationType;
  date: Date;
  responsible: string;
  info: string;
}

export interface EventLog {
  machineId: number;
  eventType: string;
  modificationType: string;
  date: Date;
  responsible: string;
  info: string;
}

export function searchEventLogs(
  eventLogs: EventLog[],
  searchTerm: string,
  timeZone: any
) {
  return eventLogs.length === 0 || timeZone === null
    ? eventLogs
    : eventLogs.filter((eventLog) => {
        let searchDate = dateToTimezoneString(eventLog.date, timeZone);
        return (
          searchDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
          eventLog.modificationType
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          eventLog.responsible
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          eventLog.info
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        );
      });
}

export function dateToTimezoneString(time: any, timeZone: any): string {
  if (timeZone === null) return "";
  const asDate: Date = new Date(time);
  const asDateZoned = getZonedTime(asDate, timeZone);
  const year = asDateZoned.year.toString().padStart(4, "0");
  const month = asDateZoned.month.toString().padStart(2, "0");
  const day = asDateZoned.day.toString().padStart(2, "0");

  const hours = asDateZoned.hours.toString().padStart(2, "0");
  const mins = asDateZoned.minutes.toString().padStart(2, "0");
  const seconds = asDateZoned.minutes.toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${mins}:${seconds}`;
}
