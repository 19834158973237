import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReportService } from "../../api/ServiceContext";
import RealtimeDashboard from "../../components/Dashboard/RealtimeDashboard";
import { useSelectedMachine } from "../../store/GlobalContext";

export type DashboardLayoutResponse = {
  data: DashboardLayoutData;
};

export type DashboardLayoutData = {
  dashboardWidgets: Array<DashboardWidget>;
};

export type DashboardWidget = {};

function DashboardPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [dashboardWidgets, setDashboardWidgets] = useState<
    Array<DashboardWidget>
  >([]);
  const reportService = useReportService();
  const { user } = useAuth0();
  const selectedMachine = useSelectedMachine();

  useEffect(() => {
    setAwaitingResponse(true);
    reportService.getDashboard(selectedMachine.machineId, user.email).then((response: DashboardLayoutResponse) => {
      const newDashboardWidgets = response.data.dashboardWidgets;

      setDashboardWidgets(newDashboardWidgets);
      setAwaitingResponse(false);
    });
  }, [reportService, selectedMachine.machineId, user.email]);

  return (
    <>
      {awaitingResponse ? (
        <div className={classes.centered}>
          <div>
            <div className={classes.centeredSpinner}>
              <CircularProgress />
            </div>
            <div>
              <Typography variant="h5" className={classes.loadingText}>
                {t("dashboardPage.loadingMessage")}
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <RealtimeDashboard dashboardWidgets={dashboardWidgets} />
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    height: "100%",
    width: "100%",
  },
  loadingText: {
    marginTop: theme.spacing(4),
  },
  centeredSpinner: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
}));

export default DashboardPage;
