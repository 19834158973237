import { IHttpClient } from "../IHttpClient";
import { IInformationService } from "./IInformationSerice";

export class InformationService implements IInformationService {
    private httpclient: IHttpClient;
  
    constructor(httpclient: IHttpClient) {
      this.httpclient = httpclient;
    }
    
    getLastDataReceived(machineId: MachineId): Promise<any> {
      return this.get(`v1/information/last-change/${machineId}`);
    }
  
    private get(url: string): Promise<any> {
      return this.httpclient.get(url);
    }
  }