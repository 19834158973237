import HighchartsReact from "highcharts-react-official";
import { createRef } from "react";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import FullscreenCardWrapper, {
  FullscreenVariant
} from "./FullscreenCardWrapper";

export enum RenderPosition {
  Top,
  Bottom,
}

export enum ScreenState {
  Default,
  Fullscreen
}

/**
 * A wrapper for highcharts components that implements fullscreen rendering.
 * Also offers the possibility to render custom JSX in this component.
 *
 * @param title The title of the card
 * @param awaitingResponse Determines whether to display a loading component
 * @param highcharts The highcharts chart
 * @param chartOptions The options for the highcharts chart
 * @param customFullscreenHeight Allows for custom fullscreen height, measured using CSS-statements (%, vh, px)
 * @param customJsx Allows for custom JSX to be rendered next to the chart
 * @param renderPosition Determines where to render the customJSX
 * @param onFullscreenChange is called when fullscreen state changes. 
 */
export default function HighchartsFullscreenWrapper({
  title,
  awaitingResponse,
  highcharts,
  chartOptions,
  customFullscreenHeight,
  customJsx,
  renderPosition,
  cardAction,
  onFullscreenChange
}: any) {
  const chartComponent: any = createRef();

  const defaultViewHeight = "95vh";
  const defaultViewWidth = "100%";
  const defaultNormalViewHeight = "400px";
  const defaultMobileViewHeight = "240px";
  const isMobileSize = useIsMobileSize();

  const chartHeight = isMobileSize ? defaultMobileViewHeight : defaultNormalViewHeight;

  chartOptions = {
    ...chartOptions,
    chart: {
      height: chartHeight,
      ...chartOptions?.chart
    }
  };

  const handleOnFullscreen = () => {
    if (!chartComponent.current) return;

    const container = chartComponent.current.container.current;

    container.style.height = customFullscreenHeight
      ? customFullscreenHeight
      : defaultViewHeight;
    container.style.width = defaultViewWidth;

    if (onFullscreenChange) {
      onFullscreenChange(ScreenState.Fullscreen);
    }

    chartComponent.current.chart.reflow();
  };

  const handleOnExitFullscreen = () => {
    if (!chartComponent.current) return;

    const container = chartComponent.current.container.current;

    container.style.height = chartHeight;

    chartComponent.current.chart.reflow();

    if (onFullscreenChange) {
      onFullscreenChange(ScreenState.Default);
    }
  };

  return (
    <FullscreenCardWrapper
      title={title}
      awaitingResponse={awaitingResponse}
      fullscreenVariant={FullscreenVariant.Graph}
      onFullscreen={() => handleOnFullscreen()}
      onExitFullscreen={() => handleOnExitFullscreen()}
      cardAction={cardAction}
    >
      {renderPosition === RenderPosition.Top ? customJsx : <></>}
      <HighchartsReact
        ref={chartComponent}
        highcharts={highcharts}
        options={chartOptions}
      />
      {renderPosition === RenderPosition.Bottom ? customJsx : <></>}
    </FullscreenCardWrapper>
  );
}
