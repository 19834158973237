import { useEffect, useMemo, useState } from "react";
import { useManagementSettingsService } from "../../../api/ServiceContext";
import { colors } from "../../../colors";
import {
  CharacteristicsInfoLabel,
  ColorSchema,
  ColorSchemaRowType,
  DowntimeInfoLabel,
  ReportSettings,
  ReportSettingsApiData,
} from "../../../pages/management-settings/Common";
import { useSelectedMachine } from "../../../store/GlobalContext";
import { getUtcDate } from "../../../utility/DateUtility";
import { EggUnit } from "../../../utility/EggUnitUtility";

const defaultSettings: ReportSettings = {
  timeSlots: {
    startTimeSlot: new Date(2020, 1, 1, 0, 0, 0),
    endTimeSlot: new Date(2020, 1, 1, 0, 0, 0),
  },
  colorSchemas: [],
  hourlyTarget: {
    target: 10,
    eggUnit: EggUnit.Eggs,
  },
  enableTeamSizeDataEntry: false,
  downtimeInfoLabels: [],
  characteristicsInfoLabels: [],
};

function GetDefaultColorSchema(): ColorSchemaRowType {
  return {
    schemaNumber: 0,
    identifier: 0,
    targetValue: 0,
    colorHex: colors.mobaBlue,
  };
}

export function toReportSettings(
  source: ReportSettingsApiData
): ReportSettings {
  let newColors = source.colorSchemas.map<ColorSchemaRowType>((c, i) => {
    return {
      colorHex: c.colorHex,
      schemaNumber: c.schemaNumber,
      targetValue: c.targetValue,
      identifier: i + 1,
    };
  });
  if (!newColors.find((c) => c.schemaNumber === 0)) {
    newColors.push(GetDefaultColorSchema());
  }

  return {
    timeSlots: {
      startTimeSlot: getUtcDate(source.timeSlots.startTimeSlot),
      endTimeSlot: getUtcDate(source.timeSlots.endTimeSlot),
    },
    hourlyTarget: { ...source.hourlyTarget },
    colorSchemas: [...newColors],
    enableTeamSizeDataEntry: source.enableTeamSizeDataEntry,
    downtimeInfoLabels: [...source.downtimeInfoLabels],
    characteristicsInfoLabels: [...source.characteristicsInfoLabels],
  };
}

export function toReportSettingsApiData(
  source: ReportSettings
): ReportSettingsApiData {
  return {
    timeSlots: { ...source.timeSlots },
    hourlyTarget: { ...source.hourlyTarget },
    characteristicsInfoLabels: [...source.characteristicsInfoLabels],
    colorSchemas: source.colorSchemas.map<ColorSchema>((c) => {
      return {
        colorHex: c.colorHex,
        schemaNumber: c.schemaNumber,
        targetValue: c.targetValue,
      };
    }),
    enableTeamSizeDataEntry: source.enableTeamSizeDataEntry,
    downtimeInfoLabels: [...source.downtimeInfoLabels],
  };
}

function checkForPendingColorschemaChanges(
  originalColorSchemas: Array<ColorSchema>,
  newColorSchemas: Array<ColorSchema>
) {
  if (originalColorSchemas.length !== newColorSchemas.length) return true;
  var originalCopy = [...originalColorSchemas];
  var newColorSchemasCopy = [...newColorSchemas];

  originalCopy.sort((a, b) => b.targetValue - a.targetValue);
  newColorSchemasCopy.sort((a, b) => b.targetValue - a.targetValue);

  for (let i = 0; i < originalCopy.length; i++) {
    if (
      originalCopy[i].targetValue !== newColorSchemasCopy[i].targetValue ||
      originalCopy[i].colorHex !== newColorSchemasCopy[i].colorHex
    )
      return true;
  }

  return false;
}

function checkForPendingDowntimeInfoLabelsChanges(
  originalDowntimeInfoLabel: Array<DowntimeInfoLabel>,
  newDowntimeInfoLabel: Array<DowntimeInfoLabel>
) {
  if (originalDowntimeInfoLabel.length !== newDowntimeInfoLabel.length)
    return true;
  var originalCopy = [...originalDowntimeInfoLabel];
  var newDowntimeInfoLabelCopy = [...newDowntimeInfoLabel];

  originalCopy.sort((a, b) => b.labelNumber - a.labelNumber);
  newDowntimeInfoLabelCopy.sort((a, b) => b.labelNumber - a.labelNumber);

  for (let i = 0; i < originalCopy.length; i++) {
    if (
      originalCopy[i].labelNumber !== newDowntimeInfoLabelCopy[i].labelNumber ||
      originalCopy[i].name !== newDowntimeInfoLabelCopy[i].name
    )
      return true;
  }
  return false;
}

function checkForPendingCharacteristicsInfoLabelsChanges(
  originalCharacteristicsInfoLabels: Array<CharacteristicsInfoLabel>,
  newCharacteristicsInfoLabels: Array<CharacteristicsInfoLabel>
) {
  if (
    originalCharacteristicsInfoLabels.length !==
    newCharacteristicsInfoLabels.length
  )
    return true;
  var originalCopy = [...originalCharacteristicsInfoLabels];
  var newCharacteristicsInfoLabelsCopy = [...newCharacteristicsInfoLabels];

  originalCopy.sort((a, b) => b.labelNumber - a.labelNumber);
  newCharacteristicsInfoLabelsCopy.sort(
    (a, b) => b.labelNumber - a.labelNumber
  );

  for (let i = 0; i < originalCopy.length; i++) {
    if (originalCopy[i].label !== newCharacteristicsInfoLabelsCopy[i].label)
      return true;
  }

  return false;
}

export function useReportSettingsManagement(): {
  reportSettings: ReportSettings;
  setReportSetting: (setting: ReportSettings) => void;
  saveReportSetting: () => Promise<void>;
  isDirty: boolean;
  hasTimeSlotError: boolean;
  hasTargetError: boolean;
  hasDuplicateColorSchemaError: boolean;
} {
  const machineId = useSelectedMachine().machineId;
  const service = useManagementSettingsService();

  const [savedSettings, setSavedSettings] =
    useState<ReportSettings>(defaultSettings);
  const [settings, setSettings] = useState<ReportSettings>(defaultSettings);

  const isDirty = useMemo(() => {
    return (
      settings.timeSlots.startTimeSlot.getTime() !==
        savedSettings.timeSlots.startTimeSlot.getTime() ||
      settings.timeSlots.endTimeSlot.getTime() !==
        savedSettings.timeSlots.endTimeSlot.getTime() ||
      checkForPendingColorschemaChanges(
        settings.colorSchemas,
        savedSettings.colorSchemas
      ) ||
      checkForPendingCharacteristicsInfoLabelsChanges(
        settings.characteristicsInfoLabels,
        savedSettings.characteristicsInfoLabels
      ) ||
      settings.hourlyTarget.target !== savedSettings.hourlyTarget.target ||
      settings.hourlyTarget.eggUnit !== savedSettings.hourlyTarget.eggUnit ||
      settings.enableTeamSizeDataEntry !==
        savedSettings.enableTeamSizeDataEntry ||
      settings.hourlyTarget.eggUnit !== savedSettings.hourlyTarget.eggUnit ||
      checkForPendingDowntimeInfoLabelsChanges(
        savedSettings.downtimeInfoLabels,
        settings.downtimeInfoLabels
      )
    );
  }, [settings, savedSettings]);

  const hasTimeSlotError = useMemo(() => {
    return settings.timeSlots.startTimeSlot > settings.timeSlots.endTimeSlot;
  }, [settings]);

  const hasColorSchemaError = useMemo(() => {
    let seen = new Set();
    return settings.colorSchemas.some(function (currentColorSchema) {
      return (
        seen.size === seen.add(currentColorSchema.colorHex.toLowerCase()).size
      );
    });
  }, [settings]);

  const hasTargetError = useMemo(() => {
    return isNaN(settings.hourlyTarget.target);
  }, [settings]);

  useEffect(() => {
    service
      .getReportSettings(machineId.toString(), new Date())
      .then((response: IApiResponse<ReportSettingsApiData>) => {
        setSavedSettings(toReportSettings(response.data));
        setSettings(toReportSettings(response.data));
      });
  }, [service, machineId]);

  const saveQualityChartSetting = async () => {
    await service.updateReportSettings(
      machineId.toString(),
      toReportSettingsApiData(settings)
    );
    return setSavedSettings({ ...settings });
  };

  return {
    reportSettings: settings,
    setReportSetting: setSettings,
    saveReportSetting: saveQualityChartSetting,
    isDirty: isDirty,
    hasTimeSlotError: hasTimeSlotError,
    hasTargetError: hasTargetError,
    hasDuplicateColorSchemaError: hasColorSchemaError,
  };
}
