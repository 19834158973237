import { useCallback, useEffect, useState } from "react";
import { useManagementSettingsService, useReportService } from "../../../api/ServiceContext";
import { MachineUnitSettings } from "../../../pages/management-settings/Common";
import { EggUnit } from "../../../utility/EggUnitUtility";
import useRefresh from "../../Dashboard/useRefresh";
import {
  EggProductionApiData,
  EggProductionApiResponse, EggProductionWidgetPresentation
} from "./EggProduction";

export type EggProductionWidgetContainerProps = {
  machineId: number;
  automaticRefresh: boolean;
};

/**
 * Shows the number of produced eggs for the last 30 minutes.
 * Also displays the egg production speed.
 */
export default function EggProductionWidgetContainer({
  machineId,
  automaticRefresh,
}: EggProductionWidgetContainerProps) {
  const reportService = useReportService();
  const settingsService = useManagementSettingsService();
  
  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [data, setData] = useState<EggProductionApiData | any>(null);
  const [units, setUnits] = useState<EggUnit>(EggUnit.Eggs);

  const fetchData = useCallback(
    (newMachineId: number) => {
      reportService
        .getEggProduction(newMachineId)
        .then((response: EggProductionApiResponse) => {
          setAwaitingResponse(false);

          if (
            response.data.eggProductionEggTypePeriods.length <= 0 &&
            response.data.eggProductionSpeedPeriods.length <= 0
          )
            return;

          setData(response.data);
        });
      settingsService
        .getUnitsFromMachineTargetsSettings(newMachineId, new Date())
        .then((response: IApiResponse<MachineUnitSettings>) => {
          setUnits(response.data.eggUnit)
        })
    },
    [reportService, settingsService]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return (
    <EggProductionWidgetPresentation
      awaitingResponse={awaitingResponse}
      data={data}
      units={units}
    />
  );
}
