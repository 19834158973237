import { UpdateDashboardCommand } from "../../components/Dashboard/RealtimeDashboard";
import { IHttpClient } from "../IHttpClient";
import { IReportService } from "./IReportService";

const baseUrl = "v1/RealtimeDashboard";

export class ReportService implements IReportService {
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }

  get(url: string): Promise<any> {
    return this.httpclient.get(url);
  }

  put(url: string, body: any): Promise<any> {
    return this.httpclient.put(url, body);
  }

  getFillRate(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/fill-rate/${machineId}`);
  }

  getOffgrades(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/offgrades/${machineId}`);
  }

  getMachineStates(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/machine-states/${machineId}`);
  }

  getActualSpeed(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/actual-speed/${machineId}`);
  }

  getMachineSpeedHour(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/machine-speed-hour/${machineId}`);
  }

  getMachineSpeedMinute(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/machine-speed-minute/${machineId}`);
  }

  getMachineRunning(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/machine-running/${machineId}`);
  }

  getEggTypes(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/egg-type/${machineId}`);
  }

  getEggProduction(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/egg-production/${machineId}`);
  }

  getTotalEggs(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/total-eggs/${machineId}`);
  }

  getSupplierShed(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/supplier-shed/${machineId}`);
  }

  getLaneProducts(machineId: MachineId): Promise<any> {
    return this.get(`${baseUrl}/lane-products/${machineId}`);
  }

  getDashboard(machineId: MachineId, email: string): Promise<any> {
    return this.get(`${baseUrl}/dashboard/machines/${machineId}/users`);
  }

  putDashboard(command: UpdateDashboardCommand): Promise<any> {
    return this.put(`${baseUrl}/dashboard`, command);
  }
}
