import React, { ChangeEvent, useEffect, useState } from "react";
import {
  useBenchmarkActions,
  useBenchmarkState,
} from "../../../../../../store/GlobalContext";
import { ChangesPerDay } from "../ChangesPerDay";
import { minGreaterThanMaxError, setNewValue } from "../ChangesPerDayFunctions";

export default function SupplyChangesPerDayContainer() {
  const benchmarkState = useBenchmarkState();
  const { updateFilterSupplyChanges } = useBenchmarkActions();

  const [min, setMin] = useState<number | null>(null);
  const [max, setMax] = useState<number | null>(null);

  useEffect(() => {
    setMin(benchmarkState.editingFilterState.supplyChanges.min);
    setMax(benchmarkState.editingFilterState.supplyChanges.max);
  }, [benchmarkState.editingFilterState.supplyChanges]);

  const handleBlur = () => {
    if (!minGreaterThanMaxError(min, max))
      updateFilterSupplyChanges({ min: min, max: max });
  };

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    textField: string
  ) => {
    const setFunction = textField === "max" ? setMax : setMin;
    setNewValue(event.target.value, setFunction);
  };

  return (
    <ChangesPerDay
      type="supplyChanges"
      min={min}
      max={max}
      minGreaterThanMaxError={minGreaterThanMaxError(min, max)}
      maxValueExceededError={false}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
}
