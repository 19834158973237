import { TermsAndConditions } from "../../components/TermsAndConditions/TermsAndConditions";
import { TermsAndConditionsVersion } from "../../components/TermsAndConditions/TermsAndConditionsVersion";
import {
  PageHeader,
  TextInfoPage
} from "./../../components/Page";

export default function TermsAndConditionsPage() {
  return (
    <TextInfoPage>
      <PageHeader>Terms & Conditions of Use for iMoba Application</PageHeader>
      <TermsAndConditionsVersion />
      <TermsAndConditions />
    </TextInfoPage>
  );
}
