import { makeStyles } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useState } from "react";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { determineEggTypeName } from "../../../utility/EggUtility";
import { nbsp, t } from "../../../utility/TranslateUtility";
import { SettingsCard } from "../Common";
import { EggCountInput, PercentageInput } from "./Common";
import { EggTypesWithTargets, EggTypeTargets } from "./Types";

const useStyles = makeStyles((theme: any) => ({
  targetsTabs: {
    marginBottom: theme.spacing(3),
  },
}));

export type EggTypeTargetsFormProps = EggTypeTargets & {
  onChange: (v: EggTypeTargets) => void;
};

export default function EggTypeTargetsPresentation({
  eggTypeTargets,
  onEggTypeTargetsChange,
}: {
  eggTypeTargets: EggTypeTargets[];
  onEggTypeTargetsChange: (e: EggTypeTargets) => void;
}) {
  const classes = useStyles();
  const isMobileSize = useIsMobileSize('xs');

  const [selectedTab, setSelectedTab] = useState(0);
  const selectedEggType = EggTypesWithTargets[selectedTab];
  const selectedTargets = eggTypeTargets.find(
    (e) => e.eggType === selectedEggType
  )!;

  return (
    <SettingsCard title={t("managementSettings.targets.eggTypeTargets.title")}>
      <Tabs
        value={selectedTab}
        className={classes.targetsTabs}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons={isMobileSize ? "on" : "off"}
        onChange={(_, v) => setSelectedTab(v)}
      >
        {EggTypesWithTargets.map((e) => (
          <Tab key={e} label={determineEggTypeName(e)} />
        ))}
      </Tabs>

      {selectedTargets && (
        <EggTypeTargetsForm
          onChange={(nv) => onEggTypeTargetsChange(nv)}
          inputOffgrades={selectedTargets.inputOffgrades}
          setSpeed={selectedTargets.setSpeed}
          setSpeedEggUnit={selectedTargets.setSpeedEggUnit}
          tableEggs={selectedTargets.tableEggs}
          throughput={selectedTargets.throughput}
          throughputEggUnit={selectedTargets.throughputEggUnit}
          eggType={selectedTargets.eggType}
        />
      )}
    </SettingsCard>
  );
}

export function EggTypeTargetsForm(props: EggTypeTargetsFormProps) {
  const { onChange, ...values } = props;

  return (
    <>
      <EggCountInput
        id={`set-speed-${values.eggType}`}
        label={t("managementSettings.targets.eggTypeTargets.setSpeed")}
        timeLabel={t("managementSettings.targets.eggTypeTargets.setSpeedTime")}
        value={values.setSpeed}
        unit={values.setSpeedEggUnit}
        onChange={(s) => onChange({ ...values, setSpeed: s })}
        onUnitChange={(u) => onChange({ ...values, setSpeedEggUnit: u })}
      />
      <PercentageInput
        id={`table-eggs-${values.eggType}`}
        label={nbsp(t("managementSettings.targets.eggTypeTargets.tableEggs"))}
        value={values.tableEggs}
        onChange={(v) => onChange({ ...values, tableEggs: v })}
      />
      <PercentageInput
        id={`offgrades-${values.eggType}`}
        label={nbsp(
          t("managementSettings.targets.eggTypeTargets.inputOffgrades")
        )}
        value={values.inputOffgrades}
        onChange={(v) => onChange({ ...values, inputOffgrades: v })}
      />
      <EggCountInput
        id={`throughput-${values.eggType}`}
        label={t("managementSettings.targets.eggTypeTargets.throughput")}
        timeLabel={t("managementSettings.targets.eggTypeTargets.throughputTime")}
        value={values.throughput}
        unit={values.throughputEggUnit}
        onChange={(v) => onChange({ ...values, throughput: v })}
        onUnitChange={(u) => onChange({ ...values, throughputEggUnit: u })}
      />
    </>
  );
}
