import { EggGroupType } from "../../../apiModels/EggTypeMapping/EggTypeGroupApiModel";
import { t } from "../../../utility/TranslateUtility";
import { SelectFormControl, SelectItem } from "../../UI/Form/Select";

const EggTypeSelectValues: Array<{ [key: string]: any }> = [
  { label: "eggTypes.unmapped", value: EggGroupType.Unmapped },
  { label: "eggTypes.cage", value: EggGroupType.Cage },
  { label: "eggTypes.barn", value: EggGroupType.Barn },
  { label: "eggTypes.freeRange", value: EggGroupType.FreeRange },
  { label: "eggTypes.organic", value: EggGroupType.Organic },
];

export interface EggTypeGroupSelectProps {
  selectedEggType: number;
  onEggTypeChange: (newEggType: number) => void;
}

export function EggTypeGroupSelect({
  selectedEggType,
  onEggTypeChange,
}: EggTypeGroupSelectProps) {
  return (
    <SelectFormControl
      label={t("eggTypeGroupPage.table.selectLabel")}
      value={selectedEggType}
      onValueChange={onEggTypeChange}
    >
      {EggTypeSelectValues.map((e) => (
        <SelectItem key={e.value} value={e.value}>
          {t(e.label)}
        </SelectItem>
      ))}
    </SelectFormControl>
  );
}
