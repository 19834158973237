import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  withStyles
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { EggType } from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import { OrderType } from "../../CommonUtil/OrderType";
import { StyledTableCell } from "../../UI/Table/Table";
import { SupplyRanking } from "./Common";
import { SupplyRankingRow } from "./SupplyRankingRow";

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  noSupplyRankings: {
    textAlign: "center",
  },
}));

interface Props {
  rowsToDisplay?: Array<keyof SupplyRanking>;
  rankingOfSupplies: SupplyRanking[];
  brownDetectionActive: boolean;
  shellStrengthActive: boolean;
}

export default function RankingOfSuppliesPresentation({
  rankingOfSupplies,
  brownDetectionActive,
  shellStrengthActive
}: Props) {
  const classes = useStyles();

  const [order, setOrder] = useState<OrderType>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof SupplyRanking>("supplierNameShed");

  const [sortedSuppliesRanking, setSortedSuppliesRanking] =
    useState<SupplyRanking[]>(rankingOfSupplies);

  const createSortHandler =
    (property: keyof SupplyRanking) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === "asc";
      const newOrder = isAsc ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(property);
    };

  useEffect(() => {
    createSortHandler(orderBy);
    const sortFunction = getComparator(order, orderBy);
    const rankingOfSuppliersToSort = rankingOfSupplies.map((x) => {
      return {
        ...x,
        name: x.supplierNameShed,
        averageEggWeight: x.averageEggWeight,
        averageColor: x.averageColor,
        averageShellStrength: x.averageShellStrength,
        eggTypes: x.eggTypes,
        offgradeEggsPercentage: x.offgradeEggsPercentage,
        offgradeEggsNumber: x.offgradeEggsNumber,
        totalEggsNumber: x.totalEggsNumber,
        ticketNumbers: x.ticketNumbers,
      };
    });
    setSortedSuppliesRanking([...rankingOfSuppliersToSort].sort(sortFunction));
  }, [rankingOfSupplies, order, orderBy]);

  let sortDirection =
    order === "desc" ? "sorted descending" : "sorted ascending";

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {sortLabels.map((label) => (
              <StyledTableCell key={label.key}>
                <StyledTableSortLabel
                  active={orderBy === label.key}
                  direction={orderBy === label.key ? order : "asc"}
                  onClick={createSortHandler(label.key)}
                >
                  {t(label.text)}
                  {orderBy === label.key ? (
                    <span className={classes.visuallyHidden}>
                      {sortDirection}
                    </span>
                  ) : null}
                </StyledTableSortLabel>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rankingOfSupplies.length === 0 && (
            <TableRow>
              <TableCell colSpan={6} className={classes.noSupplyRankings}>
                {t("qualityViews.rankingOfSupplies.noSupplyRankings")}
              </TableCell>
            </TableRow>
          )}
          {sortedSuppliesRanking.map((supplyRanking: SupplyRanking) => (
            <SupplyRankingRow
              key={supplyRanking.supplierNameShed}
              supplyRanking={supplyRanking}
              columnsToDisplay={sortLabels.map((x) => x.key)}
              brownDetectionActive={brownDetectionActive}
              shellStrengthActive={shellStrengthActive}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const sortLabels: { key: keyof SupplyRanking; text: string }[] = [
  {
    key: "supplierNameShed",
    text: "qualityViews.rankingOfSupplies.labels.supplierName",
  },
  {
    key: "averageEggWeight",
    text: "qualityViews.rankingOfSupplies.labels.averageEggWeight",
  },
  {
    key: "averageColor",
    text: "qualityViews.rankingOfSupplies.labels.averageColor",
  },
  {
    key: "averageShellStrength",
    text: "qualityViews.rankingOfSupplies.labels.averageShellStrength",
  },
  {
    key: "eggTypes",
    text: "qualityViews.rankingOfSupplies.labels.eggTypes",
  },
  {
    key: "offgradeEggsPercentage",
    text: "qualityViews.rankingOfSupplies.labels.offgradeEggsPercentage",
  },
  {
    key: "offgradeEggsNumber",
    text: "qualityViews.rankingOfSupplies.labels.offgradeEggsNumber",
  },
  {
    key: "totalEggsNumber",
    text: "qualityViews.rankingOfSupplies.labels.totalEggsNumber",
  },
  {
    key: "ticketNumbers",
    text: "qualityViews.rankingOfSupplies.labels.ticketNumbers",
  },
];

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  })
)(TableSortLabel);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator<Key extends keyof any>(
  order: OrderType,
  orderBy: Key
): (
  a: { [key in Key]: number | string | string[] | EggType[] },
  b: { [key in Key]: number | string | string[] | EggType[] }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
