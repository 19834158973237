import * as Highcharts from "highcharts";
import { useMemo } from "react";
import { colors } from "../../../colors";
import { getLastElement } from "../../../utility/ArrayUtility";
import {
  addMinutes,
  addSeconds,
  subtractMinutes
} from "../../../utility/DateUtility";
import { EggUnit } from "../../../utility/EggUnitUtility";
import {
  calculateNumberOfEggs,
  determineEggTypeColor,
  determineEggTypeName,
  EggType
} from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import { BarChartSettingsMinute } from "../../Charts/ChartSettings/BarChartSettings";
import HighchartsFullscreenWrapper from "../Wrappers/HighchartsFullscreenWrapper";
import { EggProductionToolTipFormatter } from "./EggProductionTooltipFormatter";

export type EggProductionApiResponse = {
  data: EggProductionApiData;
};

export type EggProductionApiData = {
  eggProductionEggTypePeriods: Array<EggProductionEggTypePeriod>;
  eggProductionSpeedPeriods: Array<EggProductionSpeedPeriod>;
};

export type EggProductionEggTypePeriod = {
  dateTime: Date;
  eggTypeCounts: Array<EggTypeCount>;
};

export type EggTypeCount = {
  eggType: EggType;
  numberOfEggs: number;
};

export type EggProductionSpeedPeriod = {
  dateTime: Date;
  eggsPerMinute: number;
};

const ChartSettings: any = {
  ...BarChartSettingsMinute,
  colors: [
    colors.mobaBlue,
    colors.cageEgg,
    colors.barnEgg,
    colors.freeRangeEgg,
    colors.organicEgg,
    colors.lightBlue,
    colors.dirtGreen,
    colors.leakerYellow,
    colors.crackBlue,
    colors.mobaOrange,
  ],
  plotOptions: {
    column: {
      pointPadding: 0,
      grouping: false,
    },
    spline: {},
    series: {
      stacking: "normal",
    },
  },
  tooltip: EggProductionToolTipFormatter,
  yAxis: {
    title: {
      text: "",
    },
  },
};

function CreateEggProductionSpeedSeries(
  eggProductionSpeedPeriods: EggProductionSpeedPeriod[],
  units: EggUnit
) {
  return {
    data: eggProductionSpeedPeriods.map((productionSpeed) => {
      return {
        x: addSeconds(productionSpeed.dateTime, 30),
        y: calculateNumberOfEggs(productionSpeed.eggsPerMinute, units),
      };
    }),
    type: "spline",
    color: colors.mobaOrange,
    custom: {
      name: "set-machine-speed-yaxis",
    },
    name: t("widgets.eggProduction.legendSetMachineSpeedLabel"),
  };
}

function MapToChartData(input: EggProductionApiData, units: EggUnit) {
  let last_element = null;
  if (input.eggProductionEggTypePeriods.length > 0) {
    last_element = getLastElement(input.eggProductionEggTypePeriods);
  } else if (input.eggProductionSpeedPeriods.length > 0) {
    last_element = getLastElement(input.eggProductionSpeedPeriods);
  }
  const newMax = last_element.dateTime;

  const result = Array<any>();

  for (const eggProductionEggTypePeriod of input.eggProductionEggTypePeriods) {
    for (const eggTypeCount of eggProductionEggTypePeriod.eggTypeCounts) {
      AddEggTypeSeriesToChart(eggProductionEggTypePeriod, eggTypeCount, result, units);
    }
  }

  if (input.eggProductionSpeedPeriods.length > 0) {
    result.push(
      CreateEggProductionSpeedSeries(input.eggProductionSpeedPeriods, units)
    );
  }

  return {
    series: result,
    maxDate: addMinutes(newMax, 1),
    minDate: subtractMinutes(newMax, 29),
  };
}

/**
 * EggProduction is displayed as a stacked bar chart.
 * For this chart, that means that the series are grouped by EggType.
 *
 * This function creates a new group for an egg type if it does not exist.
 * Otherwise, it will append the eggTypeCount to the existing data point.
 *
 * Also translate the EggProduction to Eggs per hour, so that the y-axes will scale properly.
 *
 * @param eggProductionEggTypePeriod The overarching data point
 * @param eggTypeCount The data containing the count of eggtypes
 * @param series The array containing the series that is edited
 */
function AddEggTypeSeriesToChart(
  eggProductionEggTypePeriod: EggProductionEggTypePeriod,
  eggTypeCount: EggTypeCount,
  series: any[],
  units: EggUnit
) {
  const eggTypeName = determineEggTypeName(eggTypeCount.eggType);
  const found = series.find((x) => x.name === eggTypeName);
  const dataItem = {
    x: eggProductionEggTypePeriod.dateTime.getTime(),
    y: calculateNumberOfEggs(eggTypeCount.numberOfEggs * 60, units),
  };

  if (!found) {
    series.push({
      name: eggTypeName,
      data: [dataItem],
      type: "column",
      color: determineEggTypeColor(eggTypeCount.eggType),
      custom: {
        name: "egg-production-yaxis"
      },
    });
  } else {
    found.data.push(dataItem);
  }
}

export interface EggProductionWidgetPresentationProps {
  awaitingResponse: boolean;
  data: EggProductionApiData;
  units: EggUnit
}

export function EggProductionWidgetPresentation({
  awaitingResponse,
  data,
  units
}: EggProductionWidgetPresentationProps) {
  const newOptions = useMemo(() => {
    if (awaitingResponse || !data) return null;

    const newChartData = MapToChartData(data, units);

    return {
      ...ChartSettings,
      xAxis: {
        ...ChartSettings.xAxis,
        max: newChartData.maxDate,
        min: newChartData.minDate,
      },
      tooltip: EggProductionToolTipFormatter(units),
      series: newChartData.series,
    };
  }, [data, awaitingResponse, units]);

  return (
    <HighchartsFullscreenWrapper
      title={t("widgets.eggProduction.title")}
      awaitingResponse={awaitingResponse}
      highcharts={Highcharts}
      chartOptions={newOptions}
    />
  );
}
