import { Paper, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { LoadingWrapper } from "../../ProductionViews/LoadingWrapper";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingTop: 0,
  },
  cardHeader: {
    paddingBottom: 0,
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
  description: {
    margin: theme.spacing(2),
    marginTop: 0,
    marginBottom: 0,
  },
}));

export function ChartCardHeader({ title }: { title: string }) {
  const classes = useStyles();

  return (
    <CardHeader
      title={title}
      titleTypographyProps={{
        color: "textSecondary",
        gutterBottom: true,
        variant: "body1",
      }}
      className={classes.cardHeader}
    />
  );
}

export function ChartCard({
  headerTitle,
  isLoading,
  children,
}: {
  headerTitle: string;
  isLoading: boolean;
  children: JSX.Element | Array<JSX.Element>;
}) {
  const classes = useStyles();

  return (
    <Paper>
      <LoadingWrapper isLoading={isLoading}>
        <Card>
          <ChartCardHeader title={headerTitle} />
          <CardContent className={classes.cardContent}>{children}</CardContent>
        </Card>
      </LoadingWrapper>
    </Paper>
  );
}

export function ChartCardWithDescription({
  headerTitle,
  isLoading,
  desciption,
  children,
}: {
  headerTitle: string;
  isLoading: boolean;
  desciption: string;
  children: JSX.Element | Array<JSX.Element>;
}) {
  const classes = useStyles();

  return (
    <Paper>
      <LoadingWrapper isLoading={isLoading}>
        <Card>
          <ChartCardHeader title={headerTitle} />
          <Typography variant={"body2"} className={classes.description}>
            {desciption}
          </Typography>
          <CardContent className={classes.cardContent}>{children}</CardContent>
        </Card>
      </LoadingWrapper>
    </Paper>
  );
}

export function Selectbox({
  onChange,
  isChecked,
  name,
  label,
  value,
}: {
  onChange: (checked: boolean, value: string) => void;
  isChecked: boolean;
  name: string;
  value: string;
  label?: string;
}) {
  const classes = useStyles();

  return (
    <FormControlLabel
      key={name}
      control={
        <Checkbox
          onChange={(_: any, checked: boolean) => onChange(checked, value)}
          className={classes.checkbox}
          name={name}
          color="primary"
          size="small"
          checked={isChecked}
        />
      }
      label={label || name}
    />
  );
}
