import { CircularProgress, makeStyles } from "@material-ui/core";
import { EggUnit } from "../../../utility/EggUnitUtility";
import { TargetsView } from "../../Settings/Targets/Types";
import { ReportView } from "./Common";
import { ReportPerHourContainer } from "./ReportPerHourContainer";
import { ReportSummaryPresentation } from "./ReportSummaryPresentation";

interface Props {
  readonly reportView: ReportView | undefined;
  readonly isLoading: boolean;
  readonly targets: TargetsView | null;
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export function ReportPresentation({ reportView, isLoading, targets }: Props) {
  const classes = useStyles();
  const unitType = targets?.units.eggUnit ?? EggUnit.Eggs;

  return (
    <>
      {isLoading || reportView == null || reportView === undefined ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classes.content}>
            <ReportSummaryPresentation
              reportView={reportView}
              unitType={unitType}
            />
          </div>
          <div className={classes.content}>
            <ReportPerHourContainer
              reportView={reportView}
              unitType={unitType}
            />
          </div>
        </>
      )}
    </>
  );
}
