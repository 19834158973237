import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import PauseIcon from "@material-ui/icons/Pause";
import { Skeleton } from "@material-ui/lab";
import { format } from "date-fns";
import { useMemo } from "react";
import { ConditionalWrapper } from "../../../utility/ConditionalWrapper";
import { t } from "../../../utility/TranslateUtility";
import CustomTooltip from "../../Tooltip/CustomTooltip";
import { iMobaModule } from "../../Users/AddOrUpdateUser/Common";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "4px",
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  price: {
    marginBottom: theme.spacing(1),
  },
  statusChip: {
    marginBottom: theme.spacing(1),
  },
  subscriptionValidPeriod: {
    marginBottom: theme.spacing(1),
  },
  renewalSwitchWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: "0px",
  },
  switch: {
    marginRight: 0,
    marginLeft: "4px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1),
  },
  flex1: {
    flex: 1,
  },
  subscriptionButton: {
    marginLeft: "0px",
    paddinLeft: theme.spacing(1),
  },
}));

export interface SubscriptionCardProps {
  title: string;
  subtitle: string;
  pricePerDay?: number;
  isActiveSubscription: boolean;
  validUntilDate?: Date;
  module?: iMobaModule;
  isSubscriptionAutomaticallyRenewed: boolean;
  onSubscriptionRenewalSwitch: (newValue: boolean) => void;
  onSubscriptionActivation: () => void;
  onAssingUserClicked?: (module: iMobaModule) => void;
  isLoading: boolean;
}

export default function SubscriptionCard({
  title,
  subtitle,
  pricePerDay,
  isActiveSubscription,
  validUntilDate,
  module,
  onAssingUserClicked,
  isSubscriptionAutomaticallyRenewed,
  onSubscriptionRenewalSwitch,
  onSubscriptionActivation,
  isLoading,
}: SubscriptionCardProps) {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardContent className={classes.cardWrapper}>
        <div className={classes.header}>
          <SubscriptionCardTitle title={title} isLoading={isLoading} />
          <div className={classes.flex1} />
          {module && (
            <SubscriptionCardAssignUsersButton
              handleClick={() => {
                if (onAssingUserClicked) onAssingUserClicked(module);
              }}
              isLoading={isLoading}
              isActiveSubscription={isActiveSubscription}
            />
          )}
        </div>
        <SubscriptionCardSubtitle subtitle={subtitle} isLoading={isLoading} />
        <SubscriptionCardPrice
          pricePerDay={pricePerDay}
          isLoading={isLoading}
        />
        <SubscriptionCardStatus
          isActiveSubscription={isActiveSubscription}
          isLoading={isLoading}
        />
        <SubscriptionValidPeriod
          validUntilDate={validUntilDate}
          isActiveSubscription={
            isActiveSubscription && pricePerDay != undefined && pricePerDay > 0
          }
          isLoading={isLoading}
        />
        <SubscriptionRenewalSwitch
          isSubscriptionRenewed={isSubscriptionAutomaticallyRenewed}
          isActiveSubscription={isActiveSubscription}
          isDisabled={pricePerDay === 0}
          onSubscriptionRenewalSwitch={onSubscriptionRenewalSwitch}
          onSubscriptionActivation={onSubscriptionActivation}
          isLoading={isLoading}
        />
      </CardContent>
    </Card>
  );
}

function SubscriptionCardTitle({
  title,
  isLoading,
}: {
  title: string;
  isLoading: boolean;
}) {
  const classes = useStyles();

  return isLoading ? (
    <Skeleton variant="text" width={200} height={50} animation="wave" />
  ) : (
    <Typography variant="h5" className={classes.title}>
      {title}
    </Typography>
  );
}

function renderCustomTooltip(
  title: string,
  body: string,
  wrappedChildren: any
) {
  const result = (
    <CustomTooltip title={t(title)} body={t(body)}>
      {wrappedChildren}
    </CustomTooltip>
  );

  return result;
}

function SubscriptionCardAssignUsersButton({
  handleClick,
  isLoading,
  isActiveSubscription,
}: {
  handleClick: () => void;
  isLoading: boolean;
  isActiveSubscription: boolean;
}) {
  return (
    <ConditionalWrapper
      condition={!isActiveSubscription}
      wrapper={(wrappedChildren: any) =>
        renderCustomTooltip(
          "machineManagementPage.genericSubscriptionCard.assingUserDisabledTooltipTitle",
          "machineManagementPage.genericSubscriptionCard.assingUserDisabledTooltipBody",
          wrappedChildren
        )
      }
    >
      {isLoading ? (
        <Skeleton variant="rect" width={100} height={50} animation="wave" />
      ) : (
        <div>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={handleClick}
            disabled={!isActiveSubscription}
          >
            <AddIcon />
            {t("users.buttons.AssignUsers")}
          </Button>
        </div>
      )}
    </ConditionalWrapper>
  );
}

function SubscriptionCardSubtitle({
  subtitle,
  isLoading,
}: {
  subtitle: string;
  isLoading: boolean;
}) {
  const classes = useStyles();

  return isLoading ? (
    <Skeleton variant="text" animation="wave" />
  ) : (
    <Typography
      variant="body2"
      color="textSecondary"
      className={classes.subtitle}
    >
      {subtitle}
    </Typography>
  );
}

function SubscriptionCardPrice({
  pricePerDay,
  isLoading,
}: {
  pricePerDay?: number;
  isLoading: boolean;
}) {
  return isLoading ? (
    <Skeleton variant="text" height={70} animation="wave" />
  ) : (
    <CardPrice pricePerDay={pricePerDay} />
  );
}

function CardPrice({ pricePerDay }: { pricePerDay?: number }) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={classes.price}>
        {pricePerDay === 0
          ? t("machineManagementPage.genericSubscriptionCard.priceFree")
          : t("machineManagementPage.genericSubscriptionCard.pricePerDay", {
              pricePerDay: pricePerDay,
            })}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.subscriptionValidPeriod}
      >
        {t("machineManagementPage.BillingDetailCaption")}
      </Typography>
    </>
  );
}

function SubscriptionCardStatus({
  isActiveSubscription,
  isLoading,
}: {
  isActiveSubscription: boolean;
  isLoading: boolean;
}) {
  return isLoading ? (
    <Skeleton variant="rect" width={100} height={40} animation="wave" />
  ) : (
    <ChipSection isActiveSubscription={isActiveSubscription} />
  );
}

function ChipSection({
  isActiveSubscription,
}: {
  isActiveSubscription: boolean;
}) {
  const classes = useStyles();

  return isActiveSubscription ? (
    <div>
      <Chip
        label={t(
          "machineManagementPage.genericSubscriptionCard.activeSubscription"
        )}
        icon={<DoneIcon style={{ color: "#ffffff" }} />}
        className={classes.statusChip}
        style={{
          backgroundColor: "#5cb85c",
          color: "#ffffff",
        }}
      />
    </div>
  ) : (
    <div>
      <Chip
        label={t(
          "machineManagementPage.genericSubscriptionCard.inActiveSubscription"
        )}
        icon={<PauseIcon />}
        className={classes.statusChip}
      />
    </div>
  );
}

function SubscriptionValidPeriod({
  isActiveSubscription,
  validUntilDate,
  isLoading,
}: {
  isActiveSubscription: boolean;
  validUntilDate?: Date;
  isLoading: boolean;
}) {
  const classes = useStyles();

  return isLoading ? (
    <Skeleton
      variant="text"
      className={classes.subscriptionValidPeriod}
      animation="wave"
    />
  ) : (
    <Typography
      variant="caption"
      color="textSecondary"
      className={classes.subscriptionValidPeriod}
      style={{ visibility: isActiveSubscription ? "visible" : "hidden" }}
    >
      {t(
        "machineManagementPage.genericSubscriptionCard.subscriptionValidMessage",
        { formattedDate: format(validUntilDate ?? new Date(), "dd/MM/yyyy") }
      )}
    </Typography>
  );
}

function SubscriptionRenewalSwitch({
  isSubscriptionRenewed,
  isActiveSubscription,
  isDisabled,
  onSubscriptionRenewalSwitch,
  onSubscriptionActivation,
  isLoading,
}: {
  isSubscriptionRenewed: boolean;
  isActiveSubscription: boolean;
  isDisabled: boolean;
  onSubscriptionRenewalSwitch: (newSubscriptionRenewedValue: boolean) => void;
  onSubscriptionActivation: () => void;
  isLoading: boolean;
}) {
  const classes = useStyles();

  const handleActivation = () => {
    onSubscriptionActivation();
  };

  const handleRenewChange = () => {
    const toggledValue = !isSubscriptionRenewed;

    onSubscriptionRenewalSwitch(toggledValue);
  };

  const { isActiveDisabled, isAutoRenewDisabled } = useMemo(() => {
    return {
      isActiveDisabled: isActiveSubscription,
      isAutoRenewDisabled: !isActiveSubscription,
    };
  }, [isActiveSubscription]);

  return (
    <>
      <Divider />
      <div className={classes.renewalSwitchWrapper}>
        <ActivateSubscriptionButtonWrapper
          isActiveDisabled={isActiveDisabled}
          isLoading={isLoading}
          isActiveSubscription={isActiveSubscription}
          isDisabled={isDisabled}
          OnActivation={handleActivation}
        />
        <AutoRenewalSwitchWrapper
          isVisible={isActiveSubscription}
          isAutoRenewDisabled={isAutoRenewDisabled}
          isLoading={isLoading}
          isSubscriptionRenewed={isSubscriptionRenewed}
          isDisabled={isDisabled}
          onRenewChange={handleRenewChange}
        />
      </div>
    </>
  );
}

function ActivateSubscriptionButtonWrapper({
  isActiveDisabled,
  isLoading,
  isActiveSubscription,
  isDisabled,
  OnActivation,
}: Readonly<{
  isActiveDisabled: boolean;
  isLoading: boolean;
  isActiveSubscription: boolean;
  isDisabled: boolean;
  OnActivation: () => void;
}>) {
  return (
    <ConditionalWrapper
      condition={isActiveDisabled}
      wrapper={(wrappedChildren: any) =>
        renderCustomTooltip(
          "machineManagementPage.genericSubscriptionCard.subscriptionAlreadyActiveTitle",
          "machineManagementPage.genericSubscriptionCard.subscriptionAlreadyActiveBody",
          wrappedChildren
        )
      }
    >
      {isActiveSubscription ? (
        <></>
      ) : (
        <ActivateSubscriptionButton
          isActiveDisabled={isActiveDisabled}
          isLoading={isLoading}
          isDisabled={isDisabled}
          OnActivation={OnActivation}
        />
      )}
    </ConditionalWrapper>
  );
}

function ActivateSubscriptionButton({
  isActiveDisabled,
  isLoading,
  isDisabled,
  OnActivation,
}: Readonly<{
  isActiveDisabled: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  OnActivation: () => void;
}>) {
  const classes = useStyles();

  return isLoading ? (
    <Skeleton variant="text" animation="wave" />
  ) : (
    <Button
      onClick={() => OnActivation()}
      name={`button-subscription`}
      className={classes.subscriptionButton}
      color="primary"
      disabled={isDisabled || isActiveDisabled}
    >
      {t(
        "machineManagementPage.genericSubscriptionCard.activateSubscriptionLabel"
      )}
    </Button>
  );
}

function AutoRenewalSwitchWrapper({
  isVisible,
  isAutoRenewDisabled,
  isLoading,
  isDisabled,
  isSubscriptionRenewed,
  onRenewChange,
}: Readonly<{
  isVisible: boolean;
  isAutoRenewDisabled: boolean;
  isLoading: boolean;
  isSubscriptionRenewed: boolean;
  isDisabled: boolean;
  onRenewChange: () => void;
}>) {
  return (
    <ConditionalWrapper
      condition={isAutoRenewDisabled}
      wrapper={(wrappedChildren: any) =>
        renderCustomTooltip(
          "machineManagementPage.genericSubscriptionCard.autoRenewDisabledTooltipTitle",
          "machineManagementPage.genericSubscriptionCard.autoRenewDisabledTooltipBody",
          wrappedChildren
        )
      }
    >
      {isVisible ? (
        <AutoRenewalSwitch
          isAutoRenewDisabled={isAutoRenewDisabled}
          isLoading={isLoading}
          isDisabled={isDisabled}
          isSubscriptionRenewed={isSubscriptionRenewed}
          onRenewChange={onRenewChange}
        />
      ) : (
        <></>
      )}
    </ConditionalWrapper>
  );
}

function AutoRenewalSwitch({
  isAutoRenewDisabled,
  isLoading,
  isDisabled,
  isSubscriptionRenewed,
  onRenewChange,
}: {
  isAutoRenewDisabled: boolean;
  isLoading: boolean;
  isSubscriptionRenewed: boolean;
  isDisabled: boolean;
  onRenewChange: () => void;
}) {
  const classes = useStyles();

  return isLoading ? (
    <Skeleton variant="text" animation="wave" />
  ) : (
    <FormControlLabel
      control={
        <Switch
          checked={isSubscriptionRenewed}
          onChange={() => onRenewChange()}
          name={`switch-renewal`}
          color="primary"
        />
      }
      labelPlacement="end"
      label={t(
        "machineManagementPage.genericSubscriptionCard.renewSubscriptionAutomaticallyLabel"
      )}
      className={classes.switch}
      classes={{
        labelPlacementStart: classes.switch,
      }}
      style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
      disabled={isDisabled || isAutoRenewDisabled}
    />
  );
}
