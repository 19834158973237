import React, { useCallback, useEffect, useState } from "react";
import {
  useManagementSettingsService,
  useReportService,
} from "../../../api/ServiceContext";
import { MachineUnitSettings } from "../../../pages/management-settings/Common";
import {
  calculateNumberOfEggs,
  getDisplayUnit,
} from "../../../utility/EggUtility";
import useRefresh from "../../Dashboard/useRefresh";
import ActualSpeedWidgetPresentation, {
  ActualSpeedApiResponse,
} from "./ActualSpeed";

export type ActualSpeedWidgetProps = {
  machineId: number;
  automaticRefresh: boolean;
};

/**
 * A simple widget that fetches and displays the current sorting speed of the machine, measured in the number of eggs of the last 60 seconds
 */
export default function ActualSpeedWidgetContainer({
  machineId,
  automaticRefresh,
}: ActualSpeedWidgetProps) {
  const reportService = useReportService();
  const settingsService = useManagementSettingsService();

  const [eggsPerHour, setEggsPerHour] = useState(0);
  const [machineCapacity, setMachineCapacity] = useState(0);
  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [calculatedEggsPerHour, setCalculatedEggsPerHour] = useState(0);
  const [calculatedMachineCapacity, setCalculatedMachineCapacity] = useState(0);
  const [displayUnit, setDisplayUnit] = useState("");

  const handleMenuItemClicked = (eggUnit: string) => {
    setCalculatedEggsPerHour(calculateNumberOfEggs(eggsPerHour, eggUnit));
    setCalculatedMachineCapacity(
      calculateNumberOfEggs(machineCapacity, eggUnit)
    );
    setDisplayUnit(getDisplayUnit(eggUnit));
  };

  const fetchData = useCallback(
    (newMachineId: number) => {
      reportService
        .getActualSpeed(newMachineId)
        .then((response: ActualSpeedApiResponse) => {
          setMachineCapacity(response.data.machineCapacity);
          setEggsPerHour(response.data.eggsPerHour);
          setCalculatedMachineCapacity(
            calculateNumberOfEggs(response.data.machineCapacity, displayUnit)
          );
          setAwaitingResponse(false);
        });
      settingsService
        .getUnitsFromMachineTargetsSettings(newMachineId, new Date())
        .then((settingsResponse: IApiResponse<MachineUnitSettings>) => {
          handleMenuItemClicked(settingsResponse.data.eggUnit.toString());
        });
    },
    // DisplayUnit is missing in this useEffect, we can't include it here because
    // menuItemClicked also updates this property. So it is safe to omit displayUnit here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportService, settingsService, eggsPerHour, machineCapacity]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return (
    <ActualSpeedWidgetPresentation
      awaitingResponse={awaitingResponse}
      calculatedEggsPerHour={calculatedEggsPerHour}
      calculatedMachineCapacity={calculatedMachineCapacity}
      displayUnit={displayUnit}
      onMenuItemClicked={handleMenuItemClicked}
    />
  );
}
