import { subWeeks } from "date-fns";
import { getStartOfWeek } from "../../../utility/DateUtility";
import { EggType } from "../../../utility/EggUtility";
import { benchmarkStateStorageService } from "../selectDisplayedWidgetsHooks";

export type MachineIdFilter = string;

export const allMeasures: Measure[] = [
  "InputOffgrades",
  "Upgrades",
  "Crack",
  "Leaker",
  "UpgradeUtilization",
  "ProductionAvailability",
  "TableEggs",
  "Dirt",
  "Blood",
  "CapacityUtilization",
  "InputGradeA",
];

export const allRadarMeasures: Measure[] = [
  "TableEggs",
  "Upgrades",
  "InputGradeA",
  "CapacityUtilization",
  "UpgradeUtilization",
  "ProductionAvailability",
];

export type PeriodInYearType = "month" | "week";
export type ChangesPerDayType =
  | "supplyChanges"
  | "productChanges"
  | "upgradingPercentage";

export type Measure =
  | "TableEggs"
  | "InputOffgrades"
  | "Upgrades"
  | "InputGradeA"
  | "Dirt"
  | "Crack"
  | "Blood"
  | "CapacityUtilization"
  | "UpgradeUtilization"
  | "ProductionAvailability"
  | "Leaker";

export interface PeriodInYear {
  period: number;
  year: number;
}

export interface MeasureComparison extends PeriodInYear {
  value: number;
}

export interface MachineMeasureComparison extends MeasureComparison {
  machineId: MachineId;
}

export interface RangeFilter {
  min: number | null;
  max: number | null;
}

export interface MachineMeasureComparisonData {
  periodInYearType: PeriodInYearType;
  comparisonMachinesMeasures: MachineMeasureComparison[];
  bestMachinesMeasures: MeasureComparison[];
  worstMachinesMeasures: MeasureComparison[];
  comparedMachinesCount: number;
  minimumComparedMachinesCount: number;
}

export interface CompairedMachineCountData {
  comparedMachinesCount: number;
  minimumComparedMachinesCount: number;
}

export interface MachineComparison {
  measure: Measure;
  data: MachineMeasureComparisonData;
}

export interface MeasureData {
  data: Array<MachineComparison>;
}

export interface BenchmarkState {
  editingFilterState: MachineMeasuresComparisonFilterState;
  appliedFilterState: MachineMeasuresComparisonFilterState;
  lastValidFilterState: MachineMeasuresComparisonFilterState;
  comparedToMachinesCount: number | null;
  minimumComparedToMachinesCount: number | null;
  selectedWidgets: Measure[];
  showRadar: boolean;
  measureData: MeasureData;
  awaitingRequestCount: number;
  comparedMachineRequestCount: number;
}

export interface MachineMeasuresComparisonFilterState {
  periodType: PeriodInYearType;
  countries: Array<string>;
  machineTypes: Array<string>;
  eggTypes: EggType[];
  measures: Measure[];
  fromDate: Date;
  untilDate: Date;
  myMachines: Array<MachineIdFilter>;
  supplyChanges: RangeFilter;
  productChanges: RangeFilter;
  upgradingPercentage: RangeFilter;
}

export const minimumMeasuresForRadarGraph = 3;

export const initialBenchmarkFilterState: () => MachineMeasuresComparisonFilterState = () => {
  return {
    periodType: "week",
    countries: [],
    machineTypes: [],
    eggTypes: [],
    fromDate: subWeeks(getStartOfWeek(new Date()), 1),
    untilDate: getStartOfWeek(new Date()),
    myMachines: [],
    measures: [...allMeasures],
    supplyChanges: { min: null, max: null },
    productChanges: { min: null, max: null },
    upgradingPercentage: { min: null, max: null },
  };
};

export const initialState: BenchmarkState = {
  editingFilterState: initialBenchmarkFilterState(),
  lastValidFilterState: initialBenchmarkFilterState(),
  selectedWidgets: benchmarkStateStorageService.getSelectedWidgets(),
  appliedFilterState: initialBenchmarkFilterState(),
  comparedToMachinesCount: null,
  minimumComparedToMachinesCount: null,
  showRadar: benchmarkStateStorageService.getShowRadar(),
  measureData: {
    data: [],
  },
  awaitingRequestCount: 0,
  comparedMachineRequestCount: 0,
};

export type MachineMeasuresQuery = {
  measures: Measure[];
  periodInYearType: PeriodInYearType;
};

export function areMatching(a: PeriodInYear, b: PeriodInYear) {
  return (!a && !b) || (a && b && a.period === b.period && a.year === b.year);
}
