import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { useOwnerService } from "../../../api/ServiceContext";
import { useSelectedMachine } from "../../../store/GlobalContext";
import { t } from "../../../utility/TranslateUtility";
import { useDispatchAlert } from "../../UI/Alert";
import { ConfirmActionDialog } from "../ConfirmActionDialog";
import { AssignAdminDialog } from "./AssignAdminDialog";
import { AssignAdminPresentation } from "./AssignAdminPresentation";
export interface AssignAdminFormFields {
  email: string;
}

export interface AssignAdminContainerProps {
  userIsAdmin: boolean;
  isLoading: boolean;
  onOwnerAdminStatusChanged: () => void;
}

export function AssignAdminContainer({
  userIsAdmin,
  isLoading,
  onOwnerAdminStatusChanged,
}: AssignAdminContainerProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const ownerService = useOwnerService();
  const selectedMachine = useSelectedMachine();
  const dispatchAlert = useDispatchAlert();
  const auth0 = useAuth0();
  const [toggleDisabled, setToggleDisabled] = useState(false);
  let revokeAdminConfirmed = false;
  const [showConfirmRevokeDialog, setShowConfirmRevokeDialog] = useState(false);

  const handleConfirmRevokeDialogConfirm = () => {
    setShowConfirmRevokeDialog(false);
    revokeAdminConfirmed = true;
    handleMakeMeAdmin();
  };

  const handleConfirmRevokeDialogReject = () => {
    setShowConfirmRevokeDialog(false);
    revokeAdminConfirmed = false;
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const assignAdmin = (
    adminEmail: string,
    machineId: MachineId,
    responsibleEmail: string
  ) => {
    ownerService
      .assignAdmin(adminEmail, machineId, responsibleEmail)
      .then(() => {
        dispatchAlert({
          message: t("users.messages.userSavedReload"),
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: t("users.messages.userSaveFailed"),
          messageType: "error",
        });
      })
      .finally(() => {
        refreshAdmins(adminEmail);
        setToggleDisabled(false);
      });
  };

  const revokeAdmin = (
    adminEmail: string,
    machineId: MachineId,
    responsibleEmail: string
  ) => {
    ownerService
      .revokeAdmin(adminEmail, machineId, responsibleEmail)
      .then(() => {
        dispatchAlert({
          message: t("users.messages.userSaved"),
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: t("users.messages.userSaveFailed"),
          messageType: "error",
        });
      })
      .finally(() => {
        refreshAdmins(adminEmail);
        setToggleDisabled(false);
      });
  };

  const handleAssign = (assignAdminFields: AssignAdminFormFields) => {
    assignAdmin(
      assignAdminFields.email,
      selectedMachine.machineId,
      auth0.user.email
    );
    handleClose();
  };

  const handleMakeMeAdmin = () => {
    if (!revokeAdminConfirmed && userIsAdmin) {
      setShowConfirmRevokeDialog(true);
      return;
    }

    if (auth0.user) {
      setToggleDisabled(true);
      revokeAdminConfirmed = false;

      if (userIsAdmin) {
        revokeAdmin(
          auth0.user.email,
          selectedMachine.machineId,
          auth0.user.email
        );
      } else {
        assignAdmin(
          auth0.user.email,
          selectedMachine.machineId,
          auth0.user.email
        );
      }
    }
  };

  return (
    <>
      <ConfirmActionDialog
        message={t("users.messages.revokeAdmin")}
        isOpen={showConfirmRevokeDialog}
        onConfirm={handleConfirmRevokeDialogConfirm}
        onReject={handleConfirmRevokeDialogReject}
      />
      <AssignAdminDialog
        isOpen={dialogOpen}
        handleAssign={handleAssign}
        handleClose={handleClose}
      />
      <AssignAdminPresentation
        openDialog={() => setDialogOpen(true)}
        onMakeMeAdmin={handleMakeMeAdmin}
        userIsAdmin={userIsAdmin}
        toggleDisabled={toggleDisabled}
        isLoading={isLoading}
      />
    </>
  );

  function refreshAdmins(adminEmail: string) {
    if (adminEmail === auth0.user.email) {
      window.location.reload(); // hard refresh so the changes are visible immediately
    } else {
      onOwnerAdminStatusChanged();
    }
  }
}
