import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    marginTop: theme.spacing(2),
  },
}));

export function Paragraph({ children }: any) {
  const classes = useStyles();
  return (
    <Typography className={classes.paragraph} variant="body1">
      {children}
    </Typography>
  );
}
