import React from "react";
import { t } from "../../utility/TranslateUtility";
import ActualSpeedWidget from "../Widgets/ActualSpeed";
import EggProductionWidget from "../Widgets/EggProduction";
import EggTypeWidget from "../Widgets/EggType";
import FillRateWidget from "../Widgets/FillRate";
import LaneProductsDistribution from "../Widgets/LaneProductsDistribution";
import LaneProductProgress from "../Widgets/LaneProductsProgress";
import MachineRunningWidget from "../Widgets/MachineRunning";
import MachineSpeedHourWidget from "../Widgets/MachineSpeedHour";
import MachineSpeedMinuteWidget from "../Widgets/MachineSpeedMinute";
import MachineStatesWidget from "../Widgets/MachineStates";
import OffgradesWidget from "../Widgets/Offgrades";
import SupplierShedWidget from "../Widgets/SupplierShed";
import TotalEggsWidget from "../Widgets/TotalEggs";
import ActualSpeedImage from "./images/ActualSpeed.jpg";
import EggProductionImage from "./images/EggProduction.jpg";
import EggTypeImage from "./images/EggType.jpg";
import FillRateImage from "./images/FillRate.jpg";
import LaneProductDistributionImage from "./images/LaneProductDistribution.jpg";
import LaneProductProgressImage from "./images/LaneProductProgress.jpg";
import MachineRunningImage from "./images/MachineRunning.jpg";
import MachineSpeedHourImage from "./images/MachineSpeedHour.jpg";
import MachineSpeedMinuteImage from "./images/MachineSpeedMinute.jpg";
import MachineStatesImage from "./images/MachineStates.jpg";
import OffgradesImage from "./images/Offgrades.jpg";
import SupplierShedImage from "./images/SupplierShed.jpg";
import TotalEggsImage from "./images/TotalEggs.jpg";

export type Widget = {
  title: string;
  widgetName: string;
  description: string;
  image: string;
  width: number;
  height: number;
  usedInDashboard: boolean;
  component: JSX.Element;
};

export const WidgetList: Array<Widget> = [
  {
    title: t("widgetList.laneProductProgress.title"),
    widgetName: "lane-product-progress",
    description: t("widgetList.laneProductProgress.description"),
    image: LaneProductProgressImage,
    width: 6,
    height: 3,
    usedInDashboard: false,
    component: <LaneProductProgress machineId={0} automaticRefresh={true} />,
  },
  {
    title: t("widgetList.laneProductDistribution.title"),
    widgetName: "lane-product-distribution",
    description: t("widgetList.laneProductDistribution.description"),
    image: LaneProductDistributionImage,
    width: 6,
    height: 3,
    usedInDashboard: false,
    component: (
      <LaneProductsDistribution machineId={0} automaticRefresh={true} />
    ),
  },
  {
    title: t("widgetList.supplierShed.title"),
    widgetName: "supplier-shed",
    description: t("widgetList.supplierShed.description"),
    image: SupplierShedImage,
    width: 2.5,
    height: 1,
    usedInDashboard: false,
    component: <SupplierShedWidget machineId={0} automaticRefresh={true} />,
  },
  {
    title: t("widgetList.totalEggs.title"),
    widgetName: "total-eggs",
    description: t("widgetList.totalEggs.description"),
    image: TotalEggsImage,
    width: 2,
    height: 1,
    usedInDashboard: false,
    component: <TotalEggsWidget machineId={0} automaticRefresh={true} />,
  },
  {
    title: t("widgetList.eggType.title"),
    widgetName: "egg-type",
    description: t("widgetList.eggType.description"),
    image: EggTypeImage,
    width: 2,
    height: 1,
    usedInDashboard: false,
    component: <EggTypeWidget machineId={0} automaticRefresh={true} />,
  },
  {
    title: t("widgetList.actualSpeed.title"),
    widgetName: "actual-speed",
    description: t("widgetList.actualSpeed.description"),
    image: ActualSpeedImage,
    width: 3,
    height: 2,
    usedInDashboard: false,
    component: <ActualSpeedWidget machineId={0} automaticRefresh={true} />,
  },
  {
    title: t("widgetList.machineRunning.title"),
    widgetName: "machine-running",
    description: t("widgetList.machineRunning.description"),
    image: MachineRunningImage,
    width: 6,
    height: 3,
    usedInDashboard: false,
    component: <MachineRunningWidget machineId={0} automaticRefresh={true} />,
  },
  {
    title: t("widgetList.offgrades.title"),
    widgetName: "offgrades",
    description: t("widgetList.offgrades.description"),
    image: OffgradesImage,
    width: 6,
    height: 3,
    usedInDashboard: false,
    component: <OffgradesWidget machineId={0} automaticRefresh={true} />,
  },
  {
    title: t("widgetList.fillRate.title"),
    widgetName: "fill-rate",
    description: t("widgetList.fillRate.description"),
    image: FillRateImage,
    width: 6,
    height: 3,
    usedInDashboard: false,
    component: <FillRateWidget machineId={0} automaticRefresh={true} />,
  },
  {
    title: t("widgetList.machineStates.title"),
    widgetName: "machine-states",
    description: t("widgetList.machineStates.description"),
    image: MachineStatesImage,
    width: 6,
    height: 3,
    usedInDashboard: false,
    component: <MachineStatesWidget machineId={0} automaticRefresh={true} />,
  },
  {
    title: t("widgetList.machineSpeedHour.title"),
    widgetName: "machine-speed-hour",
    description: t("widgetList.machineSpeedHour.description"),
    image: MachineSpeedHourImage,
    width: 6,
    height: 3,
    usedInDashboard: false,
    component: <MachineSpeedHourWidget machineId={0} automaticRefresh={true} />,
  },
  {
    title: t("widgetList.machineSpeedMinute.title"),
    widgetName: "machine-speed-minute",
    description: t("widgetList.machineSpeedMinute.description"),
    image: MachineSpeedMinuteImage,
    width: 6,
    height: 3,
    usedInDashboard: false,
    component: (
      <MachineSpeedMinuteWidget machineId={0} automaticRefresh={true} />
    ),
  },
  {
    title: t("widgetList.eggProduction.title"),
    widgetName: "egg-production",
    description: t("widgetList.eggProduction.description"),
    image: EggProductionImage,
    width: 6,
    height: 3,
    usedInDashboard: false,
    component: <EggProductionWidget machineId={0} automaticRefresh={true} />,
  },
];
