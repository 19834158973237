import React from "react";
import { AlertSnackbar } from "./Alert";
import { useAlertsState } from "../../../store/GlobalContext";
import { useDispatchClearMessages } from "./alertHooks";

export function AlertConnected() {
  const state = useAlertsState();
  const dispatchClear = useDispatchClearMessages();

  const hasMessages = state.alertMessages.length > 0;
  const message = hasMessages
    ? state.alertMessages[state.alertMessages.length - 1]
    : undefined;

  return (
    <AlertSnackbar
      open={hasMessages}
      onClose={() => dispatchClear()}
      message={message}
    />
  );
}
