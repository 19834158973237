import React from "react";
import { PerformanceProToolbarContainer } from "./PerformanceProToolbar";
import { ReportContainer } from "../../components/PerformancePro/Report/ReportContainer";

export function PerformanceProReport() {
  return (
    <>
      <PerformanceProToolbarContainer
        enableEggTypeSelect={false}
        enableDatePeriod={false}
      />
      <ReportContainer />
    </>
  );
}
