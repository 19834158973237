import React from "react";
import { t } from "../../utility/TranslateUtility";
import { makeStyles } from "@material-ui/core";
import { SettingsCard } from "../../components/Settings/Common";
import DeviceRegistrationContainer from "../../components/Notifications/DeviceRegistration/DeviceRegistrationContainer";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3),
  },
  button: {
    float: "right",
    [theme.breakpoints.down("xs")]: {
      float: "none",
    },
  },
}));

function NotificationsPage() {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <SettingsCard title={t("notificationManagement.linkTitle")}>
        <DeviceRegistrationContainer />
      </SettingsCard>
    </div>
  );
}

export default NotificationsPage;
