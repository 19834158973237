
export enum AlarmTopic {
  TotalEggs = 0,
  Throughput,
  ProductionTime,
  TableEggs,
  InputOffgrades,
  Availability,
  Upgrades,
  OutputOffgrades,
  Bypass,
  BucketEggs,
  FillRate,
  MachineConnection
}

export enum AlarmCondition {
  Below = 0,
  Above
}

export enum AlarmFrequency {
  Hourly = 0,
  Daily
}

export enum AlarmStatus {
  Active = 0,
  Passive
}

export enum AlarmEggsProdUnit {
  Eggs = 0,
  Dozens,
  Cases
}

export enum AlarmThroughputUnit {
  EpH = 0,
  DpH,
  CpH
}

export enum AlarmTimeUnit {
  Minutes = 0,
  Hours
}

export enum AlarmPercentage {
  Percentage = 0
}

export enum AlarmLevelUnit {
  Eggs = 0,
  Dozens,
  Cases,

  EpH,
  DpH,
  CpH,

  Minutes,
  Hours,

  Percentage
}
