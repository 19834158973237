import {
  endOfMonth,
  isBefore,
  isSameMonth,
  startOfDay,
  startOfMonth,
  subMonths,
  subWeeks
} from "date-fns";
import { endOfDay } from "date-fns/esm";
import {
  useBenchmarkActions,
  useBenchmarkState
} from "../../../store/GlobalContext";
import {
  DatePeriod,
  getEndOfWeek,
  getStartOfWeek,
  isInSameWeek
} from "../../../utility/DateUtility";
import BenchmarkToolbarPresentation from "./BenchmarkToolbarPresentation";

/**
 *
 * @param children The children to render when the viewport is mobile
 * @returns
 */
export default function BenchmarkToolbarContainer({ children }: any) {
  const benchmarkState = useBenchmarkState();
  const { updateFilterPeriodType, updateFromDate, updateUntilDate } =
    useBenchmarkActions();

  const handleFromDateChange = (newDate: Date) => {
    if (isInSameWeek(newDate, benchmarkState.editingFilterState.untilDate))
      return;

    if (benchmarkState.editingFilterState.periodType == "month") {
      updateFromDate(startOfMonth(newDate));
    }

    if (benchmarkState.editingFilterState.periodType == "week") {
      updateFromDate(getStartOfWeek(newDate));
    }
  };

  const handleUntilDateChange = (untilDate: Date) => {
    if (benchmarkState.editingFilterState.periodType === "month") {
      if (
        isSameMonth(untilDate, benchmarkState.editingFilterState.fromDate) ||
        isBefore(untilDate, benchmarkState.editingFilterState.fromDate)
      ) {
        const fromDate = startOfMonth(subMonths(untilDate, 1));
        updateFromDate(fromDate);
      }
      updateUntilDate(endOfMonth(untilDate));
    }

    if (benchmarkState.editingFilterState.periodType === "week") {
      if (
        isInSameWeek(untilDate, benchmarkState.editingFilterState.fromDate) ||
        isBefore(untilDate, benchmarkState.editingFilterState.fromDate)
      ) {
        const fromDate = subWeeks(untilDate, 1);
        updateFromDate(fromDate);
      }

      var newUntilDate = isInSameWeek(untilDate, new Date())
        ? endOfDay(new Date())
        : getEndOfWeek(untilDate);

      updateUntilDate(newUntilDate);
    }
  };

  function setFromDateMonth() {
    if (
      isSameMonth(
        benchmarkState.editingFilterState.fromDate,
        benchmarkState.editingFilterState.untilDate
      )
    ) {
      const fromDate = subMonths(benchmarkState.editingFilterState.fromDate, 1);
      const startOfMonthFromDate = startOfMonth(fromDate);
      updateFromDate(startOfMonthFromDate);
    } else {
      const startOfMonthFromDate = startOfMonth(
        benchmarkState.editingFilterState.fromDate
      );
      updateFromDate(startOfMonthFromDate);
    }
  }

  function setFromDateWeek() {
    if (
      isInSameWeek(
        benchmarkState.editingFilterState.fromDate,
        benchmarkState.editingFilterState.untilDate
      )
    ) {
      const fromDate = subWeeks(benchmarkState.editingFilterState.fromDate, 1);
      const startOfWeekFromDate = getStartOfWeek(fromDate);
      updateFromDate(startOfWeekFromDate);
    } else {
      const startOfWeekFromDate = getStartOfWeek(
        benchmarkState.editingFilterState.fromDate
      );
      updateFromDate(startOfWeekFromDate);
    }
  }

  function setUntilDateMonth() {
    const endOfMonthUnilDate = isSameMonth(
      benchmarkState.editingFilterState.untilDate,
      new Date()
    )
      ? startOfDay(new Date())
      : endOfMonth(benchmarkState.editingFilterState.untilDate);

    updateUntilDate(endOfMonthUnilDate);
  }

  function setUntilDateWeek() {
    const endOfWeekUnilDate = isInSameWeek(
      benchmarkState.editingFilterState.untilDate,
      new Date()
    )
      ? startOfDay(new Date())
      : getEndOfWeek(benchmarkState.editingFilterState.untilDate);

    updateUntilDate(endOfWeekUnilDate);
  }

  const handleDatePeriodChange = (datePeriod: DatePeriod) => {
    if (datePeriod === DatePeriod.Month) {
      setFromDateMonth();
      setUntilDateMonth();
      updateFilterPeriodType("month");
    }

    if (datePeriod === DatePeriod.Week) {
      setFromDateWeek();
      setUntilDateWeek();
      updateFilterPeriodType("week");
    }
  };

  return (
    <BenchmarkToolbarPresentation
      onFromDateChange={handleFromDateChange}
      onUntilDateChange={handleUntilDateChange}
      onDatePeriodChange={handleDatePeriodChange}
      fromDate={benchmarkState.editingFilterState.fromDate}
      untilDate={benchmarkState.editingFilterState.untilDate}
      periodType={benchmarkState.editingFilterState.periodType}
    >
      {children}
    </BenchmarkToolbarPresentation>
  );
}
