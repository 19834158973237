import { EggUnit } from "./EggUnitUtility";
import { createUnitsConverter, FormatEggs, menuItems } from "./EggUtility";
import { GetCurrentLanguage } from "./TranslateUtility";

export { FormatEggs } from "./EggUtility";

export function FormatWithTwoDecimals(n: number, unit?: EggUnit): string {
  if (unit) {
    const inUnits = createUnitsConverter(unit);

    return Intl.NumberFormat(GetCurrentLanguage(), {
      minimumFractionDigits: 2,
    }).format(Math.round((inUnits(n) + Number.EPSILON) * 100) / 100);
  }

  return Intl.NumberFormat(GetCurrentLanguage(), {
    minimumFractionDigits: 2,
  }).format(Math.round((n + Number.EPSILON) * 100) / 100);
}

export function FormatWithOneDecimal(n: number): string {
  return Intl.NumberFormat(GetCurrentLanguage(), {
    minimumFractionDigits: 1,
  }).format(Math.round(n * 10) / 10);
}

export function FormatWithNoDecimal(n: number): string {
  return Intl.NumberFormat(GetCurrentLanguage(), {
    minimumFractionDigits: 0,
  }).format(Math.round(n));
}

export function FormatSelectedEggUnit(
  displayUnit: string,
  calculatedEggsPerHour: number
): string {
  return displayUnit === menuItems[1]
    ? FormatEggs(calculatedEggsPerHour, null)
    : FormatWithOneDecimal(calculatedEggsPerHour);
}

export function FormatPercentage(n: number) {
  return Number((n * 100).toFixed(2));
}

export function roundToDecimal(number: number, decimals: number = 2) {
  return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function FormatSecondsToMinutesAndSeconds(
  totalSeconds?: number
): string {
  if (totalSeconds === undefined) {
    return "00:00";
  }

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
}
