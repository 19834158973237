import { useCallback } from "react";
import {
  FeaturePolicyItems,
  useAuthorizationScope
} from "./authorizationHooks";

export interface Props {
  policy: FeaturePolicyItems;
  children: JSX.Element | Array<JSX.Element>;
}

export function PolicyFeatureWrapper({ policy, children }: Props) {
  const { hasFeatureEnabled } = usePolicyFeatures();

  return hasFeatureEnabled(policy) ? <>{children}</> : null;
}

export function usePolicyFeatures() {
  const authorizationScope = useAuthorizationScope();

  const hasFeatureEnabled = useCallback(
    (policy: FeaturePolicyItems) => {
      return (
        authorizationScope.userMachineAuthorizations.policy !== undefined &&
        authorizationScope.userMachineAuthorizations.policy.enabledFeatures.includes(
          policy
        )
      );
    },
    [authorizationScope]
  );

  return {
    hasFeatureEnabled: hasFeatureEnabled,
    enabledFeatures:
      authorizationScope.userMachineAuthorizations.policy.enabledFeatures,
  };
}
