import { EggTypeGroupMappingRequest } from "../../apiModels/EggTypeMapping/EggTypeGroupApiModel";
import { TargetsView } from "../../components/Settings/Targets/Types";
import {
  BreakTimeStrings,
  ManagementSettings,
  QualityChartSetting,
  ReportSettingsApiData,
} from "../../pages/management-settings/Common";
import { axiosHttpClient } from "../../store/GlobalContext";
import {
  removeTimezone,
  toISO8601DateStringLocal,
} from "../../utility/DateUtility";
import { IHttpClient } from "../IHttpClient";
import { IManagementSettingsService } from "./IManagementSettingsService";

export const getBreakTimes = async (
  machineId: MachineId,
  settingsDate: Date
): Promise<any> => {
  const response = await axiosHttpClient.get(
    `v1/machinesettings/${machineId}/settings/break-times`,
    {
      params: {
        settingsDateUtc: toISO8601DateStringLocal(settingsDate),
      },
    }
  );

  return response.data;
};

export const updateBreakTimes = async (
  machineId: MachineId,
  breakTimes: Array<BreakTimeStrings>
): Promise<any> => {
  const response = await axiosHttpClient.put(
    `v1/machinesettings/${machineId}/settings/break-times`,
    {
      breakTimes: breakTimes,
    }
  );

  return response.data;
};

export class ManagmentSettingsService implements IManagementSettingsService {
  constructor(private httpclient: IHttpClient) {}

  getMachineTargetsSettings(
    machineId: MachineId,
    settingsDate: Date
  ): Promise<any> {
    return this.get(`v1/machinesettings/${machineId}/settings/targets`, {
      params: {
        settingsDateUtc: toISO8601DateStringLocal(settingsDate),
      },
    });
  }

  updateMachineTargetsSettings(
    machineId: MachineId,
    targets: TargetsView
  ): Promise<any> {
    return this.put(`v1/machinesettings/${machineId}/settings/targets`, {
      ...targets,
    });
  }

  getEggTypeGroupMappings(machineId: MachineId) {
    return this.get(
      `v1/machinesettings/${machineId}/settings/egg-type-group`,
      {}
    );
  }

  saveEggTypeGroupMappings(
    machineId: MachineId,
    requestBody: EggTypeGroupMappingRequest
  ) {
    return this.put(
      `v1/machinesettings/${machineId}/settings/egg-type-group`,
      requestBody
    );
  }

  getOffgradeLabelsSettings(
    machineId: MachineId,
    settingsDate: Date
  ): Promise<any> {
    return this.get(
      `v1/machinesettings/${machineId}/settings/offgrade-labels`,
      {
        params: {
          settingsDateUtc: toISO8601DateStringLocal(settingsDate),
        },
      }
    );
  }

  updateOffgradeLabelsSettings(
    machineId: MachineId,
    settings: ManagementSettings
  ): Promise<any> {
    return this.put(
      `v1/machinesettings/${machineId}/settings/offgrade-labels`,
      {
        ...settings,
        offgradeLabelsView: settings.offgradeLabels.map((x) => {
          return {
            MMILabel: x.mmiLabel,
            MMILabelName: x.mmiLabelName,
            CustomName: x.customLabel,
          };
        }),
      }
    );
  }

  getMachineChartViewSettings(machineId: MachineId): Promise<any> {
    return this.get(`v1/machinesettings/${machineId}/settings/views`);
  }

  updateMachineChartViewSettings(
    machineId: MachineId,
    settings: QualityChartSetting
  ): Promise<any> {
    return this.put(`v1/machinesettings/${machineId}/settings/views`, {
      ...settings,
    });
  }

  getReportSettings(machineId: MachineId, settingsDate: Date): Promise<any> {
    return this.get(`v1/machinesettings/${machineId}/settings/report`, {
      params: {
        settingsDateUtc: toISO8601DateStringLocal(settingsDate),
      },
    });
  }

  updateReportSettings(
    machineId: MachineId,
    settings: ReportSettingsApiData
  ): Promise<any> {
    return this.put(`v1/machinesettings/${machineId}/settings/report`, {
      ...settings,
      timeSlots: {
        startTimeSlot: removeTimezone(settings.timeSlots.startTimeSlot),
        endTimeSlot: removeTimezone(settings.timeSlots.endTimeSlot),
      },
    });
  }

  getUnitsFromMachineTargetsSettings(
    machineId: MachineId,
    settingsDate: Date
  ): Promise<any> {
    return this.getMachineTargetsSettings(machineId, settingsDate).then(
      (r) => ({
        data: r.data.units,
      })
    );
  }

  private get(url: string, params?: any): Promise<any> {
    return this.httpclient.get(url, params);
  }
  private put(url: string, body: any): Promise<any> {
    return this.httpclient.put(url, body);
  }
}
