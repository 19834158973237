import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import BreakTimesContainer from "../../../components/Settings/BreakTimes/BreakTimesContainer";
import { SettingsCard } from "../../../components/Settings/Common";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3),
  },
}));

export default function BreakTimesPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <SettingsCard
        title={t("managementSettings.breakTimes.title")}
        subtitle={t("managementSettings.breakTimes.subtitle")}
      >
        <Grid container>
          <Grid item xs={12} lg={8}>
            <BreakTimesContainer />
          </Grid>
        </Grid>
      </SettingsCard>
    </div>
  );
}
