import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Phone from "google-libphonenumber";
import MuiPhoneInput from "material-ui-phone-number";
import { Fragment, useEffect, useMemo, useState } from "react";
import { t } from "../../../utility/TranslateUtility";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  mt4: {
    marginTop: "2rem",
  },
  error: {
    color: "#f33",
  },
}));

interface UserSettingsProps {
  user: any | undefined;
  onUpdateInformation: (newInformation: any) => void;
}

function UserSettings({ user, onUpdateInformation }: UserSettingsProps) {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [cellphone, setCellphone] = useState<string>("");

  const userChnaged = useMemo(() => {
    if (
      email !== user.email ||
      firstName !== user.firstName ||
      lastName !== user.lastName ||
      cellphone !== user.phoneNumber
    )
      return true;
    return false;
  }, [firstName, lastName, cellphone, email, user]);

  const [isCellphoneValid, setIsCellphoneValid] = useState<boolean>(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);

  const phoneInstance = Phone.PhoneNumberUtil.getInstance();

  const classes = useStyles();

  useEffect(() => {
    if (user === undefined) {
      return;
    }
    setEmail(user.email);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setCellphone(user.phoneNumber.replace(" ", ""));
  }, [user]);

  const onUpdate = () => {
    if (!isCellphoneValid || !isFirstNameValid || !isLastNameValid) {
      return;
    }
    const newDetails = {
      ...user,
      firstName,
      lastName,
      phoneNumber: cellphone.replace(" ", ""),
    };
    onUpdateInformation(newDetails);
  };

  const onCellNumberChange = (value: any, country: any) => {
    const numberStr = value.replace(" ", "");
    setCellphone(numberStr);
    try {
      const number = phoneInstance.parseAndKeepRawInput(
        numberStr,
        country.countryCode
      );
      setIsCellphoneValid(
        phoneInstance.isValidNumberForRegion(number, country.countryCode)
      );
    } catch (err) {
      // prevent exception from crashing app
    }
  };

  const onFirstNameChange = (event: any) => {
    const value = event.target.value;
    setIsFirstNameValid(value.length > 0);
    setFirstName(value);
  };

  const onLastNameChange = (event: any) => {
    const value = event.target.value;
    setIsLastNameValid(value.length > 0);
    setLastName(value);
  };

  return (
    <>
      <Typography variant="h6">{t("settings.user.header")}</Typography>

      <Grid container>
        <Grid item xs={12} md={3} lg={2}>
          <p>{t("settings.user.email")}</p>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <FormControl fullWidth={true}>
            <TextField
              placeholder={t("settings.user.email")}
              value={email}
              disabled={true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={7}></Grid>

        <Grid item xs={12} md={3} lg={2}>
          <p>{t("settings.user.firstname")}</p>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <FormControl fullWidth={true}>
            <TextField
              placeholder={t("settings.user.firstname")}
              value={firstName}
              required={true}
              onChange={onFirstNameChange}
            />
          </FormControl>
          {!isFirstNameValid && (
            <Fragment>
              <span className={classes.error}>
                {t("settings.user.requiredfield", {
                  fieldname: t("generic.firstname"),
                })}
              </span>
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={7}></Grid>

        <Grid item xs={12} md={3} lg={2}>
          <p>{t("settings.user.lastname")}</p>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <FormControl fullWidth={true}>
            <TextField
              placeholder={t("settings.user.lastname")}
              value={lastName}
              required={true}
              onChange={onLastNameChange}
            />
          </FormControl>
          {!isLastNameValid && (
            <Fragment>
              <span className={classes.error}>
                {t("settings.user.requiredfield", {
                  fieldname: t("generic.lastname"),
                })}
              </span>
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={7}></Grid>

        <Grid item xs={12} md={3} lg={2}>
          <p>{t("settings.user.cellphone")}</p>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <FormControl fullWidth={true}>
            <MuiPhoneInput
              defaultCountry={"nl"}
              value={cellphone}
              onChange={onCellNumberChange}
            />
          </FormControl>
          {!isCellphoneValid && (
            <Fragment>
              <span className={classes.error}>
                {t("settings.user.numbererror")}
              </span>
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={7}></Grid>

        <Grid item xs={12} className={classes.mt4}>
          <Button
            color="primary"
            variant="contained"
            onClick={onUpdate}
            disabled={!userChnaged}
          >
            {t("settings.user.update")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default UserSettings;
