import { isMobile } from "react-device-detect";

const chartSettings = {
  title: {
    text: "",
  },
  chart: {
    type: "areaspline",
  },
  credits: {
    enabled: false,
  },
  xAxis: {},
  yAxis: {
    title: {
      text: "%",
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  plotOptions: {
    series: {
      turboThreshold: 0,
      marker: {
        radius: 2,
      },
      cursor: "pointer",
      point: {
        events: {},
      },
      pointPlacement: "on",
      lineWidth: 1,
      events: {
        legendItemClick: function (e: any) {
          e.preventDefault();
        },
      },
    },
    areaspline: {
      fillOpacity: 1,
    },
    spline: {
      fillOpacity: 1,
    },
    areasplinerange: {
      marker: {
        enabled: false,
      },
    },
  },
  exporting: {
    enabled: !isMobile,
    buttons: {
      contextButton: {
        menuItems: [
          "printChart",
          "separator",
          "downloadPNG",
          "downloadJPEG",
          "downloadPDF",
          "downloadSVG",
          "separator",
          "downloadCSV",
          "downloadXLS",
        ],
      },
    },
  },
  series: [],
};

export const BenchmarkColors = {
  RedSpace: "#eb1f26",
  RedLine: "#8A0808",
  GreenSpace: "#58b95b",
  GreenLine: "#088A08",
  GreySpace: "#d9dae5",
  GreyLine: "#6E7CA3",
  BlueLine: "#0404B4",
  WhiteSpace: "#FFFFFF",
};

export default chartSettings;
