import { Link } from "@material-ui/core";
import {
  BoldParagraph,
  NumberedList,
  NumberedParagraph,
  Paragraph
} from "./../../components/Page";

export function TermsAndConditions() {
  const BP = BoldParagraph;
  const P = Paragraph;
  const NP = NumberedParagraph;

  return (
    <>
      <BP>
        YOUR USE OF iMOBA, ITS MOBILE APPLICATIONS AND OTHER SERVICES
        CONSTITUTES YOUR AGREEMENT TO BE BOUND BY THESE TERMS AND CONDITIONS OF
        USE.
      </BP>
      <P>
        iMoba, its mobile applications and other services, including all of its
        features and content (hereinafter referred to as "iMoba") is a service
        made available by MOBA or its affiliates ("MOBA") and all content and
        information provided on or through iMoba, ("Content") may be used solely
        under the following terms and conditions ("Terms of Use").
      </P>
      <NumberedList>
        <NP>
          1. iMoba Limited License. As a user of iMoba
          <NumberedList>
            <NP>
              1.1. you are granted a nonexclusive, nontransferable, revocable,
              limited license to access and use iMoba, its mobile applications
              and other services and Content in accordance with these Terms of
              Use. MOBA may terminate this license at any time for any reason.
            </NP>
            <NP>
              1.2. you grant to MOBA an exclusive, transferable, irrevocable
              limited license to use all Data, gathered by and/or uploaded in
              iMoba, pertaining to the functioning, maintenance and performance
              of your equipment for the purpose of customer care, product
              analysis, product improvement and product development.
            </NP>
            <NP>
              1.3. you grant to MOBA an exclusive, transferable, irrevocable
              limited license to use all Data, gathered by and/or uploaded in
              iMoba, pertaining to the functioning, maintenance and performance
              of your equipment – provided such data is made irreversibly
              anonymous by iMoba - for the purpose of benchmarking of all such
              anonymous aggregated Data for public or user information and other
              commercial purposes.
            </NP>
          </NumberedList>
        </NP>
        <NP>
          2. Limitations on Use; Third Party Communications and Web Sites.
          <NumberedList>
            <NP>
              2.1. Limitations on Use. The Content on iMoba, is for your
              business use only and not for further commercial exploitation. You
              may not decompile, reverse engineer, disassemble, rent, lease,
              loan, sell, sublicense, or create derivative works from iMoba or
              the Content, nor may you use any network monitoring or discovery
              software to determine the site architecture or extract information
              about usage, individual identities or users. You must retain all
              copyright and other proprietary notices contained on the materials
              delivered herein. The content of iMoba – other than Data
              pertaining to your own equipment - cannot be used, posted, sold,
              transmitted, distributed, modified or transferred for public or
              commercial purposes, without the prior written permission of MOBA.
              You may not use any robot, spider, other automatic software or
              device, or manual process to monitor or copy iMoba, its mobile
              applications and other services or the Content without MOBA's
              prior written permission. You may not use iMoba to transmit any
              false, misleading, fraudulent or illegal communications. You may
              not copy, modify, reproduce, republish, distribute, display, or
              transmit for commercial, non-profit or public purposes all or any
              portion of iMoba, its mobile applications and other services,
              except to the extent permitted above. Any unauthorized use of
              iMoba or its Content is prohibited.
            </NP>
            <NP>
              2.2. Third Party Communications and Information, Web Sites and
              Content. MOBA disclaims all liability for any Third Party
              Communications or Information you may receive or any actions you
              may take or refrain from taking as a result of any Third Party
              Communications. You are solely responsible for assessing and
              verifying the identity and trustworthiness of the source and
              content of any Third Party Communications or Information. MOBA
              assumes no responsibility for verifying, and makes no
              representations or warranties regarding, the identity or
              trustworthiness of the source or content of any Third Party
              Information. As used herein, "Third Party Information" means any
              information directed to you from any third party directly or
              indirectly in connection with iMoba, its mobile applications and
              other services. MOBA makes no representations whatsoever about any
              Third Party Information. In addition, a link to a non-MOBA web
              site does not constitute an endorsement of such web site by MOBA
              or an acceptance of responsibility by MOBA for the content, or the
              use, of such web site. Third party content (including, without
              limitation, Postings) may appear on iMoba or may be accessible via
              links from iMoba. MOBA shall not be responsible for and assumes no
              liability for any infringement, mistakes, omissions, falsehood in
              the Information or any other form of content contained in any
              third party content appearing on this Web Site. Further,
              information and opinions provided by employees and agents of MOBA
              in Interactive Areas are not necessarily endorsed by MOBA and do
              not necessarily represent the beliefs and opinions of MOBA's Board
              of Directors.
            </NP>
          </NumberedList>
        </NP>
        <NP>
          3. Not Business Advice. Content is not intended to and does not
          constitute Business advice and no consultant-client relationship is
          formed. The accuracy, completeness, adequacy or currency of the
          Content is not warranted or guaranteed. Your use of Content on iMoba
          or materials linked from iMoba is at your own risk.
        </NP>
        <NP>
          4. Access to iMoba.
          <NumberedList>
            <NP>
              4.1. On acceptance of these Terms of Use, MOBA is granted
              administration rights, solely executed by trusted and validated
              employees of the Moba Customer Care team, to set up your iMoba
              account. No other people except for this trusted team, are
              authorized and able to make modifications to your account.
              <P>
                Next to trusted members of this Moba Customer Care team, the
                Owner of the iMoba account is given access to its owner-account
                on iMoba. MOBA is not responsible nor liable for the
                administration of this owner account. Owner will act as
                Administrator or may appoint a different person as Administrator
                who will be responsible for the use, granting or revoking of
                access-rights to user accounts related to the company of the
                Owner. The Administrator will be authorized to set up the user
                accounts and choose the users relevant modules and features and
                add them to the respective user-accounts. By doing so Owner will
                become fully responsible and liable for the costs and fees
                incurred by the choices made by himself and/or his appointed
                Administrator.
              </P>
              <P>
                Upon written request by an Owner, the administrative access
                rights of the Moba Customer Care team to the account of the
                Owner and all related user accounts can be deactivated. Moba
                advises not to do so, since all remote help and assistance will
                be fully blocked and can only be activated again by the
                Administrator following a specific procedure in the account
                settings.
              </P>
            </NP>
            <NP>
              4.2. All iMoba modules have a 3 month free trial in case of
              subscriptions for existing equipment. With new machines, 1 year of
              iMoba Performance Pro is included unless a longer period is agreed
              and stated in the order confirmation. After any free-of-charge
              period, access to iMoba will automatically be prolonged via a
              yearly subscription-fee. The automatic renewal of the yearly
              subscription can be de-activated at any given moment by the
              Administrator. In case of an ended subscription, access to the
              Performance Lite module (main dashboard only) remains freely
              accessible for unlimited period of time.
            </NP>
          </NumberedList>
        </NP>
        <NP>
          5. Data and accessibility
          <NumberedList>
            <NP>
              5.1 Encryption for accessing information. All data transferred
              over internet will be encrypted to latest technical standards.
              Access to the iMoba applications is secured via a two-factor
              authentication procedure using a separate and personal
              Authenticator Application. Owner will be solely responsible for
              careful treatment of login and authenticator information and MOBA
              cannot and will not accept any liability for loss of Data or
              access to Data due to loss or damage to login information to be
              administrated by Owner or his appointed Administrator.
            </NP>
            <NP>
              5.2 Encryption for benchmarking and service related matters. All
              data relating to origin or destination of eggs will be anonymized
              via additional encryption. This means that this information is not
              only transported in an encrypted way, but also stored in an
              encrypted way. Decryption is technically only possible for Owner,
              Administrator and employees granted access by the Administrator as
              well as -if permitted- Moba’s trusted Customer Care team in case
              of need for assistance. In this way this information is anonymized
              for any other purpose such as benchmarking or other service
              related purposes.
            </NP>
            <NP>
              5.3 Data storage. All production and performance related data,
              both encrypted anonymous data as well as private data is kept in a
              database for at least 3 full calendar years. When data is older
              than 3 calendar years, it will be deleted automatically.{" "}
            </NP>
          </NumberedList>
        </NP>
        <NP>
          6. Intellectual Property Rights.
          <NumberedList>
            <NP>
              6.1 Except as expressly provided in these Terms of Use, nothing
              contained herein shall be construed as conferring on you or any
              third party any license or right, by implication, estoppel or
              otherwise, under any law (whether common law or statutory law),
              rule or regulation including, without limitation those related to
              copyright or other intellectual property rights. You acknowledge
              and agree that the Content and IMoba are protected by copyrights,
              trademarks, service marks, patents or other proprietary rights and
              laws. The trademarks, logos, and service marks (collectively the
              "Marks") appearing on iMoba are registered and unregistered Marks
              of MOBA and others. Use of iMoba and the information contained in
              no way constitutes the granting by MOBA of any license or right to
              use any Marks displayed on this Web Site. You are strictly
              prohibited from using the Marks displayed on iMoba or any other
              content on this Web Site, except as provided in these Legal
              Notices.
            </NP>
            <NP>
              6.2 IMoba contains interactive areas which can include, without
              limitation, any blogs, comment area’s, wikis, bulletin boards,
              discussion boards, chat rooms, email forums, and question and
              answer features (the "Interactive Areas"). You grant to MOBA an
              irrevocable, non-exclusive, royalty-free, perpetual, worldwide
              license to use, authorize use of and have used on its behalf any
              ideas, expression of ideas, text, graphics, messages, blogs,
              links, data, information and other materials you submit
              (collectively, "Postings") to this Web Site. Said license is
              without restrictions of any kind and without any payment due from
              MOBA to you or permission or notification, to you or any third
              party. The license includes, the right to make, use, sell,
              reproduce, publish, modify, adapt, prepare derivative works from,
              combine with other works, translate, distribute, display, perform
              and sublicense Postings in any form, medium, or technology now
              known or hereafter developed.
            </NP>
            <NP>
              6.3. You certify and warrant that the Postings: (i) are your
              original works or that the owner of such works has expressly
              granted to MOBA a perpetual worldwide royalty-free irrevocable,
              non-exclusive license for said works with all of the rights
              granted by you in section 4.2 of these Terms of Use and (ii) do
              not violate and will not violate the rights of any third party
              including any right of publicity, right of privacy, copyright,
              patent or other intellectual property right or any proprietary
              right.
            </NP>
            <NP>
              6.4. You acknowledge and agree that your submitting Postings to
              iMoba does not create any new or alter any existing relationship
              between you and MOBA.
            </NP>
            <NP>
              6.5. By submitting Postings to this Web Site, you acknowledge and
              agree that MOBA may create on its own ideas that may be, or may
              obtain submissions that may be, similar or identical to Postings
              you submit. You agree that you shall have no recourse against MOBA
              for any alleged or actual infringement or misappropriation of any
              proprietary or other right in the Postings you provide to MOBA.
            </NP>
          </NumberedList>
        </NP>
        <NP>
          7. Linking to the iMoba Web Site. You may provide links to the iMoba
          Web Site, provided that (a) you do not remove or obscure, by framing
          or otherwise, any portion of the iMoba Web Site, including any
          advertisements, terms of use, copyright notice, and other notices on
          the iMoba Web Site, (b) you immediately deactivate and discontinue
          providing links to the iMoba Web Site if requested by MOBA, and (c)
          MOBA may deactivate any link(s) at its discretion.
        </NP>
        <NP>
          8. No Solicitation. You shall not distribute on or through iMoba any
          Postings containing any advertising, promotion, solicitation for
          goods, services or funds or solicitation for others to become members
          of any enterprise or organization without the express prior written
          permission of MOBA. Notwithstanding the foregoing, in any interactive
          areas of this Web Site, where appropriate you a) may list along with
          your name, address and email address, your own web site's URL and b)
          may recommend third party web sites, goods or services so long as you
          have no financial interest in and receive no direct or indirect
          benefit from such recommended web site, product or service or its
          recommendation. In no event may any person or entity solicit anyone
          with data retrieved from this Web Site.
        </NP>
        <NP>
          9. Registration. Certain sections of iMoba require you to register. If
          registration is requested, you agree to provide accurate and complete
          registration information. It is your responsibility to inform MOBA of
          any changes to that information. Each registration is for a single
          individual only, unless specifically designated otherwise on the
          registration page. MOBA does not permit a) anyone other than you to
          use the sections requiring registration by using your name or
          password; or b) access through a single name being made available to
          multiple users on a network or otherwise. You are responsible for
          preventing such unauthorized use. If you believe there has been
          unauthorized use, you must notify MOBA immediately via mail to
          <Link
            href="mailto:imoba@moba.net"
            variant="body2"
            style={{ marginLeft: "5px", fontSize: "1rem" }}
          >
            imoba@moba.net
          </Link>
        </NP>
        <NP>
          10. Postings in Interactive Areas of this Web Site.
          <NumberedList>
            <NP>
              10.1. Postings to be Lawful. If you participate in Interactive
              Areas on iMoba, you shall not post, publish, upload or distribute
              any Postings which are unlawful or abusive in any way, including,
              but not limited to, any Postings that are defamatory, libelous,
              invasive of privacy or publicity rights, inclusive of hate speech,
              violate the rights of any party, or give rise to liability or
              violate any local, state, federal or international law,
              intentionally or unintentionally. MOBA may delete your Postings at
              any time for any reason without permission from you.
            </NP>
            <NP>
              10.2. Postings to be in Your Name. Your Postings shall be
              accompanied by your real name and shall not be posted anonymously.
              Participants in Interactive Areas shall not misrepresent their
              identity or their affiliation with any person or entity.
            </NP>
            <NP>
              10.3. Postings shall not contain protected health information. You
              are strictly prohibited from submitting Postings that are
              considered protected health information.
            </NP>
            <NP>
              10.4. No Monitoring of Postings. MOBA has no obligation to monitor
              or screen Postings and is not responsible for the content in such
              Postings or any content linked to or from such Postings. MOBA
              however reserves the right, in its sole discretion, to monitor
              Interactive Areas, screen Postings, edit Postings, cause Postings
              not to be posted, published, uploaded or distributed, and remove
              Postings, at any time and for any reason or no reason.
            </NP>
            <NP>
              10.5. Commercial Use only of Interactive Areas. Interactive Areas
              are provided solely for commercial use in your own business. Any
              unauthorized use outside this scope of the Interactive Areas of
              this Web Site, its Content, or Postings is expressly prohibited.
            </NP>
          </NumberedList>
        </NP>
        <NP>
          11. Errors and Corrections. MOBA does not represent or warrant that
          iMoba or the Content or Postings will always be error-free, free of
          viruses or other harmful components, or that defects will be corrected
          or that it will always be accessible. MOBA does not warrant or
          represent that the Content or Postings available on or through iMoba
          will be correct, accurate, timely, or otherwise always reliable. MOBA
          may make improvements and/or changes to its features, functionality or
          Content or Postings at any time.
        </NP>
        <NP>
          12. Assumption of Risk. You assume all liability for any claims, suits
          or grievances filed against you, including all damages related to your
          participation in any of the Interactive Areas.
        </NP>
        <NP>
          13. Disclaimer. iMoba, its mobile applications and other services, the
          interactive areas, the content, and postings are provided on an "as
          is, as available" basis. MOBA expressly disclaims all warranties,
          including the warranties of merchantability, fitness for a particular
          purpose and non-infringement. MOBA disclaims all responsibility for
          any loss, injury, claim, liability, fines, penalties or damage of any
          kind resulting from, arising out of or any way related to (a) any
          errors in or omissions from this web site, the interactive areas, the
          content, and the postings including, but not limited to, technical
          inaccuracies and typographical errors, (b) third party communications,
          (c) any third party web sites or content therein directly or
          indirectly accessed through links in this web site, including but not
          limited to any errors in or omissions therefrom, (d) the
          unavailability of this web site, the interactive areas, the content,
          the postings, or any portion thereof, (e) your use of this web site,
          the interactive areas, the content, or the postings, (f) your use of
          any equipment or software in connection with this web site, the
          interactive areas, the content, or the postings or (g) the use of the
          Benchmark Information for commercial purposes infringing Anti Trust
          principles. MOBA also assumes no responsibility and shall not be
          liable for any damages or viruses that may infect your computer
          equipment or other property arising from or related to access to, use
          of, or browsing in the web site or the downloading of any materials,
          data, text, images, video or audio from iMoba.
        </NP>
        <NP>
          14. Limitation of liability. MOBA shall not be liable for any loss,
          injury, claim, liability, fines, penalties or damage of any kind
          resulting from your use of this web site, the content, the postings,
          the interactive areas, any facts or opinions appearing on or through
          any of the interactive areas, or any third party communications. MOBA
          shall not be liable for any special, direct, indirect, incidental,
          punitive or consequential damages of any kind whatsoever (including,
          without limitation, attorneys' fees) in any way due to, resulting
          from, or arising in connection with the use of or inability to use
          this Web Site, the interactive areas, the content, the postings, or
          any third party communications or aggregated Information. To the
          extent the foregoing limitation of liability is prohibited or fails of
          its essential purpose, MOBA's sole obligation to you for damages shall
          be limited to the amount, equal to the yearly service fee paid by user
          for iMoba-services in the ongoing calendar year.
        </NP>
        <NP>
          15. Indemnification. You agree to indemnify, defend and hold MOBA, its
          officers, directors, employees, agents, licensors, suppliers and any
          third party information MOBAs to iMoba harmless from and against all
          claims, losses, expenses, punitive or consequential damages and costs,
          including reasonable attorneys' fees, resulting from any violation of
          these Terms of Use by you or arising from or related to any Postings
          uploaded or submitted by you.
        </NP>
        <NP>
          16. Third Party Rights. The provisions of paragraphs 11(Disclaimer),
          12(Limitation of Liability), and 13 (Indemnification) are for the
          benefit of MOBA and its officers, directors, employees, agents,
          licensors, suppliers, and any third party information MOBAs to this
          Web Site. Each of these individuals or entities shall have the right
          to assert and enforce those provisions directly against you on its own
          behalf.
        </NP>
        <NP>
          17. Unlawful Activity; Termination of Access. MOBA reserves the right
          to investigate complaints or reported violations of these Terms of Use
          and to take any action MOBA deems appropriate including but not
          limited to reporting any suspected unlawful activity to law
          enforcement officials, regulators, or other third parties and
          disclosing any information necessary or appropriate to such persons or
          entities relating to user profiles, e-mail addresses, usage history,
          posted materials, IP addresses and traffic information. MOBA may
          discontinue any party’s participation in any of the Interactive Areas
          at any time for any reason or no reason.
        </NP>
        <NP>
          18. Remedies for Violations. MOBA reserves the right to seek all
          remedies available at law and in equity for violations of these Terms
          of Use, including but not limited to the right to block access from a
          particular internet address to iMoba and any other MOBA web sites and
          their features.
        </NP>
        <NP>
          19. Governing Law and Jurisdiction. The Terms of Use are governed by
          and construed in accordance with the laws of the Netherlands and any
          action arising out of or relating to these terms shall be filed only
          in the District Court of Arnhem, Gelderland, The Netherlands and you
          hereby consent and submit to the exclusive jurisdiction of this court
          for the purpose of litigating any such action.
        </NP>
        <NP>
          20. Privacy. Your use of iMoba is subject to MOBA's
          <Link
            href="#privacy"
            variant="body2"
            style={{ marginLeft: "5px", fontSize: "1rem" }}
          >
            Privacy Policy
          </Link>
          .
        </NP>
        <NP>
          21. Severability of Provisions. These Terms of Use incorporate by
          reference any notices contained on this Web Site, the Privacy Policy
          and the MOBA Services Terms and constitute the entire agreement with
          respect to access to and use of this Web Site, the Interactive Areas,
          and the Content and Postings. If any provision of these Terms of Use
          is unlawful, void or unenforceable, or conflicts with the MOBA
          Services Terms then that provision shall be deemed severable from the
          remaining provisions and shall not affect their validity and
          enforceability.
        </NP>
        <NP>
          22. Notwithstanding anything to the contrary in these Terms of Use, if
          you have a separate signed written agreement with MOBA that applies to
          your use of any of MOBA's Content, that agreement constitutes the
          entire agreement between you and MOBA with respect to the affected
          Content subject thereto (the "Otherwise Covered Content"), and these
          Terms of Use shall be treated as having no force or effect with
          respect to the Otherwise Covered Content.
        </NP>
        <NP>
          23. Modifications to Terms of Use. MOBA reserves the right to change
          these Terms of Use at any time. Updated versions of the Terms of Use
          will appear on iMoba and are effective immediately The updated valid
          Terms of Use will be downloadable from the iMoba Web Site. On request
          the updated valid version of the Terms of Use will be sent by email to
          the requesting users. You are responsible for regularly reviewing the
          Terms of Use. Continued use of iMoba after any such changes
          constitutes your consent to such changes.
        </NP>
      </NumberedList>
    </>
  );
}
