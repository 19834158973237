import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { SettingsCard } from "../Common";
import Select from "@material-ui/core/Select";
import { SelectItem } from "../../UI/Form/Select";
import { FormControl, InputLabel } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { listTimeZones } from "timezone-support";
import { MachineUnitSettings } from "../../../pages/management-settings/Common";
import { determineEggUnit } from "../../../utility/EggUtility";

const useStyles = makeStyles((theme: any) => ({
  contentWrapper: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  formControl: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  select: {
    minWidth: theme.spacing(12),
  },
  autoComplete: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: 350,
      marginLeft: theme.spacing(6),
    },
  },
}));

const EggTypes = ["Eggs", "Cases", "Dozens"];

export type UnitsPresentationProps = {
  settings: MachineUnitSettings;
  onSettingsChange: (settings: MachineUnitSettings) => void;
};

export default function UnitsPresentation({
  settings,
  onSettingsChange,
}: UnitsPresentationProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const Timezones = listTimeZones();

  const handleChangeEggType = (value: string) => {
    onSettingsChange({
      ...settings,
      eggUnit: determineEggUnit(value),
    });
  };

  const handleChangeTimezone = (_: any, value: any, reason: any) => {
    onSettingsChange({
      ...settings,
      timezone: value,
    });
  };

  return (
    <>
      <SettingsCard title={t("managementSettings.unitsTitle")}>
        <div className={classes.contentWrapper}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              {t("settings.setUnits.eggUnit")}
            </InputLabel>
            <Select
              value={settings.eggUnit}
              classes={{
                select: classes.select,
              }}
              onChange={(
                event: React.ChangeEvent<{
                  name?: string;
                  value: unknown;
                }>
              ) => {
                handleChangeEggType(event.target.value as string);
              }}
            >
              {EggTypes.map((e) => (
                <SelectItem key={e} value={e}>
                  {e}
                </SelectItem>
              ))}
            </Select>
          </FormControl>
          <Autocomplete
            className={classes.autoComplete}
            value={settings.timezone}
            onChange={handleChangeTimezone}
            options={Timezones}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("settings.setUnits.timeZone")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>
      </SettingsCard>
    </>
  );
}
