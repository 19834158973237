import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { Selectbox } from "../../Common";
import { MachineIdFilter } from "../../Types";

export type MyMachinesApiResponse = {
  results: Array<MyMachine>;
};

export type MyMachine = {
  machineId: MachineId;
  machineName: string;
};

export default function MachineMeasureMyMachinesSelect({
  allMyMachines,
  selectedMyMachines,
  onMachinesSelectionChange,
}: {
  allMyMachines: Array<MachineIdFilter>;
  selectedMyMachines: Array<MachineIdFilter>;
  onMachinesSelectionChange: (newMachines: Array<MachineIdFilter>) => void;
}) {
  const handleChange = (checked: boolean, value: string) => {
    let newSelectedMyMachines = checked
      ? [...selectedMyMachines, value]
      : selectedMyMachines.filter((x) => x.toString() !== value);

    onMachinesSelectionChange(newSelectedMyMachines);
  };

  return (
    <MachineMeasureMyMachinesSelectPresentation
      allMyMachines={allMyMachines}
      mySelectedMachines={selectedMyMachines}
      onChange={handleChange}
    />
  );
}

function MachineMeasureMyMachinesSelectPresentation({
  allMyMachines,
  mySelectedMachines,
  onChange,
}: {
  allMyMachines: Array<MachineIdFilter>;
  mySelectedMachines: Array<MachineIdFilter>;
  onChange: (checked: boolean, value: string) => void;
}) {
  const { t } = useTranslation();

  return allMyMachines.length > 0 ? (
    <>
      {allMyMachines &&
        allMyMachines.map((m) => {
          return (
            <div key={m.toString()}>
              <Selectbox
                name={
                  m.toString() === "AVG"
                    ? t("benchmark.filters.myMachinesAverage")
                    : m.toString()
                }
                isChecked={
                  mySelectedMachines.findIndex(
                    (x) => x.toString() === m.toString()
                  ) !== -1
                }
                onChange={onChange}
                value={m.toString()}
              />
            </div>
          );
        })}
    </>
  ) : (
    <Typography variant="caption">
      {t("benchmark.filters.noMachineTypes")}
    </Typography>
  );
}
