import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  acceptedTermsAndConditions: boolean;
  onAccept: (newValue: boolean) => void;
}

export function AcceptTermsAndConditionsCheckBox({
  acceptedTermsAndConditions,
  onAccept,
}: Props) {
  const { t } = useTranslation();

  return (
    <Box mt={1}>
      <FormControlLabel
        control={
          <Checkbox
            checked={acceptedTermsAndConditions}
            onChange={() => onAccept(!acceptedTermsAndConditions)}
            name="accept-terms-and-conditions-checkbox"
            color="primary"
          />
        }
        label={t("termsAndConditions.acceptCheckboxLabel")}
      />
    </Box>
  );
}
