import { IHttpClient } from "../IHttpClient";
import { IOwnerService } from "./IOwnerService";

export class OwnerService implements IOwnerService {
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }

  getOwnedMachines(): Promise<any> {
    return this.get(`v1/owner`);
  }

  getAdminMachines(): Promise<any> {
    return this.get(`v1/owner/admin`);
  }

  approveMachine(
    machineId: MachineId,
    approvalVersion: number,
    responsibleEmail: string
  ): Promise<any> {
    return this.post(`v1/owner/approve-machine`, {
      machineId: machineId,
      approvalVersion: approvalVersion,
      responsibleEmail: responsibleEmail,
    });
  }

  assignAdmin(email: string, machineId: MachineId, responsibleEmail: string) {
    return this.post("v1/owner/assign-admin", {
      email: email,
      machineId: machineId,
      responsibleEmail: responsibleEmail,
    });
  }

  revokeAdmin(email: string, machineId: MachineId, responsibleEmail: string) {
    return this.post("v1/owner/revoke-admin", {
      email: email,
      machineId: machineId,
      responsibleEmail: responsibleEmail,
    });
  }

  sendMissingMachineMail(machineIds: Array<number>, emailAddressToCc: string) {
    return this.post("v1/owner/missing-machine", {
      machineIds: machineIds,
      emailAddressToCarbonCopy: emailAddressToCc,
    });
  }

  updateMachineName(
    name: string,
    machineId: MachineId,
    responsibleEmail: string
  ) {
    return this.put(`v1/owner/machines/${machineId}`, {
      machineId: machineId,
      machineName: name,
      responsibleEmail: responsibleEmail,
    });
  }

  getOwnerEventLogs(machineId: MachineId): Promise<any> {
    return this.get(`v1/owner/event-logs/${machineId}`);
  }

  private get(url: string): Promise<any> {
    return this.httpclient.get(url);
  }

  private post(url: string, body: any): Promise<any> {
    return this.httpclient.post(url, body);
  }

  private put(url: string, body: any): Promise<any> {
    return this.httpclient.put(url, body);
  }
}
