import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "../../UI/Button/LoadingButton";
import { AcceptTermsAndConditionsCheckBox } from "./AcceptTermsAndConditionsCheckBox";

interface Props {
  acceptedTermsAndConditions: boolean;
  isLoading: boolean;
  onAcceptCheckboxChecked: (newValue: boolean) => void;
  onAcceptButtonClicked: () => void;
}

export function AcceptTermsAndConditionsPresentation({
  acceptedTermsAndConditions,
  isLoading,
  onAcceptCheckboxChecked,
  onAcceptButtonClicked,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <AcceptTermsAndConditionsCheckBox
        acceptedTermsAndConditions={acceptedTermsAndConditions}
        onAccept={onAcceptCheckboxChecked}
      />
      <Box mt={2}>
        <LoadingButton
          disabled={!acceptedTermsAndConditions || isLoading}
          isLoading={isLoading}
          title={t("termsAndConditions.acceptButtonLabel")}
          onClick={onAcceptButtonClicked}
        />
      </Box>
    </>
  );
}
