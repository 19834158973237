import { FormControl, makeStyles } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import React from "react";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { SelectItem } from "../../UI/Form/Select";

const useStyles = makeStyles((theme: any) => ({
  select: {
    minWidth: theme.spacing(8),
  },
}));

const OffgradeLabelSelectValues: Array<{ [key: string]: any }> = [
  { label: "Leaker", value: "Leaker" },
  { label: "Blood", value: "Blood" },
  { label: "Crack", value: "Crack" },
  { label: "Dirt", value: "Dirt" },
  { label: "Other", value: "Other" },
  { label: "None", value: "None" },
];

export function OffgradeLabelSelect({
  selectedOffgradeLabel,
  onOffgradeLabelChange,
}: {
  selectedOffgradeLabel: string;
  onOffgradeLabelChange: (newOffgradeLabel: string) => void;
}) {
  const classes = useStyles();
  const isMobileSize = useIsMobileSize('xs');

  return (
    <FormControl fullWidth={isMobileSize}>
      <Select
        value={selectedOffgradeLabel}
        onChange={(
          event: React.ChangeEvent<{
            name?: string;
            value: unknown;
          }>
        ) => {
          onOffgradeLabelChange(event.target.value as string);
        }}
        classes={{
          select: classes.select,
        }}
      >
        {OffgradeLabelSelectValues.map((e) => (
          <SelectItem key={e.value} value={e.value}>
            {e.label}
          </SelectItem>
        ))}
      </Select>
    </FormControl>
  );
}
