import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { usePerformanceProService } from "../../api/ServiceContext";
import {
  usePerformanceProFilterOptions,
  usePerformanceProGroupActions,
  usePerformanceProProductionTime,
} from "../../store/GlobalContext";
import { getFirstOfPeriod } from "../../utility/DateUtility";
import {
  DefaultErrorHandler,
  GetCancellationToken,
} from "../../utility/HttpServiceUtility";
import { ConvertDatePeriod, ProductionTimeWindow } from "./performanceProHooks";

/**
 * Fetch data required for the initial load of the Performance Pro Module and persist it it Global Storage.
 * @param MachineId
 */
export function useUpdatePerformanceProInitialState({
  machineId,
}: {
  machineId: MachineId;
}) {
  const cancellationToken = GetCancellationToken();
  const service = usePerformanceProService(cancellationToken);
  const options = usePerformanceProFilterOptions();
  const location = useLocation();
  const locationsToIgnore = [
    "/performance-pro/overview",
    "/performance-pro/report",
  ];

  const { updateProductionTimeWindow } = usePerformanceProGroupActions();

  const periodType = useMemo(() => {
    return ConvertDatePeriod(options.selectedDatePeriod);
  }, [options.selectedDatePeriod]);

  const fromDate = useMemo(() => {
    return getFirstOfPeriod(options.selectedDate, options.selectedDatePeriod);
  }, [options.selectedDate, options.selectedDatePeriod]);

  /**
   * Only fetch ProductionTime when the PeriodType equals "Day".
   * The reason for this is that ProductionTime is used to remove (trim) the hours with empty data in Performance Pro Charts.
   * This trimming of hours is only relevant when the PeriodType is "Day", as the other views do not display any hours.
   */
  useEffect(() => {
    if (locationsToIgnore.includes(location.pathname)) return;

    updateProductionTimeWindow(null);
    if (periodType === "Day") {
      service
        .getProductionTimeWindow({
          machineId: machineId,
          date: fromDate,
        })
        .then((response: IApiResponse<ProductionTimeWindow>) => {
          updateProductionTimeWindow(response.data);
        })
        .catch(DefaultErrorHandler);
    }

    return () => {
      cancellationToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, machineId, options.selectedDate, options.selectedDatePeriod]);
}

/**
 * Checks whether all Performance Pro Initial State objects are being loaded.
 */
export function useAwaitingPerformanceProInitialState() {
  const options = usePerformanceProFilterOptions();
  const productionTimeWindow = usePerformanceProProductionTime();
  const periodType = ConvertDatePeriod(options.selectedDatePeriod);

  // ProductionTime will only be fetched when the PeriodType is "Day"
  return periodType !== "Day"
    ? false
    : !productionTimeWindow?.productionEndTime ||
        !productionTimeWindow?.productionStartTime;
}

/**
 * Returns the ProductionTime when PeriodType equals "Day", null otherwise.
 * This is because ProductionTime should be ignored when other PeriodTypes are selected.
 */
export function useProductionTime() {
  const options = usePerformanceProFilterOptions();
  const productionTimeGlobalState = usePerformanceProProductionTime();
  const periodType = ConvertDatePeriod(options.selectedDatePeriod);

  return periodType === "Day" ? productionTimeGlobalState : null;
}
