import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import solidgauge from "highcharts/modules/solid-gauge";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../colors";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { FormatSelectedEggUnit } from "../../../utility/NumberFormatUtility";
import EggDisplayUnitCardWrapper from "../Wrappers/EggDisplayUnitCardWrapper";

// Enable special charts
HC_more(Highcharts);
solidgauge(Highcharts);

export type ActualSpeedApiResponse = {
  data: ActualSpeedApiData;
};

export type ActualSpeedApiData = {
  eggsPerHour: number;
  machineCapacity: number;
};

export type AcutalSpeedWidgetPresentationProps = {
  awaitingResponse: boolean;
  calculatedEggsPerHour: number;
  calculatedMachineCapacity: number;
  displayUnit: string;
  onMenuItemClicked: (eggUnit: string) => void;
};

/**
 * A simple widget that fetches and displays the current sorting speed of the machine, measured in the number of eggs of the last 60 seconds
 */
export default function ActualSpeedWidgetPresentation({
  awaitingResponse,
  calculatedEggsPerHour,
  calculatedMachineCapacity,
  displayUnit,
  onMenuItemClicked,
}: AcutalSpeedWidgetPresentationProps) {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  const newChartSettings = useMemo(() => {
    return {
      chart: {
        type: "solidgauge",
        height: 230,
      },
      title: null,
      pane: {
        center: ["50%", isMobileSize ? "50%" : "85%"],
        size: isMobileSize ? "100%" : "140%",
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: colors.grey93,
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc",
        },
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      colors: [colors.mobaBlue],
      yAxis: {
        min: 0,
        max: calculatedMachineCapacity,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        tickPositioner: function () {
          return [0, calculatedMachineCapacity];
        },
        labels: {
          y: isMobileSize ? 20 : 24,
          style: {
            fontSize: "20px",
            color: colors.textBlue,
          },
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: isMobileSize ? -35 : 0,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          data: [calculatedEggsPerHour],
          dataLabels: {
            format:
              '<div style="text-align:center;">' +
              '<span style="font-size:16px;color:' +
              colors.textBlue +
              '">' +
              FormatSelectedEggUnit(displayUnit, calculatedEggsPerHour) +
              " " +
              t(displayUnit).toLowerCase() +
              "</span><br/>" +
              '<span style="font-size:16px;color:' +
              colors.textBlue +
              '">' +
              t("widgets.actualSpeed.perHour") +
              "</span>" +
              "</div>",
          },
        },
      ],
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
    };
  }, [calculatedEggsPerHour, calculatedMachineCapacity, displayUnit, t]);

  const chartComponent: any = React.createRef();

  return (
    <EggDisplayUnitCardWrapper
      title={t("widgets.actualSpeed.title")}
      awaitingResponse={awaitingResponse}
      disableFullscreen={true}
      onMenuItemClicked={onMenuItemClicked}
    >
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        options={newChartSettings}
      />
    </EggDisplayUnitCardWrapper>
  );
}
