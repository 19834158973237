import {
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import {
  CharacteristicsInfoLabel,
  ReportSettings,
} from "../../../../pages/management-settings/Common";
import { t } from "../../../../utility/TranslateUtility";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  selectContainer: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
  },
  select: {
    minWidth: theme.spacing(30),
  },
  labelNumber: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0),
      marginRight: theme.spacing(2),
    },
  },
}));

export function compareCharacteristicsInfoLabel(
  a: CharacteristicsInfoLabel,
  b: CharacteristicsInfoLabel
) {
  return a.labelNumber - b.labelNumber;
}

export interface CharacteristicsInfoPresentationProps {
  settings: ReportSettings;
  onSettingsChange: (settings: ReportSettings) => void;
}

export const StaticLabelOptions = [
  "None",
  "Offgrades",
  "ProductChanges",
  "FillRate",
  "Leakers",
];

export function LabelSelect({
  label,
  onLabelChange,
}: {
  label: CharacteristicsInfoLabel;
  onLabelChange: (
    currentLabel: CharacteristicsInfoLabel,
    newLabel: any
  ) => void;
}) {
  const classes = useStyles();
  return (
    <div className={classes.selectContainer}>
      <Typography className={classes.labelNumber}>
        {label.labelNumber}
      </Typography>
      <Select
        className={classes.select}
        value={label.label}
        onChange={(e) => {
          onLabelChange(label, e.target.value);
        }}
      >
        {StaticLabelOptions.map((x) => (
          <MenuItem key={x} value={x}>
            {t(`managementSettings.report.characteristicsStaticLabels.${x}`)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export function LabelTextInput({
  label,
  onLabelChange,
}: {
  label: CharacteristicsInfoLabel;
  onLabelChange: (
    currentLabel: CharacteristicsInfoLabel,
    newLabel: any
  ) => void;
}) {
  const classes = useStyles();
  return (
    <div className={classes.selectContainer}>
      <Typography className={classes.labelNumber}>
        {label.labelNumber}
      </Typography>
      <TextField
        className={classes.select}
        value={label.label}
        placeholder={t(
          "managementSettings.report.characteristicsStaticLabels.placeholder"
        )}
        onChange={(e) => {
          onLabelChange(label, e.target.value);
        }}
      />
    </div>
  );
}
