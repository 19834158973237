import React, { useCallback, useEffect, useState } from "react";
import {
  useManagementSettingsService,
  useReportService,
} from "../../../api/ServiceContext";
import { MachineUnitSettings } from "../../../pages/management-settings/Common";
import {
  calculateNumberOfEggs,
  getDisplayUnit,
} from "../../../utility/EggUtility";
import useRefresh from "../../Dashboard/useRefresh";
import TotalEggsWidgetPresentation, { TotalEggsApiResponse } from "./TotalEggs";

export type TotalEggsWidgetContainerProps = {
  machineId: MachineId;
  automaticRefresh: boolean;
};

/**
 * A simple widget that displays the daily total of eggs produced on the machine.
 * Allows for different display units to display the total number of eggs.
 */
export default function TotalEggsWidgetContainer({
  machineId,
  automaticRefresh,
}: TotalEggsWidgetContainerProps) {
  const reportService = useReportService();
  const settingsService = useManagementSettingsService();

  const [totalEggs, setTotalEggs] = useState(0);
  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [calculatedNumberOfEggs, setCalculatedNumberOfEggs] = useState(0);
  const [displayUnit, setDisplayUnit] = useState("");

  const handleMenuItemClicked = (eggUnit: string) => {
    setCalculatedNumberOfEggs(calculateNumberOfEggs(totalEggs, eggUnit));
    setDisplayUnit(getDisplayUnit(eggUnit));
  };

  const fetchData = useCallback(
    (newMachineId: MachineId) => {
      reportService
        .getTotalEggs(newMachineId)
        .then((response: TotalEggsApiResponse) => {
          setTotalEggs(response.data.numberOfEggs);
          setAwaitingResponse(false);
        });
      settingsService
        .getUnitsFromMachineTargetsSettings(machineId, new Date())
        .then((response: IApiResponse<MachineUnitSettings>) => {
          handleMenuItemClicked(response.data.eggUnit.toString());
        });
    },
    // DisplayUnit is missing in this useEffect, we can't include it here because
    // menuItemClicked also updates this property. So it is safe to omit displayUnit here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportService, settingsService, totalEggs]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return (
    <TotalEggsWidgetPresentation
      awaitingResponse={awaitingResponse}
      calculatedNumberOfEggs={calculatedNumberOfEggs}
      displayUnit={displayUnit}
      onMenuItemClicked={handleMenuItemClicked}
    />
  );
}
