import { MachineUnitSettings } from "../../../pages/management-settings/Common";
import { EggUnit } from "../../../utility/EggUnitUtility";
import { EggType } from "../../../utility/EggUtility";

export const EggTypesWithTargets: EggType[] = [
  EggType.AllEggTypes,
  EggType.Barn,
  EggType.Cage,
  EggType.FreeRange,
  EggType.Organic,
];

export type GeneralTargets = {
  productionTime: number;
  totalEggs: number;
  totalEggsEggUnit: EggUnit;
  availability: number;
};

export type EggTypeTargets = {
  eggType: EggType;
  setSpeed: number;
  setSpeedEggUnit: EggUnit;
  tableEggs: number;
  inputOffgrades: number;
  throughput: number;
  throughputEggUnit: EggUnit;
};

export type TargetsView = {
  targets: GeneralTargets;
  targetsPerEggtypes: EggTypeTargets[];
  units: MachineUnitSettings;
};

export const emptyTargets: TargetsView = {
  targets: {
    availability: 0,
    productionTime: 0,
    totalEggs: 0,
    totalEggsEggUnit: EggUnit.Eggs,
  },
  targetsPerEggtypes: [],
  units: {
    eggUnit: EggUnit.Eggs,
    timezone: "",
  },
};

export const emptyEggTypeTargets: EggTypeTargets = {
  eggType: EggType.AllEggTypes,
  inputOffgrades: 0,
  setSpeed: 0,
  setSpeedEggUnit: EggUnit.Eggs,
  tableEggs: 0,
  throughput: 0,
  throughputEggUnit: EggUnit.Eggs,
};

export function areGeneralTargetsEqual(
  a: GeneralTargets,
  b: GeneralTargets
): boolean {
  return (
    a.availability === b.availability &&
    a.productionTime === b.productionTime &&
    a.totalEggs === b.totalEggs &&
    a.totalEggsEggUnit === b.totalEggsEggUnit
  );
}

export function areEggTypeTargetsEqual(
  a: EggTypeTargets,
  b: EggTypeTargets
): boolean {
  return (
    a.eggType === b.eggType &&
    a.inputOffgrades === b.inputOffgrades &&
    a.setSpeed === b.setSpeed &&
    a.tableEggs === b.tableEggs &&
    a.throughput === b.throughput &&
    a.setSpeedEggUnit === b.setSpeedEggUnit &&
    a.throughputEggUnit === b.throughputEggUnit
  );
}
