import {
  OptionalCancellationToken,
  useServiceContextState
} from "../store/GlobalContext";
import { IAlarmService } from "./Alarms/IAlarmService";
import ILocalAlarmService from "./Alarms/ILocalAlarmService";
import { IBenchmarkService } from "./Benchmark/IBenchmarkService";
import IGlobalAnnouncementService from "./GlobalAnnouncement/IGlobalAnnouncementService";
import { IInformationService } from "./Information/IInformationSerice";
import { IMachineService } from "./Machine/IMachineService";
import { IManagementSettingsService } from "./ManagementMachine/IManagementSettingsService";
import { IDeviceRegistrationService } from "./Notifications/IDeviceRegistrationService";
import { IOwnerService } from "./Owner/IOwnerService";
import { IPerformanceProService } from "./PerformancePro/IPerformanceProService";
import { IPerformanceProProductionService } from "./PerformanceProProduction/IPerformanceProProductionService";
import { IPerformanceProReportService } from "./PerformanceProReport/IPerformanceProReportService";
import { IReportService } from "./Report/IReportService";
import { IUserActivityService } from "./Users/IUserActivityService";
import { IUsersService } from "./Users/IUsersService";

export const useReportService: (
  cancellationToken?: OptionalCancellationToken
) => IReportService = (cancellationToken?: OptionalCancellationToken) =>
    useServiceContextState(cancellationToken).report;

export const useUserService: (
  cancellationToken?: OptionalCancellationToken
) => IUsersService = (cancellationToken?: OptionalCancellationToken) =>
    useServiceContextState(cancellationToken).user;

export const useMachineService: (
  cancellationToken?: OptionalCancellationToken
) => IMachineService = (cancellationToken?: OptionalCancellationToken) =>
    useServiceContextState(cancellationToken).machine;

export const usePerformanceProProductionService: (
  cancellationToken?: OptionalCancellationToken
) => IPerformanceProProductionService = (
  cancellationToken?: OptionalCancellationToken
) => useServiceContextState(cancellationToken).performanceProProduction;

export const usePerformanceProReportService: (
  cancellationToken?: OptionalCancellationToken
) => IPerformanceProReportService = (
  cancellationToken?: OptionalCancellationToken
) => useServiceContextState(cancellationToken).performanceProReport;

export const usePerformanceProService: (
  cancellationToken?: OptionalCancellationToken
) => IPerformanceProService = (cancellationToken?: OptionalCancellationToken) =>
    useServiceContextState(cancellationToken).performancePro;

export const useBenchmarkService: (
  cancellationToken?: OptionalCancellationToken
) => IBenchmarkService = (cancellationToken?: OptionalCancellationToken) =>
    useServiceContextState(cancellationToken).benchmark;

export const useManagementSettingsService: (
  cancellationToken?: OptionalCancellationToken
) => IManagementSettingsService = (
  cancellationToken?: OptionalCancellationToken
) => useServiceContextState(cancellationToken).managementSettings;

export const useInformationService: (
  cancellationToken?: OptionalCancellationToken
) => IInformationService = (cancellationToken?: OptionalCancellationToken) =>
    useServiceContextState(cancellationToken).informationService;

export const useOwnerService: (
  cancellationToken?: OptionalCancellationToken
) => IOwnerService = (cancellationToken?: OptionalCancellationToken) =>
    useServiceContextState(cancellationToken).ownerService;

export const useAlarmService: (
  cancellationToken?: OptionalCancellationToken
) => IAlarmService = (cancellationToken?: OptionalCancellationToken) =>
    useServiceContextState(cancellationToken).alarm;

export const useLocalAlarmService: (
  cancellationToken?: OptionalCancellationToken
) => ILocalAlarmService = (cancellationToken?: OptionalCancellationToken) =>
    useServiceContextState(cancellationToken).localAlarm;

export const useUserActivityService: (
  cancellationToken?: OptionalCancellationToken
) => IUserActivityService = (cancellationToken?: OptionalCancellationToken) =>
    useServiceContextState(cancellationToken).userActivity;

export const useDeviceRegistrationService: (
  cancellationToken?: OptionalCancellationToken
) => IDeviceRegistrationService = (
  cancellationToken?: OptionalCancellationToken
) => useServiceContextState(cancellationToken).deviceRegistrationService;

export const useGlobalAnnouncementService: (
  cancellationToken?: OptionalCancellationToken
) => IGlobalAnnouncementService = (cancellationToken?: OptionalCancellationToken) =>
    useServiceContextState(cancellationToken).globalAnnouncementService;
