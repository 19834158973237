import { useAuth0 } from "@auth0/auth0-react";
import { TextField } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { colors } from "../../colors";
import { useMachineNames } from "../../globalHooks/authorizationHooks";
import {
  useMyUserState,
  useSelectedMachine,
  useSelectedMachineAction,
} from "../../store/GlobalContext";
import { t } from "../../utility/TranslateUtility";

/**
 * Gets the current page URL.
 * This is the page after the hash in the url, e.g.
 * www.app.imoba.com/#/dashboard => /dashboard
 */
function retrievePageUrl(): string {
  const locationHash = window.location.hash;
  return locationHash.substring(1, locationHash.length);
}

export type NavigationItemEntry = {
  icon: JSX.Element;
  name: string;
  link: string;
};

export type NavigationItem = {
  name: string;
  entries: NavigationItemEntry[];
};

export type DrawerMenuItemProps = {
  /**
   * These items are displayed inside the drawer
   */
  navigationItems: NavigationItem[];
  onDrawerLinkClick: () => void;
};

function DrawerMenuItems({
  navigationItems,
  onDrawerLinkClick,
}: DrawerMenuItemProps) {
  const classes = useStyles();

  return (
    <>
      {navigationItems.map((item, itemKey) => (
        <div key={itemKey}>
          <Typography variant="h6" className={classes.sectionName}>
            {item.name}
          </Typography>
          <List>
            {item.entries.map((entry, entryKey) => (
              <Link to={entry.link} key={entryKey} onClick={onDrawerLinkClick}>
                <ListItem button selected={entry.link === retrievePageUrl()}>
                  <ListItemIcon>{entry.icon}</ListItemIcon>
                  <ListItemText primary={entry.name} />
                </ListItem>
              </Link>
            ))}
          </List>
          <Divider />
        </div>
      ))}
    </>
  );
}

function UserProfileSection({
  onUserProfileSectionClick,
}: UserProfileSectionProps) {
  const classes = useStyles();

  const dispatch = useSelectedMachineAction();
  const selectedSortingMachine = useSelectedMachine();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const userState = useMyUserState();

  const machineNames = useMachineNames();

  const handleChange = (value: any) => {
    if (!value) return;
    const selectedMachineId = value.machineId;

    const foundMachine = machineNames.find(
      (x) => x.machineId === selectedMachineId
    );
    if (!foundMachine) return;

    const machineIsLocked =
      userState.userSystemWideAuthorization.lockedMachines.includes(
        selectedMachineId
      );
    const hasSystemRoles =
      userState.userSystemWideAuthorization.systemRoles &&
      userState.userSystemWideAuthorization.systemRoles.length > 0;

    if (machineIsLocked && hasSystemRoles) {
      alert(t("drawer.selectedMachineIsLockedForUserWithSystemRole"));
    }

    let machine: Machine = {
      machineId: selectedMachineId,
      machineName: foundMachine.machineName,
    };

    dispatch(machine);
  };

  return (
    <div className={classes.userProfileSection}>
      {!isLoading && isAuthenticated && (
        <div className={classes.profileNameSection}>
          <div>
            <Typography variant="body1" className={classes.userFullName}>
              {user.name}
            </Typography>
            <Link to={"/settings"} key={"settings"}>
              <Typography
                variant="subtitle2"
                className={classes.viewSettings}
                onClick={onUserProfileSectionClick}
              >
                View profile settings
              </Typography>
            </Link>
          </div>
        </div>
      )}
      {isLoading || selectedSortingMachine.machineId === 0 ? (
        <Skeleton width={200} height={50} animation="wave" />
      ) : (
        <Autocomplete
          className={classes.machineSelectAutocomplete}
          disabled={isLoading}
          value={{
            machineId: selectedSortingMachine.machineId,
            machineName: selectedSortingMachine.machineName,
          }}
          disableClearable
          disableCloseOnSelect={true}
          onChange={(_: any, value: any) => {
            handleChange(value);
          }}
          options={machineNames}
          getOptionLabel={(option: Machine) =>
            `${option.machineId ?? ''} - ${option.machineName ?? ''}`
          }
          getOptionSelected={(option: Machine, value: Machine) => {
            return option.machineId === value.machineId;
          }}
          renderInput={(params: any) => <TextField {...params} autoWidth />}
        />
      )}
    </div>
  );
}

export type NavigationDrawerProps = {
  /**
   * These items are displayed inside the drawer
   */
  navigationItems: NavigationItem[];
  open: boolean;
  onDrawerLinkClick: () => void;
};

export type UserProfileSectionProps = {
  onUserProfileSectionClick: () => void;
};

/**
 * A Navigation Drawer that is closed by default and opens by selecting the menu icon,
 * and stays open until closed by the user.
 */
function NavigationDrawer({
  navigationItems,
  open,
  onDrawerLinkClick,
}: NavigationDrawerProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={onDrawerLinkClick}
      >
        <div className={classes.wrapper}>
          <UserProfileSection onUserProfileSectionClick={onDrawerLinkClick} />
          <Divider />
          <DrawerMenuItems
            navigationItems={navigationItems}
            onDrawerLinkClick={onDrawerLinkClick}
          />
        </div>
      </Drawer>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  wrapper: {
    minWidth: "300px",
    overflowX: "hidden",
  },
  drawer: {
    flexShrink: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  sectionName: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    fontWeight: "normal",
    color: colors.grey40,
  },
  userFullName: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  userProfileSection: {
    display: "block",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(12),
    paddingBottom: 0,
  },
  selected: {
    "&:focus": {
      backgroundColor: "#FFFFFF",
    },
  },
  avatarSection: {
    alignSelf: "center",
    marginRight: theme.spacing(1),
  },
  profileNameSection: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  machineSelectAutocomplete: {
    marginBottom: theme.spacing(4),
  },
  viewSettings: {
    fontWeight: 300,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default NavigationDrawer;
