import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useUserService } from "../../../api/ServiceContext";
import { useSelectedMachine } from "../../../store/GlobalContext";
import { t } from "../../../utility/TranslateUtility";
import { useDispatchAlert } from "../../UI/Alert";
import { ConfirmActionDialog } from "../ConfirmActionDialog";
import { AddOrUpdateUserDialogPresentation } from "./AddOrUpdateUserPresentation";
import { emptyUser, iMobaUser } from "./Common";

export interface AddUserDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  isUpdate: boolean;
  existingUser?: iMobaUser;
  afterUpsertUser: () => void;
}

export default function AddOrUpdateUserDialogContainer({
  isOpen,
  handleClose,
  isUpdate,
  existingUser,
  afterUpsertUser,
}: AddUserDialogProps) {
  const userService = useUserService();
  const dispatchAlert = useDispatchAlert();
  const selectedMachine = useSelectedMachine();
  const [user, setUser] = useState<iMobaUser>(
    isUpdate ? existingUser! : emptyUser
  );
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [unConfirmedUser, setUnconfirmedUser] = useState(emptyUser);
  const auth0 = useAuth0();

  useEffect(() => {
    if (isUpdate) {
      setUser(existingUser!);
    } else {
      setUser(emptyUser);
    }
  }, [existingUser, isUpdate]);

  const handleSave = (newUser: iMobaUser) => {
    // Temporary until modules are stored in the backend
    if (!user.modules) newUser.modules = [];

    if (newUser.modules.length === 0 && !isUpdate) {
      setUnconfirmedUser(newUser);
      setConfirmDialogOpen(true);
    } else {
      upsertUser(newUser);
      handleClose();
    }
  };

  const upsertUser = (newUser: iMobaUser) => {
    if (isUpdate) {
      userService
        .updateUser(newUser, selectedMachine.machineId, auth0.user.email)
        .then(() => {
          dispatchAlert({
            message: t("users.messages.userSavedReload"),
            messageType: "success",
          });
          afterUpsertUser();
        })
        .catch(() => {
          dispatchAlert({
            message: t("users.messages.userSaveFailed"),
            messageType: "error",
          });
        });
    } else {
      userService
        .addUser(newUser, selectedMachine.machineId, auth0.user.email)
        .then(() => {
          dispatchAlert({
            message: t("users.messages.userSaved"),
            messageType: "success",
          });
          afterUpsertUser();
        })
        .catch(() => {
          dispatchAlert({
            message: t("users.messages.userSaveFailed"),
            messageType: "error",
          });
        });
    }
  };

  const handleConfirm = () => {
    if (unConfirmedUser.email !== "") upsertUser(unConfirmedUser);
    setConfirmDialogOpen(false);
    handleClose();
  };

  const handleReject = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <>
      <AddOrUpdateUserDialogPresentation
        isOpen={isOpen}
        handleClose={handleClose}
        isUpdate={isUpdate}
        user={user}
        handleSave={handleSave}
      />
      <ConfirmActionDialog
        message={t("users.messages.confirmNoModules")}
        isOpen={confirmDialogOpen}
        onConfirm={handleConfirm}
        onReject={handleReject}
      />
    </>
  );
}
