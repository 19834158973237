import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { t } from "../../../utility/TranslateUtility";

export interface DestinationProductSearchProps {
  searchString: string;
  onChange: (searchString: string) => void;
}

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(35),
    margin: theme.spacing(2),
    marginLeft: 0,
  },
}));

export function DestinationProductSearch({
  searchString,
  onChange: onChange,
}: DestinationProductSearchProps) {
  const classes = useStyles();

  return (
    <div className={classes.searchContainer}>
      <TextField
        value={searchString}
        onChange={(event) => onChange(event.target.value)}
        variant="outlined"
        placeholder={t(
          "performanceProQuality.supplierDetailPage.destinationProduct.search"
        )}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
