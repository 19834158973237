import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useUserService } from "../api/ServiceContext";
import { Measure } from "../components/Benchmark/MachineMeasureComparison";
import { LocalStorageLanguageKey } from "../i18n";
import { useBenchmarkActions } from "../store/GlobalContext";
import { useCurrentLanguage } from "../utility/TranslateUtility";
export interface IUserPreferences extends IKpiPreferences {
  defaultLanguage: string;
}

export interface IKpiPreferences {
  showRadarGraphKpi: boolean;
  showProductionAvailabilityKpi: boolean;
  showInputOffgradesKpi: boolean;
  showTableEggsKpi: boolean;
  showUpgradeEggsKpi: boolean;
  showDirtKpi: boolean;
  showCrackKpi: boolean;
  showBloodKpi: boolean;
  showLeakerKpi: boolean;
  showCapacityUtilizationKpi: boolean;
  showUpgradeUtilizationKpi: boolean;
  showInputGradeAKpi: boolean;
}

export function useGetUserSettingsAndSaveInLocalStorage() {
  const userService = useUserService();
  const { isAuthenticated } = useAuth0();
  const {
    updateDisplayedMachineMeasures,
    updateShowRadar,
  } = useBenchmarkActions();

  let [, setCurrentLanguage] = useCurrentLanguage(LocalStorageLanguageKey);

  useEffect(() => {
    if (isAuthenticated) {
      userService
        .getUserPreferences()
        .then((userPreferences: IApiResponse<IUserPreferences>) => {
          updateDisplayedMachineMeasures(
            ConvertPreferencesToSelectedMeasures(userPreferences.data)
          );
          updateShowRadar(userPreferences.data.showRadarGraphKpi);

          setCurrentLanguage(userPreferences.data.defaultLanguage);
        });
    }
  }, [isAuthenticated, userService]);
}

function ConvertPreferencesToSelectedMeasures(
  preferences: IUserPreferences
): Measure[] {
  let measures: Measure[] = [];
  if (preferences.showTableEggsKpi) measures.push("TableEggs");
  if (preferences.showInputOffgradesKpi) measures.push("InputOffgrades");
  if (preferences.showUpgradeEggsKpi) measures.push("Upgrades");
  if (preferences.showInputGradeAKpi) measures.push("InputGradeA");
  if (preferences.showDirtKpi) measures.push("Dirt");
  if (preferences.showCrackKpi) measures.push("Crack");
  if (preferences.showBloodKpi) measures.push("Blood");
  if (preferences.showCapacityUtilizationKpi)
    measures.push("CapacityUtilization");
  if (preferences.showUpgradeUtilizationKpi)
    measures.push("UpgradeUtilization");
  if (preferences.showProductionAvailabilityKpi)
    measures.push("ProductionAvailability");
  if (preferences.showLeakerKpi) measures.push("Leaker");

  return measures;
}

export function ConvertSelectedMeasuresToPreferences(
  measures: Measure[],
  showRadar: boolean
): IKpiPreferences {
  return {
    showRadarGraphKpi: showRadar,
    showProductionAvailabilityKpi: measures.includes("ProductionAvailability"),
    showInputOffgradesKpi: measures.includes("InputOffgrades"),
    showTableEggsKpi: measures.includes("TableEggs"),
    showUpgradeEggsKpi: measures.includes("Upgrades"),
    showDirtKpi: measures.includes("Dirt"),
    showCrackKpi: measures.includes("Crack"),
    showBloodKpi: measures.includes("Blood"),
    showLeakerKpi: measures.includes("Leaker"),
    showCapacityUtilizationKpi: measures.includes("CapacityUtilization"),
    showUpgradeUtilizationKpi: measures.includes("UpgradeUtilization"),
    showInputGradeAKpi: measures.includes("InputGradeA"),
  };
}
