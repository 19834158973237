import { FormatWithTwoDecimals } from "../../../utility/NumberFormatUtility";
import { t } from "../../../utility/TranslateUtility";
import { format, TooltipBuilder } from "../../ProductionViews/Tooltip";
import { SeriesType } from "./MachineMeasureComparison";

type LabelData = {
  x: string | number;
  y: number;
  points: {
    series: LabelDataPointSeries;
    x: string | number;
    y: number;
  }[];
};

type LabelDataPointSeries = {
  name?: string;
  userOptions: {
    id?: string;
    custom: {
      seriesType: SeriesType;
    };
  };
};

export const tooltipFormatter = new TooltipBuilder()
  .add((data: LabelData) => {
    const avgMachinePoint = data.points.filter(
      (x) => x.series.userOptions.custom.seriesType === "machine-avg"
    )[0];
    const bestMachinesPoint = data.points.filter(
      (x) => x.series.userOptions.custom.seriesType === "best3"
    )[0];
    const worstMachinePoint = data.points.filter(
      (x) => x.series.userOptions.custom.seriesType === "worst3"
    )[0];
    const machinePoints = data.points.filter(
      (x) => x.series.userOptions.custom.seriesType === "machine"
    );

    let parts = format({}, data.x);

    let bestMachinesPointValue = 0;
    if (bestMachinesPoint !== undefined)
      bestMachinesPointValue = bestMachinesPoint.y;
    parts += format(
      { label: t("benchmark.best3Machines"), unit: "%", separator: true },
      FormatWithTwoDecimals(bestMachinesPointValue)
    );

    for (let m of machinePoints) {
      let machinePointValue = 0;
      if (m !== undefined) machinePointValue = m.y;
      parts += format(
        { label: m.series.name, unit: "%", separator: true },
        FormatWithTwoDecimals(machinePointValue)
      );
    }

    let worstMachinesPointValue = 0;
    if (worstMachinePoint !== undefined)
      worstMachinesPointValue = worstMachinePoint.y;
    parts += format(
      { label: t("benchmark.worst3Machines"), unit: "%", separator: true },
      FormatWithTwoDecimals(worstMachinesPointValue)
    );

    let avgMachinePointValue = 0;
    if (avgMachinePoint !== undefined) avgMachinePointValue = avgMachinePoint.y;
    parts += format(
      { label: t("benchmark.average"), unit: "%", separator: true },
      FormatWithTwoDecimals(avgMachinePointValue)
    );

    return parts;
  })
  .buildSharedFormatter();
