import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { EggUnit } from "../../../utility/EggUnitUtility";
import { FormatEggs } from "../../../utility/EggUtility";
import { ReportView } from "./Common";

interface ReportPresentationProps {
  reportView: ReportView;
  unitType: EggUnit;
}

const BorderedTableCell = withStyles({
  root: {
    border: "1px solid lightgrey",
  },
})(TableCell);

const useStyles = makeStyles({
  header: {
    marginBottom: "10px",
  },
  table: {
    minWidth: 650,
  },
});

export function ReportSummaryPresentation({
  reportView,
  unitType,
}: ReportPresentationProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.header} variant="h5">
        {t("performanceProReportPage.summaryHeader")}
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <BorderedTableCell>
                {t("performanceProReportPage.summaryFields.machineId")}
              </BorderedTableCell>
              <BorderedTableCell>
                {t(
                  `performanceProReportPage.summaryFields.${unitType
                    .toString()
                    .toLowerCase()}Graded`
                )}
              </BorderedTableCell>
              <BorderedTableCell>
                {t("performanceProReportPage.summaryFields.targetPerHour")}
              </BorderedTableCell>
              <BorderedTableCell>
                {t("performanceProReportPage.summaryFields.percentageVsTarget")}
              </BorderedTableCell>
              <BorderedTableCell>
                {t(
                  `performanceProReportPage.summaryFields.average${unitType.toString()}PerHour`
                )}
              </BorderedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <BorderedTableCell>{reportView.machineId}</BorderedTableCell>
              <BorderedTableCell>
                {FormatEggs(reportView.sumEggsGraded, unitType)}
              </BorderedTableCell>
              <BorderedTableCell>
                {FormatEggs(reportView.targetEggsPerHour, unitType)}
              </BorderedTableCell>
              <BorderedTableCell>
                {reportView.avgPercentageVsTarget.toFixed(1)}%
              </BorderedTableCell>
              <BorderedTableCell>
                {FormatEggs(reportView.avgEggsPerHour, unitType)}
              </BorderedTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
