import { useEffect, useState } from "react";
import { useGlobalAnnouncementService } from "../../../api/ServiceContext";
import { GlobalAnnouncementModel } from "../../../apiModels/GlobalAnnouncement/GlobalAnnouncementModel";
import { GlobalAnnouncement } from "./GlobalAnnouncement";

export function GlobalAnnouncementConnected() {
  const globalAnnouncementService = useGlobalAnnouncementService();
  const [announcementMessage, setAnnouncementMessage] = useState<GlobalAnnouncementModel | null>(null);

  //Poll to check announcement
  useEffect(() => {
    async function getAndSetAnnouncement() {
      let resultModel = await globalAnnouncementService.getGlobalAnnouncement()
      setAnnouncementMessage(resultModel);
    }

    const refreshInMinutesInterval = 15;
    getAndSetAnnouncement();

    const refreshInterval = setInterval(() => {
      void(async () => {
        await getAndSetAnnouncement();
      })()
    }, refreshInMinutesInterval * 60 * 1000);

    return () => clearInterval(refreshInterval);
  }, [globalAnnouncementService]);

  return GlobalAnnouncement(announcementMessage);
}