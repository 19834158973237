import { Button, makeStyles, Paper, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

export function PageHeaderCard({
  title,
  buttonText,
  redirectTo,
}: {
  title: string;
  buttonText: string;
  redirectTo: string;
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Link to={redirectTo}>
        <Button startIcon={<ArrowBackIcon />} variant="outlined">
          {buttonText}
        </Button>
      </Link>
    </Paper>
  );
}
