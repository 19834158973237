import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";

export function SelectFormControl({
  label,
  value,
  onValueChange,
  children,
  isDisabled,
}: {
  label: string;
  value: number;
  onValueChange: (value: number) => void;
  children: any;
  isDisabled?: boolean;
}) {
  const classes = useStyles();
  const isMobileSize = useIsMobileSize('xs');

  const handleChange = (event: any) => {
    onValueChange(event.target.value);
  };

  return (
    <FormControl fullWidth={isMobileSize}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        classes={{
          select: classes.select,
        }}
        disabled={isDisabled}
      >
        {children}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: theme.spacing(12),
  },
}));

export const SelectItem = MenuItem;
