import * as Highcharts from "highcharts";
import { useMemo } from "react";
import { getLastElement } from "../../../utility/ArrayUtility";
import {
  formatWithLeadingZero,
  subtractMinutes,
} from "../../../utility/DateUtility";
import { FormatEggs } from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import { BarChartSettingsMinute } from "../../Charts/ChartSettings/BarChartSettings";
import HighchartsFullscreenWrapper from "../Wrappers/HighchartsFullscreenWrapper";

export type MachineSpeedMinuteWidgetPresentationProps = {
  awaitingResponse: boolean;
  data: MachineSpeedMinuteApiData;
};

export type MachineSpeedMinuteApiResponse = {
  data: MachineSpeedMinuteApiData;
};

export type MachineSpeedMinuteApiData = {
  machineSpeedMinutePeriods: Array<MachineSpeedMinutePeriod>;
};

export type MachineSpeedMinutePeriod = {
  dateTime: Date;
  eggsPerMinute: number;
};

const ChartSettings = {
  ...BarChartSettingsMinute,
  yAxis: {
    title: {
      text: t("widgets.machineSpeedMinute.yAxisTitle"),
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    formatter: function (this: any) {
      return (
        "<b>" +
        t("widgets.machineSpeedMinute.tooltipTime") +
        "</b>" +
        formatWithLeadingZero(new Date(this.point.x).getUTCHours()) +
        ":" +
        formatWithLeadingZero(new Date(this.point.x).getUTCMinutes()) +
        "<br/><b>" +
        t("widgets.machineSpeedMinute.tooltipMeasure") +
        "</b> " +
        FormatEggs(this.point.y, null) +
        "</b> " +
        t("widgets.machineSpeedMinute.tooltipUnit")
      );
    },
  },
};

function MapToChartData(input: MachineSpeedMinuteApiData) {
  const result = {
    data: input.machineSpeedMinutePeriods.map(
      (machineSpeedMinutePeriod: MachineSpeedMinutePeriod) => ({
        x: machineSpeedMinutePeriod.dateTime,
        y: machineSpeedMinutePeriod.eggsPerMinute,
      })
    ),
  };

  const last_element = getLastElement(input.machineSpeedMinutePeriods);
  const newMax = last_element.dateTime;

  return {
    series: result,
    minDate: subtractMinutes(newMax, 30),
  };
}

export default function MachineSpeedMinuteWidgetPresentation({
  awaitingResponse,
  data,
}: MachineSpeedMinuteWidgetPresentationProps) {
  const newOptions = useMemo(() => {
    if (awaitingResponse || !data) return ChartSettings;

    const newChartData = MapToChartData(data);

    return {
      ...ChartSettings,
      xAxis: {
        ...ChartSettings.xAxis,
        min: newChartData.minDate,
      },
      series: newChartData.series,
    };
  }, [data, awaitingResponse]);

  return (
    <HighchartsFullscreenWrapper
      title={t("widgets.machineSpeedMinute.title")}
      awaitingResponse={awaitingResponse}
      highcharts={Highcharts}
      chartOptions={newOptions}
    />
  );
}
