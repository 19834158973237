import exportToXlsx from "json-as-xlsx";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { onlyUnique } from "../../../utility/ArrayUtility";
import { determineEggTypeName } from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import { ChartCard } from "../../Benchmark/MachineMeasureComparison/Common";
import { usePerformanceGroupMeasures, usePerformanceGroupMeasureWithNormalizedEggTypes } from "../../PerformancePro/Grouped/performanceProGroupHooks";
import {
  KpiMeasureGroupType
} from "../../PerformancePro/Types";
import { ChartSubTitle } from "../../ProductionViews/Common";
import { useQualityChartSettingManagement } from "../../Settings/QualityCharts/hooks";
import { filterBySupplier, getAverage, getAverageWeight, getOffgradeEggsPercentage, SupplyRanking } from "./Common";
import { ExportRankingOfSuppliesButton } from "./ExportRankingOfSuppliesButton";
import RankingOfSuppliesPresentation from "./RankingOfSuppliesPresentation";
import { RankingOfSuppliesSearch } from "./RankingOfSuppliesSearch";

export default function RankingOfSuppliesContainer() {
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(true);
  const { brownDetectionActive, shellStrengthActive } = usePerformanceGroupMeasures();

  const isInfeedBased =
    useQualityChartSettingManagement().qualityChartSetting
      .qualityChartSetting === "InfeedBasedCharts";

  const colorData = usePerformanceGroupMeasureWithNormalizedEggTypes(
    KpiMeasureGroupType.ColorBySupply
  ).data;
  
  const shellStrengthData = usePerformanceGroupMeasureWithNormalizedEggTypes(
    KpiMeasureGroupType.ShellStrengthBySupply
  ).data;

  const offgradeData = usePerformanceGroupMeasureWithNormalizedEggTypes(
    isInfeedBased
      ? KpiMeasureGroupType.InfeedOffgradeBySupply
      : KpiMeasureGroupType.TakeawayOffgradeBySupply
  ).data;

  const totalWeightData = usePerformanceGroupMeasureWithNormalizedEggTypes(
    KpiMeasureGroupType.TotalWeightBySupply
  ).data;

  const totalEggsData = usePerformanceGroupMeasureWithNormalizedEggTypes(
    KpiMeasureGroupType.TotalBySupply
  ).data;
  const metadata = usePerformanceGroupMeasureWithNormalizedEggTypes(
    KpiMeasureGroupType.TotalBySupply
  ).metadata;

  const rankingOfSupplies = useMemo(() => {
    setAwaitingResponse(true);

    const offgradePerSupply = _.groupBy(offgradeData, "x");
    let newRankingOfSupplies: SupplyRanking[] = [];
    for (const supply in offgradePerSupply) {
      const offgradeDataForSupplier = offgradePerSupply[supply];
      const colorDataForSupplier = filterBySupplier(colorData, supply);
      const shellStrengthDataForSupplier = filterBySupplier(shellStrengthData, supply);
      const totalEggsDataForSupplier = filterBySupplier(totalEggsData, supply);
      const totalWeightDataForSupplier = filterBySupplier(totalWeightData, supply);

      const supplyInfo = metadata.xAxis.values[supply];

      const avgWeight = getAverageWeight(
        totalEggsDataForSupplier,
        totalWeightDataForSupplier
      );

      const offgradeEggsPercentage = getOffgradeEggsPercentage(
        offgradeDataForSupplier,
        totalEggsDataForSupplier
      );

      newRankingOfSupplies.push({
        supplierNameShed: supplyInfo ? supplyInfo.supplierNameShed : supply,
        averageEggWeight: avgWeight,
        averageColor: getAverage(colorDataForSupplier, -1),
        averageShellStrength: getAverage(shellStrengthDataForSupplier, 0),
        eggTypes: colorDataForSupplier.map((c) => c.eggType).filter(onlyUnique),
        offgradeEggsNumber: offgradeDataForSupplier.reduce(
          (a, b) => a + b.value,
          0
        ),
        offgradeEggsPercentage: offgradeEggsPercentage,
        totalEggsNumber: totalEggsDataForSupplier.reduce(
          (a, b) => a + b.value,
          0
        ),
        ticketNumbers: supplyInfo ? supplyInfo.ticketNumbers : [],
      });
    }

    setAwaitingResponse(false);
    return newRankingOfSupplies;
  }, [colorData, shellStrengthData, offgradeData, totalWeightData, totalEggsData, metadata ]);

  const [rankingOfSuppliesToDisplay, setRankingOfSuppliesToDisplay] =
    useState<SupplyRanking[]>(rankingOfSupplies);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    setRankingOfSuppliesToDisplay(
      rankingOfSupplies.filter((supplyRanking) => {
        return (
          supplyRanking.supplierNameShed
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          supplyRanking.averageEggWeight
            .toString()
            .includes(searchString.toLowerCase()) ||
          supplyRanking.averageColor
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          supplyRanking.averageShellStrength
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          supplyRanking.eggTypes.find((eggType) =>
            determineEggTypeName(eggType)
              .toLowerCase()
              .includes(searchString.toLowerCase())
          ) ||
          supplyRanking.offgradeEggsNumber
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          supplyRanking.offgradeEggsPercentage
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          supplyRanking.totalEggsNumber
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          supplyRanking.ticketNumbers.find((ticketNumber) =>
            ticketNumber.toLowerCase().includes(searchString.toLowerCase())
          )
        );
      })
    );
  }, [rankingOfSupplies, searchString]);

  const handleExportToExcel = () => {
    const roundedSupplies = rankingOfSuppliesToDisplay.map((sr) => ({
      supplierNameShed: sr.supplierNameShed,
      averageEggWeight: mapForExport(sr.averageEggWeight),
      averageColor: brownDetectionActive ? mapForExport(sr.averageColor) : "-",
      averageShellStrength: shellStrengthActive ? mapForExport(sr.averageShellStrength) : "-",
      eggTypes: sr.eggTypes
        .map((eggType) => determineEggTypeName(eggType).toLowerCase())
        .join(" ,"),
      offgradeEggsNumber: mapForExport(sr.offgradeEggsNumber),
      offgradeEggsPercentage: mapForExport(sr.offgradeEggsPercentage),
      totalEggsNumber: mapForExport(sr.totalEggsNumber),
      ticketNumbers: sr.ticketNumbers.join(" ,"),
    }));

    const data = [
      {
        sheet: "Ranking of supplies",
        columns: [
          { label: "supplierNameShed", value: "supplierNameShed" },
          {
            label: "averageEggWeight",
            value: "averageEggWeight",
          },
          { label: "averageColor", value: "averageColor" },
          { label: "averageShellStrength", value: "averageShellStrength" },
          { label: "eggTypes", value: "eggTypes" },
          { label: "offgradeEggsNumber", value: "offgradeEggsNumber" },
          {
            label: "offgradeEggsPercentage",
            value: "offgradeEggsPercentage",
          },
          { label: "totalEggsNumber", value: "totalEggsNumber" },
          { label: "ticketNumbers", value: "ticketNumbers" },
        ],
        content: roundedSupplies,
      },
    ];
    const fileName = "ranking_of_supplies";

    let settings = {
      fileName,
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    exportToXlsx(data, settings);
  };

  function mapForExport(data: number): number {
    if (!data) return data;
    return Math.round(data * 100) / 100;
  }

  let infeedOrTakeawayLabel = isInfeedBased
    ? t("uniqueViews.infeedBased")
    : t("uniqueViews.takeawayBased");

  return (
    <>
      <ChartCard
        headerTitle={t("qualityViews.rankingOfSupplies.cardHeader")}
        isLoading={awaitingResponse}
      >
        <ChartSubTitle>{infeedOrTakeawayLabel}</ChartSubTitle>

        <RankingOfSuppliesSearch
          searchString={searchString}
          onSearchStringChange={setSearchString}
        />

        <ExportRankingOfSuppliesButton exportToExcel={handleExportToExcel} />

        <RankingOfSuppliesPresentation
          rankingOfSupplies={rankingOfSuppliesToDisplay}
          brownDetectionActive={brownDetectionActive}
          shellStrengthActive={shellStrengthActive}
        />
      </ChartCard>
    </>
  );
}
