import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: "grey",
    fontWeight: "normal",
  },
}));

export function PageHeaderSubtitle({ children }: any) {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classes.subtitle}>
      {children}
    </Typography>
  );
}
