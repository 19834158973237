import React from "react";
import { TargetsView } from "../Settings/Targets/Types";
import { usePerformanceProMeasuresOverview } from "./performanceProHooks";
import PeriodViewSparklineChart from "./PeriodViewSparklineChart";
import { KpiMeasureType } from "./Types";

export default function PerformanceProSparklineViewContainer({
  machineId,
  measure,
  targets,
}: {
  machineId: MachineId;
  measure: KpiMeasureType;
  targets: TargetsView | null;
}) {
  const { periods, chartMapping, options, awaitingResponse } =
    usePerformanceProMeasuresOverview({
      machineId: machineId,
      measure: measure,
    });

  return (
    periods && (
      <PeriodViewSparklineChart
        periods={periods}
        chartMapping={chartMapping}
        awaitingResponse={awaitingResponse}
        targets={targets}
        selectedEggType={options.selectedEggType}
      />
    )
  );
}
