import { Paper } from "@material-ui/core";
import Draggable from "react-draggable";
import { emptyUser, iMobaUser } from "../../Users/AddOrUpdateUser/Common";
import {
  AlarmCondition,
  AlarmEggsProdUnit,
  AlarmFrequency,
  AlarmLevelUnit,
  AlarmPercentage,
  AlarmStatus,
  AlarmThroughputUnit,
  AlarmTimeUnit,
  AlarmTopic,
} from "../Types";

// style={{width: "600px"}} for internet explorer
export function PaperComponent(props: any) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper style={{ width: "600px" }} {...props} />
    </Draggable>
  );
}

export function splitOnCapital(topicStr: string): string {
  return topicStr.match(/[A-Z][a-z]+/g)?.join(" ") || "";
}

export interface IAlarm {
  alarmId: number;
  topic: AlarmTopic;
  user: iMobaUser;
  level: number;
  levelUnit:
    | AlarmEggsProdUnit
    | AlarmThroughputUnit
    | AlarmTimeUnit
    | AlarmPercentage
    | AlarmLevelUnit;
  condition: AlarmCondition;
  frequency: AlarmFrequency;
  startTimeUtc: Date;
  endTimeUtc: Date;
  status: AlarmStatus;
}

export interface IAlarmCommand extends IAlarm {
  machineId: number;
}

export const emptyAlarm: IAlarm = {
  alarmId: 0,
  topic: AlarmTopic.TotalEggs,
  user: emptyUser,
  level: 0,
  levelUnit: 0,
  condition: AlarmCondition.Above,
  frequency: AlarmFrequency.Hourly,
  startTimeUtc: new Date(),
  endTimeUtc: new Date(),
  status: AlarmStatus.Passive,
};
