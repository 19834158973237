import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MachineMeasureComparisonContainer, {
  Measure,
  useMachineMeasureComparisonFilters
} from "../../components/Benchmark/MachineMeasureComparison";
import MachineMeasureComparisonContainerFilters, {
  FiltersSideWrapper,
  FiltersToolbarWrapper as MobileFiltersToolbarWrapper
} from "../../components/Benchmark/MachineMeasureComparison/Filters";
import MachineMeasureRadarGraphContainer from "../../components/Benchmark/MachineMeasureRadarGraph/MachineMeasureRadarGraphContainer";
import { BenchmarkToolbar } from "../../components/Benchmark/Toolbar";
import { useBenchmarkState } from "../../store/GlobalContext";
import { t } from "../../utility/TranslateUtility";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: 0,
    paddingTop: theme.spacing(2),
    maxHeight: "100%",
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  benchmarkFilters: {
    padding: theme.spacing(2),
    width: "30vw",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  toolbar: {
    position: "fixed",
    width: "100%",
    zIndex: 1,
  },
  chartContent: {
    marginTop: theme.spacing(2),
  },
  benchmarkCharts: {
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(13),
    },
  },
  content: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2),
    },
  },
  gridContent: {
    paddingTop: 0,
  },
}));

export const BenchmarkMeasures = [
  {
    measure: "CapacityUtilization",
    title: "benchmark.capacityUtilization.title",
  },
  {
    measure: "UpgradeUtilization",
    title: "benchmark.upgradeUtilization.title",
  },
  {
    measure: "InputGradeA",
    title: "benchmark.inputGradeA.title",
  },
  {
    measure: "ProductionAvailability",
    title: "benchmark.productionAvailability.title",
  },
  {
    measure: "TableEggs",
    title: "benchmark.tableEggs.title",
  },
  {
    measure: "Upgrades",
    title: "benchmark.upgrades.title",
  },
  {
    measure: "InputOffgrades",
    title: "benchmark.inputOffgrades.title",
  },
  {
    measure: "Dirt",
    title: "benchmark.dirt.title",
  },
  {
    measure: "Crack",
    title: "benchmark.crack.title",
  },
  {
    measure: "Blood",
    title: "benchmark.blood.title",
  },
  {
    measure: "Leaker",
    title: "benchmark.leaker.title",
  },
];

const benchmarkMeasureOrderSortFunction = (a: Measure, b: Measure) => {
  if (
    BenchmarkMeasures.findIndex((x) => x.measure === a) <
    BenchmarkMeasures.findIndex((x) => x.measure === b)
  )
    return -1;
  return 1;
};

function BenchmarkPage() {
  const classes = useStyles();
  const { filter } = useMachineMeasureComparisonFilters();
  const state = useBenchmarkState();
  const filtersNode = <MachineMeasureComparisonContainerFilters />;

  const measuresToShow = [...filter.measures];
  measuresToShow.sort(benchmarkMeasureOrderSortFunction);

  return (
    <>
      <div className={classes.toolbar}>
        <Paper elevation={1}>
          <BenchmarkToolbar>
            <MobileFiltersToolbarWrapper>
              {filtersNode}
            </MobileFiltersToolbarWrapper>
          </BenchmarkToolbar>
        </Paper>
      </div>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={4}>
            <FiltersSideWrapper>{filtersNode}</FiltersSideWrapper>
          </Grid>
          <Grid item lg={8} md={12} sm={12} xs={12} className={classes.benchmarkCharts}>
            <ChartWrapper>
              {state.showRadar && <MachineMeasureRadarGraphContainer />}
            </ChartWrapper>
            {measuresToShow.map(
              (measure: Measure) =>
                state.selectedWidgets.find((x) => x === measure) && (
                  <ChartWrapper key={measure}>
                    <MachineMeasureComparisonContainer
                      measure={measure}
                      title={t(
                        BenchmarkMeasures.find((b) => b.measure === measure)!
                          .title
                      )}
                    />
                  </ChartWrapper>
                )
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

function ChartWrapper({ children }: { children: any }): any {
  const classes = useStyles();
  return <div className={classes.chartContent}>{children}</div>;
}

export default BenchmarkPage;
