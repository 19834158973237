import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { IDeviceRegistrationService } from "../../../api/Notifications/IDeviceRegistrationService";
import { useDeviceRegistrationService } from "../../../api/ServiceContext";
import {
  useDeviceHasNotificationsEnabled,
  useDeviceInstallation,
} from "../../../globalHooks/deviceInstallationHooks";
import { t } from "../../../utility/TranslateUtility";
import { useDispatchAlert } from "../../UI/Alert/alertHooks";
import { IDeviceInstallation, IDeviceRegistration } from "../Common";
import DeviceRegistrationPresentation from "./DeviceRegistrationPresentation";

function DeviceRegistrationContainer() {
  const [deviceRegistrations, setDeviceRegistrations] = useState<
    IDeviceRegistration[]
  >([]);
  const deviceRegistrationService = useDeviceRegistrationService();
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [deviceHasNotificationsEnabled, setDeviceHasNotificationsEnabled] =
    useState<boolean>(false);
  const [deviceInfo, setDeviceInfo] = useState<IDeviceInstallation>();
  const [deviceInfoLoaded, setDeviceInfoLoaded] = useState(false);
  const { user } = useAuth0();
  const dispatchAlert = useDispatchAlert();
  const retrieveDeviceInstallation = useDeviceInstallation();
  const queryDeviceHasNotificationsEnabled = useDeviceHasNotificationsEnabled();

  useEffect(() => {
    if (!deviceInfoLoaded) {
      retrieveDeviceInstallation.then(
        function (result: IDeviceInstallation | undefined) {
          if (result) {
            setDeviceInfo(result);
            setDeviceInfoLoaded(true);
          }
        },
        function (error: string) {
          console.error(error);
        }
      );
    }
  }, [deviceInfoLoaded, retrieveDeviceInstallation]);

  useEffect(() => {
    queryDeviceHasNotificationsEnabled.then(
      (result: boolean) => {
        setDeviceHasNotificationsEnabled(result);
      },
      (err) => {
        console.error(err);
      }
    );
  });

  useEffect(() => {
    if (!dataLoaded && deviceHasNotificationsEnabled) {
      getDeviceRegistrations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataLoaded,
    deviceRegistrationService,
    user,
    deviceHasNotificationsEnabled,
  ]);

  const awaitingResponse = !dataLoaded || !deviceInfoLoaded;
  if (deviceHasNotificationsEnabled) {
    return (
      <DeviceRegistrationPresentation
        awaitingResponse={awaitingResponse}
        deviceRegistrations={deviceRegistrations}
        deviceInstallation={deviceInfo}
        onNotificationChange={onNotificationChange}
        onRequestTestNotification={onRequestTestNotification}
      />
    );
  }
  return (
    <div>{t("notificationManagement.messages.notificationsNotSupported")}</div>
  );
  function getDeviceRegistrations() {
    deviceRegistrationService
      .getDeviceRegistrations()
      .then((response: IApiResponse<IDeviceRegistration[]>) => {
        if (response?.data) {
          processDeviceRegistrations(response);
        }
      })
      .catch((err) => {
        dispatchAlert({
          message: t(
            "notificationManagement.messages.retrieveRegistrationsFailure"
          ),
          messageType: "error",
        });
      });
  }

  function processDeviceRegistrations(
    response: IApiResponse<IDeviceRegistration[]>
  ) {
    removeEmailTag(response.data, user);
    setDataLoaded(true);
    setDeviceRegistrations(response.data);
  }
  function onNotificationChange(
    registration: IDeviceRegistration,
    enable: boolean
  ) {
    if (enable) {
      createOrUpdateDeviceRegistration().then(() => setDataLoaded(false));
    } else {
      deleteDeviceRegistration(
        registration,
        deviceRegistrationService,
        dispatchAlert
      ).then(() => setDataLoaded(false));
    }
  }

  function createOrUpdateDeviceRegistration(): Promise<any> {
    deviceInfo!.deviceDescription = stripInvalidTags(
      deviceInfo!.deviceDescription
    );
    const tags = [];
    if (deviceInfo?.deviceDescription) {
      tags.push(deviceInfo.deviceDescription);
    }

    return deviceRegistrationService
      .createOrUpdateDeviceRegistration({
        platform: deviceInfo!.platform,
        handle: deviceInfo!.pushChannel,
        tags: tags,
      })
      .catch((err) => {
        dispatchAlert({
          message: t("notificationManagement.messages.updateFailed"),
          messageType: "error",
        });
      });
  }

  function onRequestTestNotification(
    registration: IDeviceRegistration
  ): Promise<any> {
    return deviceRegistrationService
      .requestTestNotification(registration)
      .then(() => {
        dispatchAlert({
          message: t(
            "notificationManagement.messages.requestTestNotificationSuccess"
          ),
          messageType: "success",
        });
      })
      .catch((err) => {
        dispatchAlert({
          message: t(
            "notificationManagement.messages.requestTestNotificationFailed"
          ),
          messageType: "error",
        });
      });
  }
}

export default DeviceRegistrationContainer;

function deleteDeviceRegistration(
  registration: IDeviceRegistration,
  service: IDeviceRegistrationService,
  dispatchAlert: any
): Promise<any> {
  return service
    .deleteDeviceRegistrations({
      platform: registration.platform,
      handle: registration.handle,
      tags: registration.tags,
    })
    .catch((err) => {
      dispatchAlert({
        message: t("notificationManagement.messages.deleteFailed"),
        messageType: "error",
      });
    });
}

function removeEmailTag(data: IDeviceRegistration[], user: any) {
  data.forEach((registration) => {
    registration.tags = registration.tags.filter((tag) => tag !== user.email);
    return registration;
  });
}

function stripInvalidTags(
  deviceDescription: string | undefined
): string | undefined {
  if (!deviceDescription) return;

  return deviceDescription.replace(new RegExp(/[^a-zA-Z0-9_@\\-\\.,:#]/g), "");
}
