import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { KpiMeasurePeriodType } from ".";
import {
  useManagementSettingsService,
  usePerformanceProService,
} from "../../api/ServiceContext";
import {
  IPerformanceProFilterOptions,
  useIsAwaitingOverviewResponse,
  usePerformanceProFilterOptions,
  usePerformanceProGroupActions,
  usePerformanceProOverview,
  useSelectedMachine,
  useTargets,
} from "../../store/GlobalContext";
import { DatePeriod, getFirstOfPeriod } from "../../utility/DateUtility";
import { createUnitsConverter, EggType } from "../../utility/EggUtility";
import { GetCancellationToken } from "../../utility/HttpServiceUtility";
import { TargetsView } from "../Settings/Targets/Types";
import { useDispatchAlert } from "../UI/Alert";
import { ChartMapping, Mappings } from "./ChartMapping";
import {
  KpiMeasureType,
  MachinePerformanceResult,
  PerformancePeriod,
  PerformancePeriodSummation,
  ValueType,
} from "./Types";
import {
  useAwaitingPerformanceProInitialState,
  useProductionTime,
} from "./usePerformanceProInitialStateHooks";

export function ConvertDatePeriod(
  datePeriod: DatePeriod
): KpiMeasurePeriodType {
  switch (datePeriod) {
    case DatePeriod.Week:
      return "Week";
    case DatePeriod.Sparkline:
      return "Sparkline";
    case DatePeriod.Month:
      return "Month";
    case DatePeriod.Year:
      return "Year";
    case DatePeriod.Day:
      return "Day";
    default:
      return "Day";
  }
}

export interface PerformanceProMeasureWithOptions
  extends PerformanceProMeasure {
  chartMapping: ChartMapping;
  options: IPerformanceProFilterOptions;
  targets: TargetsView | null;
  setSpeed: PerformancePeriod[] | undefined;
  awaitingResponse: boolean;
}

export interface PerformanceProMeasure {
  summation: PerformancePeriodSummation;
  periods: PerformancePeriod[] | never[];
  valueType: ValueType;
}

export interface ProductionTimeWindow {
  productionStartTime: Date;
  productionEndTime: Date;
}

export const AllOverviewMeasures = [
  KpiMeasureType.TotalEggs,
  KpiMeasureType.ProductionTime,
  KpiMeasureType.Throughput,
  KpiMeasureType.FillRate,
  KpiMeasureType.ProductionAvailability,
  KpiMeasureType.TableEggs,
  KpiMeasureType.InputOffgrades,
  KpiMeasureType.Outputoffgrades,
  KpiMeasureType.BucketEggs,
  KpiMeasureType.Bypass,
  KpiMeasureType.Upgrades,
];

const dateOffset = 24 * 60 * 60 * 1000 * 13;

export function useRequestPerformanceProOverviewData() {
  const cancellationToken = GetCancellationToken();
  const service = usePerformanceProService(cancellationToken);
  const options = usePerformanceProFilterOptions();
  const { selectedDate, selectedDatePeriod } = options;
  const { updateOverviewData, updateIsAwaitingOverviewResponse } =
    usePerformanceProGroupActions();
  const machineId = useSelectedMachine().machineId;
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();

  useEffect(() => {
    let periodType: KpiMeasurePeriodType = "Sparkline";
    const beginDate = new Date();
    beginDate.setTime(options.selectedDate.getTime() - dateOffset);
    const fromDate = getFirstOfPeriod(beginDate, DatePeriod.Sparkline);

    updateIsAwaitingOverviewResponse(true);
    service
      .getMachinePerformanceBatch({
        machineId: machineId,
        measures: AllOverviewMeasures,
        periodType: periodType,
        fromDate: fromDate,
      })
      .then((response: { data: { results: any } }) => {
        updateOverviewData(response.data.results);
        updateIsAwaitingOverviewResponse(false);
      })
      .catch((reason) => {
        if (!reason.isCancelled) {
          dispatchAlert({
            message: t("generic.errorMessage"),
            messageType: "error",
          });
        }
      });

    return () => {
      cancellationToken.cancel();
    };
  }, [machineId, selectedDate, selectedDatePeriod]);
}

export function usePerformanceProMeasuresOverview({
  machineId,
  measure,
  allPeriods: allEggTypes,
}: {
  machineId: MachineId;
  allPeriods?: boolean;
  measure: KpiMeasureType;
}) {
  const options = usePerformanceProFilterOptions();
  const { selectedEggType } = options;
  const storedData = usePerformanceProOverview();

  const storedResult: MachinePerformanceResult | null = useMemo(() => {
    if (storedData) {
      return storedData[KpiMeasureType[measure]];
    }
    return null;
  }, [storedData]);

  const filteredResult = useFilteredResult(
    storedResult,
    options,
    allEggTypes,
    measure,
    selectedEggType,
    null
  );

  return {
    chartMapping: useChartMapping(measure),
    options: options,
    awaitingResponse: useIsAwaitingOverviewResponse(),
    periods: filteredResult.periods,
  };
}

export function usePerformanceProMeasure({
  machineId,
  measure,
  allPeriods: allEggTypes,
  callSetSpeed,
}: {
  machineId: MachineId;
  measure: KpiMeasureType;
  allPeriods?: boolean;
  callSetSpeed?: boolean;
}): PerformanceProMeasureWithOptions {
  const cancellationToken = GetCancellationToken();
  const service = usePerformanceProService(cancellationToken);
  const options = usePerformanceProFilterOptions();
  const { selectedEggType, selectedDate, selectedDatePeriod } = options;
  const productionTimeWindow = useProductionTime();

  const [result, setResult] = useState<MachinePerformanceResult | null>(null);
  const [setSpeed, setSetSpeed] = useState<MachinePerformanceResult | null>(
    null
  );
  const awaitingPerformanceProInitialState =
    useAwaitingPerformanceProInitialState();
  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();

  useEffect(() => {
    const periodType = ConvertDatePeriod(options.selectedDatePeriod);
    const fromDate = getFirstOfPeriod(
      options.selectedDate,
      options.selectedDatePeriod
    );

    setAwaitingResponse(true);
    Promise.all([
      service.getMachinePerformance({
        machineId: machineId,
        measure: measure,
        periodType: periodType,
        fromDate: fromDate,
      }),
      callSetSpeed
        ? service.getMachinePerformance({
            machineId: machineId,
            measure: KpiMeasureType.SetSpeed,
            periodType: periodType,
            fromDate: fromDate,
          })
        : null,
    ])
      .then((response) => {
        setResult(response[0].data);
        response[1] !== null
          ? setSetSpeed(response[1].data)
          : setSetSpeed(null);
        setAwaitingResponse(false);
      })
      .catch((reason) => {
        if (!reason.isCancelled) {
          dispatchAlert({
            message: t("generic.errorMessage"),
            messageType: "error",
          });
        }
      });

    return () => {
      cancellationToken.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineId, measure, selectedDate, selectedDatePeriod, callSetSpeed]);

  const setSpeedDuringProduction = useSetSpeedDuringProduction(
    productionTimeWindow,
    setSpeed
  );

  const { periods, summation, valueType } = useFilteredResult(
    result,
    options,
    allEggTypes,
    measure,
    selectedEggType,
    productionTimeWindow
  );

  const chartMapping = useChartMapping(measure);
  const targets = usePerformanceTargets(machineId);

  const { periodsInUnits, setSpeedDuringProductionInUnits }: any =
    useMemo(() => {
      const inUnits = createUnitsConverter(targets?.units.eggUnit ?? null);
      if (
        measure === KpiMeasureType.TotalEggs ||
        measure === KpiMeasureType.Throughput
      ) {
        return {
          periodsInUnits: periods.map((period: any) => {
            return { ...period, value: inUnits(period.value) };
          }),
          setSpeedDuringProductionInUnits: setSpeedDuringProduction?.map(
            (speed) => {
              return { ...speed, value: inUnits(speed.value) };
            }
          ),
        };
      }
      return { periodsInUnits: periods, setSpeedDuringProduction };
    }, [measure, periods, setSpeedDuringProduction, targets]);

  return {
    chartMapping: chartMapping,
    options: options,
    periods: periodsInUnits,
    summation: summation,
    valueType: valueType,
    targets: targets,
    setSpeed: setSpeedDuringProductionInUnits,
    awaitingResponse: awaitingResponse || awaitingPerformanceProInitialState,
  };
}

function useChartMapping(measure: KpiMeasureType) {
  return useMemo(() => {
    return Mappings.filter(
      (x: ChartMapping) => x.performanceProChart === measure
    )[0];
  }, [measure]);
}

function useSetSpeedDuringProduction(
  productionTimeWindow: ProductionTimeWindow | null,
  setSpeed: MachinePerformanceResult | null
) {
  return useMemo(() => {
    if (productionTimeWindow !== null && setSpeed !== null) {
      return setSpeed?.performancePeriods.filter(
        (x) =>
          x.dateTime >= productionTimeWindow.productionStartTime &&
          x.dateTime <= productionTimeWindow.productionEndTime
      );
    }

    return setSpeed?.performancePeriods;
  }, [setSpeed, productionTimeWindow]);
}

export function usePerformanceTargets(machineId: MachineId) {
  const cancellationToken = GetCancellationToken();
  const settingsService = useManagementSettingsService(cancellationToken);
  const options = usePerformanceProFilterOptions();
  const { selectedDate, selectedDatePeriod } = options;
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();

  const { updateTargets } = usePerformanceProGroupActions();

  useEffect(() => {
    settingsService
      .getMachineTargetsSettings(machineId, options.selectedDate)
      .then((response: any) => {
        updateTargets(response.data);
      })
      .catch((reason) => {
        if (!reason.isCancelled) {
          dispatchAlert({
            message: t("generic.errorMessage"),
            messageType: "error",
          });
        }
      });

    return () => {
      cancellationToken.cancel();
    };
  }, [selectedDate, selectedDatePeriod, machineId]);

  return useTargets() ?? null;
}

function useFilteredResult(
  result: MachinePerformanceResult | null,
  options: IPerformanceProFilterOptions,
  allEggTypes: boolean | undefined,
  measure: KpiMeasureType,
  selectedEggType: number,
  productionTimeWindow: ProductionTimeWindow | null
): { periods: any; summation: any; valueType: any } {
  return useMemo<PerformanceProMeasure>(() => {
    if (!result) {
      return {
        periods: [],
        summation: createEmptySummation(options.selectedEggType),
        valueType: "Count",
      };
    }

    let resultPeriods = result.performancePeriods;
    if (!allEggTypes) {
      resultPeriods = resultPeriods.filter(
        (x) => x.eggType === EggType.AllEggTypes
      );
    }

    let resultsSummation = result.performancePeriodSummations.find(
      (x) => x.eggType === EggType.AllEggTypes
    );

    if (!EggTypeIndependentMeasures.includes(measure) && !allEggTypes) {
      resultPeriods = result.performancePeriods.filter(
        (x) => x.eggType === selectedEggType
      );

      resultsSummation = result.performancePeriodSummations.find(
        (x) => x.eggType === selectedEggType
      );
    }

    if (productionTimeWindow !== null) {
      resultPeriods = resultPeriods.filter(
        (x) =>
          x.dateTime >= productionTimeWindow.productionStartTime &&
          x.dateTime <= productionTimeWindow.productionEndTime
      );
    }

    return {
      periods: resultPeriods,
      summation: resultsSummation
        ? resultsSummation
        : createEmptySummation(options.selectedEggType),
      valueType: result.valueType,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEggTypes, measure, result, selectedEggType, productionTimeWindow]);
}

function createEmptySummation(eggType: EggType): PerformancePeriodSummation {
  return { eggType: eggType, value: 0, numerator: 0, denominator: 0 };
}

export interface TargetsResponse {
  targets: TargetsView | null;
  awaitingTargetsResponse: boolean;
}

export const EggTypeIndependentMeasures: Array<KpiMeasureType> = [
  KpiMeasureType.FillRate,
  KpiMeasureType.ProductionTime,
  KpiMeasureType.Throughput,
  KpiMeasureType.ProductionAvailability,
];
