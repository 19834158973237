import React, { useEffect, useState, useCallback } from "react";
import { useReportService } from "../../../api/ServiceContext";
import useRefresh from "../../Dashboard/useRefresh";
import FillRateWidgetPresentation, {
  FillRateApiResponse,
  FillRateApiData,
} from "./FillRate";

export type FillRateWidgetProps = {
  machineId: number;
  automaticRefresh: boolean;
};

/**
 * Shows the fill rate percentage for the last 30 minutes.
 * The fillrate is the ratio between the number of eggs in pockets (dividend) and
 * the number of pockets (divisor) at the input side of the machine.
 */
export default function FillRateWidgetContainer({
  machineId,
  automaticRefresh,
}: FillRateWidgetProps) {
  const reportService = useReportService();
  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [data, setData] = useState<FillRateApiData | any>(null);

  const fetchData = useCallback(
    (newMachineId: number) => {
      reportService
        .getFillRate(newMachineId)
        .then((response: FillRateApiResponse) => {
          setAwaitingResponse(false);
          const fillRatePeriodLength = response.data.fillRatePeriods.length - 1;

          if (fillRatePeriodLength < 0) return;

          setData(response.data);
        });
    },
    [reportService]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return (
    <FillRateWidgetPresentation
      awaitingResponse={awaitingResponse}
      data={data}
    />
  );
}
