import { useMemo } from "react";
import {
  matchPath,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import {
  NavigationItem,
  TabNavigation,
  TabNavigationVariant
} from "../../components/UI/TabNavigation";
import { t } from "../../utility/TranslateUtility";
import BreakTimesPage from "./break-times/BreakTimesPage";
import EggTypeGroupPage from "./egg-type-groups/EggTypeGroupPageContainer";
import ReportPage from "./report/ReportSettingsPageContainer";
import TargetPage from "./target/TargetPageContainer";
import ViewSettingsPage from "./views/ViewSettingsPageContainer";

export default function ManagementSettingsPage() {
  let { path, url } = useRouteMatch();
  let location = useLocation();

  const navigationItems: Array<NavigationItem> = [
    {
      link: `${url}/targets`,
      title: t("managementSettings.tabs.targets"),
      shouldRender: true
    },
    {
      link: `${url}/egg-type-groups`,
      title: t("managementSettings.tabs.eggTypeGroups"),
      shouldRender: true
    },
    {
      link: `${url}/break-times`,
      title: t("managementSettings.tabs.breakTimes"),
      shouldRender: true
    },
    {
      link: `${url}/views`,
      title: t("managementSettings.tabs.views"),
      shouldRender: true
    },
    {
      link: `${url}/report`,
      title: t("managementSettings.tabs.report"),
      shouldRender: true
    },
  ];

  const selectedNavigationItem = useMemo<number>((): number => {
    const result = navigationItems.findIndex((item) =>
      matchPath(location.pathname, {
        path: item.link,
        exact: false,
        strict: false,
      })
    );

    return result < 0 ? 0 : result;
  }, [navigationItems, location]);

  return (
    <>
      <TabNavigation
        navigationItems={navigationItems}
        variant={TabNavigationVariant.Primary}
        selectedNavigationItem={selectedNavigationItem}
      />
      <Switch>
        <Route path={`${path}/targets`}>
          <TargetPage />
        </Route>
        <Route path={`${path}/egg-type-groups`}>
          <EggTypeGroupPage />
        </Route>
        <Route path={`${path}/break-times`}>
          <BreakTimesPage />
        </Route>
        <Route path={`${path}/views`}>
          <ViewSettingsPage />
        </Route>
        <Route path={`${path}/report`}>
          <ReportPage />
        </Route>
        <Route path={`${path}/`}>
          <TargetPage />
        </Route>
      </Switch>
    </>
  );
}
