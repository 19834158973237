/**
 * Various helper methods for arrays
 */

/**
 * Retrieves the last element of the array, or null if the array is empty.
 * @param array
 */
export const getLastElement = (array: any[]) => {
  if (isEmptyArray(array)) {
    return null;
  }
  return array[array.length - 1];
};

/**
 * Determines if an array is empty or not.
 * Returns true if the array is empty, false otherwise.
 * @param array
 */
export const isEmptyArray = (array: any[]) => {
  return !array || array.length === 0;
};


/**
 * Filter predicate used to filter only unique values
 * @param value value in array
 * @param index index of value
 * @param self array
 */
export function onlyUnique(value: any, index: number, self: any) {
  return self.indexOf(value) === index;
}