import { Button, makeStyles, TableCell, TableRow } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import GetAppIcon from "@material-ui/icons/GetApp";
import SaveIcon from "@material-ui/icons/Save";
import { withStyles } from "@material-ui/styles";
import { colors } from "../../../colors";
import { t } from "../../../utility/TranslateUtility";

export const StyledTableCell = withStyles({
  head: {
    backgroundColor: colors.mobaBlue,
    color: "#fff",
  },
  body: {
    fontSize: 14,
  },
})(TableCell);

export const StyledTableRow = withStyles({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: colors.rowBackgroundColor,
    },
  },
})(TableRow);

export const useTableStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  actionWrapper: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  saveButton: {
    marginLeft: "auto",
    backgroundColor: colors.dirtGreen,
    color: "#fff",
  },
  cancelButton: {
    marginLeft: "auto",
    backgroundColor: colors.mobaBlue,
    color: "#fff",
  },
  firstColumn: {
    width: theme.spacing(15),
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: colors.dirtGreen,
    color: colors.white,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export function TableSaveButton({
  text,
  onSave,
  pendingChanges,
}: {
  text: string;
  onSave: () => any;
  pendingChanges: boolean;
}) {
  const classes = useTableStyles();
  return (
    <Button
      data-testid="saveButton"
      variant="contained"
      size="small"
      onClick={onSave}
      className={classes.saveButton}
      startIcon={<SaveIcon />}
      disabled={!pendingChanges}
    >
      {t(text)}
    </Button>
  );
}

export function TableCancelButton({
  text,
  onCancel,
  pendingChanges,
}: {
  text: string;
  onCancel: () => any;
  pendingChanges: boolean;
}) {
  const classes = useTableStyles();
  return (
    <Button
      data-testid="cancelButton"
      variant="contained"
      size="small"
      onClick={onCancel}
      className={classes.cancelButton}
      startIcon={<ClearIcon />}
      disabled={!pendingChanges}
    >
      {t(text)}
    </Button>
  );
}

export function TableExportButton({
  text,
  onClick,
  pendingChanges,
}: {
  text: string;
  onClick: () => any;
  pendingChanges: boolean;
}) {
  const classes = useTableStyles();
  return (
    <Button
      data-testid="exportButton"
      variant="contained"
      size="small"
      onClick={onClick}
      className={classes.cancelButton}
      startIcon={<GetAppIcon />}
      disabled={!pendingChanges}
    >
      {t(text)}
    </Button>
  );
}
