import { Chip, IconButton, makeStyles, TableCell } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { findTimeZone, getZonedTime } from "timezone-support";
import { colors } from "../../../colors";
import {
  minutesInAnHour,
  numberOfEggsInACase,
  numberOfEggsInADozen,
} from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import { StyledTableCell, StyledTableRow } from "../../UI/Table/Table";
import { IAlarm, splitOnCapital } from "../AddOrUpdateAlarm/Common";
import {
  AlarmEggsProdUnit,
  AlarmThroughputUnit,
  AlarmTimeUnit,
  AlarmTopic,
} from "../Types";

interface Props {
  row: IAlarm;
  timeZone: any;
  onDelete: (alarmId: number) => void;
  onEdit: (alarm: IAlarm) => void;
}

export type AlertStatus = "active" | "passive";

const useStyles = makeStyles((theme) => ({
  status: {
    margin: "5px",
    color: "#fff",
    padding: "3px",
    textAlign: "center",
    borderRadius: "15px",
  },
  statusChip: {
    color: "white",
    margin: theme.spacing(1),
  },
}));

function determineColor(status: AlertStatus): string {
  return status === "active" ? colors.activeChip : colors.grey40;
}

function getUnitLabel(topic: AlarmTopic, level: number, levelUnit: number) {
  switch (topic.valueOf().toString()) {
    case "TotalEggs":
      switch (levelUnit) {
        case AlarmEggsProdUnit.Eggs:
          return `${level} eggs`;
        case AlarmEggsProdUnit.Dozens:
          return `${level / numberOfEggsInADozen} dozens`;
        case AlarmEggsProdUnit.Cases:
          return `${level / numberOfEggsInACase} cases`;
      }
      break;
    case "Throughput":
      switch (levelUnit) {
        case AlarmThroughputUnit.EpH:
          return `${level} eggs/h`;
        case AlarmThroughputUnit.DpH:
          return `${level / numberOfEggsInADozen} dozens/h`;
        case AlarmThroughputUnit.CpH:
          return `${level / numberOfEggsInACase} cases/h`;
      }
      break;
    case "MachineConnection":
      switch (levelUnit) {
        case AlarmTimeUnit.Hours:
          return `${level / minutesInAnHour} hours`;
        case AlarmTimeUnit.Minutes:
          return `${level} minutes`;
      }
      break;
    case "ProductionTime":
      return `${level} minutes`;
  }
  return `${level} %`;
}

function AlarmRow({ row, timeZone, onDelete, onEdit }: Props) {
  const classes = useStyles();
  const status = row.status.toString().toLowerCase();
  return (
    <StyledTableRow>
      <StyledTableCell>{splitOnCapital(row.topic.toString())}</StyledTableCell>

      <StyledTableCell>{row.user.name}</StyledTableCell>

      <StyledTableCell>
        {getUnitLabel(row.topic, row.level, row.levelUnit)}
      </StyledTableCell>

      <StyledTableCell>{row.condition}</StyledTableCell>

      <StyledTableCell>{row.frequency}</StyledTableCell>

      <TimeCell time={row.startTimeUtc} timeZone={timeZone} />

      <TimeCell time={row.endTimeUtc} timeZone={timeZone} />

      <TableCell>
        <Chip
          label={t(`alarmManagement.status.${status}`)}
          className={classes.statusChip}
          style={{
            backgroundColor: determineColor(
              (status as AlertStatus) || "active"
            ),
          }}
        />
      </TableCell>

      <TableCell align="left">
        <IconButton color="primary" onClick={() => onEdit(row)}>
          <EditIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => {
            onDelete(row.alarmId);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </StyledTableRow>
  );
}

function TimeCell({ time, timeZone }: { time: Date | null; timeZone: string }) {
  if (!time || !timeZone) {
    return <StyledTableCell>-</StyledTableCell>;
  }
  const asDate: Date = new Date(time);
  const asDateZoned = getZonedTime(asDate, findTimeZone(timeZone));
  const hours = asDateZoned.hours.toString().padStart(2, "0");
  const mins = asDateZoned.minutes.toString().padStart(2, "0");
  return <StyledTableCell>{`${hours}:${mins}`}</StyledTableCell>;
}

export default AlarmRow;
