import { laneColors } from "../../../colors";
import { EggUnit } from "../../../utility/EggUnitUtility";
import { t } from "../../../utility/TranslateUtility";
import LaneProductsChart from "../LaneProducts/LaneProductsChart";
import {
  laneNumberToType,
  LaneProductsView,
  MapProductsResponse,
  PackingLane,
} from "../LaneProducts/LaneProductsTypes";

const weightColors = laneColors.weight;

function mapProductsToWeightDistribution(
  productLanes: PackingLane[],
  unit: EggUnit
): MapProductsResponse {
  const data: any[] = [];

  productLanes.forEach((l) => {
    l.packingLaneGrades.forEach((p) => {
      if (p.grade >= 10) return;
      data.push({
        x: l.packingLaneNumber,
        y: p.count,
        color: weightColors[p.grade],
        custom: {
          lane: l,
          laneType: laneNumberToType(l.packingLaneNumber),
          units: unit,
        },
      });
    });
  });

  return {
    name: "Production",
    showInLegend: false,
    data: data,
  };
}

export function LaneProductsDistribution({
  data,
  isAwaitingResponse,
  units,
  onUnitsChange,
  isRemainderEnabled,
}: {
  data: LaneProductsView;
  isAwaitingResponse: boolean;
  units: EggUnit;
  onUnitsChange: (units: EggUnit) => void;
  isRemainderEnabled: boolean;
}) {
  return (
    <LaneProductsChart
      cardTitle={t("widgetList.laneProductDistribution.title")}
      isRemainderEnabled={isRemainderEnabled}
      data={data}
      isAwaitingResponse={isAwaitingResponse}
      units={units}
      onUnitsChange={onUnitsChange}
      mapProducts={mapProductsToWeightDistribution}
    />
  );
}
