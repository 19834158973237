import React, { useCallback, useEffect, useState } from "react";
import { useReportService } from "../../../api/ServiceContext";
import useRefresh from "../../Dashboard/useRefresh";
import MachineSpeedHourWidgetPresentation, {
  MachineSpeedHourApiData,
  MachineSpeedHourApiResponse,
} from "./MachineSpeedHour";

export type MachineSpeedHourWidgetContainerProps = {
  machineId: number;
  automaticRefresh: boolean;
};

/**
 * Shows the average machine speed per hour for the last 30 hours.
 * The machine speed is the number of carriers divided by the runtime.
 */
export default function MachineSpeedHourWidgetContainer({
  machineId,
  automaticRefresh,
}: MachineSpeedHourWidgetContainerProps) {
  const reportService = useReportService();
  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [data, setData] = useState<MachineSpeedHourApiData | any>(null);

  const fetchData = useCallback(
    (newMachineId: number) => {
      reportService
        .getMachineSpeedHour(newMachineId)
        .then((response: MachineSpeedHourApiResponse) => {
          setAwaitingResponse(false);
          const machineSpeedHourPeriodLength =
            response.data.machineSpeedHourPeriods.length - 1;

          if (machineSpeedHourPeriodLength < 0) {
            return;
          }

          setData(response.data);
        });
    },
    [reportService]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return (
    <MachineSpeedHourWidgetPresentation
      awaitingResponse={awaitingResponse}
      data={data}
    />
  );
}
