import React from "react";
import { DatePeriod } from "../../../utility/DateUtility";
import { t } from "../../../utility/TranslateUtility";
import { ToolbarButtonGroup } from "./ToolbarButtonGroup";

const ButtonGroupItems = {
  Day: "toolbarDatePeriodButtonGroup.dayPeriod",
  Week: "toolbarDatePeriodButtonGroup.weekPeriod",
  Month: "toolbarDatePeriodButtonGroup.monthPeriod",
  Year: "toolbarDatePeriodButtonGroup.yearPeriod",
};

const AllDatePeriods = [
  DatePeriod.Day,
  DatePeriod.Week,
  DatePeriod.Month,
  DatePeriod.Year,
];

const toList = (buttonGroupItems: {
  Day: string;
  Week: string;
  Month: string;
  Year: string;
}) => {
  return [
    buttonGroupItems.Day,
    buttonGroupItems.Week,
    buttonGroupItems.Month,
    buttonGroupItems.Year,
  ];
};

const toString = (datePeriod: DatePeriod) => {
  switch (datePeriod) {
    case DatePeriod.Day:
      return "Day";
    case DatePeriod.Week:
      return "Week";
    case DatePeriod.Month:
      return "Month";
    case DatePeriod.Year:
      return "Year";
    default:
      return "Unknown";
  }
};

export interface ToolbarDatePeriodButtonGroupProps {
  selectedDatePeriod: DatePeriod;
  onDatePeriodChange: (newDatePeriod: DatePeriod) => void;
  supportedPeriods?: DatePeriod[];
}

/**
 * Renders various date periods as a ButtonGroup.
 */
export function ToolbarDatePeriodButtonGroup({
  selectedDatePeriod,
  onDatePeriodChange,
  supportedPeriods,
}: ToolbarDatePeriodButtonGroupProps) {
  const itemsToUse = supportedPeriods
    ? supportedPeriods.map((s) => ButtonGroupItems[toString(s)])
    : toList(ButtonGroupItems);

  const handlePeriodChange = (index: number) => {
    const datePeriod = supportedPeriods
      ? supportedPeriods[index]
      : AllDatePeriods[index];
    onDatePeriodChange(datePeriod);
  };

  const selectedIndex = supportedPeriods
    ? supportedPeriods.indexOf(selectedDatePeriod)
    : AllDatePeriods.indexOf(selectedDatePeriod);

  return (
    <ToolbarButtonGroup
      label={t("toolbarDatePeriodButtonGroup.label")}
      buttonGroupItems={itemsToUse}
      selectedDatePeriod={selectedIndex}
      onDatePeriodChange={handlePeriodChange}
    />
  );
}
