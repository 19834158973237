import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HC_offline_exporting from "highcharts/modules/offline-exporting";
import _ from "lodash";
import { useMemo } from "react";
import { colors } from "../../colors";
import { IPerformanceProFilterOptions } from "../../store/GlobalContext";
import { DatePeriod } from "../../utility/DateUtility";
import { GetEggUnitTranslation } from "../../utility/EggUnitUtility";
import { EggType, FormatEggs } from "../../utility/EggUtility";
import { t } from "../../utility/TranslateUtility";
import { PerformancePeriod } from "../PerformancePro/Types";
import { format, TooltipBuilder } from "../ProductionViews/Tooltip";
import { TargetsView } from "../Settings/Targets/Types";
import { defaultExportButtonSettings } from "./ChartSettings/BarChartSettings";

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_offline_exporting(Highcharts);

export interface Props {
  data: any;
  yAxisKey: string;
  options: IPerformanceProFilterOptions;
  setSpeed: Array<PerformancePeriod> | undefined;
  targets: TargetsView | null;
}

export default function EggProductionChart({
  data,
  yAxisKey,
  options,
  setSpeed,
  targets,
}: Props) {
  const newOptions = useMemo(() => {
    if (targets === null) return {};

    const groupedPerEggType = _.groupBy(data, "eggType");
    let series = determineColumnSeries(
      groupedPerEggType,
      yAxisKey,
      options,
      targets
    );

    let yAxisLabel = determineUnitsPerTimeLabel(options, targets);

    series = series.concat(determineSetSpeedSeries(setSpeed, options, targets));
    series = series.concat(
      determineWeightedTargetSpeedSeries(groupedPerEggType, options, targets)
    );

    const eggProductionColors = [
      colors.cageEgg,
      colors.barnEgg,
      colors.freeRangeEgg,
      colors.organicEgg,
      colors.unmappedEgg,
      colors.mobaOrange,
      colors.mobaBlue,
    ];

    return {
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        label: {
          enabled: false,
        },
        column: {
          stacking: "normal",
          pointPlacement:
            options.selectedDatePeriod === DatePeriod.Day
              ? "between"
              : undefined,
        },
        spline: {
          pointPlacement:
            options.selectedDatePeriod === DatePeriod.Day
              ? "between"
              : undefined,
        },
      },
      series: series,
      title: {
        text: "",
      },
      tooltip: createEggProductionTooltipFormatter(
        series,
        eggProductionColors,
        options.selectedDatePeriod,
        targets,
        options
      ),
      xAxis: {
        dateTimeLabelFormats: {
          day:
            options.selectedDatePeriod === DatePeriod.Year
              ? "%b %y"
              : "%e %b %y",
          month: "%b %y",
        },
        minorGridLineWidth: 0,
        minorTicks: true,
        showFirstLabel: true,
        showLastLabel: true,
        startOfWeek: 1,
        tickInterval: 0,
        type: "datetime",
      },
      yAxis: {
        min: 0,
        title: {
          text: yAxisLabel,
        },
      },
      ...defaultExportButtonSettings,
    };
  }, [targets, data, yAxisKey, options, setSpeed]);

  return <HighchartsReact highcharts={Highcharts} options={newOptions} />;
}

function determineUnitsPerTimeLabel(
  options: IPerformanceProFilterOptions,
  targets: TargetsView | null
) {
  return `${GetEggUnitTranslation(targets?.units.eggUnit)} / 
  ${getSelectedDatePeriodComponent(options.selectedDatePeriod)}`;
}

function determineColumnSeries(
  groupedPerEggType: any,
  yAxisKey: string,
  options: IPerformanceProFilterOptions,
  targets: TargetsView | null
): any[] {
  let series = [];

  for (let eggTypeKey in groupedPerEggType) {
    let eggType = EggType[Number(eggTypeKey)];
    if (
      eggType === "Unknown" ||
      eggType === "Mapped" ||
      eggType === "AllEggTypes"
    )
      continue;

    let groupPeriods = groupedPerEggType[eggTypeKey];

    let color = determineEggTypeColor(eggType);

    const result = {
      name: `${EggType[Number(eggTypeKey)]} ${GetEggUnitTranslation(
        targets?.units.eggUnit
      )} / 
      ${getSelectedDatePeriodComponent(options.selectedDatePeriod)}`,
      type: "column",
      color: color,
      data: groupPeriods.map((item: any) => [
        item.dateTime.getTime(),
        item[yAxisKey],
      ]),
    };

    series.push(result);
  }

  return series;

  function determineEggTypeColor(eggType: string) {
    let color;
    switch (eggType) {
      case "Cage": {
        color = colors.cageEgg;
        break;
      }
      case "Barn": {
        color = colors.barnEgg;
        break;
      }
      case "FreeRange": {
        color = colors.freeRangeEgg;
        break;
      }
      case "Organic": {
        color = colors.organicEgg;
        break;
      }
      case "Unmapped": {
        color = colors.unmappedEgg;
        break;
      }
    }
    return color;
  }
}

function determineSetSpeedSeries(
  setSpeed: any,
  options: IPerformanceProFilterOptions,
  targets: TargetsView | null
): any {
  const groupedPerEggType = _.groupBy(setSpeed, "eggType");
  let periods = groupedPerEggType[EggType.Unknown];
  if (periods === undefined || periods.length === 0) return {};

  let data = [];
  for (let i = 0; i < periods.length; i++) {
    if (periods[i] !== undefined) {
      data.push([periods[i].dateTime.getTime(), periods[i].value]);
    }
  }

  return {
    name: `Set Speed ${GetEggUnitTranslation(targets?.units.eggUnit)} / 
    ${getSelectedDatePeriodComponent(options.selectedDatePeriod)}`,
    type: "spline",
    color: colors.mobaOrange,
    data: data,
  };
}

function determineWeightedTargetSpeedSeries(
  groupedPerEggType: any,
  options: IPerformanceProFilterOptions,
  targets: TargetsView | null
): any {
  let totalGroupPeriods = groupedPerEggType[EggType.Mapped];
  if (totalGroupPeriods === undefined || totalGroupPeriods.length === 0)
    return {};

  let data = [];
  for (let i = 0; i < totalGroupPeriods.length; i++) {
    let weightedTargetSpeedForHour = 0;
    let totalValue = totalGroupPeriods[i].value;

    for (let eggTypeKey in groupedPerEggType) {
      if (
        targets === null ||
        Number(eggTypeKey) === EggType.Unknown ||
        Number(eggTypeKey) === EggType.Mapped ||
        Number(eggTypeKey) === EggType.AllEggTypes
      )
        continue;

      let groupPeriods = groupedPerEggType[eggTypeKey];
      if (groupPeriods === undefined || groupPeriods.length === 0) return {};

      let value = groupPeriods[i].value;
      let ratio = value !== 0 ? value / totalValue : 0;

      let eggTypeIndex = targets.targetsPerEggtypes.findIndex(
        (x) => Number(x.eggType) === Number(eggTypeKey)
      );
      let settingsSpeed = targets.targetsPerEggtypes[eggTypeIndex]?.setSpeed;
      weightedTargetSpeedForHour +=
        settingsSpeed === undefined ? 0 : ratio * settingsSpeed;
    }

    data.push([
      totalGroupPeriods[i].dateTime.getTime(),
      weightedTargetSpeedForHour,
    ]);
  }

  return {
    name: `${t(
      "uniqueViews.eggProduction.weightedTargetSpeedSeriesName"
    )} ${GetEggUnitTranslation(targets?.units.eggUnit)} /
     ${getSelectedDatePeriodComponent(options.selectedDatePeriod)}`,
    type: "spline",
    color: colors.mobaBlue,
    data: data,
  };
}

function getSelectedDatePeriodComponent(selectedDatePeriod: DatePeriod) {
  switch (selectedDatePeriod) {
    case DatePeriod.Day: {
      return t("uniqueViews.dayPeriodComponent");
    }
    case DatePeriod.Week: {
      return t("uniqueViews.weekPeriodComponent");
    }
    case DatePeriod.Month: {
      return t("uniqueViews.monthPeriodComponent");
    }
    case DatePeriod.Year: {
      return t("uniqueViews.yearPeriodComponent");
    }
    default: {
      return "Day";
    }
  }
}

function createEggProductionTooltipFormatter(
  series: any[],
  eggProductionColors: string[],
  selectedDatePeriod: DatePeriod,
  targets: TargetsView,
  options: IPerformanceProFilterOptions
) {
  return new TooltipBuilder()
    .addXProductionSubCharts(selectedDatePeriod)
    .add((data: any) => {
      let parts = "";
      if (data.length < series.length) return parts;
      for (let i = 0; i < series.length; i++) {
        const dataPoint = (series[i].data as any[]).find(
          (x) => x[0] === data.x
        );
        if (dataPoint === undefined) return parts;
        parts += format(
          {
            label: `<span style="color:${eggProductionColors[i]}">\u25CF</span> ${series[i].name}`,
            separator: true,
          },
          FormatEggs(dataPoint[1], targets?.units.eggUnit ?? null, false)
        );
      }

      const total = determineTotal(data);

      const labelText =
        `${t("uniqueViews.eggProduction.total")} ` +
        determineUnitsPerTimeLabel(options, targets);

      parts += format(
        {
          label: `<span style="color:${colors.priorityRed}">\u25CF</span> ${labelText}`,
          separator: true,
        },
        FormatEggs(total, targets?.units.eggUnit ?? null, false)
      );

      return parts;
    })
    .buildSharedFormatter();

  function determineTotal(data: any) {
    let total = 0;

    for (let shldf of series.filter((x) => x.type === "column")) {
      total += shldf.data
        .filter((y: any) => y[0] === data.x)
        .map((x: number[]) => x[1])
        .reduce((a: number, b: number) => a + b, 0);
    }
    return total;
  }
}
