import React, { useReducer } from "react";
import {
  IGlobalState,
  GlobalContext,
  initialState as defaultState,
} from "./GlobalContext";
import { Reducer } from "./Reducer";

export interface GlobalContextProviderProps {
  initialState?: IGlobalState;
  children: any;
}

/**
 * Enables Global State for the children components.
 *
 * @param initialState The state is used to initialize the global state. If null, a default state is loaded instead.
 * @param children The children to wrap with the GlobalContext.Provider
 */
export function GlobalContextProvider({
  initialState,
  children,
}: GlobalContextProviderProps) {
  return (
    <GlobalContext.Provider
      value={useReducer(Reducer, { ...defaultState, ...initialState })}
    >
      {children}
    </GlobalContext.Provider>
  );
}
