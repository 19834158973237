import {
  EggUnit,
  GetEggUnitTranslation,
} from "../../../utility/EggUnitUtility";
import { createUnitsConverter, FormatEggs } from "../../../utility/EggUtility";
import { FormatWithTwoDecimals } from "../../../utility/NumberFormatUtility";
import { ChartTitle } from "../../ProductionViews/Common";
import {
  getHoursAndMinutesFromSeconds,
  getHoursAndMinutesLabel,
} from "../../ProductionViews/Tooltip";
import { ValueType } from "../Types";

interface Props {
  valueType: ValueType;
  summation: number;
  eggUnit: EggUnit | null;
}

export function PerformanceProChartTitle({
  valueType,
  summation,
  eggUnit,
}: Props) {
  const inUnits = createUnitsConverter(eggUnit);

  const renderLabel = (): any => {
    switch (valueType) {
      case "Percentage":
        return <PercentageLabel value={summation} />;
      case "Count":
        return <EggLabel value={inUnits(summation)} eggUnit={eggUnit} />;
      case "ValueOverTime":
        return <ValueOfTime value={inUnits(summation)} eggUnit={eggUnit} />;
      case "Time":
        return <TimeLabel value={summation} />;
      default:
        return summation;
    }
  };

  return <ChartTitle>{renderLabel()}</ChartTitle>;
}

function EggLabel({
  value,
  eggUnit,
}: {
  value: number;
  eggUnit: EggUnit | null;
}) {
  return (
    <>{`${FormatEggs(value, eggUnit, false)} ${GetEggUnitTranslation(
      eggUnit
    )}`}</>
  );
}

function ValueOfTime({
  value,
  eggUnit,
}: {
  value: number;
  eggUnit: EggUnit | null;
}) {
  const perHourPostfix = "pH";
  return (
    <>{`${FormatEggs(value, eggUnit, false)} ${
      GetEggUnitTranslation(eggUnit).substring(0, 1) + perHourPostfix
    }`}</>
  );
}

function PercentageLabel({ value }: { value: number }) {
  return <>{`${FormatWithTwoDecimals(value)} %`}</>;
}

function TimeLabel({ value }: { value: number }) {
  return <>{getHoursAndMinutesLabel(getHoursAndMinutesFromSeconds(value))}</>;
}
