import { format } from "date-fns";
import { IHttpClient } from "../IHttpClient";
import { GetBenchmarkDataQuery, IBenchmarkService } from "./IBenchmarkService";

export class BenchmarkService implements IBenchmarkService {
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }
  getComparedMachineCount(query: GetBenchmarkDataQuery): Promise<any> {
    const requestParams = this.getRequestParams(query);

    return this.get(
      `v1/benchmark/machines-comparison/compared-machine-count`,
      requestParams
    );
  }

  getBenchmarkData(query: GetBenchmarkDataQuery) {
    const requestParams = this.getRequestParams(query);

    return this.get(
      `v1/benchmark/machines-comparison/${query.measure}`,
      requestParams
    );
  }

  getCountries(): Promise<any> {
    return this.get(`v1/benchmark/countries/`);
  }

  getMachineTypes(): Promise<any> {
    return this.get(`v1/benchmark/machine-types/`);
  }

  private get(url: string, params?: any): Promise<any> {
    return this.httpclient.get(url, params);
  }

  private getRequestParams(query: GetBenchmarkDataQuery) {
    const queryFromDate = format(query.fromDate, "yyyy-MM-dd HH:mm:ss");
    const queryUntilDate = format(query.untilDate, "yyyy-MM-dd HH:mm:ss");

    return {
      params: {
        periodType: query.periodInYearType,
        countries: query.countries,
        machineTypes: query.machineTypes,
        machines: query.machines,
        fromDate: queryFromDate,
        untilDate: queryUntilDate,
        eggTypes: query.eggTypes,
        supplyChangesMin: query.supplyChanges.min,
        supplyChangesMax: query.supplyChanges.max,
        productChangesMin: query.productChanges.min,
        productChangesMax: query.productChanges.max,
        upgradingPercentageMin: query.upgradingPercentage.min,
        upgradingPercentageMax: query.upgradingPercentage.max,
      },
    };
  }
}
