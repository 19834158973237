import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import * as Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import xrange from "highcharts/modules/xrange";
import { useMemo } from "react";
import { colors } from "../../../colors";
import { getLastElement } from "../../../utility/ArrayUtility";
import { subtractMinutes } from "../../../utility/DateUtility";
import { t } from "../../../utility/TranslateUtility";
import { XRangeChartSettings } from "../../Charts/ChartSettings/XRangeChartSettings";
import { TooltipBuilder } from "../../ProductionViews/Tooltip";
import HighchartsFullscreenWrapper, {
  RenderPosition,
} from "../Wrappers/HighchartsFullscreenWrapper";

// Enable special charts
HC_more(Highcharts);
xrange(Highcharts);

export type MachineStatesApiResponse = {
  data: MachineStatesApiData;
};

export type MachineStatesApiData = {
  machineStatePeriods: Array<MachineStatePeriod>;
};

export type MachineStatePeriod = {
  machineState: MachineState;
  startTime: Date;
  endTime: Date;
};

export enum MachineState {
  Unknown,
  Running,
  MachineStop,
  StaffBreak,
}

const tooltipFormatter = new TooltipBuilder()
  .addXHourWithMinutesWithSecondsPeriod(
    t("performanceViews.fillRateProduction.tooltipTime")
  )
  .addMachineStateWithTransformation({
    label: t("widgets.machineStates.tooltipValue"),
    unit: "",
    transformation: (machineState: MachineState) =>
      DetermineMachineStateLabel(machineState),
  })
  .buildFormatter();

const ChartSettings: any = {
  ...XRangeChartSettings,
  tooltip: tooltipFormatter,
};

const determineBackgroundColor = (props: MachineState) => {
  switch (props) {
    case MachineState.Running:
      return colors.machineRunning;
    case MachineState.MachineStop:
      return colors.machineStop;
    case MachineState.StaffBreak:
      return colors.staffBreak;
    case MachineState.Unknown:
      return colors.white;
    default:
      return colors.white;
  }
};

function determineColor(machineState: MachineState) {
  switch (machineState) {
    case MachineState.Running:
      return colors.machineRunning;
    case MachineState.MachineStop:
      return colors.machineStop;
    case MachineState.StaffBreak:
      return colors.staffBreak;
    case MachineState.Unknown:
      return colors.white;
    default:
      return colors.white;
  }
}

function MapToChartData(input: MachineStatesApiData) {
  if (input === null) {
    return {
      series: [],
      maxDate: null,
      minDate: null,
    };
  }

  const last_element = getLastElement(input.machineStatePeriods);
  const newMax = last_element.endTime;

  const result = input.machineStatePeriods.map((period: any) => ({
    x: period.startTime.getTime(),
    x2: period.endTime.getTime(),
    machineState: period.machineState,
    y: 0,
    color: determineColor(period.machineState),
  }));

  const maxDate = newMax.getTime();
  const minDate = subtractMinutes(newMax, 30);

  return {
    series: result,
    maxDate: maxDate,
    minDate: minDate,
  };
}

export interface MachineStatesWidgetPresentationProps {
  awaitingResponse: boolean;
  data: MachineStatesApiData;
}

export default function MachineStatesWidgetPresentation({
  awaitingResponse,
  data,
}: MachineStatesWidgetPresentationProps) {
  const newOptions = useMemo(() => {
    if (awaitingResponse) return ChartSettings;

    const newChartData = MapToChartData(data);

    return {
      ...ChartSettings,
      xAxis: {
        ...ChartSettings.xAxis,
        max: newChartData?.maxDate,
        min: newChartData?.minDate,
      },
      series: [
        {
          name: "",
          showInLegend: false,
          data: newChartData?.series,
        },
      ],
    };
  }, [data, awaitingResponse]);

  return (
    <HighchartsFullscreenWrapper
      title={t("widgets.machineStates.title")}
      awaitingResponse={awaitingResponse}
      highcharts={Highcharts}
      chartOptions={newOptions}
      customFullscreenHeight={"90vh"}
      customJsx={<MachineStateLegend />}
      renderPosition={RenderPosition.Bottom}
    />
  );
}

function MachineStateLegend() {
  const classes = useStyles();
  return (
    <div className={classes.legend}>
      <MachineStateLegendItem variant={MachineState.Running} />
      <MachineStateLegendItem variant={MachineState.StaffBreak} />
      <MachineStateLegendItem variant={MachineState.MachineStop} />
    </div>
  );
}

function DetermineMachineStateLabel(variant: MachineState) {
  switch (variant) {
    case MachineState.Running:
      return t("widgets.machineStates.legendLabels.machineRunning");
    case MachineState.MachineStop:
      return t("widgets.machineStates.legendLabels.machineStop");
    case MachineState.StaffBreak:
      return t("widgets.machineStates.legendLabels.staffBreak");
    case MachineState.Unknown:
      return t("widgets.machineStates.legendLabels.unknown");
    default:
      return t("widgets.machineStates.legendLabels.unknown");
  }
}

function MachineStateLegendItem({ variant }: any) {
  const classes = useLegendItemStyles(variant);

  return (
    <div className={classes.legendItem}>
      <div className={clsx(classes.machineRunningLegendItem)} />
      <Typography>{DetermineMachineStateLabel(variant)}</Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingTop: 0,
  },
  cardHeader: {
    paddingBottom: 0,
  },
  legend: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
}));

const useLegendItemStyles = makeStyles((theme) => ({
  machineRunningLegendItem: {
    padding: "10px",
    width: "0",
    height: "0",
    marginRight: theme.spacing(1),
    backgroundColor: (props: MachineState) => determineBackgroundColor(props),
  },
  legendItem: {
    display: "flex",
    marginRight: "32px",
  },
}));
