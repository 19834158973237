import React, { useMemo } from "react";
import { colors } from "../../../../colors";
import {
  ColorSchemaRowType,
  ReportSettings,
} from "../../../../pages/management-settings/Common";
import ColorSchemasPresentation from "./ColorSchemasPresentation";

function mapSchemaNumbers(source: ColorSchemaRowType[]): ColorSchemaRowType[] {
  const defaultColor = source.find((s) => s.schemaNumber === 0)!;
  let withoutDefault = [...source].filter((x) => x.schemaNumber !== 0);
  withoutDefault.sort((a, b) => a.targetValue - b.targetValue);
  withoutDefault = withoutDefault.map<ColorSchemaRowType>((c, i) => ({
    colorHex: c.colorHex,
    identifier: c.identifier,
    targetValue: c.targetValue,
    schemaNumber: i + 1,
  }));

  defaultColor.targetValue = Math.max(
    ...withoutDefault.map((x) => x.targetValue)
  );

  return [defaultColor, ...withoutDefault];
}

export default function ColorSchemasContainer({
  settings,
  onSettingsChange,
  showDuplicateColorSchemaError,
}: {
  settings: ReportSettings;
  onSettingsChange: (settings: ReportSettings) => void;
  showDuplicateColorSchemaError: boolean;
}) {
  const handleNewColorSchema = () => {
    const nextIdentifier =
      Math.max(...settings.colorSchemas.map((c) => c.identifier)) + 1;

    const newColorSchema: ColorSchemaRowType = {
      identifier: nextIdentifier,
      schemaNumber: 1,
      targetValue: 0,
      colorHex: colors.mobaBlue,
    };

    const newColors = mapSchemaNumbers([
      ...settings.colorSchemas,
      newColorSchema,
    ]);

    onSettingsChange({
      ...settings,
      colorSchemas: newColors,
    });
  };

  const handleOnDeleteRow = (identifier: number) => {
    const newSchemas: ReportSettings = {
      ...settings,
      colorSchemas: [
        ...mapSchemaNumbers(
          settings.colorSchemas.filter((s) => s.identifier !== identifier)
        ),
      ],
    };
    onSettingsChange(newSchemas);
  };

  const handleRemoveAllColorSchemas = () => {
    onSettingsChange({
      ...settings,
      colorSchemas: [
        ...settings.colorSchemas.filter((s) => s.schemaNumber === 0),
      ],
    });
  };

  const handleOnValueChanged = (
    newTargetValue: number,
    newColorHex: string,
    identifier: number
  ) => {
    const unchangedItems = settings.colorSchemas.filter(
      (s) => s.identifier !== identifier
    );
    const changedItem: ColorSchemaRowType = {
      ...settings.colorSchemas.find((s) => s.identifier === identifier)!,
      colorHex: newColorHex,
      targetValue: newTargetValue,
    };

    onSettingsChange({
      ...settings,
      colorSchemas: mapSchemaNumbers([...unchangedItems, changedItem]),
    });
  };

  const rowsToDisplay = useMemo(() => {
    if (settings.colorSchemas.length === 0) return [];

    const defaultColor = settings.colorSchemas.find(
      (s) => s.schemaNumber === 0
    )!;
    const otherColors = settings.colorSchemas.filter(
      (s) => s.schemaNumber !== 0
    )!;

    return [...otherColors, defaultColor];
  }, [settings.colorSchemas]);

  return (
    <ColorSchemasPresentation
      rowsToDisplay={rowsToDisplay}
      onNewColorSchema={handleNewColorSchema}
      onDeleteRow={handleOnDeleteRow}
      onValueChanged={handleOnValueChanged}
      onRemoveAllColorSchemas={handleRemoveAllColorSchemas}
      showDuplicateColorSchemaError={showDuplicateColorSchemaError}
    />
  );
}
