import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { determineEggUnitByIndex, menuItems } from "../../../utility/EggUtility";
import FullscreenCardWrapper from "./FullscreenCardWrapper";
import { t } from "../../../utility/TranslateUtility";

/**
 * A wrapper for Widgets that use Eggs as Display Unit
 * Offers a menu item for various Egg Display Units and allows for fullscreen rendering of the component.
 *
 * @param children The component to render in this wrapper
 * @param awaitingResponse Determines whether to display a loading component
 * @param onMenuItemClicked Callback for when the user clicks on a DisplayUnit menu item
 * @param title The title of the card
 * @param onFullscreen Callback for when the children are rendered in fullscreen
 * @param fullscreenVariant An option to determine which kind of fullscreen rendering is used
 */
function EggDisplayUnitCardWrapper({
  children,
  awaitingResponse,
  onMenuItemClicked,
  title,
  onFullscreen,
  fullscreenVariant,
  disableFullscreen,
  isPerformancePro,
  defaultEggUnit,
}: any) {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <FullscreenCardWrapper
        title={title}
        awaitingResponse={awaitingResponse}
        fullscreenVariant={fullscreenVariant}
        disableFullscreen={disableFullscreen}
        onFullscreen={onFullscreen}
        cardAction={
          <EggUnitSelectionButton onClick={(event: any) => setAnchorEl(event.currentTarget)} />
        }
        isPerformancePro={isPerformancePro}
      >
        {children}
        <EggUnitMenu anchorElement={anchorEl} onClose={() => setAnchorEl(null)} onMenuItemClicked={onMenuItemClicked} defaultEggUnit={defaultEggUnit} />
      </FullscreenCardWrapper>
    </>
  );
}

export function EggUnitMenu({
  anchorElement,
  onClose,
  onMenuItemClicked,
  defaultEggUnit,
}: {
  anchorElement: Element | null;
  onMenuItemClicked: any;
  onClose: any;
  defaultEggUnit: any;
}) {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleMenuItemClick = (event: any, index: number) => {
    if (index === -1) {
      index = 1
    }
    setSelectedIndex(index);
    onClose();
    onMenuItemClicked(determineEggUnitByIndex(index));
  };

  return (
    <Menu
      anchorEl={anchorElement}
      keepMounted
      open={Boolean(anchorElement)}
      onClose={() => onClose()}
    >
      <MenuItem disabled></MenuItem>
      {menuItems.map((menuItem, index) => (
        <MenuItem
          key={menuItem}
          id={`menu-item-${index}`}
          disabled={index === 0}
          selected={index === selectedIndex}
          onClick={(event) => handleMenuItemClick(event, index)}
        >
          {t(menuItem)}
        </MenuItem>
      ))}
    </Menu>
  );
}

export function EggUnitSelectionButton({ onClick }: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <IconButton onClick={onClick}>
      <MoreHorizIcon />
    </IconButton>
  );
}

export default EggDisplayUnitCardWrapper;
