import { useEffect, useState } from "react";
import {
  useAlarmService,
  useLocalAlarmService,
} from "../../../api/ServiceContext";
import { usePerformanceProFilterOptions } from "../../../store/GlobalContext";
import { getHoursFromSeconds } from "../../../utility/DateUtility";
import AddOrUpdateAlarmDialogContainer from "../../Alarms/AddOrUpdateAlarm/AddOrUpdateAlarmDialogContainer";
import { emptyAlarm, IAlarm } from "../../Alarms/AddOrUpdateAlarm/Common";
import { AlarmStatus, AlarmTopic } from "../../Alarms/Types";
import { MapToAlarmTopic } from "../../CommonUtil/KpiMeasurementAlarmTopicMapper";
import { ChartMapping } from "../ChartMapping";
import { usePerformanceProMeasure } from "../performanceProHooks";
import { KpiMeasureType, PerformancePeriod } from "../Types";
import { PerformanceProViewPresentation } from "./PerformanceProViewPresentation";

interface AlarmsApiResponse {
  alarms: IAlarm[];
}

interface Props {
  machineId: MachineId;
  measure: KpiMeasureType;
}

export function PerformanceProViewContainer({ machineId, measure }: Props) {
  const {
    chartMapping,
    options,
    periods,
    summation,
    valueType,
    targets,
    awaitingResponse,
  } = usePerformanceProMeasure({ machineId: machineId, measure: measure });
  const [hasAlarm, setHasAlarm] = useState<boolean>(false);
  const [showAlarm, setShowAlarm] = useState<boolean>(false);
  const [alarmForTopic, setAlarmForTopic] =
    useState<IAlarm | undefined>(undefined);
  const [alarmToShow, setAlarmToShow] = useState<IAlarm>(emptyAlarm);

  const [showAlarmPopup, setShowAlarmPopup] = useState<boolean>(false);

  const alarmService = useAlarmService();
  const localAlarmService = useLocalAlarmService();
  const performanceProFilterOptions = usePerformanceProFilterOptions();

  const updatedPeriods = updatePeriods(periods, chartMapping);

  const pickAlarmToShow = () => {
    const alarmTopic = MapToAlarmTopic(measure) || AlarmTopic.TotalEggs;
    return hasAlarm
      ? alarmForTopic!
      : {
          ...emptyAlarm,
          topic: alarmTopic,
          status: AlarmStatus.Active,
        };
  };

  const stopEditing = (retainChanges: boolean) => {
    if (retainChanges) {
      setAlarmForTopic(alarmToShow);
      setHasAlarm(true);
    }
    localAlarmService.stopAddingOrUpdating();
  };

  const handleResponse = (alarms: any[]) => {
    const alarmTopic = MapToAlarmTopic(measure);
    if (alarmTopic !== undefined) {
      const alarm = alarms.find(
        (a) => a.topic.toString() === AlarmTopic[alarmTopic]
      );
      setAlarmForTopic(alarm);
      setHasAlarm(alarm !== undefined);
      setShowAlarm(performanceProFilterOptions.selectedDatePeriod === 0);
    } else {
      setAlarmForTopic(undefined);
    }
  };

  const onShowAlarmPopupClick = (
    showDialog: boolean,
    retainChanges: boolean
  ) => {
    if (showDialog) {
      const alarm = pickAlarmToShow();
      localAlarmService.startAddingOrUpdating(alarm, setAlarmToShow);
      setAlarmToShow(alarm);
    } else {
      stopEditing(retainChanges);
    }
    setShowAlarmPopup(showDialog);
  };

  useEffect(() => {
    alarmService
      .getMyAlarms(machineId)
      .then((response: IApiResponse<AlarmsApiResponse>) => {
        handleResponse(response.data.alarms);
      });
  }, [machineId, measure, performanceProFilterOptions, hasAlarm]);

  return (
    <>
      {showAlarmPopup && (
        <AddOrUpdateAlarmDialogContainer
          isUpdating={hasAlarm}
          timeZone={targets!.units.timezone}
          disableTopic={true}
          closeDialog={(closedOnSave: boolean) =>
            onShowAlarmPopupClick(false, closedOnSave)
          }
          onListUpdated={undefined}
        />
      )}
      <PerformanceProViewPresentation
        chartMapping={chartMapping}
        options={options}
        periods={updatedPeriods}
        summation={summation}
        valueType={valueType}
        targets={targets}
        showAlarm={showAlarm}
        hasAlarmSet={hasAlarm}
        alarm={
          localAlarmService.isAddingOrUpdating() ? alarmToShow : alarmForTopic
        }
        onShowAlarmPopupClick={(showDialog: any) =>
          onShowAlarmPopupClick(showDialog, false)
        }
        awaitingResponse={awaitingResponse}
      />
    </>
  );
}

function updatePeriods(
  periods: PerformancePeriod[],
  chartMapping: ChartMapping
): PerformancePeriod[] {
  if (chartMapping.valueType === "Time") {
    return periods.map((x) => {
      return {
        dateTime: x.dateTime,
        eggType: x.eggType,
        value: getHoursFromSeconds(x.value),
        numerator: x.numerator,
        denominator: x.denominator,
      };
    });
  }
  return periods;
}
