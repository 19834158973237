import { differenceInMinutes } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getBreakTimes,
  updateBreakTimes,
} from "../../../api/ManagementMachine/ManagementSettingService";
import { SettingsQueryNames } from "../../../api/Queries";
import {
  BreakTime,
  BreakTimeApiData,
  BreakTimeStrings,
} from "../../../pages/management-settings/Common";
import { useSelectedMachine } from "../../../store/GlobalContext";
import {
  getUtcDate,
  toISO8601DateTimeStringWithLocalTime,
} from "../../../utility/DateUtility";
import { useDispatchAlert } from "../../UI/Alert/alertHooks";
import BreakTimesPresentation from "./BreakTimesPresentation";

export type BreakTimeRowType = {
  identifier: number;
  breakNumber: number;
  startTime: Date;
  endTime: Date;
};

interface UpdateBreakTimesRequest {
  machineId: MachineId;
  breakTimes: BreakTimeStrings[];
}

function checkForPendingChanges(
  originalBreaktimes: Array<BreakTime>,
  newBreaktimes: Array<BreakTimeRowType>
) {
  if (originalBreaktimes.length !== newBreaktimes.length) return true;
  let originalCopy = [...originalBreaktimes];
  let newBreakTimesCopy = [...newBreaktimes];

  originalCopy.sort(
    (a, b) => a.startBreakTime.getTime() - b.startBreakTime.getTime()
  );
  newBreakTimesCopy.sort(
    (a, b) => a.startTime.getTime() - b.startTime.getTime()
  );

  for (let i = 0; i < originalCopy.length; i++) {
    if (
      originalCopy[i].startBreakTime.getTime() !==
        newBreakTimesCopy[i].startTime.getTime() ||
      originalCopy[i].endBreakTime.getTime() !==
        newBreakTimesCopy[i].endTime.getTime()
    ) {
      return true;
    }
  }

  return false;
}

export default function BreakTimesContainer() {
  const machineId = useSelectedMachine().machineId;
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [originalBreakTimes, setOriginalBreakTimes] = useState<
    Array<BreakTime>
  >([]);
  const [internalBreakTimes, setInternalBreakTimes] = useState<
    Array<BreakTimeRowType>
  >([]);
  const [containsErrors, setContainsErrors] = useState(false);

  const data = useQuery(
    [SettingsQueryNames.GetBreakTimes, [machineId]],
    () => {
      return getBreakTimes(machineId, new Date());
    },
    {
      onSuccess: (response: BreakTimeApiData) => {
        const newOriginalBreakTimes = response.breakTimes.map((x) => ({
          startBreakTime: getUtcDate(new Date(x.startBreakTime)),
          endBreakTime: getUtcDate(new Date(x.endBreakTime)),
        }));
        setOriginalBreakTimes(newOriginalBreakTimes);
        const newBreakTimes = response.breakTimes.map((x, index) => ({
          identifier: index + 1,
          breakNumber: index + 1,
          startTime: getUtcDate(new Date(x.startBreakTime)),
          endTime: getUtcDate(new Date(x.endBreakTime)),
        }));
        setInternalBreakTimes(newBreakTimes);
      },
    }
  );

  const mutation = useMutation(
    (request: UpdateBreakTimesRequest) =>
      updateBreakTimes(request.machineId, request.breakTimes),
    {
      onSuccess: () => {
        const newOriginalBreakTimes = internalBreakTimes.map((x) => ({
          startBreakTime: x.startTime,
          endBreakTime: x.endTime,
        }));
        setOriginalBreakTimes(newOriginalBreakTimes);
        dispatchAlert({
          message: t("managementSettings.breakTimes.successAlertMessage"),
          messageType: "success",
        });
        queryClient.invalidateQueries({
          queryKey: [SettingsQueryNames.GetBreakTimes],
        });
      },
      onError: () => {
        dispatchAlert({
          message: t("generic.errorMessage"),
          messageType: "error",
        });
      },
    }
  );

  /**
   * Recalculate break numbers and sort by starting time
   */
  const rowsToDisplay = useMemo<Array<BreakTimeRowType>>(() => {
    let breakTimesCopy = [...internalBreakTimes];

    breakTimesCopy.sort(
      (a, b) => a.startTime.getTime() - b.startTime.getTime()
    );

    return breakTimesCopy.map((x, index) => ({
      identifier: x.identifier,
      breakNumber: index + 1,
      startTime: x.startTime,
      endTime: x.endTime,
    }));
  }, [internalBreakTimes]);

  const dailyTotalInMinutes = useMemo<number>(() => {
    return internalBreakTimes
      .map((x) => differenceInMinutes(x.endTime, x.startTime))
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
  }, [internalBreakTimes]);

  const handleNewBreak = useCallback(() => {
    const newBreak = {
      identifier: rowsToDisplay.length + 1,
      breakNumber: rowsToDisplay.length + 1,
      startTime: new Date(),
      endTime: new Date(),
    };

    setInternalBreakTimes([...rowsToDisplay, newBreak]);
  }, [rowsToDisplay]);

  const handleOnDeleteRow = useCallback(
    (identifier: number) => {
      const breakTimesCopy = [
        ...internalBreakTimes.filter((x) => x.identifier !== identifier),
      ];

      setInternalBreakTimes(breakTimesCopy);
    },
    [internalBreakTimes]
  );

  const handleRemoveAllBreaks = useCallback(() => {
    setInternalBreakTimes(new Array<BreakTimeRowType>());
  }, []);

  const handleOnTimeChanged = useCallback(
    (startTime: Date, endTime: Date, identifier: number) => {
      const foundIndex = internalBreakTimes.findIndex(
        (x) => x.identifier === identifier
      );
      if (foundIndex === -1) return;

      let foundCopy = { ...internalBreakTimes[foundIndex] };

      foundCopy.startTime = startTime;
      foundCopy.endTime = endTime;

      const internalBreakTimesCopy = [...internalBreakTimes];
      internalBreakTimesCopy[foundIndex] = foundCopy;
      setInternalBreakTimes(internalBreakTimesCopy);
    },
    [internalBreakTimes]
  );

  const handleSaveBreakTimes = useCallback(() => {
    let requestBreakTimes = internalBreakTimes.map((x) => ({
      startBreakTime: toISO8601DateTimeStringWithLocalTime(x.startTime),
      endBreakTime: toISO8601DateTimeStringWithLocalTime(x.endTime),
    }));
    mutation.mutate({ machineId: machineId, breakTimes: requestBreakTimes });
  }, [internalBreakTimes, machineId, mutation]);

  const handleOnError = useCallback(() => {
    setContainsErrors(true);
  }, []);

  const handleOnErrorResolved = useCallback(() => {
    setContainsErrors(false);
  }, []);

  return (
    <BreakTimesPresentation
      rowsToDisplay={rowsToDisplay}
      dailyTotalInMinutes={dailyTotalInMinutes}
      pendingChanges={
        !containsErrors &&
        checkForPendingChanges(originalBreakTimes, internalBreakTimes)
      }
      isAwaitingResponse={data.isFetching}
      onNewBreak={handleNewBreak}
      onDeleteRow={handleOnDeleteRow}
      onTimeChanged={handleOnTimeChanged}
      onRemoveAllBreaks={handleRemoveAllBreaks}
      onSaveClick={handleSaveBreakTimes}
      onError={handleOnError}
      onErrorResolved={handleOnErrorResolved}
    />
  );
}
