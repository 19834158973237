import { addHours } from "date-fns";
import equal from "fast-deep-equal";
import exportToXlsx from "json-as-xlsx";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdateReportHours } from "../../../api/PerformanceProReport/IPerformanceProReportService";
import { usePerformanceProReportService } from "../../../api/ServiceContext";
import {
  usePerformanceProFilterOptions,
  useSelectedMachine,
} from "../../../store/GlobalContext";
import { removeTimezone } from "../../../utility/DateUtility";
import { EggUnit } from "../../../utility/EggUnitUtility";
import { FormatEggs } from "../../../utility/EggUtility";
import {
  FormatSecondsToMinutesAndSeconds,
  roundToDecimal,
} from "../../../utility/NumberFormatUtility";
import { useDispatchAlert } from "../../UI/Alert";
import {
  Labels,
  ProductionReportHourInfo,
  ReportView,
  setCharacteristics,
  setDownTime,
} from "./Common";
import { ReportPerHourPresentation } from "./ReportPerHourPresentation";

interface ReportContainerProps {
  readonly reportView: ReportView | undefined;
  readonly unitType: EggUnit;
}

export function ReportPerHourContainer({
  reportView,
  unitType,
}: ReportContainerProps) {
  const options = usePerformanceProFilterOptions();
  const selectedMachine = useSelectedMachine();
  const dispatchAlert = useDispatchAlert();
  const performanceProReportService = usePerformanceProReportService();
  const { t } = useTranslation();

  const [
    initialProductionReportHoursList,
    setInitialProductionReportHoursList,
  ] = useState<ProductionReportHourInfo[]>([]);
  const [
    currentProductionReportHoursList,
    setCurrentProductionReportHoursList,
  ] = useState<ProductionReportHourInfo[]>([]);

  useEffect(() => {
    if (reportView?.productionReportHours) {
      setInitialProductionReportHoursList(reportView.productionReportHours);
      setCurrentProductionReportHoursList(reportView.productionReportHours);
    }
  }, [reportView]);

  const hasPendingChanges = useMemo(() => {
    return !equal(
      initialProductionReportHoursList,
      currentProductionReportHoursList
    );
  }, [initialProductionReportHoursList, currentProductionReportHoursList]);

  const labels: Labels = useMemo(() => {
    let downtimeInfoLabel1 = reportView?.downtimeInfoLabels.find(
      (x) => x.labelNumber === 0
    );
    let downtimeInfoLabel2 = reportView?.downtimeInfoLabels.find(
      (x) => x.labelNumber === 1
    );
    let downtimeInfoLabel3 = reportView?.downtimeInfoLabels.find(
      (x) => x.labelNumber === 2
    );
    let downtimeInfoLabel4 = reportView?.downtimeInfoLabels.find(
      (x) => x.labelNumber === 3
    );
    let downtimeInfoLabel5 = reportView?.downtimeInfoLabels.find(
      (x) => x.labelNumber === 4
    );
    let downtimeInfoLabel6 = reportView?.downtimeInfoLabels.find(
      (x) => x.labelNumber === 5
    );

    let downTimeHeaderColSpan = 2;
    downtimeInfoLabel1 &&
      downtimeInfoLabel1.name !== "" &&
      downTimeHeaderColSpan++;
    downtimeInfoLabel2 &&
      downtimeInfoLabel2.name !== "" &&
      downTimeHeaderColSpan++;
    downtimeInfoLabel3 &&
      downtimeInfoLabel3.name !== "" &&
      downTimeHeaderColSpan++;
    downtimeInfoLabel4 &&
      downtimeInfoLabel4.name !== "" &&
      downTimeHeaderColSpan++;
    downtimeInfoLabel5 &&
      downtimeInfoLabel5.name !== "" &&
      downTimeHeaderColSpan++;
    downtimeInfoLabel6 &&
      downtimeInfoLabel6.name !== "" &&
      downTimeHeaderColSpan++;

    let characteristicsInfoLabel1 = reportView?.characteristicsInfoLabels.find(
      (x) => x.labelNumber === 1
    );
    let characteristicsInfoLabel2 = reportView?.characteristicsInfoLabels.find(
      (x) => x.labelNumber === 2
    );
    let characteristicsInfoLabel3 = reportView?.characteristicsInfoLabels.find(
      (x) => x.labelNumber === 3
    );
    let characteristicsInfoLabel4 = reportView?.characteristicsInfoLabels.find(
      (x) => x.labelNumber === 4
    );
    let characteristicsInfoLabel5 = reportView?.characteristicsInfoLabels.find(
      (x) => x.labelNumber === 5
    );
    let characteristicsInfoLabel6 = reportView?.characteristicsInfoLabels.find(
      (x) => x.labelNumber === 6
    );

    let characteristicsInfoHeaderColSpan = 0;
    characteristicsInfoLabel1 &&
      characteristicsInfoLabel1.label !== "None" &&
      characteristicsInfoHeaderColSpan++;
    characteristicsInfoLabel2 &&
      characteristicsInfoLabel2.label !== "None" &&
      characteristicsInfoHeaderColSpan++;
    characteristicsInfoLabel3 &&
      characteristicsInfoLabel3.label !== "None" &&
      characteristicsInfoHeaderColSpan++;
    characteristicsInfoLabel4 &&
      characteristicsInfoLabel4.label !== "" &&
      characteristicsInfoHeaderColSpan++;
    characteristicsInfoLabel5 &&
      characteristicsInfoLabel5.label !== "" &&
      characteristicsInfoHeaderColSpan++;
    characteristicsInfoLabel6 &&
      characteristicsInfoLabel6.label !== "" &&
      characteristicsInfoHeaderColSpan++;

    return {
      downtimeInfoLabel1: downtimeInfoLabel1,
      downtimeInfoLabel2: downtimeInfoLabel2,
      downtimeInfoLabel3: downtimeInfoLabel3,
      downtimeInfoLabel4: downtimeInfoLabel4,
      downtimeInfoLabel5: downtimeInfoLabel5,
      downtimeInfoLabel6: downtimeInfoLabel6,
      downTimeHeaderColSpan: downTimeHeaderColSpan,
      characteristicsInfoLabel1: characteristicsInfoLabel1,
      characteristicsInfoLabel2: characteristicsInfoLabel2,
      characteristicsInfoLabel3: characteristicsInfoLabel3,
      characteristicsInfoLabel4: characteristicsInfoLabel4,
      characteristicsInfoLabel5: characteristicsInfoLabel5,
      characteristicsInfoLabel6: characteristicsInfoLabel6,
      characteristicsInfoHeaderColSpan: characteristicsInfoHeaderColSpan,
    };
  }, [reportView]);

  function handleChange(
    text: string,
    index: number,
    characteristic:
      | "downTimeInfo1"
      | "downTimeInfo2"
      | "downTimeInfo3"
      | "downTimeInfo4"
      | "downTimeInfo5"
      | "downTimeInfo6"
  ) {
    let items = [...currentProductionReportHoursList];
    let item = { ...items[index] };
    item[characteristic] = text;
    items[index] = item;

    setCurrentProductionReportHoursList(items);
  }

  function resetReportHours() {
    setCurrentProductionReportHoursList(initialProductionReportHoursList);
  }

  function exportReportHours() {
    const reportHoursList = [...currentProductionReportHoursList];

    const parsedData = reportHoursList.map((reportHourInfo) => {
      const returnObject: any = {};
      returnObject[t("performanceProReportPage.perHourFields.from")] = new Date(
        reportHourInfo.dateTimeHour.toUTCString().slice(0, 25)
      );
      returnObject[t("performanceProReportPage.perHourFields.until")] =
        addHours(
          new Date(reportHourInfo.dateTimeHour.toUTCString().slice(0, 25)),
          1
        );
      returnObject[
        t(
          `performanceProReportPage.perHourFields.achieved${unitType.toString()}`
        )
      ] = FormatEggs(reportHourInfo.eggsAchieved ?? 0, unitType);
      returnObject[
        t("performanceProReportPage.perHourFields.hourPercentageVsTarget")
      ] = `${reportHourInfo.percentageVsTarget.toFixed(1) ?? 0}%`;
      returnObject[
        t(
          `performanceProReportPage.perHourFields.accumulated${unitType.toString()}`
        )
      ] = FormatEggs(reportHourInfo.accumelatedEggsAchieved ?? 0, unitType);
      returnObject[
        t("performanceProReportPage.perHourFields.dayPercentageVsTarget")
      ] = `${reportHourInfo.accumelatedPercentageVsTarget ?? 0}%`;

      setDownTime(labels, returnObject, reportHourInfo);

      returnObject[t("performanceProReportPage.perHourFields.downtime")] =
        FormatSecondsToMinutesAndSeconds(reportHourInfo.downTimeSeconds ?? 0);
      returnObject[t("performanceProReportPage.perHourFields.comments")] =
        reportHourInfo.downTimeComment ?? 0;
      returnObject[
        t("performanceProReportPage.characteristicsInfo.offgrades")
      ] = roundToDecimal(reportHourInfo.offgradesPercentage ?? 0);
      returnObject[t("performanceProReportPage.characteristicsInfo.leakers")] =
        roundToDecimal(reportHourInfo.leakersPercentage ?? 0);
      returnObject[t("performanceProReportPage.characteristicsInfo.fillRate")] =
        roundToDecimal(reportHourInfo.fillRatePercentage ?? 0);
      returnObject[
        t("performanceProReportPage.characteristicsInfo.productChanges")
      ] = reportHourInfo.numberOfProductChanges ?? 0;

      setCharacteristics(labels, returnObject, reportHourInfo);

      returnObject[t("performanceProReportPage.perHourFields.team")] =
        reportHourInfo.team ?? 0;
      returnObject[t("performanceProReportPage.perHourFields.teamThroughput")] =
        reportHourInfo.teamThroughput ?? 0;

      return returnObject;
    });

    const fileName =
      "reporthours " +
      options.selectedDate.getUTCFullYear() +
      "-" +
      (options.selectedDate.getUTCMonth() + 1) +
      "-" +
      options.selectedDate.getUTCDate();

    const data = [
      {
        sheet: fileName,
        columns: getExcelLabels(parsedData),
        content: parsedData,
      },
    ];

    let settings = {
      fileName,
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    exportToXlsx(data, settings);
  }

  const columnsToDisplayPerHour = [
    t("performanceProReportPage.perHourFields.from"),
    t("performanceProReportPage.perHourFields.until"),
  ];

  const getExcelLabels = (data: any[]): Array<any> => {
    const result: Array<any> = [];

    for (const dataPoint of Object.keys(data[0])) {
      if (columnsToDisplayPerHour.includes(dataPoint)) {
        result.push({
          label: dataPoint,
          value: dataPoint,
          format: "hh:mm",
        });
        continue;
      }
      result.push({
        label: dataPoint,
        value: dataPoint,
      });
    }

    return result;
  };

  const saveReportHours = () => {
    let reportHours: UpdateReportHours = {
      machineId: selectedMachine.machineId,
      productionReportHours: currentProductionReportHoursList.map(
        (reportHour) => {
          var timeString =
            reportHour.dateTimeHour.getUTCHours() +
            ":" +
            reportHour.dateTimeHour.getUTCMinutes() +
            ":00";
          var dateString =
            "" +
            options.selectedDate.getUTCFullYear() +
            "-" +
            (options.selectedDate.getUTCMonth() + 1) +
            "-" +
            options.selectedDate.getUTCDate();
          var combined = new Date(dateString + " " + timeString);

          return {
            dateTimeHour: removeTimezone(combined),
            downTimeComment: reportHour.downTimeComment,
            downTimeInfo1: reportHour.downTimeInfo1,
            downTimeInfo2: reportHour.downTimeInfo2,
            downTimeInfo3: reportHour.downTimeInfo3,
            downTimeInfo4: reportHour.downTimeInfo4,
            downTimeInfo5: reportHour.downTimeInfo5,
            downTimeInfo6: reportHour.downTimeInfo6,
            characeristicInfo4: reportHour.characeristicInfo4,
            characeristicInfo5: reportHour.characeristicInfo5,
            characeristicInfo6: reportHour.characeristicInfo6,
            team: reportHour.team,
          };
        }
      ),
    };

    reportHours.machineId = selectedMachine.machineId;

    performanceProReportService
      .updateReportHours(reportHours)
      .then(() => {
        dispatchAlert({
          message: t("performanceProReportPage.updateReportHours.success"),
          messageType: "success",
        });
        setInitialProductionReportHoursList(currentProductionReportHoursList);
      })
      .catch(() => {
        dispatchAlert({
          message: t("performanceProReportPage.updateReportHours.failed"),
          messageType: "error",
        });
      });
  };

  return (
    <ReportPerHourPresentation
      reportView={reportView}
      productionReportHoursList={currentProductionReportHoursList}
      labels={labels}
      hasPendingChanges={hasPendingChanges}
      onChange={handleChange}
      onSaveClick={saveReportHours}
      onResetClick={resetReportHours}
      onExportClick={exportReportHours}
      unitType={unitType}
    />
  );
}
