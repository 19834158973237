export const SparklineSettings = {
  chart: {
    borderWidth: 0,
    type: "area",
    height: 50,
    margin: [0, 0, 0, 0],
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
    startOnTick: false,
    endOnTick: false,
    tickPositions: [],
    minPadding: 0,
    maxPadding: 0,
  },
  yAxis: {
    endOnTick: false,
    startOnTick: false,
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tickPositions: [0],
    minPadding: 0,
    maxPadding: 0,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    hideDelay: 0,
  },
  plotOptions: {
    series: {
      animation: false,
      lineWidth: 1,
      shadow: false,
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: {
        enabled: false,
      },
      fillOpacity: 0.25,
    },
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
