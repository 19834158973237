import { List, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  numberedList: {
    listStyleType: "none",
  },
}));

export function NumberedList({ children }: any) {
  const classes = useStyles();
  return <List className={classes.numberedList}>{children}</List>;
}
