import { useMemo } from "react";
import { KpiMeasureType } from "../components/PerformancePro";
import { ChartMapping } from "../components/PerformancePro/ChartMapping";
import { FormatEggs } from "./EggUtility";
import { FormatWithTwoDecimals } from "./NumberFormatUtility";
import { t } from "./TranslateUtility";

export enum EggUnit {
  Eggs = "Eggs",
  Cases = "Cases",
  Dozens = "Dozens",
}

export function GetTargetLabel(
  summation: number,
  eggUnit: EggUnit | null
): string | null {
  return `${FormatEggs(summation, eggUnit)} ${GetEggUnitTranslation(eggUnit)}`;
}

export function GetTooltipLabel(
  usePercentage: boolean,
  value: any,
  unitLabel: string
) {
  return usePercentage
    ? `${FormatWithTwoDecimals(value)} %`
    : `${FormatEggs(value, null)} ${unitLabel.toLowerCase()}`;
}

export function GetEggUnitWithPrefix(
  usePercentage: boolean,
  eggUnit: EggUnit
): string {
  if (usePercentage) {
    return `${GetEggUnitTranslation(eggUnit)} (%)`;
  }
  return `${GetEggUnitTranslation(eggUnit)} (${t("eggUnits.numberSign")})`;
}

export function GetEggUnitTranslation(
  eggUnit: EggUnit | string | null | undefined
): string {
  switch (eggUnit) {
    case "Eggs":
      return t("eggUnits.singleEggs");
    case "Dozens":
      return t("eggUnits.dozens");
    case "Cases":
      return t("eggUnits.cases");
    default:
      return t("eggUnits.singleEggs");
  }
}

export function useUnitLabel(
  chartMapping: ChartMapping,
  eggUnit: EggUnit | null
): string {
  return useMemo(() => {
    switch (chartMapping.performanceProChart) {
      case KpiMeasureType.TotalEggs:
        return GetEggUnitTranslation(eggUnit);
      case KpiMeasureType.Throughput:
        const eggUnitString = eggUnit?.toString() ?? EggUnit.Eggs.toString();
        const perHourPostfix = "pH";

        return eggUnitString.substring(0, 1) + perHourPostfix;
      default:
        return t(chartMapping.tooltipUnit);
    }
  }, [chartMapping.performanceProChart, chartMapping.tooltipUnit, eggUnit]);
}
