import React from "react";
import { Input, InputAdornment, InputLabel } from "@material-ui/core";
import { SettingsCard } from "../Common";
import { PercentageInput, SettingFormControl, EggCountInput } from "./Common";
import { GeneralTargets } from "./Types";
import { t, nbsp } from "../../../utility/TranslateUtility";

export default function TargetsPresentation({
  targets,
  onTargetsChange,
}: {
  targets: GeneralTargets;
  onTargetsChange: (t: GeneralTargets) => void;
}) {
  return (
    <>
      <SettingsCard
        title={t("managementSettings.targets.generalTargets.title")}
      >
        <GeneralTargetsForm
          availability={targets.availability}
          productionTime={targets.productionTime}
          totalEggs={targets.totalEggs}
          totalEggsEggUnit={targets.totalEggsEggUnit}
          onChange={onTargetsChange}
        />
      </SettingsCard>
    </>
  );
}

export type GeneralTargetsFormProps = GeneralTargets & {
  onChange: (values: GeneralTargets) => void;
};

export function GeneralTargetsForm(props: GeneralTargetsFormProps) {
  const {
    productionTime,
    availability,
    totalEggs,
    totalEggsEggUnit: totalEggsUnit,
    onChange,
  } = props;

  return (
    <>
      <SettingFormControl>
        <InputLabel htmlFor="production-time">
          {nbsp(t("managementSettings.targets.generalTargets.productionTime"))}
        </InputLabel>
        <Input
          id="production-time"
          type="number"
          inputMode="decimal"
          inputProps={{
            min: 0,
            max: 24,
          }}
          endAdornment={
            <InputAdornment position="end">
              {t(
                "managementSettings.targets.generalTargets.productionTimeUnit"
              )}
            </InputAdornment>
          }
          value={productionTime}
          onChange={(e) =>
            onChange({
              ...props,
              productionTime: Math.min(24, parseFloat(e.target.value)),
            })
          }
        />
      </SettingFormControl>
      <EggCountInput
        id="total-eggs"
        label={t("managementSettings.targets.generalTargets.totalEggs")}
        timeLabel={t("managementSettings.targets.generalTargets.totalEggsTime")}
        value={totalEggs}
        unit={totalEggsUnit}
        onChange={(v) =>
          onChange({
            ...props,
            totalEggs: v,
          })
        }
        onUnitChange={(v) =>
          onChange({
            ...props,
            totalEggsEggUnit: v,
          })
        }
      />
      <PercentageInput
        id="availability"
        label={t("managementSettings.targets.generalTargets.availability")}
        value={availability}
        onChange={(v) =>
          onChange({
            ...props,
            availability: v,
          })
        }
      />
    </>
  );
}
