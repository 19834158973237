import { useEffect, useMemo, useState } from "react";
import { useManagementSettingsService } from "../../../../api/ServiceContext";
import { AllTypes, EggGroupType, EggTypeGroupApiData, EggTypeGroupMapping, EggTypeMappingSettingState } from "../../../../apiModels/EggTypeMapping/EggTypeGroupApiModel";
import { useSelectedMachine } from "../../../../store/GlobalContext";
import { t } from "../../../../utility/TranslateUtility";
import { useDispatchAlert } from "../../../UI/Alert";
import EggTypeGroupTablePresentation from "./EggTypeGroupTablePresentation";

export type InternalEggTypeGroupItem = EggTypeGroupMapping & { dirty: boolean };

function determineDirtyRow(
  allRows: Array<EggTypeGroupMapping>,
  newRow: EggTypeGroupMapping
) {
  const foundRow = allRows.find(
    (x) => x.machineEggTypeName === newRow.machineEggTypeName
  );

  if (!foundRow) return true;

  return foundRow.eggType !== newRow.eggType;
}

export default function EggTypeGroupTableContainer() {
  const service = useManagementSettingsService();
  const machineId = useSelectedMachine().machineId;
  const dispatchAlert = useDispatchAlert();

  const [originalRows, setOriginalRows] = useState<EggTypeGroupMapping[]>([]);
  const [internalRows, setInternalRows] = useState<EggTypeGroupMapping[]>([]);
  const [isAwaitingSaveResponse, setIsAwaitingSaveResponse] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [lastState, setLastState] = useState<EggTypeMappingSettingState>(EggTypeMappingSettingState.NoChangesPending);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

  const [
    selectedEggTypeFilter,
    setSelectedEggTypeFilter,
  ] = useState<EggGroupType | null>(null);

  useEffect(() => {
    service
      .getEggTypeGroupMappings(machineId)
      .then((response: IApiResponse<EggTypeGroupApiData>) => {
        setOriginalRows([...response.data.eggTypeGroupItems]);
        setInternalRows([...response.data.eggTypeGroupItems]);

        setLastState(response.data.state);
        setLastUpdated(response.data.lastUpdatedAt);
      });
  }, [service, machineId]);

  const handleEggTypeChange = (
    editedRow: EggTypeGroupMapping,
    eggTypeValue: number
  ) => {
    setInternalRows(
      internalRows.map((internalRow) =>
        internalRow.machineEggTypeName === editedRow.machineEggTypeName
          ? {
            ...internalRow,
            eggType: eggTypeValue,
            dirty: false,
          }
          : internalRow
      )
    );
  };

  const handleOnSaveClick = () => {
    const rowsToSave = internalRows.map<EggTypeGroupMapping>((r) => ({
      eggType: r.eggType,
      machineEggTypeName: r.machineEggTypeName,
    }));
    setIsAwaitingSaveResponse(true);
    setHasFailed(false);

    service
      .saveEggTypeGroupMappings(machineId, { mappings: rowsToSave })
      .then(() => {
        setOriginalRows([...internalRows]);
        setLastState(EggTypeMappingSettingState.ChangesPending);
        dispatchAlert({
          message: t("managementSettings.settingsSaveSuccessMessage"),
          messageType: "success",
        });
      })
      .catch(() => {
        setHasFailed(true);
        setLastState(EggTypeMappingSettingState.FailedSave);
        dispatchAlert({
          message: t("managementSettings.settingsSaveFailedMessage"),
          messageType: "error",
        });
      })
      .finally(() => {
        setLastUpdated(new Date());
        setIsAwaitingSaveResponse(false)
      });
  };

  const handleOnErrorMessageClick = () => {
    setHasFailed(false);
  };

  const rowsToShow = useMemo<InternalEggTypeGroupItem[]>(() => {
    const tempRows = internalRows.map<InternalEggTypeGroupItem>((row) => {
      return determineDirtyRow(originalRows, row)
        ? { ...row, dirty: true }
        : { ...row, dirty: false };
    });
    return tempRows.filter(
      (row) =>
        row.dirty ||
        !selectedEggTypeFilter ||
        row.eggType === selectedEggTypeFilter
    );
  }, [internalRows, originalRows, selectedEggTypeFilter]);

  const pendingChanges = useMemo(() => {
    return rowsToShow.some((x) => x.dirty);
  }, [rowsToShow]);

  const eggTypeGroupCount = useMemo<
    { eggType: EggGroupType; count: number }[]
  >(() => {
    const count = AllTypes.map((type) => ({ eggType: type, count: 0 }));
    for (let typeTypeCount of count) {
      typeTypeCount.count = internalRows.filter(
        (v) => v.eggType === typeTypeCount.eggType
      ).length;
    }
    return count;
  }, [internalRows]);

  return (
    <EggTypeGroupTablePresentation
      rows={rowsToShow}
      pendingChanges={pendingChanges}
      onSaveClick={handleOnSaveClick}
      onRowEdit={handleEggTypeChange}
      onErrorMessageClick={handleOnErrorMessageClick}
      onChipsClick={(value: EggGroupType) =>
        setSelectedEggTypeFilter(selectedEggTypeFilter !== value ? value : null)
      }
      eggTypeFilter={selectedEggTypeFilter}
      eggTypeGroupCount={eggTypeGroupCount}
      isAwaitingSaveResponse={isAwaitingSaveResponse}
      hasFailed={hasFailed}
      lastState={lastState}
      lastUpdated={lastUpdated}
    />
  );
}
