import { useState } from "react";
import {
  useAlarmService,
  useLocalAlarmService,
} from "../../../api/ServiceContext";
import { useSelectedMachine } from "../../../store/GlobalContext";
import { t } from "../../../utility/TranslateUtility";
import { useDispatchAlert } from "../../UI/Alert/alertHooks";
import { emptyUser } from "../../Users/AddOrUpdateUser/Common";
import AddOrUpdateAlarmPresentation from "./AddOrUpdateAlarmPresentation";
import { IAlarm, IAlarmCommand } from "./Common";

export interface Props {
  disableTopic: boolean;
  isUpdating: boolean;
  timeZone: string;
  closeDialog: (closedOnSave: boolean) => void;
  onListUpdated: ((isUpdated: boolean) => void) | undefined;
}

function AddOrUpdateAlarmDialogContainer({
  disableTopic,
  isUpdating,
  timeZone,
  closeDialog,
  onListUpdated,
}: Props) {
  const alarmService = useAlarmService();
  const localAlarmService = useLocalAlarmService();
  const selectedMachine = useSelectedMachine();

  const dispatchAlert = useDispatchAlert();

  const [alarm, setAlarm] = useState<IAlarm>(localAlarmService.currentAlarm());

  const update = (field: string, value: any) => {
    localAlarmService.updateAlarmField(field, value);
    setAlarm(localAlarmService.currentAlarm());
  };

  const createAlarmCommand = (newAlarm: IAlarm): IAlarmCommand => {
    return {
      level: newAlarm.level,
      levelUnit: newAlarm.levelUnit,
      user: emptyUser,
      startTimeUtc: newAlarm.startTimeUtc,
      endTimeUtc: newAlarm.endTimeUtc,
      topic: newAlarm.topic,
      alarmId: newAlarm.alarmId,
      status: newAlarm.status,
      condition: newAlarm.condition,
      frequency: newAlarm.frequency,
      machineId: Number(selectedMachine.machineId),
    };
  };

  const onSaveChanges = () => {
    const alarmChanges: IAlarmCommand = createAlarmCommand(alarm);
    if (isUpdating) {
      alarmService
        .updateAlarm(
          selectedMachine.machineId,
          alarmChanges.alarmId,
          alarmChanges,
          timeZone
        )
        .then(() => {
          dispatchAlert({
            message: t("alarmManagement.messages.updateSuccess"),
            messageType: "success",
          });
          if (onListUpdated !== undefined) {
            onListUpdated(true);
          }
        })
        .catch((err) => {
          dispatchAlert({
            message: t("alarmManagement.messages.updateFailed"),
            messageType: "error",
          });
        });
    } else {
      alarmService
        .addAlarm(alarmChanges, timeZone)
        .then(() => {
          dispatchAlert({
            message: t("alarmManagement.messages.addSuccess"),
            messageType: "success",
          });
          if (onListUpdated !== undefined) {
            onListUpdated(true);
          }
        })
        .catch((err) => {
          dispatchAlert({
            message: t("alarmManagement.messages.addFailed"),
            messageType: "error",
          });
        });
    }
    localAlarmService.stopAddingOrUpdating();
    closeDialog(true);
  };

  return (
    <>
      <AddOrUpdateAlarmPresentation
        alarm={alarm}
        disableTopic={disableTopic}
        updateField={update}
        onSaveChanges={onSaveChanges}
        closeDialog={() => closeDialog(false)}
      />
    </>
  );
}

export default AddOrUpdateAlarmDialogContainer;
