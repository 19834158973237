import { AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Link } from "react-router-dom";
import { colors } from "../../colors";

export type NavigationItem = {
  link: string;
  title: string;
  shouldRender: boolean;
};

export enum TabNavigationVariant {
  Primary = 0,
  Secondary = 1,
}

export type TabNavigationProps = {
  navigationItems: Array<NavigationItem>;
  variant: TabNavigationVariant;
  selectedNavigationItem: number;
};

/**
 * Combines Material UI tabs and Links from react-router-dom.
 * Renders each NavigationItem as it's own tab and sets the correct Link reference.
 *
 * Use in conjunction with react-router-dom Router and Switch components.
 *
 * @param navigationItems The items that the TavNavigation will render as tabs.
 */
export function TabNavigation({
  navigationItems,
  variant,
  selectedNavigationItem,
}: TabNavigationProps) {
  const classes = useStyles();

  return (
    <AppBar
      className={
        variant === TabNavigationVariant.Primary
          ? classes.primaryAppBar
          : classes.secondaryAppBar
      }
      position="static"
      elevation={3}
    >
      <Tabs
        value={selectedNavigationItem}
        className={
          variant === TabNavigationVariant.Primary
            ? classes.primaryTabs
            : classes.secondaryTabs
        }
        classes={{
          indicator:
            variant === TabNavigationVariant.Primary
              ? classes.primaryIndicator
              : classes.secondaryIndicator,
        }}
        scrollButtons="on"
        variant="scrollable"
      >
        {navigationItems
          .filter((x) => x.shouldRender)
          .map((navigationItem: NavigationItem, index: number) => (
            <Tab
              key={navigationItem.link}
              label={navigationItem.title}
              to={navigationItem.link}
              component={Link}
            />
          ))}
      </Tabs>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  link: {
    color: colors.white,
  },
  primaryIndicator: {
    backgroundColor: colors.white,
  },
  secondaryIndicator: {
    backgroundColor: colors.mobaBlue,
  },
  primaryAppBar: {
    backgroundColor: colors.mobaBlue,
  },
  secondaryAppBar: {
    backgroundColor: "#ffffff",
  },
  primaryTabs: {
    color: "#ffffff",
  },
  secondaryTabs: {
    color: colors.mobaBlue,
  },
}));
