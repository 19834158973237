import { Button, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useMemo, useState } from "react";
import { t } from "../../../utility/TranslateUtility";
import { IDeviceInstallation, IDeviceRegistration } from "../Common";
import DeviceRegistration from "./DeviceRegistration";
export interface DeviceRegistrationPresentationProps {
  awaitingResponse: boolean;
  deviceRegistrations: IDeviceRegistration[];
  deviceInstallation: IDeviceInstallation | undefined;
  onNotificationChange: (
    registration: IDeviceRegistration,
    enabled: boolean
  ) => void;
  onRequestTestNotification: (registration: IDeviceRegistration) => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    float: "right",
    [theme.breakpoints.down("xs")]: {
      float: "none",
    },
    marginBottom: 5,
  },
  hidden: {
    display: "hidden",
  },
}));

function DeviceRegistrationPresentation({
  awaitingResponse,
  deviceRegistrations,
  deviceInstallation,
  onNotificationChange,
  onRequestTestNotification,
}: DeviceRegistrationPresentationProps) {
  const [registration, setRegistration] = useState<IDeviceRegistration>();
  const classes = useStyles();
  const notificationsEnabled: any = useMemo(
    () => deviceInstallation && !!registration,
    [registration, deviceInstallation]
  );

  useEffect(() => {
    let reg: IDeviceRegistration | undefined;
    if (deviceInstallation && !awaitingResponse) {
      reg = deviceRegistrations.find(
        (r) => r.handle === deviceInstallation.pushChannel
      );
      setRegistration(reg);
    }
  }, [deviceInstallation, deviceRegistrations, awaitingResponse, registration]);

  let deviceRegistrationPresentation = awaitingResponse ? (
    <>
      <Skeleton variant="rect" animation="wave" />
      <p />
      <Skeleton variant="rect" animation="wave" />
    </>
  ) : (
    <>
      <DeviceRegistration
        registration={registration || newRegistration(deviceInstallation!)}
        notificationsEnabled={notificationsEnabled}
        handleEnabledNotificationsChange={onNotificationChange}
      />
      <Button
        disabled={!notificationsEnabled}
        onClick={onRequestTestClick}
        className={classes.button}
        variant={"outlined"}
        color={"primary"}
      >
        {t("notificationManagement.buttons.test")}
      </Button>
    </>
  );

  return deviceRegistrationPresentation;

  function onRequestTestClick(event: any) {
    if (registration) {
      onRequestTestNotification(registration);
    }
  }

  function newRegistration(
    installation: IDeviceInstallation
  ): IDeviceRegistration {
    const tags = [];
    if (deviceInstallation?.deviceDescription) {
      tags.push(deviceInstallation.deviceDescription);
    }
    return {
      platform: installation.platform,
      handle: installation.pushChannel,
      tags: tags,
    };
  }
}
export default DeviceRegistrationPresentation;
