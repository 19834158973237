import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { TextField, InputAdornment, makeStyles } from "@material-ui/core";
import { t } from "../../../utility/TranslateUtility";

const useStyles = makeStyles((theme) => ({
searchContainer: {
  display: "flex",
  flexDirection: "column",
  width: theme.spacing(30),
  margin: theme.spacing(2),
  marginLeft: 0,
},
}));

export interface AlarmListSearchProps {
  searchTerm: string,
  onChange: (searchTerm: string) => void
}

function AlarmListSearch({
  searchTerm,
  onChange
}: AlarmListSearchProps) {
  const classes = useStyles();
  
  return (
    <div className={classes.searchContainer}>
      <TextField
        value={searchTerm}
        onChange={(ev) => onChange(ev.target.value)}
        variant="outlined"
        placeholder={t("alarmManagement.search.placeholder")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </div>
  );
}

export default AlarmListSearch;
