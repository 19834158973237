import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(2),
  },
}));

export function Header({ children }: any) {
  const classes = useStyles();
  return (
    <Typography className={classes.header} variant="h6">
      {children}
    </Typography>
  );
}
