import { TableCell } from "@material-ui/core";
import { FormatWithTwoDecimals } from "../../../utility/NumberFormatUtility";
import { StyledTableRow } from "../../UI/Table/Table";
import { DestinationProductDisplay } from "./Common";

export interface DestinationProductRowProps {
  destinationProduct: DestinationProductDisplay;
  columnsToDisplay: Array<keyof DestinationProductDisplay>;
}

export function DestinationProductRow({
  destinationProduct,
  columnsToDisplay,
}: DestinationProductRowProps) {
  const renderTable = () => {
    let result = [];

    for (const column of columnsToDisplay) {
      switch (column) {
        case "productName":
          result.push(
            <TableCell key={column}>{destinationProduct.productName}</TableCell>
          );
          break;
        case "numberOfEggsInProduct":
          result.push(
            <TableCell key={column}>
              {destinationProduct.numberOfEggsInProduct}
            </TableCell>
          );
          break;
        case "numberOfEggsToProduct":
          result.push(
            <TableCell key={column}>
              {destinationProduct.numberOfEggsToProduct}
            </TableCell>
          );
          break;
        case "percentageEggsToProduct":
          result.push(
            <TableCell key={column}>
              {FormatWithTwoDecimals(
                destinationProduct.percentageEggsToProduct
              )}
            </TableCell>
          );
          break;
        case "numberOfUpgradedEggsToProduct":
          result.push(
            <TableCell key={column}>
              {destinationProduct.numberOfUpgradedEggsToProduct}
            </TableCell>
          );
          break;
        case "percentageOffgradeContribution":
          result.push(
            <TableCell key={column}>
              {FormatWithTwoDecimals(
                destinationProduct.percentageOffgradeContribution
              )}
            </TableCell>
          );
          break;
        case "productionTime":
          result.push(
            <TableCell key={column}>
              {destinationProduct.productionTime}
            </TableCell>
          );
          break;
        default:
          break;
      }
    }
    return result;
  };

  return <StyledTableRow>{renderTable()}</StyledTableRow>;
}
