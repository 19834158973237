import { Chip, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { EggGroupType } from "../../../apiModels/EggTypeMapping/EggTypeGroupApiModel";
import { t } from "../../../utility/TranslateUtility";
import { determineColor } from "./EggTypeGroupTable/EggTypeGroupTablePresentation";

const useChipsClasses = makeStyles((theme) => ({
  chip: {
    color: "#fff",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const chipVariants = [
  EggGroupType.Unmapped,
  EggGroupType.Organic,
  EggGroupType.Barn,
  EggGroupType.Cage,
  EggGroupType.FreeRange,
];

function determineSelected(
  variant: number,
  selectedChip: number | null
): "default" | "outlined" | undefined {
  return variant === selectedChip ? "default" : "outlined";
}

function DetermineEggGroupTypeLabel(
  eggGroupType: EggGroupType,
  typeCount: EggTypeGroupCount[]
) {
  const count = typeCount.find((c) => c.eggType === eggGroupType)?.count;
  const countPart = `${count} `;
  switch (eggGroupType) {
    case EggGroupType.Unmapped:
      return countPart + t("eggTypes.unmapped");
    case EggGroupType.Cage:
      return countPart + t("eggTypes.cage");
    case EggGroupType.Barn:
      return countPart + t("eggTypes.barn");
    case EggGroupType.FreeRange:
      return countPart + t("eggTypes.freeRange");
    case EggGroupType.Organic:
      return countPart + t("eggTypes.organic");
  }
}

export interface EggTypeGroupCount {
  eggType: EggGroupType;
  count: number;
}

export default function EggTypeChips({
  onClick,
  value,
  typeCount,
}: {
  onClick: (label: EggGroupType) => void;
  value: EggGroupType | null;
  typeCount: EggTypeGroupCount[];
}) {
  const classes = useChipsClasses();

  const handleOnClick = (variant: EggGroupType, index: number) => {
    onClick(variant);
  };

  return (
    <>
      <Grid container>
        {chipVariants.map((variant, index) => (
          <Grid item key={index}>
            <Chip
              key={index}
              label={DetermineEggGroupTypeLabel(variant, typeCount)}
              onClick={() => handleOnClick(variant, index)}
              className={classes.chip}
              style={{
                color: variant !== value ? determineColor(variant) : "",
                backgroundColor:
                  variant === value ? determineColor(variant) : "",
              }}
              variant={determineSelected(variant, value)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
