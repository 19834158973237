import { Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsMobileSize } from "../../../../globalHooks/responsiveHooks";
import { nbsp } from "../../../../utility/TranslateUtility";
import { ColorPicker } from "../../../UI/ColorPIcker/ColorPicker";
import { DeleteIconButton } from "../../Common";

export function AddNewColorSchemaButton({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={onClick}
      >
        {t("managementSettings.report.addColorSchemaButtonLabel")}
      </Button>
    </div>
  );
}

export function RemoveAllColorSchemasButton({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Button
        color="secondary"
        variant="outlined"
        startIcon={<DeleteIcon />}
        onClick={onClick}
      >
        {t("managementSettings.report.deleteAllButtonLabel")}
      </Button>
    </div>
  );
}

const useBreakTimeRowClasses = makeStyles((theme) => ({
  spacing: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
    },
  },
  gridItem: {
    alignSelf: "center",
  },
  gridContainer: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
}));

export function ColorSchemaRow({
  colorSchemaNumber,
  onDeleteRow,
  targetValue,
  color,
  onChange,
}: {
  colorSchemaNumber: number;
  onDeleteRow: (colorSchemaNumber: number) => void;
  targetValue: number;
  color: string;
  onChange: (target: number, color: string) => void;
}) {
  const isMobileSize = useIsMobileSize('xs');

  const classes = useBreakTimeRowClasses();
  const [internalColor, setInternalColor] = useState(color);
  const [internalTargetValue, setInternalTargetValue] = useState(targetValue);

  const isDefaultRow = useMemo<boolean>(() => {
    return colorSchemaNumber === 0;
  }, [colorSchemaNumber]);

  useEffect(() => {
    if (isDefaultRow) {
      setInternalTargetValue(targetValue);
    }
  }, [targetValue]);

  const handleTargetValueChange = (newTargetValue: number) => {
    setInternalTargetValue(newTargetValue);
    onChange(newTargetValue, internalColor);
  };

  const handleColorChange = (newColor: string) => {
    setInternalColor(newColor);
    onChange(internalTargetValue, newColor);
  };

  return (
    <Grid container className={classes.gridContainer} spacing={3}>
      <Grid item xs={1} sm={1} className={classes.gridItem}>
        <ColorSchemaLabel
          value={colorSchemaNumber}
          isDefault={isMobileSize ? false : isDefaultRow}
        />
      </Grid>
      <Grid item xs={5} sm={2} className={classes.gridItem}>
        <TargetValueInput
          value={internalTargetValue}
          isDefault={isDefaultRow}
          onChange={handleTargetValueChange}
          className={classes.spacing}
        />
      </Grid>
      <Grid item xs={4} sm={1} className={classes.gridItem}>
        <ColorPicker
          disabled={isDefaultRow}
          onChange={handleColorChange}
          initialColor={internalColor}
        />
      </Grid>
      <Grid item xs={1} sm={1} className={classes.gridItem}>
        {!isDefaultRow && (
          <DeleteIconButton onClick={() => onDeleteRow(colorSchemaNumber)} />
        )}
      </Grid>
    </Grid>
  );
}

export function ColorSchemaLabel({
  value,
  isDefault,
  className,
}: {
  value: number;
  isDefault: boolean;
  className?: string;
}) {
  const { t } = useTranslation();

  return (
    <Typography className={className} variant="body1">
      {isDefault
        ? t("managementSettings.report.defaultColorSchemaLabel")
        : value}
    </Typography>
  );
}

export function TargetValueInput({
  value,
  isDefault,
  onChange,
  className,
}: {
  value: number;
  isDefault: boolean;
  onChange: (v: number) => void;
  className?: string;
}) {
  const { t } = useTranslation();
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (isDefault) {
      setInternalValue(value);
    }
  }, [value]);

  const handleOnBlur = () => {
    onChange(internalValue);
  };

  return (
    <div className={className}>
      <InputLabel>
        {nbsp(
          isDefault
            ? t("managementSettings.report.defaultColorSchemaInputLabel")
            : t("managementSettings.report.colorSchemaInputLabel")
        )}
      </InputLabel>
      <Input
        disabled={isDefault}
        type={isDefault ? "string" : "number"}
        inputMode={isDefault ? "text" : "decimal"}
        inputProps={
          isDefault
            ? {}
            : {
              min: 0,
            }
        }
        value={internalValue.toString()}
        onBlur={() => handleOnBlur()}
        onChange={(e) => setInternalValue(parseFloat(e.target.value))}
      />
    </div>
  );
}
