import { IDeviceRegistration } from "../../components/Notifications/Common";
import { IHttpClient } from "../IHttpClient";
import { IDeviceRegistrationService } from "./IDeviceRegistrationService";

export class DeviceRegistrationService implements IDeviceRegistrationService {
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }

  getDeviceRegistrations(): Promise<any> {
    return this.get(`v1/deviceregistration/`);
  }

  createOrUpdateDeviceRegistration(
    deviceRegistration: IDeviceRegistration
  ): Promise<any> {
    return this.post(`v1/deviceregistration/`, deviceRegistration);
  }

  deleteDeviceRegistrations(
    deviceRegistration: IDeviceRegistration
  ): Promise<any> {
    return this.delete(`v1/deviceregistration/`, { data: deviceRegistration });
  }

  requestTestNotification(
    deviceRegistration: IDeviceRegistration
  ): Promise<any> {
    return this.post(`v1/deviceregistration/test`, deviceRegistration);
  }

  private get(url: string): Promise<any> {
    return this.httpclient.get(url);
  }

  private delete(url: string, params: any): Promise<any> {
    return this.httpclient.delete(url, params);
  }

  private post(url: string, body: any): Promise<any> {
    return this.httpclient.post(url, body);
  }
}
