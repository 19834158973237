import { CancelTokenSource } from "axios";
import { createContext, Dispatch, useContext } from "react";
import { AlarmService } from "../api/Alarms/AlarmService";
import { IAlarmService } from "../api/Alarms/IAlarmService";
import ILocalAlarmService from "../api/Alarms/ILocalAlarmService";
import LocalAlarmService from "../api/Alarms/LocalAlarmService";
import { AuthTokenGetter, AxiosHttpClient } from "../api/AxiosHttpClient";
import { BenchmarkService } from "../api/Benchmark/BenchmarkService";
import { IBenchmarkService } from "../api/Benchmark/IBenchmarkService";
import { GlobalAnnouncementService } from "../api/GlobalAnnouncement/GlobalAnnouncementService";
import IGlobalAnnouncementService from "../api/GlobalAnnouncement/IGlobalAnnouncementService";
import { IInformationService } from "../api/Information/IInformationSerice";
import { InformationService } from "../api/Information/InformationService";
import { IMachineService } from "../api/Machine/IMachineService";
import { MachineService } from "../api/Machine/MachineService";
import { IManagementSettingsService } from "../api/ManagementMachine/IManagementSettingsService";
import { ManagmentSettingsService } from "../api/ManagementMachine/ManagementSettingService";
import { DeviceRegistrationService } from "../api/Notifications/DeviceRegistrationService";
import { IDeviceRegistrationService } from "../api/Notifications/IDeviceRegistrationService";
import { IOwnerService } from "../api/Owner/IOwnerService";
import { OwnerService } from "../api/Owner/OwnerService";
import {
  IPerformanceProService,
  MachinePerformanceGroupResult
} from "../api/PerformancePro/IPerformanceProService";
import { PerformanceProService } from "../api/PerformancePro/PerformanceProService";
import { IPerformanceProProductionService } from "../api/PerformanceProProduction/IPerformanceProProductionService";
import { PerformanceProProductionService } from "../api/PerformanceProProduction/PerformanceProProductionService";
import { IPerformanceProReportService } from "../api/PerformanceProReport/IPerformanceProReportService";
import { PerformanceProReportService } from "../api/PerformanceProReport/PerformanceProReportService";
import { IReportService } from "../api/Report/IReportService";
import { ReportService } from "../api/Report/ReportService";
import { IUserActivityService } from "../api/Users/IUserActivityService";
import { IUsersService } from "../api/Users/IUsersService";
import { UserActivityService } from "../api/Users/UserActivityService";
import { UsersService } from "../api/Users/UsersService";
import {
  BenchmarkState,
  initialState as initialBenchmarkState,
  Measure
} from "../components/Benchmark/MachineMeasureComparison";
import {
  MachineComparison,
  MachineIdFilter,
  MachineMeasuresComparisonFilterState,
  RangeFilter
} from "../components/Benchmark/MachineMeasureComparison/Types";
import {
  MachinePerformanceResult,
  ProductionTimeWindow
} from "../components/PerformancePro/Types";
import { TargetsView } from "../components/Settings/Targets/Types";
import { AlertMessage } from "../components/UI/Alert/Alert";
import { useAccessTokenGetter } from "../globalHooks/authenticationHooks";
import { UserResult } from "../globalHooks/authorizationHooks";
import { DatePeriod } from "../utility/DateUtility";
import { EggType } from "../utility/EggUtility";
import { GetCancellationToken } from "../utility/HttpServiceUtility";
import {
  selectedDateKey,
  selectedDatePeriodKey,
  selectedEggTypeKey
} from "../utility/LocalStorageKeys";
import { SetSelectedMachineIdInLocalStorage } from "../utility/LocalStorageUtility";
import { Action } from "./Reducer";

/**
 * Interfaces for sections of the global state
 */

export interface IServices {
  deviceRegistrationService: IDeviceRegistrationService;
  report: IReportService;
  user: IUsersService;
  machine: IMachineService;
  performanceProProduction: IPerformanceProProductionService;
  performanceProReport: IPerformanceProReportService;
  performancePro: IPerformanceProService;
  benchmark: IBenchmarkService;
  managementSettings: IManagementSettingsService;
  informationService: IInformationService;
  ownerService: IOwnerService;
  alarm: IAlarmService;
  localAlarm: ILocalAlarmService;
  userActivity: IUserActivityService;
  globalAnnouncementService: IGlobalAnnouncementService;
}

export interface IPerformanceProFilterOptions {
  selectedDate: Date;
  selectedDatePeriod: DatePeriod;
  selectedEggType: number;
}

export interface ManagementSettingsState {
  isSaving: boolean;
}

export type OverViewData = {
  [kpiMeasureType: string]: MachinePerformanceResult;
};

export interface IGlobalState {
  services: IServices;
  performanceProFilterOptions: IPerformanceProFilterOptions;
  performanceProGroupResults: MachinePerformanceGroupResult;
  performancePro: {
    productionTime: ProductionTimeWindow | null;
    overviewData?: OverViewData;
    isAwaitingOverviewResponse: boolean;
    targets?: TargetsView;
  };
  selectedMachine: OwnerMachine;
  benchmark: BenchmarkState;
  alerts: AlertsState;
  managementSettingState: ManagementSettingsState;
  isUserLoggedIn: boolean;
  myUser: UserResult;
}

export interface AlertsState {
  alertMessages: AlertMessage[];
}

export const initialAlertsState: AlertsState = {
  alertMessages: [],
};

export const initialManagementSettingsState: ManagementSettingsState = {
  isSaving: false,
};

/**
 * Initial global state values
 */

let source = GetCancellationToken();
export const axiosHttpClient = new AxiosHttpClient(source);

export const services: IServices = CreateHttpServices(axiosHttpClient);

export function CreateHttpServices(httpClient: AxiosHttpClient): IServices {
  return {
    report: new ReportService(httpClient),
    user: new UsersService(httpClient),
    machine: new MachineService(httpClient),
    performanceProProduction: new PerformanceProProductionService(httpClient),
    performanceProReport: new PerformanceProReportService(httpClient),
    benchmark: new BenchmarkService(httpClient),
    performancePro: new PerformanceProService(httpClient),
    managementSettings: new ManagmentSettingsService(httpClient),
    informationService: new InformationService(httpClient),
    ownerService: new OwnerService(httpClient),
    alarm: new AlarmService(httpClient),
    localAlarm: new LocalAlarmService(),
    userActivity: new UserActivityService(httpClient),
    deviceRegistrationService: new DeviceRegistrationService(httpClient),
    globalAnnouncementService: new GlobalAnnouncementService(httpClient)
  };
}

let selectedDateString = localStorage.getItem(selectedDateKey);
let selectedDatePeriodString = localStorage.getItem(selectedDatePeriodKey);
let selectedEggTypeString = localStorage.getItem(selectedEggTypeKey);
export const performanceProFilterOptions: IPerformanceProFilterOptions = {
  selectedDate:
    typeof selectedDateString === "string"
      ? new Date(selectedDateString)
      : new Date(),
  selectedDatePeriod:
    typeof selectedDatePeriodString === "string"
      ? parseInt(selectedDatePeriodString)
      : DatePeriod.Day,
  selectedEggType:
    typeof selectedEggTypeString === "string"
      ? Number(selectedEggTypeString)
      : 7,
};

export const selectedSortingMachine: OwnerMachine = {
  machineId: 0,
  machineName: "",
  isApproved: false,
  machineType: "",
  machineConnectionTime: new Date(),
  numberOfMachineUsers: 0,
  numberOfMachineAdmins: 0,
};

export const initialState: IGlobalState = {
  services: services,
  performanceProFilterOptions: performanceProFilterOptions,
  performanceProGroupResults: { groups: {}, metadata: {} },
  performancePro: {
    productionTime: null,
    isAwaitingOverviewResponse: true,
  },
  selectedMachine: selectedSortingMachine,
  benchmark: initialBenchmarkState,
  alerts: initialAlertsState,
  managementSettingState: initialManagementSettingsState,
  isUserLoggedIn: false,
  myUser: {
    machines: [],
    userSystemWideAuthorization: {
      systemRoles: [],
      features: [],
      lockedMachines: [],
    },
  },
};

/**
 * Create a new global state with a temporary Reducer.
 * The actual reducer is created when the GlobalContext.Provider is initialized.
 */
export const GlobalContext = createContext<[IGlobalState, Dispatch<Action>]>([
  initialState,
  () => {
    // Intentional blank override
  },
]);

/**
 * Hooks that can be used to fetch or mutate global state.
 */

export type OptionalCancellationToken = CancelTokenSource | undefined;

export const useGlobalContext = () => useContext(GlobalContext);
export const useServiceContextState: (
  cancellationToken?: OptionalCancellationToken
) => IServices = (cancellationToken?: OptionalCancellationToken) => {
  const result = useGlobalContext()[0].services;
  const getAccessToken: AuthTokenGetter = useAccessTokenGetter();

  if (cancellationToken) {
    const httpClient = new AxiosHttpClient(cancellationToken);
    httpClient.setTokenGetter(getAccessToken);

    return CreateHttpServices(httpClient);
  }

  return result;
};
export const usePerformanceProFilterOptions = () =>
  useGlobalContext()[0].performanceProFilterOptions;
export const useSelectedMachine = () => useGlobalContext()[0].selectedMachine;
export const useBenchmarkState = () => useGlobalContext()[0].benchmark;
export const useBenchmarkMeasureDataState = () =>
  useGlobalContext()[0].benchmark.measureData;
export const useAlertsState = () => useGlobalContext()[0].alerts;
export const useManagementSettingsState = () =>
  useGlobalContext()[0].managementSettingState;
export const useUserLoggedIn = () => useGlobalContext()[0].isUserLoggedIn;
export const usePerformanceProGroupsState = () =>
  useGlobalContext()[0].performanceProGroupResults;
export const usePerformanceProProductionTime = () =>
  useGlobalContext()[0].performancePro.productionTime;
export const useTargets = () => useGlobalContext()[0].performancePro.targets;
export const usePerformanceProOverview = () =>
  useGlobalContext()[0].performancePro.overviewData;
export const useMyUserState = () => useGlobalContext()[0].myUser;
export const useIsAwaitingOverviewResponse = () =>
  useGlobalContext()[0].performancePro.isAwaitingOverviewResponse;

export const usePerformanceProFilterOptionsActions = () => {
  const dispatch = useGlobalContext()[1];

  return (newOptions: IPerformanceProFilterOptions) => {
    dispatch({
      type: "UPDATE_PERFORMANCE_PRO_FILTER_OPTIONS",
      payload: newOptions,
    });
  };
};

export const usePerformanceProGroupActions = () => {
  const dispatch = useGlobalContext()[1];

  const updatePerformanceProGroupResult = (
    newResult: MachinePerformanceGroupResult
  ) => {
    dispatch({
      type: "UPDATE_PERFORMANCE_PRO_GROUP_RESULT",
      payload: newResult,
    });
  };

  const updateProductionTimeWindow = (
    newResult: ProductionTimeWindow | null
  ) => {
    dispatch({
      type: "UPDATE_PERFORMANCE_PRO_PRODUCTION_TIME",
      payload: newResult,
    });
  };

  const updateOverviewData = (newOverviewData: OverViewData) => {
    dispatch({
      type: "UPDATE_PERFORMANCE_PRO_OVERVIEW_DATA",
      payload: newOverviewData,
    });
  };

  const updateTargets = (newTargets: TargetsView) => {
    dispatch({
      type: "UPDATE_TARGETS",
      payload: newTargets,
    });
  };
  const updateIsAwaitingOverviewResponse = (isAwaiting: boolean) => {
    dispatch({
      type: "UPDATE_IS_AWAITING_OVERVIEW",
      payload: isAwaiting,
    });
  };

  return {
    updatePerformanceProGroupResult: updatePerformanceProGroupResult,
    updateProductionTimeWindow: updateProductionTimeWindow,
    updateOverviewData: updateOverviewData,
    updateTargets: updateTargets,
    updateIsAwaitingOverviewResponse: updateIsAwaitingOverviewResponse,
  };
};

export const useSelectedMachineAction = () => {
  const dispatch = useGlobalContext()[1];
  return (newSortingMachine: Machine | OwnerMachine) => {
    SetSelectedMachineIdInLocalStorage(newSortingMachine.machineId);
    dispatch({
      type: "UPDATE_SELECTED_SORTING_MACHINE",
      payload: newSortingMachine,
    });
  };
};

export const useBenchmarkActions = () => {
  const dispatch = useGlobalContext()[1];

  const updateBenchmarkState = (state: BenchmarkState) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE",
      payload: state,
    });
  };

  const updateBenchmarkEdittingAndAppliedFilterState = (
    state: MachineMeasuresComparisonFilterState
  ) => {
    dispatch({
      type: "UPDATE_BENCHMARK_EDITITING_AND_APPLIED_FILTER_STATE",
      payload: state,
    });
  };

  const updateBenchmarkMeasureDataState = (dataState: MachineComparison) => {
    dispatch({
      type: "UPDATE_BENCHMARK_MEASURE_DATA_STATE",
      payload: dataState,
    });
  };

  const updateBenchmarkLastValidFilterState = (
    previousState: MachineMeasuresComparisonFilterState
  ) => {
    dispatch({
      type: "UPDATE_BENCHMARK_LAST_VALID_FILTER_STATE",
      payload: previousState,
    });
  };

  const applyBenchmarkFilterState = (
    editingFilterState: MachineMeasuresComparisonFilterState
  ) => {
    dispatch({
      type: "APPLY_BENCHMARK_FILTER_STATE",
      payload: editingFilterState,
    });
  };

  const updateComparedToMachinesCount = (count: number) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_COMPAREDTO_COUNT",
      payload: count,
    });
  };

  const updateMinimumComparedToMachinesCount = (count: number) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_MINIMUM_COMPAREDTO_COUNT",
      payload: count,
    });
  };

  const updateFilterPeriodType = (periodType: string) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_FILTER_PERIODTYPE",
      payload: periodType,
    });
  };

  const updateFilterCountries = (countries: Array<string>) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_FILTER_COUNTRIES",
      payload: countries,
    });
  };

  const updateFilterMachineTypes = (machineTypes: Array<string>) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_FILTER_MACHINE_TYPES",
      payload: machineTypes,
    });
  };

  const updateEggTypes = (eggTypes: Array<EggType>) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_FILTER_EGG_TYPES",
      payload: eggTypes,
    });
  };

  const updateFilterSupplyChanges = (supplyChanges: RangeFilter) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_FILTER_SUPPLY_CHANGES",
      payload: supplyChanges,
    });
  };

  const updateFilterProductChanges = (productChanges: RangeFilter) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_FILTER_PRODUCT_CHANGES",
      payload: productChanges,
    });
  };

  const updateFilterUpgradingPercentage = (
    upgradingPercentage: RangeFilter
  ) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_FILTER_UPGRADING_PERCENTAGE",
      payload: upgradingPercentage,
    });
  };

  const updateFromDate = (newDate: Date) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_FILTER_FROM_DATE",
      payload: newDate,
    });
  };

  const updateUntilDate = (newDate: Date) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_FILTER_UNTIL_DATE",
      payload: newDate,
    });
  };

  const updateSelectedMyMachines = (newMachines: Array<MachineIdFilter>) => {
    dispatch({
      type: "UPDATE_BENCHMARK_STATE_FILTER_SELECTED_MY_MACHINES",
      payload: newMachines,
    });
  };

  const updateDisplayedMachineMeasures = (
    newSelectedMachineMeasures: Array<Measure>
  ) => {
    dispatch({
      type: "UPDATE_BENCHMARK_SELECTED_MACHINE_MEASURES",
      payload: newSelectedMachineMeasures,
    });
  };

  const updateShowRadar = (showRadar: boolean) => {
    dispatch({
      type: "UPDATE_BENCHMARK_SHOW_RADAR",
      payload: showRadar,
    });
  };

  const pushAwaitingRequestCount = () => {
    dispatch({
      type: "PUSH_BENCHMARK_AWAITING_REQUEST_COUNT",
      payload: null,
    });
  };

  const popAwaitingRequestCount = () => {
    dispatch({
      type: "POP_BENCHMARK_AWAITING_REQUEST_COUNT",
      payload: null,
    });
  };

  const pushComparedMachineRequestCount = () => {
    dispatch({
      type: "PUSH_BENCHMARK_AWAITING_COMPARED_MACHINE_REQUEST_COUNT",
      payload: null,
    });
  };

  const popComparedMachineRequestCount = () => {
    dispatch({
      type: "POP_BENCHMARK_AWAITING_COMPARED_MACHINE_REQUEST_COUNT",
      payload: null,
    });
  };

  return {
    updateBenchmarkState: updateBenchmarkState,
    updateBenchmarkLastValidFilterState: updateBenchmarkLastValidFilterState,
    updateBenchmarkFilterState: updateBenchmarkEdittingAndAppliedFilterState,
    updateComparedToMachinesCount: updateComparedToMachinesCount,
    updateMinimumComparedToMachinesCount: updateMinimumComparedToMachinesCount,
    updateFilterSupplyChanges: updateFilterSupplyChanges,
    updateFilterProductChanges: updateFilterProductChanges,
    updateFilterUpgradingPercentage: updateFilterUpgradingPercentage,
    updateFilterPeriodType: updateFilterPeriodType,
    updateFilterCountries: updateFilterCountries,
    updateFilterMachineTypes: updateFilterMachineTypes,
    updateFromDate: updateFromDate,
    updateUntilDate: updateUntilDate,
    updateSelectedMyMachines: updateSelectedMyMachines,
    updateDisplayedMachineMeasures: updateDisplayedMachineMeasures,
    updateBenchmarkMeasureDataState: updateBenchmarkMeasureDataState,
    updateShowRadar: updateShowRadar,
    updateEggTypes: updateEggTypes,
    applyBenchmarkFilterState: applyBenchmarkFilterState,
    pushAwaitingRequestCount: pushAwaitingRequestCount,
    popAwaitingRequestCount: popAwaitingRequestCount,
    pushComparedMachineRequestCount: pushComparedMachineRequestCount,
    popComparedMachineRequestCount: popComparedMachineRequestCount,
  };
};

export const useAlertsActions = () => {
  const dispatch = useGlobalContext()[1];

  const updateAlertsState = (state: AlertsState) => {
    dispatch({
      type: "UPDATE_ALERTS_STATE",
      payload: state,
    });
  };

  return {
    updateAlertsState: updateAlertsState,
  };
};

export const useUserAction = () => {
  const dispatch = useGlobalContext()[1];

  const updateIsUseLoggedIn = (isUseLoggedIn: boolean) => {
    dispatch({
      type: "UPDATE_IS_USER_LOGGED_IN",
      payload: isUseLoggedIn,
    });
  };

  return {
    updateIsUseLoggedIn: updateIsUseLoggedIn,
  };
};

export const useUpdateMyUser = () => {
  const dispatch = useGlobalContext()[1];

  const updateMyUser = (myUser: UserResult) => {
    dispatch({
      type: "UPDATE_MY_USER",
      payload: myUser,
    });
  };

  return {
    updateMyUser: updateMyUser,
  };
};

/**
 * Cancels and renews global CancellationToken
 */
export const CancelHttpRequest = () => {
  source.cancel();
  source = GetCancellationToken();
};
