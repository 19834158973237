import { TableCell } from "@material-ui/core";
import React, { useMemo } from "react";
import { StyledTableRow } from "../UI/Table/Table";
import { dateToTimezoneString, EventLog } from "./Common";
import { EventLogsTableData } from "./EventLogsTablePresentation";

export interface EventLogRowProps {
  eventLog: EventLog;
  columnsToDisplay: Array<keyof EventLogsTableData>;
  timeZone: any;
}

export function EventLogRow({ eventLog, columnsToDisplay, timeZone }: EventLogRowProps) {
  const tableHtml = useMemo(() => {
    let result = [];

    for (const column of columnsToDisplay) {
      switch (column) {
        case "machineId":
          result.push(
            <StringCell key={column} string={eventLog.machineId.toString()} width={50} />
          );
          break;
        case "eventType":
          result.push(<StringCell key={column} string={eventLog.eventType} width={50} />);
          break;
        case "modificationType":
          result.push(
            <StringCell key={column} string={eventLog.modificationType} width={50} />
          );
          break;
        case "date":
          result.push(
            <TimeCell key={column} time={eventLog.date} timeZone={timeZone} width={50} />
          );
          break;
        case "responsible":
          result.push(
            <StringCell key={column} string={eventLog.responsible}  width={100} />
          );
          break;
        case "info":
          result.push(
            <StringCell key={column} string={eventLog.info} width={100} />
          );
          break;
        default:
          break;
      }
    }

    return result;
  }, [columnsToDisplay, eventLog.machineId, eventLog.eventType, eventLog.modificationType, eventLog.date, eventLog.responsible, eventLog.info, timeZone]);

  return <StyledTableRow>{tableHtml}</StyledTableRow>;
}

function StringCell({ string, width }: { string: string, width: number }) {
  return <TableCell align="left" style={{ width: width }}>{string}</TableCell>;
}

function TimeCell({time, timeZone, width}: { time: any, timeZone: any, width: number }) {
  const timeString = dateToTimezoneString(time, timeZone);

  return (
    <TableCell align="left" style={{ width: width }}>
      {timeString}
    </TableCell>
  );
}
