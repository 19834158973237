/**
 * Various helper constants for arrays
 */

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { endOfWeek, format, isSameWeek, startOfWeek } from "date-fns";

export const secondsInAnHour = 3600;
export const secondsInAMinute = 60;
export const milliSecondsInASecond = 1000;
export const everyMinute = 24 * 3600;

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
const secondsInHour = 3600;

export enum DatePeriod {
  Day = 0,
  Week = 1,
  Sparkline = 2,
  Month = 3,
  Year = 4,
}

const monday = 1;

export const DefaultStartOfWeek = monday;

export function getStartOfWeek(date: Date) {
  return startOfWeek(date, { weekStartsOn: DefaultStartOfWeek });
}

export function getEndOfWeek(date: Date) {
  return endOfWeek(date, { weekStartsOn: DefaultStartOfWeek });
}

export function isInSameWeek(date1: Date, date2: Date) {
  return isSameWeek(date1, date2, { weekStartsOn: DefaultStartOfWeek });
}

/**
 * Serializes JSON date strings into JavaScript Date Object
 *
 * @param _, the key string of the JSON object (ignored)
 * @param value, the value string of the JSON object
 */
export function dateFormatter(_: any, value: string) {
  if (dateFormat.test(value)) {
    if (value.charAt(value.length - 1) !== "Z") {
      value += "Z";
    }

    return new Date(Date.parse(value));
  }

  return value;
}

export function formatWithLeadingZero(value: number) {
  if (value < 10) {
    return "0" + value;
  } else {
    return value;
  }
}

export function subtractHours(date: Date, hours: number) {
  return addHours(date, -hours);
}

export function subtractMinutes(date: Date, minutes: number) {
  const minutesInMilliseconds = 60 * 1000 * minutes;

  const newDate = new Date(date);
  newDate.setTime(date.getTime() - minutesInMilliseconds);

  return newDate.getTime();
}

export function addMinutes(date: Date, minutes: number) {
  const minutesInMilliseconds = 60 * 1000 * minutes;

  const newDate = new Date(date);
  newDate.setTime(date.getTime() + minutesInMilliseconds);

  return newDate.getTime();
}

export function addSeconds(date: Date, seconds: number) {
  const secondsInMilliseconds = 1000 * seconds;

  const newDate = new Date(date);
  newDate.setTime(date.getTime() + secondsInMilliseconds);

  return newDate.getTime();
}

export function addHours(date: Date, hours: number) {
  const hoursInMilliseconds = 60 * 60 * 1000 * hours;

  const newDate = new Date(date);
  newDate.setTime(date.getTime() + hoursInMilliseconds);

  return newDate.getTime();
}

export function getFirstOfPeriod(date: Date, period: DatePeriod) {
  if (period === DatePeriod.Month) {
    return getFirstOfMonth(date);
  }
  if (period === DatePeriod.Year) {
    return getFirstOfYear(date);
  }
  if (period === DatePeriod.Week) {
    return getFirstOfWeek(date);
  }

  return date;
}

export function getFirstOfMonth(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getFirstOfYear(date: Date): Date {
  return new Date(date.getFullYear(), 0, 1);
}

export function getFirstOfWeek(date: Date): Date {
  var newDate = new Date(date);
  var day = date.getDay();
  var diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(newDate.setDate(diff));
}

export function getMaxDate(dates: any[]) {
  return new Date(Math.max.apply(null, dates));
}

export function getMinDate(dates: any[]) {
  return new Date(Math.min.apply(null, dates));
}

export function getHoursFromSeconds(seconds: number) {
  return seconds / secondsInHour;
}

/**
 * Formats a Date to a ISO 8601 string (yyyy-MM-dd).
 * @param date The date to format
 *
 * @returns The given date as a ISO 8601 formatted string
 */
export function toISO8601DateStringLocal(date: Date): string {
  return format(validateIfDateOrReturnToday(date), "yyyy-MM-dd");
}

/**
 * Formats a DateTime to a ISO 8601 string (yyyy-MM-dd HH:mm:ss).
 * Does not perform any local timezone conversion.
 *
 * @param date
 * @returns
 */
export function toISO8601DateTimeStringWithoutLocalTime(date: Date): string {
  const dateWithoutTimezone = new Date(
    date.valueOf() + date.getTimezoneOffset() * 60 * 1000
  );
  return format(
    validateIfDateOrReturnToday(dateWithoutTimezone),
    "yyyy-MM-dd HH:mm:ss"
  );
}

/**
 * Formats a DateTime to a ISO 8601 string (yyyy-MM-dd HH:mm:ss).
 * Performs local timezone conversion.
 *
 * @param date
 * @returns
 */
export function toISO8601DateTimeStringWithLocalTime(date: Date): string {
  return format(validateIfDateOrReturnToday(date), "yyyy-MM-dd HH:mm:ss");
}

export function convertMaterialUiPickersDate(date: MaterialUiPickersDate) {
  if (!date) return new Date();
  return new Date(date.getTime());
}

export function getUtcDate(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes()
  );
}

// The date picker for timeslots adds an timezone, eggdata doesn't have a timezone, to match the eggdata the timezone is removed
export function removeTimezone(date: Date) {
  let dateString = format(date, "yyyy-MM-dd");
  let timeString = format(date, "HH:mm:ss");

  return `${dateString}T${timeString}Z`;
}

export function validateIfDateOrReturnToday(date: Date): Date {
  return date instanceof Date && !isNaN(date.valueOf()) ? date : new Date();
}

/**
 * Normalizes date to 00:00 midnight for every timezone
 * @param Date
 * @returns Date
 */
export function normalizeDate(date: Date): Date {
  date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
  return date;
}
