import { SubscriptionType } from "../../components/ManagementMachine/SubscriptionCards/Common";
import { IHttpClient } from "../IHttpClient";
import { IMachineService } from "./IMachineService";

export class MachineService implements IMachineService {
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }

  getMyMachines(): Promise<any> {
    return this.get(`v1/machine/my`);
  }

  getMachineSubscriptions(machineId: MachineId) {
    return this.get(`v1/machine/${machineId}/subscription`);
  }

  getMachineConfiguration(machineId: MachineId) {
    return this.get(`v1/machine/${machineId}/configuration`);
  }

  updateMachineSubscriptions(
    machineId: MachineId,
    subscription: SubscriptionType,
    isSubscriptionRenewed: boolean,
    isActive: boolean,
    responsibleEmail: string
  ) {
    return this.put(
      `v1/machine/${machineId}/subscription/${subscription.toString()}`,
      {
        autoRenew: isSubscriptionRenewed,
        isActive: isActive,
        responsibleEmail: responsibleEmail,
      }
    );
  }

  getAdminEventLogs(machineId: MachineId): Promise<any> {
    return this.get(`v1/machine/event-logs/${machineId}`);
  }

  unlockMachine(machineId: MachineId): Promise<any> {
    return this.post(`v1/machine/${machineId}/unlock`, null);
  }

  private get(url: string): Promise<any> {
    return this.httpclient.get(url);
  }

  private put(url: string, body: any): Promise<any> {
    return this.httpclient.put(url, body);
  }

  private post(url: string, body: any): Promise<any> {
    return this.httpclient.post(url, body);
  }
}
