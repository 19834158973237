/**
 * Wraps a given div over the given JSX element based on a condition.
 * As such:
 * <wrapped-div> <!- If condition === true ->
 *  <children/>
 * </wrapped-div>
 *
 * @param condition The condition whether to wrap the div
 * @param wrapper The div that wraps the component
 * @param children The component to wrap
 */
export const ConditionalWrapper = ({ condition, wrapper, children }: any) =>
  condition ? wrapper(children) : children;
