import React, { useEffect, useState } from "react";
import { useBenchmarkService } from "../../../../../api/ServiceContext";
import {
  useBenchmarkActions,
  useBenchmarkState,
} from "../../../../../store/GlobalContext";
import { t } from "../../../../../utility/TranslateUtility";
import CheckboxSelection from "../CheckboxSelection";

export type MachineTypeApiResponse = {
  results: Array<string>;
};

export default function MachineMeasureMachineTypeSelect() {
  const service = useBenchmarkService();
  const benchmarkState = useBenchmarkState();
  const [machineTypes, setMachineTypes] = useState<Array<string>>();
  const { updateFilterMachineTypes } = useBenchmarkActions();

  useEffect(() => {
    service
      .getMachineTypes()
      .then((response: IApiResponse<MachineTypeApiResponse>) => {
        setMachineTypes(response.data.results);
      });
  }, [service]);

  return (
    <CheckboxSelection
      title={t("benchmark.filters.machineType")}
      options={machineTypes}
      selectedOptions={benchmarkState.editingFilterState.machineTypes}
      noOptionsTitle={t("benchmark.filters.noMachineTypes")}
      onChange={(selection) => updateFilterMachineTypes(selection as string[])}
    />
  );
}
