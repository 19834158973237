import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import FullscreenCardWrapper, {
  FullscreenVariant
} from "../Wrappers/FullscreenCardWrapper";

interface Props {
  supplyId: Nullable<number>;
  supplierName: Nullable<string>;
  shedNumber: Nullable<string>;
  awaitingResponse: boolean;
  receivedAllData: boolean;
}

export default function SupplierShedWidget({
  supplyId,
  supplierName,
  shedNumber,
  awaitingResponse,
  receivedAllData,
}: Props) {
  const { t } = useTranslation();

  const isMobileSize = useIsMobileSize();
  const headingVariant = isMobileSize ? "h5" : "h4";

  return (
    <FullscreenCardWrapper
      title={t("widgets.supplierShed.title")}
      awaitingResponse={awaitingResponse}
      fullscreenVariant={FullscreenVariant.Kpi}
    >
      {receivedAllData === false ? (
        <RetrievingDataMessage supplyId={supplyId} />
      ) : (
        <Typography variant={headingVariant} id={"supplierName"}>
          {`${supplierName} (${shedNumber})`}
        </Typography>
      )}
    </FullscreenCardWrapper>
  );
}

interface RetrievingDataMessageProps {
  supplyId: Nullable<number>;
}

function RetrievingDataMessage({ supplyId }: RetrievingDataMessageProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const isMobileSize = useIsMobileSize();
  const headingVariant = isMobileSize ? "h5" : "h4";

  return !supplyId ? (
    <Typography
      variant={headingVariant}
      className={classes.loadingMessage}
      id={"noSupplyIdMessage"}
    >
      {t("widgets.supplierShed.noSupplyIdMessage")}
    </Typography>
  ) : (
    <>
      <Typography
        variant={headingVariant}
        className={classes.loadingMessage}
        id={"loadingMessage"}
      >
        {t("widgets.supplierShed.loadingMessage", { supplyId: supplyId })}
      </Typography>
      <LinearProgress />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  loadingMessage: {
    marginBottom: theme.spacing(1),
  },
}));

