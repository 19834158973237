import { makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

export function AlertMessageForMachineOwner() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Alert severity="info" className={classes.alert}>
      <AlertTitle>{t("termsAndConditions.alert.ownerTitle")}</AlertTitle>
      {t("termsAndConditions.alert.ownerSubTitle")}
    </Alert>
  );
}

export function AlertMessageForNonMachineOwner() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Alert severity="info" className={classes.alert}>
      <AlertTitle>{t("termsAndConditions.alert.nonOwnerTitle")}</AlertTitle>
      {t("termsAndConditions.alert.nonOwnerSubTitle")}
    </Alert>
  );
}
