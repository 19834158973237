import { everyMinute } from "../../../utility/DateUtility";

export const XRangeChartSettings = {
  chart: {
    type: "xrange",
  },
  xAxis: {
    type: "datetime",
    minorTicks: true,
    showFirstLabel: true,
    showLastLabel: true,
    tickInterval: everyMinute,
    max: 0,
  },
  yAxis: {
    visible: false,
    reversed: true,
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  series: [
    {
      data: [],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};
