import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import HC_offline_exporting from "highcharts/modules/offline-exporting";
import { useMemo } from "react";
import { colors } from "../../colors";
import { IPerformanceProFilterOptions } from "../../store/GlobalContext";
import { EggUnit, GetEggUnitTranslation } from "../../utility/EggUnitUtility";
import {
  createUnitsConverter,
  determineEggTypeName,
  EggType,
  FormatEggs,
} from "../../utility/EggUtility";
import { t } from "../../utility/TranslateUtility";
import { LaneProductionPackingLane } from "../PerformancePro/LaneProductionHook";
import { format, TooltipBuilder } from "../ProductionViews/Tooltip";
import { defaultExportButtonSettings } from "./ChartSettings/BarChartSettings";

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_offline_exporting(Highcharts);

const bucketLaneNames: string[] = ["33", "64"];

interface Props {
  packingLanes: LaneProductionPackingLane[];
  eggUnit: EggUnit;
  options: IPerformanceProFilterOptions;
}

export default function LaneProductionChart({
  packingLanes,
  eggUnit,
  options,
}: Props) {
  const newOptions = useMemo<Highcharts.Options>(() => {
    if (packingLanes === undefined) return {};

    let series = determineColumnSeries(packingLanes, eggUnit, options);
    const yAxisLabel = GetEggUnitTranslation(eggUnit);
    const eggTypeColors = determineEggTypeColors(options);

    return {
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        label: {
          enabled: false,
        },
        column: {
          stacking: "normal",
        },
      },
      series: series,
      title: {
        text: "",
      },
      tooltip: createLaneProductionTooltipFormatter(
        series,
        packingLanes,
        eggTypeColors,
        eggUnit
      ),
      xAxis: {
        minorGridLineWidth: 0,
        minorTicks: true,
        showFirstLabel: true,
        showLastLabel: true,
        tickInterval: 0,
        type: "category",
      },
      yAxis: {
        min: 0,
        title: {
          text: yAxisLabel,
        },
      },
      ...defaultExportButtonSettings,
    };
  }, [packingLanes, eggUnit, options]);

  return <HighchartsReact highcharts={Highcharts} options={newOptions} />;
}

function determineEggTypeColors(options: IPerformanceProFilterOptions) {
  let eggTypeColors = [];
  let eggType = EggType[options.selectedEggType];

  eggTypeColors.push(colors.grey87);
  if (eggType === "AllEggTypes" || eggType === "Cage")
    eggTypeColors.push(colors.cageEgg);
  if (eggType === "AllEggTypes" || eggType === "Barn")
    eggTypeColors.push(colors.barnEgg);
  if (eggType === "AllEggTypes" || eggType === "FreeRange")
    eggTypeColors.push(colors.freeRangeEgg);
  if (eggType === "AllEggTypes" || eggType === "Organic")
    eggTypeColors.push(colors.organicEgg);
  if (eggType === "AllEggTypes" || eggType === "Unmapped")
    eggTypeColors.push(colors.unmappedEgg);
  eggTypeColors.push(colors.grey93);

  return eggTypeColors;
}

function determineColumnSeries(
  packingLanes: LaneProductionPackingLane[],
  eggUnit: EggUnit,
  options: IPerformanceProFilterOptions
): any[] {
  let series: any[] = [];

  let eggType = EggType[options.selectedEggType];

  series.push({
    name: t("uniqueViews.laneProduction.bucketEggs"),
    color: colors.grey87,
    type: "column",
    data: determineEggTypeData("Bucket", packingLanes, eggUnit),
  });

  if (eggType === "AllEggTypes" || eggType === "Cage") {
    series.push({
      name: determineEggTypeName(EggType.Cage),
      color: colors.cageEgg,
      type: "column",
      data: determineEggTypeData("Cage", packingLanes, eggUnit),
    });
  }

  if (eggType === "AllEggTypes" || eggType === "Barn") {
    series.push({
      name: determineEggTypeName(EggType.Barn),
      color: colors.barnEgg,
      type: "column",
      data: determineEggTypeData("Barn", packingLanes, eggUnit),
    });
  }

  if (eggType === "AllEggTypes" || eggType === "FreeRange") {
    series.push({
      name: determineEggTypeName(EggType.FreeRange),
      color: colors.freeRangeEgg,
      type: "column",
      data: determineEggTypeData("FreeRange", packingLanes, eggUnit),
    });
  }

  if (eggType === "AllEggTypes" || eggType === "Organic") {
    series.push({
      name: determineEggTypeName(EggType.Organic),
      color: colors.organicEgg,
      type: "column",
      data: determineEggTypeData("Organic", packingLanes, eggUnit),
    });
  }

  if (eggType === "AllEggTypes" || eggType === "Unmapped") {
    series.push({
      name: determineEggTypeName(EggType.Unmapped),
      color: colors.unmappedEgg,
      type: "column",
      data: determineEggTypeData("Unmapped", packingLanes, eggUnit),
    });
  }

  if (eggType === "Mapped") {
    series.push(
      {
        name: determineEggTypeName(EggType.Cage),
        color: colors.cageEgg,
        type: "column",
        data: determineEggTypeData("Cage", packingLanes, eggUnit),
      },
      {
        name: determineEggTypeName(EggType.Barn),
        color: colors.barnEgg,
        type: "column",
        data: determineEggTypeData("Barn", packingLanes, eggUnit),
      },
      {
        name: determineEggTypeName(EggType.FreeRange),
        color: colors.freeRangeEgg,
        type: "column",
        data: determineEggTypeData("FreeRange", packingLanes, eggUnit),
      },
      {
        name: determineEggTypeName(EggType.Organic),
        color: colors.organicEgg,
        type: "column",
        data: determineEggTypeData("Organic", packingLanes, eggUnit),
      }
    );
  }

  series.push({
    name: t("uniqueViews.laneProduction.offgradeEggs"),
    color: colors.grey93,
    type: "column",
    data: determineEggTypeData("Offgrade", packingLanes, eggUnit),
  });

  return series;
}

function determineEggTypeData(
  dataType: string,
  packingLanes: LaneProductionPackingLane[],
  eggUnit: EggUnit
) {
  let data: any[] = [];
  const inUnits = createUnitsConverter(eggUnit);

  packingLanes.forEach((packingLane: LaneProductionPackingLane) => {
    let laneNumber = packingLane.packingLaneNumber.toString();
    let bucketLaneName = t("uniqueViews.laneProduction.bucketEggs");
    if (bucketLaneNames.includes(laneNumber)) laneNumber = bucketLaneName;
    if (laneNumber === "34")
      laneNumber = t("uniqueViews.laneProduction.endLane");

    if (dataType === "Bucket" && laneNumber === bucketLaneName) {
      data.push([laneNumber, inUnits(packingLane.laneTotal)]);
    } else if (laneNumber !== bucketLaneName) {
      switch (dataType) {
        case "Cage":
          data.push([laneNumber, inUnits(packingLane.cageEggs)]);
          break;
        case "Barn":
          data.push([laneNumber, inUnits(packingLane.barnEggs)]);
          break;
        case "FreeRange":
          data.push([laneNumber, inUnits(packingLane.freeRangeEggs)]);
          break;
        case "Organic":
          data.push([laneNumber, inUnits(packingLane.organicEggs)]);
          break;
        case "Unmapped":
          data.push([laneNumber, inUnits(packingLane.unmappedEggs)]);
          break;
        case "Offgrade":
          data.push([laneNumber, inUnits(packingLane.offgradeEggs)]);
          break;
        default:
          break;
      }
    }
  });

  return data;
}

function createLaneProductionTooltipFormatter(
  series: any[],
  packingLanes: LaneProductionPackingLane[],
  eggTypeColors: string[],
  eggUnit: EggUnit
) {
  return new TooltipBuilder()
    .add((data: any) => {
      let laneIndex = data.x;
      if (laneIndex === 0) laneIndex = "Bucket";
      if (laneIndex === series[1].data.length) laneIndex = "End";
      return `Lane Number: ${laneIndex}<br/>`;
    })
    .add((data: any) => {
      let parts = "";
      let laneIndex = data.x.toString();

      if (data.x === 0) {
        parts += format(
          {
            label: `<span style="color:${eggTypeColors[0]}">\u25CF</span> ${series[0].name}`,
            unit: eggUnit,
            separator: true,
          },
          FormatEggs(
            series[0].data.reduce((a: number, b: number) => a[1] + b[1]),
            eggUnit,
            false
          )
        );
      } else {
        for (let i = 0; i < series.length; i++) {
          if (laneIndex === series[1].data.length.toString()) {
            laneIndex = "End";
          }

          const value = (series[i].data as any[]).find(
            (x) => x[0] === laneIndex
          );
          if (value !== undefined && value !== 0) {
            parts += format(
              {
                label: `<span style="color:${eggTypeColors[i]}">\u25CF</span> ${series[i].name}`,
                unit: eggUnit,
                separator: true,
              },
              FormatEggs(value[1], eggUnit, false)
            );
          }
        }
      }

      parts = addLaneTotal(packingLanes, laneIndex, eggUnit, parts);

      return parts;
    })
    .buildSharedFormatter();
}

function addLaneTotal(
  packingLanes: LaneProductionPackingLane[],
  laneIndex: string,
  eggUnit: EggUnit,
  parts: string
): string {
  let packingLaneNumber = laneIndex;
  if (laneIndex === "0") packingLaneNumber = bucketLaneNames[0];
  if (laneIndex === "End") packingLaneNumber = "34";

  // finds the packingLane based on the laneIndex from the tooltip data
  const lane = packingLanes.find(
    (x) => x.packingLaneNumber.toString() === packingLaneNumber
  );

  // only show the tooltip when the value is found and is not zero
  if (lane !== undefined && lane.laneTotal !== 0) {
    let laneTotal = lane.laneTotal;
    if (packingLaneNumber === bucketLaneNames[0])
      laneTotal = packingLanes
        .filter((x) => bucketLaneNames.includes(x.packingLaneNumber.toString()))
        .reduce((a, b) => a + b.laneTotal, 0);

    parts += format(
      {
        label: `<span style="color:${colors.bloodRed}">\u25CF</span> ${t(
          "uniqueViews.laneProduction.totalEggs"
        )}`,
        unit: eggUnit,
        separator: true,
      },
      FormatEggs(laneTotal, eggUnit)
    );
  }

  return parts;
}
