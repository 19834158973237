import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import React, { useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useUserService } from "../../api/ServiceContext";
import { ConvertSelectedMeasuresToPreferences } from "../../globalHooks/userPreferencesHooks";
import { BenchmarkMeasures } from "../../pages/benchmark/BenchmarkPage";
import {
  useBenchmarkActions,
  useBenchmarkState,
} from "../../store/GlobalContext";
import {
  allMeasures,
  allRadarMeasures,
  Measure,
  minimumMeasuresForRadarGraph,
} from "./MachineMeasureComparison/Types";
import { useStoreBenchmarkStateEffect } from "./selectDisplayedWidgetsHooks";

function PaperComponent(props: any) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function SelectDisplayedWidgetsDialogContainer() {
  const {
    updateDisplayedMachineMeasures,
    updateShowRadar,
  } = useBenchmarkActions();
  const state = useBenchmarkState();
  useStoreBenchmarkStateEffect();
  const [open, setOpen] = useState(false);
  const userService = useUserService();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    userService.updateKpiPreferences(
      ConvertSelectedMeasuresToPreferences(
        state.selectedWidgets,
        state.showRadar
      )
    );
    setOpen(false);
  };

  const handleWidgetSelectionChange = (measures: Measure[]) => {
    updateDisplayedMachineMeasures(measures);

    if (
      measures.filter((x) => allRadarMeasures.includes(x)).length <
      minimumMeasuresForRadarGraph
    ) {
      updateShowRadar(false);
    }
  };

  const handleShowRadarChange = () => {
    if (
      state.selectedWidgets.filter((x) => allRadarMeasures.includes(x))
        .length >= minimumMeasuresForRadarGraph
    ) {
      updateShowRadar(!state.showRadar);
    }
  };

  return (
    <SelectDisplayedWidgetsDialog
      widgetSwitches={
        <WidgetSwitches
          selectedWidgets={state.selectedWidgets}
          onSelectedWidgetsChange={handleWidgetSelectionChange}
          showRadar={state.showRadar}
          onRadarSelectedChange={handleShowRadarChange}
        />
      }
      open={open}
      onClose={handleClose}
      onClickOpen={handleClickOpen}
    />
  );
}

export function SelectDisplayedWidgetsDialog({
  open,
  onClickOpen,
  onClose,
  widgetSwitches,
}: {
  widgetSwitches: any;
  open: boolean;
  onClose: () => void;
  onClickOpen: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={onClickOpen}>
        {t("benchmark.selectWidgetsDialog.buttonTitle")}
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {t("benchmark.selectWidgetsDialog.dialogTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("benchmark.selectWidgetsDialog.dialogBody")}
          </DialogContentText>
          {widgetSwitches}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose} color="primary">
            {t("benchmark.selectWidgetsDialog.dialogCloseButton")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function WidgetSwitches({
  selectedWidgets,
  onSelectedWidgetsChange,
  onRadarSelectedChange,
  showRadar,
}: {
  selectedWidgets: Measure[];
  onSelectedWidgetsChange: (measures: Measure[]) => void;
  onRadarSelectedChange: () => void;
  showRadar: boolean;
}) {
  const { t } = useTranslation();
  const maximumSwitchesPerRow = 5;

  const firstHalfOfMeasures = allMeasures.slice(0, maximumSwitchesPerRow);
  const secondHalfOfMeasures = allMeasures.slice(
    maximumSwitchesPerRow,
    allMeasures.length
  );

  const handleChange = (measure: Measure) => {
    let displayedMeasures = [...selectedWidgets];
    const measureIsDisplayed = displayedMeasures.includes(measure);

    if (measureIsDisplayed) {
      displayedMeasures = displayedMeasures.filter((x) => x !== measure);
    } else {
      displayedMeasures.push(measure);
    }

    onSelectedWidgetsChange(displayedMeasures);
  };

  const handleRadarChange = () => {
    onRadarSelectedChange();
  };

  return (
    <>
      <Grid container>
        <Grid item lg={6}>
          <Grid item lg={12}>
            <WidgetSwitch
              label={t("benchmark.radarGraph.title")}
              onChange={() => {
                handleRadarChange();
              }}
              checked={showRadar}
            />
          </Grid>
          <HalfOfMeasure
            halfOfMeasures={firstHalfOfMeasures}
            onChange={handleChange}
            selectedWidgets={selectedWidgets}
          />
        </Grid>
        <Grid item lg={6}>
          <HalfOfMeasure
            halfOfMeasures={secondHalfOfMeasures}
            onChange={handleChange}
            selectedWidgets={selectedWidgets}
          />
        </Grid>
      </Grid>
    </>
  );
}

export function HalfOfMeasure({
  halfOfMeasures,
  onChange,
  selectedWidgets,
}: {
  halfOfMeasures: Measure[];
  onChange: (measure: Measure) => void;
  selectedWidgets: Measure[];
}) {
  const { t } = useTranslation();

  return (
    <>
      {halfOfMeasures.map((measure) => (
        <Grid item lg={12} key={measure}>
          <WidgetSwitch
            label={t(
              BenchmarkMeasures.find((x) => x.measure === measure)!.title
            )}
            onChange={() => {
              onChange(measure);
            }}
            checked={selectedWidgets.includes(measure)}
          />
        </Grid>
      ))}
    </>
  );
}

export function WidgetSwitch({
  checked,
  onChange,
  label,
}: {
  checked: boolean;
  onChange: () => void;
  label: string;
}) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          name={`switch-${label}`}
          color="primary"
        />
      }
      label={label}
    />
  );
}
