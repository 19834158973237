import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import React, { useEffect, useMemo, useState } from "react";
import { t } from "../../../utility/TranslateUtility";
import { OrderType } from "../../CommonUtil/OrderType";
import { LoadingWrapper } from "../../ProductionViews/LoadingWrapper";
import { StyledTableCell } from "../../UI/Table/Table";
import { iMobaModule, iMobaUser } from "../AddOrUpdateUser/Common";
import { UserRow } from "./UserRow";

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  noUsers: {
    textAlign: "center",
  },
}));

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  })
)(TableSortLabel);

export interface UserTableData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  name: string;
  firstRole: string;
  status: string;
}

export type TableActions = "edit" | "delete" | "reinvite" | "assignModule";

export interface UserListPresentationProps {
  rowsToDisplay?: Array<keyof UserTableData>;
  actionsToDisplay?: Array<TableActions>;
  users: iMobaUser[];
  onEdit?: (user?: iMobaUser) => void;
  onDelete?: (user: iMobaUser) => void;
  onReinvite?: (user: iMobaUser) => void;
  onAssingModule?: (user: iMobaUser, text?: string) => void;
  afterUpsertUser?: () => void;
  totalUsers: number;
  isFilterApplied?: boolean;
  selectedModule?: iMobaModule;
  isLoading: boolean;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: OrderType,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const sortLabels: { key: keyof UserTableData; text: string }[] = [
  {
    key: "name",
    text: "users.labels.name",
  },
  {
    key: "email",
    text: "users.labels.email",
  },
  {
    key: "phoneNumber",
    text: "users.labels.phoneNumber",
  },
  {
    key: "firstRole",
    text: "users.labels.roles",
  },
  {
    key: "status",
    text: "users.labels.status",
  },
];

function DisplayLength(value: number) {
  return value === 0 ? "0" : "1";
}

export function UserListPresentation({
  rowsToDisplay,
  actionsToDisplay,
  users,
  onEdit,
  onDelete,
  onReinvite,
  onAssingModule,
  afterUpsertUser,
  totalUsers,
  isFilterApplied,
  selectedModule,
  isLoading,
}: UserListPresentationProps) {
  const classes = useStyles();

  const [order, setOrder] = useState<OrderType>("asc");
  const [orderBy, setOrderBy] = useState<keyof UserTableData>("firstName");

  const [sortedUsers, setSortedUsers] = useState<iMobaUser[]>(users);

  const createSortHandler =
    (property: keyof UserTableData) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === "asc";
      const newOrder = isAsc ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(property);
    };

  useEffect(() => {
    createSortHandler(orderBy);
    const sortFunction = getComparator(order, orderBy);
    const usersToSort = users.map((x) => {
      return {
        ...x,
        name: x.name!,
        firstRole: x.roles![0],
        status: x.status!,
      };
    });
    setSortedUsers([...usersToSort].sort(sortFunction));
  }, [users, order, orderBy]);

  const renderLabels = useMemo(() => {
    return rowsToDisplay
      ? sortLabels.filter((x) => rowsToDisplay.find((y) => y === x.key))
      : sortLabels;
  }, [rowsToDisplay]);

  const orderText = order === "desc" ? "sorted descending" : "sorted ascending";

  return (
    <LoadingWrapper isLoading={isLoading}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <caption>
            {`${DisplayLength(users.length)}-${users.length} ${t(
              "users.messages.of"
            )} ${users.length}`}{" "}
            {isFilterApplied &&
              t("users.messages.ofTotal", { totalUsers: totalUsers })}
          </caption>
          <TableHead>
            <TableRow>
              {renderLabels.map((label) => (
                <StyledTableCell key={label.key}>
                  <StyledTableSortLabel
                    active={orderBy === label.key}
                    direction={orderBy === label.key ? order : "asc"}
                    onClick={createSortHandler(label.key)}
                  >
                    {t(label.text)}
                    {orderBy === label.key ? (
                      <span className={classes.visuallyHidden}>
                        {orderText}
                      </span>
                    ) : null}
                  </StyledTableSortLabel>
                </StyledTableCell>
              ))}
              <StyledTableCell align="left">
                {t("users.labels.actions")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length === 0 && !isLoading && (
              <TableRow>
                <TableCell colSpan={6} className={classes.noUsers}>
                  {t("users.messages.noMatchingUsers")}
                </TableCell>
              </TableRow>
            )}
            {users.length !== 0 &&
              sortedUsers.map((user) => (
                <UserRow
                  user={user}
                  key={user.email}
                  onEdit={
                    onEdit
                      ? onEdit
                      : () => {
                          // Intentional blank override
                        }
                  }
                  onAssingModule={
                    onAssingModule
                      ? onAssingModule
                      : () => {
                          // Intentional blank override
                        }
                  }
                  selectedModule={
                    selectedModule ? selectedModule : "PerformancePro"
                  }
                  onReinvite={
                    onReinvite
                      ? onReinvite
                      : () => {
                          // Intentional blank override
                        }
                  }
                  onDelete={
                    onDelete
                      ? onDelete
                      : () => {
                          // Intentional blank override
                        }
                  }
                  afterUpsertUser={
                    afterUpsertUser
                      ? afterUpsertUser
                      : () => {
                          // Intentional blank override
                        }
                  }
                  columnsToDisplay={renderLabels.map((x) => x.key)}
                  actionsToDisplay={
                    actionsToDisplay
                      ? actionsToDisplay
                      : ["edit", "delete", "reinvite"]
                  }
                  disableActions={isLoading}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </LoadingWrapper>
  );
}
