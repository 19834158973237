import { Fab, IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import React from "react";
import { colors } from "../../../colors";
import { t } from "../../../utility/TranslateUtility";

const useStyles = makeStyles((theme: any) => ({
  card: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
  },
  wrapper: {
    paddingBottom: theme.spacing(2),
  },
  cardHeader: {
    marginBottom: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(2),
    backgroundColor: colors.dirtGreen,
    color: colors.white,
    [theme.breakpoints.down("xs")]: {
      bottom: theme.spacing(8),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export function SettingsCard({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle?: string;
  children: any;
}) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        <div className={classes.cardHeader}>
          <Typography variant="h5">{title}</Typography>
          {subtitle && <Typography variant="body2">{subtitle}</Typography>}
        </div>
        {children}
      </Card>
    </div>
  );
}

export function SaveButton({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
}) {
  const classes = useStyles();

  return (
    <Fab
      variant="extended"
      onClick={onClick}
      className={classes.fab}
      disabled={disabled}
    >
      <SaveIcon className={classes.extendedIcon} />
      {t("offgradeLabelsPage.saveButton")}
    </Fab>
  );
}

export function DeleteIconButton({
  onClick,
  className,
}: {
  onClick: () => void;
  className?: string;
}) {
  return (
    <IconButton color="secondary" onClick={onClick}>
      <DeleteIcon className={className} color="secondary" />
    </IconButton>
  );
}
