import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { SettingsCard } from "../../../components/Settings/Common";
import EggTypeGroupTable from "../../../components/Settings/EggTypeMapping/EggTypeGroupTable/EggTypeGroupTableContainer";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3),
  },
}));

export default function EggTypeGroupPageContainer() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <SettingsCard
        title={t("eggTypeGroupPage.title")}
        subtitle={t("eggTypeGroupPage.subtitle")}
      >
        <Grid container>
          <Grid item xs={12} lg={8}>
            <EggTypeGroupTable />
          </Grid>
        </Grid>
      </SettingsCard>
    </div>
  );
}
