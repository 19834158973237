import { FormatWithTwoDecimals } from "../../../utility/NumberFormatUtility";
import { t } from "../../../utility/TranslateUtility";
import { format, TooltipBuilder } from "../../ProductionViews/Tooltip";
import { SeriesType } from "../MachineMeasureComparison/MachineMeasureComparison";

type LabelData = {
  x: string | number;
  y: number;
  points: {
    series: LabelDataPointSeries;
    x: string | number;
    y: number;
  }[];
};

type LabelDataPointSeries = {
  name?: string;
  userOptions: {
    id?: string;
    custom: {
      seriesType: SeriesType;
      actualValues: number[];
      categories?: (string | undefined)[];
    };
  };
};

export const tooltipFormatterRadar = new TooltipBuilder()
  .add((data: LabelData) => {
    const avgMachinePoint = data.points.filter(
      (x) => x.series.userOptions.custom.seriesType === "machine-avg"
    )[0];
    const bestMachinesPoint = data.points.filter(
      (x) => x.series.userOptions.custom.seriesType === "best3"
    )[0];
    const worstMachinePoint = data.points.filter(
      (x) => x.series.userOptions.custom.seriesType === "worst3"
    )[0];
    const machinePoints = data.points.filter(
      (x) => x.series.userOptions.custom.seriesType === "machine"
    );

    let parts = format({}, data.x);
    const actualValueIndex = bestMachinesPoint.series.userOptions.custom.categories!.indexOf(
      data.x.toString()
    );

    parts += format(
      { label: t("benchmark.best3Machines"), unit: "%", separator: true },
      FormatWithTwoDecimals(
        bestMachinesPoint.series.userOptions.custom.actualValues[
          actualValueIndex
        ]
      )
    );
    for (let m of machinePoints) {
      parts += format(
        { label: m.series.name, unit: "%", separator: true },
        FormatWithTwoDecimals(
          m.series.userOptions.custom.actualValues[actualValueIndex]
        )
      );
    }
    parts += format(
      { label: t("benchmark.worst3Machines"), unit: "%", separator: true },
      FormatWithTwoDecimals(
        worstMachinePoint.series.userOptions.custom.actualValues[
          actualValueIndex
        ]
      )
    );

    if(avgMachinePoint){
      parts += format(
        { label: t("benchmark.average"), unit: "%", separator: true },
        FormatWithTwoDecimals(
          avgMachinePoint.series.userOptions.custom.actualValues[actualValueIndex]
        )
      );
    }

    return parts;
  })
  .buildFormatter();
