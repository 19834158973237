import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import { EggUnit } from "../../utility/EggUnitUtility";
import {
  EggUnitMenu,
  EggUnitSelectionButton,
} from "../Widgets/Wrappers/EggDisplayUnitCardWrapper";
import { LoadingWrapper } from "./LoadingWrapper";

const useStyles = makeStyles((theme) => ({
  totalNumberOfEggsLabel: {
    marginBottom: theme.spacing(1),
  },
  cardContent: {
    paddingTop: 0,
  },
  cardHeader: {
    paddingBottom: 0,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  caption: {
    fontWeight: "lighter",
    marginBottom: theme.spacing(2),
  },
}));

export function ChartCardHeader({
  title,
  action,
}: {
  title: string;
  action?: any;
}) {
  const classes = useStyles();

  return (
    <CardHeader
      title={title}
      action={action}
      titleTypographyProps={{
        color: "textSecondary",
        gutterBottom: true,
        variant: "body1",
      }}
      className={classes.cardHeader}
    />
  );
}

export function ChartTitle({ children }: any) {
  const classes = useStyles();

  return (
    <Typography className={classes.totalNumberOfEggsLabel} variant="h4">
      {children}
    </Typography>
  );
}

export function ChartSubTitle({ children }: any) {
  const classes = useStyles();

  return <Typography className={classes.caption}>{children}</Typography>;
}

export function ChartHeader({
  title,
  subTitle,
}: {
  title: string;
  subTitle: string;
}) {
  return (
    <Grid container>
      <Grid item xs={6} sm={12}>
        <ChartTitle>{title}</ChartTitle>
      </Grid>
      <Grid item xs={6} sm={12}>
        <ChartSubTitle>{subTitle}</ChartSubTitle>
      </Grid>
    </Grid>
  );
}

export function ChartCard({
  headerTitle,
  isLoading,
  children,
}: {
  headerTitle: string;
  isLoading: boolean;
  children?: JSX.Element | Array<JSX.Element>;
}) {
  const classes = useStyles();

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Card>
        <ChartCardHeader title={headerTitle} />
        <CardContent className={classes.cardContent}>{children}</CardContent>
      </Card>
    </LoadingWrapper>
  );
}

export function ChartCardEggType({
  headerTitle,
  children,
  defaultEggUnit,
  onMenuItemClicked,
  isLoading,
}: {
  headerTitle: string;
  children?: JSX.Element | Array<JSX.Element> | null;
  defaultEggUnit: EggUnit;
  isLoading: boolean;
  onMenuItemClicked: (value: string) => void;
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Card>
        <ChartCardHeader
          title={headerTitle}
          action={
            <EggUnitSelectionButton
              onClick={(event: any) => setAnchorEl(event.currentTarget)}
            />
          }
        />
        <CardContent className={classes.cardContent}>
          {children}
          <EggUnitMenu
            anchorElement={anchorEl}
            onClose={() => setAnchorEl(null)}
            onMenuItemClicked={onMenuItemClicked}
            defaultEggUnit={defaultEggUnit}
          />
        </CardContent>
      </Card>
    </LoadingWrapper>
  );
}
