import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

/**
 * Creates an overlay over the wrapped widget, which enables the user to remove widgets
 * using the provided button.
 *
 * @param children The widget that this wrapper will wrap with an overlay
 * @param removeCallback The corresponding callback is called when the user clicks the remove button.
 */
export default function RemoveableWidgetWrapper({
  children,
  removeCallback,
}: any) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.editable}>
        <Button
          variant={"contained"}
          color="secondary"
          onClick={() => removeCallback()}
          className={classes.deleteButton}
          startIcon={<DeleteIcon />}
        >
          {t("dashboardPage.realTimeDashboard.removeButton.label")}
        </Button>
        <div className={classes.overlay} />
        {children}
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  editable: {
    maxHeight: "100%",
  },
  overlay: {
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 1,
    top: 0,
    borderRadius: "4px",
  },
  deleteButton: {
    margin: "12px",
    position: "absolute",
    zIndex: 2,
  },
}));
