import { Auth0Provider } from "@auth0/auth0-react";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "core-js/stable";
import locale from "date-fns/locale/en-US";
import "fast-text-encoding/text";
import "fontsource-roboto";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import App from "./App";
import config from "./config";
// Include to bundle i18n, core-js
import "./i18n";
import "./index.css";
import { GlobalContextProvider } from "./store/GlobalContextProvider";
import theme from "./theme";
import { DefaultStartOfWeek } from "./utility/DateUtility";
import { ReactQueryProvider } from "./utility/ReactQueryProvider";

if (locale && locale.options) locale.options.weekStartsOn = DefaultStartOfWeek;

// redirectUri must be registered in Auth0 `Allowed Callback URLs`
ReactDOM.render(
  <Auth0Provider
    domain={config.REACT_APP_AUTH0_DOMAIN}
    clientId={config.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={config.REACT_APP_AUTH0_CALLBACK_URL}
    audience={config.REACT_APP_AUTH0_AUDIENCE}
  >
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <GlobalContextProvider>
          <ReactQueryProvider>
            <App />
          </ReactQueryProvider>
        </GlobalContextProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Auth0Provider>,
  document.getElementById("root")
);
