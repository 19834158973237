import { Card, CardContent, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    paddingBottom: "4px",
  },
}));

export default function MachineInformationCardSkeleton() {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardContent className={classes.cardWrapper}>
        <Skeleton variant="text" width={300} height={25} animation="wave" />
        <Skeleton variant="text" width={200} height={25} animation="wave" />

        <Skeleton variant="text" width={150} height={50} animation="wave" />
        <Skeleton variant="text" width={250} height={50} animation="wave" />
      </CardContent>
    </Card>
  );
}
