import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export type AlertMessageType = "info" | "warning" | "error" | "success";

export type AlertMessage = {
  message: string;
  messageType: AlertMessageType;
};

function Alert({
  message,
  onClose,
}: {
  message: AlertMessage;
  onClose: () => void;
}) {
  return (
    <MuiAlert
      elevation={0}
      variant="filled"
      severity={message.messageType}
      onClose={onClose}
    >
      {message.message}
    </MuiAlert>
  );
}

export function AlertSnackbar({
  message,
  open,
  onClose,
}: {
  message?: AlertMessage;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      {message && <Alert message={message} onClose={onClose} />}
    </Snackbar>
  );
}
