import React from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import {
  Features,
  useAuthorizationScope,
} from "../globalHooks/authorizationHooks";
import AcceptTermsAndConditionsPage from "../pages/accept-terms-and-conditions/AcceptTermsAndConditionsPage";
import SkeletonPage from "../pages/skeleton/SkeletonPage";
import { useSelectedMachine } from "../store/GlobalContext";

export function ProtectedRoute(props: ProtectedRouteProps) {
  const selectedMachine = useSelectedMachine();
  const authorizationScope = useAuthorizationScope();

  if (!authorizationScope.hasApprovalForSelectedMachine()) {
    return <AcceptTermsAndConditionsPage />;
  }

  let hasAnyOfRequiredFeatures = false;

  if (props.features) {
    props.features.forEach((requiredFeature) => {
      if (
        authorizationScope.hasAuthorizationForSelectedMachine(requiredFeature)
      ) {
        hasAnyOfRequiredFeatures = true;
      }
    });
    if (!hasAnyOfRequiredFeatures) {
      return <Redirect to="/" />;
    }
  }

  let component = props.component;
  if (props.needsMachine && selectedMachine.machineId === 0) {
    component = SkeletonPage;
  }

  return (
    <Route path={props.path} component={component}>
      {props.children}
    </Route>
  );
}

interface ProtectedRouteProps {
  features?: Features[];
  path: string;
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  needsMachine: boolean;
  children?: any;
}

export function PerformanceProRoute(props: PerformanceProRouteProps) {
  return (
    <ProtectedRoute
      path={props.path}
      component={props.component}
      features={[Features.PerformancePro]}
      needsMachine={true}
    >
      {props.children}
    </ProtectedRoute>
  );
}

interface PerformanceProRouteProps {
  path: string;
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  children?: any;
}
