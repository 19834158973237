import { iMobaUser } from "../../components/Users/AddOrUpdateUser/Common";
import { IKpiPreferences } from "../../globalHooks/userPreferencesHooks";
import { IHttpClient } from "../IHttpClient";
import { IUsersService } from "./IUsersService";

export class UsersService implements IUsersService {
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }

  getMyUser() {
    return this.get(`v1/user/me`);
  }

  getUsers(machineId: MachineId) {
    return this.get(`v1/user/${machineId}`);
  }

  addUser(user: iMobaUser, machineId: MachineId, responsibleEmail: string) {
    return this.post("v1/user", {
      ...user,
      machineId: machineId,
      responsibleEmail: responsibleEmail,
    });
  }

  deleteUser(user: iMobaUser, machineId: MachineId, responsibleEmail: string) {
    return this.delete("v1/user", {
      data: {
        email: user.email,
        machineId: machineId,
        responsibleEmail: responsibleEmail,
      },
    });
  }

  updateUser(user: iMobaUser, machineId: MachineId, responsibleEmail: string) {
    return this.put("v1/user", {
      ...user,
      machineId: machineId,
      responsibleEmail: responsibleEmail,
    });
  }

  updateMyUser(user: iMobaUser) {
    return this.put("v1/user/me", {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
    });
  }

  getUserPreferences() {
    return this.get(`v1/user/preferences`);
  }

  updateUserPreferences(language: string) {
    return this.put(`v1/user/preferences/`, {
      defaultLanguage: language,
    });
  }

  updateKpiPreferences(kpiPreferences: IKpiPreferences) {
    return this.put(`v1/user/kpi-charts/`, {
      ...kpiPreferences,
    });
  }

  getChangePasswordLink() {
    return this.get(`v1/user/change-password-link`);
  }

  private post(url: string, body: any): Promise<any> {
    return this.httpclient.post(url, body);
  }

  private put(url: string, body: any): Promise<any> {
    return this.httpclient.put(url, body);
  }

  private get(url: string, params?: any): Promise<any> {
    return this.httpclient.get(url, params);
  }

  private delete(url: string, params?: any): Promise<any> {
    return this.httpclient.delete(url, params);
  }
}
