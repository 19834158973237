import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ColorSchemaRowType } from "../../../../pages/management-settings/Common";
import { t } from "../../../../utility/TranslateUtility";
import {
  AddNewColorSchemaButton,
  ColorSchemaRow,
  RemoveAllColorSchemasButton,
} from "./Common";

export default function ColorSchemasPresentation({
  rowsToDisplay,
  onNewColorSchema,
  onDeleteRow,
  onValueChanged,
  onRemoveAllColorSchemas,
  showDuplicateColorSchemaError,
}: {
  rowsToDisplay: Array<ColorSchemaRowType>;
  onNewColorSchema: () => void;
  onDeleteRow: (colorSchemaNumber: number) => void;
  onValueChanged: (
    targetValue: number,
    color: string,
    colorSchemaNumber: number
  ) => void;
  onRemoveAllColorSchemas: () => void;
  showDuplicateColorSchemaError: boolean;
}) {
  const classes = useStyles();

  return (
    <>
      {rowsToDisplay.map((colorSchema: ColorSchemaRowType) => {
        return (
          <div key={colorSchema.identifier}>
            <ColorSchemaRow
              colorSchemaNumber={colorSchema.schemaNumber}
              onDeleteRow={() => onDeleteRow(colorSchema.identifier)}
              targetValue={colorSchema.targetValue}
              color={colorSchema.colorHex}
              onChange={(targetValue: number, color: string) =>
                onValueChanged(targetValue, color, colorSchema.identifier)
              }
            />
          </div>
        );
      })}
      <div className={classes.buttonGroup}>
        <AddNewColorSchemaButton
          onClick={onNewColorSchema}
          className={classes.addNewBreakButton}
        />
        <RemoveAllColorSchemasButton
          onClick={onRemoveAllColorSchemas}
          className={classes.spacingTop}
        />
      </div>
      {showDuplicateColorSchemaError && (
        <Typography className={classes.errorMessage} color={"error"}>
          {t("managementSettings.report.duplicateColorSchemaErrorMessage")}
        </Typography>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  spacingBottom: {
    marginBottom: theme.spacing(2),
  },
  spacingTop: {
    marginTop: theme.spacing(4),
  },
  addNewBreakButton: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  buttonGroup: {
    display: "flex",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  errorMessage: {
    marginTop: theme.spacing(1),
  },
}));
