import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserService } from "../../../api/ServiceContext";
import { useSelectedMachine } from "../../../store/GlobalContext";
import { GetCancellationToken } from "../../../utility/HttpServiceUtility";
import { useDispatchAlert } from "../../UI/Alert";
import { emptyUser, iMobaUser } from "../AddOrUpdateUser/Common";
import { ConfirmActionDialog } from "../ConfirmActionDialog";
import { UserListPresentation } from "./UserListPresentation";
import { UserListSearch } from "./UserListSearch";

export interface UsersApiResponse {
  users: iMobaUser[];
}

export interface UserListContainerProps {
  onEdit: (user?: iMobaUser) => void;
  isListUpToDate: boolean;
  afterGetUsers: () => void;
  afterUpsertUser: () => void;
}
export function UserListContainer({
  onEdit,
  isListUpToDate,
  afterGetUsers,
  afterUpsertUser,
}: UserListContainerProps) {
  const [users, setUsers] = useState<iMobaUser[]>([]);
  const [usersToDisplay, setUsersToDisplay] = useState<iMobaUser[]>(users);
  const [searchString, setSearchString] = useState("");
  let deleteUserConfirmed = false;
  let reinviteUserConfirmed = false;
  let [userToBeDeleted, setUserToBeDeleted] = useState(emptyUser);
  let [userToBeReinvited, setuserToBeReinvited] = useState(emptyUser);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [showConfirmReinviteDialog, setShowConfirmReinviteDialog] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cancellationToken = GetCancellationToken();
  const userService = useUserService(cancellationToken);
  const selectedMachine = useSelectedMachine();
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();
  const auth0 = useAuth0();

  useEffect(() => {
    setIsLoading(true);
    userService
      .getUsers(selectedMachine.machineId)
      .then((response: IApiResponse<UsersApiResponse>) => {
        setUsers(response.data.users);
        afterGetUsers();
      })
      .catch((reason) => {
        if (!reason.isCancelled) {
          dispatchAlert({
            message: t("generic.errorMessage"),
            messageType: "error",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      cancellationToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMachine, isListUpToDate]);

  const handleConfirmDelete = () => {
    setShowConfirmDeleteDialog(false);
    deleteUserConfirmed = true;
    handleDelete(userToBeDeleted);
  };

  const handleRejectDelete = () => {
    setShowConfirmDeleteDialog(false);
    deleteUserConfirmed = false;
  };

  const handleDelete = (user: iMobaUser) => {
    setUserToBeDeleted(user);
    if (!deleteUserConfirmed) {
      setShowConfirmDeleteDialog(true);
      return;
    }

    setIsLoading(true);

    userService
      .deleteUser(user, selectedMachine.machineId, auth0.user.email)
      .then(() => {
        afterUpsertUser();
        dispatchAlert({
          message: t("users.messages.userSaved"),
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: t("users.messages.userSaveFailed"),
          messageType: "error",
        });
      })
      .finally(() => {
        setUserToBeDeleted(emptyUser);
        setIsLoading(false);
      });
    deleteUserConfirmed = false;
  };

  const handleConfirmReinvite = () => {
    setShowConfirmReinviteDialog(false);
    reinviteUserConfirmed = true;
    handleReinvite(userToBeReinvited);
  };

  const handleRejectReinvite = () => {
    setShowConfirmReinviteDialog(false);
    reinviteUserConfirmed = false;
  };

  const handleReinvite = (user: iMobaUser) => {
    setuserToBeReinvited(user);

    if (!user.status || user.status !== "Pending") {
      return;
    }
    if (!reinviteUserConfirmed) {
      setShowConfirmReinviteDialog(true);
      return;
    }

    userService
      .addUser(user, selectedMachine.machineId, auth0.user.email)
      .then(() => {
        afterUpsertUser();
        dispatchAlert({
          message: t("users.messages.userReinvited"),
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: t("users.messages.userReinviteFailed"),
          messageType: "error",
        });
      })
      .finally(() => {
        setuserToBeReinvited(emptyUser);
      });
    reinviteUserConfirmed = false;
  };

  useEffect(() => {
    setUsersToDisplay(
      users.filter((user) => {
        return (
          user.name!.toLowerCase().includes(searchString.toLowerCase()) ||
          user.email.toLowerCase().includes(searchString.toLowerCase()) ||
          user.phoneNumber.toLowerCase().includes(searchString.toLowerCase())
        );
      })
    );
  }, [users, searchString]);

  return (
    <>
      <UserListSearch
        searchString={searchString}
        setSearchString={setSearchString}
      />
      <UserListPresentation
        users={usersToDisplay}
        totalUsers={users.length}
        isFilterApplied={users.length !== usersToDisplay.length}
        onEdit={onEdit}
        onDelete={handleDelete}
        onReinvite={handleReinvite}
        afterUpsertUser={afterUpsertUser}
        isLoading={isLoading}
      />
      <ConfirmActionDialog
        message={t("users.messages.confirmDelete", {
          email: userToBeDeleted.email,
        })}
        isOpen={showConfirmDeleteDialog}
        onConfirm={handleConfirmDelete}
        onReject={handleRejectDelete}
      />
      <ConfirmActionDialog
        message={t("users.messages.confirmReinvite", {
          email: userToBeReinvited.email,
        })}
        isOpen={showConfirmReinviteDialog}
        onConfirm={handleConfirmReinvite}
        onReject={handleRejectReinvite}
      />
    </>
  );
}
