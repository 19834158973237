import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMemo } from "react";
import {
  matchPath,
  Redirect,
  Switch,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { KpiMeasureType } from "../../components/PerformancePro";
import EggProductionView from "../../components/PerformancePro/EggProductionView";
import LaneProductionView from "../../components/PerformancePro/LaneProductionContainer";
import MachineStopsView from "../../components/PerformancePro/MachineStopsViewContainer";
import { PerformanceProView } from "../../components/PerformancePro/PerformanceProView";
import {
  NavigationItem,
  TabNavigation,
  TabNavigationVariant
} from "../../components/UI/TabNavigation";
import { useSelectedMachine } from "../../store/GlobalContext";
import { PerformanceProRoute } from "../../utility/ProtectedRoute";
import { t } from "../../utility/TranslateUtility";
import { PerformanceProToolbarContainer } from "./PerformanceProToolbar";

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  gridContent: {
    paddingTop: 0,
  },
  emptyContent: {
    padding: theme.spacing(3),
    height: "40vh",
  },
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

export function PerformanceProProduction() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  let location = useLocation();
  const machineId = useSelectedMachine().machineId;

  const subNavigationItems: Array<NavigationItem> = [
    {
      link: `${url}/total-eggs`,
      title: t("performanceViews.navigationItems.totalEggs"),
      shouldRender: true
    },
    {
      link: `${url}/availability`,
      title: t("performanceViews.navigationItems.availability"),
      shouldRender: true
    },
    {
      link: `${url}/production-time`,
      title: t("performanceViews.navigationItems.productionTime"),
      shouldRender: true
    },
    {
      link: `${url}/throughput`,
      title: t("performanceViews.navigationItems.throughput"),
      shouldRender: true
    },
    {
      link: `${url}/fill-rate`,
      title: t("performanceViews.navigationItems.fillRate"),
      shouldRender: true
    },
  ];

  const selectedNavigationItem = useMemo<number>((): number => {
    const result = subNavigationItems.findIndex((item) =>
      matchPath(location.pathname, {
        path: item.link,
        exact: false,
        strict: false,
      })
    );

    return result < 0 ? 0 : result;
  }, [subNavigationItems, location]);

  return (
    <>
      <PerformanceProToolbarContainer
        enableEggTypeSelect={true}
        enableDatePeriod={true}
      />
      <div className={classes.content}>
        <Paper>
          <TabNavigation
            navigationItems={subNavigationItems}
            variant={TabNavigationVariant.Secondary}
            selectedNavigationItem={selectedNavigationItem}
          />
          <Switch>
            <PerformanceProRoute path={`${path}/total-eggs`}>
              <PerformanceProView
                machineId={machineId}
                measure={KpiMeasureType.TotalEggs}
              />
            </PerformanceProRoute>
            <PerformanceProRoute path={`${path}/availability`}>
              <PerformanceProView
                machineId={machineId}
                measure={KpiMeasureType.ProductionAvailability}
              />
            </PerformanceProRoute>
            <PerformanceProRoute path={`${path}/production-time`}>
              <PerformanceProView
                machineId={machineId}
                measure={KpiMeasureType.ProductionTime}
              />
            </PerformanceProRoute>
            <PerformanceProRoute path={`${path}/throughput`}>
              <PerformanceProView
                machineId={machineId}
                measure={KpiMeasureType.Throughput}
              />
            </PerformanceProRoute>
            <PerformanceProRoute path={`${path}/fill-rate`}>
              <PerformanceProView
                machineId={machineId}
                measure={KpiMeasureType.FillRate}
              />
            </PerformanceProRoute>
            <Redirect from={`${path}/`} to={`${path}/total-eggs`} />
          </Switch>
        </Paper>
      </div>

      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <EggProductionView machineId={machineId} />
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <MachineStopsView machineId={machineId} />
            </Grid>
          </Grid>
        </Paper>
      </div>

      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <LaneProductionView machineId={machineId} />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
}
