import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import React, { useEffect, useMemo, useState } from "react";
import { t } from "../../utility/TranslateUtility";
import { OrderType } from "../CommonUtil/OrderType";
import { StyledTableCell } from "../UI/Table/Table";
import { EventLog } from "./Common";
import { EventLogRow } from "./EventLogRow";

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  noEventLogs: {
    textAlign: "center",
  },
}));

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  })
)(TableSortLabel);

export interface EventLogsTableData {
  machineId: string;
  date: Date;
  eventType: string;
  modificationType: string;
  responsible: string;
  info: string;
}

export interface EventLogsTablePresentationProps {
  rowsToDisplay?: Array<keyof EventLogsTableData>;
  eventLogs: EventLog[];
  timeZone: any;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: OrderType,
  orderBy: Key
): (
  a: { [key in Key]: number | string | Date },
  b: { [key in Key]: number | string | Date }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const sortLabels: { key: keyof EventLogsTableData; text: string }[] = [
  {
    key: "date",
    text: "ownerPage.eventLogsTable.date",
  },
  {
    key: "machineId",
    text: "ownerPage.eventLogsTable.machineId",
  },
  {
    key: "eventType",
    text: "ownerPage.eventLogsTable.eventType",
  },
  {
    key: "modificationType",
    text: "ownerPage.eventLogsTable.modificationType",
  },
  {
    key: "responsible",
    text: "ownerPage.eventLogsTable.responsible",
  },
  {
    key: "info",
    text: "ownerPage.eventLogsTable.info",
  },
];

function DisplayLength(value: number) {
  return value === 0 ? "0" : "1";
}

export function EventLogsTablePresentation({
  rowsToDisplay,
  eventLogs,
  timeZone,
}: EventLogsTablePresentationProps) {
  const classes = useStyles();

  const [order, setOrder] = useState<OrderType>("desc");
  const [orderBy, setOrderBy] = useState<keyof EventLogsTableData>("date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [sortedEventLogs, setSortedEventLogs] = useState<EventLog[]>(eventLogs);

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const createSortHandler =
    (property: keyof EventLogsTableData) =>
    (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === "asc";
      const newOrder = isAsc ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(property);
    };

  useEffect(() => {
    createSortHandler(orderBy);
    const sortFunction = getComparator(order, orderBy);
    setSortedEventLogs([...eventLogs].sort(sortFunction));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventLogs, order, orderBy, timeZone]);

  const renderLabels = useMemo(() => {
    return rowsToDisplay
      ? sortLabels.filter((x) => rowsToDisplay.find((y) => y === x.key))
      : sortLabels;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsToDisplay, timeZone]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <caption>
          {`${DisplayLength(eventLogs.length)}-${eventLogs.length} ${t(
            "ownerPage.eventLogsMessages.of"
          )} ${eventLogs.length}`}{" "}
        </caption>
        <TableHead>
          <TableRow>
            {renderLabels.map((label) => (
              <StyledTableCell key={label.key}>
                <StyledTableSortLabel
                  active={orderBy === label.key}
                  direction={orderBy === label.key ? order : "asc"}
                  onClick={createSortHandler(label.key)}
                >
                  {t(label.text)}
                  {orderBy === label.key ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </StyledTableSortLabel>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {eventLogs.length === 0 || timeZone === null ? (
            <TableRow>
              <TableCell colSpan={6} className={classes.noEventLogs}>
                {t("ownerPage.eventLogsMessages.noMatchingEventLogs")}
              </TableCell>
            </TableRow>
          ) : (
            sortedEventLogs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((eventLog) => (
                <EventLogRow
                  key={
                    eventLog.date.toString() +
                    eventLog.modificationType.toString()
                  }
                  eventLog={eventLog}
                  columnsToDisplay={renderLabels.map((x) => x.key)}
                  timeZone={timeZone}
                />
              ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={sortedEventLogs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
