import { FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import React from "react";
import { t } from "../../utility/TranslateUtility";

export function EnableNotificationSwitch({
  notificationsEnabled,
  onEnabledChange,
}: {
  notificationsEnabled: boolean;
  onEnabledChange: (enableNotifications: boolean) => void;
}) {
  const handleOnChange = (_: any, checkedValue: boolean) => {
    onEnabledChange(checkedValue);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={notificationsEnabled}
          onChange={handleOnChange}
          name={`switch-enable-notification`}
          color="primary"
        />
      }
      label={t("notificationManagement.labels.pushNotificationsEnabled")}
    />
  );
}
