import { Button, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import { SettingsCard } from "../../components/Settings/Common";
import AddOrUpdateUserDialog from "../../components/Users/AddOrUpdateUser/AddOrUpdateUserUserDialogContainer";
import { iMobaUser } from "../../components/Users/AddOrUpdateUser/Common";
import { UserListContainer } from "../../components/Users/UserList/UserListContainer";
import { useMachineFromParamsOrRedirect } from "../../globalHooks/machineHooks";
import { t } from "../../utility/TranslateUtility";
import { PageWithMachineId } from "../PageWithMachineId";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3),
  },
  button: {
    float: "right",
    [theme.breakpoints.down("xs")]: {
      float: "none",
    },
  },
}));

export function UserManagementPage() {
  useMachineFromParamsOrRedirect("users/manage");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<iMobaUser>();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isListUpToDate, setIsListUpToDate] = useState(true);

  const classes = useStyles();

  const afterUpsertUser = () => {
    setIsListUpToDate(false);
  };

  const afterGetUsers = () => {
    setIsListUpToDate(true);
  };

  const openDialog = (user?: iMobaUser) => {
    if (user) {
      setEditingUser(user);
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
    setDialogOpen(!dialogOpen);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <PageWithMachineId page={"users/manage"}>
      <div className={classes.wrapper}>
        <SettingsCard title={t("users.titles.manageUsers")}>
          <AddOrUpdateUserDialog
            isOpen={dialogOpen}
            handleClose={closeDialog}
            isUpdate={isUpdate}
            existingUser={editingUser}
            afterUpsertUser={afterUpsertUser}
          />

          <Button
            className={classes.button}
            variant={"outlined"}
            color={"primary"}
            onClick={() => openDialog()}
          >
            <AddIcon />
            {t("users.buttons.addUser")}
          </Button>

          <UserListContainer
            onEdit={openDialog}
            afterGetUsers={afterGetUsers}
            isListUpToDate={isListUpToDate}
            afterUpsertUser={afterUpsertUser}
          />
        </SettingsCard>
      </div>
    </PageWithMachineId>
  );
}
