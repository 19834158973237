import {
  Button,
  FormControlLabel,
  makeStyles,
  Switch,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { t } from "../../../utility/TranslateUtility";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  spacer: {
    flex: 1,
  },
}));

export interface AssignAdminPresentationProps {
  openDialog: () => void;
  onMakeMeAdmin: () => void;
  userIsAdmin: boolean;
  toggleDisabled: boolean;
  isLoading: boolean;
}

export function AssignAdminPresentation({
  openDialog,
  onMakeMeAdmin,
  userIsAdmin,
  toggleDisabled,
  isLoading,
}: AssignAdminPresentationProps) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {isLoading ? (
        <Skeleton variant="rect" width={200} height={50} animation="wave" />
      ) : (
        <Button variant={"contained"} color={"primary"} onClick={openDialog}>
          <AddIcon />
          {t("users.buttons.assignAdmin")}
        </Button>
      )}
      <div className={classes.spacer} />
      {isLoading ? (
        <Skeleton variant="rect" width={100} height={50} animation="wave" />
      ) : (
        <FormControlLabel
          control={
            <Switch
              checked={userIsAdmin}
              onChange={onMakeMeAdmin}
              color="primary"
              disabled={toggleDisabled}
            />
          }
          label={t("users.buttons.makeMeAdmin")}
        />
      )}
    </div>
  );
}
