import { useEffect, useMemo, useState } from "react";
import { useManagementSettingsService } from "../../../api/ServiceContext";
import { QualityChartSetting } from "../../../pages/management-settings/Common";
import { useSelectedMachine } from "../../../store/GlobalContext";

const defaultSettings: QualityChartSetting = {
  qualityChartSetting: "InfeedBasedCharts",
  isDisplayWorkShiftWidgetsEnabled: true,
};

export function useQualityChartSettingManagement(): {
  qualityChartSetting: QualityChartSetting;
  setQualityChartSetting: (setting: QualityChartSetting) => void;
  saveQualityChartSetting: () => Promise<void>;
  isDirty: boolean;
} {
  const machineId = useSelectedMachine().machineId;
  const service = useManagementSettingsService();

  const [savedSettings, setSavedSettings] = useState<QualityChartSetting>(
    defaultSettings
  );
  const [settings, setSettings] = useState<QualityChartSetting>(
    defaultSettings
  );
  const isDirty = useMemo(() => {
    return (
      settings.qualityChartSetting !== savedSettings.qualityChartSetting ||
      settings.isDisplayWorkShiftWidgetsEnabled !==
      savedSettings.isDisplayWorkShiftWidgetsEnabled
    );
  }, [settings, savedSettings]);

  useEffect(() => {
    service
      .getMachineChartViewSettings(machineId.toString())
      .then((response: IApiResponse<QualityChartSetting>) => {
        setSavedSettings({ ...response.data });
        setSettings({ ...response.data });
      });
  }, [service, machineId]);

  const saveQualityChartSetting = async () => {
    return service
        .updateMachineChartViewSettings(machineId.toString(), { ...settings })
        .then(() => setSavedSettings({ ...settings }));
  };

  return {
    qualityChartSetting: settings,
    setQualityChartSetting: setSettings,
    saveQualityChartSetting: saveQualityChartSetting,
    isDirty: isDirty,
  };
}
