import { useEffect, useState } from "react";
import { useUserService } from "../../../api/ServiceContext";
import { useSelectedMachine } from "../../../store/GlobalContext";
import { t } from "../../../utility/TranslateUtility";
import { useDispatchAlert } from "../../UI/Alert";
import { iMobaModule, iMobaUser } from "../AddOrUpdateUser/Common";
import { UsersApiResponse } from "../UserList/UserListContainer";
import { AssignUserToModulePresentation } from "./AssignUserToModulePresentation";

export interface AssignUserToModuleContainerProps {
  isOpen: boolean;
  onClose: () => void;
  selectedModule: iMobaModule;
  handleAssign: (user: iMobaUser, text?: string) => void;
  usersUpToDate: boolean;
  setUsersUpToDate: (x: boolean) => void;
  isLoading: boolean;
}

export function AssingUserToModuleContainer({
  isOpen,
  onClose,
  selectedModule,
  handleAssign,
  setUsersUpToDate,
  usersUpToDate,
  isLoading,
}: AssignUserToModuleContainerProps) {
  const [users, setUsers] = useState<iMobaUser[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const userService = useUserService();
  const selectedMachine = useSelectedMachine();
  const dispatchAlert = useDispatchAlert();

  const updateUsers = () => {
    setIsLoadingUsers(true);
    userService
      .getUsers(selectedMachine.machineId)
      .then((response: IApiResponse<UsersApiResponse>) => {
        setUsers(response.data.users);
        setIsLoadingUsers(false);
      })
      .catch(() => {
        dispatchAlert({
          message: t("generic.errorMessage"),
          messageType: "error",
        });
      })
      .finally(() => {
        setUsersUpToDate(true);
      });
  };

  useEffect(() => {
    if (!usersUpToDate) {
      updateUsers();
    }
  }, [userService, usersUpToDate]);

  useEffect(() => {
    setUsers([]);
    updateUsers();
  }, [selectedMachine]);

  return (
    <AssignUserToModulePresentation
      handleAssign={handleAssign}
      isOpen={isOpen}
      onClose={onClose}
      selectedModule={selectedModule}
      users={users}
      isLoading={isLoading}
      isLoadingUsers={isLoadingUsers}
    />
  );
}
