import { useEffect, useMemo, useState } from "react";

/**
 * Determines whether components should display their initial loading indicator
 * or their second loading indicator.
 *
 * Components should only display their first loading indicator when they are initially fetching data.
 * Typically, this is displayed using a Skeleton.
 *
 * As a second loading indicator, a progress indicator (circular or linear) can be used.
 *
 * @param isLoading
 * @returns displayFirstLoadingIndicator, displaySecondLoadingIndicator
 */
export function useChartLoading(isLoading: boolean) {
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const displayFirstLoadingIndicator: boolean = useMemo(() => {
    return isLoading && isFirstLoad;
  }, [isLoading, isFirstLoad]);

  const displaySecondLoadingIndicator: boolean = useMemo(() => {
    return isLoading && !isFirstLoad;
  }, [isLoading, isFirstLoad]);

  useEffect(() => {
    if (isFirstLoad && !isLoading) {
      setIsFirstLoad(false);
    }
  }, [isLoading, isFirstLoad]);

  return {
    displayFirstLoadingIndicator: displayFirstLoadingIndicator,
    displaySecondLoadingIndicator: displaySecondLoadingIndicator,
  };
}
