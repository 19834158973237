import { IGlobalState } from "./GlobalContext";

export interface Action {
  type: string;
  payload: any;
}

/**
 * The Reducer takes the previous state an and action, and returns the next state.
 * This function will contain all actions to mutate global state in this application.
 *
 * @param state The current (previous) state
 * @param action The action to perform on the state
 *
 * @returns The state after performing the action
 */
export function Reducer(state: IGlobalState, action: Action): IGlobalState {
  switch (action.type) {
    case "UPDATE_PERFORMANCE_PRO_GROUP_RESULT":
      return { ...state, performanceProGroupResults: { ...action.payload } };
    case "UPDATE_PERFORMANCE_PRO_PRODUCTION_TIME":
      return {
        ...state,
        performancePro: {
          ...state.performancePro,
          productionTime: { ...action.payload },
        },
      };
    case "UPDATE_PERFORMANCE_PRO_OVERVIEW_DATA":
      return {
        ...state,
        performancePro: {
          ...state.performancePro,
          overviewData: { ...action.payload },
        },
      };
    case "UPDATE_TARGETS":
      return {
        ...state,
        performancePro: {
          ...state.performancePro,
          targets: { ...action.payload },
        },
      };
    case "UPDATE_IS_AWAITING_OVERVIEW":
      return {
        ...state,
        performancePro: {
          ...state.performancePro,
          isAwaitingOverviewResponse: action.payload,
        },
      };
    case "UPDATE_PERFORMANCE_PRO_FILTER_OPTIONS":
      return { ...state, performanceProFilterOptions: { ...action.payload } };
    case "UPDATE_SELECTED_SORTING_MACHINE":
      return { ...state, selectedMachine: { ...action.payload } };
    case "UPDATE_BENCHMARK_STATE":
      return { ...state, benchmark: { ...action.payload } };
    case "UPDATE_MANAGEMENT_SETTINGS_IS_SAVING":
      return {
        ...state,
        managementSettingState: {
          ...state.managementSettingState,
          isSaving: action.payload,
        },
      };
    case "UPDATE_IS_USER_LOGGED_IN":
      return {
        ...state,
        isUserLoggedIn: action.payload,
      };
    case "UPDATE_MY_USER":
      return {
        ...state,
        myUser: action.payload,
      };
    case "UPDATE_BENCHMARK_LAST_VALID_FILTER_STATE":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          lastValidFilterState: action.payload,
        },
      };
    case "APPLY_BENCHMARK_FILTER_STATE":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          appliedFilterState: action.payload,
        },
      };
    case "UPDATE_BENCHMARK_EDITITING_AND_APPLIED_FILTER_STATE":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          editingFilterState: { ...action.payload },
          appliedFilterState: { ...action.payload },
        },
      };
    case "UPDATE_BENCHMARK_STATE_COMPAREDTO_COUNT":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          comparedToMachinesCount: action.payload,
        },
      };
    case "UPDATE_BENCHMARK_STATE_MINIMUM_COMPAREDTO_COUNT":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          minimumComparedToMachinesCount: action.payload,
        },
      };
    case "UPDATE_BENCHMARK_STATE_FILTER_PERIODTYPE":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          editingFilterState: {
            ...state.benchmark.editingFilterState,
            periodType: action.payload,
          },
        },
      };
    case "UPDATE_BENCHMARK_STATE_FILTER_COUNTRIES":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          editingFilterState: {
            ...state.benchmark.editingFilterState,
            countries: action.payload,
          },
        },
      };
    case "UPDATE_BENCHMARK_STATE_FILTER_MACHINE_TYPES":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          editingFilterState: {
            ...state.benchmark.editingFilterState,
            machineTypes: action.payload,
          },
        },
      };
    case "UPDATE_BENCHMARK_STATE_FILTER_EGG_TYPES":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          editingFilterState: {
            ...state.benchmark.editingFilterState,
            eggTypes: action.payload,
          },
        },
      };
    case "UPDATE_BENCHMARK_STATE_FILTER_SUPPLY_CHANGES":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          editingFilterState: {
            ...state.benchmark.editingFilterState,
            supplyChanges: action.payload,
          },
        },
      };
    case "UPDATE_BENCHMARK_STATE_FILTER_PRODUCT_CHANGES":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          editingFilterState: {
            ...state.benchmark.editingFilterState,
            productChanges: action.payload,
          },
        },
      };
    case "UPDATE_BENCHMARK_STATE_FILTER_UPGRADING_PERCENTAGE":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          editingFilterState: {
            ...state.benchmark.editingFilterState,
            upgradingPercentage: action.payload,
          },
        },
      };
    case "UPDATE_ALERTS_STATE":
      return { ...state, alerts: { ...action.payload } };
    case "UPDATE_BENCHMARK_STATE_FILTER_FROM_DATE":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          editingFilterState: {
            ...state.benchmark.editingFilterState,
            fromDate: action.payload,
          },
        },
      };
    case "UPDATE_BENCHMARK_STATE_FILTER_UNTIL_DATE":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          editingFilterState: {
            ...state.benchmark.editingFilterState,
            untilDate: action.payload,
          },
        },
      };
    case "UPDATE_BENCHMARK_STATE_FILTER_SELECTED_MY_MACHINES":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          editingFilterState: {
            ...state.benchmark.editingFilterState,
            myMachines: action.payload,
          },
          appliedFilterState: {
            ...state.benchmark.appliedFilterState,
            myMachines: action.payload,
          },
        },
      };
    case "UPDATE_BENCHMARK_SELECTED_MACHINE_MEASURES":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          selectedWidgets: action.payload,
        },
      };
    case "UPDATE_BENCHMARK_SHOW_RADAR":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          showRadar: action.payload,
        },
      };
    case "PUSH_BENCHMARK_AWAITING_REQUEST_COUNT":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          awaitingRequestCount: state.benchmark.awaitingRequestCount + 1,
        },
      };
    case "POP_BENCHMARK_AWAITING_REQUEST_COUNT":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          awaitingRequestCount: state.benchmark.awaitingRequestCount - 1,
        },
      };
    case "PUSH_BENCHMARK_AWAITING_COMPARED_MACHINE_REQUEST_COUNT":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          comparedMachineRequestCount:
            state.benchmark.comparedMachineRequestCount + 1,
        },
      };
    case "POP_BENCHMARK_AWAITING_COMPARED_MACHINE_REQUEST_COUNT":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          comparedMachineRequestCount:
            state.benchmark.comparedMachineRequestCount - 1,
        },
      };
    case "UPDATE_BENCHMARK_MEASURE_DATA_STATE":
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          measureData: {
            data: [
              ...state.benchmark.measureData.data.filter(
                (x) => x.measure !== action.payload.measure
              ),
              action.payload,
            ],
          },
        },
      };
    default:
      return state;
  }
}
