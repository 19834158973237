import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOwnerService } from "../../../api/ServiceContext";
import { TermsAndConditionsVersion } from "../../../constants";
import { useTermsAndConditionsTracking } from "../../../globalHooks/applicationInsightsTrackingHooks";
import { useSelectedMachine } from "../../../store/GlobalContext";
import { useDispatchAlert } from "../../UI/Alert";
import { AcceptTermsAndConditionsPresentation } from "./AcceptTermsAndConditionsPresentation";

export function AcceptTermsAndConditionsContainer() {
  const ownerService = useOwnerService();
  const selectedMachine = useSelectedMachine();
  const { user } = useAuth0();
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();
  const { trackTermsAndConditionsAccepted } = useTermsAndConditionsTracking();

  const [isLoading, setIsLoading] = useState(false);

  const [acceptedTermsAndConditions, setAcceptedTermsAndConditons] =
    useState(false);

  const handleAcceptCheckboxChecked = (newValue: boolean) => {
    setAcceptedTermsAndConditons(newValue);
  };

  const onAcceptButtonClicked = () => {
    trackTermsAndConditionsAccepted();
    setIsLoading(true);
    ownerService
      .approveMachine(
        selectedMachine.machineId,
        TermsAndConditionsVersion,
        user.email
      )
      .then(() => {
        dispatchAlert({
          message: t("termsAndConditions.snackbar.successMessage"),
          messageType: "success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch(() => {
        dispatchAlert({
          message: t("termsAndConditions.snackbar.errorMessage"),
          messageType: "error",
        });
        setIsLoading(false);
      });
  };

  return (
    <AcceptTermsAndConditionsPresentation
      acceptedTermsAndConditions={acceptedTermsAndConditions}
      onAcceptCheckboxChecked={handleAcceptCheckboxChecked}
      onAcceptButtonClicked={onAcceptButtonClicked}
      isLoading={isLoading}
    />
  );
}
