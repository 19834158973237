import { AppBar, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { useMyUserState } from "../../store/GlobalContext";
import { t } from "../../utility/TranslateUtility";

export interface MachineSelectionBarProps {
  selectedMachineId: number | string;
  ownedMachines: any[];
  page: Page;
}

export type Page = "owner" | "management/machine";

function MachineSelectionBar({
  selectedMachineId,
  ownedMachines,
  page,
}: MachineSelectionBarProps) {
  const browserHistory = useHistory();
  const userState = useMyUserState();

  function handleClick(x: any) {
    browserHistory.push(`/${page}/${x.machineId}`);

    const machineIsLocked = userState.userSystemWideAuthorization.lockedMachines
      .includes(x.machineId);
    const hasSystemRoles = userState.userSystemWideAuthorization.systemRoles
      && userState.userSystemWideAuthorization.systemRoles.length > 0;

    if (machineIsLocked && hasSystemRoles) {
      alert(t("drawer.selectedMachineIsLockedForUserWithSystemRole"));
    }
  }

  return (
    <AppBar position="static" color="default">
      <Tabs
        value={selectedMachineId}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {ownedMachines.map((x, index) => (
          <Tab
            key={index}
            label={`${x.machineId} - ${x.machineName}`}
            value={x.machineId.toString()}
            onClick={() => {
              handleClick(x);
            }}
          />
        ))}
      </Tabs>
    </AppBar>
  );
}

export default MachineSelectionBar;
