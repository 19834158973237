import { useEffect, useMemo, useState } from "react";
import {
  useBenchmarkService,
  useMachineService,
} from "../../../api/ServiceContext";
import {
  Features,
  useAuthorizationScope,
} from "../../../globalHooks/authorizationHooks";
import {
  useBenchmarkActions,
  useBenchmarkState,
  useSelectedMachine,
} from "../../../store/GlobalContext";
import { GetCancellationToken } from "../../../utility/HttpServiceUtility";
import { t } from "../../../utility/TranslateUtility";
import { useDispatchAlert } from "../../UI/Alert/alertHooks";
import { MyMachinesApiResponse } from "./Filters/MyMachines/MachineMeasureMyMachinesSelect";
import {
  CompairedMachineCountData,
  MachineIdFilter,
  MachineMeasureComparisonData,
  MachineMeasuresComparisonFilterState,
  Measure,
  PeriodInYearType,
} from "./Types";

/**
 * Customer care user without any assigned machines having benchmark feature authorization won't see any results.
 * In case of an customer care user we pass the comparison machines explicitly in http request
 * @returns
 */
function useMachinesSelectionOverride(): number[] | null {
  const selectedMachineId = useSelectedMachine().machineId;
  const authorizationScope = useAuthorizationScope();

  return useMemo(() => {
    if (
      !authorizationScope.hasAuthorizationOnAnyAssignedMachine(
        Features.Benchmark
      ) &&
      authorizationScope.hasAuthorizationOnSystemLevel(Features.Benchmark)
    ) {
      return [Number(selectedMachineId)];
    }

    return null;
  }, [selectedMachineId, authorizationScope]);
}

export function useComparedMachineCount() {
  const cancellationToken = GetCancellationToken();
  const service = useBenchmarkService(cancellationToken);
  const {
    editingFilterState,
    comparedMachineRequestCount,
  } = useBenchmarkState();

  const machinesOverride = useMachinesSelectionOverride();

  const {
    updateComparedToMachinesCount,
    updateMinimumComparedToMachinesCount,
    pushComparedMachineRequestCount,
    popComparedMachineRequestCount,
  } = useBenchmarkActions();

  useEffect(() => {
    pushComparedMachineRequestCount();
    service
      .getComparedMachineCount({
        periodInYearType: editingFilterState.periodType,
        fromDate: editingFilterState.fromDate,
        untilDate: editingFilterState.untilDate,
        countries: editingFilterState.countries,
        machineTypes: editingFilterState.machineTypes,
        supplyChanges: editingFilterState.supplyChanges,
        productChanges: editingFilterState.productChanges,
        upgradingPercentage: editingFilterState.upgradingPercentage,
        eggTypes: editingFilterState.eggTypes,
        machines: machinesOverride,
      })
      .then((response: IApiResponse<CompairedMachineCountData>) => {
        updateComparedToMachinesCount(response.data.comparedMachinesCount);
        updateMinimumComparedToMachinesCount(
          response.data.minimumComparedMachinesCount
        );
      })
      .finally(() => {
        popComparedMachineRequestCount();
      });

    return () => {
      cancellationToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editingFilterState.periodType,
    editingFilterState.fromDate,
    editingFilterState.untilDate,
    editingFilterState.countries,
    editingFilterState.machineTypes,
    machinesOverride,
    editingFilterState.supplyChanges,
    editingFilterState.productChanges,
    editingFilterState.upgradingPercentage,
    editingFilterState.eggTypes,
  ]);

  return comparedMachineRequestCount !== 0;
}

export function useBenchmarkModuleLoading() {
  const state = useBenchmarkState();
  const awaitingComparedMachineCountResponse = useComparedMachineCount();

  const isDisabled = useMemo(() => {
    return state.editingFilterState === state.appliedFilterState;
  }, [state.editingFilterState, state.appliedFilterState]);

  const comparedMachineCountTooLow = useMemo(() => {
    if (!state.comparedToMachinesCount || !state.minimumComparedToMachinesCount)
      return true;
    return (
      state.comparedToMachinesCount <= state.minimumComparedToMachinesCount
    );
  }, [state.comparedToMachinesCount, state.minimumComparedToMachinesCount]);

  return {
    isDisabled:
      isDisabled ||
      state.awaitingRequestCount !== 0 ||
      awaitingComparedMachineCountResponse ||
      comparedMachineCountTooLow,
  };
}

export function useMachineMeasure({
  measure,
}: {
  measure: Measure;
}): {
  data: MachineMeasureComparisonData | any;
  awaitingResponse: boolean;
  myMachines: Array<MachineId>;
} {
  const service = useBenchmarkService();
  const { filter } = useMachineMeasureComparisonAppliedFilters();
  const [data, setData] = useState<MachineMeasureComparisonData | any>(null);
  const machinesOverride = useMachinesSelectionOverride();

  const dispatchAlert = useDispatchAlert();
  const {
    updateComparedToMachinesCount,
    updateBenchmarkFilterState,
    updateBenchmarkLastValidFilterState,
    updateBenchmarkMeasureDataState,
    updateMinimumComparedToMachinesCount,
    pushAwaitingRequestCount,
    popAwaitingRequestCount,
  } = useBenchmarkActions();
  const benchmarkState = useBenchmarkState();

  const dispatchMinimumComparedMachinesAlert = (
    minimumComparedMachines: number
  ) =>
    dispatchAlert({
      message: t("benchmark.filters.comparedMachinesAreBelowMinimumCount", {
        minimumCount: minimumComparedMachines,
      }),
      messageType: "info",
    });

  useEffect(() => {
    pushAwaitingRequestCount();
    service
      .getBenchmarkData({
        measure: measure,
        periodInYearType: filter.periodType,
        fromDate: filter.fromDate,
        untilDate: filter.untilDate,
        countries: filter.countries,
        machineTypes: filter.machineTypes,
        machines: machinesOverride,
        supplyChanges: filter.supplyChanges,
        productChanges: filter.productChanges,
        upgradingPercentage: filter.upgradingPercentage,
        eggTypes: filter.eggTypes,
      })
      .then((response: IApiResponse<MachineMeasureComparisonData>) => {
        setData(response.data);
        updateBenchmarkMeasureDataState({
          measure: measure,
          data: response.data,
        });
        updateComparedToMachinesCount(response.data.comparedMachinesCount);
        updateMinimumComparedToMachinesCount(
          response.data.minimumComparedMachinesCount
        );
        if (
          response.data.comparedMachinesCount <
          response.data.minimumComparedMachinesCount
        ) {
          updateBenchmarkFilterState(benchmarkState.lastValidFilterState);
          dispatchMinimumComparedMachinesAlert(
            response.data.minimumComparedMachinesCount
          );
        } else {
          updateBenchmarkLastValidFilterState(
            benchmarkState.appliedFilterState
          );
        }
      })
      .finally(() => {
        popAwaitingRequestCount();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    service,
    measure,
    filter.periodType,
    filter.fromDate,
    filter.untilDate,
    filter.countries,
    filter.machineTypes,
    machinesOverride,
    filter.supplyChanges,
    filter.productChanges,
    filter.upgradingPercentage,
    filter.eggTypes,
  ]);

  return {
    data: data,
    awaitingResponse: benchmarkState.awaitingRequestCount !== 0,
    myMachines: filter.myMachines,
  };
}

export function useMachineMeasureComparisonAppliedFilters(): {
  comparedToCount: number | null;
  minimumComparedToCount: number | null;
  filter: MachineMeasuresComparisonFilterState;
  updatePeriodInYearType: (periodInYearType: PeriodInYearType) => void;
} {
  const { updateFilterPeriodType } = useBenchmarkActions();
  const state = useBenchmarkState();

  const updatePeriodInYearType = (periodInYearType: PeriodInYearType) => {
    updateFilterPeriodType(periodInYearType);
  };

  return {
    comparedToCount: state.comparedToMachinesCount,
    minimumComparedToCount: state.minimumComparedToMachinesCount,
    filter: state.appliedFilterState,
    updatePeriodInYearType: updatePeriodInYearType,
  };
}

export function useMyMachines() {
  const service = useMachineService();
  const { updateSelectedMyMachines } = useBenchmarkActions();
  const [myMachines, setMyMachines] = useState<Array<MachineIdFilter>>([]);
  const machinesOverride = useMachinesSelectionOverride();

  useEffect(() => {
    service
      .getMyMachines()
      .then((response: IApiResponse<MyMachinesApiResponse>) => {
        let responseData = response.data.results.map((x) =>
          x.machineId.toString()
        );
        if (responseData.length === 0 && machinesOverride !== null) {
          responseData = machinesOverride.map((m) => m.toString());
        }
        responseData.push("AVG");
        updateSelectedMyMachines(responseData);

        setMyMachines(responseData);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, machinesOverride]);

  return {
    myMachines: myMachines,
  };
}
