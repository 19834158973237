import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  ITelemetryItem,
  PageView,
  RemoteDependencyData,
} from "@microsoft/applicationinsights-web";
import { useCallback, useEffect, useMemo, useRef } from "react";
import config from "../config";
import { UserResult } from "../globalHooks/authorizationHooks";
import { useMyUserState } from "../store/GlobalContext";

export function useApplicationInsights() {
  const reactPlugin = useMemo(() => new ReactPlugin(), []);
  const myUserStateRef = useRef<UserResult>();
  myUserStateRef.current = useMyUserState();

  let appInsightsInstance = useMemo(() => {
    return new ApplicationInsights({
      config: {
        connectionString:
          config.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
      },
    });
  }, [reactPlugin]);

  let initializeAppInsights = useCallback(() => {
    appInsightsInstance.loadAppInsights();
    const telemetryInitializer = (envelop: ITelemetryItem) => {
      if (envelop?.name !== RemoteDependencyData.envelopeType) {
        // Set role name
        envelop.tags = envelop.tags ?? [];
        envelop.tags.push({ "ai.cloud.role": "CustomerAppWeb" });

        // Set custom property machine id
        const machineId = localStorage.getItem("selectedSortingMachineId");
        envelop.data = envelop.data ?? {};
        envelop.data.MachineId = machineId;
        envelop.data.UserRole =
          myUserStateRef.current?.userSystemWideAuthorization?.systemRoles;

        // Replace title with uri
        if (
          envelop.baseData !== undefined &&
          envelop.name === PageView.envelopeType
        ) {
          // this statement replaces title with uri
          const hashSymbol = "#/";
          const hashIndex = envelop.baseData.uri.indexOf(hashSymbol);

          // We don't need to track route-less entries.
          if (hashIndex > -1) {
            envelop.baseData.name = envelop.baseData.uri.substring(
              hashIndex + hashSymbol.length
            );
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    };
    appInsightsInstance.addTelemetryInitializer(telemetryInitializer);
  }, [appInsightsInstance, myUserStateRef]);

  useEffect(() => {
    initializeAppInsights();
  }, [initializeAppInsights]);

  return reactPlugin;
}
