import { useCallback, useEffect, useState } from "react";
import {
  useManagementSettingsService,
  useReportService,
} from "../../../api/ServiceContext";
import { MachineUnitSettings } from "../../../pages/management-settings/Common";
import { EggUnit } from "../../../utility/EggUnitUtility";
import useRefresh from "../../Dashboard/useRefresh";
import {
  byPackingLaneGradeDescending,
  LaneProductsView,
} from "../LaneProducts/LaneProductsTypes";

export default function useLaneProducts({
  machineId,
  automaticRefresh,
}: {
  machineId: MachineId;
  automaticRefresh: boolean;
}): {
  awaitingResponse: boolean;
  data: LaneProductsView | any;
  units: EggUnit;
  setUnits: (input: EggUnit) => void;
} {
  const reportService = useReportService();
  const settingsService = useManagementSettingsService();

  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [data, setData] = useState<LaneProductsView | any>(null);
  const [units, setUnits] = useState<EggUnit>(EggUnit.Eggs);

  const fetchData = useCallback(
    (newMachineId: MachineId) => {
      settingsService
        .getUnitsFromMachineTargetsSettings(machineId, new Date())
        .then((response: IApiResponse<MachineUnitSettings>) => {
          setUnits(response.data.eggUnit);
        })
        .then(() => {
          reportService
            .getLaneProducts(newMachineId)
            .then((response: IApiResponse<LaneProductsView>) => {
              setAwaitingResponse(false);
              if (response.data.packingLanes.length !== 0) {
                response.data.packingLanes.forEach((lane) => {
                  lane.packingLaneGrades.sort(byPackingLaneGradeDescending());
                });
                setData(response.data);
              }
            });
        });
    },
    [reportService, settingsService]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return { awaitingResponse, data, units, setUnits };
}
