import { Redirect, Route, Switch } from "react-router-dom";
import GlobalAnnouncement from "../components/UI/GlobalAnnouncement";
import { Features } from "../globalHooks/authorizationHooks";
import { ProtectedRoute } from "../utility/ProtectedRoute";
import AlarmsPage from "./alarms/AlarmsPage";
import BenchmarkPage from "./benchmark/BenchmarkPage";
import DashboardPage from "./dashboard/DashboardPage";
import AboutPage from "./general/AboutPage";
import GlossaryPage from "./general/GlossaryPage";
import PrivacyPolicyPage from "./general/PrivacyPolicyPage";
import TermsAndConditionsPage from "./general/TermsAndConditionsPage";
import LogoutPage from "./logout/LogoutPage";
import ManagementMachinePage from "./management-machine/ManagementMachinePage";
import ManagementSettingsPage from "./management-settings/ManagementSettingsPage";
import NotificationsPage from "./notifications/NotificationsPage";
import OwnerPage from "./owner/OwnerPage";
import PerformanceProPage from "./performance-pro/PerformanceProPage";
import SettingsPage from "./settings/SettingsPage";
import { UserManagementPage } from "./users/UserManagementPage";

const RootPage = () => (
  <>
    <Switch>
      <Route path="/settings" component={SettingsPage} />
      <ProtectedRoute
        path="/dashboard"
        component={DashboardPage}
        features={[Features.RealtimeDashboard]}
        needsMachine={true}
      />
      <ProtectedRoute
        path="/performance-pro"
        component={PerformanceProPage}
        needsMachine={true}
      />
      <ProtectedRoute
        path="/benchmark"
        component={BenchmarkPage}
        features={[Features.Benchmark]}
        needsMachine={true}
      />
      <ProtectedRoute
        path="/management/machine/:machineId"
        component={ManagementMachinePage}
        features={[Features.ManageMachine, Features.CustomerCareManageMachines]}
        needsMachine={true}
      />
      <ProtectedRoute
        path="/management/alarms"
        component={AlarmsPage}
        features={[Features.ManageAlarms]}
        needsMachine={true}
      />
      <ProtectedRoute
        path="/management/settings"
        component={ManagementSettingsPage}
        features={[Features.ManageSettings]}
        needsMachine={false}
      />
      <Route path="/management/notifications" component={NotificationsPage} />
      <Route path="/glossary" component={GlossaryPage} />
      <Route path="/terms" component={TermsAndConditionsPage} />
      <Route path="/privacy" component={PrivacyPolicyPage} />
      <Route path="/about" component={AboutPage} />
      <Route path="/logout" component={LogoutPage} />
      <ProtectedRoute
        path="/users/manage/:machineId"
        component={UserManagementPage}
        features={[Features.ManageUsers, Features.CustomerCareManageUsers]}
        needsMachine={false}
      />
      <ProtectedRoute
        path="/owner/:machineId"
        component={OwnerPage}
        features={[Features.Owner, Features.CustomerCareManageMachines]}
        needsMachine={true}
      />
      <Redirect from="/" to="/performance-pro" />
    </Switch>
    <GlobalAnnouncement />
  </>
);

export default RootPage;
