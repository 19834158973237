import { laneColors } from "../../../colors";
import { onlyUnique } from "../../../utility/ArrayUtility";
import { EggUnit } from "../../../utility/EggUnitUtility";
import { t } from "../../../utility/TranslateUtility";
import LaneProductsChart from "../LaneProducts/LaneProductsChart";
import {
  laneNumberToType,
  LaneProductsView,
  LaneSeriesData,
  MapProductsResponse,
  PackingLane,
} from "../LaneProducts/LaneProductsTypes";

function mapProductsToWeightDistribution(
  productLanes: PackingLane[],
  unit: EggUnit
): MapProductsResponse {
  const productIds = productLanes.map((p) => p.productId).filter(onlyUnique);
  const productColors = productIds.reduce<any>((pc, id, index) => {
    pc[id] = laneColors.production[index];
    return pc;
  }, {});

  return {
    name: "Production",
    showInLegend: false,
    data: productLanes.map<LaneSeriesData>((l) => ({
      x: l.packingLaneNumber,
      y: l.laneTotal - l.laneOffgrades,
      color: productColors[l.productId],
      custom: {
        lane: l,
        laneType: laneNumberToType(l.packingLaneNumber),
        units: unit,
      },
    })),
  };
}

export function LaneProductsProgress({
  data,
  isAwaitingResponse,
  units,
  onUnitsChange,
  isRemainderEnabled,
}: {
  data: LaneProductsView;
  isAwaitingResponse: boolean;
  units: EggUnit;
  onUnitsChange: (units: EggUnit) => void;
  isRemainderEnabled: boolean;
}) {
  return (
    <LaneProductsChart
      cardTitle={t("widgetList.laneProductProgress.title")}
      isRemainderEnabled={isRemainderEnabled}
      data={data}
      isAwaitingResponse={isAwaitingResponse}
      units={units}
      onUnitsChange={onUnitsChange}
      mapProducts={mapProductsToWeightDistribution}
    />
  );
}
