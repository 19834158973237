import React, { useEffect, useState, useCallback } from "react";
import { useReportService } from "../../../api/ServiceContext";
import useRefresh from "../../Dashboard/useRefresh";
import MachineStatesWidgetPresentation, {
  MachineStatesApiData,
  MachineStatesApiResponse,
} from "./MachineStates";
import { isEmptyArray } from "../../../utility/ArrayUtility";

export type MachineStatesWidgetProps = {
  machineId: number;
  automaticRefresh: boolean;
};

/**
 * Shows the states of the machine for the last 30 minutes.
 */
export default function MachineStatesWidgetContainer({
  machineId,
  automaticRefresh,
}: MachineStatesWidgetProps) {
  const reportService = useReportService();
  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [data, setData] = useState<MachineStatesApiData | any>(null);

  const fetchData = useCallback(
    (newMachineId: number) => {
      reportService
        .getMachineStates(newMachineId)
        .then((response: MachineStatesApiResponse) => {
          setAwaitingResponse(false);

          if (isEmptyArray(response.data.machineStatePeriods)) {
            return;
          }

          setData(response.data);
        });
    },
    [reportService]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return (
    <MachineStatesWidgetPresentation
      awaitingResponse={awaitingResponse}
      data={data}
    />
  );
}
