import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { SaveButton } from "../../../components/Settings/Common";
import { useReportSettingsManagement } from "../../../components/Settings/ReportSettings/hooks";
import { ReportSettingsPresentation } from "../../../components/Settings/ReportSettings/ReportSettingsPresentation";
import { useDispatchAlert } from "../../../components/UI/Alert";
import { t } from "../../../utility/TranslateUtility";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3),
  },
}));

export default function ReportSettingsPageContainer() {
  const classes = useStyles();
  const reportSettings = useReportSettingsManagement();
  const [isSaving, setIsSaving] = useState(false);
  const dispatchAlert = useDispatchAlert();

  function handleOnSaving() {
    setIsSaving(true);
    Promise.all([reportSettings.saveReportSetting()])
      .then(() => {
        dispatchAlert({
          message: t("managementSettings.settingsSaveSuccessMessage"),
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: t("managementSettings.settingsSaveFailedMessage"),
          messageType: "error",
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  const savingEnabled =
    reportSettings.isDirty &&
    !isSaving &&
    !reportSettings.hasTimeSlotError &&
    !reportSettings.hasTargetError &&
    !reportSettings.hasDuplicateColorSchemaError;

  return (
    <div className={classes.wrapper}>
      <SaveButton disabled={!savingEnabled} onClick={handleOnSaving} />
      <Prompt
        when={reportSettings.isDirty}
        message={t("managementSettings.pendingChanges")}
      />
      <ReportSettingsPresentation
        settings={reportSettings.reportSettings}
        onSettingsChange={(s) => reportSettings.setReportSetting(s)}
        showTimeSlotError={reportSettings.hasTimeSlotError}
        showTargetError={reportSettings.hasTargetError}
        showDuplicateColorSchemaError={reportSettings.hasDuplicateColorSchemaError}
      />
    </div>
  );
}
