import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import deTranslations from "./locales/de-de/translation.json";
import enTranslations from "./locales/en-us/translation.json";
import spTranslations from "./locales/es-es/translation.json";
import frTranslations from "./locales/fr-fr/translation.json";
import itTranslations from "./locales/it-it/translation.json";
import jaTranslations from "./locales/ja/translation.json";
import nlTranslations from "./locales/nl-nl/translation.json";
import ptTranslations from "./locales/pt-pt/translation.json";
import ruTranslations from "./locales/ru/translation.json";
import cnTranslations from "./locales/zh-cn/translation.json";

import { format as formatDate, isDate } from "date-fns";
import { de, enUS, es, fr, it, ja, nl, pt, ru, zhCN } from "date-fns/locale";
const locales = {
  "en-US": enUS,
  "nl-NL": nl,
  "zh-CN": zhCN,
  "de-DE": de,
  "fr-FR": fr,
  "it-IT": it,
  ja: ja,
  "pt-PT": pt,
  ru: ru,
  "es-ES": es,
};

export const LocalStorageLanguageKey = "language";

export const DefaultLanguage = "en-US";

function getLangauge() {
  let storedLanguage = window.localStorage.getItem(LocalStorageLanguageKey);
  return storedLanguage ? storedLanguage : DefaultLanguage;
}

i18n.use(initReactI18next).init({
  lng: getLangauge(),
  fallbackLng: ["en-US", "dev"],
  debug: false,
  resources: {
    "en-US": enTranslations,
    "nl-NL": nlTranslations,
    "zh-CN": cnTranslations,
    "de-DE": deTranslations,
    "fr-FR": frTranslations,
    "it-IT": itTranslations,
    ja: jaTranslations,
    "pt-PT": ptTranslations,
    ru: ruTranslations,
    "es-ES": spTranslations,
  },
  interpolation: {
    escapeValue: false, // React is already safe from XSS
    format: (value, format, lng) => {
      if (isDate(value) && lng && format) {
        const locale = locales[lng];
        return formatDate(value, format, { locale });
      }
      return value;
    },
  },
});
export default i18n;
