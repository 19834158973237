import { useEffect } from "react";

export const LOCAL_STORAGE_VERSION_KEY = "LOCAL_STORAGE_VERSION";
export const LOCAL_STORAGE_VERSION_VALUE = "2022-03-31";

function shouldRefreshLocalStorage(storedLocalStorageVersion: string | null) {
  return (
    storedLocalStorageVersion === null ||
    storedLocalStorageVersion === undefined ||
    storedLocalStorageVersion !== LOCAL_STORAGE_VERSION_VALUE
  );
}

export function useLocalStorage() {
  useEffect(() => {
    const storedLocalStorageVersion = window.localStorage.getItem(
      LOCAL_STORAGE_VERSION_KEY
    );

    if (shouldRefreshLocalStorage(storedLocalStorageVersion)) {
      window.localStorage.clear();
      window.localStorage.setItem(
        LOCAL_STORAGE_VERSION_KEY,
        LOCAL_STORAGE_VERSION_VALUE
      );
    }
  }, []);
}
