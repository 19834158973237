import { useSelectedMachine } from "../../../store/GlobalContext";
import { usePerformanceProMeasure } from "../performanceProHooks";
import { KpiMeasureType } from "../Types";
import { useReportData } from "./Hooks";
import { ReportPresentation } from "./ReportPresentation";

export function ReportContainer() {
  const report = useReportData();
  const machineId = useSelectedMachine().machineId;
  const { targets } = usePerformanceProMeasure({
    machineId: machineId,
    measure: KpiMeasureType.TotalEggs,
    allPeriods: true,
  });

  return (
    <ReportPresentation
      reportView={report.data?.data}
      isLoading={report.isLoading || report.isFetching}
      targets={targets}
    />
  );
}
