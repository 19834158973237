import { GlobalAnnouncementModel } from "../../apiModels/GlobalAnnouncement/GlobalAnnouncementModel";
import { IHttpClient } from "../IHttpClient";
import IGlobalAnnouncementService from "./IGlobalAnnouncementService";

export class GlobalAnnouncementService implements IGlobalAnnouncementService {
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }

  private get(url: string): Promise<any> {
    return this.httpclient.get(url);
  }

  getGlobalAnnouncement(): Promise<GlobalAnnouncementModel> {
    return new Promise((resolve, reject) => {
      this.get('v1/announcement')
        .then((response: { data: GlobalAnnouncementModel; }) => {
          resolve(response.data);
        })
        .catch((err: any) => {
          reject(err);
        })
    })
  }
}