import { EggType } from "../../utility/EggUtility";

export type MachinePerformanceResult = {
  valueType: ValueType;
  periodType: KpiMeasurePeriodType;
  performancePeriods: Array<PerformancePeriod>;
  performancePeriodSummations: Array<PerformancePeriodSummation>;
};

export enum KpiMeasureType {
  Undefined = 0,
  TotalEggs,
  ProductionAvailability,
  ProductionTime,
  Throughput,
  FillRate,
  TableEggs,
  InputOffgrades,
  Upgrades,
  Outputoffgrades,
  Bypass,
  BucketEggs,
  SetSpeed,
  PauseTime,
  MachineStop,
}

export enum KpiMeasureGroupType {
  Undefined = 0,

  InfeedGradeBySupply,
  InfeedGradeByDatePeriod,

  TakeawayGradeBySupply,
  TakeawayGradeByDatePeriod,

  InfeedOffgradeBySupply,
  InfeedOffgradeByDatePeriod,

  TakeawayOffgradeBySupply,
  TakeawayOffgradeByDatePeriod,

  ColorBySupply,
  ColorByDatePeriod,

  ShellStrengthBySupply,
  ShellStrengthByDatePeriod,

  TotalBySupply,
  TotalByDatePeriod,
  TotalByDatePeriodForSupplier,

  TotalWeightBySupply,
  TotalWeightByDatePeriod,
  TotalWeightByDatePeriodForSupplier,

  InfeedOffgradeByDatePeriodForSupplier,
  TakeawayOffgradeByDatePeriodForSupplier,

  InfeedGradeByDatePeriodForSupplier,
  TakeawayGradeByDatePeriodForSupplier,

  ColorByDatePeriodForSupplier,
  ShellStrengthByDatePeriodForSupplier,
}

export enum InfeedOrTakeawayBases {
  None,
  Infeed,
  Takeaway,
}

export type ValueType =
  | "Percentage"
  | "Time"
  | "Count"
  | "ValueOverTime"
  | "Average";

// Day is currently unsupported
export type KpiMeasurePeriodType =
  | "Week"
  | "Sparkline"
  | "Month"
  | "Year"
  | "Day";

export type PerformanceGroupItem = {
  x: Date | string;
  group: string;
  value: number;
  eggType: EggType;
};

export type PerformancePeriod = {
  dateTime: Date;
  eggType: EggType;
  value: number;
  numerator?: number;
  denominator?: number;
};

export type PerformancePeriodSummation = {
  eggType: EggType;
  value: number;
  numerator?: number;
  denominator?: number;
};

export interface ProductionTimeWindow {
  productionStartTime: Date;
  productionEndTime: Date;
}

export const AllKpiMeasuresBySupply: Array<KpiMeasureGroupType> = [
  KpiMeasureGroupType.InfeedGradeBySupply,
  KpiMeasureGroupType.TakeawayGradeBySupply,
  KpiMeasureGroupType.InfeedOffgradeBySupply,
  KpiMeasureGroupType.TakeawayOffgradeBySupply,
  KpiMeasureGroupType.ColorBySupply,
  KpiMeasureGroupType.ShellStrengthBySupply,
  KpiMeasureGroupType.TotalBySupply,
  KpiMeasureGroupType.TotalWeightBySupply,
];

export const AllKpiMeasuresOffgrade: Array<KpiMeasureGroupType> = [
  KpiMeasureGroupType.InfeedOffgradeBySupply,
  KpiMeasureGroupType.TakeawayOffgradeBySupply,
  KpiMeasureGroupType.InfeedOffgradeByDatePeriod,
  KpiMeasureGroupType.TakeawayOffgradeByDatePeriod,
  KpiMeasureGroupType.InfeedOffgradeByDatePeriodForSupplier,
  KpiMeasureGroupType.TakeawayOffgradeByDatePeriodForSupplier,
];

export const AllKpiMeasuresByDate: Array<KpiMeasureGroupType> = [
  KpiMeasureGroupType.InfeedGradeByDatePeriod,
  KpiMeasureGroupType.TakeawayGradeByDatePeriod,
  KpiMeasureGroupType.InfeedOffgradeByDatePeriod,
  KpiMeasureGroupType.TakeawayOffgradeByDatePeriod,
  KpiMeasureGroupType.ColorByDatePeriod,
  KpiMeasureGroupType.ShellStrengthByDatePeriod,
  KpiMeasureGroupType.TotalByDatePeriod,
  KpiMeasureGroupType.TotalWeightByDatePeriod,
];

export const AllKpiMeasuresByDatePeriodForSupplier: Array<KpiMeasureGroupType> =
  [
    KpiMeasureGroupType.ColorByDatePeriodForSupplier,
    KpiMeasureGroupType.TotalByDatePeriodForSupplier,
    KpiMeasureGroupType.InfeedGradeByDatePeriodForSupplier,
    KpiMeasureGroupType.TotalWeightByDatePeriodForSupplier,
    KpiMeasureGroupType.ShellStrengthByDatePeriodForSupplier,
    KpiMeasureGroupType.TakeawayGradeByDatePeriodForSupplier,
    KpiMeasureGroupType.InfeedOffgradeByDatePeriodForSupplier,
    KpiMeasureGroupType.TakeawayOffgradeByDatePeriodForSupplier,
  ];
