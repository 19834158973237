import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useMemo } from "react";
import { IPerformanceProFilterOptions } from "../../../store/GlobalContext";
import { DatePeriod } from "../../../utility/DateUtility";
import { EggUnit } from "../../../utility/EggUnitUtility";
import { FormatWithTwoDecimals } from "../../../utility/NumberFormatUtility";
import { t } from "../../../utility/TranslateUtility";
import { ChartCard } from "../../ProductionViews/Common";
import { format, TooltipBuilder } from "../../ProductionViews/Tooltip";
import { StackedBarChartMapping } from "../Grouped/GroupsChartMapping";
import { KpiMeasureGroupType, PerformanceGroupItem } from "../Types";

export function SupplierDetailLineChart({
  options,
  data,
  totalEggData,
  chartMapping,
  eggUnit,
  measure,
  awaitingResponse,
}: {
  options: IPerformanceProFilterOptions;
  data: PerformanceGroupItem[];
  totalEggData: PerformanceGroupItem[];
  chartMapping: StackedBarChartMapping;
  eggUnit: EggUnit;
  measure: KpiMeasureGroupType;
  awaitingResponse: boolean;
}) {
  const newOptions = useMemo(() => {
    return {
      ...LineChartBaseOptions,
      series: createSeries(data, totalEggData, measure),
      tooltip: {
        ...createTooltip(options.selectedDatePeriod),
        shared: true,
      },
      plotOptions: {
        series: {
          connectNulls: true,
        },
      },
      yAxis: [
        {
          index: 0,
          title: {
            text:
              measure === KpiMeasureGroupType.ColorByDatePeriodForSupplier
                ? t("uniqueViews.lineChartPerSupplier.yAxisLabelColor")
                : t("uniqueViews.lineChartPerSupplier.yAxisLabelShellStrength"),
          },
        },
        {
          index: 1,
          opposite: true,
          title: {
            text: t("uniqueViews.lineChartPerSupplier.StandardDeviation"),
          },
        },
      ],
    };
  }, [data, totalEggData, options, eggUnit, measure]);

  return (
    <ChartCard
      isLoading={awaitingResponse}
      headerTitle={
        chartMapping.supplierNameShed
          ? chartMapping.supplierNameShed + " " + t(chartMapping.headerTitle)
          : t(chartMapping.headerTitle)
      }
    >
      <HighchartsReact highcharts={Highcharts} options={newOptions} />
    </ChartCard>
  );
}

function createSeries(
  data: PerformanceGroupItem[],
  totalEggData: PerformanceGroupItem[],
  measure: KpiMeasureGroupType
) {
  const averageSeries: { name: string; color: string; data: any[] } = {
    name:
      measure === KpiMeasureGroupType.ColorByDatePeriodForSupplier
        ? t("uniqueViews.lineChartPerSupplier.ColorLineName")
        : t("uniqueViews.lineChartPerSupplier.ShellStrengthLineName"),
    color: LineColors.value,
    data: [],
  };

  const standardDeviationSeries: {
    name: string;
    color: string;
    data: any[];
  } = {
    name: t("uniqueViews.lineChartPerSupplier.StandardDeviation"),
    color: LineColors.standardDeviation,
    data: [],
  };

  for (let eggTotalForPeriod of totalEggData) {
    const valueDataForPeriod = data.filter(
      (x) => (x.x as Date).getTime() === (eggTotalForPeriod.x as Date).getTime()
    );

    const valueTotalForPeriod = valueDataForPeriod.reduce(
      (a, b) => a + b.value * Number(b.group.split(" ")[1]),
      0
    );

    const averageValue = valueTotalForPeriod / eggTotalForPeriod.value;

    averageSeries.data.push([
      (eggTotalForPeriod.x as Date).getTime(),
      averageValue,
    ]);

    standardDeviationSeries.data.push([
      (eggTotalForPeriod.x as Date).getTime(),
      getStandardDeviation(valueDataForPeriod),
    ]);
  }

  return [standardDeviationSeries, averageSeries];
}

function getStandardDeviation(data: PerformanceGroupItem[]) {
  let mean = 0;
  let oldMean = 0;
  let variance = 0;
  let i = 1;

  let length = 0;

  for (let item of data) {
    for (let j = 0; j < item.value; j++) {
      const value = Number(item.group.split(" ")[1]);

      oldMean = mean;
      mean += (value - mean) / i;

      variance += (value - mean) * (value - oldMean);
      i++;
      length++;
    }
  }

  variance = variance / length;

  return Math.sqrt(variance);
}

function createTooltip(selectedDatePeriod: DatePeriod) {
  return new TooltipBuilder()
    .addXProductionSubCharts(selectedDatePeriod)
    .add((data: { points: any[] }) => {
      let parts = "";

      for (let point of data.points) {
        parts += format(
          {
            label: `<span style="color:${point.color}">\u25CF</span> ${point.series.name}`,
            separator: true,
          },
          FormatWithTwoDecimals(point.y)
        );
      }

      return parts;
    })
    .buildSharedFormatter();
}

const LineChartBaseOptions = {
  chart: {
    spacingLeft: 0,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {},
  title: false,
  subtitle: false,
  xAxis: {
    type: "datetime",
    isX: true,
    crosshair: false,
    index: 0,
  },
};

const LineColors = {
  standardDeviation: "#4286f4",
  value: "#f44141",
};
