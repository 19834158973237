import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { colors } from "../../../colors";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { EggType } from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import FullscreenCardWrapper, {
  FullscreenVariant
} from "../Wrappers/FullscreenCardWrapper";

export type EggTypeWidgetPresentationProps = {
  awaitingResponse: boolean;
  eggType: EggType;
  retrievingForSupply: boolean;
  currentSupplyId: Nullable<number>;
};

export type EggTypeApiResponse = {
  data: EggTypeApiData;
};

export type EggTypeApiData = {
  eggType: EggType;
  supplyId: Nullable<number>;
};

function getEggTypeColor(eggType: EggType) {
  switch (eggType) {
    case EggType.Cage:
      return colors.cageEgg;
    case EggType.Barn:
      return colors.barnEgg;
    case EggType.FreeRange:
      return colors.freeRangeEgg;
    case EggType.Organic:
      return colors.organicEgg;
    case EggType.Unmapped:
      return colors.unmappedEgg;
    case EggType.Unknown:
      return colors.mobaBlue;
    default:
      return colors.unmappedEgg;
  }
}

const determineEggTypeLabel = (eggType: EggType) => {
  switch (eggType) {
    case EggType.Cage:
      return t("widgets.eggType.cageEgg");
    case EggType.Barn:
      return t("widgets.eggType.barnEgg");
    case EggType.FreeRange:
      return t("widgets.eggType.freeRangeEgg");
    case EggType.Organic:
      return t("widgets.eggType.organicEgg");
    case EggType.Unmapped:
      return t("widgets.eggType.unmappedEgg");
    case EggType.Unknown:
      return t("widgets.eggType.unknown");
    default:
      return t("widgets.eggType.unknown");
  }
};

export default function EggTypeWidgetPresentation({
  awaitingResponse,
  eggType,
  retrievingForSupply,
  currentSupplyId,
}: EggTypeWidgetPresentationProps) {
  const classes = useStyles();

  const isMobileSize = useIsMobileSize();
  const headingVariant = isMobileSize ? "h5" : "h4";

  return (
    <FullscreenCardWrapper
      title={t("widgets.eggType.title")}
      awaitingResponse={awaitingResponse}
      fullscreenVariant={FullscreenVariant.Kpi}
    >
      {retrievingForSupply === true ? (
        <RetrievingSupplyMessage supplyId={currentSupplyId} />
      ) : currentSupplyId === null ? (
        <NoLastEggRecord />
      ) :
        (
          <Typography
            variant={headingVariant}
            className={classes.eggTypeLabel}
            id="eggTypeLabel"
            style={{ backgroundColor: getEggTypeColor(eggType) }}
          >
            {determineEggTypeLabel(eggType)}
          </Typography>
        )}
    </FullscreenCardWrapper>
  );
}

interface RetrievingSupplyMessageProps {
  supplyId: Nullable<number>;
}

function RetrievingSupplyMessage({ supplyId }: RetrievingSupplyMessageProps) {
  const classes = useStyles();
  const isMobileSize = useIsMobileSize();
  const headingVariant = isMobileSize ? "h5" : "h4";

  return (
    <>
      <Typography
        variant={headingVariant}
        id="eggTypeLoadingMessage"
        className={classes.loadingMessage}
      >
        {t("widgets.eggType.loadingMessage", {
          supplyId: supplyId,
        })}
      </Typography>
      <LinearProgress />
    </>
  );
}

function NoLastEggRecord() {
  const classes = useStyles();
  const isMobileSize = useIsMobileSize();
  const headingVariant = isMobileSize ? "h5" : "h4";

  return (
    <>
      <Typography
        variant={headingVariant}
        className={classes.eggTypeLabel}
        id="eggTypeLabel"
        style={{ backgroundColor: colors.mobaBlue }}
      >
        {t("widgets.eggType.noLastEggRecordMessage")}
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingTop: 0,
  },
  eggTypeLabel: {
    borderRadius: 5,
    padding: 5,
    clear: "both",
    float: "left",
    marginBottom: 20,
    color: "#ffffff",
  },
  cardHeader: {
    paddingBottom: 0,
  },
  loadingMessage: {
    marginBottom: theme.spacing(1),
  },
  card: {
    height: "100%",
    width: "100%",
    position: "fixed",
  },
}));
