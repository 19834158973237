import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

export type SkeletonCardWrapperProps = {
  variant: SkeletonVariant;
};

export enum SkeletonVariant {
  Kpi,
  Graph,
  Sparkline,
  BenchmarkGraph,
}

/**
 * A skeleton for a widget used to indicate that the widget is loading.
 * Used inside components as a replacement for a spinner icon.
 */
function SkeletonCardWrapper({ variant }: SkeletonCardWrapperProps) {
  const renderSkeleton = () => {
    if (variant === SkeletonVariant.Graph) {
      return (
        <Card>
          <CardContent>
            <Skeleton variant="rect" height={250} animation="wave" />
          </CardContent>
        </Card>
      );
    } else if (variant === SkeletonVariant.BenchmarkGraph) {
      return (
        <Card>
          <CardContent>
            <Skeleton variant="text" width={150} animation="wave" />
            <Skeleton variant="rect" height={430} animation="wave" />
          </CardContent>
        </Card>
      );
    } else if (variant === SkeletonVariant.Sparkline) {
      return (
        <Card>
          <CardContent>
            <Skeleton variant="text" width={150} animation="wave" />
            <Skeleton variant="rect" height={145} animation="wave" />
          </CardContent>
        </Card>
      );
    }

    return (
      <Card>
        <CardContent>
          <Skeleton variant="text" width={150} animation="wave" />
          <Skeleton variant="rect" height={50} animation="wave" />
        </CardContent>
      </Card>
    );
  };

  return <>{renderSkeleton()}</>;
}

export default SkeletonCardWrapper;
