import { useEffect, useMemo, useState } from "react";
import { useManagementSettingsService } from "../../../api/ServiceContext";
import {
  OffgradeLabel,
  OffgradeLabelApiData,
} from "../../../pages/management-settings/Common";
import { usePerformanceProFilterOptions, useSelectedMachine } from "../../../store/GlobalContext";
import { InternalOffgradeLabelItem } from "./OffgradeLabelsPagePartPresentation";

export function useOffgradeLabelMapping(): {
  isDirty: boolean;
  internalRows: InternalOffgradeLabelItem[];
  changeOffgradeLabel: (
    editedRow: InternalOffgradeLabelItem,
    offgradeLabelValue: string
  ) => void;
  saveRows: () => Promise<any>;
} {
  const service = useManagementSettingsService();
  const machineId = useSelectedMachine().machineId;
  const options = usePerformanceProFilterOptions();
  
  const [rows, setRows] = useState<OffgradeLabel[]>([]);
  const [savedRows, setSavedRows] = useState<OffgradeLabel[]>([]);

  useEffect(() => {
    service
      .getOffgradeLabelsSettings(machineId, options.selectedDate)
      .then((response: IApiResponse<OffgradeLabelApiData>) => {
        setSavedRows(response.data.offgradeLabels.map((r) => ({ ...r })));
        setRows(response.data.offgradeLabels.map((r) => ({ ...r })));
      });
  }, [service, machineId]);

  const saveRows = () => {
    return service
      .updateOffgradeLabelsSettings(machineId, {
        offgradeLabels: rows,
      })
      .then(() => {
        setSavedRows(rows);
      });
  };

  const changeOffgradeLabel = (
    editedRow: InternalOffgradeLabelItem,
    offgradeLabelValue: string
  ) => {
    setRows((prevRows) =>
      prevRows.map((r) =>
        r.mmiLabel === editedRow.mmiLabel
          ? { ...r, customLabel: offgradeLabelValue }
          : r
      )
    );
  };

  const internalRows = useMemo<InternalOffgradeLabelItem[]>(() => {
    return rows.map<InternalOffgradeLabelItem>((r) => ({
      ...r,
      isDirty:
        savedRows.find((sr) => sr.mmiLabel === r.mmiLabel)?.customLabel !==
        r.customLabel,
    }));
  }, [rows, savedRows]);

  const isDirty = useMemo(() => internalRows.some((x) => x.isDirty), [
    internalRows,
  ]);

  return {
    isDirty: isDirty,
    internalRows: internalRows,
    changeOffgradeLabel: changeOffgradeLabel,
    saveRows: saveRows,
  };
}
