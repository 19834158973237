import React from "react";
import { IPerformanceProFilterOptions } from "../../store/GlobalContext";
import { t } from "../../utility/TranslateUtility";
import EggProductionChart from "../Charts/EggProductionChart";
import { ChartCard } from "../ProductionViews/Common";
import { TargetsView } from "../Settings/Targets/Types";
import { usePerformanceProMeasure } from "./performanceProHooks";
import { KpiMeasureType, PerformancePeriod } from "./Types";

export default function EggProductionViewContainer({
  machineId,
}: {
  machineId: MachineId;
}) {
  const {
    options,
    periods,
    setSpeed,
    targets,
    awaitingResponse,
  } = usePerformanceProMeasure({
    machineId: machineId,
    measure: KpiMeasureType.TotalEggs,
    allPeriods: true,
    callSetSpeed: true,
  });

  return (
    <EggProductionView
      options={options}
      periods={periods}
      targets={targets}
      setSpeed={setSpeed}
      awaitingResponse={awaitingResponse}
    />
  );
}

export function EggProductionView({
  options,
  periods,
  targets,
  setSpeed,
  awaitingResponse,
}: {
  options: IPerformanceProFilterOptions;
  periods: Array<PerformancePeriod>;
  targets: TargetsView | null;
  setSpeed: Array<PerformancePeriod> | undefined;
  awaitingResponse: boolean;
}) {
  return (
    <ChartCard
      headerTitle={t("uniqueViews.eggProduction.cardHeader")}
      isLoading={awaitingResponse}
    >
      <EggProductionChart
        data={periods}
        yAxisKey={"value"}
        options={options}
        setSpeed={setSpeed}
        targets={targets}
      />
    </ChartCard>
  );
}
