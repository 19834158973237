import {
  Button,
  Dialog,
  Grid,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import React, { useCallback, useEffect, useState } from "react";
import { useMachineService, useOwnerService } from "../../api/ServiceContext";
import {
  EventLog,
  EventLogsApiResponse
} from "../../components/EventLogs/Common";
import { EventLogsContainer } from "../../components/EventLogs/EventLogsContainer";
import SubscriptionCardsContainer from "../../components/ManagementMachine/SubscriptionCards/SubscriptionCardsContainer";
import { MachineInformationCardContainer } from "../../components/Owner/MachineInformationCard";
import MachineSelectionBar from "../../components/Owner/MachineSelectionBar";
import { useDispatchAlert } from "../../components/UI/Alert";
import { ConfirmActionDialog } from "../../components/Users/ConfirmActionDialog";
import { useMyUserState, useSelectedMachine } from "../../store/GlobalContext";
import { t } from "../../utility/TranslateUtility";
import TermsAndConditionsPage from "../general/TermsAndConditionsPage";
import { PageWithMachineId } from "../PageWithMachineId";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    marginTop: "1rem",
    marginBottom: theme.spacing(1),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  mt2: {
    marginTop: "1rem",
  },
  tabsContent: {
    padding: theme.spacing(3),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    height: "100%",
    width: "100%",
  },
  centeredSpinner: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
}));

export default function ManagementMachinePage() {
  return (
    <PageWithMachineId page={"machine"}>
      <ManagementMachinePageInner />
    </PageWithMachineId>
  );
}

function ManagementMachinePageInner() {
  const [machines, setMachines] = useState<OwnerMachine[]>([]);
  const [eventLogs, setEventLogs] = useState<EventLog[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ownedMachine, setOwnedMachine] = useState<Nullable<OwnerMachine>>(
    null
  );
  const user = useMyUserState();
  const classes = useStyles();

  const ownerService = useOwnerService();
  const dispatchAlert = useDispatchAlert();
  const selectedMachine = useSelectedMachine();
  const machineService = useMachineService();

  const dispatchErrorMessage = useCallback(() => {
    dispatchAlert({
      message: t("generic.errorMessage"),
      messageType: "error",
    });
  }, [dispatchAlert]);

  const isSelectedMachineLocked = user.userSystemWideAuthorization.lockedMachines
    .includes(selectedMachine.machineId);

  const getAdminEventQuery = useCallback(() => {
    machineService
      .getAdminEventLogs(selectedMachine.machineId)
      .then((response: IApiResponse<EventLogsApiResponse>) => {
        var newEventLogs = response.data.eventLogs.map((x) => ({
          ...x,
          date: x.date,
          modificationType: toPrettyModificationType(
            x.modificationType.toString()
          ),
        }));
        setEventLogs(newEventLogs);
      })
      .catch(() => {
        dispatchErrorMessage();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMachine]);

  const updateAdminEventLog = () => {
    setIsLoading(true);

    setTimeout(() => {
      fetchData();
    }, 1000); // Refresh timeout to make sure retrieval of events happens after persisting the new event.
  };

  const fetchData = useCallback(() => {
    setIsLoading(true);

    ownerService
      .getAdminMachines()
      .then((response) => {
        setMachines(response.data.ownedMachines);

        const found = response.data.ownedMachines.find(
          (x: OwnerMachine) => x.machineId === selectedMachine.machineId
        );

        if (!found) dispatchErrorMessage();

        setOwnedMachine(found);
      })
      .then(() => {
        getAdminEventQuery();
      })
      .catch(() => {
        dispatchErrorMessage();
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMachine]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <PageWithMachineId page={"machine"}>
      <div className={classes.wrapper}>
        <Paper>
          <>
            <div>
              {selectedMachine !== undefined && (
                <MachineSelectionBar
                  selectedMachineId={selectedMachine.machineId.toString()}
                  ownedMachines={machines}
                  page="management/machine"
                />
              )}
            </div>
            <Grid container spacing={0} className={classes.tabsContent}>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} md={12} xl={5}>
                  {ownedMachine && (
                    <MachineInformationCardContainer
                      actions={undefined}
                      selectedMachine={ownedMachine}
                      isLoading={isLoading}
                    />
                  )}
                </Grid>
                { isSelectedMachineLocked &&
                  <UnlockMachineButton
                    machineId={selectedMachine.machineId}
                  />
                }
                <Grid item xs={12} md={12} xl={11}>
                  <Typography variant="h5" className={classes.header}>
                    {t("machineManagementPage.adminEventLogSectionTitle")}
                  </Typography>
                  <EventLogsContainer
                    eventLogs={eventLogs}
                    isLoading={isLoading}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <SubscriptionSection
                  modulesUpdated={updateAdminEventLog}
                  subscriptionRenewalUpdated={updateAdminEventLog}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
          </>
        </Paper>
      </div>
    </PageWithMachineId>
  );
}
export interface SubscriptionSectionProps {
  modulesUpdated: (machineId: MachineId) => void;
  subscriptionRenewalUpdated: (machineId: MachineId) => void;
  isLoading: boolean;
}

function SubscriptionSection({
  modulesUpdated,
  subscriptionRenewalUpdated,
  isLoading,
}: SubscriptionSectionProps) {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Typography variant="h5" className={classes.header}>
        {t("machineManagementPage.iMobaSubscriptionSectionTitle")}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={handleClickOpen}
      >
        {t("machineManagementPage.termsAndConditionsButtonLabel")}
      </Button>
      <SubscriptionCardsContainer
        modulesUpdated={modulesUpdated}
        subscriptionRenewalUpdated={subscriptionRenewalUpdated}
        isLoading={isLoading}
      />
      <TermsAndConditionsDialog open={openDialog} onClose={handleClose} />
    </>
  );
}

function TermsAndConditionsDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="terms-and-conditions-dialog-title"
      aria-describedby="terms-and-conditions-dialog-description"
      maxWidth="lg"
      style={{
        zIndex: 2500,
      }}
    >
      <DialogTitle id="terms-and-conditions-dialog-title">
        {t("machineManagementPage.termsAndConditionsButtonLabel")}
      </DialogTitle>
      <DialogContent>
        <TermsAndConditionsPage />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {t("machineManagementPage.genericSubscriptionCard.dialogButtonLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface UnlockMachineButtonProps {
  machineId: MachineId;
}

function UnlockMachineButton({
  machineId,
}: UnlockMachineButtonProps) {
  const classes = useStyles();
  var machineService = useMachineService();
  var dispatchAlert = useDispatchAlert();
  const [openUnlockMachineDialog, setOpenUnlockMachineDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUnlockMachine = () => {
    setOpenUnlockMachineDialog(true);
};

const handleLockMachineDialogClose = () => {
  setOpenUnlockMachineDialog(false);
};

const handleUnlockMachineDialogAccept = () => {
  setOpenUnlockMachineDialog(false);
  setIsLoading(true);
  machineService
      .unlockMachine(machineId)
      .then(() => {
        dispatchAlert({
          message: t("machineManagementPage.machineUnlockedSuccess"),
          messageType: "success"
        });
      })
    .catch(() => {
      dispatchAlert({
        message: t("machineManagementPage.machineUnlockedError"),
        messageType: "error",
      });
    })
    .finally(() => {
      setIsLoading(false);
      window.location.reload(); // hard refresh so the user information is retrieved again, not including the recently unlocked machine.
    });
  };

  return (
    <>
      <Grid className={classes.header}>
        <Button
        startIcon={<LockOpenIcon />}
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={handleUnlockMachine}
        disabled={isLoading}
      >
        {t("machineManagementPage.unlockMachineButton")}
      </Button>
      </Grid>
      <ConfirmActionDialog 
        isOpen={openUnlockMachineDialog}
        message={t("machineManagementPage.unlockMachineConfirmationMessage")}
        onConfirm={handleUnlockMachineDialogAccept}
        onReject={handleLockMachineDialogClose}
      />
    </>
  );
}


function toPrettyModificationType(modificationType: string): string {
  switch (modificationType) {
    case "UserAssignedToMachine": {
      return t(
        "machineManagementPage.eventLogsModificationType.UserAssignedToMachine"
      );
    }
    case "UserRemovedFromMachine": {
      return t(
        "machineManagementPage.eventLogsModificationType.UserRemovedFromMachine"
      );
    }
    case "ModuleAssignedToUser": {
      return t(
        "machineManagementPage.eventLogsModificationType.ModuleAssignedToUser"
      );
    }
    case "ModuleRevokedFromUser": {
      return t(
        "machineManagementPage.eventLogsModificationType.ModuleRevokedFromUser"
      );
    }
    case "AutoRenewEnabled": {
      return t(
        "machineManagementPage.eventLogsModificationType.AutoRenewEnabled"
      );
    }
    case "AutoRenewDisabled": {
      return t(
        "machineManagementPage.eventLogsModificationType.AutoRenewDisabled"
      );
    }
    case "SubscriptionActivated": {
      return t(
        "machineManagementPage.eventLogsModificationType.SubscriptionActivated"
      );
    }
    case "SubscriptionDeactivated": {
      return t(
        "machineManagementPage.eventLogsModificationType.SubscriptionDeactivated"
      );
    }
    case "MachineLocked": {
      return t(
        "machineManagementPage.eventLogsModificationType.MachineLocked"
      );
    }
    case "MachineUnlocked": {
      return t(
        "machineManagementPage.eventLogsModificationType.MachineUnlocked"
      );
    }
    default:
      return "";
  }
}

