import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { useState } from "react";
import { BlockPicker } from "react-color";
import Chrome from "react-color/lib/components/chrome/Chrome";
import { useTranslation } from "react-i18next";
import { colors } from "../../../colors";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";

export function ColorPicker({
  initialColor,
  onChange,
  className,
  disabled
}: {
  initialColor?: string;
  onChange: (color: string) => void;
  className?: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const [color, setColor] = useState<string>(
    initialColor ? initialColor : colors.mobaBlue
  );
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const classes = useColorPickerClasses({ color: color });
  const isMobileSize = useIsMobileSize('xs');

  const handleClick = () => {
    if (disabled) return;

    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (inputColor: any) => {
    setColor(inputColor.hex);
    onChange(inputColor.hex);
  };

  return (
    <div className={className}>
      <Chip
        label={t("generic.colorPickerLabel")}
        icon={<EditIcon className={classes.icon} />}
        className={classes.chip}
        onClick={handleClick}
        clickable={!disabled}
      />
      {displayColorPicker ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handleClose} />
          {isMobileSize ? (
            <BlockPicker color={color} onChange={handleColorChange} />
          ) : (
            <Chrome color={color} onChange={handleColorChange} />
          )}
        </div>
      ) : null}
    </div>
  );
}

const useColorPickerClasses = makeStyles((theme) => ({
  chip: {
    backgroundColor: (props: any) => props.color,
    color: "#ffffff",
  },
  icon: {
    color: "#ffffff",
  },
  popover: {
    position: "absolute",
    zIndex: 2,
  },
  cover: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));
