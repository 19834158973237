import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ReportSettings } from "../../../pages/management-settings/Common";
import { t } from "../../../utility/TranslateUtility";
import { TimePicker } from "../../UI/TimePicker/TimePicker";

const useStyles = makeStyles((theme: any) => ({
  select: {
    minWidth: theme.spacing(12),
  },
  autoComplete: {
    width: 300,
    marginLeft: theme.spacing(6),
  },
  timeslotWrapper: {
    width: "100%",
  },
  timeSlot: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(1),
  },
  label: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(20),
    marginLeft: 0,
    minWidth: theme.spacing(20),
  },
  errorMessage: {
    margin: theme.spacing(1),
  },
}));

export function TimeSlotsPresentation({
  settings,
  onSettingsChange,
  showError,
}: {
  settings: ReportSettings;
  onSettingsChange: (settings: ReportSettings) => void;
  showError: boolean;
}) {
  const classes = useStyles();

  const handleStartTimeChange = (newStartTime: Date) => {
    onSettingsChange({
      ...settings,
      timeSlots: {
        ...settings.timeSlots,
        startTimeSlot: newStartTime,
      },
    });
  };

  const handleEndTimeChange = (newEndTime: Date) => {
    onSettingsChange({
      ...settings,
      timeSlots: {
        ...settings.timeSlots,
        endTimeSlot: newEndTime,
      },
    });
  };

  return (
    <div className={classes.timeslotWrapper}>
      <div className={classes.timeSlot}>
        <Typography className={classes.label}>
          {t("managementSettings.report.startTimeSlot")}
        </Typography>
        <TimePicker
          selectedTime={settings.timeSlots.startTimeSlot}
          onTimeChange={handleStartTimeChange}
          label={""}
        />
      </div>
      <div className={classes.timeSlot}>
        <Typography className={classes.label}>
          {t("managementSettings.report.endTimeSlot")}
        </Typography>
        <TimePicker
          selectedTime={settings.timeSlots.endTimeSlot}
          onTimeChange={handleEndTimeChange}
          label={""}
        />
      </div>
      {showError && (
        <Typography className={classes.errorMessage} color={"error"}>
          {t("managementSettings.report.timeSlotsErrorMessage")}
        </Typography>
      )}
    </div>
  );
}
