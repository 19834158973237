import { makeStyles } from "@material-ui/core";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import MuiAlertTitle from "@material-ui/lab/AlertTitle";
import { useEffect, useState } from "react";
import {
  AnnouncementSeverity,
  GlobalAnnouncementModel,
} from "../../../apiModels/GlobalAnnouncement/GlobalAnnouncementModel";
import MultilineText from "../MultilineText";

export function GlobalAnnouncement(model: GlobalAnnouncementModel | null) {
  const styles = useStyles();
  let [isClosed, setClosed] = useState<boolean>(false);
  const { severity, title, message, isActive } = model ?? {};

  //reset closed status to false if announcementMessage changed
  useEffect(() => {
    setClosed(false);
  }, [message, title, severity, isActive]);

  return (
    <>
      {isActive === true && isClosed === false && (
        <MuiAlert
          classes={styles}
          elevation={0}
          variant="filled"
          severity={
            severity !== undefined
              ? (AnnouncementSeverity[severity] as unknown as Color)
              : undefined
          }
          onClose={() => setClosed(true)}
        >
          <MuiAlertTitle>{title}</MuiAlertTitle>
          <MultilineText text={message} />
        </MuiAlert>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 10,
    left: theme.spacing(1),
    bottom: theme.spacing(6),
  },
}));
