import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useDispatchAlert } from "../components/UI/Alert";
import {
  useSelectedMachine,
  useSelectedMachineAction,
} from "../store/GlobalContext";
import { useMachineNames } from "./authorizationHooks";

export type UsesMachineIdPage = "users/manage" | "machine" | "owner";

export function useMachineFromParamsOrRedirect(page: UsesMachineIdPage) {
  // @ts-ignore
  const { machineId } = useParams();
  const selectedMachine = useSelectedMachine();
  const machineNames = useMachineNames();
  const dispatch = useSelectedMachineAction();
  const dispatchAlert = useDispatchAlert();
  const history = useHistory();
  const { t } = useTranslation();
  const [shouldRender, setShouldRender] = useState(false);

  /**
   * Prevents an infinite loop when rendering for the first time. Only one UseEffect should trigger at the same time.
   */
  const [firstTimeRender, setFirstTimeRender] = useState(true);

  /**
   * If the selectedMachine switches, redirect the user
   */
  useEffect(() => {
    if (firstTimeRender) {
      setShouldRender(true);
      return;
    }

    if (!machineId) {
      history.push("/");
    }

    if (selectedMachine.machineId.toString() !== machineId.toString()) {
      const foundMachine = machineNames.find(
        (x) => x.machineId.toString() === selectedMachine.machineId.toString()
      );

      if (!foundMachine) {
        history.push("/");
        dispatchAlert({
          message: t("machineSwitchNotification.error", {
            machineId: selectedMachine.machineId.toString(),
          }),
          messageType: "error",
        });
        return;
      }

      history.push(`/${page}/${selectedMachine.machineId.toString()}`);
    }

    setShouldRender(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMachine]);

  /**
   * If the url changes, switch the machine.
   */
  useEffect(() => {
    if (!machineId) {
      history.push("/");
    }

    if (selectedMachine.machineId.toString() !== machineId.toString()) {
      const foundMachine = machineNames.find(
        (x) => x.machineId.toString() === machineId.toString()
      );

      if (!foundMachine) {
        history.push("/");
        dispatchAlert({
          message: t("machineSwitchNotification.error", {
            machineId: machineId,
          }),
          messageType: "error",
        });
        return;
      }

      let machine: Machine = {
        machineId: Number(machineId),
        machineName: foundMachine.machineName,
      };

      dispatch(machine);
      dispatchAlert({
        message: t("machineSwitchNotification.success", {
          machineId: machine.machineId,
          machineName: machine.machineName,
        }),
        messageType: "success",
      });
      setShouldRender(true);
      if (firstTimeRender) {
        setFirstTimeRender(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineId]);
  return shouldRender;
}
