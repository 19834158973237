import { createMuiTheme } from "@material-ui/core/styles";
import { colors } from "./colors";

let theme = createMuiTheme({
  typography: {
    fontSize: 14,
  },
  palette: {
    primary: {
      main: colors.mobaBlue,
    },
  },
  props: {
    MuiPopover: {
      style: {
        zIndex: 2000
      }
    }
  }
});

export default theme;
