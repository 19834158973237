import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useMemo } from "react";
import { GetCurrentLanguage, t } from "../../../utility/TranslateUtility";
import defaultChartSettings, { BenchmarkColors } from "./chartSettings";
import { ChartCard } from "./Common";
import { tooltipFormatter } from "./MachineMeasureTooltipBuilder";
import {
  areMatching,
  MachineMeasureComparison,
  MachineMeasureComparisonData,
  MeasureComparison,
  PeriodInYear,
  PeriodInYearType,
} from "./Types";

interface SeriesData {
  x: number;
  y: number;
}

export type SeriesType =
  | "machine"
  | "machine-avg"
  | "best3"
  | "worst3"
  | "difference";

export enum BestMeasureType {
  HighValue,
  LowValue,
}

interface Series {
  name: string;
  data: SeriesData[];
  color: string;
  lineColor: string;
  lineWidth?: number;
  type: string;
  opacity?: number;
  fillOpacity?: number;
  showInLegend?: boolean;
  linkedTo?: string;
  id?: string;
  legendColor?: string;
  tooltip?: any;
  enableMouseTracking?: boolean;
  visible?: boolean;
  custom: {
    seriesType: SeriesType;
  };
  zIndex?: number;
}

function MapToChartData(
  data: MachineMeasureComparisonData,
  translate: (key: string) => string,
  machinesToDisplay: Array<MachineId>
): { series: Array<any> } {
  const getXForPeriod = (period: PeriodInYear): number => {
    return data.bestMachinesMeasures.findIndex((m) => areMatching(m, period));
  };
  const getDataPoint = (measure: MeasureComparison): SeriesData => {
    return {
      x: getXForPeriod(measure),
      y: measure.value * 100,
    };
  };

  if (
    data.bestMachinesMeasures.length === 0 ||
    data.worstMachinesMeasures.length === 0 ||
    data.comparisonMachinesMeasures.length === 0
  )
    return { series: [] };

  // machines
  const filteredComparisonMeasures = data.comparisonMachinesMeasures.filter(
    (x) => machinesToDisplay.includes(x.machineId.toString())
  );
  const machineSeriesArray = filteredComparisonMeasures.reduce<Series[]>(
    (series: Series[], current: MachineMeasureComparison): Series[] => {
      // MachineId number is used as a name of series, compare them without checking the type
      // eslint-disable-next-line eqeqeq
      const existingSeries = series.find((s) => s.name == current.machineId);
      if (existingSeries) {
        existingSeries.data = [...existingSeries.data, getDataPoint(current)];
        return series;
      }

      return [
        ...series,
        {
          name: current.machineId.toString(),
          data: [getDataPoint(current)],
          color: BenchmarkColors.GreyLine,
          lineColor: BenchmarkColors.GreyLine,
          legendColor: BenchmarkColors.GreyLine,
          type: "spline",
          opacity: 1,
          fillOpacity: 1,
          custom: {
            seriesType: "machine",
          },
          zIndex: 5,
        },
      ];
    },
    []
  );

  let machineAverageSeries: Series | null = null;

  if (
    machinesToDisplay.includes("AVG") &&
    data.comparisonMachinesMeasures.length > 1
  ) {
    const machineAverageSeriesData = getAverageSeriesData(
      data.comparisonMachinesMeasures,
      data.bestMachinesMeasures,
      getDataPoint
    );

    machineAverageSeries = {
      name: translate("benchmark.average"),
      data: machineAverageSeriesData,
      color: BenchmarkColors.BlueLine,
      lineColor: BenchmarkColors.BlueLine,
      lineWidth: 2,
      type: "spline",
      custom: {
        seriesType: "machine-avg",
      },
      zIndex: 5,
    };
  }

  // best & worst
  const bestMeasureType =
    data.bestMachinesMeasures[0].value > data.worstMachinesMeasures[0].value
      ? BestMeasureType.HighValue
      : BestMeasureType.LowValue;
  const bestMeasureLineColor = BenchmarkColors.RedLine;
  const bestMeasureColor = BenchmarkColors.RedSpace;
  const worstMeasureLineColor = BenchmarkColors.GreenLine;
  const worstMeasureColor = BenchmarkColors.GreenSpace;

  // best 3 machines
  const bestSeries: Series = {
    name: translate("benchmark.best3Machines"),
    id: "best3",
    data: data.bestMachinesMeasures.map((m) => getDataPoint(m)),
    color: bestMeasureLineColor,
    lineColor: bestMeasureLineColor,
    type: "spline",
    opacity: 1,
    fillOpacity: 1,
    custom: {
      seriesType: "best3",
    },
    zIndex: 4,
  };

  // worst 3 machines
  const topWorstSeries: Series = {
    name: translate("benchmark.worst3Machines"),
    id: "worst3",
    data: data.worstMachinesMeasures.map((m) => getDataPoint(m)),
    color: worstMeasureLineColor,
    lineColor: worstMeasureLineColor,
    legendColor: worstMeasureLineColor,
    type: "spline",
    opacity: 1,
    fillOpacity: 1,
    custom: {
      seriesType: "worst3",
    },
    zIndex: 4,
  };

  let result: Array<any> = [topWorstSeries, bestSeries];

  if (machineSeriesArray.length > 0) {
    // ranges
    const myWorstMachineMeasures =
      bestMeasureType === BestMeasureType.HighValue
        ? getMinValues(machineSeriesArray)
        : getMaxValues(machineSeriesArray);

    const differenceBetweenWorstMachines = {
      id: "differenceBetweenWorstMachines",
      linkedTo: "differenceBetweenBestAndWorstMyMachines",
      showInLegend: false,
      data: myWorstMachineMeasures.map((myWorstMeasure) => {
        const worst3MachinesY = (
          topWorstSeries.data.find((v) => v.x === myWorstMeasure.x) || { y: 0 }
        ).y;

        return {
          x: myWorstMeasure.x,
          high:
            bestMeasureType === BestMeasureType.HighValue
              ? myWorstMeasure.y
              : worst3MachinesY,
          low:
            bestMeasureType === BestMeasureType.HighValue
              ? worst3MachinesY
              : myWorstMeasure.y,
        };
      }),
      color: worstMeasureColor,
      lineColor: worstMeasureLineColor,
      tooltip: null,
      type: "areasplinerange",
      custom: {
        seriesType: "difference",
      },
      marker: {
        enabled: false,
      },
      includeInDataExport: false,
      zIndex: 1,
    };

    const myBestMachineMeasures =
      bestMeasureType === BestMeasureType.HighValue
        ? getMaxValues(machineSeriesArray)
        : getMinValues(machineSeriesArray);

    const differenceBetweenBestMachines = {
      id: "differenceBetweenBestMachines",
      linkedTo: "differenceBetweenBestAndWorstMyMachines",
      showInLegend: false,
      data: myBestMachineMeasures.map((myBestMeasure) => {
        const top3MachinesY = (
          bestSeries.data.find((v) => v.x === myBestMeasure.x) || {
            y: myBestMeasure.y,
          }
        ).y;

        return {
          x: myBestMeasure.x,
          low:
            bestMeasureType === BestMeasureType.HighValue
              ? myBestMeasure.y
              : top3MachinesY,
          high:
            bestMeasureType === BestMeasureType.HighValue
              ? top3MachinesY
              : myBestMeasure.y,
        };
      }),
      color: bestMeasureColor,
      lineColor: bestMeasureLineColor,
      tooltip: null,
      type: "areasplinerange",
      custom: {
        seriesType: "difference",
      },
      marker: {
        enabled: false,
      },
      includeInDataExport: false,
      zIndex: 1,
    };

    const differenceBetweenBestAndWorstMyMachines = {
      id: "differenceBetweenBestAndWorstMyMachines",
      showInLegend: false,
      data: myBestMachineMeasures.map((m) => ({
        x: m.x,
        high: m.y,
        low: (myWorstMachineMeasures.find((v) => v.x === m.x) || { y: m.y }).y,
      })),
      color: BenchmarkColors.GreySpace,
      lineColor: BenchmarkColors.GreyLine,
      tooltip: null,
      type: "areasplinerange",
      custom: {
        seriesType: "difference",
      },
      marker: {
        enabled: false,
      },
      fillOpacity: 1,
      includeInDataExport: false,
      zIndex: 1,
    };

    result = determineResultOrder(
      result,
      machineSeriesArray,
      topWorstSeries,
      differenceBetweenBestMachines,
      differenceBetweenWorstMachines,
      differenceBetweenBestAndWorstMyMachines
    );
  }

  if (machineAverageSeries) {
    result.push(machineAverageSeries);
  }

  return {
    series: result,
  };
}

/**
 * When rendering the Benchmark module charts, it is possible that "My Machines" are the best or worst machines for that benchmark measure.
 * In this case:
 * - The area above the top 3 best machines are colored dark green
 * - The area under the worst 3 machiens are colored dark red
 *
 * In order to do this properly, we need to reorder the results array, such that the areasplines are rendered correctly on top of each other.
 * Otherwise, the wrong color will be rendered on top. E.g.: the area above the top 3 machines will turn red, because the red area has the highest zIndex.
 *
 * There is one scenario that we do not support, namely that in one chart, the "My Machines" are both the best and the worst at some point in time.
 * In this case, the default order is returned, accepting that this does not return the correct visualization.
 *
 * @param result
 * @param machineSeriesArray
 * @param topWorstSeries
 * @param differenceBetweenBestMachines
 * @param differenceBetweenWorstMachines
 * @param differenceBetweenBestAndWorstMyMachines
 * @returns The order of the data to render
 */
function determineResultOrder(
  result: Array<any>,
  machineSeriesArray: Series[],
  topWorstSeries: Series,
  differenceBetweenBestMachines: any,
  differenceBetweenWorstMachines: any,
  differenceBetweenBestAndWorstMyMachines: any
) {
  if (
    determineMyMachinesWorseThanWorstMachines(
      machineSeriesArray,
      topWorstSeries.data
    )
  ) {
    return [
      differenceBetweenWorstMachines,
      differenceBetweenBestAndWorstMyMachines,
      differenceBetweenBestMachines,
      ...result,
      ...machineSeriesArray,
    ];
  }

  return [
    differenceBetweenBestMachines,
    differenceBetweenBestAndWorstMyMachines,
    differenceBetweenWorstMachines,
    ...result,
    ...machineSeriesArray,
  ];
}

function determineMyMachinesWorseThanWorstMachines(
  myMachines: Series[],
  worstMachineData: SeriesData[]
): boolean {
  for (const machineData of myMachines) {
    if (machineData.data.length != worstMachineData.length) return false;

    for (let i = 0; i < machineData.data.length; i++) {
      const myMachinesDataPoint = machineData.data[i];
      const worstMachineDataPoint = worstMachineData[i];

      if (myMachinesDataPoint.y >= worstMachineDataPoint.y) continue;

      return true;
    }
  }

  return false;
}

/**
 * Returns min y value for each x of given series
 * @param series series
 */
function getMinValues(series: Series[]): SeriesData[] {
  const min = (numbers: number[]) => Math.min.apply(null, numbers);
  return getMinMaxMachines(series, min);
}

/**
 * Returns max y value for each x of given series
 * @param series series
 */
function getMaxValues(series: Series[]): SeriesData[] {
  const max = (numbers: number[]) => Math.max.apply(null, numbers);
  return getMinMaxMachines(series, max);
}

function getMinMaxMachines(
  series: Series[],
  getMinMax: (numbers: number[]) => number
): SeriesData[] {
  let minMaxSeries: SeriesData[] = [];
  const xValues = series[0].data.map((x) => x.x);
  const flatValues = series.map((s) => s.data).flat();
  for (let x of xValues) {
    const minMaxY = getMinMax(
      flatValues.filter((v) => v.x === x).map((v) => v.y)
    );
    minMaxSeries.push({ x: x, y: minMaxY });
  }

  return minMaxSeries;
}

function getAverageSeriesData(
  machineMeasures: MachineMeasureComparison[],
  xValues: PeriodInYear[],
  getDataPoint: (measure: MeasureComparison) => SeriesData
): SeriesData[] {
  let averages: SeriesData[] = [];
  for (let period of xValues) {
    const periodValues = machineMeasures
      .filter((m) => areMatching(m, period))
      .map((x) => x.value);
    const periodTotal = periodValues.reduce((a, b) => a + b, 0);
    const periodAverage = periodTotal / periodValues.length;

    averages.push(
      getDataPoint({
        ...period,
        value: periodAverage,
      })
    );
  }
  return averages;
}

function getPeriodDateXLabel(
  periodInYear: PeriodInYear,
  periodInYearType: PeriodInYearType,
  translate: (key: string) => string,
  language: string
): string {
  const yearPart = periodInYear.year.toString().slice(2);
  if (periodInYearType.toLowerCase() === "week") {
    return `${translate("benchmark.week")} ${periodInYear.period}/${yearPart}`;
  } else {
    const date = new Date(periodInYear.year, periodInYear.period - 1, 1);
    const dateFormatter = new Intl.DateTimeFormat(language, { month: "short" });
    const monthPart = dateFormatter.format(date);
    return `${monthPart} ${yearPart}`;
  }
}

export default function MachineMeasureComparisonPresentation({
  awaitingResponse,
  data,
  title,
  machinesToDisplay,
}: {
  awaitingResponse: boolean;
  data: MachineMeasureComparisonData;
  title: string;
  machinesToDisplay: Array<MachineId>;
}) {
  const newOptions = useMemo(() => {
    if (!data) return defaultChartSettings;

    const language = GetCurrentLanguage();
    const newData = MapToChartData(data, t, machinesToDisplay);
    const xAxisLabels = data.bestMachinesMeasures.map((m) =>
      getPeriodDateXLabel(m, data.periodInYearType, t, language)
    );

    return {
      ...defaultChartSettings,
      xAxis: {
        ...defaultChartSettings.xAxis,
        categories: xAxisLabels,
        startOnTick: true,
      },
      tooltip: tooltipFormatter,
      series: [...newData.series],
    };
  }, [data, machinesToDisplay]);

  return (
    <ChartCard headerTitle={title} isLoading={awaitingResponse}>
      <HighchartsReact
        highcharts={Highcharts}
        options={newOptions}
        immutable={true}
      />
    </ChartCard>
  );
}
