import { CardHeader, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useMemo, useState } from "react";
import { colors } from "../../colors";
import SkeletonCardWrapper, {
  SkeletonVariant,
} from "../Widgets/Wrappers/SkeletonCardWrapper";
import { SparklineSettings } from "./ChartSettings/SparklineChartSettings";

interface Props {
  title: string;
  measure: string;
  caption?: string;
  tooltip: { formatter: (value: any) => string };
  data: any;
  yAxisKey: string;
  awaitingResponse: boolean;
  display: string | undefined;
}

export default function SparklineWidgetPresentation({
  title,
  measure,
  caption,
  tooltip,
  data,
  yAxisKey,
  awaitingResponse,
  display,
}: Props) {
  const classes = useStyles();

  const newOptions = useMemo(() => {
    const newData = MapToChartData(data, yAxisKey);

    return {
      ...SparklineSettings,
      series: [newData.series],
      tooltip: tooltip,
    };
  }, [data, tooltip, yAxisKey]);

  const [elevationLevel, setElevationLevel] = useState(1);

  return awaitingResponse ? (
    <SkeletonCardWrapper variant={SkeletonVariant.Sparkline} />
  ) : (
    <Card
      elevation={elevationLevel}
      onMouseEnter={() => setElevationLevel(10)}
      onMouseLeave={() => setElevationLevel(1)}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{
          color: "textSecondary",
          gutterBottom: true,
          variant: "body1",
        }}
        className={classes.cardHeader}
      />
      <CardContent
        classes={{
          root: classes.root,
        }}
      >
        <div className={classes.content}>
          <Typography variant="h4" id={`sparkline-measure-${yAxisKey}`}>
            {measure}
          </Typography>
          {caption && (
            <Typography
              variant="body2"
              id={`sparkline-caption-${yAxisKey}`}
              className={classes.caption}
            >
              {caption}
            </Typography>
          )}
          {display ? (
            <Typography
              variant="body2"
              id={`sparkline-targets-${yAxisKey}`}
              className={classes.display}
            >
              {display}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              id={`sparkline-targets-${yAxisKey}`}
              className={classes.noDisplay}
            ></Typography>
          )}
        </div>
        <HighchartsReact highcharts={Highcharts} options={newOptions} />
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px !important",
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: theme.spacing(4),
  },
  cardHeader: {
    paddingBottom: theme.spacing(0),
  },
  caption: {
    fontWeight: "lighter",
  },
  display: {
    color: "gray",
    height: "30px",
    backgroundColor: colors.whitesmoke,
    marginTop: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "auto",
    display: "inline-block",
    right: "inherit",
    borderRadius: "10px",
  },
  noDisplay: {
    marginTop: "5px",
    height: "30px",
  },
}));

function MapToChartData(
  data: any,
  yAxisKey: string
): { series: { data: any } } {
  const result = {
    data: data.map((item: any) => ({
      x: item.dateTime,
      y: item[yAxisKey],
    })),
  };

  return {
    series: result,
  };
}
