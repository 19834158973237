import React, { useEffect, useState, useCallback } from "react";
import { useReportService } from "../../../api/ServiceContext";
import OffgradesWidgetPresentation, {
  OffgradesApiData,
  OffgradesApiResponse,
} from "./Offgrades";
import useRefresh from "../../Dashboard/useRefresh";

export type OffgradesWidgetProps = {
  machineId: number;
  automaticRefresh: boolean;
};

/**
 * Shows the offgrade percentage for the last 30 minutes.
 */
export default function OffgradesWidgetContainer({
  machineId,
  automaticRefresh,
}: OffgradesWidgetProps) {
  const reportService = useReportService();
  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [data, setData] = useState<OffgradesApiData | any>(null);

  const fetchData = useCallback(
    (newMachineId: number) => {
      reportService
        .getOffgrades(newMachineId)
        .then((response: OffgradesApiResponse) => {
          setAwaitingResponse(false);
          const offGradesPeriodLength =
            response.data.offgradePeriods.length - 1;

          if (offGradesPeriodLength < 0) return;

          setData(response.data);
        });
    },
    [reportService]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return (
    <OffgradesWidgetPresentation
      awaitingResponse={awaitingResponse}
      data={data}
    />
  );
}
