import { Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { isAfter } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { TimePicker } from "../../UI/TimePicker/TimePicker";
import { DeleteIconButton } from "../Common";

export function DailyTotalInMinutesCount({
  dailyTotalInMinutes,
  className,
}: {
  dailyTotalInMinutes: number;
  className?: string;
}) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Typography variant="body2">
        {t("managementSettings.breakTimes.dailyTotalCount", {
          dailyTotalInMinutes: dailyTotalInMinutes,
        })}
      </Typography>
    </div>
  );
}

export function AddNewBreakButton({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={onClick}
      >
        {t("managementSettings.breakTimes.addNewBreakButtonLabel")}
      </Button>
    </div>
  );
}

export function RemoveAllBreaksButton({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Button
        color="secondary"
        variant="outlined"
        startIcon={<DeleteIcon />}
        onClick={onClick}
      >
        {t("managementSettings.breakTimes.deleteAllButtonLabel")}
      </Button>
    </div>
  );
}

const useBreakTimeRowClasses = makeStyles((theme) => ({
  spacing: {
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(1),
    },
  },
  timePickers: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0),
    },
  },
  gridItem: {
    alignSelf: "center",
  },
  deleteButton: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0),
    },
  },
  gridContainer: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
}));

export function BreakTimeRow({
  breakNumber,
  onDeleteRow,
  startTime,
  endTime,
  onTimeChanged,
  onError,
  onErrorResolved,
}: {
  breakNumber: number;
  onDeleteRow: (breakNumber: number) => void;
  startTime: Date;
  endTime: Date;
  onTimeChanged: (startTime: Date, endTime: Date) => void;
  onError: () => void;
  onErrorResolved: () => void;
}) {
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize('xs');

  const classes = useBreakTimeRowClasses();
  const [internalStartTime, setInternalStartTime] = useState(startTime);
  const [internalEndTime, setInternalEndTime] = useState(endTime);
  const [errorMessage, setErrorMessage] = useState("");

  const handleTimeChange = (newStartDate: Date, newEndDate: Date) => {
    if (isAfter(newStartDate, newEndDate)) {
      setErrorMessage(t("managementSettings.breakTimes.errorMessage"));
      onError();
    } else {
      setErrorMessage("");
      onErrorResolved();
    }

    setInternalStartTime(newStartDate);
    setInternalEndTime(newEndDate);
    onTimeChanged(newStartDate, newEndDate);
  };

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={3} sm={1} className={classes.gridItem}>
        <BreakTimeLabel breakNumber={breakNumber} />
      </Grid>
      <Grid item xs={8} sm={4} className={classes.gridItem}>
        <div className={classes.timePickers}>
          <div className={classes.spacing}>
            <TimePicker
              selectedTime={internalStartTime}
              onTimeChange={(newDate: Date) =>
                handleTimeChange(newDate, internalEndTime)
              }
              label={
                isMobileSize
                  ? ""
                  : t("managementSettings.breakTimes.startBreakTime")
              }
            />
          </div>
          <TimePicker
            selectedTime={internalEndTime}
            onTimeChange={(newDate: Date) =>
              handleTimeChange(internalStartTime, newDate)
            }
            label={
              isMobileSize
                ? ""
                : t("managementSettings.breakTimes.endBreakTime")
            }
          />
        </div>
      </Grid>
      <Grid item xs={1} sm={1} className={classes.gridItem}>
        <DeleteIconButton
          onClick={() => onDeleteRow(breakNumber)}
          className={classes.deleteButton}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        {errorMessage !== "" && (
          <Typography color="secondary">{errorMessage}</Typography>
        )}
      </Grid>
    </Grid>
  );
}

export function BreakTimeLabel({
  breakNumber,
  className,
}: {
  breakNumber: number;
  className?: string;
}) {
  const { t } = useTranslation();

  return (
    <Typography className={className} variant="body1">
      {t("managementSettings.breakTimes.breakNumberName", {
        breakNumber: breakNumber,
      })}
    </Typography>
  );
}
