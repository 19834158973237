import { colors } from "../colors";
import { EggUnit } from "./EggUnitUtility";
import {
  FormatWithNoDecimal,
  FormatWithOneDecimal,
  FormatWithTwoDecimals
} from "./NumberFormatUtility";
import { t } from "./TranslateUtility";

export enum EggType {
  Unknown = 0,
  Cage,
  Barn,
  FreeRange,
  Organic,
  Unmapped,
  Mapped,
  AllEggTypes,
}

export const allEggTypes: EggType[] = [
  EggType.Unknown,
  EggType.Cage,
  EggType.Barn,
  EggType.FreeRange,
  EggType.Organic,
  EggType.Unmapped,
  EggType.Mapped,
  EggType.AllEggTypes,
];

export const basicEggTypes: EggType[] = [
  EggType.Cage,
  EggType.Barn,
  EggType.FreeRange,
  EggType.Organic,
  EggType.Unmapped,
  EggType.Unknown,
];

export const mappedEggTypes: EggType[] = [
  EggType.Cage,
  EggType.Barn,
  EggType.FreeRange,
  EggType.Organic,
];

export function normalizeEggType(eggType: EggType): EggType[] {
  if (eggType === EggType.Mapped) {
    return mappedEggTypes;
  }
  if (eggType === EggType.AllEggTypes) {
    return basicEggTypes;
  }
  return [eggType];
}

export const singleEggUnit = 1;
export const numberOfEggsInADozen = 12;
export const numberOfEggsInACase = 360;

export const minutesInAnHour = 60;

export const menuItems = [
  "eggUnits.displayUnit",
  "eggUnits.singleEggs",
  "eggUnits.cases",
  "eggUnits.dozens",
];

export const calculateNumberOfEggs = (
  totalNumberOfEggs: number,
  eggUnit: string
): number => {
  let numberOfEggsInSelectedUnit;
  switch (eggUnit) {
    case "Eggs":
      numberOfEggsInSelectedUnit = singleEggUnit;
      break;
    case "Dozens":
      numberOfEggsInSelectedUnit = numberOfEggsInADozen;
      break;
    case "Cases":
      numberOfEggsInSelectedUnit = numberOfEggsInACase;
      break;
    default:
      numberOfEggsInSelectedUnit = singleEggUnit;
      break;
  }

  return (totalNumberOfEggs / numberOfEggsInSelectedUnit) >> 0;
};

export const getDisplayUnit = (eggUnit: string): string => {
  switch (eggUnit) {
    case "Eggs":
      return menuItems[1];
    case "Dozens":
      return menuItems[3];
    case "Cases":
      return menuItems[2];
    default:
      return menuItems[1];
  }
};

export function determineEggTypeName(eggType: EggType) {
  switch (eggType) {
    case EggType.Barn:
      return t("eggTypes.barn");
    case EggType.Cage:
      return t("eggTypes.cage");
    case EggType.FreeRange:
      return t("eggTypes.freeRange");
    case EggType.Organic:
      return t("eggTypes.organic");
    case EggType.Unmapped:
      return t("eggTypes.unmapped");
    case EggType.Unknown:
      return t("eggTypes.unknown");
    case EggType.AllEggTypes:
      return t("eggTypes.allEggTypes");
    default:
      return t("eggTypes.unknown");
  }
}

export function determineEggTypeColor(eggType: EggType): string {
  switch (eggType) {
    case EggType.Cage:
      return colors.cageEgg;
    case EggType.Barn:
      return colors.barnEgg;
    case EggType.FreeRange:
      return colors.freeRangeEgg;
    case EggType.Organic:
      return colors.organicEgg;
    case EggType.Unmapped:
      return colors.unmappedEgg;
    case EggType.Unknown:
      return colors.mobaBlue;
    default:
      return colors.mobaBlue;
  }
}

export function determineEggUnit(eggUnit: string): EggUnit {
  switch (eggUnit) {
    case "Eggs":
      return EggUnit.Eggs;
    case "Cases":
      return EggUnit.Cases;
    case "Dozens":
      return EggUnit.Dozens;
    default:
      return EggUnit.Eggs;
  }
}

export function determineEggUnitIndex(eggUnit: EggUnit): number {
  switch (eggUnit.toString()) {
    case "Eggs":
      return 1;
    case "Cases":
      return 2;
    case "Dozens":
      return 3;
    default:
      return 1;
  }
}

export function determineEggUnitByIndex(index: number): EggUnit {
  switch (index) {
    case 1:
      return EggUnit.Eggs;
    case 2:
      return EggUnit.Cases;
    case 3:
      return EggUnit.Dozens;
    default:
      return EggUnit.Eggs;
  }
}

export function createUnitsConverter(
  unit: EggUnit | null
): (value: number) => number {
  let divisor = 1;
  if (unit === EggUnit.Cases) divisor = numberOfEggsInACase;
  else if (unit === EggUnit.Dozens) divisor = numberOfEggsInADozen;
  return (value: number) => {
    return value / divisor;
  };
}

export function FormatEggs(
  n: number,
  unit: EggUnit | null,
  convert: boolean = true
): string {
  if (convert) {
    const inUnits = createUnitsConverter(unit || EggUnit.Eggs);
    n = inUnits(n);
  }
  switch (unit) {
    case EggUnit.Dozens:
      return FormatWithOneDecimal(n);
    case EggUnit.Cases:
      return FormatWithTwoDecimals(n);
    default:
      return FormatWithNoDecimal(n);
  }
}
