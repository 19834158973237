import React from "react";
import Toolbar from "../../components/UI/Toolbar/Toolbar";
import { ToolbarDatePeriodButtonGroup } from "../../components/UI/Toolbar/ToolbarDatePeriodButtonGroup";
import { ToolbarDatePicker } from "../../components/UI/Toolbar/ToolbarDatepicker";
import { ToolbarEggTypeSelect } from "../../components/UI/Toolbar/ToolbarEggTypeSelect";
import {
  usePerformanceProFilterOptions,
  usePerformanceProFilterOptionsActions,
} from "../../store/GlobalContext";
import {
  DatePeriod,
  validateIfDateOrReturnToday,
} from "../../utility/DateUtility";
import {
  selectedDateKey,
  selectedDatePeriodKey,
  selectedEggTypeKey,
} from "../../utility/LocalStorageKeys";

export interface PerformanceProToolbarContainerProps {
  enableEggTypeSelect: boolean;
  enableDatePeriod: boolean;
}

export function PerformanceProToolbarContainer({
  enableEggTypeSelect,
  enableDatePeriod,
}: PerformanceProToolbarContainerProps) {
  const performanceProFilterOptions = usePerformanceProFilterOptions();
  const dispatch = usePerformanceProFilterOptionsActions();

  const handleDateChange = (newDate: Date) => {
    localStorage.setItem(selectedDateKey, newDate.toString());
    dispatch({
      ...performanceProFilterOptions,
      selectedDate: newDate,
    });
  };

  const handleDatePeriodChange = (newDatePeriod: DatePeriod) => {
    localStorage.setItem(selectedDatePeriodKey, newDatePeriod.toString());
    dispatch({
      ...performanceProFilterOptions,
      selectedDatePeriod: newDatePeriod,
    });
  };

  const handleEggTypeChange = (newEggType: number) => {
    localStorage.setItem(selectedEggTypeKey, newEggType.toString());
    dispatch({
      ...performanceProFilterOptions,
      selectedEggType: newEggType,
    });
  };

  return (
    <PerformanceProToolbarPresentation
      enableEggTypeSelect={enableEggTypeSelect}
      enableDatePeriod={enableDatePeriod}
      selectedDate={validateIfDateOrReturnToday(
        performanceProFilterOptions.selectedDate
      )}
      selectedDatePeriod={performanceProFilterOptions.selectedDatePeriod}
      selectedEggType={performanceProFilterOptions.selectedEggType}
      onDateChange={handleDateChange}
      onDatePeriodChange={handleDatePeriodChange}
      onEggTypeChange={handleEggTypeChange}
    />
  );
}

export interface PerformanceProToolbarPresentationProps {
  enableEggTypeSelect: boolean;
  enableDatePeriod: boolean;
  selectedDate: Date;
  selectedDatePeriod: DatePeriod;
  selectedEggType: number;
  onDateChange: (newDate: Date) => void;
  onDatePeriodChange: (newDatePeriod: DatePeriod) => void;
  onEggTypeChange: (newEggType: number) => void;
}

export function PerformanceProToolbarPresentation({
  enableEggTypeSelect,
  enableDatePeriod,
  selectedDate,
  selectedDatePeriod,
  selectedEggType,
  onDateChange,
  onDatePeriodChange,
  onEggTypeChange,
}: PerformanceProToolbarPresentationProps) {
  return (
    <Toolbar>
      <ToolbarDatePicker
        selectedDate={selectedDate}
        onDateChange={onDateChange}
      />
      {enableDatePeriod && (
        <ToolbarDatePeriodButtonGroup
          selectedDatePeriod={selectedDatePeriod}
          onDatePeriodChange={onDatePeriodChange}
        />
      )}
      {enableEggTypeSelect && (
        <ToolbarEggTypeSelect
          selectedEggType={selectedEggType}
          onEggTypeChange={onEggTypeChange}
        />
      )}
    </Toolbar>
  );
}
