import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as iso31661 from "iso-3166-1";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBenchmarkService } from "../../../../../api/ServiceContext";
import {
  useBenchmarkActions,
  useBenchmarkState
} from "../../../../../store/GlobalContext";

export type CountriesApiResponse = {
  results: Array<string>;
};

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(2),
  },
}));

export default function MachineMeasureCountrySelect() {
  const service = useBenchmarkService();
  const { updateFilterCountries } = useBenchmarkActions();
  const { t } = useTranslation();
  const classes = useStyles();
  const benchmarkState = useBenchmarkState();
  const [countries, setCountries] = useState<Array<string | undefined>>([]);
  const currentSelection = benchmarkState.editingFilterState.countries.map(
    (x) => iso31661.whereAlpha3(x)?.country
  );

  useEffect(() => {
    service
      .getCountries()
      .then((response: IApiResponse<CountriesApiResponse>) => {
        const machineCountries = response.data.results
          .map((countryCode) => iso31661.whereAlpha3(countryCode)?.country)
          .filter((country) => country !== undefined);
        setCountries(machineCountries);
      });
  }, [service]);

  const handleChange = (_: any, value: string[]) => {
    const isoCountryCodes = value.map(
      (x: string) => iso31661.whereCountry(x)?.alpha3 || ""
    );

    updateFilterCountries(isoCountryCodes);
  };

  return (
    <>
      <Typography variant="body1">
        {t("benchmark.filters.countrySelect")}
      </Typography>
      <div className={classes.input}>
        <Autocomplete
          multiple
          value={currentSelection}
          disableCloseOnSelect={true}
          onChange={handleChange}
          options={countries}
          getOptionLabel={(option) => option ?? ""}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("benchmark.filters.countrySelect")}
              variant="outlined"
            />
          )}
        />
      </div>
    </>
  );
}
