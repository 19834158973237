import { Button, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    marginTop: theme.spacing(2),
  },
  lightbox: {
    position: "fixed",
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 5000,
    display: "flex",
    justifyContent: "center",
  },
  lightboxImage: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  closeButton: {
    zIndex: 5001,
    width: "50px",
    height: "50px",
    position: "fixed",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export function Image({ src, title }: any) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <img
        style={{
          cursor: "pointer",
          height: "auto",
          width: "40%",
        }}
        src={src}
        alt={title}
        onClick={() => setIsOpen(true)}
      />
      <br />
      {isOpen && (
        <Lightbox
          onCloseClicked={() => setIsOpen(false)}
          imageSrc={src}
          altText={title}
        />
      )}
      <Typography variant="caption" className={classes.paragraph}>
        An informative illustration of a grading machine (click to enlarge).
      </Typography>
    </>
  );
}

function Lightbox({
  onCloseClicked,
  imageSrc,
  altText,
}: {
  onCloseClicked: () => void;
  imageSrc: string;
  altText: string;
}) {
  const classes = useStyles();
  return (
    <div className={classes.lightbox} onClick={onCloseClicked}>
      <img src={imageSrc} alt={altText} className={classes.lightboxImage} />
      <Button
        variant="contained"
        size="small"
        onClick={onCloseClicked}
        className={classes.closeButton}
      >
        <CloseIcon />
      </Button>
    </div>
  );
}
