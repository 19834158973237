import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../colors";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { DatePeriod } from "../../../utility/DateUtility";
import { t } from "../../../utility/TranslateUtility";

export type ToolbarButtonGroupProps = {
  label: string;
  buttonGroupItems: Array<string>;
  selectedDatePeriod: DatePeriod;
  onDatePeriodChange: (newDatePeriod: DatePeriod) => void;
};

/**
 * Displays the given buttonGroupItems together in one group.
 *
 * @param label The label to render above the button group
 * @param buttonGroupItems The content of the buttons
 */
export function ToolbarButtonGroup({
  label,
  buttonGroupItems,
  selectedDatePeriod,
  onDatePeriodChange,
}: ToolbarButtonGroupProps) {
  const classes = useStyles();
  const isMobileSize = useIsMobileSize();

  const handleButtonClick = (index: number) => {
    onDatePeriodChange(index);
  };

  return (
    <div className={classes.root}>
      <InputLabel id="button-group-label" className={classes.buttonGroupLabel}>
        {label}
      </InputLabel>

      <ButtonGroup
        size={isMobileSize ? "large" : "medium"}
        fullWidth={isMobileSize}
        variant="outlined"
        color="primary"
        aria-label="outlined primary button group"
      >
        {buttonGroupItems.map((buttonGroupItem: any, index: number) => (
          <Button
            key={index}
            onClick={() => handleButtonClick(index)}
            className={selectedDatePeriod === index ? classes.selected : ""}
          >
            {t(buttonGroupItem)}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  buttonGroupLabel: {
    fontSize: "0.75rem",
    marginBottom: theme.spacing(1),
  },
  selected: {
    backgroundColor: colors.primarySelected,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.primarySelected,
      color: colors.white,
    },
  },
}));
