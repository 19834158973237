import { useEffect } from "react";
import { useBenchmarkState } from "../../store/GlobalContext";
import { allMeasures, Measure } from "./MachineMeasureComparison/Types";

export function useStoreBenchmarkStateEffect() {

  const state = useBenchmarkState();

  useEffect(() => {
    benchmarkStateStorageService.saveSelectedWidgets(state.selectedWidgets);
  }, [state.selectedWidgets])

  useEffect(() => {
    benchmarkStateStorageService.saveShowRadar(state.showRadar);
  }, [state.showRadar])
}

export class BenchmarkStateStorageService {

  getSelectedWidgets() : Measure[] {
    return window.localStorage.getItem("benchmark.selectedWidgets") ? JSON.parse(window.localStorage.getItem("benchmark.selectedWidgets")!) : allMeasures
  }

  saveSelectedWidgets(measures: Measure[]) {
    window.localStorage.setItem("benchmark.selectedWidgets", JSON.stringify(measures));
  }

  getShowRadar(): boolean {
    return window.localStorage.getItem("benchmark.showRadar") ? JSON.parse(window.localStorage.getItem("benchmark.showRadar")!) : true
  }

  saveShowRadar(showRadar: boolean) {
    window.localStorage.setItem("benchmark.showRadar", JSON.stringify(showRadar));
  }
}

export const benchmarkStateStorageService = new BenchmarkStateStorageService();