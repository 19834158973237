import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import logo from "../../../img/moba_logo.png";
import { t } from "../../../utility/TranslateUtility";

const useStyles = makeStyles((theme) => ({
  bar: {
    width: "100vw",
    position: "fixed",
    left: 0,
    bottom: 0,
    padding: theme.spacing(1),
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0.5),
    }
  },
  lastReceived: {
    marginRight: theme.spacing(2),
  },
  version: {
    flex: 1,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  logo: {
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(2),
    },
  },
}));
interface InformationBarProps {
  version: string;
  lastDataReceived: Date;
  noDate: boolean;
}

export function InformationBarPresentation({
  lastDataReceived,
  version,
  noDate,
}: InformationBarProps) {
  const classes = useStyles();

  const isMobileSize = useIsMobileSize('sm');

  return (
    <Paper className={classes.bar}>
      <div className={classes.flexBox}>
        <img src={logo} alt={t("infoBar.imgAlt")} className={classes.logo} />

        <div className={classes.textContainer}>
          <Typography
            className={classes.version}
            variant={isMobileSize ? "caption" : "body1"}
          >
            {t("infoBar.version", {
              year: new Date().getFullYear(),
              version: version,
            })}
          </Typography>
          {noDate ? (
            <Typography
              className={classes.lastReceived}
              variant={isMobileSize ? "caption" : "body1"}
            >
              {t("infoBar.dataReceivedOn", {
                date: format(lastDataReceived, "yyyy-MM-dd HH:mm"),
              })}
            </Typography>
          ) : (
            <Typography variant={isMobileSize ? "caption" : "body1"}>
              {t("infoBar.noDate")}{" "}
            </Typography>
          )}
        </div>
      </div>
    </Paper>
  );
}
