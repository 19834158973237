import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { isMobile } from "react-device-detect";
import { t } from "../../../utility/TranslateUtility";

const useStyles = makeStyles(() => ({
  export: {
    marginBottom: 15,
  },
}));

export function ExportRankingOfSuppliesButton({
  exportToExcel,
}: {
  exportToExcel: () => void;
}) {
  const classes = useStyles();

  return (
    <>
      {!isMobile && (
        <Button
          variant="outlined"
          color="primary"
          className={classes.export}
          onClick={exportToExcel}
        >
          {t("qualityViews.rankingOfSupplies.export")}
        </Button>
      )}
    </>
  );
}
