import { CardActions } from "@material-ui/core";
import ChangeMachineNameContainer from "./ChangeMachineNameContainer";

export interface MachineInformationCardActionsProps {
  machineName: string;
  saveMachineName: (name: string) => void;
}

function MachineInformationCardActions({
  machineName,
  saveMachineName,
}: MachineInformationCardActionsProps) {
  const handleSaveMachineName = (name: string) => {
    saveMachineName(name);
  };

  return (
    <CardActions>
      <ChangeMachineNameContainer
        machineName={machineName}
        saveMachineName={handleSaveMachineName}
      />
    </CardActions>
  );
}

export default MachineInformationCardActions;
