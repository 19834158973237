import { PerformanceGroupItem } from "../../PerformancePro/Types";

export interface SupplyRanking {
  supplierNameShed: string;
  averageEggWeight: number;
  averageColor: number;
  averageShellStrength: number;
  eggTypes: number[];
  offgradeEggsPercentage: number;
  offgradeEggsNumber: number;
  totalEggsNumber: number;
  ticketNumbers: string[];
}

export function getAverage(
  supplierData: PerformanceGroupItem[],
  offset: number,
): number {
  const totalColor = supplierData.reduce(
    (a, b) => a + b.value * (Number(b.group.split(" ")[1])+1),
    0
  );
  const totalEggs = supplierData.reduce((a, b) => a + b.value, 0);
  return (totalColor / totalEggs) + offset;
};

export function getOffgradeEggsPercentage(
  supplierOffgradeData: PerformanceGroupItem[],
  SupplierTotalEggsData: PerformanceGroupItem[]
): number {
  const totalOffgradeEggs = supplierOffgradeData.reduce(
    (a, b) => a + b.value,
    0
  );
  const totalEggs = SupplierTotalEggsData.reduce(
    (a, b) => a + b.value,
    0
  );
  return (totalOffgradeEggs / totalEggs) * 100;
};

export function getAverageWeight(
  supplierEggData: PerformanceGroupItem[],
  supplierWeightData: PerformanceGroupItem[]
): number {
  const totalEggs = supplierEggData.reduce((a, b) => a + b.value, 0);
  const totalWeightInMG = supplierWeightData.reduce(
    (a, b) => a + b.value,
    0
  );
  const totalWeightInGram = totalWeightInMG / 1000;

  return totalWeightInGram / totalEggs;
};

export function filterBySupplier(
  data: PerformanceGroupItem[],
  supply: string,
): PerformanceGroupItem[] {
  return data.filter((item: PerformanceGroupItem) => item.x === supply);
}