import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React from "react";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "bold",
  },
}));

export default function CustomTooltip({
  children,
  title,
  body,
}: {
  children: any;
  title: string;
  body: string;
}) {
  const classes = useStyles();
  return (
    <HtmlTooltip
      title={
        <>
          <Typography className={classes.title} color="inherit" variant="body1">
            {title}
          </Typography>
          <Typography color="inherit" variant="body2">
            {body}
          </Typography>
        </>
      }
    >
      {children}
    </HtmlTooltip>
  );
}
