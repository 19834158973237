import { useMemo } from "react";
import {
  matchPath,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { useUpdatePerformanceProInitialState } from "../../components/PerformancePro/usePerformanceProInitialStateHooks";
import {
  NavigationItem,
  TabNavigation,
  TabNavigationVariant
} from "../../components/UI/TabNavigation";
import {
  Features,
  useAuthorizationScope
} from "../../globalHooks/authorizationHooks";
import { useSelectedMachine } from "../../store/GlobalContext";
import { PerformanceProRoute } from "../../utility/ProtectedRoute";
import { t } from "../../utility/TranslateUtility";
import { PerformanceProOverview } from "./PerformanceProOverview";
import { PerformanceProProduction } from "./PerformanceProProduction";
import { PerformanceProQuality } from "./PerformanceProQuality";
import { PerformanceProReport } from "./PerformanceProReport";
import PerformanceProSupplierDetail from "./PerformanceProSupplierDetail";

export default function PerformanceProPage() {
  let { path, url } = useRouteMatch();
  let location = useLocation();
  const authorizationScope = useAuthorizationScope();
  const machineId = useSelectedMachine();
  useUpdatePerformanceProInitialState(machineId);

  const navigationItems: Array<NavigationItem> = [
    {
      link: `${url}/overview`,
      title: t("performanceProNavigationTabs.navigationItems.overview"),
      shouldRender: true
    },
  ];

  if (
    authorizationScope.hasAuthorizationForSelectedMachine(
      Features.PerformancePro
    )
  ) {
    navigationItems.push({
      link: `${url}/production`,
      title: t("performanceProNavigationTabs.navigationItems.production"),
      shouldRender: true
    });
    navigationItems.push({
      link: `${url}/quality`,
      title: t("performanceProNavigationTabs.navigationItems.quality"),
      shouldRender: true
    });
    navigationItems.push({
      link: `${url}/report`,
      title: t("performanceProNavigationTabs.navigationItems.report"),
      shouldRender: true
    });
  }

  const selectedNavigationItem = useMemo<number>((): number => {
    const result = navigationItems.findIndex((item) =>
      matchPath(location.pathname, {
        path: item.link,
        exact: false,
        strict: false,
      })
    );

    return result < 0 ? 0 : result;
  }, [navigationItems, location]);

  return (
    <>
      <TabNavigation
        navigationItems={navigationItems}
        variant={TabNavigationVariant.Primary}
        selectedNavigationItem={selectedNavigationItem}
      />
      <Switch>
        <PerformanceProRoute
          path={`${path}/production`}
          component={PerformanceProProduction}
        />
        <Route
          path={`${path}/quality/supplier/:supplierName/shed/:supplierShed`}
          component={PerformanceProSupplierDetail}
        />
        <PerformanceProRoute
          path={`${path}/quality`}
          component={PerformanceProQuality}
        />
        <PerformanceProRoute
          path={`${path}/report`}
          component={PerformanceProReport}
        />
        <Route path={`${path}/overview`} component={PerformanceProOverview} />

        <Route path={`${path}/`} component={PerformanceProOverview} />
      </Switch>
    </>
  );
}
