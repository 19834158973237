import { makeStyles, Paper, Toolbar } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  TabNavigation,
  TabNavigationVariant
} from "../../components/UI/TabNavigation";
import { useIsMobileSize } from "../../globalHooks/responsiveHooks";

export default function SkeletonPage() {
  const classes = useStyles();
  const isMobileSize = useIsMobileSize('xs');

  return (
    <>
      <TabNavigation
        navigationItems={[]}
        variant={TabNavigationVariant.Primary}
        selectedNavigationItem={0}
      />

      {!isMobileSize && (
        <div className={classes.toolbarWrapper}>
          <Toolbar>
            <Skeleton
              variant="rect"
              className={classes.inputSkeleton}
              animation="wave"
            />
            <Skeleton
              variant="rect"
              className={classes.inputSkeleton}
              animation="wave"
            />
          </Toolbar>
        </div>
      )}

      <div className={classes.wrapper}>
        <Paper className={classes.paper}>
          <Skeleton
            variant="rect"
            className={classes.cardSkeleton}
            animation="wave"
          />
        </Paper>
        <Paper className={classes.paper}>
          <Skeleton
            variant="rect"
            className={classes.cardSkeleton}
            animation="wave"
          />
        </Paper>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  toolbarWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  cardSkeleton: {
    margin: theme.spacing(2),
    height: "350px",
  },
  inputSkeleton: {
    height: 50,
    width: 200,
    marginRight: theme.spacing(10),
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));
