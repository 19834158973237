import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { cloneElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReportService } from "../../api/ServiceContext";
import DashboardGrid from "../../components/Dashboard/DashboardGrid";
import { WidgetList } from "../../components/Dashboard/WidgetList";
import { useIsMobileSize } from "../../globalHooks/responsiveHooks";
import { useSelectedMachine } from "../../store/GlobalContext";
import { ConditionalWrapper } from "../../utility/ConditionalWrapper";
import { t } from "../../utility/TranslateUtility";
import SplashPage from "../Dashboard/SplashPage";
import { useDispatchAlert } from "../UI/Alert";
import RemoveableWidgetWrapper from "./RemoveableWidgetWrapper";
import WidgetDrawer from "./WidgetDrawer";

export type UpdateDashboardCommand = {
  dashboardWidgets: Array<DashboardWidget>;
  machineId: MachineId;
  email: string;
};

export type DashboardWidget = {
  widgetName: string;
  positionX: number;
  positionY: number;
};

export default function RealtimeDashboard({ dashboardWidgets }: any) {
  const classes = useStyles();
  const reportService = useReportService();
  const machineId = useSelectedMachine().machineId;
  const { user } = useAuth0();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEmptyDashboard, setIsEmptyDashboard] = useState(
    dashboardWidgets.length === 0
  );
  const dispatchAlert = useDispatchAlert();

  const [layouts, setLayouts] = useState({
    lg: [],
  } as any);

  useEffect(() => {
    const newLayout = [];

    WidgetList.forEach((widget) => {
      widget.usedInDashboard = false;
    });

    for (const dashboardWidget of dashboardWidgets) {
      let foundWidget = WidgetList.find(
        (w) => w.widgetName === dashboardWidget.widgetName
      );

      if (!foundWidget) {
        console.error("Widget not found!");
        dispatchAlert({
          message: t("generic.errorMessage"),
          messageType: "error",
        });
        return;
      }

      foundWidget.usedInDashboard = true;

      newLayout.push({
        i: foundWidget.widgetName,
        x: dashboardWidget.positionX,
        y: dashboardWidget.positionY,
        w: foundWidget.width,
        h: foundWidget.height,
        component: foundWidget.component,
      });
    }

    setLayouts({ lg: newLayout });
  }, [dashboardWidgets]);

  const handleItemSelected = (item: any) => {
    setLayouts((state: any) => {
      const newLayouts = [
        ...state.lg,
        {
          i: item.widgetName,
          x: 0,
          y: Infinity,
          w: item.width,
          h: item.height,
          component: item.component,
        },
      ];
      return { lg: newLayouts };
    });
  };

  const handleWidgetRemove = (index: number, widgetName: string) => {
    let tempList = [...layouts.lg];
    if (index > -1) {
      tempList.splice(index, 1);
    }
    setLayouts({ lg: tempList });

    const widgetIndex = WidgetList.findIndex(
      (w) => w.widgetName === widgetName
    );

    if (widgetIndex < 0) return;

    WidgetList[widgetIndex].usedInDashboard = false;
  };

  const onLayoutChange = (newLayouts: any) => {
    if (!isEditMode) return;
    let layoutsCopy = layouts;

    for (let newLayout of newLayouts) {
      for (let currentLayout of layoutsCopy.lg) {
        if (currentLayout.i === newLayout.i) {
          currentLayout.x = newLayout.x;
          currentLayout.y = newLayout.y;
        }
      }
    }

    setLayouts(layoutsCopy);
  };

  const renderRemoveableWidgetWrapper = (
    index: number,
    layoutItem: any,
    wrappedChildren: any
  ) => {
    const result = (
      <RemoveableWidgetWrapper
        removeCallback={() => handleWidgetRemove(index, layoutItem.i)}
      >
        {wrappedChildren}
      </RemoveableWidgetWrapper>
    );
    return result;
  };

  const renderSelectedWidgets = () => {
    const result = layouts.lg.map((layoutItem: any, index: any) => (
      <div key={layoutItem.i}>
        <ConditionalWrapper
          condition={isEditMode}
          wrapper={(wrappedChildren: any) =>
            renderRemoveableWidgetWrapper(index, layoutItem, wrappedChildren)
          }
        >
          {cloneElement(layoutItem.component, {
            machineId: machineId,
            key: layoutItem,
          })}
        </ConditionalWrapper>
      </div>
    ));

    return result;
  };

  const handleActionbarClick = (value: boolean) => {
    setIsEditMode(value);

    if (machineId === 0 || user.email === "") return;

    const updateDashboardCommand: UpdateDashboardCommand = {
      machineId: machineId,
      email: user.email,
      dashboardWidgets: Array<DashboardWidget>(),
    };

    for (const layoutItem of layouts.lg) {
      updateDashboardCommand.dashboardWidgets.push({
        widgetName: layoutItem.i,
        positionX: layoutItem.x,
        positionY: layoutItem.y,
      });
    }

    reportService.putDashboard(updateDashboardCommand);
    setIsEmptyDashboard(updateDashboardCommand.dashboardWidgets.length === 0);
  };

  return (
    <>
      {isEmptyDashboard && !isEditMode ? (
        <SplashPage onActionButtonClick={() => setIsEditMode(true)} />
      ) : (
        <>
          {isEditMode && (
            <WidgetDrawer
              widgetList={WidgetList}
              onItemSelect={handleItemSelected}
            />
          )}
          <ActionBar
            isEditMode={isEditMode}
            onEditModeClick={(value: boolean) => handleActionbarClick(value)}
          />
          <div className={classes.dashboardGrid}>
            <DashboardGrid
              layouts={layouts}
              onLayoutChange={(layout: any) => onLayoutChange(layout)}
              lockGrid={isEditMode}
            >
              {renderSelectedWidgets()}
            </DashboardGrid>
          </div>
        </>
      )}
    </>
  );
}

function ActionBar({ isEditMode, onEditModeClick }: any) {
  const classes = useStyles();
  const { t } = useTranslation();

  const isMobileSize = useIsMobileSize();
  const editIconShown = isEditMode ? <></> : <EditIcon />;

  const actionBarLabel = isEditMode
    ? t("dashboardPage.realTimeDashboard.actionBar.labelEdit")
    : t("dashboardPage.realTimeDashboard.actionBar.labelView");

  const actionBarButtonLabel = isEditMode
    ? t("dashboardPage.realTimeDashboard.actionBar.buttonLabelEdit")
    : t("dashboardPage.realTimeDashboard.actionBar.buttonLabelView");

  return (
    <div className={classes.actionbar}>
      <Typography variant="body1" className={classes.editModeLabel}>
        {actionBarLabel}
      </Typography>
      {isMobileSize ? (
        t("dashboardPage.realTimeDashboard.actionBar.editOnlyOnDesktop")
      ) : (
        <Button
          variant={"contained"}
          color="primary"
          onClick={() => onEditModeClick(!isEditMode)}
          startIcon={editIconShown}
        >
          {actionBarButtonLabel}
        </Button>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  actionbar: {
    backgroundColor: "white",
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  editModeLabel: {
    marginRight: theme.spacing(2),
  },
  dashboardGrid: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
  },
  layout: {
    rowHeight: 160,
    [theme.breakpoints.down("md")]: {
      rowHeight: 80,
    },
  },
}));
