import { KpiMeasureType, ValueType } from "./Types";

export enum ChartVisualization {
  bar,
  area,
}

export const Mappings: Array<ChartMapping> = [
  {
    performanceProChart: KpiMeasureType.TotalEggs,
    valueType: "Count",
    headerTitle: "performanceViews.totalEggs.cardHeader",
    tooltipUnit: "performanceViews.totalEggs.tooltipUnit",
    tooltipYLabel: "performanceViews.totalEggs.tooltipMeasure",
    yAxisLabel: "performanceViews.totalEggs.yAxisLabel",
    yAxisUnit: "performanceViews.totalEggs.tooltipUnit",
    chartDayVisualization: ChartVisualization.bar,
    useEggUnit: true,
  },
  {
    performanceProChart: KpiMeasureType.ProductionTime,
    valueType: "Time",
    headerTitle: "performanceViews.productionTime.cardHeader",
    tooltipUnit: "",
    tooltipYLabel: "performanceViews.productionTime.tooltipMeasure",
    yAxisLabel: "performanceViews.productionTime.yAxisLabel",
    yAxisUnit: "performanceViews.productionTime.tooltipUnit",
    chartDayVisualization: ChartVisualization.area,
    useEggUnit: false,
  },
  {
    performanceProChart: KpiMeasureType.Throughput,
    valueType: "ValueOverTime",
    headerTitle: "performanceViews.throughput.cardHeader",
    tooltipUnit: "performanceViews.throughput.tooltipUnit",
    tooltipYLabel: "performanceViews.throughput.tooltipMeasure",
    yAxisLabel: "performanceViews.throughput.yAxisLabel",
    yAxisUnit: "performanceViews.throughput.tooltipUnit",
    chartDayVisualization: ChartVisualization.bar,
    useEggUnit: true,
  },
  {
    performanceProChart: KpiMeasureType.InputOffgrades,
    valueType: "Percentage",
    headerTitle: "qualityViews.inputOffgrade.cardHeader",
    tooltipUnit: "qualityViews.inputOffgrade.tooltipUnit",
    tooltipYLabel: "qualityViews.inputOffgrade.tooltipMeasure",
    yAxisLabel: "qualityViews.inputOffgrade.yAxisLabel",
    yAxisUnit: "qualityViews.inputOffgrade.tooltipUnit",
    chartDayVisualization: ChartVisualization.area,
    useEggUnit: false,
  },
  {
    performanceProChart: KpiMeasureType.ProductionAvailability,
    valueType: "Percentage",
    headerTitle: "performanceViews.productionAvailability.cardHeader",
    tooltipUnit: "performanceViews.productionAvailability.subtitleUnit",
    tooltipYLabel: "performanceViews.productionAvailability.tooltipMeasure",
    yAxisLabel: "performanceViews.productionAvailability.yAxisLabel",
    yAxisUnit: "performanceViews.productionAvailability.tooltipUnit",
    chartDayVisualization: ChartVisualization.bar,
    useEggUnit: false,
  },
  {
    performanceProChart: KpiMeasureType.FillRate,
    valueType: "Percentage",
    headerTitle: "performanceViews.fillRateProduction.cardHeader",
    tooltipUnit: "performanceViews.fillRateProduction.tooltipUnit",
    tooltipYLabel: "performanceViews.fillRateProduction.tooltipMeasure",
    yAxisLabel: "performanceViews.fillRateProduction.yAxisLabel",
    yAxisUnit: "performanceViews.fillRateProduction.tooltipUnit",
    chartDayVisualization: ChartVisualization.bar,
    useEggUnit: false,
  },
  {
    performanceProChart: KpiMeasureType.TableEggs,
    valueType: "Percentage",
    headerTitle: "qualityViews.tableEggs.cardHeader",
    tooltipUnit: "qualityViews.tableEggs.tooltipUnit",
    tooltipYLabel: "qualityViews.tableEggs.tooltipMeasure",
    yAxisLabel: "qualityViews.tableEggs.yAxisLabel",
    yAxisUnit: "qualityViews.tableEggs.tooltipUnit",
    chartDayVisualization: ChartVisualization.area,
    useEggUnit: false,
  },
  {
    performanceProChart: KpiMeasureType.InputOffgrades,
    valueType: "Percentage",
    headerTitle: "qualityViews.inputOffgrade.cardHeader",
    tooltipUnit: "qualityViews.inputOffgrade.tooltipUnit",
    tooltipYLabel: "qualityViews.inputOffgrade.tooltipMeasure",
    yAxisLabel: "qualityViews.inputOffgrade.yAxisLabel",
    yAxisUnit: "qualityViews.inputOffgrade.tooltipUnit",
    chartDayVisualization: ChartVisualization.area,
    useEggUnit: false,
  },
  {
    performanceProChart: KpiMeasureType.Outputoffgrades,
    valueType: "Percentage",
    headerTitle: "qualityViews.outputOffgrade.cardHeader",
    tooltipUnit: "qualityViews.outputOffgrade.tooltipUnit",
    tooltipYLabel: "qualityViews.outputOffgrade.tooltipMeasure",
    yAxisLabel: "qualityViews.outputOffgrade.yAxisLabel",
    yAxisUnit: "qualityViews.outputOffgrade.tooltipUnit",
    chartDayVisualization: ChartVisualization.area,
    useEggUnit: false,
  },
  {
    performanceProChart: KpiMeasureType.Upgrades,
    valueType: "Percentage",
    headerTitle: "qualityViews.upgrade.cardHeader",
    tooltipUnit: "qualityViews.upgrade.tooltipUnit",
    tooltipYLabel: "qualityViews.upgrade.tooltipMeasure",
    yAxisLabel: "qualityViews.upgrade.yAxisLabel",
    yAxisUnit: "qualityViews.upgrade.tooltipUnit",
    chartDayVisualization: ChartVisualization.area,
    useEggUnit: false,
  },
  {
    performanceProChart: KpiMeasureType.Bypass,
    valueType: "Percentage",
    headerTitle: "qualityViews.bypass.cardHeader",
    tooltipUnit: "qualityViews.bypass.tooltipUnit",
    tooltipYLabel: "qualityViews.bypass.tooltipMeasure",
    yAxisLabel: "qualityViews.bypass.yAxisLabel",
    yAxisUnit: "qualityViews.bypass.tooltipUnit",
    chartDayVisualization: ChartVisualization.area,
    useEggUnit: false,
  },
  {
    performanceProChart: KpiMeasureType.BucketEggs,
    valueType: "Percentage",
    headerTitle: "qualityViews.bucketEggs.cardHeader",
    tooltipUnit: "qualityViews.bucketEggs.tooltipUnit",
    tooltipYLabel: "qualityViews.bucketEggs.tooltipMeasure",
    yAxisLabel: "qualityViews.bucketEggs.yAxisLabel",
    yAxisUnit: "qualityViews.bucketEggs.tooltipUnit",
    chartDayVisualization: ChartVisualization.area,
    useEggUnit: false,
  },
];

export type ChartMapping = {
  performanceProChart: KpiMeasureType;
  valueType: ValueType;
  headerTitle: string;
  tooltipUnit: string;
  tooltipYLabel: string;
  yAxisLabel: string;
  yAxisUnit: string;
  chartDayVisualization: ChartVisualization;
  useEggUnit: boolean;
};
