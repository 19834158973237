import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function ComparedMachineCountSection({
  comparedToCount,
  comparedCountToLow,
  awaitingResponse,
}: {
  comparedToCount: number | null;
  comparedCountToLow: boolean;
  awaitingResponse: boolean;
}) {
  const { t } = useTranslation();

  const color = useMemo(() => {
    return comparedCountToLow ? "error" : "inherit";
  }, [comparedCountToLow]);

  return awaitingResponse ? (
    <Skeleton variant="text" width={175} height={25} />
  ) : (
    <>
      <Typography variant="body2" color={color}>
        <b>
          {t("benchmark.filters.myMachinesComparedToCountLabel")}:{" "}
          {comparedToCount}
        </b>
      </Typography>
    </>
  );
}
