import React, { useMemo } from "react";
import {
  useBenchmarkMeasureDataState,
  useBenchmarkState,
} from "../../../store/GlobalContext";
import { allRadarMeasures } from "../MachineMeasureComparison/Types";
import MachineMeasureRadarGraphPresentation from "./MachineMeasureRadarGraphPresentation";

export default function MachineMeasureRadarGraphContainer() {
  const benchmarkMeasureDataState = useBenchmarkMeasureDataState();
  const benchmarkState = useBenchmarkState();

  const measuresToShow = useMemo(() => {
    return benchmarkState.selectedWidgets.filter((x) =>
      allRadarMeasures.includes(x)
    );
  }, [benchmarkState.selectedWidgets]);

  return (
    <MachineMeasureRadarGraphPresentation
      measureData={benchmarkMeasureDataState}
      measuresToShow={measuresToShow}
    />
  );
}
