import { Paper, TablePagination } from "@material-ui/core";
import Badge from "@material-ui/core/Badge/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, Color } from "@material-ui/lab";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";
import { EggGroupType, EggTypeMappingSettingState } from "../../../../apiModels/EggTypeMapping/EggTypeGroupApiModel";
import { colors } from "../../../../colors";
import MultilineText from "../../../UI/MultilineText";
import {
  StyledTableCell,
  StyledTableRow,
  useTableStyles
} from "../../../UI/Table/Table";
import { SaveButton } from "../../Common";
import EggTypeChips, { EggTypeGroupCount } from "../EggTypeChips";
import { EggTypeGroupSelect } from "../EggTypeGroupSelect";
import { InternalEggTypeGroupItem } from "./EggTypeGroupTableContainer";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

interface EggTypeGroupTablePresentation {
  rows: Array<InternalEggTypeGroupItem>;
  pendingChanges: boolean;
  onSaveClick: () => void;
  onRowEdit: (
    editedRow: InternalEggTypeGroupItem,
    eggTypeValue: number
  ) => void;
  onChipsClick: (selectedLabel: EggGroupType) => void;
  eggTypeFilter: EggGroupType | null;
  eggTypeGroupCount: EggTypeGroupCount[];
  isAwaitingSaveResponse: boolean;
  hasFailed: boolean;
  onErrorMessageClick: () => void;
  lastState: EggTypeMappingSettingState;
  lastUpdated: Date | null;
}

export default function EggTypeGroupTablePresentation({
  rows,
  pendingChanges,
  onSaveClick,
  onRowEdit,
  onChipsClick,
  eggTypeFilter,
  eggTypeGroupCount,
  isAwaitingSaveResponse,
  hasFailed,
  onErrorMessageClick,
  lastState,
  lastUpdated
}: EggTypeGroupTablePresentation) {
  const classes = useTableStyles();
  const alertClasses = useStyles();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let stateColor: Color | undefined;
  let stateMessage: string | undefined;
  switch (lastState) {
    case EggTypeMappingSettingState.ChangesPending:
      stateColor = "warning";
      stateMessage = "eggTypeGroupPage.changesArePending";
      break;
    case EggTypeMappingSettingState.FailedSave:
      stateColor = "error";
      stateMessage = "eggTypeGroupPage.lastSaveFailed";
      break;
    default:
      stateColor = undefined;
      stateMessage = undefined;
      break;
  }

  return (
    <>
      <Prompt
        when={pendingChanges}
        message={t("eggTypeGroupPage.promptMessage")}
      />
      {stateColor !== undefined && (
        <Alert
          severity={stateColor}
          className={alertClasses.alert}
        >
          <MultilineText text={stateMessage && t(stateMessage, { lastUpdated: lastUpdated })} />
        </Alert>
      )}
      {isAwaitingSaveResponse && (
        <Alert
          severity="info"
          className={alertClasses.alert}
          action={<CircularProgress size={25} />}
        >
          {t("eggTypeGroupPage.loadingMessage")}
        </Alert>
      )}
      {hasFailed && (
        <Alert
          severity="error"
          className={alertClasses.alert}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                onErrorMessageClick();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {t("eggTypeGroupPage.errorMessage")}
        </Alert>
      )}
      <div className={classes.actionWrapper}>
        <EggTypeChips
          value={eggTypeFilter}
          onClick={onChipsClick}
          typeCount={eggTypeGroupCount}
        />
        <SaveButton
          onClick={onSaveClick}
          disabled={!pendingChanges || isAwaitingSaveResponse}
        />
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="egg-group-table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {t("eggTypeGroupPage.table.customEggTypeName")}
              </StyledTableCell>
              <StyledTableCell align="left">
                {t("eggTypeGroupPage.table.eggTypeLabel")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableContent
              onRowEdit={onRowEdit}
              rows={rows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
            />
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

const useTableContentStyles = makeStyles((theme) => ({
  emptyMessage: {
    textAlign: "center",
  },
}));

interface TableContentProps {
  rows: Array<InternalEggTypeGroupItem>;
  onRowEdit: (
    editedRow: InternalEggTypeGroupItem,
    eggTypeValue: number
  ) => void;
}

function TableContent({ rows, onRowEdit }: TableContentProps) {
  const classes = useTableContentStyles();
  const { t } = useTranslation();

  return (
    <>
      {rows.length === 0 ? (
        <StyledTableRow>
          <StyledTableCell
            component="th"
            scope="row"
            colSpan={2}
            className={classes.emptyMessage}
          >
            {t("eggTypeGroupPage.table.emptyMessage")}
          </StyledTableCell>
        </StyledTableRow>
      ) : (
        rows.map((eggTypeGroupRow) => (
          <StyledTableRow key={eggTypeGroupRow.machineEggTypeName}>
            <StyledTableCell component="th" scope="row">
              {eggTypeGroupRow.dirty && (
                <TableBadge eggTypeGroupLabel={eggTypeGroupRow.eggType} />
              )}
              {eggTypeGroupRow.machineEggTypeName}
            </StyledTableCell>
            <StyledTableCell align="left">
              <EggTypeGroupSelect
                selectedEggType={eggTypeGroupRow.eggType}
                onEggTypeChange={(value) => onRowEdit(eggTypeGroupRow, value)}
              />
            </StyledTableCell>
          </StyledTableRow>
        ))
      )}
    </>
  );
}

const useBadgeClasses = makeStyles((theme) => ({
  badge: {
    marginRight: theme.spacing(2),
  },
  colorBadge: {
    backgroundColor: (props: EggGroupType) => determineColor(props),
  },
}));

export function determineColor(eggTypeGroupLabel: EggGroupType): string {
  switch (eggTypeGroupLabel) {
    case EggGroupType.Cage:
      return colors.cageEgg;
    case EggGroupType.Barn:
      return colors.barnEgg;
    case EggGroupType.FreeRange:
      return colors.freeRangeEgg;
    case EggGroupType.Organic:
      return colors.organicEgg;
    case EggGroupType.Unmapped:
      return colors.unmappedEgg;
    default:
      return colors.mobaBlue;
  }
}

interface TableBadgeProps {
  eggTypeGroupLabel: EggGroupType;
}

function TableBadge({ eggTypeGroupLabel: eggTypeLabel }: TableBadgeProps) {
  const classes = useBadgeClasses(eggTypeLabel);
  return (
    <Badge
      color="primary"
      variant="dot"
      className={classes.badge}
      classes={{ colorPrimary: classes.colorBadge }}
    />
  );
}
