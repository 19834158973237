import {
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { t } from "../../../utility/TranslateUtility";

export interface UserListSearchProps {
  searchString: string;
  setSearchString: (searchString: string) => void;
}

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(30),
    margin: theme.spacing(2),
    marginLeft: 0,
  },
}));

export function UserListSearch({
  searchString,
  setSearchString,
}: UserListSearchProps) {
  const classes = useStyles();

  return (
    <div className={classes.searchContainer}>
      <TextField
        value={searchString}
        onChange={(event) => setSearchString(event.target.value)}
        variant="outlined"
        placeholder={t("users.search.placeholder")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography variant="caption">{t("users.search.description")}</Typography>
    </div>
  );
}
