import React from "react";
import { usePerformanceProFilterOptions } from "../../../store/GlobalContext";
import { useTargetSettings } from "../../Settings/Targets/hooks";
import { usePerformanceGroupMeasure } from "../Grouped/performanceProGroupHooks";
import { KpiMeasureGroupType } from "../Types";
import { SupplierDetailLineChart } from "./SupplierDetailLineChart";

export function SupplierDetailLineChartView({
  measure,
  supplierNameShed,
}: {
  measure: KpiMeasureGroupType;
  supplierNameShed?: string;
}) {
  const { units } = useTargetSettings();

  const options = usePerformanceProFilterOptions();

  const { data, chartMapping, awaitingResponse } = usePerformanceGroupMeasure(
    measure
  );
  const totalEggData = usePerformanceGroupMeasure(
    KpiMeasureGroupType.TotalByDatePeriodForSupplier
  ).data;

  return (
    <SupplierDetailLineChart
      options={options}
      data={data}
      totalEggData={totalEggData}
      chartMapping={{ ...chartMapping, supplierNameShed: supplierNameShed }}
      eggUnit={units.eggUnit}
      measure={measure}
      awaitingResponse={awaitingResponse}
    />
  );
}
