import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PerformanceProSparklineView from "../../components/PerformancePro/PerformanceProSparklineViewContainer";
import { KpiMeasureType } from "../../components/PerformancePro/Types";
import {
  usePerformanceTargets,
  useRequestPerformanceProOverviewData,
} from "../../components/PerformancePro/performanceProHooks";
import {
  FeaturePolicyItems,
  Features,
  useAuthorizationScope,
} from "../../globalHooks/authorizationHooks";
import { PolicyFeatureWrapper } from "../../globalHooks/usePolicyFeatures";
import { useSelectedMachine } from "../../store/GlobalContext";
import { PerformanceProToolbarContainer } from "./PerformanceProToolbar";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  caption: {
    marginBottom: theme.spacing(2),
  },
  header: {
    fontWeight: "bold",
  },
  overviewSection: {
    padding: theme.spacing(2),
    "& ~ $overviewSection": {
      marginTop: theme.spacing(2),
    },
  },
}));

export function PerformanceProOverview() {
  const { t } = useTranslation();
  const classes = useStyles();
  const selectedMachineId = useSelectedMachine().machineId;
  const url = "/performance-pro";

  useRequestPerformanceProOverviewData();
  const targets = usePerformanceTargets(selectedMachineId);

  return (
    <>
      <PerformanceProToolbarContainer
        enableEggTypeSelect={true}
        enableDatePeriod={false}
      />
      <div className={classes.content}>
        <Paper className={classes.overviewSection}>
          <Typography variant="h5" className={classes.header}>
            {t("performanceProPage.production.header")}
          </Typography>
          <Typography variant="body2" className={classes.caption}>
            {t("performanceProPage.production.caption")}
          </Typography>

          <Grid container>
            <Grid item xs={12} md={3}>
              <div className={classes.content}>
                <SparklineWrapper to={`${url}/production/total-eggs`}>
                  <PerformanceProSparklineView
                    machineId={selectedMachineId}
                    measure={KpiMeasureType.TotalEggs}
                    targets={targets}
                  />
                </SparklineWrapper>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.content}>
                <SparklineWrapper
                  to={`${url}/production/production-availability`}
                >
                  <PerformanceProSparklineView
                    machineId={selectedMachineId}
                    measure={KpiMeasureType.ProductionAvailability}
                    targets={targets}
                  />
                </SparklineWrapper>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.content}>
                <SparklineWrapper to={`${url}/production/production-time`}>
                  <PerformanceProSparklineView
                    machineId={selectedMachineId}
                    measure={KpiMeasureType.ProductionTime}
                    targets={targets}
                  />
                </SparklineWrapper>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.content}>
                <SparklineWrapper to={`${url}/production/throughput`}>
                  <PerformanceProSparklineView
                    machineId={selectedMachineId}
                    measure={KpiMeasureType.Throughput}
                    targets={targets}
                  />
                </SparklineWrapper>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.content}>
                <SparklineWrapper to={`${url}/production/fill-rate`}>
                  <PerformanceProSparklineView
                    machineId={selectedMachineId}
                    measure={KpiMeasureType.FillRate}
                    targets={targets}
                  />
                </SparklineWrapper>
              </div>
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.overviewSection}>
          <Typography variant="h5" className={classes.header}>
            {t("performanceProPage.quality.header")}
          </Typography>
          <Typography variant="body2" className={classes.caption}>
            {t("performanceProPage.quality.caption")}
          </Typography>

          <Grid container>
            <Grid item xs={12} md={3}>
              <div className={classes.content}>
                <SparklineWrapper to={`${url}/quality/table-eggs`}>
                  <PerformanceProSparklineView
                    machineId={selectedMachineId}
                    measure={KpiMeasureType.TableEggs}
                    targets={targets}
                  />
                </SparklineWrapper>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.content}>
                <SparklineWrapper to={`${url}/quality/input-offgrades`}>
                  <PerformanceProSparklineView
                    machineId={selectedMachineId}
                    measure={KpiMeasureType.InputOffgrades}
                    targets={targets}
                  />
                </SparklineWrapper>
              </div>
            </Grid>
            <PolicyFeatureWrapper
              policy={FeaturePolicyItems.EnableQualityUpgrades}
            >
              <Grid item xs={12} md={3}>
                <div className={classes.content}>
                  <SparklineWrapper to={`${url}/quality/upgrades`}>
                    <PerformanceProSparklineView
                      machineId={selectedMachineId}
                      measure={KpiMeasureType.Upgrades}
                      targets={targets}
                    />
                  </SparklineWrapper>
                </div>
              </Grid>
            </PolicyFeatureWrapper>
            <Grid item xs={12} md={3}>
              <div className={classes.content}>
                <SparklineWrapper to={`${url}/quality/output-offgrades`}>
                  <PerformanceProSparklineView
                    machineId={selectedMachineId}
                    measure={KpiMeasureType.Outputoffgrades}
                    targets={targets}
                  />
                </SparklineWrapper>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.content}>
                <SparklineWrapper to={`${url}/quality/bypass`}>
                  <PerformanceProSparklineView
                    machineId={selectedMachineId}
                    measure={KpiMeasureType.Bypass}
                    targets={targets}
                  />
                </SparklineWrapper>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.content}>
                <SparklineWrapper to={`${url}/quality/bucket-eggs`}>
                  <PerformanceProSparklineView
                    machineId={selectedMachineId}
                    measure={KpiMeasureType.BucketEggs}
                    targets={targets}
                  />
                </SparklineWrapper>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
}

function SparklineWrapper({ children, to }: { children: any; to: string }) {
  const authorizationScope = useAuthorizationScope();

  return (
    <>
      {authorizationScope.hasAuthorizationForSelectedMachine(
        Features.PerformancePro
      ) ? (
        <Link to={to}>{children}</Link>
      ) : (
        children
      )}
    </>
  );
}
