import HC_patternFill from "highcharts-pattern-fill";
import Highcharts from "highcharts/highstock";
import { useMemo, useState } from "react";
import { laneColors } from "../../../colors";
import {
  EggUnit,
  GetEggUnitTranslation,
} from "../../../utility/EggUnitUtility";
import { createUnitsConverter } from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import {
  EggUnitMenu,
  EggUnitSelectionButton,
} from "../Wrappers/EggDisplayUnitCardWrapper";
import HighchartsFullscreenWrapper, {
  RenderPosition,
  ScreenState,
} from "../Wrappers/HighchartsFullscreenWrapper";
import buildTooltipFormatter from "./LaneProductsTooltipBuilder";
import {
  LaneProductsView,
  LaneSeriesData,
  LaneType,
  MapProductsResponse,
  PackingLane,
  PackingLaneGrade,
  laneNumberToType,
} from "./LaneProductsTypes";

HC_patternFill(Highcharts);

const labels: any = t("widgets.laneProducts", { returnObjects: true });

const laneChartSettings = {
  defs: {
    patterns: [
      {
        id: "remaining-target",
        path: {
          d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
          stroke: "lightgrey",
          strokeWidth: 3,
        },
      },
    ],
  },
  chart: {
    type: "column",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: null,
  },
  plotOptions: {
    column: {
      stacking: "normal",
    },
  },
  tooltip: buildTooltipFormatter({
    EnableRemainingLaneProductTargetsGraph: true,
  }),
  yAxis: {
    title: {
      text: labels.yAxisTitle,
    },
  },
  xAxis: {
    title: {
      text: labels.xAxisTitle,
    },
    tickPosition: "inside",
    categories: [],
    labels: {
      formatter: function (this: {
        isFirst: boolean;
        isLast: boolean;
        value: number;
      }) {
        if (this.isFirst) return labels.outlet;
        if (this.isLast) return labels.end;
        return this.value;
      },
    },
  },
  series: [],
};

function createChartSettings(
  view: LaneProductsView,
  unit: EggUnit,
  mapProducts: (
    productLanes: PackingLane[],
    unit: EggUnit
  ) => MapProductsResponse,
  isRemainderEnabled: boolean
): any {
  return {
    ...laneChartSettings,
    yAxis: {
      ...laneChartSettings.yAxis,
      title: {
        ...laneChartSettings.yAxis.title,
        text:
          "Number of " + GetEggUnitTranslation(unit.toString()).toLowerCase(),
      },
    },
    tooltip: buildTooltipFormatter({
      EnableRemainingLaneProductTargetsGraph: isRemainderEnabled,
    }),
    series: mapToChartSeries(view, unit, mapProducts),
  };
}

function convertPackingLaneUnits(
  lanes: PackingLane[],
  unit: EggUnit
): PackingLane[] {
  const inUnits = createUnitsConverter(unit);
  return lanes.map<PackingLane>((p) => ({
    ...p,
    laneOffgrades: inUnits(p.laneOffgrades),
    laneTotal: inUnits(p.laneTotal),
    productTarget: inUnits(p.productTarget),
    productTotal: inUnits(p.productTotal),
    remainingTarget: inUnits(p.remainingTarget),
    packingLaneGrades: p.packingLaneGrades.map<PackingLaneGrade>((g) => ({
      ...g,
      count: inUnits(g.count),
    })),
  }));
}

function mapToChartSeries(
  view: LaneProductsView,
  unit: EggUnit,
  mapProducts: (
    productLanes: PackingLane[],
    unit: EggUnit
  ) => MapProductsResponse
): any[] {
  const packingLanes = convertPackingLaneUnits(view.packingLanes, unit);
  const productLanes = packingLanes.filter(
    (l) =>
      l.packingLaneNumber !== LaneType.outlet &&
      l.packingLaneNumber !== LaneType.end
  );

  // remaining target
  const remainingTargetSeries = {
    name: "Remaining target",
    color: "url(#remaining-target)",
    showInLegend: false,
    data: productLanes.map<LaneSeriesData>((l) => ({
      x: l.packingLaneNumber,
      y: l.remainingTarget,
      name: l.productName,
      custom: {
        lane: l,
        laneType: laneNumberToType(l.packingLaneNumber),
        units: unit,
      },
    })),
  };

  // offgrades
  const offgradesSeries = {
    name: "Offgrades",
    color: "#843C0B",
    showInLegend: false,
    data: productLanes.map<LaneSeriesData>((l) => ({
      x: l.packingLaneNumber,
      y: l.laneOffgrades,
      name: l.productName,
      custom: {
        lane: l,
        laneType: laneNumberToType(l.packingLaneNumber),
        units: unit,
      },
    })),
  };

  const productsSeries = mapProducts(productLanes, unit);

  // outlet
  const outletLane = packingLanes.find(
    (l) => l.packingLaneNumber === LaneType.outlet
  );
  const outletLaneSeries = {
    name: labels.outlet,
    color: laneColors.outlet,
    showInLegend: false,
    data: [
      {
        x: 0,
        y: outletLane?.laneTotal,
        custom: {
          lane: outletLane,
          laneType: LaneType.outlet,
          units: unit,
        },
      },
    ],
  };
  // end
  const endLane = packingLanes.find(
    (l) => l.packingLaneNumber === LaneType.end
  );
  const endLaneSeries = {
    name: labels.end,
    showInLegend: false,
    data: [
      {
        x: packingLanes.length - 1,
        y: endLane?.laneTotal,
        color: laneColors.end,
        custom: {
          lane: endLane,
          laneType: LaneType.end,
          units: unit,
        },
      },
    ],
  };

  return [
    outletLaneSeries,
    remainingTargetSeries,
    offgradesSeries,
    productsSeries,
    endLaneSeries,
  ];
}

export default function LaneProductsChart({
  cardTitle,
  data,
  isAwaitingResponse,
  units,
  onUnitsChange,
  mapProducts,
  isRemainderEnabled,
}: {
  cardTitle: string;
  data: LaneProductsView;
  isAwaitingResponse: boolean;
  units: EggUnit;
  onUnitsChange: (units: EggUnit) => void;
  mapProducts: (
    productLanes: PackingLane[],
    unit: EggUnit
  ) => MapProductsResponse;
  isRemainderEnabled: boolean;
}) {
  const newOptions = useMemo(() => {
    if (isAwaitingResponse || !data) return null;

    return createChartSettings(data, units, mapProducts, isRemainderEnabled);
  }, [isAwaitingResponse, data, units, mapProducts, isRemainderEnabled]);

  const [unitSelectionVisible, setUnitSelectionVisible] = useState(true);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleMenuClick = (eggUnit: string) => {
    switch (eggUnit) {
      case "Eggs":
        onUnitsChange(EggUnit.Eggs);
        break;
      case "Cases":
        onUnitsChange(EggUnit.Cases);
        break;
      case "Dozens":
        onUnitsChange(EggUnit.Dozens);
        break;
      default:
        onUnitsChange(EggUnit.Eggs);
        break;
    }
  };

  const eggSelectionButton = unitSelectionVisible ? (
    <EggUnitSelectionButton
      onClick={(event) => setAnchorEl(event.currentTarget)}
    />
  ) : undefined;

  const eggUnitSelectionMenu = (
    <EggUnitMenu
      anchorElement={anchorEl}
      onClose={() => setAnchorEl(null)}
      onMenuItemClicked={handleMenuClick}
      defaultEggUnit={"Eggs"}
    />
  );

  return (
    <HighchartsFullscreenWrapper
      title={cardTitle}
      awaitingResponse={isAwaitingResponse}
      highcharts={Highcharts}
      chartOptions={newOptions}
      cardAction={eggSelectionButton}
      customJsx={eggUnitSelectionMenu}
      renderPosition={RenderPosition.Top}
      onFullscreenChange={(state: ScreenState) =>
        setUnitSelectionVisible(state === ScreenState.Default)
      }
    />
  );
}
