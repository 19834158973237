import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { FormatSelectedEggUnit } from "../../../utility/NumberFormatUtility";
import EggDisplayUnitCardWrapper from "../Wrappers/EggDisplayUnitCardWrapper";
import { FullscreenVariant } from "../Wrappers/FullscreenCardWrapper";

export type TotalEggsWidgetPresentationProps = {
  awaitingResponse: boolean;
  calculatedNumberOfEggs: number;
  displayUnit: string;
  onMenuItemClicked: (eggUnit: string) => void;
};

export type TotalEggsApiResponse = {
  data: TotalEggsApiData;
};

export type TotalEggsApiData = {
  numberOfEggs: number;
};

export default function TotalEggsWidgetPresentation({
  awaitingResponse,
  calculatedNumberOfEggs,
  displayUnit,
  onMenuItemClicked,
}: TotalEggsWidgetPresentationProps) {
  const { t } = useTranslation();

  const isMobileSize = useIsMobileSize();
  const headingVariant = isMobileSize ? "h5" : "h4";

  return (
    <EggDisplayUnitCardWrapper
      title={t("widgets.totalEggs.title")}
      awaitingResponse={awaitingResponse}
      onMenuItemClicked={onMenuItemClicked}
      fullscreenVariant={FullscreenVariant.Kpi}
    >
      <Typography variant={headingVariant} id={"totalNumberOfEggs"}>
        {FormatSelectedEggUnit(displayUnit, calculatedNumberOfEggs)} {t(displayUnit).toLowerCase()}
      </Typography>
    </EggDisplayUnitCardWrapper>
  );
}
