import { makeStyles } from "@material-ui/core";
import utcToZonedTime from "date-fns-tz/utcToZonedTime/index.js";
import { useState } from "react";
import { useLocalAlarmService } from "../../api/ServiceContext";
import AddOrUpdateAlarmDialog from "../../components/Alarms/AddOrUpdateAlarm/AddOrUpdateAlarmDialogContainer";
import {
  emptyAlarm,
  IAlarm,
} from "../../components/Alarms/AddOrUpdateAlarm/Common";
import AlarmListContainer from "../../components/Alarms/AlarmList/AlarmListContainer";
import { SettingsCard } from "../../components/Settings/Common";
import { useTargetSettings } from "../../components/Settings/Targets/hooks";
import { t } from "../../utility/TranslateUtility";

export type AlarmsWidget = {};

export type AlarmsLayoutData = {
  alarmsWidgets: Array<AlarmsWidget>;
};

export type AlarmsLayoutResponse = {
  data: AlarmsLayoutResponse;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3),
  },
  button: {
    float: "right",
    [theme.breakpoints.down("xs")]: {
      float: "none",
    },
  },
}));

function AlarmsPage() {
  const classes = useStyles();
  const localAlarmService = useLocalAlarmService();
  const targets = useTargetSettings();

  const [showAddUpdateAlarmDialog, setShowAddUpdateAlarmDialog] =
    useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isListUpToDate, setIsListUpToDate] = useState<boolean>(false);

  const onAddClick = () => {
    setIsUpdating(false);
    setShowAddUpdateAlarmDialog(true);
    localAlarmService.startAddingOrUpdating(emptyAlarm, undefined);
  };

  const openDialog = (selectedAlarm: IAlarm) => {
    setIsUpdating(true);
    setShowAddUpdateAlarmDialog(true);
    if (selectedAlarm.startTimeUtc !== undefined) {
      selectedAlarm.startTimeUtc = utcToZonedTime(
        selectedAlarm.startTimeUtc,
        targets.units.timezone
      );
    }
    selectedAlarm.endTimeUtc = utcToZonedTime(
      selectedAlarm.endTimeUtc,
      targets.units.timezone
    );
    localAlarmService.startAddingOrUpdating(selectedAlarm, undefined);
  };

  return (
    <div className={classes.wrapper}>
      <SettingsCard title={t("alarmManagement.linkTitle")}>
        {showAddUpdateAlarmDialog && (
          <AddOrUpdateAlarmDialog
            timeZone={targets.units.timezone}
            disableTopic={isUpdating}
            isUpdating={isUpdating}
            onListUpdated={(isUpdated: boolean) =>
              setIsListUpToDate(!isUpdated)
            }
            closeDialog={() => setShowAddUpdateAlarmDialog(false)}
          />
        )}

        <AlarmListContainer
          timeZone={targets.units.timezone}
          onAddClick={onAddClick}
          isListUpToDate={isListUpToDate}
          onListUpdated={(isUpdated: boolean) => setIsListUpToDate(!isUpdated)}
          openDialog={openDialog}
        />
      </SettingsCard>
    </div>
  );
}

export default AlarmsPage;
