import { addDays, addHours, isWeekend } from "date-fns";
import { useMemo } from "react";
import { colors } from "../../colors";
import {
  IPerformanceProFilterOptions,
  usePerformanceProFilterOptionsActions,
} from "../../store/GlobalContext";
import { DatePeriod, getMaxDate, getMinDate } from "../../utility/DateUtility";
import {
  EggUnit,
  GetEggUnitTranslation,
  useUnitLabel,
} from "../../utility/EggUnitUtility";
import { createUnitsConverter } from "../../utility/EggUtility";
import { t } from "../../utility/TranslateUtility";
import {
  BarChartSettingsHour,
  defaultExportButtonSettings,
  defaultLegendSettings,
  defaultNavigationSettings,
} from "../Charts/ChartSettings/BarChartSettings";
import PerformanceProBaseChart from "../Charts/PerformanceProBaseChart";
import { ChartMapping, ChartVisualization } from "./ChartMapping";
import { PerformanceProTooltipBuilder } from "./PerformanceProTooltipBuilder";
import { KpiMeasureType, PerformancePeriod } from "./Types";

export function ChartSettings(options: IPerformanceProFilterOptions) {
  return {
    ...BarChartSettingsHour,
    colors: [colors.performanceProChart],
    plotOptions: {
      column: {
        borderColor: colors.performanceProChartBorder,
        groupPadding: 0.1,
        pointPadding: 0.1,
        borderWidth: 1,
        pointPlacement:
          options.selectedDatePeriod === DatePeriod.Day ? "between" : undefined,
        grouping: false,
      },
    },
    ...defaultLegendSettings,
    ...defaultNavigationSettings,
    ...defaultExportButtonSettings,
  };
}

export function AreaChartSettings(options: IPerformanceProFilterOptions) {
  return {
    ...BarChartSettingsHour,
    colors: [colors.performanceProChart],
    plotOptions: {
      column: {
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
        pointPlacement:
          options.selectedDatePeriod === DatePeriod.Day ? "between" : undefined,
        grouping: false,
      },
    },
    ...defaultLegendSettings,
    ...defaultNavigationSettings,
    ...defaultExportButtonSettings,
  };
}

function getChartSettingsBase({
  options,
  chartMapping,
}: {
  options: IPerformanceProFilterOptions;
  chartMapping: ChartMapping;
}): any {
  return chartMapping.chartDayVisualization === ChartVisualization.area &&
    options.selectedDatePeriod === DatePeriod.Day
    ? AreaChartSettings(options)
    : ChartSettings(options);
}

export default function PeriodViewBarChart({
  periods,
  options,
  chartMapping,
  eggUnit,
  additionalData,
}: {
  periods: Array<PerformancePeriod>;
  options: IPerformanceProFilterOptions;
  chartMapping: ChartMapping;
  eggUnit: EggUnit | null;
  additionalData: any;
}) {
  if (chartMapping.useEggUnit && eggUnit === null) eggUnit = EggUnit.Eggs;
  const dispatch = usePerformanceProFilterOptionsActions();

  const xAxisPlotBands = useMemo(() => {
    if (
      options.selectedDatePeriod === DatePeriod.Month ||
      options.selectedDatePeriod === DatePeriod.Week
    ) {
      const dates = periods.map((x) => x.dateTime).filter((x) => isWeekend(x));
      const plotBands = dates.map((x) => ({
        from: addHours(x, -12),
        to: addHours(addDays(x, 1), -12),
        color: colors.greyVeryLight,
      }));
      return plotBands;
    }
    return null;
  }, [options, periods, additionalData]);

  const newChartSettings = useMemo(() => {
    const chartSettingsToUse = getChartSettingsBase({
      options: options,
      chartMapping: chartMapping,
    });

    return {
      ...chartSettingsToUse,
      chart: {
        ...chartSettingsToUse.chart,
        height: window.devicePixelRatio > 1.25 ? 275 : null,
      },
      xAxis: {
        ...chartSettingsToUse.xAxis,
        startOfWeek: 1, // monday
        dateTimeLabelFormats: {
          day:
            options.selectedDatePeriod === DatePeriod.Year
              ? "%b %y"
              : "%e %b %y",
          month: "%b %y",
        },
        plotBands: xAxisPlotBands,
      },
      plotOptions: {
        column: {
          ...chartSettingsToUse.plotOptions.column,
        },
        series: {
          cursor: "pointer",
          events: {
            click: function (event: any) {
              if (options.selectedDatePeriod === DatePeriod.Year) {
                dispatch({
                  ...options,
                  selectedDatePeriod: DatePeriod.Month,
                  selectedDate: event.point.category,
                });
              } else {
                dispatch({
                  ...options,
                  selectedDatePeriod: DatePeriod.Day,
                  selectedDate: event.point.category,
                });
              }
            },
          },
        },
      },
    };
  }, [dispatch, options, xAxisPlotBands, chartMapping.chartDayVisualization]);

  const { productionStart, productionEnd } = useMemo(() => {
    const dates = periods.map((p) => p.dateTime);
    return {
      productionStart: getMinDate(dates),
      productionEnd: getMaxDate(dates),
    };
  }, [periods]);

  const tooltipUnitLabel = useUnitLabel(chartMapping, eggUnit);

  const tooltipFormatter = useMemo(() => {
    return PerformanceProTooltipBuilder(
      options.selectedDatePeriod,
      chartMapping,
      tooltipUnitLabel,
      eggUnit!
    );
  }, [options.selectedDatePeriod, chartMapping, tooltipUnitLabel, eggUnit]);

  const isPercentageBased = (measureType: KpiMeasureType) => () => {
    return (
      measureType == KpiMeasureType.ProductionAvailability ||
      measureType == KpiMeasureType.FillRate ||
      measureType == KpiMeasureType.TableEggs ||
      measureType == KpiMeasureType.InputOffgrades ||
      measureType == KpiMeasureType.Upgrades ||
      measureType == KpiMeasureType.Outputoffgrades ||
      measureType == KpiMeasureType.Bypass ||
      measureType == KpiMeasureType.BucketEggs
    );
  };

  const { periodsForUnits } = useMemo(() => {
    let newPeriods: PerformancePeriod[];
    if (
      chartMapping.performanceProChart == KpiMeasureType.ProductionTime ||
      isPercentageBased(chartMapping.performanceProChart)
    ) {
      newPeriods = periods.map<PerformancePeriod>((p) => ({
        ...p,
        value: p.value, // no unit conversion needed.
      }));
    } else {
      const inUnits = createUnitsConverter(eggUnit);
      newPeriods = periods.map<PerformancePeriod>((p) => ({
        ...p,
        value: inUnits(p.value),
      }));
    }

    return {
      periodsForUnits: newPeriods,
    };
  }, [eggUnit, periods]);

  const { yAxisLabel } = useMemo(() => {
    let label = t(chartMapping.headerTitle);
    let yAxisUnit = eggUnit
      ? GetEggUnitTranslation(eggUnit)
      : t(chartMapping.yAxisUnit);

    if (chartMapping.valueType === "ValueOverTime") {
      label = `${label} (${tooltipUnitLabel})`;
    } else if (yAxisUnit !== "") {
      label = `${label}  (${yAxisUnit})`;
    }

    return {
      yAxisLabel: label,
    };
  }, [
    chartMapping.headerTitle,
    chartMapping.yAxisUnit,
    eggUnit,
    tooltipUnitLabel,
  ]);

  return (
    <PerformanceProBaseChart
      data={periodsForUnits}
      startDate={options.selectedDate}
      productionStartTime={productionStart}
      productionEndTime={productionEnd}
      yAxisLabel={yAxisLabel}
      yAxisKey={"value"}
      tooltipFormatter={tooltipFormatter}
      defaultChartSettings={newChartSettings}
      additionalData={additionalData}
    />
  );
}
