import React, { useEffect, useState, useCallback } from "react";
import { useReportService } from "../../../api/ServiceContext";
import useRefresh from "../../Dashboard/useRefresh";
import MachineSpeedMinuteWidgetPresentation, {
  MachineSpeedMinuteApiData,
  MachineSpeedMinuteApiResponse,
} from "./MachineSpeedMinute";

export type MachineSpeedMinuteWidgetContainerProps = {
  machineId: number;
  automaticRefresh: boolean;
};

/**
 * Shows the average machine speed per minute for the last 30 minutes.
 * The machine speed is the number of carriers divided by the runtime.
 */
export default function MachineSpeedMinuteWidgetContainer({
  machineId,
  automaticRefresh,
}: MachineSpeedMinuteWidgetContainerProps) {
  const reportService = useReportService();
  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [data, setData] = useState<MachineSpeedMinuteApiData | any>(null);

  const fetchData = useCallback(
    (newMachineId: number) => {
      reportService
        .getMachineSpeedMinute(newMachineId)
        .then((response: MachineSpeedMinuteApiResponse) => {
          setAwaitingResponse(false);
          const machineSpeedHourPeriodLength =
            response.data.machineSpeedMinutePeriods.length - 1;

          if (machineSpeedHourPeriodLength < 0) {
            return;
          }

          setData(response.data);
        });
    },
    [reportService]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return (
    <MachineSpeedMinuteWidgetPresentation
      awaitingResponse={awaitingResponse}
      data={data}
    />
  );
}
