import React from "react";
import {
  useBenchmarkActions,
  useBenchmarkState,
} from "../../../../../store/GlobalContext";
import {
  determineEggTypeName,
  EggType,
} from "../../../../../utility/EggUtility";
import { t } from "../../../../../utility/TranslateUtility";
import CheckboxSelection from "../CheckboxSelection";

const options = [
  EggType.Cage,
  EggType.Barn,
  EggType.FreeRange,
  EggType.Organic,
];

export default function EggTypeSelect() {
  const { updateEggTypes } = useBenchmarkActions();
  const selectedEggTypes = useBenchmarkState().editingFilterState.eggTypes;

  return (
    <CheckboxSelection
      title={t("benchmark.filters.eggType")}
      options={options}
      optionsLabelSelector={(o) => determineEggTypeName(o as EggType)}
      selectedOptions={selectedEggTypes}
      onChange={(selection) => updateEggTypes(selection)}
    />
  );
}
