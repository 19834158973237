import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GroupMetadata,
  MachinePerformanceGroupResult,
  MachinePerformanceGroupResultGroups,
} from "../../../api/PerformancePro/IPerformanceProService";
import {
  useMachineService,
  usePerformanceProService,
} from "../../../api/ServiceContext";
import { FeaturePolicyItems } from "../../../globalHooks/authorizationHooks";
import { usePolicyFeatures } from "../../../globalHooks/usePolicyFeatures";
import {
  usePerformanceProFilterOptions,
  usePerformanceProGroupActions,
  usePerformanceProGroupsState,
  useSelectedMachine,
} from "../../../store/GlobalContext";
import { DatePeriod, getFirstOfPeriod } from "../../../utility/DateUtility";
import { normalizeEggType } from "../../../utility/EggUtility";
import { useQualityChartSettingManagement } from "../../Settings/QualityCharts/hooks";
import { useDispatchAlert } from "../../UI/Alert";
import {
  AllKpiMeasuresByDatePeriodForSupplier,
  AllKpiMeasuresBySupply,
  InfeedOrTakeawayBases,
  KpiMeasureGroupType,
  PerformanceGroupItem,
} from "../Types";
import {
  getColorsBetween,
  weightMaxColor,
  weightMinColor,
} from "../WeightColorUtilities";
import {
  ConvertDatePeriod,
  ProductionTimeWindow,
} from "../performanceProHooks";
import {
  useAwaitingPerformanceProInitialState,
  useProductionTime,
} from "../usePerformanceProInitialStateHooks";
import {
  StackedBarChartMapping,
  StackedBarChartMappings,
  XAxisType,
} from "./GroupsChartMapping";

export const infeedMeasures: KpiMeasureGroupType[] = [
  KpiMeasureGroupType.InfeedGradeByDatePeriod,
  KpiMeasureGroupType.InfeedGradeBySupply,
  KpiMeasureGroupType.InfeedOffgradeByDatePeriod,
  KpiMeasureGroupType.InfeedOffgradeBySupply,
  KpiMeasureGroupType.InfeedGradeByDatePeriodForSupplier,
  KpiMeasureGroupType.InfeedOffgradeByDatePeriodForSupplier,
];

export const takeawayMeasures: KpiMeasureGroupType[] = [
  KpiMeasureGroupType.TakeawayGradeByDatePeriod,
  KpiMeasureGroupType.TakeawayGradeBySupply,
  KpiMeasureGroupType.TakeawayOffgradeByDatePeriod,
  KpiMeasureGroupType.TakeawayOffgradeBySupply,
  KpiMeasureGroupType.TakeawayGradeByDatePeriodForSupplier,
  KpiMeasureGroupType.TakeawayOffgradeByDatePeriodForSupplier,
];

export const gradeMeasures: KpiMeasureGroupType[] = [
  KpiMeasureGroupType.InfeedGradeByDatePeriod,
  KpiMeasureGroupType.InfeedGradeBySupply,
  KpiMeasureGroupType.TakeawayGradeByDatePeriod,
  KpiMeasureGroupType.TakeawayGradeBySupply,
  KpiMeasureGroupType.TakeawayGradeByDatePeriodForSupplier,
  KpiMeasureGroupType.InfeedGradeByDatePeriodForSupplier,
];

export const offgradeMeasures: KpiMeasureGroupType[] = [
  KpiMeasureGroupType.InfeedOffgradeByDatePeriod,
  KpiMeasureGroupType.InfeedOffgradeBySupply,
  KpiMeasureGroupType.TakeawayOffgradeByDatePeriod,
  KpiMeasureGroupType.TakeawayOffgradeBySupply,
];

const groupMeasures: KpiMeasureGroupType[] = [
  KpiMeasureGroupType.ColorByDatePeriod,
  KpiMeasureGroupType.ColorBySupply,
  KpiMeasureGroupType.ShellStrengthBySupply,
  KpiMeasureGroupType.TotalWeightBySupply,
  KpiMeasureGroupType.TotalBySupply,
  KpiMeasureGroupType.TotalByDatePeriod,
];

const perSupplierMeasures: KpiMeasureGroupType[] = [
  KpiMeasureGroupType.ColorByDatePeriodForSupplier,
  KpiMeasureGroupType.ShellStrengthByDatePeriodForSupplier,
  KpiMeasureGroupType.InfeedGradeByDatePeriodForSupplier,
  KpiMeasureGroupType.TakeawayGradeByDatePeriodForSupplier,
  KpiMeasureGroupType.InfeedOffgradeByDatePeriodForSupplier,
  KpiMeasureGroupType.TakeawayOffgradeByDatePeriodForSupplier,
  KpiMeasureGroupType.TotalByDatePeriodForSupplier,
  KpiMeasureGroupType.TotalWeightByDatePeriodForSupplier,
];

export interface PerformanceGroupMeasureHookResult {
  data: PerformanceGroupItem[] | never[];
  chartMapping: StackedBarChartMapping;
  infeedOrTakeawayMeasure: InfeedOrTakeawayBases | undefined;
  metadata: GroupMetadata;
  awaitingResponse: boolean;
}

export function usePerformanceEggTotals(
  measure: KpiMeasureGroupType
): PerformanceGroupItem[] {
  let measureToUse: KpiMeasureGroupType = KpiMeasureGroupType.TotalByDatePeriod;

  if (AllKpiMeasuresByDatePeriodForSupplier.includes(measure)) {
    measureToUse = KpiMeasureGroupType.TotalByDatePeriodForSupplier;
  } else if (AllKpiMeasuresBySupply.includes(measure)) {
    measureToUse = KpiMeasureGroupType.TotalBySupply;
  }

  return usePerformanceGroupMeasure(measureToUse).data;
}

export function usePerformanceGroupMeasure(
  measure: KpiMeasureGroupType
): PerformanceGroupMeasureHookResult {
  const options = usePerformanceProFilterOptions();
  const { selectedEggType } = options;
  // needed for useEffect dependency use
  const selectedEggTypes = useMemo(() => [selectedEggType], [selectedEggType]);
  return usePerformanceGroupMeasureWithEggTypes(measure, selectedEggTypes);
}

export function usePerformanceGroupMeasureWithNormalizedEggTypes(
  measure: KpiMeasureGroupType
): PerformanceGroupMeasureHookResult {
  const options = usePerformanceProFilterOptions();
  const { selectedEggType } = options;
  // needed for useEffect dependency use
  const selectedEggTypes = useMemo(
    () => normalizeEggType(selectedEggType),
    [selectedEggType]
  );
  return usePerformanceGroupMeasureWithEggTypes(measure, selectedEggTypes);
}

export function usePerformanceGroupMeasureWithEggTypes(
  measure: KpiMeasureGroupType,
  selectedEggTypes: number[]
): PerformanceGroupMeasureHookResult {
  const performanceGroupsResult = usePerformanceProGroupsState();
  const productionTimeWindow = useProductionTime();
  const { selectedDate, selectedDatePeriod } = usePerformanceProFilterOptions();
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(true);

  const data =
    performanceGroupsResult.groups[
      KpiMeasureGroupType[measure] as keyof MachinePerformanceGroupResultGroups
    ];

  useEffect(() => {
    setAwaitingResponse(true);
  }, [selectedDate, selectedDatePeriod]);

  const { filteredData, chartMapping, infeedOrTakeawayMeasure, metadata } =
    useMemo(() => {
      setAwaitingResponse(true);

      let measureMetadata =
        performanceGroupsResult.metadata[
          KpiMeasureGroupType[
            measure
          ] as keyof MachinePerformanceGroupResultGroups
        ]!;

      if (!measureMetadata) {
        measureMetadata = {
          groups: [],
          xAxis: { values: {} },
        };
      }

      let measureChartMapping = StackedBarChartMappings.filter(
        (x) => x.measure === measure
      )[0];

      if (gradeMeasures.includes(measure)) {
        measureChartMapping = fillGradeGroupLabels(
          measureChartMapping,
          measureMetadata
        );
      }

      const measureInfeedOrTakeawayMeasure =
        determinInfeedOrTakeawayBased(measure);

      if (!data) {
        return {
          filteredData: [],
          chartMapping: measureChartMapping,
          metadata: measureMetadata,
        };
      }

      let filteredResult: PerformanceGroupItem[] = [];

      const resultGroupFilteredForEggType = data.filter((x: any) =>
        selectedEggTypes.some((e) => e === x.eggType)
      );

      filteredResult = resultGroupFilteredForEggType;

      if (
        measureChartMapping &&
        measureChartMapping.xAxisType == XAxisType.DatePeriod &&
        selectedDatePeriod === DatePeriod.Day &&
        productionTimeWindow != null
      ) {
        filteredResult = filteredResult.filter(
          (x) =>
            (x.x as Date) >= productionTimeWindow.productionStartTime &&
            (x.x as Date) <= productionTimeWindow.productionEndTime
        );
      }

      if (offgradeMeasures.includes(measure)) {
        filteredResult = determineOffgradeOtherGroup(filteredResult);
      }

      setAwaitingResponse(false);

      return {
        filteredData: filteredResult,
        chartMapping: measureChartMapping,
        infeedOrTakeawayMeasure: measureInfeedOrTakeawayMeasure,
        metadata: measureMetadata,
      };
    }, [data, selectedEggTypes, measure]);

  return {
    data: filteredData,
    chartMapping: chartMapping,
    infeedOrTakeawayMeasure: infeedOrTakeawayMeasure,
    metadata: metadata,
    awaitingResponse: awaitingResponse,
  };
}

export function usePerformanceGroupMeasureForSupplier(
  supplierName: string,
  supplierShed: string
) {
  const qualityChartSetting =
    useQualityChartSettingManagement().qualityChartSetting.qualityChartSetting;
  const service = usePerformanceProService();
  const options = usePerformanceProFilterOptions();
  const { selectedDate, selectedDatePeriod } = options;
  const machineId = useSelectedMachine().machineId;
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const machineService = useMachineService();
  const [shellStrengthActive, setShellStrengthActive] = useState(false);
  const [brownDetectionActive, setBrownDetectionActive] = useState(false);

  const { updatePerformanceProGroupResult, updateProductionTimeWindow } =
    usePerformanceProGroupActions();

  const periodType = ConvertDatePeriod(options.selectedDatePeriod);
  const fromDate = getFirstOfPeriod(
    options.selectedDate,
    options.selectedDatePeriod
  );
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();

  const isInfeedBased = useMemo(() => {
    return qualityChartSetting === "InfeedBasedCharts";
  }, [qualityChartSetting]);

  useEffect(() => {
    if (!awaitingResponse) {
      Promise.all([
        service.getMachinePerformanceBarChartForSupplier({
          machineId: machineId,
          measures: perSupplierMeasures,
          periodType: periodType,
          fromDate: fromDate,
          supplierName: supplierName,
          supplierShed: supplierShed,
        }),
        periodType === "Day"
          ? service.getProductionTimeWindow({
              machineId: machineId,
              date: fromDate,
            })
          : Promise.resolve({ data: null }),
        machineService
          .getMachineConfiguration(machineId)
          .then((response: IApiResponse<any>) => {
            setShellStrengthActive(response.data.shellStrengthActive);
            setBrownDetectionActive(response.data.brownDetectionActive);
          }),
      ])
        .then((results) => {
          const response: IApiResponse<MachinePerformanceGroupResult> =
            results[0];
          const productionTimeResponse: IApiResponse<ProductionTimeWindow> =
            results[1];

          updateProductionTimeWindow(productionTimeResponse.data);
          updatePerformanceProGroupResult(response.data);
          setAwaitingResponse(false);
        })
        .catch((reason) => {
          if (!reason.isCancelled) {
            dispatchAlert({
              message: t("generic.errorMessage"),
              messageType: "error",
            });
          }
        });
    }
  }, [
    awaitingResponse,
    supplierName,
    supplierShed,
    service,
    machineId,
    selectedDate,
    selectedDatePeriod,
  ]);

  return {
    isInfeedBased: isInfeedBased,
    awaitingResponse: awaitingResponse,
    shellStrengthActive: shellStrengthActive,
    brownDetectionActive: brownDetectionActive,
  };
}
export function usePerformanceGroupMeasures() {
  const service = usePerformanceProService();
  const machineService = useMachineService();
  const options = usePerformanceProFilterOptions();
  const { selectedDate, selectedDatePeriod } = options;
  const machineId = useSelectedMachine().machineId;
  const policyFeatures = usePolicyFeatures();

  const { updatePerformanceProGroupResult } = usePerformanceProGroupActions();
  const awaitingPerformanceProInitialState =
    useAwaitingPerformanceProInitialState();

  const [awaitingLocalStateLoaded, setAwaitingResponse] = useState(true);
  const [shellStrengthActive, setShellStrengthActive] = useState(false);
  const [brownDetectionActive, setBrownDetectionActive] = useState(false);
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();

  const isInfeedBased =
    useQualityChartSettingManagement().qualityChartSetting
      .qualityChartSetting === "InfeedBasedCharts";

  useEffect(() => {
    setAwaitingResponse(true);
    const infeedOrTakeawayMeasure = isInfeedBased
      ? infeedMeasures
      : takeawayMeasures;

    const measureToRequest = [...groupMeasures, ...infeedOrTakeawayMeasure];

    const periodType = ConvertDatePeriod(options.selectedDatePeriod);
    const fromDate = getFirstOfPeriod(
      options.selectedDate,
      options.selectedDatePeriod
    );

    machineService
      .getMachineConfiguration(machineId)
      .then((response: IApiResponse<any>) => {
        setShellStrengthActive(response.data.shellStrengthActive);
        setBrownDetectionActive(response.data.brownDetectionActive);
      })
      .catch((reason) => {
        if (!reason.isCancelled) {
          dispatchAlert({
            message: t("generic.errorMessage"),
            messageType: "error",
          });
        }
      });

    service
      .getMachinePerformanceBarChart({
        machineId: machineId,
        measures: measureToRequest,
        periodType: periodType,
        fromDate: fromDate,
      })
      .then((response: IApiResponse<MachinePerformanceGroupResult>) => {
        updatePerformanceProGroupResult(response.data);
        setAwaitingResponse(false);
      })
      .catch((reason) => {
        if (!reason.isCancelled) {
          dispatchAlert({
            message: t("generic.errorMessage"),
            messageType: "error",
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, machineId, isInfeedBased, selectedDate, selectedDatePeriod]);

  // This code can be removed when Moba enables Brown Scale and Shell Strength for Forta machines, PBI 10090
  let hideBrownScaleAndShellStrength = useMemo(
    () =>
      !policyFeatures.hasFeatureEnabled(
        FeaturePolicyItems.ShowBrownScaleAndShellStrength
      ),
    [policyFeatures]
  );

  return {
    awaitingResponse:
      awaitingLocalStateLoaded || awaitingPerformanceProInitialState,
    isInfeedBased: isInfeedBased,
    shellStrengthActive: hideBrownScaleAndShellStrength
      ? false
      : shellStrengthActive,
    brownDetectionActive: hideBrownScaleAndShellStrength
      ? false
      : brownDetectionActive,
  };
}

function determineOffgradeOtherGroup(filteredResult: PerformanceGroupItem[]) {
  let otherGroup: any[] = [];
  let items: PerformanceGroupItem[] = [];
  _.clone(filteredResult).forEach((item) => {
    if (item.group === "Overweight" || item.group === "Underweight") {
      otherGroup.push({
        ...item,
        group: "Other",
        groupKey: item.x.toString() + item.eggType,
      });
    } else {
      items.push(item);
    }
  });

  const grouped = _.groupBy(otherGroup, "groupKey");
  for (const [, list] of Object.entries(grouped)) {
    if (list[0]) {
      var value = list.reduce(function (prev, cur) {
        return prev + cur.value;
      }, 0);
      items.push({ ...list[0], value: value });
    }
  }

  return items;
}

function fillGradeGroupLabels(
  chartMapping: StackedBarChartMapping,
  metadata: GroupMetadata
): StackedBarChartMapping {
  const colors = getColorsBetween(
    weightMaxColor,
    weightMinColor,
    metadata?.groups.length || 1
  );

  chartMapping.groupMapping =
    metadata?.groups.map((x, i) => {
      return {
        name: x,
        color: colors[i],
        label: metadata?.groups[i] || "",
      };
    }) || [];

  return chartMapping;
}

function determinInfeedOrTakeawayBased(measure: KpiMeasureGroupType) {
  if (infeedMeasures.includes(measure)) {
    return InfeedOrTakeawayBases.Infeed;
  }
  if (takeawayMeasures.includes(measure)) {
    return InfeedOrTakeawayBases.Takeaway;
  }
  return InfeedOrTakeawayBases.None;
}
