import {
  Code,
  Header,
  Image,
  PageHeader,
  Paragraph,
  TextInfoPage,
} from "../../components/Page";
import CountingOverviewSvg from "./../../img/CountingOverview.svg";

export default function GlossaryPage() {
  const P = Paragraph;
  const H = Header;

  return (
    <TextInfoPage>
      <PageHeader>Glossary</PageHeader>
      <Image src={CountingOverviewSvg} title="Infographic" />
      <H>Actual Speed</H>
      <P>
        The actual speed is the realised speed. The dimension of actual speed is
        carriers per hour.
      </P>
      <H>Availability</H>
      <P>
        The portion of the OEE Metric that represents the percentage of
        scheduled time that the operation is available to operate.
        <Code>Availability = Uptime / (Uptime + Downtime)</Code>
      </P>
      <H>Average Set Speed</H>
      <P>
        This is the speed of the machine measured over a certain time interval
        leaving the stops out of the calculation. Example: when during an hour
        the machine is stopped for 20 minutes, during 20 minutes the speed is
        set to 100 and during 20 minutes it is set to 20 the average set speed
        is 60.
      </P>
      <H>Average Speed</H>
      <P>
        This is the speed of the machine measured over a certain time interval.
        The stops in that interval are taken into account. I.e. during such a
        stop the speed is accounted for as being zero. Note that the following
        comparison is always true:
        <Code>Average set speed &gt;= Average speed</Code>
        Example: when during an hour the machine is stopped for 20 minutes,
        during 20 minutes the speed is set to 100 and during 20 minutes it is
        set to 20, the average speed is 40.
      </P>
      <H>Blood Detector</H>
      <P>
        Detection system that spots the existence of haemoglobin inside of the
        egg.
      </P>
      <H>Blood Eggs</H>
      <P>Number of eggs removed by the blood detector</P>
      <H>Bucket Eggs</H>
      <P>
        The Omnia has several exits that end into a bucket (for leakers, blood
        eggs and extreme weights). All eggs that leave the machine via these
        exits are counted as bucket eggs. There are three possible exits: Egg
        inspector/ Leaker detector; Crack detector; Blood detector & weighing
        system. Refer to illustration.
      </P>
      <H>Bypass Eggs</H>
      <P>
        All eggs that are not place able (i.e. for some reason they cannot reach
        their designated destination) are called bypass eggs.
      </P>
      <H>Capacity Utilization</H>
      <P>
        The equipment has a certain max capacity expressed in eggs / hour. The
        capacity utilization during a period is defined as the ratio between the
        average eggs / hour and this max capacity. It is a measure of your
        efficiency.
      </P>
      <H>Carrier</H>
      <P>
        A carrier is a gripper set that can contain one egg. The machine speed
        is defined in number of carriers per time unit.
      </P>
      <H>Crack Detector</H>
      <P>
        Acoustic detection system that classifies each egg into one of 30 crack
        levels.
      </P>
      <H>Designated Break Time</H>
      <P>
        The planned break time: the planned period that a work shift is
        suspended, e.g. for coffee or lunch breaks. (Note that this might differ
        from the actual pause time).
      </P>
      <H>Designed Speed</H>
      <P>
        The maximum number of carriers per time unit a machine is designed for.
      </P>
      <H>Detection Systems</H>
      <P>Egg Inspector, Crack detector, Blood detector, Leaker detector.</P>
      <H>Downgraded Eggs</H>
      <P>Grade A eggs that are sorted out to offgrade destination.</P>
      <H>Downsizing</H>
      <P>Using an egg of a higher weight in a lower weight product.</P>
      <H>Downtime</H>
      <P>Period of time that the machine is stopped unplanned.</P>
      <H>Downtyping</H>
      <P>Using an egg of a higher egg type in a product of a lower egg type.</P>
      <H>Egg Inspector</H>
      <P>
        Vision system that can detect leakers, dirt eggs and brown / white eggs.
        Leakers are removed as quickly as possible and end up in the bucket.
        Dirt eggs are sorted out as offgrades or go to the rewash.
      </P>
      <H>Egg Type</H>
      <P>
        The egg type specifies the way the production hens are taken care for. A
        common ranked (descending order) division is: Organic, Free Range, Barn
        and Cage. Organic is the highest type and cage the lowest in this
        example. Note that on a Moba machine the customer can define its own egg
        types (these are always ranked). In iMoba we use a standardized set of 4
        egg types (Cage, Barn, Free Range, and Organic). The admin has the
        possibility to map the user defined egg types onto these standardized
        egg types.
      </P>
      <H>End Eggs</H>
      <P>All eggs that end up on the end conveyor.</P>
      <H>Error Categories</H>
      <P>
        User stops; Machine stops Making a distinction between user and machine
        stops has the intention to differentiate between equipment issues and
        process issues (when optimizing OEE). Be aware that this distinction is
        not exact science. For example: A user may stop the machine because he
        sees a problem (that the machine doesn't detect itself: the machine does
        wrongly not stop), this will be logged as a user stop, but could be an
        equipment issue.
      </P>
      <H>Fillrate</H>
      <P>
        The fillrate is the ratio between the number of eggs in pockets
        (dividend) and the number of pockets (divisor) at the input side of the
        machine.
      </P>
      <H>Grade A Eggs</H>
      <P>
        Grade A eggs are eggs that within grade A specs, i.e. their weight is
        within Grade A boundaries, their crack level is below a boundary, the
        dirt level is below a boundary and they don't contain blood.
      </P>
      <H>Input Cracks</H>
      <P>Number of eggs seen by crack detector in input eggs.</P>
      <H>Input Dirts</H>
      <P>Number of eggs seen by dirt detector in input eggs.</P>
      <H>Input Grade A</H>
      <P>Input grade A</P>
      <H>Input Offgrades</H>
      <P>
        By definition we call all eggs that are deemed as offgrade by the
        detection system as input offgrades. That means that the upgrading
        process has no influence on this number. (Note however that the Omnia
        has an option "CountInfeedAsTakeaway" or "print supply countings as
        product counts" that changes the input offgrades when upgrading, we call
        that Upgraded Input Offgrades, in iMoba we want that distinction to be
        transparent, i.e. just a matter of representation).
      </P>
      <H>Leaker Detector</H>
      <P>
        Vision system that can detect leakers. Leakers are removed as quickly as
        possible and end up in the bucket.
      </P>
      <H>Leaker Eggs</H>
      <P>Number of eggs removed by the egg inspector or leaker detector.</P>
      <H>Lifesign</H>
      <P>Machine is powered and has internet connection, Y/N signal.</P>
      <H>MobaAvailability</H>
      <P>Machine availability versus 10 minutes pause time filter.</P>
      <H>Net Production Time</H>
      <P>
        The time period between the start of the first shift and the stop of the
        last shift minus all pause times.
      </P>
      <H>Net Throughput</H>
      <P>Net Throughput = Total Eggs / Net Production Time</P>
      <H>OEE</H>
      <P>
        OEE is an abbreviation for Overall Equipment Effectiveness. It is
        defined by the following formula:
        <Code>OEE = Availability * Performance * Quality</Code> Overall
        equipment effectiveness quantifies how well a manufacturing unit
        performs relative to its designed capacity, during the periods when it
        is scheduled to run.
      </P>
      <H>Offgrades</H>
      <P>
        Eggs that have characteristics that don't match grade A specs. Offgrades
        can be detected manually (candling) or automatically by detection
        systems. The performance of the detection process is regularly verified
        by sampling the table egg products. When during this inspection the
        table egg products contain more offgrades than the specified tolerances,
        the batch will be rejected.
      </P>
      <H>Output Cracks</H>
      <P>Number of eggs removed by the crack detector.</P>
      <H>Output Dirts</H>
      <P>Number of eggs removed by the dirt detector.</P>
      <H>Output Offgrades</H>
      <P>
        Output offgrades are the offgrades that remain after upgrading and are
        sorted to the offgrade packing lanes.
      </P>
      <H>Over/Under Weights</H>
      <P>
        Eggs with an extreme weight that can get a designated destination (i.e.
        on the Omnia the user can specify a specific outlet for these eggs, when
        he doesn't they are sorted out as offgrades).
      </P>
      <H>Pause Time</H>
      <P>
        Portion of the scheduled time that the machine is stopped for a process
        break (e.g. lunch break).
      </P>
      <H>Performance</H>
      <P>
        Performance: The portion of the OEE Metric that represents the speed at
        which the Work Center runs as a percentage of its designed speed.
        <Code>Performance = Throughput / Designed speed</Code>
      </P>
      <H>Production Time</H>
      <P>
        The time period between the start of the first shift and the stop of the
        last shift minus all pause times.
      </P>
      <H>Quality</H>
      <P>
        The portion of the OEE Metric that represents the Good Units produced as
        a percentage of the Total Units Started. Commonly referred to as First
        Pass Yield FPY. Note that the function of an egg grading machine is to
        separate incoming eggs into offgrade eggs and table egg products. Table
        egg products that are rejected by quality inspection (recall or retain)
        influence the quality factor. The quality factor can be calculated as:
        <Code>Quality = (Table Eggs - Rejected eggs) / Table Eggs</Code>
      </P>
      <H>Rejected Eggs</H>
      <P>
        Batches of products that are rejected by inspection. Retained: products
        that are rejected inside the pack station by the quality inspector of
        the pack station. Recalled: products that are rejected by the quality
        inspector of the customer of the pack station.
      </P>
      <H>Runhours</H>
      <P>
        Hour counter of hours that tracks of machine were running (same as
        mechanical hour counter on cabinet).
      </P>
      <H>Sellable Product</H>
      <P>Synonym for Table eggs.</P>
      <H>Set Speed</H>
      <P>
        This is the intended speed by the operator: the machine speed that he
        has set via the MMI. The dimension of set speed is carriers per time
        unit.
      </P>
      <H>Software Versions</H>
      <P>Record with version numbers of all equipment, apps etc.</P>
      <H>Spare Lane</H>
      <P>
        A spare lane is a packing lane that is used to sort exceptional eggs to
        such as: Bypass eggs, Table eggs for which no product destination is
        assigned.
      </P>
      <H>Supply Destinations</H>
      <P>The list of products where the eggs of a supply are sorted to.</P>
      <H>Table Eggs</H>
      <P>
        Table eggs are all eggs that end up in the consumer products. Note that
        the end products allow sometimes for a certain small percentage offgrade
        eggs where the upgrading process takes advantage of. Upgraded offgrade
        eggs are counted as table eggs. Bypass eggs are NOT counted as table
        eggs. Note that eggs that are sorted to the spare lane for the reason
        that there are no applicable products defined, are included in the table
        eggs counting.
      </P>
      <H>Takeaway Offgrades</H>
      <P>Synonym for output offgrades.</P>
      <H>TEEP</H>
      <P>
        Total effective equipment performance (TEEP) measures OEE against
        calendar hours, i.e.: 24 hours per day, 365 days per year.
      </P>
      <H>Throughput</H>
      <P>
        Number of eggs processed by the machine per time unit, measured at the
        input side of the machine during runtime.
        <Code>Throughput = Total Eggs / Production Time</Code>
      </P>
      <H>Total Eggs</H>
      <P>
        Number of eggs processed during a production period.
        <Code>Total Eggs = Throughput * Production Time</Code>
      </P>
      <H>Track</H>
      <P>
        A track is a chain of Carriers that rotates above the packing lanes. The
        carriers are opened to drop the egg in the destination lane.
      </P>
      <H>Upgrade Utilization</H>
      <P>
        Only crack and dirt eggs can be upgraded. So upgrade utilization is
        defined as the ratio between the number of upgraded eggs and the
        original number of cracks and dirts.
      </P>
      <H>Upgraded Eggs</H>
      <P>
        Eggs that where detected to be offgrades but were made table eggs during
        upgrading.
      </P>
      <H>Upgrading</H>
      <P>
        Upgrading is the process that takes advantage of regulatory boundaries
        for products: Table egg products are allowed to contain a fraction
        offgrade eggs within boundaries. Note that with an ideal detector one
        can approach the boundaries the closest without risk. Detection systems
        are not 100% ideal: they produce false positives and false negatives. In
        general these fractions can be influenced by tuning the sensitivity
        parameters.
      </P>
      <H>Upsizing</H>
      <P>
        Using an egg of a lower weight in a higher weight product. Note that
        from a product perspective this is allowed within specific boundaries.
        Approaching the boundaries earns money.
      </P>
      <H>Uptime</H>
      <P>Production time minus downtime.</P>
      <H>Uptyping</H>
      <P>
        (FORBIDDEN): using an egg of a lower egg type in a product of a higher
        egg type.
      </P>
    </TextInfoPage>
  );
}
