import axios from "axios";

export const GetCancellationToken = () => {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
};

/**
 * Handles Axios promises. Does not log errors when the request is cancelled.
 * @param reason
 */
export const DefaultErrorHandler = (reason: any) => {
  if (!reason.isCancelled) {
    console.error("An error occurred", reason);
  }
};
