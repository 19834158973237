import { toISO8601DateTimeStringWithoutLocalTime } from "../../../utility/DateUtility";
import { t } from "../../../utility/TranslateUtility";

export interface DestinationProduct {
  productId: string;
  productName: string;
  numberOfEggsInProduct: number;
  numberOfEggsToProduct: number;
  percentageEggsToProduct: number;
  numberOfUpgradedEggsToProduct: number;
  percentageOffgradeContribution: number;
  productionTime: Date;
}

export interface DestinationProductDisplay {
  productId: string;
  productName: string;
  numberOfEggsInProduct: number;
  numberOfEggsToProduct: number;
  percentageEggsToProduct: number;
  numberOfUpgradedEggsToProduct: number;
  percentageOffgradeContribution: number;
  productionTime: string;
}

export const determineProductName = (productName: string) => {
  if (!productName || productName === "") {
    return getNoProductAssignedText();
  }

  if (productName === "#bucket#") {
    productName = getBucketEggsText();
  } else if (productName === "#unknown#") {
    productName = getNoProductAssignedText();
  }

  return productName;
};

export const getBucketEggsText = () => {
  return t(
    "performanceProQuality.supplierDetailPage.destinationProduct.bucketEggs"
  );
};

export const getNoProductAssignedText = () => {
  return (
    "<" +
    t(
      "performanceProQuality.supplierDetailPage.destinationProduct.noProductAssigned"
    ) +
    ">"
  );
};

export const determineProductionTime = (productionTime?: Date) => {
  return productionTime
    ? toISO8601DateTimeStringWithoutLocalTime(productionTime)
    : "";
};
