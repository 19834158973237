import ILocalAlarmService from "./ILocalAlarmService";
import { IAlarm, emptyAlarm } from "../../components/Alarms/AddOrUpdateAlarm/Common";
import { AlarmCondition, AlarmTopic, AlarmFrequency, AlarmStatus } from "../../components/Alarms/Types";

function getEnumValueFromString(enumObj: any, enums: any) {
  const keys: string[] = Object.keys(enums);
  const enumStr = enumObj.toString();
  for(const key of keys) {
    if(enums[Number(key)] === enumStr || Number(key) === Number(enumObj)) {
      return Number(key);
    }
  }
  return 0;
}

class LocalAlarmService implements ILocalAlarmService {
  private m_isAddingOrUpdating: boolean;
  private m_currentAlarm: IAlarm;
  private m_onUpdate: ((alarmUpdates: IAlarm) => void) | undefined;

  constructor() {
    this.m_isAddingOrUpdating = false;
    this.m_currentAlarm = emptyAlarm;
    this.m_onUpdate = undefined;
  }

  isAddingOrUpdating(): boolean {
    return this.m_isAddingOrUpdating;
  }

  currentAlarm(): IAlarm {
    return this.m_currentAlarm;
  }

  updateAlarmField(field: string, value: any): void {
    const newAlarm: IAlarm = {
      alarmId: this.m_currentAlarm.alarmId,
      topic: this.m_currentAlarm.topic,
      user: this.m_currentAlarm.user,
      level: this.m_currentAlarm.level,
      levelUnit: this.m_currentAlarm.levelUnit,
      condition: this.m_currentAlarm.condition,
      frequency: this.m_currentAlarm.frequency,
      startTimeUtc: this.m_currentAlarm.startTimeUtc,
      endTimeUtc: this.m_currentAlarm.endTimeUtc,
      status: this.m_currentAlarm.status,
      [field]: value
    };
    this.m_currentAlarm = newAlarm;
    if(this.m_onUpdate !== undefined) {
      this.m_onUpdate(this.m_currentAlarm);
    }
  }

  startAddingOrUpdating(alarm: IAlarm, onAlarmUpdate: ((alarmUpdates: IAlarm) => void) | undefined): void {
    this.m_isAddingOrUpdating = true;
    this.m_onUpdate = onAlarmUpdate;
    this.m_currentAlarm = {
      ...alarm,
      topic: getEnumValueFromString(alarm.topic, AlarmTopic),
      condition: getEnumValueFromString(alarm.condition, AlarmCondition),
      frequency: getEnumValueFromString(alarm.frequency, AlarmFrequency),
      status: getEnumValueFromString(alarm.status, AlarmStatus)
    };
  }

  stopAddingOrUpdating(): void {
    this.m_isAddingOrUpdating = false;
    this.m_currentAlarm = emptyAlarm;
  }

}

export default LocalAlarmService;
