import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react";
import { findTimeZone, listTimeZones } from "timezone-support";
import { useManagementSettingsService } from "../../api/ServiceContext";
import { useSelectedMachine } from "../../store/GlobalContext";
import { t } from "../../utility/TranslateUtility";
import { useDispatchAlert } from "../UI/Alert/alertHooks";
import { EventLog, searchEventLogs } from "./Common";
import EventLogsListSearch from "./EventLogsListSearch";
import { EventLogsTablePresentation } from "./EventLogsTablePresentation";

export interface EventLogsContainerProps {
  eventLogs: EventLog[];
  isLoading: boolean;
}

export function EventLogsContainer({
  eventLogs,
  isLoading,
}: EventLogsContainerProps) {
  const managementService = useManagementSettingsService();
  const selectedMachine = useSelectedMachine();
  const timeZones = listTimeZones();
  const dispatchAlert = useDispatchAlert();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [timeZone, setTimeZone] = useState<any>(null);

  useEffect(() => {
    managementService
      .getMachineTargetsSettings(selectedMachine.machineId, new Date())
      .then((response: IApiResponse<any>) => {
        const timeZoneString: string =
          timeZones.find((tz) =>
            response.data.units.timezone.includes(tz.toString())
          ) || "";
        if (timeZoneString.length > 0) {
          setTimeZone(findTimeZone(timeZoneString));
        }
      })
      .catch(() => {
        dispatchAlert({
          message: t("generic.errorMessage"),
          messageType: "error",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMachine]);

  const searchedEventLogs = useMemo(() => {
    return searchEventLogs(eventLogs, searchTerm, timeZone);
  }, [eventLogs, searchTerm, timeZone]);

  return (
    <>
      <EventLogsListSearch
        searchTerm={searchTerm}
        onChange={setSearchTerm}
        isLoading={isLoading}
      />
      {isLoading ? (
        <Skeleton variant="rect" height={500} animation="wave" />
      ) : (
        <EventLogsTablePresentation
          rowsToDisplay={["date", "modificationType", "responsible", "info"]}
          eventLogs={searchedEventLogs}
          timeZone={timeZone}
        />
      )}
    </>
  );
}
