import { makeStyles, Typography } from "@material-ui/core";
import { KpiMeasureType } from ".";
import { colors } from "../../colors";
import { IPerformanceProFilterOptions } from "../../store/GlobalContext";
import { DatePeriod } from "../../utility/DateUtility";
import { EggUnit, GetTargetLabel } from "../../utility/EggUnitUtility";

const measuresWithUnits: KpiMeasureType[] = [
  KpiMeasureType.Upgrades,
  KpiMeasureType.Outputoffgrades,
  KpiMeasureType.Bypass,
  KpiMeasureType.BucketEggs,
];

export function HasUnits(
  measure: KpiMeasureType,
  options: IPerformanceProFilterOptions
): boolean {
  return (
    options.selectedDatePeriod === DatePeriod.Day &&
    measuresWithUnits.includes(measure)
  );
}

export default function BarChartDisplayUnit({
  summation,
  eggUnit,
}: {
  summation: number;
  eggUnit: EggUnit | null;
}) {
  return <UnitDisplay>{GetTargetLabel(summation, eggUnit)}</UnitDisplay>;
}

export function UnitDisplay({ children }: any): any {
  const classes = useStyles();

  return (
    <Typography variant="body2" className={classes.display}>
      {children}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  display: {
    color: "gray",
    height: "30px",
    backgroundColor: colors.whitesmoke,
    marginTop: "5px",
    marginBottom: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "auto",
    display: "inline-block",
    right: "inherit",
    borderRadius: "10px",
  },
}));
