import { KpiMeasurePeriodType } from "../../components/PerformancePro";
import { toISO8601DateStringLocal } from "../../utility/DateUtility";
import { IHttpClient } from "../IHttpClient";
import { IPerformanceProProductionService } from "./IPerformanceProProductionService";

const baseUrl = "v1/PerformanceProProduction";

export class PerformanceProProductionService
  implements IPerformanceProProductionService
{
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }

  get(url: string): Promise<any> {
    return this.httpclient.get(url);
  }

  getFillRateDayView(machineId: MachineId, date: Date): Promise<any> {
    const dateString = toISO8601DateStringLocal(date);

    return this.get(
      `${baseUrl}/fill-rate-day?machineId=${machineId}&date=${dateString}`
    );
  }

  getMachineStopsDayView(machineId: MachineId, date: Date): Promise<any> {
    const dateString = toISO8601DateStringLocal(date);

    return this.get(
      `${baseUrl}/machine-stops-day?machineId=${machineId}&date=${dateString}`
    );
  }

  getLaneProduction(
    machineId: MachineId,
    date: Date,
    periodType: KpiMeasurePeriodType
  ): Promise<any> {
    const dateString = toISO8601DateStringLocal(date);

    return this.get(
      `${baseUrl}/lane-production?machineId=${machineId}&fromDate=${dateString}&periodType=${periodType}`
    );
  }
}
