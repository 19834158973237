import { IHttpClient } from "../IHttpClient";
import { IUserActivityService } from "./IUserActivityService";

export class UserActivityService implements IUserActivityService {
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }

  updateLastLoginDate(lastLoginDate: Date): Promise<any> {
    return this.put(`v1/user/activity`, lastLoginDate);
  }

  private put(url: string, body: any): Promise<any> {
    return this.httpclient.put(url, body);
  }
}
