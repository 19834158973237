import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { PerformanceProReportQueryNames } from "../../../api/Queries";
import { usePerformanceProReportService } from "../../../api/ServiceContext";
import {
  usePerformanceProFilterOptions,
  useSelectedMachine,
} from "../../../store/GlobalContext";
import { useDispatchAlert } from "../../UI/Alert";

export function useReportData() {
  const reportService = usePerformanceProReportService();
  const selectedMachine = useSelectedMachine();
  const dispatchAlert = useDispatchAlert();
  const options = usePerformanceProFilterOptions();
  const { t } = useTranslation();

  return useQuery(
    [
      PerformanceProReportQueryNames.GetReport,
      [selectedMachine.machineId, options.selectedDate],
    ],
    () =>
      reportService.getProductionReportView(
        selectedMachine.machineId,
        options.selectedDate
      ),
    {
      onError: () => {
        dispatchAlert({
          message: t("generic.errorMessage"),
          messageType: "error",
        });
      },
    }
  );
}
