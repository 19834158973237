import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOwnerService, useUserService } from "../../api/ServiceContext";
import {
  EventLog,
  EventLogsApiResponse,
} from "../../components/EventLogs/Common";
import { MachineInformationCardContainer } from "../../components/Owner/MachineInformationCard";
import MachineInformationCardActions from "../../components/Owner/MachineInformationCardActions";
import MachineSelectionBar from "../../components/Owner/MachineSelectionBar";
import MissingAMachineButtonContainer from "../../components/Owner/MissingAMachineButtonContainer";
import {
  OwnerEventLogsContainer,
  toPrettyModificationType,
} from "../../components/Owner/OwnerEventLogsContainer";
import { useDispatchAlert } from "../../components/UI/Alert/alertHooks";
import { iMobaUser } from "../../components/Users/AddOrUpdateUser/Common";
import { AssignAdminContainer } from "../../components/Users/AssignAdmin/AssignAdminContainer";
import { ConfirmActionDialog } from "../../components/Users/ConfirmActionDialog";
import { UsersApiResponse } from "../../components/Users/UserList/UserListContainer";
import { UserListPresentation } from "../../components/Users/UserList/UserListPresentation";
import { useMachineFromParamsOrRedirect } from "../../globalHooks/machineHooks";
import { useSelectedMachine } from "../../store/GlobalContext";
import { PageWithMachineId } from "../PageWithMachineId";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  tabsContent: {
    padding: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    height: "100%",
    width: "100%",
  },
  centeredSpinner: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
}));

export interface OwnedMachinesApiResponse {
  ownedMachines: OwnerMachine[];
}

export default function OwnerPage() {
  useMachineFromParamsOrRedirect("owner");
  const classes = useStyles();
  const selectedMachine = useSelectedMachine();
  const { user } = useAuth0();
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();

  const ownerService = useOwnerService();
  const userService = useUserService();

  const [isLoading, setIsLoading] = useState(true);
  const [ownedMachines, setOwnedMachines] = useState<OwnerMachine[]>([]);
  const [eventLogs, setEventLogs] = useState<EventLog[]>([]);
  const [admins, setAdmins] = useState<iMobaUser[]>([]);
  const [adminToRevoke, setAdminToRevoke] = useState<iMobaUser>();
  const [adminDialogOpen, setAdminDialogOpen] = useState(false);
  const [ownedMachine, setOwnedMachine] = useState<OwnerMachine | null>(null);

  const userIsAdmin = useMemo(() => {
    if (user) {
      const loggedInUser = admins.filter((x) => x.email === user.email)[0];
      return loggedInUser
        ? loggedInUser.roles!.includes("AdminCustomerApp")
        : false;
    } else {
      return false;
    }
  }, [user, admins]);

  const handleDelete = (admin: iMobaUser) => {
    setAdminDialogOpen(true);
    setAdminToRevoke(admin);
  };

  const handleConfirmRevokeDialogConfirm = () => {
    handleRemoveAdmin();
    setAdminDialogOpen(false);
  };

  const handleConfirmRevokeDialogReject = () => {
    setAdminDialogOpen(false);
  };

  const dispatchErrorMessage = () => {
    dispatchAlert({
      message: t("generic.errorMessage"),
      messageType: "error",
    });
  };

  const fetchOwnerPageData = () => {
    setIsLoading(true);
    userService
      .getUsers(selectedMachine.machineId)
      .then((response: IApiResponse<UsersApiResponse>) => {
        const administrators = response.data.users.filter((x) =>
          x.roles?.find((y) => y === "AdminCustomerApp")
        );

        setAdmins(administrators);
      })
      .then(() => {
        ownerService
          .getOwnerEventLogs(selectedMachine.machineId)
          .then((response: IApiResponse<EventLogsApiResponse>) => {
            var newEventLogs = response.data.eventLogs.map((x) => ({
              ...x,
              date: x.date,
              modificationType: toPrettyModificationType(
                x.modificationType.toString(),
                t
              ),
            }));
            setEventLogs(newEventLogs);
          });
      })
      .then(() => {
        ownerService
          .getOwnedMachines()
          .then((response: IApiResponse<OwnedMachinesApiResponse>) => {
            var result = response.data.ownedMachines;
            setOwnedMachines(result);

            var foundMachine = result.find(
              (x) =>
                x.machineId.toString() === selectedMachine.machineId.toString()
            );

            setOwnedMachine(foundMachine ?? null);
            setIsLoading(false);
          });
      })
      .catch(() => {
        dispatchErrorMessage();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchOwnerPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMachine]);

  const handleRemoveAdmin = () => {
    ownerService
      .revokeAdmin(adminToRevoke!.email, selectedMachine.machineId, user.email)
      .then(() => {
        dispatchAlert({
          message: t("users.messages.userSaved"),
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: t("users.messages.userSaveFailed"),
          messageType: "error",
        });
      })
      .finally(() => {
        handleOnOwnerAdminStatusChanged();
      });
  };

  const handleOnOwnerAdminStatusChanged = () => {
    fetchOwnerPageData();
  };

  return (
    <PageWithMachineId page={"owner"}>
      <div className={classes.wrapper}>
        <Paper>
          <>
            <div>
              <MachineSelectionBar
                selectedMachineId={selectedMachine.machineId.toString()}
                ownedMachines={ownedMachines}
                page="owner"
              />
            </div>
            <Grid container spacing={0} className={classes.tabsContent}>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} md={12} xl={5}>
                  <MachineConnectionSection
                    selectedMachine={ownedMachine}
                    isLoading={isLoading}
                  />
                </Grid>
                <Grid item xs={12} md={12} xl={11}>
                  <Typography variant="h5" className={classes.header}>
                    {t("ownerPage.ownerEventLogSectionTitle")}
                  </Typography>
                  <OwnerEventLogsContainer
                    eventLogs={eventLogs}
                    isLoading={isLoading}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" className={classes.header}>
                  {t("ownerPage.administratorSectionTitle")}
                </Typography>

                <ConfirmActionDialog
                  message={t("users.messages.revokeAdmin")}
                  isOpen={adminDialogOpen}
                  onConfirm={handleConfirmRevokeDialogConfirm}
                  onReject={handleConfirmRevokeDialogReject}
                />

                <AssignAdminContainer
                  userIsAdmin={userIsAdmin}
                  isLoading={isLoading}
                  onOwnerAdminStatusChanged={handleOnOwnerAdminStatusChanged}
                />

                <UserListPresentation
                  rowsToDisplay={["email", "status"]}
                  users={admins}
                  totalUsers={admins.length}
                  actionsToDisplay={["delete"]}
                  onDelete={handleDelete}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
          </>
        </Paper>
      </div>
    </PageWithMachineId>
  );
}

function MachineConnectionSection({
  selectedMachine,
  isLoading,
}: {
  selectedMachine: OwnerMachine | null;
  isLoading: boolean;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const ownerService = useOwnerService();
  const dispatchAlert = useDispatchAlert();
  const { user } = useAuth0();

  const saveMachineName = (name: string) => {
    if (!selectedMachine) return;
    ownerService
      .updateMachineName(name, selectedMachine.machineId, user.email)
      .then(() => {
        selectedMachine.machineName = name;
        dispatchAlert({
          message: t("ownerPage.changeNameSuccess"),
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: t("ownerPage.changeNameFailed"),
          messageType: "error",
        });
      });
  };

  return (
    <div className={classes.section}>
      <Typography variant="h5" className={classes.header}>
        {t("ownerPage.machineConnectionSectionTitle")}
      </Typography>
      <MissingAMachineButtonContainer isLoading={isLoading} />
      <MachineInformationCardContainer
        selectedMachine={selectedMachine}
        isLoading={isLoading}
        actions={
          <MachineInformationCardActions
            isApproved={!selectedMachine ? false : selectedMachine.isApproved}
            machineName={!selectedMachine ? "" : selectedMachine.machineName}
            saveMachineName={saveMachineName}
          />
        }
      />
    </div>
  );
}
