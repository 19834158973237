import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import { Fragment, useEffect, useState } from "react";
import { t } from "../../../utility/TranslateUtility";
import { OrderType } from "../../CommonUtil/OrderType";
import { StyledTableCell } from "../../UI/Table/Table";
import { iMobaUser } from "../../Users/AddOrUpdateUser/Common";
import { IAlarm } from "../AddOrUpdateAlarm/Common";
import AlarmRow from "./AlarmRow";

interface Props {
  alarms: IAlarm[];
  timeZone: string;
  order: OrderType;
  orderBy: keyof IAlarm;
  createSortHandler: (property: keyof IAlarm) => void;
  openDialog: (selectedAlarm: IAlarm) => void;
  onDelete: (alarmId: number) => void;
}

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "white !important",
      "&:hover": {
        color: "white !important",
      },
      "&$active": {
        color: "white !important",
      },
    },
    icon: {
      color: "white !important",
    },
  })
)(TableSortLabel);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: OrderType,
  orderBy: Key
): (
  a: { [key in Key]: number | string | iMobaUser | Date | null },
  b: { [key in Key]: number | string | iMobaUser | Date | null }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const sortLabels: { key: keyof IAlarm; text: string }[] = [
  {
    key: "topic",
    text: "alarmManagement.labels.topic",
  },
  {
    key: "user",
    text: "alarmManagement.labels.user",
  },
  {
    key: "level",
    text: "alarmManagement.labels.level",
  },
  {
    key: "condition",
    text: "alarmManagement.labels.condition",
  },
  {
    key: "frequency",
    text: "alarmManagement.labels.frequency",
  },
  {
    key: "startTimeUtc",
    text: "alarmManagement.labels.first",
  },
  {
    key: "endTimeUtc",
    text: "alarmManagement.labels.last",
  },
  {
    key: "status",
    text: "alarmManagement.labels.status",
  },
];

const unSortLabels: { key: string; text: string }[] = [
  {
    key: "actions",
    text: "alarmManagement.labels.actions",
  },
];

function AlarmListPresentation({
  alarms,
  timeZone,
  order,
  orderBy,
  createSortHandler,
  openDialog,
  onDelete,
}: Props) {
  const [sortedAlarms, setSortedAlarms] = useState<IAlarm[]>(alarms);

  useEffect(() => {
    createSortHandler(orderBy);
    const sortFunction = getComparator(order, orderBy);
    const alarmsToSort = alarms.map((x) => {
      return {
        ...x,
      };
    });
    setSortedAlarms([...alarmsToSort].sort(sortFunction));
  }, [alarms, order, orderBy, createSortHandler]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {sortLabels.map((label) => (
                <StyledTableCell key={label.key}>
                  <StyledTableSortLabel
                    active={orderBy === label.key}
                    direction={orderBy === label.key ? order : "asc"}
                    onClick={createSortHandler(label.key)}
                  >
                    <div>{t(label.text)}</div>
                  </StyledTableSortLabel>
                </StyledTableCell>
              ))}
              {unSortLabels.map((label) => (
                <StyledTableCell key={label.key}>
                  <div>{t(label.text)}</div>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedAlarms.map((record) => {
              return (
                <Fragment key={record.alarmId}>
                  <AlarmRow
                    row={record}
                    timeZone={timeZone}
                    onDelete={(id) => onDelete(id)}
                    onEdit={(alarm) => openDialog(alarm)}
                  />
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AlarmListPresentation;
