import React from "react";
import { t } from "../../utility/TranslateUtility";
import MachineStopsChart from "../Charts/MachineStopsChart";
import { ChartCard } from "../ProductionViews/Common";
import { useMachineStopsHook } from "./MachineStopsHook";

export default function MachineStopsViewContainer({
  machineId,
}: {
  machineId: MachineId;
}) {
  const { options, data, awaitingResponse } = useMachineStopsHook({
    machineId: machineId,
  });

  return (
    data && (
      <>
        <ChartCard headerTitle={t("uniqueViews.machineStops.cardHeader")} isLoading={awaitingResponse} >
          <MachineStopsChart groups={data} options={options} />
        </ChartCard>
      </>
    )
  );
}
