import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  usePerformanceProProductionService,
  usePerformanceProService,
} from "../../api/ServiceContext";
import { usePerformanceProFilterOptions } from "../../store/GlobalContext";
import { DatePeriod, getFirstOfPeriod } from "../../utility/DateUtility";
import { EggType } from "../../utility/EggUtility";
import { GetCancellationToken } from "../../utility/HttpServiceUtility";
import { useDispatchAlert } from "../UI/Alert";
import { ConvertDatePeriod } from "./performanceProHooks";
import { KpiMeasureType, PerformancePeriod } from "./Types";
import {
  useAwaitingPerformanceProInitialState,
  useProductionTime,
} from "./usePerformanceProInitialStateHooks";

export interface MachineStopsGroup {
  group: string;
  periods: MachineStopsPeriod[];
}

export type MachineStopsPeriod = {
  hour: Date;
  value: number;
};

export function useMachineStopsHook({ machineId }: { machineId: MachineId }) {
  const cancellationToken = GetCancellationToken();
  const service = usePerformanceProService(cancellationToken);
  const productionService =
    usePerformanceProProductionService(cancellationToken);
  const options = usePerformanceProFilterOptions();
  const productionTimeWindow = useProductionTime();
  const awaitingPerformanceProInitialState =
    useAwaitingPerformanceProInitialState();
  const { selectedDate, selectedDatePeriod } = options;
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();

  const [data, setData] = useState<MachineStopsGroup[]>([]);
  const [awaitingResponse, setAwaitingResponse] = useState(true);

  useEffect(() => {
    if (awaitingPerformanceProInitialState) {
      // Display loading indicator until both global initial state and local state is loaded
      setAwaitingResponse(true);
      return;
    }

    const periodType = ConvertDatePeriod(options.selectedDatePeriod);
    const fromDate = getFirstOfPeriod(
      options.selectedDate,
      options.selectedDatePeriod
    );

    if (selectedDatePeriod === DatePeriod.Day && productionTimeWindow) {
      productionService
        .getMachineStopsDayView(machineId, selectedDate)
        .then((response) => {
          let groups: MachineStopsGroup[] = [];

          let machineStopsPeriods = response.data.groups[0].periods.filter(
            (x: any) =>
              x.hour >= productionTimeWindow.productionStartTime &&
              x.hour <= productionTimeWindow.productionEndTime
          );
          groups.push(mapDayToGroup(machineStopsPeriods, "MachineStop"));

          let staffBreakPeriods = response.data.groups[1].periods.filter(
            (x: any) =>
              x.hour >= productionTimeWindow.productionStartTime &&
              x.hour <= productionTimeWindow.productionEndTime
          );
          groups.push(mapDayToGroup(staffBreakPeriods, "StaffBreak"));

          let usedBreakPeriods = response.data.groups[2].periods.filter(
            (x: any) =>
              x.hour >= productionTimeWindow.productionStartTime &&
              x.hour <= productionTimeWindow.productionEndTime
          );
          groups.push(mapDayToGroup(usedBreakPeriods, "UsedDesignatedBreaks"));

          let unusedBreakPeriods = response.data.groups[3].periods.filter(
            (x: any) =>
              x.hour >= productionTimeWindow.productionStartTime &&
              x.hour <= productionTimeWindow.productionEndTime
          );
          groups.push(
            mapDayToGroup(unusedBreakPeriods, "UnusedDesignatedBreaks")
          );

          setData(groups);
          setAwaitingResponse(false);
        })
        .catch((reason) => {
          if (!reason.isCancelled) {
            dispatchAlert({
              message: t("generic.errorMessage"),
              messageType: "error",
            });
          }
        });
    } else {
      Promise.all([
        service.getMachinePerformance({
          machineId: machineId,
          measure: KpiMeasureType.MachineStop,
          periodType: periodType,
          fromDate: fromDate,
        }),
        service.getMachinePerformance({
          machineId: machineId,
          measure: KpiMeasureType.PauseTime,
          periodType: periodType,
          fromDate: fromDate,
        }),
      ])
        .then((response) => {
          let groups: MachineStopsGroup[] = [];
          groups.push(
            mapPerformanceToGroup(
              response[0].data.performancePeriods,
              "MachineStop"
            )
          );
          groups.push(
            mapPerformanceToGroup(
              response[1].data.performancePeriods,
              "StaffBreak"
            )
          );

          setData(groups);
          setAwaitingResponse(false);
        })
        .catch((reason) => {
          if (!reason.isCancelled) {
            dispatchAlert({
              message: t("generic.errorMessage"),
              messageType: "error",
            });
          }
        });

      return () => {
        cancellationToken.cancel();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    machineId,
    options.selectedDate,
    options.selectedDatePeriod,
    selectedDate,
    selectedDatePeriod,
    productionTimeWindow,
    awaitingPerformanceProInitialState,
  ]);

  return {
    options: options,
    data: data,
    awaitingResponse: awaitingResponse,
  };
}

function mapPerformanceToGroup(
  periods: PerformancePeriod[],
  groupName: string
): MachineStopsGroup {
  var grouped = periods.reduce(
    (r, v, i, a, k = v.eggType) => (
      ((r as any)[k] || ((r as any)[k] = [])).push(v), r
    ),
    {}
  );
  // Because the endpoint is generic, we need to add an eggtype.
  // Don't use AllEggTypes, because the values are times 5 for all eggs then.
  // Instead, use EggType Unknown.
  let periodsWithoutDuplications = (grouped as any[])[EggType.Unknown];

  return {
    group: groupName,
    periods: periodsWithoutDuplications.map(
      (item: PerformancePeriod) =>
        ({
          hour: item.dateTime,
          value: item.value,
        } as MachineStopsPeriod)
    ),
  };
}

function mapDayToGroup(
  inputPeriods: any[],
  groupName: string
): MachineStopsGroup {
  if (inputPeriods === undefined) {
    inputPeriods = [];
  } else {
    inputPeriods = inputPeriods.map(
      (item: any) =>
        ({
          hour: item.hour,
          value: item.value,
        } as MachineStopsPeriod)
    );
  }

  return {
    group: groupName,
    periods: inputPeriods,
  };
}
