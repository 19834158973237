import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReportService } from "../../../api/ServiceContext";
import { isEmptyArray } from "../../../utility/ArrayUtility";
import { secondsInAMinute, secondsInAnHour } from "../../../utility/DateUtility";
import useRefresh from "../../Dashboard/useRefresh";
import MachineRunningWidgetPresentation, {
  MachineRunningApiData,
  MachineRunningApiResponse
} from "./MachineRunning";

export type MachineRunningWidgetProps = {
  machineId: number;
  automaticRefresh: boolean;
};

/**
 * Shows the running state of the machine for the last 30 minutes.
 * The widget summarizes the last consecutive period of the current state (running/stopped).
 */
export default function MachineRunningWidgetContainer({
  machineId,
  automaticRefresh,
}: MachineRunningWidgetProps) {
  const { t } = useTranslation();

  const reportService = useReportService();

  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [runningMessage, setRunningMessage] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [data, setData] = useState<MachineRunningApiData | any>(null);

  const calculateRunningStateTime = useCallback(
    (durationInSeconds: number): string => {
      const differenceInMinutes =
        ~~(durationInSeconds / secondsInAMinute) % 60;
      const differenceInHours = ~~(durationInSeconds / secondsInAnHour);

      const differenceInSecondsToDisplay = ~~(
        durationInSeconds
        - (differenceInHours * secondsInAnHour)
        - (differenceInMinutes * secondsInAMinute)
      );

      const minuteText =
        differenceInMinutes === 1
          ? t("widgets.machineRunning.minute")
          : t("widgets.machineRunning.minutes");

      const hourText =
        differenceInHours === 1
          ? t("widgets.machineRunning.hour")
          : t("widgets.machineRunning.hours");

      const secondText =
        durationInSeconds === 1
          ? t("widgets.machineRunning.second")
          : t("widgets.machineRunning.seconds");

      return `${differenceInHours} ${hourText} ${differenceInMinutes} ${minuteText} ${differenceInSecondsToDisplay} ${secondText}`;
    },
    [t]
  );

  const setRunningStateMessage = useCallback(
    (apiReponse: MachineRunningApiData) => {
      const runningState =
        apiReponse.isRunning
          ? t("widgets.machineRunning.runningMessage")
          : t("widgets.machineRunning.stoppedMessage");

      const runningStateTime = calculateRunningStateTime(apiReponse.stateDurationSeconds);

      setRunningMessage(`${runningState} ${runningStateTime}`);
      setIsRunning(apiReponse.isRunning);
    },
    [calculateRunningStateTime, t]
  );

  const fetchData = useCallback(
    (newMachineId: number) => {
      reportService
        .getMachineRunning(newMachineId)
        .then((response: MachineRunningApiResponse) => {
          setAwaitingResponse(false);
          if (isEmptyArray(response.data.machineRunningPeriods)) {
            setIsRunning(false);
            setRunningMessage(t("widgets.machineRunning.noDataMessage"));
            return;
          }

          setRunningStateMessage(response.data);

          setData(response.data);
        });
    },
    [reportService, t, setRunningStateMessage]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return (
    <MachineRunningWidgetPresentation
      awaitingResponse={awaitingResponse}
      isRunning={isRunning}
      runningMessage={runningMessage}
      data={data}
    />
  );
}
