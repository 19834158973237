import { Card, CardContent, makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import MachineInformationCardSkeleton from "./MachineInformationCardSkeleton";

export function MachineInformationCardContainer({
  selectedMachine,
  actions,
  isLoading,
}: {
  selectedMachine: OwnerMachine | null;
  actions: any;
  isLoading: boolean;
}) {
  return isLoading || !selectedMachine ? (
    <MachineInformationCardSkeleton />
  ) : (
    <MachineInformationCardPresentation
      machineName={selectedMachine.machineName}
      machineType={selectedMachine.machineType}
      isApproved={selectedMachine.isApproved}
      connectedOnDate={selectedMachine.machineConnectionTime}
      numberOfMachineAdmins={selectedMachine.numberOfMachineAdmins}
      numberOfMachineUsers={selectedMachine.numberOfMachineUsers}
      actions={actions}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    paddingBottom: "4px",
  },
  machineUsersSection: {
    display: "flex",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  machineUsersSectionWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  machineNameAndConnectionSection: {
    display: "flex",
  },
  machineConnectionChip: {
    marginLeft: "auto",
  },
}));

function MachineInformationCardPresentation({
  machineName,
  machineType,
  connectedOnDate,
  numberOfMachineUsers,
  numberOfMachineAdmins,
  isApproved,
  actions,
}: {
  machineName: string;
  machineType: string;
  connectedOnDate: Date;
  numberOfMachineUsers: number;
  numberOfMachineAdmins: number;
  isApproved: boolean;
  actions: any | undefined;
}) {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardContent className={classes.cardWrapper}>
        <MachineName machineName={machineName} isApproved={isApproved} />
        <MachineType machineType={machineType} />
        {isApproved && connectedOnDate && <ConnectedOn connectedOnDate={connectedOnDate} />}
        <UsersAndAdminsSection
          numberOfMachineUsers={numberOfMachineUsers}
          numberOfMachineAdmins={numberOfMachineAdmins}
        />
      </CardContent>
      {actions !== undefined && actions}
    </Card>
  );

  function MachineName({
    machineName,
    isApproved,
  }: {
    machineName: string;
    isApproved: boolean;
  }) {
    const classes = useStyles();

    return (
      <div className={classes.machineNameAndConnectionSection}>
        <Typography variant="h6">{machineName}</Typography>
        <MachineConnectionStatus isApproved={isApproved} />
      </div>
    );
  }

  function MachineType({ machineType }: { machineType: string }) {
    return (
      <Typography variant="body2" color="textSecondary">
        {machineType}
      </Typography>
    );
  }

  function ConnectedOn({ connectedOnDate }: { connectedOnDate: Date }) {
    const { t } = useTranslation();

    return (
      <Typography variant="body2">
        {t("ownerPage.machineCard.connectedOnMessage", {
          connectedOnDate: format(connectedOnDate, "dd/MM/yyyy HH:mm"),
        })}
      </Typography>
    );
  }

  function UsersAndAdminsSection({
    numberOfMachineUsers,
    numberOfMachineAdmins,
  }: {
    numberOfMachineUsers: number;
    numberOfMachineAdmins: number;
  }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <div className={classes.machineUsersSectionWrapper}>
        <Divider />
        <div className={classes.machineUsersSection}>
          <Typography variant="body2">
            <b>{t("ownerPage.machineCard.usersLabel")}</b>
            {numberOfMachineUsers}
          </Typography>
          <Typography variant="body2" className={classes.marginLeft}>
            <b>{t("ownerPage.machineCard.adminsLabel")}</b>
            {numberOfMachineAdmins}
          </Typography>
        </div>
        <Divider />
      </div>
    );
  }

  function MachineConnectionStatus({ isApproved }: { isApproved: boolean }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <div className={classes.machineConnectionChip}>
        {isApproved ? (
          <div>
            <Chip
              label={t("ownerPage.machineCard.approvedChipLabel")}
              style={{
                backgroundColor: "#5cb85c",
                color: "#ffffff",
              }}
            />
          </div>
        ) : (
          <div>
            <Chip label={t("ownerPage.machineCard.notApprovedChipLabel")} />
          </div>
        )}
      </div>
    );
  }
}
