import {
  BulletList,
  Header,
  PageHeader,
  Paragraph,
  TextInfoPage,
} from "../../components/Page";

export default function AboutPage() {
  const P = Paragraph;
  const H = Header;
  const BL = BulletList;

  return (
    <TextInfoPage>
      <PageHeader>
        iMoba: All the info you need at your fingertips... anywhere
      </PageHeader>
      <P>
        Every modern packing station will recognize the need for employees
        collecting numbers on a regular base and use this data for creating
        management information dashboards. This is a lot of work with the risk
        of making errors.
      </P>
      <P>
        iMoba changes this completely. iMoba is a cloud solution that offers
        instant insights on a management level with the option to drill down to
        detailed overviews. Information is shown in simple to read graphs and
        overviews. The results can be reviewed on any smartphone, tablet or
        computer as the system is completely web based.
      </P>
      <H>Performance Pro Module</H>
      <P>
        iMoba is a service where customers can subscribe to without the need of
        initial high investments. The first module "Performance Pro" offers
        instant management information about:
      </P>
      <BL>
        <ul>
          <li>
            Grader statistics such as graded eggs, machine availability,
            production time and throughput data
          </li>
          <li>Production information about different egg-types</li>
          <li>Production information about different supplies</li>
          <li>
            Most information is shown in easy to read graphs that can be plot
            per day, week, month or year
          </li>
        </ul>
      </BL>
      <H>Realtime Dashboard Module</H>
      <P>
        The second module “Realtime dashboard” provides you the actual status of
        your Omnia grader. This module allows the user to create a customized
        dashboard for the information that is important to that particular user.
      </P>
      <H>Benchmark Module</H>
      <P>
        The third module is the “Benchmark” module. This module allows you to
        compare the performance of your machine(s) against a set of anonymous
        machines. By default this set exists of all connected machines. You can
        apply a filter to select a subset that is more relevant to your
        situation. To ensure anonymity the comparison set contains at least 10
        machines.
      </P>
    </TextInfoPage>
  );
}
