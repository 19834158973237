import FormControl from "@material-ui/core/FormControl";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import "date-fns";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { toISO8601DateStringLocal } from "../../../utility/DateUtility";
import { t } from "../../../utility/TranslateUtility";

export interface ToolbarDatePickerProps {
  selectedDate: Date;
  onDateChange: (newDate: Date) => void;
}

/**
 * A DatePicker that can be used in a toolbar.
 */
export function ToolbarDatePicker({
  selectedDate,
  onDateChange,
}: ToolbarDatePickerProps) {
  const isMobileSize = useIsMobileSize();

  function handleOnChange(newDate?: MaterialUiPickersDate) {
    const result = newDate?.getTime();

    if (!result) return;

    const resultDate = new Date(result);

    if (
      toISO8601DateStringLocal(resultDate) ===
      toISO8601DateStringLocal(selectedDate)
    )
      return;

    onDateChange(resultDate);
  }

  return (
    <FormControl fullWidth={isMobileSize}>
      <KeyboardDatePicker
        disableToolbar
        disableFuture
        variant="inline"
        label={t("toolbarDatePicker.label")}
        value={selectedDate}
        onChange={(date) => handleOnChange(date)}
        autoOk={true}
      />
    </FormControl>
  );
}
