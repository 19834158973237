import { Chip, makeStyles, TableCell } from "@material-ui/core";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { colors } from "../../../colors";
import { determineEggTypeName, EggType } from "../../../utility/EggUtility";
import { FormatWithTwoDecimals } from "../../../utility/NumberFormatUtility";
import { GetSupplierNameAndShedURI } from "../../../utility/SupplyUtility";
import { StyledTableRow } from "../../UI/Table/Table";
import { SupplyRanking } from "./Common";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1),
  },
  statusChip: {
    margin: theme.spacing(1),
    color: "white",
  },
}));

interface Props {
  supplyRanking: SupplyRanking;
  columnsToDisplay: Array<keyof SupplyRanking>;
  brownDetectionActive: boolean;
  shellStrengthActive: boolean;
}

export function SupplyRankingRow({
  supplyRanking,
  columnsToDisplay,
  brownDetectionActive,
  shellStrengthActive
}: Props) {
  const tableHtml = useMemo(() => {
    let result = [];

    for (const column of columnsToDisplay) {
      const supplierNameAndShedURI = GetSupplierNameAndShedURI(
        supplyRanking.supplierNameShed
      );
      switch (column) {
        case "supplierNameShed":
          result.push(
            <TableCell key={column}>
              <Link to={supplierNameAndShedURI}>
                {supplyRanking.supplierNameShed}
              </Link>
            </TableCell>
          );
          break;
        case "averageEggWeight":
          result.push(
            <TableCell key={column}>
              {FormatWithTwoDecimals(supplyRanking.averageEggWeight)}
            </TableCell>
          );
          break;
        case "averageColor":
          if (brownDetectionActive) {
            result.push(
              <TableCell key={column}>
                {FormatWithTwoDecimals(supplyRanking.averageColor)}
              </TableCell>
            );
          } else {
            result.push(<TableCell key={column}>-</TableCell>);
          }
          break;
        case "averageShellStrength":
          if (shellStrengthActive) {
            result.push(
              <TableCell key={column}>
                {FormatWithTwoDecimals(supplyRanking.averageShellStrength)}
              </TableCell>
            );
          } else {
            result.push(<TableCell key={column}>-</TableCell>);
          }
          break;
        case "eggTypes":
          result.push(
            <EggTypeCell
              key={column}
              eggTypes={supplyRanking.eggTypes}
            ></EggTypeCell>
          );
          break;
        case "offgradeEggsPercentage":
          result.push(
            <TableCell key={column}>
              {FormatWithTwoDecimals(supplyRanking.offgradeEggsPercentage)}
            </TableCell>
          );
          break;
        case "offgradeEggsNumber":
          result.push(
            <TableCell key={column}>
              {supplyRanking.offgradeEggsNumber}
            </TableCell>
          );
          break;
        case "totalEggsNumber":
          result.push(
            <TableCell key={column}>{supplyRanking.totalEggsNumber}</TableCell>
          );
          break;
        case "ticketNumbers":
          result.push(
            <TableCell key={column}>
              {supplyRanking.ticketNumbers.join(" ,")}
            </TableCell>
          );
          break;
        default:
          break;
      }
    }
    return result;
  }, [columnsToDisplay]);

  return <StyledTableRow>{tableHtml}</StyledTableRow>;
}

function EggTypeCell({ eggTypes }: { eggTypes: EggType[] }) {
  const supplyRankingRowclasses = useStyles();

  return (
    <TableCell align="left">
      {eggTypes.map((eggType) => (
        <Chip
          key={eggType}
          label={determineEggTypeName(eggType)}
          className={supplyRankingRowclasses.statusChip}
          style={{
            backgroundColor: determineColor(eggType),
          }}
        />
      ))}
    </TableCell>
  );
}

function determineColor(eggType: EggType): string {
  switch (eggType) {
    case EggType.FreeRange:
      return colors.freeRangeEgg;
    case EggType.Barn:
      return colors.barnEgg;
    case EggType.Cage:
      return colors.cageEgg;
    case EggType.Organic:
      return colors.organicEgg;
    case EggType.Unmapped:
    case EggType.AllEggTypes:
    case EggType.Mapped:
    case EggType.Unknown:
      return colors.unmappedEgg;
    default:
      return colors.unmappedEgg;
  }
}
