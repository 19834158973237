import { SeriesType } from "../MachineMeasureComparison/MachineMeasureComparison";

export const DefaultBestWorstSeriesSettings = {
  type: "line",
  opacity: 1,
  fillOpacity: 1,
  zIndex: 5,
};

export const DefaultDifferenceSeriesSettings = {
  showInLegend: false,
  tooltip: null,
  type: "arearange",
  marker: {
    enabled: false,
  },
  includeInDataExport: false,
  zIndex: 1,
};

export interface Series {
  name?: string;
  data: number[];
  color: string;
  lineColor: string;
  lineWidth?: number;
  type: string;
  opacity?: number;
  fillOpacity?: number;
  showInLegend?: boolean;
  linkedTo?: string;
  id?: string;
  legendColor?: string;
  tooltip?: any;
  enableMouseTracking?: boolean;
  visible?: boolean;
  custom: {
    seriesType: SeriesType;
    actualValues: number[];
    categories?: (string | undefined)[];
  };
  zIndex?: number;
  marker?: {
    enabled?: boolean;
  };
  includeInDataExport?: boolean;
}

export interface DifferenceSeries {
  name?: string;
  data: { high: number; low: number }[];
  color: string;
  lineColor: string;
  lineWidth?: number;
  type: string;
  opacity?: number;
  fillOpacity?: number;
  showInLegend?: boolean;
  linkedTo?: string;
  id?: string;
  legendColor?: string;
  tooltip?: any;
  enableMouseTracking?: boolean;
  visible?: boolean;
  custom: {
    seriesType: SeriesType;
  };
  zIndex?: number;
  marker?: {
    enabled?: boolean;
  };
  includeInDataExport?: boolean;
}
