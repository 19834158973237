import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { t } from "../../utility/TranslateUtility";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(35),
    margin: theme.spacing(2),
    marginLeft: 0,
  },
}));

export interface EventLogsListSearchpProps {
  searchTerm: string;
  isLoading: boolean;
  onChange: (searchTerm: string) => void;
}

function EventLogsListSearch({
  searchTerm,
  isLoading,
  onChange,
}: EventLogsListSearchpProps) {
  const classes = useStyles();

  return (
    <div className={classes.searchContainer}>
      {isLoading ? (
        <Skeleton variant="rect" width={300} height={50} animation="wave" />
      ) : (
        <TextField
          value={searchTerm}
          onChange={(ev) => onChange(ev.target.value)}
          variant="outlined"
          placeholder={t("ownerPage.eventLogsMessages.searchplaceholder")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    </div>
  );
}

export default EventLogsListSearch;
