import React from "react";
import MachineMeasureComparison from "./MachineMeasureComparison";
import { useMachineMeasure } from "./machineMeasureComparisonHooks";
import { Measure } from "./Types";

export function MachineMeasureComparisonContainer({
  measure,
  title,
}: {
  measure: Measure;
  title: string;
}) {
  const { data, awaitingResponse, myMachines } = useMachineMeasure({
    measure: measure,
  });

  return (
    <MachineMeasureComparison
      awaitingResponse={awaitingResponse}
      data={data}
      title={title}
      machinesToDisplay={myMachines}
    />
  );
}
