import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

export function useTermsAndConditionsTracking() {
  const appInsights = useAppInsightsContext();
  const termsAndConditionsAcceptedEvent = useTrackEvent(
    appInsights,
    "Terms and Conditions accepted",
    {}
  );

  const trackTermsAndConditionsAccepted = () => {
    termsAndConditionsAcceptedEvent({});
  };

  return { trackTermsAndConditionsAccepted };
}

export function useManagementMachinePageTracking() {
  const appInsights = useAppInsightsContext();
  const subscriptionActivatedEvent = useTrackEvent(
    appInsights,
    "Machine Subscription Activated",
    {}
  );
  const autoRenewActivatedEvent = useTrackEvent(
    appInsights,
    "Machine Subscription Auto Renew Activated",
    {}
  );
  const autoRenewDeactivatedEvent = useTrackEvent(
    appInsights,
    "Machine Subscription Auto Renew Deactivated",
    {}
  );

  const trackSubscriptionActivated = () => {
    subscriptionActivatedEvent({});
  };

  const trackAutoRenewActivated = () => {
    autoRenewActivatedEvent({});
  };

  const trackAutoRenewDeactivated = () => {
    autoRenewDeactivatedEvent({});
  };

  return {
    trackSubscriptionActivated,
    trackAutoRenewActivated,
    trackAutoRenewDeactivated,
  };
}
