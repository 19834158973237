import FormControl from "@material-ui/core/FormControl";
import {
  KeyboardTimePicker,
  TimePicker as MuiTimePicker
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";

export function TimePicker({
  selectedTime,
  onTimeChange,
  label,
  className,
}: {
  selectedTime: Date;
  onTimeChange: (newTime: Date) => void;
  label: string;
  className?: string;
}) {
  const isMobileSize = useIsMobileSize('xs');

  function handleOnChange(newDate?: MaterialUiPickersDate) {
    if (!newDate) return;
    const resultDate = new Date(newDate.getTime());

    onTimeChange(resultDate);
  }

  return (
    <div className={className}>
      <FormControl>
        {isMobileSize ? (
          <MuiTimePicker
            ampm={false}
            variant="dialog"
            label={label}
            value={selectedTime}
            onChange={(date: MaterialUiPickersDate) => handleOnChange(date)}
          />
        ) : (
          <KeyboardTimePicker
            ampm={false}
            variant="inline"
            label={label}
            value={selectedTime}
            onChange={(date: MaterialUiPickersDate) => handleOnChange(date)}
          />
        )}
      </FormControl>
    </div>
  );
}
