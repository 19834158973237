import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  infoPage: {
    paddingTop: theme.spacing(5),
  },
  infoPaper: {
    padding: theme.spacing(5),
  },
}));

export function TextInfoPage({ children }: any) {
  const classes = useStyles();

  return (
    <Container className={classes.infoPage}>
      <Paper className={classes.infoPaper} elevation={0}>
        {children}
      </Paper>
    </Container>
  );
}
