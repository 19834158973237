import { useState } from "react";
import i18n, { DefaultLanguage } from "../i18n";

export const t = i18n.t.bind(i18n);

export function GetCurrentLanguage(): string {
  return i18n.language;
}

export function GetAllLanguages(): string[] {
  return Object.keys(i18n.services.resourceStore.data);
}

export function ChangeLanguage(langauge: string): void {
  i18n.changeLanguage(langauge);
}

export const LanguageMapping: Record<string, string> = {
  "zh-CN": "Chinese",
  "de-DE": "German",
  "en-US": "English",
  "fr-FR": "French",
  "it-IT": "Italian",
  ja: "Japanese",
  "nl-NL": "Dutch",
  "pt-PT": "Portugese",
  ru: "Russian",
  "es-ES": "Spanish",
};

export function useCurrentLanguage(key: string, initialValue?: string) {
  if (!initialValue) initialValue = DefaultLanguage;
  const [storedValue, setStoredValue] = useState<any>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? item : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: string) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, value);
      ChangeLanguage(value);
    } catch (error) {}
  };

  return [storedValue, setValue];
}

export function nbsp(value: string): string {
  return value.split(" ").join("\u00A0");
}
