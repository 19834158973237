import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { useChartLoading } from "../Charts/chartLoadingHooks";
import SkeletonCardWrapper, {
  SkeletonVariant,
} from "../Widgets/Wrappers/SkeletonCardWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  skeleton: {
    marginTop: theme.spacing(1),
  },
  isLoading: {
    marginTop: theme.spacing(1),
    filter: "blur(3px) grayscale(1)",
    pointerEvents: "none",
    transition: "1s filter linear",
  },
}));

export function LoadingWrapper({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: JSX.Element | Array<JSX.Element>;
}) {
  const classes = useStyles();

  const {
    displayFirstLoadingIndicator,
    displaySecondLoadingIndicator,
  } = useChartLoading(isLoading);

  return displayFirstLoadingIndicator ? (
    <div className={classes.skeleton}>
      <SkeletonCardWrapper variant={SkeletonVariant.BenchmarkGraph} />
    </div>
  ) : (
    <>
      {displaySecondLoadingIndicator && <LinearProgress />}
      <div
        className={clsx({
          [classes.root]: !isLoading,
          [classes.isLoading]: displaySecondLoadingIndicator,
        })}
      >
        {children}
      </div>
    </>
  );
}
