import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useMemo } from "react";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { useBenchmarkState } from "../../../store/GlobalContext";
import { t } from "../../../utility/TranslateUtility";
import { Measure } from "../MachineMeasureComparison";
import { BenchmarkColors } from "../MachineMeasureComparison/chartSettings";
import { ChartCardWithDescription } from "../MachineMeasureComparison/Common";
import {
  MachineComparison,
  MeasureData
} from "../MachineMeasureComparison/Types";
import { tooltipFormatterRadar } from "./MachineMeasureRadarTooltipBuilder";
import { DefaultRadarChartSettings } from "./radarChartSettings";
import {
  DefaultBestWorstSeriesSettings,
  DefaultDifferenceSeriesSettings,
  DifferenceSeries,
  Series
} from "./Types";

const bestMeasureLineColor = BenchmarkColors.RedLine;
const bestMeasureColor = BenchmarkColors.RedSpace;
const worstMeasureLineColor = BenchmarkColors.GreenLine;
const worstMeasureColor = BenchmarkColors.GreenSpace;

const getBestSeries = (
  measureData: MeasureData,
  displayValueMulitpliers: number[]
): Series => {
  const displayValues: number[] = [];
  const actualValues: number[] = [];

  for (let i = 0; i < measureData.data.length; i++) {
    const category = measureData.data[i];

    const categoryAverage = getAverageForBestMachineMeasures(category);

    displayValues.push(categoryAverage * displayValueMulitpliers[i]);
    actualValues.push(categoryAverage);
  }

  return {
    ...DefaultBestWorstSeriesSettings,
    name: t("benchmark.best3Machines"),
    data: displayValues,
    color: bestMeasureLineColor,
    lineColor: bestMeasureLineColor,
    custom: {
      seriesType: "best3",
      actualValues: actualValues,
      categories: getCategories(measureData),
    },
  };
};

const getWorstSeries = (
  measureData: MeasureData,
  displayValueMulitpliers: number[]
): Series => {
  const displayValues: number[] = [];
  const actualValues: number[] = [];

  for (let i = 0; i < measureData.data.length; i++) {
    const category = measureData.data[i];

    const totalPeriods = category.data.worstMachinesMeasures.length;
    const totalValue = category.data.worstMachinesMeasures.reduce<number>(
      (acc, curr) => {
        return acc + curr.value;
      },
      0
    );

    const categoryAverage = (totalValue / totalPeriods) * 100;
    displayValues.push(categoryAverage * displayValueMulitpliers[i]);
    actualValues.push(categoryAverage);
  }

  return {
    ...DefaultBestWorstSeriesSettings,
    name: t("benchmark.worst3Machines"),
    data: displayValues,
    color: worstMeasureLineColor,
    lineColor: worstMeasureLineColor,
    legendColor: worstMeasureLineColor,
    custom: {
      seriesType: "worst3",
      actualValues: actualValues,
      categories: getCategories(measureData),
    },
  };
};

const getMyMachinesAndAverageSeries = (
  measureData: MeasureData,
  myMachines: string[],
  displayValueMulitpliers: number[]
): Series[] => {
  let series: Series[] = [];

  if (measureData.data.length > 0) {
    const machines: MachineId[] = [];
    measureData.data[0].data.comparisonMachinesMeasures.forEach((x) => {
      if (
        !machines.find((y) => y === x.machineId) &&
        myMachines.includes(x.machineId.toString())
      )
        machines.push(x.machineId);
    });

    for (let machineId of machines) {
      const displayValues: number[] = [];
      const actualValues: number[] = [];
      for (let i = 0; i < measureData.data.length; i++) {
        const category = measureData.data[i];
        const dataForMachineId =
          category.data.comparisonMachinesMeasures.filter(
            (x) => x.machineId === machineId
          );

        const totalPeriods = dataForMachineId.length;
        const totalValue = dataForMachineId.reduce<number>((acc, curr) => {
          return acc + curr.value;
        }, 0);

        const categoryAverage = (totalValue * 100) / totalPeriods;
        displayValues.push(categoryAverage * displayValueMulitpliers[i]);
        actualValues.push(categoryAverage);
      }

      series.push({
        name: machineId.toString(),
        data: displayValues,
        color: BenchmarkColors.GreyLine,
        lineColor: BenchmarkColors.GreyLine,
        legendColor: BenchmarkColors.GreyLine,
        type: "line",
        opacity: 1,
        fillOpacity: 1,
        custom: {
          seriesType: "machine",
          actualValues: actualValues,
          categories: getCategories(measureData),
        },
        zIndex: 5,
      });
    }

    if (myMachines.includes("AVG")) {
      const displayValues: number[] = [];
      const actualValues: number[] = [];
      for (let i = 0; i < measureData.data.length; i++) {
        const categoryTotalValue = series.reduce((acc, curr) => {
          return acc + curr.custom.actualValues[i];
        }, 0);

        const categoryAverage = categoryTotalValue / machines.length;
        displayValues.push(categoryAverage * displayValueMulitpliers[i]);
        actualValues.push(categoryAverage);
      }

      series.push({
        name: t("benchmark.average"),
        data: displayValues,
        color: BenchmarkColors.BlueLine,
        lineColor: BenchmarkColors.BlueLine,
        lineWidth: 2,
        type: "line",
        opacity: 1,
        fillOpacity: 1,
        custom: {
          seriesType: "machine-avg",
          actualValues: actualValues,
          categories: getCategories(measureData),
        },
        zIndex: 5,
      });
    }
  }

  return series;
};

const getDifferenceSeriesToWorstMachines = (
  measureData: MeasureData,
  myMachinesSeries: Series[],
  worstMachineSeries: Series
): DifferenceSeries => {
  const seriesData: { high: number; low: number }[] = [];
  for (let i = 0; i < measureData.data.length; i++) {
    const myMachinenValues = myMachinesSeries
      .filter((x) => x.name !== "Average")
      .map((x) => x.data[i]);

    seriesData.push({
      high: Math.min(...myMachinenValues),
      low: worstMachineSeries.data[i],
    });
  }

  return {
    ...DefaultDifferenceSeriesSettings,
    id: "differenceBetweenWorstMachines",
    linkedTo: "differenceBetweenBestAndWorstMyMachines",
    data: seriesData,
    color: worstMeasureColor,
    lineColor: worstMeasureLineColor,
    custom: {
      seriesType: "difference",
    },
  };
};

const getDifferenceSeriesToBestMachines = (
  measureData: MeasureData,
  myMachinesSeries: Series[],
  bestMachineSeries: Series
): DifferenceSeries => {
  const seriesData: { high: number; low: number }[] = [];
  for (let i = 0; i < measureData.data.length; i++) {
    const myMachineValues = myMachinesSeries
      .filter((x) => x.name !== "average")
      .map((x) => x.data[i]);

    seriesData.push({
      high: Math.max(...myMachineValues),
      low: bestMachineSeries.data[i],
    });
  }

  return {
    ...DefaultDifferenceSeriesSettings,
    id: "differenceBetweenBestMachines",
    linkedTo: "differenceBetweenBestAndWorstMyMachines",
    data: seriesData,
    color: bestMeasureColor,
    lineColor: bestMeasureLineColor,
    custom: {
      seriesType: "difference",
    },
  };
};

const getDifferenceSeriesForMyMachines = (
  measureData: MeasureData,
  myMachinesSeries: Series[]
): DifferenceSeries => {
  const seriesData: { high: number; low: number }[] = [];
  for (let i = 0; i < measureData.data.length; i++) {
    const myMachinenValues = myMachinesSeries
      .filter((x) => x.name !== "average")
      .map((x) => x.data[i]);

    seriesData.push({
      high: Math.max(...myMachinenValues),
      low: Math.min(...myMachinenValues),
    });
  }

  return {
    ...DefaultDifferenceSeriesSettings,
    id: "differenceBetweenBestAndWorstMyMachines",
    data: seriesData,
    color: BenchmarkColors.GreySpace,
    lineColor: BenchmarkColors.GreyLine,
    custom: {
      seriesType: "difference",
    },
  };
};

const getAverageForBestMachineMeasures = (
  machineComparison: MachineComparison
) => {
  const totalPeriods = machineComparison.data.bestMachinesMeasures.length;
  const totalValue = machineComparison.data.bestMachinesMeasures.reduce<number>(
    (acc, curr) => {
      return acc + curr.value;
    },
    0
  );

  return (totalValue / totalPeriods) * 100;
};

/**
  Because it is not possible to use multiple y axis scales on a radar chart,
  small values need to be mulitplied to make them visible on the chart. 
 */
const getDisplayValueMulitpliers = (measureData: MeasureData): number[] => {
  const multipliers: number[] = [];

  for (let category of measureData.data) {
    const categoryAverage = getAverageForBestMachineMeasures(category);
    if (categoryAverage < 2) multipliers.push(50);
    else if (categoryAverage < 5) multipliers.push(20);
    else if (categoryAverage < 10) multipliers.push(10);
    else if (categoryAverage < 20) multipliers.push(5);
    else multipliers.push(1);
  }

  return multipliers;
};

const getCategories = (measureData: MeasureData) => {
  return measureData.data.map((x) => {
    switch (x.measure) {
      case "TableEggs":
        return t("benchmark.tableEggs.title");
      case "CapacityUtilization":
        return t("benchmark.capacityUtilization.title");
      case "UpgradeUtilization":
        return t("benchmark.upgradeUtilization.title");
      case "ProductionAvailability":
        return t("benchmark.productionAvailability.title");
      case "InputGradeA":
        return t("benchmark.inputGradeA.title");
      case "Upgrades":
        return t("benchmark.upgrades.title");
    }
  });
};

const measureOrder: { measure: Measure; order: number }[] = [
  { measure: "TableEggs", order: 1 },
  { measure: "InputGradeA", order: 2 },
  { measure: "ProductionAvailability", order: 3 },
  { measure: "CapacityUtilization", order: 4 },
  { measure: "UpgradeUtilization", order: 5 },
  { measure: "Upgrades", order: 6 },
];

const getSortedData = (
  measureData: MeasureData,
  measuresToShow: string[]
): MeasureData => {
  const sortFunction = (a: MachineComparison, b: MachineComparison) => {
    if (
      measureOrder.find((x) => x.measure === a.measure)!.order <
      measureOrder.find((x) => x.measure === b.measure)!.order
    )
      return -1;
    return 1;
  };

  return {
    ...measureData,
    data: [
      ...measureData.data
        .filter((x) => measuresToShow.indexOf(x.measure.toString()) !== -1)
        .sort(sortFunction),
    ],
  };
};

export default function MachineMeasureRadarGraphPresentation({
  measureData,
  measuresToShow,
}: {
  measureData: MeasureData;
  measuresToShow: string[];
}) {
  const benchmarkState = useBenchmarkState();
  const isMobileSize = useIsMobileSize();

  const chartOptions = useMemo(() => {
    const sortedData = getSortedData(measureData, measuresToShow);

    const displayValueMulitpliers = getDisplayValueMulitpliers(sortedData);

    const worstSeries = getWorstSeries(sortedData, displayValueMulitpliers);
    const bestSeries = getBestSeries(sortedData, displayValueMulitpliers);

    const myMachinesAndAverageSeries = getMyMachinesAndAverageSeries(
      sortedData,
      benchmarkState.editingFilterState.myMachines,
      displayValueMulitpliers
    );

    const worstMachineDifferenceSeries = getDifferenceSeriesToWorstMachines(
      sortedData,
      myMachinesAndAverageSeries,
      worstSeries
    );

    const bestMachinesDifferenceSeries = getDifferenceSeriesToBestMachines(
      sortedData,
      myMachinesAndAverageSeries,
      bestSeries
    );

    const myMachinesDifferenceSeries = getDifferenceSeriesForMyMachines(
      sortedData,
      myMachinesAndAverageSeries
    );

    return {
      ...DefaultRadarChartSettings,
      xAxis: {
        ...DefaultRadarChartSettings.xAxis,
        categories: [...getCategories(sortedData)],
      },
      tooltip: {
        ...tooltipFormatterRadar,
        shared: true,
      },
      series: [
        bestSeries,
        bestMachinesDifferenceSeries,
        myMachinesDifferenceSeries,
        worstSeries,
        worstMachineDifferenceSeries,
        ...myMachinesAndAverageSeries,
      ],
      legend: {
        ...DefaultRadarChartSettings?.legend,
        align: isMobileSize ? "bottom" : "right",
        verticalAlign: isMobileSize ? "bottom" : "middle",
      },
    };
  }, [
    measureData,
    measuresToShow,
    benchmarkState.editingFilterState.myMachines,
    isMobileSize
  ]);

  return (
    <ChartCardWithDescription
      headerTitle={t("benchmark.radarGraph.title")}
      isLoading={benchmarkState.awaitingRequestCount !== 0}
      desciption={t("benchmark.radarGraph.description")}
    >
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </ChartCardWithDescription>
  );
}
