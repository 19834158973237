import { FormControl, Radio, RadioGroup, Switch } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { QualityChartSetting } from "../../../pages/management-settings/Common";
import { SettingsCard } from "../Common";

const useStyles = makeStyles((theme: any) => ({
  contentWrapper: {
    display: "flex",
  },
  select: {
    minWidth: theme.spacing(12),
  },
  autoComplete: {
    width: 300,
    marginLeft: theme.spacing(6),
  },
}));

export type QualityChartsPresentationProps = {
  settings: QualityChartSetting;
  onSettingsChange: (settings: QualityChartSetting) => void;
};

export default function QualityChartsPresentation({
  settings,
  onSettingsChange,
}: QualityChartsPresentationProps) {
  const handleQualityChartsSettingChange = (value: string) => {
    onSettingsChange({ ...settings, qualityChartSetting: value });
  };

  const handleDisplayWorkShiftWidgetsSettingChange = (value: boolean) => {
    onSettingsChange({ ...settings, isDisplayWorkShiftWidgetsEnabled: value });
  };

  return (
    <>
      <QualityChartsSettingCard
        qualityChartsSetting={settings.qualityChartSetting}
        onChange={handleQualityChartsSettingChange}
      />
      <DisplayWorkShiftWidgetsCard
        isDisplayWorkShiftWidgetsEnabled={
          settings.isDisplayWorkShiftWidgetsEnabled
        }
        onChange={handleDisplayWorkShiftWidgetsSettingChange}
      />
    </>
  );
}

function QualityChartsSettingCard({
  qualityChartsSetting,
  onChange,
}: {
  qualityChartsSetting: string;
  onChange: (value: string) => void;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <SettingsCard title={t("managementSettings.views.qualityCharts")}>
      <div className={classes.contentWrapper}>
        <FormControl>
          <RadioGroup
            aria-label="qualityChartsSetting"
            name="qualityChartsSetting"
            value={qualityChartsSetting}
            onChange={(_, value: any) => onChange(value)}
          >
            <FormControlLabel
              value="InfeedBasedCharts"
              control={<Radio color={"primary"} />}
              label={t(
                "managementSettings.views.qualityChartsInfeedBasedChartLabel"
              )}
            />
            <FormControlLabel
              value="TakeawayBasedCharts"
              control={<Radio color={"primary"} />}
              label={t(
                "managementSettings.views.qualityChartsTakeawayBasedChartLabel"
              )}
            />
          </RadioGroup>
        </FormControl>
      </div>
    </SettingsCard>
  );
}

function DisplayWorkShiftWidgetsCard({
  isDisplayWorkShiftWidgetsEnabled,
  onChange,
}: {
  isDisplayWorkShiftWidgetsEnabled: boolean;
  onChange: (newValue: boolean) => void;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <SettingsCard title={t("managementSettings.views.displayWorkShiftWidgets")}>
      <div className={classes.contentWrapper}>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={isDisplayWorkShiftWidgetsEnabled}
                onChange={(event) => onChange(event.target.checked)}
                name="displayWorkShiftWidgets"
                color="primary"
              />
            }
            label={t("managementSettings.views.displayWorkShiftWidgetsLabel")}
          />
        </FormControl>
      </div>
    </SettingsCard>
  );
}
