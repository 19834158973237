export interface ReportView {
  machineId: number;
  sumEggsGraded: number;
  avgPercentageVsTarget: number;
  avgEggsPerHour: number;
  targetEggsPerHour: number;
  enableTeamSizeDataEntry: boolean;
  colorSchemas: ColorSchemna[];
  characteristicsInfoLabels: CharacteristicsInfoLabel[];
  downtimeInfoLabels: DowntimeInfoLabel[];
  productionReportHours: ProductionReportHourInfo[];
}

export interface ColorSchemna {
  schemaNumber: number;
  targetValue: number;
  colorHex: string;
}

export interface CharacteristicsInfoLabel {
  labelNumber: number;
  label: string;
}

export interface DowntimeInfoLabel {
  name: string;
  labelNumber: number;
}

export interface ProductionReportHourInfo {
  dateTimeHour: Date;
  eggsAchieved: number;
  percentageVsTarget: number;
  accumelatedEggsAchieved: number;
  accumelatedPercentageVsTarget: number;
  downTimeComment: string;
  downTimeInfo1: string;
  downTimeInfo2: string;
  downTimeInfo3: string;
  downTimeInfo4: string;
  downTimeInfo5: string;
  downTimeInfo6: string;
  characeristicInfo1: string;
  characeristicInfo2: string;
  characeristicInfo3: string;
  characeristicInfo4: string;
  characeristicInfo5: string;
  characeristicInfo6: string;
  downTimeSeconds: number;
  offgradesPercentage: number;
  leakersPercentage: number;
  fillRatePercentage: number;
  numberOfProductChanges: number;
  team: number;
  teamThroughput: number;
}

export function setCharacteristics(
  labels: Labels,
  returnObject: any,
  reportHourInfo: ProductionReportHourInfo
) {
  if (labels.characteristicsInfoLabel4) {
    returnObject[labels.characteristicsInfoLabel4.label] =
      reportHourInfo.characeristicInfo4;
  }
  if (labels.characteristicsInfoLabel5) {
    returnObject[labels.characteristicsInfoLabel5.label] =
      reportHourInfo.characeristicInfo5;
  }
  if (labels.characteristicsInfoLabel6) {
    returnObject[labels.characteristicsInfoLabel6.label] =
      reportHourInfo.characeristicInfo6;
  }
}

export function setDownTime(
  labels: Labels,
  returnObject: any,
  reportHourInfo: ProductionReportHourInfo
) {
  if (labels.downtimeInfoLabel1) {
    returnObject[labels.downtimeInfoLabel1.name] = reportHourInfo.downTimeInfo1;
  }
  if (labels.downtimeInfoLabel2) {
    returnObject[labels.downtimeInfoLabel2.name] = reportHourInfo.downTimeInfo2;
  }
  if (labels.downtimeInfoLabel3) {
    returnObject[labels.downtimeInfoLabel3.name] = reportHourInfo.downTimeInfo3;
  }
  if (labels.downtimeInfoLabel4) {
    returnObject[labels.downtimeInfoLabel4.name] = reportHourInfo.downTimeInfo4;
  }
  if (labels.downtimeInfoLabel5) {
    returnObject[labels.downtimeInfoLabel5.name] = reportHourInfo.downTimeInfo5;
  }
  if (labels.downtimeInfoLabel6) {
    returnObject[labels.downtimeInfoLabel6.name] = reportHourInfo.downTimeInfo6;
  }
}

export interface Labels {
  downtimeInfoLabel1?: DowntimeInfoLabel;
  downtimeInfoLabel2?: DowntimeInfoLabel;
  downtimeInfoLabel3?: DowntimeInfoLabel;
  downtimeInfoLabel4?: DowntimeInfoLabel;
  downtimeInfoLabel5?: DowntimeInfoLabel;
  downtimeInfoLabel6?: DowntimeInfoLabel;
  downTimeHeaderColSpan: number;
  characteristicsInfoLabel1?: CharacteristicsInfoLabel;
  characteristicsInfoLabel2?: CharacteristicsInfoLabel;
  characteristicsInfoLabel3?: CharacteristicsInfoLabel;
  characteristicsInfoLabel4?: CharacteristicsInfoLabel;
  characteristicsInfoLabel5?: CharacteristicsInfoLabel;
  characteristicsInfoLabel6?: CharacteristicsInfoLabel;
  characteristicsInfoHeaderColSpan: number;
}
