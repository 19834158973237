import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
import { Prompt } from "react-router-dom";
import { t } from "../../../utility/TranslateUtility";
import { SaveButton } from "../Common";
import { BreakTimeRowType } from "./BreakTimesContainer";
import {
  AddNewBreakButton,
  BreakTimeRow,
  DailyTotalInMinutesCount,
  RemoveAllBreaksButton,
} from "./Common";

export default function BreakTimesPresentation({
  rowsToDisplay,
  dailyTotalInMinutes,
  pendingChanges,
  isAwaitingResponse,
  onNewBreak,
  onDeleteRow,
  onTimeChanged,
  onRemoveAllBreaks,
  onSaveClick,
  onError,
  onErrorResolved,
}: {
  rowsToDisplay: Array<BreakTimeRowType>;
  dailyTotalInMinutes: number;
  pendingChanges: boolean;
  isAwaitingResponse: boolean;
  onNewBreak: () => void;
  onDeleteRow: (breakNumber: number) => void;
  onTimeChanged: (
    newStartTime: Date,
    newEndTime: Date,
    breakNumber: number
  ) => void;
  onRemoveAllBreaks: () => void;
  onSaveClick: () => void;
  onError: () => void;
  onErrorResolved: () => void;
}) {
  return (
    <>
      <SaveButton disabled={!pendingChanges} onClick={onSaveClick} />
      <Prompt
        when={pendingChanges}
        message={t("managementSettings.pendingChanges")}
      />
      {isAwaitingResponse ? (
        <CircularProgress />
      ) : (
        <BreakTimeSection
          rowsToDisplay={rowsToDisplay}
          dailyTotalInMinutes={dailyTotalInMinutes}
          onNewBreak={onNewBreak}
          onDeleteRow={onDeleteRow}
          onTimeChanged={onTimeChanged}
          onRemoveAllBreaks={onRemoveAllBreaks}
          onError={onError}
          onErrorResolved={onErrorResolved}
        />
      )}
    </>
  );
}

function BreakTimeSection({
  rowsToDisplay,
  dailyTotalInMinutes,
  onNewBreak,
  onDeleteRow,
  onTimeChanged,
  onRemoveAllBreaks,
  onError,
  onErrorResolved,
}: {
  rowsToDisplay: Array<BreakTimeRowType>;
  dailyTotalInMinutes: number;
  onNewBreak: () => void;
  onDeleteRow: (breakNumber: number) => void;
  onTimeChanged: (
    newStartTime: Date,
    newEndTime: Date,
    breakNumber: number
  ) => void;
  onRemoveAllBreaks: () => void;
  onError: () => void;
  onErrorResolved: () => void;
}) {
  const classes = useStyles();

  return (
    <>
      <DailyTotalInMinutesCount
        dailyTotalInMinutes={dailyTotalInMinutes}
        className={classes.spacingBottom}
      />
      {rowsToDisplay.map((breakTime: BreakTimeRowType) => {
        return (
          <div key={breakTime.identifier}>
            <BreakTimeRow
              breakNumber={breakTime.breakNumber}
              onDeleteRow={() => onDeleteRow(breakTime.identifier)}
              startTime={breakTime.startTime}
              endTime={breakTime.endTime}
              onTimeChanged={(startTime: Date, endTime: Date) =>
                onTimeChanged(startTime, endTime, breakTime.identifier)
              }
              onError={onError}
              onErrorResolved={onErrorResolved}
            />
          </div>
        );
      })}
      <div className={classes.buttonGroup}>
        <AddNewBreakButton
          onClick={onNewBreak}
          className={classes.addNewBreakButton}
        />
        <RemoveAllBreaksButton
          onClick={onRemoveAllBreaks}
          className={classes.spacingTop}
        />
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  spacingBottom: {
    marginBottom: theme.spacing(2),
  },
  spacingTop: {
    marginTop: theme.spacing(4),
  },
  addNewBreakButton: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  buttonGroup: {
    display: "flex",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
