/**
 * Set the yAxis to a arbitrary value when the chartData is empty,
 * in order to display an empty chart canvas.
 * When all data is between 100 and 75 yAxis gets set to 100.
 * When the data contains numerators/denominators, check if the percentages are lower than 0.01.
 *      In this case, set the yAxis max to 0.1 to propery show low values.
 *
 * When null, HighCharts will calculate it's own yAxis Max.
 *
 * @param data
 * @param yAxisDefaultRange
 */
export function determineYAxisMax(
  data?: any[],
  yAxisDefaultRange?: number
): Nullable<number> {
  if (!data || data.length === 0) {
    return yAxisDefaultRange || 100;
  }

  if (ShouldCalculatePercentages(data)) {
    return DetermineYAxisMaxPercentage(data);
  }

  if (ShouldCalculateYAxisPoints(data)) {
    return CalculateYAxisPoints(data);
  }

  return null;
}

/**
 * Calculate Percentages if the numerator and denominator exist on the data.
 */
function ShouldCalculatePercentages(data: any[]): boolean {
  return data[0].numerator != null && data[0].denominator != null;
}

function DetermineYAxisMaxPercentage(data: any[]): Nullable<number> {
  if (Math.max(...data) <= 0.01) {
    return 0.1;
  }

  return null;
}

/**
 * Calculate YAxis points if y exists on the data
 */
function ShouldCalculateYAxisPoints(data: any[]): boolean {
  //FixMe: introduce type safety
  return data[0].y != null || data[0][1] != null;
}

function CalculateYAxisPoints(data: any[]): Nullable<number> {
  const lowerbound = 74.1;
  const upperbound = 100.9;

  //FixMe: introduce type safety
  const yPoints = data.map((d) => d.y ?? d[1]);
  if (
    Math.max(...yPoints) <= upperbound &&
    Math.min(...yPoints) >= lowerbound
  ) {
    return 100;
  }

  return null;
}
