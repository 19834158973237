import { makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import "date-fns";
import {
  endOfMonth,
  format,
  isSameMonth,
  startOfDay,
  startOfMonth,
  subMonths
} from "date-fns";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import WeekPicker from "./WeekPicker";

export interface ToolbarDatePickerProps {
  selectedDate: Date;
  onDateChange: (newDate: Date) => void;
  label: string;
  datePickerType: DatePickerType;
  maxDate?: Date;
  isFromPicker: boolean;
}

export enum DatePickerType {
  Undefined,
  Weekly,
  Monthly,
}

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

/**
 * A DatePicker that can be used in a toolbar.
 */
export function ToolbarDatePickerBenchmark({
  selectedDate,
  onDateChange,
  label,
  datePickerType,
  maxDate,
  isFromPicker,
}: ToolbarDatePickerProps) {
  const isMobileSize = useIsMobileSize();

  function handleOnChange(newDate?: MaterialUiPickersDate) {
    const result = newDate?.getTime();

    if (!result) return;

    const resultDate = new Date(result);

    if (resultDate.toLocaleDateString() === selectedDate.toLocaleDateString())
      return;

    onDateChange(resultDate);
  }

  return (
    <FormControl fullWidth={isMobileSize}>
      {datePickerType === DatePickerType.Monthly ? (
        <MonthPicker
          value={selectedDate}
          onChange={(date: Date) => handleOnChange(date)}
          label={label}
          maxDate={maxDate}
          isFromPicker={isFromPicker}
        />
      ) : (
        <WeekPicker
          selectedDate={selectedDate}
          onChange={(date: Date) => handleOnChange(date)}
          label={label}
          maxDate={maxDate}
        />
      )}
    </FormControl>
  );
}

function DetermineMaxDate(maxDate?: Date) {
  if (!maxDate) return endOfMonth(new Date());
  return endOfMonth(subMonths(maxDate, 1));
}

/*
  Returns start of the first day of the selected month
*/
function getStartOfMonth(date: Date): Date {
  return startOfDay(startOfMonth(new Date(date.getTime())));
}

/*
  If the selected month is the current month, the start of today is returned
  else returns the start of the last day of the selected month.
*/
function getEndOfMonthOrToday(date: Date): Date {
  return startOfDay(
    isSameMonth(date, new Date())
      ? new Date()
      : endOfMonth(new Date(date.getTime()))
  );
}

function calculateDayInMonthToReturn(isFromPicker: boolean, date: Date) {
  return isFromPicker ? getStartOfMonth(date) : getEndOfMonthOrToday(date);
}

function MonthPicker({
  value,
  onChange,
  label,
  maxDate,
  isFromPicker,
}: {
  value: Date;
  onChange: (date: Date) => void;
  label: string;
  maxDate?: Date;
  isFromPicker: boolean;
}) {
  const handleWeekChange = (date: MaterialUiPickersDate) => {
    if (!date) return;

    const newMonth = calculateDayInMonthToReturn(isFromPicker, date);

    onChange(newMonth);
  };

  return (
    <KeyboardDatePicker
      variant="inline"
      views={["month"]}
      value={value}
      onChange={handleWeekChange}
      label={label}
      maxDate={DetermineMaxDate(maxDate)}
      format={"MMMM yyyy"}
      ToolbarComponent={DatePickerMonthToolbar}
    />
  );
}

function DatePickerMonthToolbar(props: ToolbarComponentProps) {
  const classes = useStyles();
  const { date, isLandscape, openView, setOpenView } = props;

  const handleChangeViewClick = (view: any) => (e: any) => {
    setOpenView(view);
  };

  return (
    <PickerToolbar isLandscape={isLandscape} className={classes.toolbar}>
      <ToolbarButton
        onClick={handleChangeViewClick("year")}
        variant="h6"
        label={format(date!, "yyyy")}
        selected={openView === "year"}
      />
      <ToolbarButton
        onClick={() => {
          // onClick handler is ignored because the user should no be able to select a date when filtering on month level
        }}
        variant="h4"
        label={format(date!, "MMMM")}
        selected={false}
      />
    </PickerToolbar>
  );
}
