import { IconButton, withStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { createStyles } from "@material-ui/styles";
import clsx from "clsx";
import { isAfter, isFuture } from "date-fns";
import { addDays } from "date-fns/esm";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import isValid from "date-fns/isValid";
import isWithinInterval from "date-fns/isWithinInterval";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  convertMaterialUiPickersDate,
  getEndOfWeek,
  getStartOfWeek,
} from "../../../utility/DateUtility";

function WeekPicker({
  classes,
  onChange,
  selectedDate,
  label,
  maxDate,
}: {
  classes: any;
  onChange: (date: Date) => void;
  selectedDate: Date;
  label: string;
  maxDate?: any;
}) {
  const { t } = useTranslation();
  const handleWeekChange = (
    date: MaterialUiPickersDate,
    _?: string | null | undefined
  ) => {
    if (!date) return;
    onChange(getStartOfWeek(new Date(date.getTime())));
  };

  const formatWeekSelectLabel = (
    date: MaterialUiPickersDate,
    invalidLabel: string
  ) => {
    if (!date) return invalidLabel;
    let dateClone = new Date(date.getTime());

    return dateClone && isValid(dateClone)
      ? t("benchmark.weekLabel", {
          formattedDate: format(
            addDays(getStartOfWeek(dateClone), 3),
            "MMM do"
          ),
        })
      : invalidLabel;
  };

  const renderWrappedWeekDay = (
    date: MaterialUiPickersDate,
    inputDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean
  ) => {
    let dateClone = convertMaterialUiPickersDate(date);
    let selectedDateClone = convertMaterialUiPickersDate(inputDate);

    const start = getStartOfWeek(selectedDateClone);
    const end = getEndOfWeek(selectedDateClone);

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);
    const dayIsInFuture = isFuture(dateClone);
    const isOnOrAfterMaxDate =
      isSameDay(dateClone, maxDate) || isAfter(dateClone, maxDate);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]:
        (!dayInCurrentMonth && dayIsBetween) ||
        dayIsInFuture ||
        isOnOrAfterMaxDate,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <KeyboardDatePicker
      disableFuture
      maxDate={maxDate}
      label={label}
      variant="inline"
      value={selectedDate}
      onChange={handleWeekChange}
      renderDay={renderWrappedWeekDay}
      labelFunc={formatWeekSelectLabel}
    />
  );
}

const styles = createStyles((theme: any) => ({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

export default withStyles(styles)(WeekPicker);
