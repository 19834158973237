import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import config from "../config";
import { dateFormatter } from "../utility/DateUtility";
import { IHttpClient } from "./IHttpClient";

const axiosInstance = axios.create({
  baseURL: config.REACT_APP_API_BASE_URL,
});

/**
 * Gets the custom Axios Config for API requests
 */
export const getAxiosConfig = (
  requestCancellationToken: CancelTokenSource,
  params?: any
): AxiosRequestConfig => {
  return {
    transformResponse: [
      function (data) {
        return JSON.parse(data, dateFormatter);
      },
    ],
    ...params,
    cancelToken: requestCancellationToken.token,
  };
};

const handleHttpError = (err: any) => {
  if (axios.isCancel(err)) {
    return Promise.reject({ isCancelled: true });
  }
  return Promise.reject({ isCancelled: false });
};

/**
 * A default wrapper for the Axios HTTP client
 */
export class AxiosHttpClient implements IHttpClient {
  private authTokenGetter?: AuthTokenGetter;
  private requestCancellationToken: CancelTokenSource;

  constructor(requestCancellationToken: CancelTokenSource) {
    this.requestCancellationToken = requestCancellationToken;
  }

  get(url: string, params?: any) {
    const getConfig = getAxiosConfig(this.requestCancellationToken, params);

    if (this.authTokenGetter) {
      return this.authTokenGetter().then((token) => {
        return axiosInstance
          .get(url, addAuthToken(getConfig, token))
          .catch(handleHttpError);
      });
    }

    return axiosInstance.get(url, getConfig).catch(handleHttpError);
  }

  getWithHeaders(url: string, headers: any, params?: any) {
    const getConfig = getAxiosConfig(this.requestCancellationToken, params);

    if (this.authTokenGetter) {
      return this.authTokenGetter().then((token) => {
        return axiosInstance
          .get(url, addAuthToken({ ...getConfig, ...headers }, token))
          .catch(handleHttpError);
      });
    }

    return axiosInstance
      .get(url, { ...getConfig, ...headers })
      .catch(handleHttpError);
  }

  put(url: string, body: any) {
    if (this.authTokenGetter) {
      return this.authTokenGetter().then((token) => {
        return axiosInstance
          .put(url, body, addAuthToken({}, token))
          .catch(handleHttpError);
      });
    }

    return axiosInstance
      .put(url, body, {
        cancelToken: this.requestCancellationToken.token,
      })
      .catch(handleHttpError);
  }

  putWithHeaders(url: string, body: any, headers: any): Promise<any> {
    if (this.authTokenGetter) {
      return this.authTokenGetter().then((token) => {
        return axiosInstance
          .put(url, body, addAuthToken(headers, token))
          .catch(handleHttpError);
      });
    }

    return axiosInstance
      .put(url, body, {
        cancelToken: this.requestCancellationToken.token,
      })
      .catch(handleHttpError);
  }

  post(url: string, body: any): Promise<any> {
    if (this.authTokenGetter) {
      return this.authTokenGetter()
        .then((token) => {
          return axiosInstance.post(url, body, addAuthToken({}, token));
        })
        .catch(handleHttpError);
    }

    return axiosInstance
      .post(url, body, {
        cancelToken: this.requestCancellationToken.token,
      })
      .catch(handleHttpError);
  }

  delete(url: string, params?: any) {
    if (this.authTokenGetter) {
      return this.authTokenGetter()
        .then((token) => {
          return axiosInstance.delete(url, addAuthToken(params, token));
        })
        .catch(handleHttpError);
    }

    return axiosInstance
      .delete(url, {
        ...params,
        cancelToken: this.requestCancellationToken.token,
      })
      .catch(handleHttpError);
  }

  setTokenGetter(getter: AuthTokenGetter) {
    this.authTokenGetter = getter;
  }
}

function addAuthToken(
  axiosConfig: AxiosRequestConfig,
  token: string
): AxiosRequestConfig {
  return {
    ...axiosConfig,
    headers: {
      ...axiosConfig.headers,
      Authorization: `Bearer ${token}`,
    },
  };
}

export interface AuthTokenGetter {
  (): Promise<string>;
}
