import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  EggUnit,
  GetEggUnitTranslation,
} from "../../../utility/EggUnitUtility";

const useStyles = makeStyles((theme: any) => ({
  contentWrapper: {
    display: "flex",
  },
  formControl: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: theme.spacing(1),
    },
    "& + $formControl": {
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(6),
      },
    },
  },
}));

export function EggCountInput({
  value,
  onChange,
  unit,
  onUnitChange,
  label,
  timeLabel,
  id,
  showError,
}: {
  value: number;
  onChange: (v: number) => void;
  unit: EggUnit;
  onUnitChange: (u: EggUnit) => void;
  label: string;
  timeLabel: string;
  id: string;
  showError?: boolean;
}) {
  return (
    <SettingFormControl>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        id={id}
        type="number"
        inputMode="numeric"
        inputProps={{
          min: "0",
          step: "1",
        }}
        value={value}
        error={showError}
        onChange={(e) => onChange(parseInt(e.target.value))}
        endAdornment={
          <InputAdornment position="end">
            <UnitsSelection value={unit} onChange={(u) => onUnitChange(u)} />
            &nbsp;/{timeLabel}
          </InputAdornment>
        }
      />
    </SettingFormControl>
  );
}

export function UnitsSelection({
  value,
  onChange,
}: {
  value: EggUnit;
  onChange: (v: EggUnit) => void;
}) {
  return (
    <Select
      disableUnderline
      value={value}
      onChange={(e) => onChange(e.target.value as EggUnit)}
    >
      <MenuItem value={EggUnit.Eggs.toString()}>
        {GetEggUnitTranslation(EggUnit.Eggs)}
      </MenuItem>
      <MenuItem value={EggUnit.Dozens.toString()}>
        {GetEggUnitTranslation(EggUnit.Dozens)}
      </MenuItem>
      <MenuItem value={EggUnit.Cases.toString()}>
        {GetEggUnitTranslation(EggUnit.Cases)}
      </MenuItem>
    </Select>
  );
}

export function PercentageInput({
  id,
  label,
  value,
  onChange,
}: {
  id: string;
  label: string;
  value: number;
  onChange: (v: number) => void;
}) {
  return (
    <SettingFormControl>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        id={id}
        type="number"
        inputMode="decimal"
        inputProps={{
          min: 0,
          max: 100,
        }}
        endAdornment={<InputAdornment position="end">%</InputAdornment>}
        value={value}
        onChange={(e) =>
          onChange(
            Math.min(100, parseFloat(parseFloat(e.target.value).toFixed(2)))
          )
        }
      />
    </SettingFormControl>
  );
}

export function NumberInput({
  id,
  label,
  value,
  onChange,
}: {
  id: string;
  label: string;
  value: number;
  onChange: (v: number) => void;
}) {
  return (
    <SettingFormControl>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        id={id}
        type="number"
        inputMode="decimal"
        inputProps={{
          min: "0",
        }}
        value={value}
        onChange={(e) => onChange(parseFloat(e.target.value))}
      />
    </SettingFormControl>
  );
}

export function SettingFormControl({ children }: any) {
  const classes = useStyles();
  return <FormControl className={classes.formControl}>{children}</FormControl>;
}
