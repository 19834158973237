import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { useTranslation } from "react-i18next";
import { useIsMobileSize } from "../../globalHooks/responsiveHooks";

export default function SplashPage({ onActionButtonClick }: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobileSize = useIsMobileSize();

  return (
    <div className={classes.centered}>
      <div>
        <div className={classes.centeredIcon}>
          <AssessmentIcon className={classes.splashIcon} />
        </div>
        <Typography variant="h3" className={classes.splashTitle}>
          {t("dashboardPage.splashScreen.title")}
        </Typography>
        <Typography variant="body1" className={classes.centeredText}>
          {isMobileSize ? t("dashboardPage.realTimeDashboard.actionBar.editOnlyOnDesktop") : t("dashboardPage.splashScreen.subText")}
        </Typography>
        {!isMobileSize &&
          <div className={classes.centeredIcon}>
            <Button
              variant={"contained"}
              color="primary"
              onClick={() => onActionButtonClick()}
            >
              {t("dashboardPage.splashScreen.buttonLabel")}
            </Button>
          </div>
        }
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    height: "100%",
    width: "100%",
  },
  splashTitle: {
    marginBottom: theme.spacing(1),
  },
  splashIcon: {
    fontSize: "6em",
  },
  centeredText: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  centeredIcon: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
}));
