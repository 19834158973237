import { Responsive, WidthProvider } from "react-grid-layout";
import { useIsMobileSize } from "../../globalHooks/responsiveHooks";

const ResponsiveGridLayout = WidthProvider(Responsive);

function DashboardGrid({ children, layouts, onLayoutChange, lockGrid }: any) {
  const isMobileSize = useIsMobileSize();

  const mobileRowHeight = 90;
  const desktopRowHeight = 160;
  const selectedRowHeight = isMobileSize ? mobileRowHeight : desktopRowHeight;

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={layouts}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 6, sm: 6, xs: 2, xxs: 1 }}
      isResizable={false}
      measureBeforeMount={false}
      isDroppable={false}
      isDraggable={lockGrid}
      rowHeight={selectedRowHeight}
      onLayoutChange={(layout) => onLayoutChange(layout)}
    >
      {children}
    </ResponsiveGridLayout>
  );
}

export default DashboardGrid;
