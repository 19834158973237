import { useAuth0 } from "@auth0/auth0-react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import BarChartIcon from "@material-ui/icons/BarChart";
import DescriptionIcon from "@material-ui/icons/Description";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import InfoIcon from "@material-ui/icons/Info";
import MenuIcon from "@material-ui/icons/Menu";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import BellIcon from "@material-ui/icons/Notifications";
import PeopleIcon from "@material-ui/icons/People";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import SettingsIcon from "@material-ui/icons/Settings";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import TuneIcon from "@material-ui/icons/Tune";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HashRouter } from "react-router-dom";
import { colors } from "./colors";
import Alert from "./components/UI/Alert";
import NavigationDrawer from "./components/UI/Drawer";
import InformationBarContainer from "./components/UI/InformationBar/InformationBarContainer";
import {
  useHttpClientPipelineInjectorEffect,
  useLoginWithRedirectEffect,
} from "./globalHooks/authenticationHooks";
import {
  Features,
  useAuthorizationScope,
  useLoadMyUserIntoGlobalState as useLoadMyUserIntoGlobalStateEffect,
} from "./globalHooks/authorizationHooks";
import { useIsGetDeviceInstallationSupported } from "./globalHooks/deviceInstallationHooks";
import { useLocalStorage } from "./globalHooks/localStorageHooks";
import { useIsMobileSize } from "./globalHooks/responsiveHooks";
import { useGetUserSettingsAndSaveInLocalStorage } from "./globalHooks/userPreferencesHooks";
import iMobaLogoImage from "./logo.png";
import ErrorPage from "./pages/error/ErrorPage";
import RootPage from "./pages/RootPage";
import SkeletonPage from "./pages/skeleton/SkeletonPage";
import { useSelectedMachine } from "./store/GlobalContext";
import { useApplicationInsights } from "./utility/ApplicationInsightsUtility";
import ScrollToTop from "./utility/ScrollToTop";

function LoadErrorPage() {
  return <ErrorPage />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: colors.mobaBlue,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 2000, // The z-index should be higher than the drawer
    position: "fixed",
  },
  appToolBar: {
    [theme.breakpoints.down("sm")]: {
      minHeight: theme.spacing(2),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(6),
    },
    width: "100vw",
    paddingBottom: theme.spacing(10),
  },
  logo: {
    height: "40px",
  },
  logoText: {
    marginLeft: theme.spacing(1),
  },
  machineNameWrapper: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
}));

function App() {
  const { t } = useTranslation();
  const classes = useStyles();
  const selectedMachine = useSelectedMachine();
  const { isAuthenticated } = useAuth0();
  const isGetDeviceInstallationSupported =
    useIsGetDeviceInstallationSupported();

  useHttpClientPipelineInjectorEffect();
  const shouldRenderApp = useLoadMyUserIntoGlobalStateEffect();

  const reactPlugin: ReactPlugin = useApplicationInsights();
  const isMobileSize = useIsMobileSize("sm");

  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  let navigationModules = {
    name: "Modules",
    entries: [
      {
        icon: <QueryBuilderIcon />,
        name: t("performanceProPage.linkTitle"),
        link: "/performance-pro",
      },
    ],
  };

  const authorizationScope = useAuthorizationScope();

  if (
    authorizationScope.hasAuthorizationForSelectedMachine(
      Features.RealtimeDashboard
    )
  ) {
    navigationModules.entries.push({
      icon: <BarChartIcon />,
      name: t("dashboardPage.linkTitle"),
      link: "/dashboard",
    });
  }

  if (
    authorizationScope.hasAuthorizationForSelectedMachine(Features.Benchmark)
  ) {
    navigationModules.entries.push({
      icon: <ShowChartIcon />,
      name: t("benchmark.linkTitle"),
      link: "/benchmark",
    });
  }

  let navigationManagementEntries = [];

  if (
    authorizationScope.hasAuthorizationForSelectedMachine(
      Features.ManageSettings
    )
  ) {
    navigationManagementEntries.push({
      icon: <SettingsIcon />,
      name: t("managementSettings.menuItemTitle"),
      link: "/management/settings",
    });
  }

  if (
    authorizationScope.hasAuthorizationForSelectedMachine(Features.ManageUsers)
  ) {
    navigationManagementEntries.push({
      icon: <PeopleIcon />,
      name: t("users.titles.linkTitle"),
      link: "/users/manage/" + selectedMachine.machineId,
    });
  }

  if (
    authorizationScope.hasAuthorizationForSelectedMachine(
      Features.ManageMachine
    )
  ) {
    navigationManagementEntries.push({
      icon: <TuneIcon />,
      name: t("machineManagementPage.linkTitle"),
      link: "/management/machine/" + selectedMachine.machineId,
    });
  }

  if (authorizationScope.hasAuthorizationForSelectedMachine(Features.Owner)) {
    navigationManagementEntries.push({
      icon: <SupervisedUserCircleIcon />,
      name: t("ownerPage.linkTitle"),
      link: "/owner/" + selectedMachine.machineId,
    });
  }

  if (
    authorizationScope.hasAuthorizationForSelectedMachine(Features.ManageAlarms)
  ) {
    navigationManagementEntries.push({
      icon: <BellIcon />,
      name: t("alarmManagement.linkTitle"),
      link: "/management/alarms",
    });
  }

  if (isGetDeviceInstallationSupported) {
    navigationManagementEntries.push({
      icon: <SmartphoneIcon />,
      name: t("notificationManagement.linkTitle"),
      link: "/management/notifications",
    });
  }

  let navigationManagement = {
    name: "Management",
    entries: navigationManagementEntries,
  };

  let navigationGeneral = {
    name: "General",
    entries: [
      {
        icon: <MenuBookIcon />,
        name: t("general.glossary"),
        link: "/glossary",
      },
      {
        icon: <DescriptionIcon />,
        name: t("general.terms"),
        link: "/terms",
      },
      {
        icon: <InfoIcon />,
        name: t("general.about"),
        link: "/about",
      },
      {
        icon: <ExitToAppIcon />,
        name: t("general.logout"),
        link: "/logout",
      },
    ],
  };

  let navigationItems = [
    navigationModules,
    navigationManagement,
    navigationGeneral,
  ];

  if (navigationManagementEntries.length === 0) {
    navigationItems = [navigationModules, navigationGeneral];
  }

  useLocalStorage();
  useLoginWithRedirectEffect();
  useGetUserSettingsAndSaveInLocalStorage();

  return (
    <div className={classes.root}>
      <HashRouter>
        <AppInsightsContext.Provider value={reactPlugin}>
          <AppInsightsErrorBoundary
            onError={() => LoadErrorPage()}
            appInsights={reactPlugin}
          >
            <>
              <ScrollToTop />
              <AppBar elevation={0} className={classes.appBar}>
                <Toolbar className={classes.appToolBar}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    edge="start"
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                  {!isMobileSize && (
                    <>
                      <img
                        src={iMobaLogoImage}
                        alt={"imoba-logo"}
                        className={classes.logo}
                      />
                      <Typography
                        variant="h6"
                        noWrap
                        className={classes.logoText}
                      >
                        iMoba
                      </Typography>
                    </>
                  )}
                  <div className={classes.machineNameWrapper}>
                    <Typography variant="h6" noWrap>
                      {determineMachineName(selectedMachine, isMobileSize)}
                    </Typography>
                  </div>
                </Toolbar>
              </AppBar>
              <NavigationDrawer
                navigationItems={navigationItems}
                open={open}
                onDrawerLinkClick={toggleDrawer}
              />
              <div className={classes.content}>
                {shouldRenderApp && isAuthenticated ? (
                  <RootPage />
                ) : (
                  <SkeletonPage />
                )}
              </div>
              <Alert />
              <InformationBarContainer />
            </>
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
      </HashRouter>
    </div>
  );
}

function determineMachineName(
  selectedMachine: OwnerMachine,
  isMobileSize: boolean
) {
  if (!selectedMachine || selectedMachine.machineId === 0) return "";
  return isMobileSize
    ? selectedMachine.machineId
    : `${selectedMachine.machineId} - ${selectedMachine.machineName}`;
}

export default App;
