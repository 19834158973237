import { ListItem, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  numberedListItem: {
    "& + $header": {
      marginTop: theme.spacing(2),
    },
    display: "block",
  },
}));

export function NumberedParagraph({ children }: any) {
  const classes = useStyles();
  return (
    <ListItem className={classes.numberedListItem}>
      <Typography component={"span"} variant="body1">
        {children}
      </Typography>
    </ListItem>
  );
}
