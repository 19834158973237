import { KpiMeasureType } from "../../components/PerformancePro";
import { toISO8601DateStringLocal } from "../../utility/DateUtility";
import { IHttpClient } from "../IHttpClient";
import {
  GetDestinationProductForSupplierQuery,
  GetMachinePerformanceBatchQuery,
  GetMachinePerformanceGroupForSupplierQuery,
  GetMachinePerformanceGroupQuery,
  GetMachinePerformanceQuery,
  GetProductionTimeWindow,
  IPerformanceProService,
} from "./IPerformanceProService";

export class PerformanceProService implements IPerformanceProService {
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }

  getMachinePerformanceBarChart(
    query: GetMachinePerformanceGroupQuery
  ): Promise<any> {
    const measureGroupQueryPart = query.measures
      .map((g) => `group=${g}`)
      .join("&");

    return this.get(
      `v1/PerformancePro/${query.machineId}/grouped-measures?${measureGroupQueryPart}`,
      {
        params: {
          periodType: query.periodType,
          fromDate: toISO8601DateStringLocal(query.fromDate),
        },
      }
    );
  }

  getMachinePerformanceBarChartForSupplier(
    query: GetMachinePerformanceGroupForSupplierQuery
  ): Promise<any> {
    const measureGroupQueryPart = query.measures
      .map((g) => `group=${g}`)
      .join("&");

    return this.get(
      `v1/PerformancePro/${query.machineId}/grouped-measures?${measureGroupQueryPart}`,
      {
        params: {
          periodType: query.periodType,
          fromDate: toISO8601DateStringLocal(query.fromDate),
          supplierName: query.supplierName,
          supplierShed: query.supplierShed,
        },
      }
    );
  }

  getMachinePerformance(query: GetMachinePerformanceQuery) {
    return this.get(
      `v1/PerformancePro/${query.machineId}/${KpiMeasureType[query.measure]}`,
      {
        params: {
          periodType: query.periodType,
          fromDate: toISO8601DateStringLocal(query.fromDate),
        },
      }
    );
  }

  getMachinePerformanceBatch(query: GetMachinePerformanceBatchQuery) {
    return this.get(`v1/PerformancePro/${query.machineId}/measures`, {
      params: {
        measures: query.measures,
        periodType: query.periodType,
        fromDate: toISO8601DateStringLocal(query.fromDate),
      },
    });
  }

  getProductionTimeWindow(query: GetProductionTimeWindow) {
    return this.get(
      `v1/PerformancePro/${query.machineId}/production-time-window`,
      {
        params: {
          date: toISO8601DateStringLocal(query.date),
        },
      }
    );
  }

  getDestinationProductForSupplier(
    query: GetDestinationProductForSupplierQuery
  ) {
    return this.get(
      `v1/PerformancePro/${query.machineId}/destination-product`,
      {
        params: {
          periodType: query.periodType,
          fromDate: toISO8601DateStringLocal(query.fromDate),
          supplierName: query.supplierName,
          supplierShed: query.supplierShed,
        },
      }
    );
  }

  private get(url: string, params: any): Promise<any> {
    return this.httpclient.get(encodeURI(url), params);
  }
}
