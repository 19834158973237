import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import React from "react";
import { ReportSettings } from "../../../pages/management-settings/Common";
import { t } from "../../../utility/TranslateUtility";

export function TeamSizePresentation({
  settings,
  onSettingsChange,
}: {
  settings: ReportSettings;
  onSettingsChange: (settings: ReportSettings) => void;
}) {
  const handleOnChange = (_: any, checkedValue: boolean) => {
    onSettingsChange({
      ...settings,
      enableTeamSizeDataEntry: checkedValue,
    });
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={settings.enableTeamSizeDataEntry}
          onChange={handleOnChange}
          name={`switch-team-size`}
          color="primary"
        />
      }
      label={t("managementSettings.report.teamSizeSwitchLabel")}
    />
  );
}
