import React from "react";
import { useTranslation } from "react-i18next";
import { DatePeriod } from "../../../utility/DateUtility";
import Toolbar from "../../UI/Toolbar/Toolbar";
import { ToolbarDatePeriodButtonGroup } from "../../UI/Toolbar/ToolbarDatePeriodButtonGroup";
import {
  DatePickerType,
  ToolbarDatePickerBenchmark,
} from "../../UI/Toolbar/ToolbarDatePickerBenchmark";
import { PeriodInYearType } from "../MachineMeasureComparison/Types";

function toDatePeriod(datePeriodName: PeriodInYearType): DatePeriod {
  return datePeriodName === "week" ? DatePeriod.Week : DatePeriod.Month;
}

function toDatePickerType(periodType: PeriodInYearType) {
  if (periodType === "week") {
    return DatePickerType.Weekly;
  } else if (periodType === "month") {
    return DatePickerType.Monthly;
  }

  return DatePickerType.Undefined;
}

interface Props {
  onFromDateChange(date: Date): void;
  onUntilDateChange(date: Date): void;
  onDatePeriodChange(datePeriod: DatePeriod): void;
  fromDate: Date;
  untilDate: Date;
  periodType: PeriodInYearType;
  children: any;
}

export default function BenchmarkToolbarPresentation({
  onFromDateChange,
  onUntilDateChange,
  onDatePeriodChange,
  fromDate,
  untilDate,
  periodType,
  children,
}: Props) {
  const { t } = useTranslation();

  return (
    <Toolbar>
      <ToolbarDatePickerBenchmark
        selectedDate={fromDate}
        onDateChange={onFromDateChange}
        label={t("benchmark.fromDateLabel")}
        datePickerType={toDatePickerType(periodType)}
        maxDate={untilDate}
        isFromPicker={true}
      />
      <ToolbarDatePickerBenchmark
        selectedDate={untilDate}
        onDateChange={onUntilDateChange}
        label={t("benchmark.untilDateLabel")}
        datePickerType={toDatePickerType(periodType)}
        isFromPicker={false}
      />
      <ToolbarDatePeriodButtonGroup
        selectedDatePeriod={toDatePeriod(periodType)}
        onDatePeriodChange={onDatePeriodChange}
        supportedPeriods={[DatePeriod.Week, DatePeriod.Month]}
      />
      {children}
    </Toolbar>
  );
}
