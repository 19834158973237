import { IPerformanceProFilterOptions } from "../../../store/GlobalContext";
import { DatePeriod } from "../../../utility/DateUtility";
import {
  GetSupplierNameAndShed,
  SupplierNameAndShed,
} from "../../../utility/SupplyUtility";
import { XAxisType } from "./GroupsChartMapping";

export function StackBarChartBaseOptions(
  options: IPerformanceProFilterOptions,
  xAxisType: XAxisType,
  onBarSupplyClicked: Nullable<
    (supplierName: string, supplierShed: string) => void
  >
) {
  return {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        cursor: onBarSupplyClicked != null ? "pointer" : "default",
        events: {
          click: function (event: any) {
            if (onBarSupplyClicked != null) {
              const supplierShedName: string = event.point.name as string;
              const supplierShedAndName: SupplierNameAndShed =
                GetSupplierNameAndShed(supplierShedName);

              onBarSupplyClicked(
                supplierShedAndName.supplierName,
                supplierShedAndName.supplierShed
              );
            }
          },
        },
      },
      label: {
        enabled: false,
      },
      column: {
        pointPlacement:
          options.selectedDatePeriod === DatePeriod.Day &&
          xAxisType !== XAxisType.Supply
            ? "between"
            : undefined,
        stacking: "normal",
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      minorGridLineWidth: 0,
      minorTicks: true,
      showFirstLabel: true,
      showLastLabel: true,
      startOfWeek: 1,
      tickInterval: 0,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
  };
}
