import { Grid, Input, InputLabel, makeStyles } from "@material-ui/core";
import React from "react";
import { DowntimeInfoLabel, ReportSettings } from "../../../pages/management-settings/Common";
import { nbsp, t } from "../../../utility/TranslateUtility";

const useStyles = makeStyles((theme: any) => ({
  downtimeInfoLabelsWrapper: {
    width: "100%",
  },
  downtimeInfoLabels: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    display: "flex",
    flexDirection: "row"
  },
  gridItem: {
    alignSelf: "center",
  },
  gridContainer: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
  errorMessage: {
    margin: theme.spacing(1),
  },
}));

export function DowntimeInfoLabelsPresentation({
  settings,
  onSettingsChange,
}: {
  settings: ReportSettings;
  onSettingsChange: (settings: ReportSettings) => void;
}) {
  const classes = useStyles();
  settings.downtimeInfoLabels.sort((a,b) => a.labelNumber - b.labelNumber);
  const handleDowntimeInfoLabelsChange = (index: number, value: string) => {

    const updatedValue: DowntimeInfoLabel = { name: value, labelNumber: index };

    onSettingsChange({
      ...settings,
      downtimeInfoLabels: [
        ...settings.downtimeInfoLabels.filter(x => x.labelNumber !== index),
        updatedValue
      ]
    });
  };

  const downtimeInfoLabels = settings.downtimeInfoLabels.map((x) => (
    <div key={x.labelNumber} className={classes.downtimeInfoLabels}>
    <Grid container className={classes.gridContainer} spacing={3}>
        <Grid item xs={1} sm={1} className={classes.gridItem}>
        {x.labelNumber + 1}
        </Grid>
        <Grid item xs={11} sm={11}>
            <InputLabel>{nbsp(t("managementSettings.report.labelName"))}</InputLabel>
            <Input
                type="string"
                inputMode="text"
                placeholder={t("managementSettings.report.customLabel") + " " + (x.labelNumber + 1)}
                value={x.name || ''}
                onChange={(e) => handleDowntimeInfoLabelsChange(x.labelNumber, e.target.value)}
                inputProps={
                  {maxLength: 255}
                }
            />
        </Grid>
        </Grid>
      </div>
  ))

  return (
    <div className={classes.downtimeInfoLabelsWrapper}>
      {downtimeInfoLabels}
    </div>
  )
}
