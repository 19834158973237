import { makeStyles, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { t } from "../../../../../utility/TranslateUtility";
import { ChangesPerDayType } from "../../Types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  inputs: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
  },
  input: {
    marginRight: theme.spacing(2),
  },
  errorMessage: {
    marginTop: theme.spacing(1),
  },
}));

export function ChangesPerDay({
  type,
  min,
  max,
  minGreaterThanMaxError,
  maxValueExceededError,
  onBlur,
  onChange,
}: {
  type: ChangesPerDayType,
  min: number | null;
  max: number | null;
  minGreaterThanMaxError: boolean;
  maxValueExceededError: boolean;
  onBlur: () => void;
  onChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    textField: string
  ) => void;
}) {
  const classes = useStyles();
  const inputMin = min === null ? "" : min;
  const inputMax = max === null ? "" : max;

  return (
    <div className={classes.root}>
      <Typography variant="body1">
        {t("benchmark.filters." + type)}
      </Typography>
      <div className={classes.inputs}>
        <TextField
          onChange={(event) => onChange(event, "min")}
          value={inputMin}
          error={minGreaterThanMaxError}
          variant={"outlined"}
          onBlur={onBlur}
          label={"Min"}
          className={classes.input}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
        <TextField
          error={minGreaterThanMaxError || maxValueExceededError}
          value={inputMax}
          onChange={(event) => onChange(event, "max")}
          onBlur={onBlur}
          variant={"outlined"}
          label={"Max"}
          className={classes.input}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
      </div>
      {minGreaterThanMaxError && (
        <Typography
          variant="body1"
          color="error"
          className={classes.errorMessage}
        >
          {t("benchmark.filters.errorMessages.minGreaterThanMaxErrorMessage")}
        </Typography>
      )}
      {maxValueExceededError && (
        <Typography
          variant="body1"
          color="error"
          className={classes.errorMessage}
        >
          {t("benchmark.filters.errorMessages.maxValueExceededErrorMessage")}
        </Typography>
      )}
    </div>
  );
}
