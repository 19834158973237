export enum AnnouncementSeverity {
    info,
    warning,
    error
}

export type GlobalAnnouncementModel = {
    severity: AnnouncementSeverity;
    title: string;
    message: string;
    isActive: boolean;
};