import { Link } from "@material-ui/core";
import {
  BoldNumberedParagraph,
  BulletList,
  NumberedList,
  NumberedParagraph,
  PageHeader,
  PageHeaderSubtitle,
  TextInfoPage
} from "../../components/Page";

export default function PrivacyPolicyPage() {
  const BNP = BoldNumberedParagraph;
  const NP = NumberedParagraph;
  const BL = BulletList;

  return (
    <TextInfoPage>
      <PageHeader>Privacy Policy iMOBA</PageHeader>
      <PageHeaderSubtitle>Version 002 of August 4, 2021</PageHeaderSubtitle>
      <NumberedList>
        <BNP>1. About This Privacy Policy</BNP>
        <NP>
          This privacy policy explains how the personal information collected
          when you use iMOBA, its mobile applications and other services that
          post a link to this privacy policy (each, a “Service”) will be used by
          MOBA. This privacy policy may be supplemented by additional privacy
          terms or notices set forth on certain areas of the iMoba, hereinafter
          called "the Service".
        </NP>
        <BNP>2. Collection of Information</BNP>
        <NP>
          The Service collects information from you in two ways: directly from
          your input and automatically from your use.
        </NP>
        <BNP>2.1 Information you provide</BNP>
        <NP>
          The types of (personal) information that the Service collects directly
          from you depends on how you interact with the Service and may include:
        </NP>
        <BL>
          <ul>
            <li>
              Contact details, such as your name, email address, postal address
              and phone number;
            </li>
            <li>
              Maintenance-, production-, performance- and other
              (non-)confidential commercial information
            </li>
            <li>
              Comments, feedback and other information you submit to iMoba; and
            </li>
            <li>Interests and communication preferences.</li>
          </ul>
        </BL>
        <BNP>2.2 Information Collected Automatically</BNP>
        <NP>
          The Service also automatically collects, through its connection to
          your equipment, servers and the use of cookies and similar
          technologies, information that tells MOBA how you use the Service and
          may include:
        </NP>
        <BL>
          <ul>
            <li>
              Internet Protocol ("IP") address used to connect your computer to
              the Internet;
            </li>
            <li>
              Computer, device and connection information, such as browser type
              and version, operating system, mobile platform and unique device
              identifier (“UDID”) and other technical identifiers;
            </li>
            <li>
              Uniform Resource Locator ("URL") click stream data, including date
              and time stamp, referring and exit URLs, search terms you used and
              pages you visited or searched for on ; and
            </li>
            <li>
              For location-aware Services, the region, city or town where your
              device and equipment is located in order to provide you with more
              relevant content.
            </li>
            <li>
              Information and data (either commercial or technical, confidential
              or non-confidential) pertaining to the use, maintenance,
              operating, performance, operators and other relevant information
              of MOBA equipment and/or machinery, installed at users facilities
              or controlled by users.
            </li>
          </ul>
        </BL>
        <NP>
          For product development and support services MOBA may use usage data
          and aggregated information collected from your equipment.
        </NP>
        <NP>
          For benchmarking and any other purpose MOBA may use and disclose
          information that does not reveal your identity or permit direct
          association with any specific individual, installation, equipment,
          plant or company, such as browser and device information, irreversibly
          anonymized usage data and aggregated information, except where MOBA is
          restricted by law.
        </NP>
        <NP>
          You can control cookies through your browser’s settings and other
          tools. However, if you block certain cookies, you may not be able to
          register, login, or access certain parts or make full use of iMoba .
        </NP>
        <BNP>3. Use of Your Information</BNP>
        <NP>MOBA may use your (personal) information to:</NP>
        <BL>
          <ul>
            <li>Process and fulfill a transaction or order;</li>
            <li>
              Provide technical, product and other support and to help keep
              iMoba and MOBA equipment and machinery installed at users
              facilities working, safe and secure;
            </li>
            <li>Respond to your requests, inquiries, comments and concerns;</li>
            <li>
              Provide, evaluate and improve iMoba, its information and business
              campaigns and MOBA's other products and services and to develop
              new products, services and benefits;
            </li>
            <li>
              Offer you customized content and individualized personalization of
              iMoba to make it more relevant to your interests and needs;
            </li>
            <li>
              Notify you about changes or updates to iMoba and our other
              products and services;
            </li>
            <li>
              Provide you with special offers and other information about iMoba
              as well as other products, events and services of ours, our
              affiliates, and non-affiliated third parties;
            </li>
            <li>
              Invite you to participate in surveys, benchmarking and similar
              activities;
            </li>
            <li>
              Identify usage trends and for data analysis, including for
              purposes of research, audit and reporting and determining the
              effectiveness of MOBA's equipment and services, and
            </li>
            <li>
              Comply with MOBA's obligations, prevent or solve disputes, and
              enforce MOBA's agreements.
            </li>
          </ul>
        </BL>
        <NP>
          We may also match or combine the personal information that you provide
          with information that we obtain from other sources or that is already
          in our records, whether collected online or offline or by predecessor
          or affiliated group companies, for the purposes described above.
        </NP>
        <BNP>4. Disclosure of Your Information</BNP>
        <NP>We may share your personal information with:</NP>
        <BL>
          <ul>
            <li>
              Our affiliates, trading names and divisions within the MOBA group
              of companies worldwide (for a list, visit our website
              www.moba.net) that provide technology, customer service and other
              shared services functions; and
            </li>
            <li>
              Our service providers, suppliers, agents and representatives,
              including but not limited to, editors, payment processors,
              customer support, email service providers, IT service providers,
              mailing houses and shipping agents;
            </li>
            <li>Other Users of iMoba</li>
          </ul>
        </BL>
        <NP>
          to process the information for us based on our instructions and in
          compliance with this privacy policy and any other appropriate
          confidentiality and security measures.
        </NP>
        <NP>
          If you access iMoba through an institution-sponsored subscription,
          your personal information and certain usage data gathered through
          iMoba may be shared with your institution for the purposes of usage
          analysis, subscription management and compliance, cost attribution and
          departmental budgeting.
        </NP>
        <NP>We also may disclose your personal information:</NP>
        <BL>
          <ul>
            <li>
              To (i) respond to or comply with any law, regulation, subpoena,
              court order or other legal obligation; (ii) detect, investigate
              and help prevent security threats, fraud or other malicious
              activity; (iii) enforce and protect our rights and properties; or
              (iv) protect the rights, property or safety of our users,
              employees and others; and
            </li>
            <li>
              If iMoba or a related asset or line of business is acquired by,
              transferred to or merged with another company.
            </li>
          </ul>
        </BL>
        <NP>
          The Service may let you post and share personal information, comments,
          materials and other content. Any information you post or disclose
          publicly may be collected and used by others, may be indexable by
          search engines, and might not be able to be removed. Please be careful
          when disclosing personal information in these public areas.
        </NP>
        <BNP>5. Your Choices and Communications Preferences</BNP>
        <NP>
          You can manage your communications preferences when you register with
          the Service, by updating your account preferences, by using the
          “opt-out” or unsubscribe mechanism or other means provided within the
          communications that you receive, or by contacting us. We reserve the
          right to notify you of changes or updates to the Service whenever
          necessary.
        </NP>
        <BNP>6. Access to and Accuracy of Your Information</BNP>
        <NP>
          iMoba may allow registered users to directly access the account
          information they provided and make corrections or updates to that
          information upon login at any time. The accuracy of such information
          is solely the responsibility of the user.
        </NP>
        <NP>
          You may also request access to and correction of other personal
          information about you that you have directly provided to us through
          iMoba . To protect your privacy and security, we may require you to
          verify your identity.
        </NP>
        <NP>
          If you request to deactivate your account or delete your personal
          information, we will endeavor to fulfill your request in accordance
          with our policies but some personal information may persist in backup
          copies for a certain period of time and may be retained as necessary
          for legitimate business purposes or to comply with our legal
          obligations.
        </NP>
        <NP>
          Access to personal information that iMoba may gather from public
          records and other sources is subject to applicable laws.
        </NP>
        <BNP>7. Data Security</BNP>
        <NP>
          We use a variety of administrative, physical and technical security
          measures intended to safeguard your personal information.
        </NP>
        <BNP>8. Cross-Border Transfer</BNP>
        <NP>
          Your personal information may be transferred to, accessed from and
          stored in servers and facilities located outside the country where you
          live as may be necessary for the purposes described in this privacy
          policy. When we transfer your personal information internationally, we
          take steps intended to ensure that the information continues to
          receive appropriate protections.
        </NP>
        <NP>
          Moba will see to it that Data stored outside the EU are stored by
          companies that have certified their services to the EU-U.S. Privacy
          Shield Framework. To learn more about the Privacy Shield program, and
          to view these entities’ certification, please visit
          <Link
            href="https://www.privacyshield.gov"
            variant="body2"
            style={{ marginLeft: "5px", fontSize: "1rem" }}
          >
            www.privacyshield.gov
          </Link>
        </NP>
        <BNP>9. Changes</BNP>
        <NP>
          We may change this privacy policy from time to time. Any changes will
          be posted on iMoba and MOBA's website with an updated revision date.
        </NP>
        <BNP>10. Contact</BNP>
        <NP>
          If you have any questions, comments or requests regarding this privacy
          policy or our processing of your information, please contact:
          <Link
            href="mailto:imoba@moba.net"
            variant="body2"
            style={{ marginLeft: "5px", fontSize: "1rem" }}
          >
            imoba@moba.net
          </Link>
        </NP>
        <BNP>11. Governing Law and Jurisdiction</BNP>
        <NP>
          This Privacy Policy is - as far as permitted under the privacy laws of
          your jurisdiction - governed by and construed in accordance with the
          laws of the Netherlands and any action arising out of or relating to
          this Privacy Policy shall be filed only in the District Court of
          Arnhem, Gelderland, The Netherlands. You hereby consent and submit to
          the exclusive jurisdiction of this court for the purpose of litigating
          any such action.
        </NP>
      </NumberedList>
    </TextInfoPage>
  );
}
