import { isMobile } from "react-device-detect";

export const DefaultRadarChartSettings = {
  title: false,
  pane: {
    size: "80%",
  },
  chart: {
    polar: true,
    type: "line",
  },
  xAxis: {
    tickmarkPlacement: "on",
    lineWidth: 0,
    gridZIndex: 5,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      turboThreshold: 0,
      marker: {
        radius: 2,
      },
      cursor: "pointer",
      point: {
        events: {},
      },
      pointPlacement: "on",
      lineWidth: 1,
      events: {
        legendItemClick: function (e: any) {
          e.preventDefault();
        },
      },
    },
  },
  yAxis: {
    gridLineInterpolation: "polygon",
    lineWidth: 0,
    min: 0,
    max: 100,
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  legend: {
    align: "right",
    verticalAlign: "middle",
    layout: "vertical",
  },
  exporting: {
    enabled: !isMobile,
    buttons: {
      contextButton: {
        menuItems: [
          "printChart",
          "separator",
          "downloadPNG",
          "downloadJPEG",
          "downloadPDF",
          "downloadSVG",
          "separator",
          "downloadCSV",
          "downloadXLS",
        ],
      },
    },
  },
};
