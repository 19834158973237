import { FeaturePolicyItems } from "../../../globalHooks/authorizationHooks";
import { usePolicyFeatures } from "../../../globalHooks/usePolicyFeatures";
import { EggUnit } from "../../../utility/EggUnitUtility";
import useLaneProducts from "../LaneProducts/useLaneProducts";
import { LaneProductsDistribution } from "./LaneProductsDistribution";

export default function LaneProductsDistributionContainer({
  machineId,
  automaticRefresh,
}: {
  machineId: MachineId;
  automaticRefresh: boolean;
}) {
  const { data, awaitingResponse, units, setUnits } = useLaneProducts({
    machineId,
    automaticRefresh,
  });

  const { hasFeatureEnabled } = usePolicyFeatures();

  const isRemainderEnabled = hasFeatureEnabled(
    FeaturePolicyItems.EnableRemainingLaneProductTargetsGraph
  );

  return (
    <LaneProductsDistribution
      data={data}
      isRemainderEnabled={isRemainderEnabled}
      isAwaitingResponse={awaitingResponse}
      units={units}
      onUnitsChange={(u: EggUnit) => setUnits(u)}
    />
  );
}
