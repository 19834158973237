import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { t } from "../../../utility/TranslateUtility";

export type ToolbarSelectProps = {
  selectLabel: string;
  selectItems: Array<string>;
  selectedItem: number;
  onSelectedItemChange: (newItemIndex: number) => void;
};

/**
 * Renders the given selectItems in a Material UI Select component.
 * To be used inside a Toolbar.
 *
 * @param selectLabel The label that is rendered above the Select component.
 * @param selectItems The items that are rendered as all the Select options.
 */
export function ToolbarSelect({
  selectLabel,
  selectItems,
  selectedItem,
  onSelectedItemChange,
}: ToolbarSelectProps) {
  const classes = useStyles();
  const isMobileSize = useIsMobileSize('xs');

  const handleChange = (event: any) => {
    onSelectedItemChange(event.target.value);
  };

  return (
    <FormControl fullWidth={isMobileSize}>
      <InputLabel id="toolbar-select-label">{selectLabel}</InputLabel>
      <Select
        labelId="toolbar-select-label"
        id="toolbar-select"
        value={selectedItem}
        onChange={handleChange}
        classes={{
          select: classes.select,
        }}
        MenuProps={{
          PopoverClasses: {
            root: classes.popoverRoot,
          },
        }}
      >
        {selectItems.map((selectItem: any, index: number) => (
          <MenuItem key={index} value={index}>
            {t(selectItem)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: theme.spacing(12),
  },
  popoverRoot: {
    zIndex: "2500 !important" as any,
  },
}));
