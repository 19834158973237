import { EggType } from "./EggUtility";

/**
 * Groups period together based on the selected EggType.
 *
 * Total Eggs => All egg types summed together
 * Mapped Eggs => Cage, Barn, Free Range and Organic eggs together
 * Otherwise => The single egg type
 *
 * @param selectedEggType
 * @param periods
 */
export function filterPeriods(selectedEggType: EggType, periods: Array<any>) {
  return periods.filter((period) => period.eggType === selectedEggType);
}

export enum PeriodDataType {
  DailyTotal,
  DailyPercentage,
}

/**
 * Based on EggType and dataType, determines the total number to display.
 *
 * @param eggType
 * @param data
 * @param periodDataType
 */
export function determineTotalValue(
  eggType: EggType,
  data: any,
  periodDataType: PeriodDataType
): number {
  let dataKey = determineDataKey(periodDataType);

  switch (eggType) {
    case EggType.Barn:
      dataKey += "BarnEggs";
      break;
    case EggType.Cage:
      dataKey += "CageEggs";
      break;
    case EggType.FreeRange:
      dataKey += "FreeRangeEggs";
      break;
    case EggType.Organic:
      dataKey += "OrganicEggs";
      break;
    case EggType.Unmapped:
      dataKey += "UnmappedEggs";
      break;
    case EggType.Unknown:
      dataKey += "UnknownEggs";
      break;
    case EggType.Mapped:
      dataKey += "MappedEggs";
      break;
    case EggType.AllEggTypes:
      dataKey += "AllEggTypes";
      break;
    default:
      dataKey += "AllEggTypes";
      break;
  }

  return data[dataKey];
}

function determineDataKey(periodDataType: PeriodDataType) {
  if (periodDataType === PeriodDataType.DailyTotal) {
    return "dailyTotal";
  }

  return "dailyPercentage";
}

export type ProductionTimeFrame = {
  productionStartTime: Date;
  productionEndTime: Date;
};

/**
 * Contains daily percentage for each egg type
 */
export type DailyPercentageByEggtype = {
  dailyPercentageAllEggTypes: number;
  dailyPercentageCageEggs: number;
  dailyPercentageBarnEggs: number;
  dailyPercentageFreeRangeEggs: number;
  dailyPercentageOrganicEggs: number;
  dailyPercentageUnknownEggs: number;
  dailyPercentageUnmappedEggs: number;
  dailyPercentageMappedEggs: number;
};

/**
 * Contains daily total for each egg type
 */
export type DailyTotalByEggtype = {
  dailyTotalAllEggTypes: number;
  dailyTotalUnknownEggs: number;
  dailyTotalCageEggs: number;
  dailyTotalBarnEggs: number;
  dailyTotalFreeRangeEggs: number;
  dailyTotalOrganicEggs: number;
  dailyTotalUnmappedEggs: number;
  dailyTotalMappedEggs: number;
};

/**
 * Represents period of time for specific egg type
 */
export type EggTypePeriod = {
  eggType: EggType;
  dateTime: Date;
};

/**
 * Finds the Production Time Frame of the given array.
 * This is the period without leading zero values.
 *
 * E.g.: The period between brackets "[", "]"
 * 0 0 [10 10 0 0 10 10 10] 0 0 0
 *
 * Does not modify the original array.
 *
 * @param data The data to find the production time frame for.
 * @param yAxisKey The value that is checked for zero values.
 *
 * @returns A new array containing the Production Time Frame.
 */
export function FindProductionTimeFrame(data: any[], yAxisKey: string) {
  if (!data || data.length === 0) return [];

  let workingArray = [...data];

  if (data.every((item) => item[yAxisKey] === 0)) return data;

  const startIndex = workingArray.findIndex(
    (item: any) => item[yAxisKey] !== 0
  );

  workingArray = workingArray.reverse();

  const endIndex = workingArray.findIndex((item: any) => item[yAxisKey] !== 0);

  return data.slice(startIndex, data.length - endIndex);
}
