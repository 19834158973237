import * as Highcharts from "highcharts";
import { useMemo } from "react";
import { colors } from "../../../colors";
import { getLastElement } from "../../../utility/ArrayUtility";
import { everyMinute } from "../../../utility/DateUtility";
import { FormatWithTwoDecimals } from "../../../utility/NumberFormatUtility";
import { t } from "../../../utility/TranslateUtility";
import { format, TooltipBuilder } from "../../ProductionViews/Tooltip";
import HighchartsFullscreenWrapper from "../Wrappers/HighchartsFullscreenWrapper";

const tooltipFormatter = new TooltipBuilder()
  .addXHourWithMinutes(t("widgets.fillRate.tooltipFillRateTime"))
  .addYWithTransfomation({
    label: t("widgets.fillRate.tooltipFillRate"),
    unit: "%",
    transformation: FormatWithTwoDecimals,
  })
  .add((data) =>
    format(
      { label: t("widgets.fillRate.tooltipCarriersFilled") },
      data.point.carriersFilled
    )
  )
  .add((data) =>
    format(
      { label: t("widgets.fillRate.tooltipCarriersTotal") },
      data.point.carriersTotal
    )
  )
  .buildFormatter();

export type FillRateApiResponse = {
  data: FillRateApiData;
};

export type FillRateApiData = {
  fillRatePeriods: Array<FillRatePeriod>;
};

export type FillRatePeriod = {
  dateTime: Date;
  fillRatePercentage: number;
  carriersFilled: number;
  carriersTotal: number;
};

const ChartSettings: any = {
  chart: {
    type: "column"
  },
  colors: [colors.mobaBlue],
  plotOptions: {
    column: {
      groupPadding: 0,
      pointPadding: 0,
      borderWidth: 0,
      grouping: false,
    },
  },
  xAxis: {
    type: "datetime",
    minorTicks: true,
    showFirstLabel: true,
    showLastLabel: true,
    tickInterval: everyMinute,
    max: 0,
    minorGridLineWidth: 0,
  },
  yAxis: {
    title: {
      text: t("widgets.fillRate.yAxisTitle"),
    },
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  tooltip: tooltipFormatter,
  series: [
    {
      data: [],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

function MapToChartData(input: FillRateApiData) {
  const last_element = getLastElement(input.fillRatePeriods);

  const newMax = last_element.dateTime;

  const result = {
    data: input.fillRatePeriods.map((fillRatePeriod: FillRatePeriod) => ({
      x: fillRatePeriod.dateTime,
      y: fillRatePeriod.fillRatePercentage,
      carriersFilled: fillRatePeriod.carriersFilled,
      carriersTotal: fillRatePeriod.carriersTotal,
    })),
  };

  return {
    series: [result],
    maxDate: newMax.getTime(),
  };
}

export interface FillRateWidgetPresentationProps {
  awaitingResponse: boolean;
  data: FillRateApiData;
}

export default function FillRateWidgetPresentation({
  awaitingResponse,
  data,
}: FillRateWidgetPresentationProps) {
  const newOptions = useMemo(() => {
    if (awaitingResponse || !data) return null;

    const newChartData = MapToChartData(data);

    return {
      ...ChartSettings,
      xAxis: {
        ...ChartSettings.xAxis,
        max: newChartData?.maxDate,
      },
      series: newChartData?.series
    };
  }, [data, awaitingResponse]);

  return (
    <HighchartsFullscreenWrapper
      title={t("widgets.fillRate.title")}
      awaitingResponse={awaitingResponse}
      highcharts={Highcharts}
      chartOptions={newOptions}
    />
  );
}
