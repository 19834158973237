import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { SaveButton } from "../../../components/Settings/Common";
import { useOffgradeLabelMapping } from "../../../components/Settings/OffgradeLabelsMapping/hooks";
import OffgradeLabelsTablePresentation from "../../../components/Settings/OffgradeLabelsMapping/OffgradeLabelsPagePartPresentation";
import EggTypeTargetsPresentation from "../../../components/Settings/Targets/EggTypeTargetsPresentation";
import GeneralTargetsPresentation from "../../../components/Settings/Targets/GeneralTargetsPresentation";
import { useTargetSettings } from "../../../components/Settings/Targets/hooks";
import UnitsPagePartPresentation from "../../../components/Settings/Units/UnitsPagePartPresentation";
import { useDispatchAlert } from "../../../components/UI/Alert";
import { t } from "../../../utility/TranslateUtility";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3),
  },
}));

export default function TargetPageContainer() {
  const classes = useStyles();
  const labelMapping = useOffgradeLabelMapping();

  const targetSettings = useTargetSettings();

  const dispatchAlert = useDispatchAlert();
  const [isSaving, setIsSaving] = useState(false);

  function handleOnSaving() {
    setIsSaving(true);
    Promise.all([
      labelMapping.saveRows(),
      targetSettings.saveTargetSettings(),
    ]).then(
      () => {
        setIsSaving(false);
        dispatchAlert({
          message: t("managementSettings.settingsSaveSuccessMessage"),
          messageType: "success",
        });
      },
      () => {
        setIsSaving(false);
        dispatchAlert({
          message: t("managementSettings.settingsSaveFailedMessage"),
          messageType: "error",
        });
      }
    );
  }

  const isDirty = labelMapping.isDirty || targetSettings.isDirty;
  const savingEnabled = isDirty && !isSaving;

  return (
    <div className={classes.wrapper}>
      <SaveButton disabled={!savingEnabled} onClick={handleOnSaving} />
      <Prompt when={isDirty} message={t("managementSettings.pendingChanges")} />
      <UnitsPagePartPresentation
        settings={targetSettings.units}
        onSettingsChange={(s) => targetSettings.setUnits(s)}
      />
      <GeneralTargetsPresentation
        targets={targetSettings.generalTargets}
        onTargetsChange={(v) => targetSettings.setGeneralTargets(v)}
      />
      <EggTypeTargetsPresentation
        eggTypeTargets={targetSettings.eggTypeTargets}
        onEggTypeTargetsChange={(v) => targetSettings.setEggTypeTargets(v)}
      />
      <OffgradeLabelsTablePresentation
        rows={labelMapping.internalRows}
        onRowEdit={labelMapping.changeOffgradeLabel}
        isSaving={isSaving}
      />
    </div>
  );
}
