import React from'react';
import { t } from '../../utility/TranslateUtility';
import { PaperComponent } from '../Users/AddOrUpdateUser/Common';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@material-ui/core';

export interface ConfirmActionDialogProps {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
  onReject: () => void;
}

function ConfirmActionDialog({
  isOpen,
  message,
  onConfirm,
  onReject
}: ConfirmActionDialogProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={onReject}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {t("alarmManagement.messages.warning")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onReject} color="primary">
          {t("alarmManagement.buttons.no")}
        </Button>
        <Button autoFocus onClick={onConfirm} color="primary">
          {t("alarmManagement.buttons.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmActionDialog;
