import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import { useLogout } from "../../globalHooks/authenticationHooks";

function LogoutPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  useLogout();

  return (
    <>
      <div className={classes.centered}>
        <div>
          <div className={classes.centeredSpinner}>
            <CircularProgress />
          </div>
          <div>
            <Typography variant="h5" className={classes.loadingText}>
              {t("logoutPage.loadingMessage")}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    height: "100%",
    width: "100%",
  },
  loadingText: {
    marginTop: theme.spacing(4),
  },
  centeredSpinner: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
}));

export default LogoutPage;
