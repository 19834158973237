import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { SaveButton } from "../../../components/Settings/Common";
import { useQualityChartSettingManagement } from "../../../components/Settings/QualityCharts/hooks";
import QualityChartsPresentation from "../../../components/Settings/QualityCharts/QualityChartsPresentation";
import { useDispatchAlert } from "../../../components/UI/Alert";
import { t } from "../../../utility/TranslateUtility";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3),
  },
}));

export default function ViewSettingsPageContainer() {
  const classes = useStyles();
  const qualityChartSettings = useQualityChartSettingManagement();
  const [isSaving, setIsSaving] = useState(false);
  const dispatchAlert = useDispatchAlert();

  function handleOnSaving() {
    setIsSaving(true);
    Promise.all([qualityChartSettings.saveQualityChartSetting()]).then(() => {
      setIsSaving(false);
      dispatchAlert({
        message: t("managementSettings.settingsSaveSuccessMessage"),
        messageType: "success",
      });
    });
  }

  const savingEnabled = qualityChartSettings.isDirty && !isSaving;

  return (
    <div className={classes.wrapper}>
      <SaveButton disabled={!savingEnabled} onClick={handleOnSaving} />
      <Prompt
        when={qualityChartSettings.isDirty}
        message={t("managementSettings.pendingChanges")}
      />
      <QualityChartsPresentation
        settings={qualityChartSettings.qualityChartSetting}
        onSettingsChange={(s) => qualityChartSettings.setQualityChartSetting(s)}
      />
    </div>
  );
}
