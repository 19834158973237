function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(rgb: number[]) {
  return (
    "#" +
    componentToHex(rgb[0]) +
    componentToHex(rgb[1]) +
    componentToHex(rgb[2])
  );
}

function interpolateColor(color1: number[], color2: number[], factor: number) {
  var result = [...color1];
  for (var i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
}

export const weightMaxColor = [19, 37, 68];
export const weightMinColor = [217, 220, 226];

export function getColorsBetween(
  color1: number[],
  color2: number[],
  steps: number
) {
  var stepFactor = 1 / (steps - 1),
    colors = [];

  for (var i = 0; i < steps; i++) {
    colors.push(rgbToHex(interpolateColor(color1, color2, stepFactor * i)));
  }

  return colors;
}
