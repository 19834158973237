import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { t } from "../../../utility/TranslateUtility";
import {
  iMobaModule,
  iMobaUser,
  PaperComponent,
} from "../AddOrUpdateUser/Common";
import { UserListPresentation } from "../UserList/UserListPresentation";

export interface AssignUserToModulePresentationProps {
  isOpen: boolean;
  onClose: () => void;
  users: Array<iMobaUser>;
  selectedModule: iMobaModule;
  handleAssign: (user: iMobaUser, text?: string) => void;
  isLoading: boolean;
  isLoadingUsers: boolean;
}

export function AssignUserToModulePresentation({
  isOpen,
  onClose,
  users,
  selectedModule,
  handleAssign,
  isLoading,
  isLoadingUsers,
}: AssignUserToModulePresentationProps) {
  return (
    <Dialog
      maxWidth={"md"}
      open={isOpen}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {t("users.messages.AssignOrRemoveModules")}
      </DialogTitle>
      <DialogContent>
        <UserListPresentation
          rowsToDisplay={["email", "status"]}
          users={users}
          totalUsers={users.length}
          actionsToDisplay={["assignModule"]}
          selectedModule={selectedModule}
          onAssingModule={handleAssign}
          isLoading={isLoading || isLoadingUsers}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          {t("users.buttons.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
