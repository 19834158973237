import {
  Badge,
  CircularProgress,
  makeStyles,
  Paper,
  TableRow,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import React from "react";
import { colors } from "../../../colors";
import { OffgradeLabel } from "../../../pages/management-settings/Common";
import { t } from "../../../utility/TranslateUtility";
import {
  StyledTableCell,
  StyledTableRow,
  useTableStyles,
} from "../../UI/Table/Table";
import { SettingsCard } from "../Common";
import { OffgradeLabelSelect } from "./OffgradeLabelSelect";

export type InternalOffgradeLabelItem = OffgradeLabel & {
  isDirty: boolean;
};

interface OffgradeLabeelsTablePresentation {
  rows: Array<InternalOffgradeLabelItem>;
  onRowEdit: (
    editedRow: InternalOffgradeLabelItem,
    offgradeLabelValue: string
  ) => void;
  isSaving: boolean;
}

export default function OffgradeLabelsTablePresentation({
  rows,
  onRowEdit,
  isSaving,
}: OffgradeLabeelsTablePresentation) {
  const classes = useTableStyles();

  return (
    <SettingsCard
        title={t("offgradeLabelsPage.title")}
        subtitle={t("offgradeLabelsPage.subtitle")}
      >
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="offgrade-label-table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.firstColumn}>
                {t("offgradeLabelsPage.table.customOffgradeLabelName")}
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="left">
                {t("offgradeLabelsPage.table.offgradeLabel")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableContent onRowEdit={onRowEdit} rows={rows} isSaving={isSaving} />
          </TableBody>
        </Table>
      </TableContainer>
    </SettingsCard>
  );
}

const useTableContentStyles = makeStyles((theme) => ({
  emptyMessage: {
    textAlign: "center",
  },
  stillSaving: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  savingText: {
    lineHeight: "40px",
    marginLeft: theme.spacing(2),
  },
}));

function StillSavingMessage() {
  const classes = useTableContentStyles();
  return (
    <div className={classes.stillSaving}>
      <CircularProgress />
      <Typography className={classes.savingText}>
        {t("managementSettings.isSaving")}
      </Typography>
    </div>
  );
}

interface TableContentProps {
  rows: Array<InternalOffgradeLabelItem>;
  onRowEdit: (
    editedRow: InternalOffgradeLabelItem,
    offgradeLabelValue: string
  ) => void;
  isSaving: boolean;
}

function TableContent({ rows, onRowEdit, isSaving }: TableContentProps) {
  const classes = useTableContentStyles();

  return (
    <>
      {rows.length === 0 ? (
        <StyledTableRow>
          <StyledTableCell
            component="th"
            scope="row"
            colSpan={3}
            className={classes.emptyMessage}
          >
            {isSaving ? (
              <StillSavingMessage />
            ) : (
              t("offgradeLabelsPage.table.emptyMessage")
            )}
          </StyledTableCell>
        </StyledTableRow>
      ) : (
        rows.map((offgradeLabekRow) => (
          <StyledTableRow key={offgradeLabekRow.mmiLabel}>
            <StyledTableCell component="th" scope="row">
              {offgradeLabekRow.isDirty && (
                <TableBadge offgradeLabel={offgradeLabekRow.customLabel} />
              )}
              {offgradeLabekRow.mmiLabel}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
              {offgradeLabekRow.mmiLabelName}
            </StyledTableCell>
            <StyledTableCell align="left">
              <OffgradeLabelSelect
                selectedOffgradeLabel={offgradeLabekRow.customLabel}
                onOffgradeLabelChange={(value) =>
                  onRowEdit(offgradeLabekRow, value)
                }
              />
            </StyledTableCell>
          </StyledTableRow>
        ))
      )}
    </>
  );
}

const useBadgeClasses = makeStyles((theme) => ({
  badge: {
    marginRight: theme.spacing(2),
  },
  colorBadge: {
    backgroundColor: colors.mobaBlue,
  },
}));

function TableBadge({
  offgradeLabel: offgradeLabel,
}: {
  offgradeLabel: string;
}) {
  const classes = useBadgeClasses(offgradeLabel);
  return (
    <Badge
      color="primary"
      variant="dot"
      className={classes.badge}
      classes={{ colorPrimary: classes.colorBadge }}
    />
  );
}
