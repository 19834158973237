import { useState } from "react";
import { IDeviceInstallation } from "../components/Notifications/Common";

export function useDeviceInstallation(): Promise<
  IDeviceInstallation | undefined
> {
  // @ts-ignore
  const getInstallationId = window.getDeviceInstallationId;
  const [isMobileApp] = useState(useIsGetDeviceInstallationSupported());

  var retrieveDeviceInstallation: Promise<IDeviceInstallation | undefined> =
    new Promise(() => undefined);

  if (isMobileApp) {
    retrieveDeviceInstallation = getInstallationId().then(
      function (result: string) {
        return JSON.parse(result);
      },
      function (error: string) {
        console.error(error);
      }
    );
  }
  return retrieveDeviceInstallation;
}

export function useIsGetDeviceInstallationSupported(): boolean {
  // @ts-ignore
  return !!window.getDeviceInstallationId;
}
export async function useDeviceHasNotificationsEnabled(): Promise<boolean> {
  var installation: IDeviceInstallation;

  // @ts-ignore
  const getInstallationId = window.getDeviceInstallationId;
  var hasFunction = !!getInstallationId;
  if (hasFunction) {
    var json = await getInstallationId();
    installation = JSON.parse(json);

    return (
      installation.notificationsSupported &&
      installation.pushChannel !== null &&
      installation.pushChannel !== undefined
    );
  }

  return false;
}
