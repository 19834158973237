export function GetSupplierName(
  supplierShedName: string,
  supplierShed: string
): string {
  return supplierShedName.slice(
    0,
    supplierShedName.length - (supplierShed.length + 3)
  );
}

export function GetSupplierShed(supplierShedName: string): string {
  const shedRegex = /\(([^)]+)\)$/;

  const shedRegexResult = supplierShedName.match(shedRegex);
  return shedRegexResult ? shedRegexResult[shedRegexResult?.length - 1] : "";
}

export function GetSupplierNameAndShed(
  supplierShedName: string
): SupplierNameAndShed {
  const supplierShed = GetSupplierShed(supplierShedName);
  const supplierName = GetSupplierName(supplierShedName, supplierShed);

  return { supplierShed, supplierName };
}

export function GetSupplierNameAndShedURI(supplierShedName: string): string {
  return CreateURI(GetSupplierNameAndShed(supplierShedName));
}

export function CreateURI(supplierNameAndShed: SupplierNameAndShed): string {
  return `supplier/${encodeURIComponent(
    supplierNameAndShed.supplierName
  )}/shed/${supplierNameAndShed.supplierShed}`;
}

export interface SupplierNameAndShed {
  supplierShed: string;
  supplierName: string;
}
