import { EggUnit } from "../../../utility/EggUnitUtility";
export const outletLaneNumber = 33;
export const endLaneNumber = 34;

export interface MapProductsResponse {
  name: string;
  showInLegend: boolean;
  data: any[];
}

export enum LaneType {
  production,
  outlet = outletLaneNumber,
  end = endLaneNumber,
}

export interface LaneProductsView {
  packingLanes: PackingLane[];
}

export interface PackingLane {
  packingLaneNumber: number;
  productId: number;
  productName?: string;
  packingLaneGrades: PackingLaneGrade[];
  laneOffgrades: number;
  laneTotal: number;
  productTotal: number;
  productTarget: number;
  remainingTarget: number;
}

export interface PackingLaneGrade {
  weightLabel: string;
  grade: number;
  count: number;
}

export interface LaneSeriesData {
  custom: { lane: PackingLane; laneType: LaneType; units: EggUnit };
  x: number;
  y: number;
}

export interface TooltipLaneSeriesData {
  point: LaneSeriesData;
}

export function laneNumberToType(lane: number): LaneType {
  if (lane === outletLaneNumber) return LaneType.outlet;
  if (lane === endLaneNumber) return LaneType.end;
  return LaneType.production;
}

/**
 * Comparison function to sort by descending.
 * @param a first element for comparison
 * @param b second element for comparison
 * @return 0, -1 or 1 if a is equal to b, a is less than b or if a is greater than b respectively.
 */
export function byPackingLaneGradeDescending(): (
  a: PackingLaneGrade,
  b: PackingLaneGrade
) => number {
  return (a, b) => a.grade - b.grade;
}
