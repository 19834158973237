import React from "react";
import { ToolbarSelect } from "./ToolbarSelect";
import { t } from "../../../utility/TranslateUtility";

const EggTypeSelectValues = [
  "eggTypes.unknown",
  "eggTypes.cage",
  "eggTypes.barn",
  "eggTypes.freeRange",
  "eggTypes.organic",
  "eggTypes.unmapped",
  "eggTypes.mapped",
  "eggTypes.allEggTypes",
];

export interface ToolbarEggTypeSelectProps {
  selectedEggType: number;
  onEggTypeChange: (newEggType: number) => void;
}

/**
 * A select that can be used inside a Toolbar.
 * Contains all selectable eggtypes.
 */
export function ToolbarEggTypeSelect({
  selectedEggType,
  onEggTypeChange,
}: ToolbarEggTypeSelectProps) {
  return (
    <ToolbarSelect
      selectLabel={t("toolbarEggTypeSelect.label")}
      selectItems={EggTypeSelectValues}
      selectedItem={selectedEggType}
      onSelectedItemChange={onEggTypeChange}
    />
  );
}
