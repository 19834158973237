import React from "react";
import { IDeviceRegistration } from "../Common";
import { EnableNotificationSwitch } from "../EnableNotificationSwitch";

export interface DeviceRegistrationProps {
  registration: IDeviceRegistration;
  notificationsEnabled: boolean;
  handleEnabledNotificationsChange: (
    registration: IDeviceRegistration,
    enabled: boolean
  ) => void;
}

function DeviceRegistration({
  registration,
  notificationsEnabled,
  handleEnabledNotificationsChange,
}: DeviceRegistrationProps) {
  return (
    <>
      <EnableNotificationSwitch
        notificationsEnabled={notificationsEnabled}
        onEnabledChange={(enabled) =>
          handleEnabledNotificationsChange(registration, enabled)
        }
      />
    </>
  );
}

export default DeviceRegistration;
