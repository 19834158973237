import { GetEggUnitTranslation } from "../../../utility/EggUnitUtility";
import { FormatEggs } from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import { format, TooltipBuilder } from "../../ProductionViews/Tooltip";
import {
  LaneType,
  PackingLaneGrade,
  TooltipLaneSeriesData,
} from "./LaneProductsTypes";
import { TooltipBuilderOptions, ToolTipFormatter } from "./Types";

const labels: any = t("widgets.laneProducts", { returnObjects: true });

export default function buildTooltipFormatter(
  options: TooltipBuilderOptions
): ToolTipFormatter {
  const tooltipFormatter = new TooltipBuilder();

  addProductNameLineAndLaneTotalLineAddGradeNameLines(tooltipFormatter);
  addLaneOffGrades(tooltipFormatter);
  addProductTotalLineAndProductTargetLine(tooltipFormatter);

  if (options.EnableRemainingLaneProductTargetsGraph) {
    addRemainingTargetLine(tooltipFormatter);
  }

  return tooltipFormatter.buildFormatter();
}

function addProductNameLineAndLaneTotalLineAddGradeNameLines(
  tooltipFormatter: TooltipBuilder
): void {
  tooltipFormatter.add((data: TooltipLaneSeriesData) => {
    const { lane, units, laneType } = data.point.custom;
    const { laneTotal, productName, productId } = lane;

    if (
      laneType === LaneType.outlet ||
      data.point.custom.laneType === LaneType.end
    ) {
      const label = laneType === LaneType.outlet ? labels.outlet : labels.end;
      return format(
        {
          label: label,
          unit: GetEggUnitTranslation(units),
          separator: true,
        },
        FormatEggs(laneTotal, units)
      );
    }

    const productNameLine = !!productName
      ? format({ label: productName }, "")
      : format(
          { label: labels.tooltip.productName, separator: true },
          productId
        );

    const gradeNameLines = lane.packingLaneGrades
      .map((p) =>
        format(
          {
            label: `${formatWeightLabel(p)}(${labels.tooltip.grade} ${
              p.grade
            })`,
            separator: true,
          },
          p.count
        )
      )
      .join(" ");

    const laneTotalLine = format(
      {
        label: labels.tooltip.laneTotal,
        unit: GetEggUnitTranslation(units),
        separator: true,
      },
      FormatEggs(laneTotal, units)
    );

    return productNameLine + laneTotalLine + gradeNameLines;
  });
}

function addLaneOffGrades(tooltipFormatter: TooltipBuilder): void {
  tooltipFormatter.add((data: TooltipLaneSeriesData) => {
    const { units } = data.point.custom;
    const { laneOffgrades } = data.point.custom.lane;

    if (laneOffgrades > 0) {
      return format(
        {
          label: labels.tooltip.laneOffgrades,
          unit: GetEggUnitTranslation(units),
          separator: true,
        },
        FormatEggs(laneOffgrades, units)
      );
    }

    return "";
  });
}

function addProductTotalLineAndProductTargetLine(
  tooltipBuilder: TooltipBuilder
): void {
  tooltipBuilder.add((data: TooltipLaneSeriesData) => {
    const { lane, units, laneType } = data.point.custom;
    const { productTarget, productTotal, productName } = lane;
    const isFetching = !productName;

    if (laneType === LaneType.outlet || laneType === LaneType.end) return "";

    const productTotalLine = format(
      {
        label: labels.tooltip.productTotal,
        unit: GetEggUnitTranslation(units),
        separator: true,
      },
      FormatEggs(productTotal, units)
    );

    const targetCount = isFetching ? labels.tooltip.fetching : productTarget;
    const targetUnits = isFetching ? undefined : GetEggUnitTranslation(units);
    let productTargetLine = format(
      {
        label: labels.tooltip.productTarget,
        separator: true,
        unit: targetUnits,
      },
      FormatEggs(targetCount, units)
    );

    if (!productTarget) {
      productTargetLine = format(
        { label: labels.tooltip.productTarget, separator: true },
        labels.tooltip.none
      );
    }

    return productTotalLine + productTargetLine;
  });
}

function addRemainingTargetLine(tooltipBuilder: TooltipBuilder): void {
  tooltipBuilder.add((data: TooltipLaneSeriesData) => {
    const { lane, units } = data.point.custom;
    const { productName, remainingTarget } = lane;
    const isFetching = !!!productName;

    const remainingCount = isFetching
      ? labels.tooltip.fetching
      : remainingTarget;
    const remainingUnits = isFetching
      ? undefined
      : GetEggUnitTranslation(units);

    let remainingTargetLine = format(
      {
        label: labels.tooltip.remainingTarget,
        separator: true,
        unit: remainingUnits,
      },
      FormatEggs(remainingCount, units)
    );

    if (!remainingTarget) {
      remainingTargetLine = format(
        { label: labels.tooltip.remainingTarget, separator: true },
        labels.tooltip.none
      );
    }

    return remainingTargetLine;
  });
}

function formatWeightLabel(p: PackingLaneGrade) {
  return p.weightLabel === "" ? "" : `${p.weightLabel} `;
}
