import { Box, Divider } from "@material-ui/core";
import { PageHeader, TextInfoPage } from "../../components/Page";
import { AcceptTermsAndConditions } from "../../components/TermsAndConditions/Accept";
import {
  AlertMessageForMachineOwner,
  AlertMessageForNonMachineOwner,
} from "../../components/TermsAndConditions/Alert/TermsAndConditionAlerts";
import { TermsAndConditions } from "../../components/TermsAndConditions/TermsAndConditions";
import { TermsAndConditionsVersion } from "../../components/TermsAndConditions/TermsAndConditionsVersion";
import {
  Features,
  useAuthorizationScope,
} from "../../globalHooks/authorizationHooks";

export default function AcceptTermsAndConditionsPage() {
  const authorizationScope = useAuthorizationScope();

  const isOwnerOfMachine =
    authorizationScope.hasAuthorizationSpecificOnlyForSelectedMachine(
      Features.Owner
    );

  return (
    <TextInfoPage>
      <PageHeader>Terms & Conditions of Use for iMoba Application</PageHeader>
      {isOwnerOfMachine ? <OwnerView /> : <NonMachineOwnerView />}
    </TextInfoPage>
  );
}

function OwnerView() {
  return (
    <>
      <TermsAndConditionsVersion />
      <Box mt={2}>
        <AlertMessageForMachineOwner />
      </Box>
      <TermsAndConditions />
      <Divider />
      <AcceptTermsAndConditions />
    </>
  );
}

function NonMachineOwnerView() {
  return (
    <Box mt={2}>
      <AlertMessageForNonMachineOwner />
    </Box>
  );
}
