import React, { useMemo } from "react";
import { GroupMetadata } from "../../../api/PerformancePro/IPerformanceProService";
import { t } from "../../../utility/TranslateUtility";
import { ChartCard, ChartSubTitle } from "../../ProductionViews/Common";
import { useQualityChartSettingManagement } from "../../Settings/QualityCharts/hooks";
import { usePerformanceGroupMeasure } from "../Grouped/performanceProGroupHooks";
import { KpiMeasureGroupType, PerformanceGroupItem } from "../Types";
import { ShellQualityChart } from "./ShellQualityChart";

export default function ShellQualityViewContainer() {
  const isInfeedBased =
    useQualityChartSettingManagement().qualityChartSetting
      .qualityChartSetting === "InfeedBasedCharts";

  const offgradeData = usePerformanceGroupMeasure(
    isInfeedBased
      ? KpiMeasureGroupType.InfeedOffgradeBySupply
      : KpiMeasureGroupType.TakeawayOffgradeBySupply
  );
  const totalEggsData = usePerformanceGroupMeasure(
    KpiMeasureGroupType.TotalBySupply
  );
  const totalWeightData = usePerformanceGroupMeasure(
    KpiMeasureGroupType.TotalWeightBySupply
  );

  const awaitingResponse = useMemo(() => {
    let awaiting = true;

    if (
      offgradeData.awaitingResponse === false &&
      totalEggsData.awaitingResponse === false &&
      totalWeightData.awaitingResponse === false
    )
      awaiting = false;

    return awaiting;
  }, [offgradeData, totalEggsData, totalWeightData]);

  const label = isInfeedBased
    ? t("uniqueViews.infeedBased")
    : t("uniqueViews.takeawayBased");

  return (
    <ShellQualityView
      offgradeData={offgradeData.data}
      totalEggsData={totalEggsData.data}
      totalWeightData={totalWeightData.data}
      chartSubtitleLabel={label}
      metadata={totalEggsData.metadata}
      awaitingResponse={awaitingResponse}
    />
  );
}

export function ShellQualityView({
  offgradeData,
  totalEggsData,
  totalWeightData,
  chartSubtitleLabel,
  metadata,
  awaitingResponse,
}: {
  offgradeData: PerformanceGroupItem[];
  totalEggsData: PerformanceGroupItem[];
  totalWeightData: PerformanceGroupItem[];
  chartSubtitleLabel: string;
  metadata: GroupMetadata;
  awaitingResponse: boolean;
}) {
  return (
    <>
      <ChartCard
        headerTitle={t("uniqueViews.shellQuality.cardHeader")}
        isLoading={awaitingResponse}
      >
        <ChartSubTitle>{chartSubtitleLabel}</ChartSubTitle>
        <ShellQualityChart
          offgradeData={offgradeData}
          totalEggsData={totalEggsData}
          totalWeightData={totalWeightData}
          metadata={metadata}
        />
      </ChartCard>
    </>
  );
}
