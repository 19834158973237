import { Button, makeStyles } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { GroupMetadata } from "../../../api/PerformancePro/IPerformanceProService";
import { colors } from "../../../colors";
import {
  IPerformanceProFilterOptions,
  usePerformanceProFilterOptions,
} from "../../../store/GlobalContext";
import { onlyUnique } from "../../../utility/ArrayUtility";
import { EggUnit } from "../../../utility/EggUnitUtility";
import { CreateURI } from "../../../utility/SupplyUtility";
import { t } from "../../../utility/TranslateUtility";
import { ChartCard, ChartSubTitle } from "../../ProductionViews/Common";
import { useTargetSettings } from "../../Settings/Targets/hooks";
import {
  AllKpiMeasuresOffgrade,
  InfeedOrTakeawayBases,
  KpiMeasureGroupType,
  PerformanceGroupItem,
} from "../Types";
import { StackedBarChartMapping, XAxisType } from "./GroupsChartMapping";
import {
  usePerformanceEggTotals,
  usePerformanceGroupMeasure,
} from "./performanceProGroupHooks";
import { StackedBarBaseChart } from "./StackedBarBaseChart";

const useStyles = makeStyles(() => {
  const btnCommon = {
    width: "40px !important",
    height: "40px !important",
    minWidth: "0 !important",
    minHeight: "0 !important",
  };
  return {
    justifyBetween: {
      display: "flex",
      justifyContent: "space-between",
    },
    percentageBtn: {
      ...btnCommon,
      backgroundColor: colors.crackBlue,
      color: colors.white,
      "&:hover": {
        backgroundColor: colors.crackBlue,
        color: colors.white,
      },
    },
    numberBtn: {
      ...btnCommon,
      backgroundColor: colors.grassGreen,
      color: colors.white,
      "&:hover": {
        backgroundColor: colors.grassGreen,
        color: colors.white,
      },
    },
  };
});

export default function PerformanceProGroupedView({
  chartName,
  measure,
  supplierNameShed,
}: {
  chartName: string;
  measure: KpiMeasureGroupType;
  supplierNameShed?: string;
}) {
  const { units } = useTargetSettings();

  const options = usePerformanceProFilterOptions();

  const [usePercentage, setUsePercentage] = useState<boolean>(false);

  const {
    data,
    infeedOrTakeawayMeasure,
    chartMapping,
    metadata,
    awaitingResponse,
  } = usePerformanceGroupMeasure(measure);

  const totalsPerX = usePerformanceEggTotals(measure);

  const newTotalsPerX = useMemo(() => {
    return enrichData({ data: totalsPerX, metadata: metadata });
  }, [totalsPerX, metadata]);

  // Offgrades should use the total of all eggs
  const useGroupTotals = !AllKpiMeasuresOffgrade.includes(measure);

  let infeedOrTakeawayLabel = "";

  useEffect(() => {
    const usePercentageItem = localStorage.getItem(chartName);
    if (usePercentageItem !== null) {
      setUsePercentage(JSON.parse(usePercentageItem));
    }
  }, []);

  const onPercentageToggle = (value: boolean) => {
    setUsePercentage(value);
    localStorage.setItem(chartName, JSON.stringify(value));
  };

  switch (infeedOrTakeawayMeasure) {
    case InfeedOrTakeawayBases.Infeed:
      infeedOrTakeawayLabel = t("uniqueViews.infeedBased");
      break;
    case InfeedOrTakeawayBases.Takeaway:
      infeedOrTakeawayLabel = t("uniqueViews.takeawayBased");
      break;
  }

  const newData = useMemo(() => {
    return enrichData({ data, metadata });
  }, [data, metadata]);

  const browserHistory = useHistory();

  const handleBarSupplyClicked = (
    supplierName: string,
    supplierShed: string
  ) => {
    browserHistory.push(CreateURI({ supplierName, supplierShed }));
  };

  return (
    <StackedBarChartView
      options={options}
      data={newData}
      chartMapping={{ ...chartMapping, supplierNameShed: supplierNameShed }}
      eggUnit={units.eggUnit}
      chartSubtitleLabel={infeedOrTakeawayLabel}
      usePercentage={usePercentage}
      togglePercentage={onPercentageToggle}
      awaitingResponse={awaitingResponse}
      totalsPerX={newTotalsPerX}
      useGroupTotals={useGroupTotals}
      onBarSupplyClicked={
        !supplierNameShed && chartMapping.xAxisType === XAxisType.Supply
          ? handleBarSupplyClicked
          : null
      }
    />
  );
}

function enrichData({
  data,
  metadata,
}: {
  data: PerformanceGroupItem[];
  metadata: GroupMetadata;
}): PerformanceGroupItem[] {
  if (data.length <= 0) return [];

  const isDateSeries = data[0].x instanceof Date;
  if (isDateSeries) return data;

  const supplyAxis = createSupplyAxis({ data: data, metadata: metadata });

  return data.map<PerformanceGroupItem>((item) => ({
    ...item,
    x: supplyAxis[item.x as string],
  }));
}

function createSupplyAxis({
  data,
  metadata,
}: {
  data: PerformanceGroupItem[];
  metadata: GroupMetadata;
}): SupplyAxis {
  const supplyIds = data
    .map((item) => item.x as string)
    .filter(onlyUnique)
    .sort((a, b) => a.localeCompare(b));
  return supplyIds.reduce<SupplyAxis>((previous, current) => {
    const supplyId = current as unknown as number;
    const label = determineSeriesXLabel(metadata, supplyId);
    return {
      ...previous,
      [supplyId]: label,
    };
  }, {});
}

type SupplyAxis = {
  [supplyId: string]: string;
};

function determineSeriesXLabel(metadata: GroupMetadata, x: any): string {
  const supplyInfo = metadata.xAxis.values[x];

  if (!supplyInfo) return x;

  return supplyInfo.supplierNameShed;
}

export function StackedBarChartView({
  options,
  data,
  chartMapping,
  chartSubtitleLabel,
  eggUnit,
  usePercentage,
  togglePercentage,
  awaitingResponse,
  totalsPerX,
  useGroupTotals,
  onBarSupplyClicked,
}: {
  options: IPerformanceProFilterOptions;
  data: PerformanceGroupItem[];
  chartMapping: StackedBarChartMapping;
  eggUnit: EggUnit;
  chartSubtitleLabel: string;
  usePercentage: boolean;
  togglePercentage: (usePercentage: boolean) => void;
  awaitingResponse: boolean;
  totalsPerX: PerformanceGroupItem[];
  useGroupTotals: boolean;
  onBarSupplyClicked: Nullable<
    (supplierName: string, supplierShed: string) => void
  >;
}) {
  const classes = useStyles();

  return (
    <ChartCard
      headerTitle={
        chartMapping.supplierNameShed
          ? chartMapping.supplierNameShed + " " + t(chartMapping.headerTitle)
          : t(chartMapping.headerTitle)
      }
      isLoading={awaitingResponse}
    >
      <div className={classes.justifyBetween}>
        <ChartSubTitle>{chartSubtitleLabel}</ChartSubTitle>
        <Button
          className={usePercentage ? classes.percentageBtn : classes.numberBtn}
          onClick={() => togglePercentage(!usePercentage)}
          size="small"
        >
          {usePercentage ? "%" : "#"}
        </Button>
      </div>
      <StackedBarBaseChart
        data={data}
        options={options}
        chartMapping={chartMapping}
        eggUnit={eggUnit}
        usePercentage={usePercentage}
        totalsPerX={totalsPerX}
        useGroupTotals={useGroupTotals}
        onBarSupplyClicked={onBarSupplyClicked}
      />
    </ChartCard>
  );
}
