import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pre: {
    whiteSpace: "pre-wrap",
  },
}));

export function Code({ children }: any) {
  const classes = useStyles();

  return <pre className={classes.pre}>{children}</pre>;
}
