import React, { useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button, makeStyles } from '@material-ui/core';
import { t } from '../../utility/TranslateUtility';

export interface ChangeMachineNameContainerProps {
  machineName: string;
  saveMachineName: (name: string) => void;
}

const useStyles = makeStyles((theme) => ({
  changeNameContainer: {
    display: "flex",
    flexDirection: "column",
  },
  buttonsSection: {
    marginTop: theme.spacing(1),
  },
  saveButton: {
    backgroundColor: "#5cb85c",
    marginRight: theme.spacing(1),
  },
  machineNameText: {
    width: "100%",
  },
}));

function ChangeMachineNameContainer({machineName, saveMachineName}: ChangeMachineNameContainerProps) {
  const [showChangeName, setShowChangeName] = useState<boolean>(false);

  const handleSaveMachineName = (name: string) => {
    setShowChangeName(false);
    saveMachineName(name)
  }

  return (
    <>
      {showChangeName && 
        <MachineNameEdit
          name={machineName}
          onCancel={() => setShowChangeName(false)}
          onSave={handleSaveMachineName} 
      />}
      {!showChangeName &&
      <Button color="primary" onClick={() => setShowChangeName(true)}>
        {t("ownerPage.machineCard.changeMachineNameButtonLabel")}
      </Button>}
    </>
  );
}

function MachineNameEdit({
  name,
  onSave,
  onCancel,
}: {
  name: string;
  onSave: (name: string) => void;
  onCancel: () => void;
}) {
  const classes = useStyles();

  const [internalName, setInternalName] = useState(name);
  const handleNameChange = (newName: string) => {
    setInternalName(newName);
  };
  return (
    <div className={classes.changeNameContainer}>
      <TextField
        className={classes.machineNameText}
        variant="outlined"
        value={internalName}
        onChange={(e) => handleNameChange(e.target.value)}
      />
      <div className={classes.buttonsSection}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSave(internalName)}
          className={classes.saveButton}
          disabled={name === internalName}
        >
          <SaveIcon />
          {t("users.buttons.save")}
        </Button>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          {t("users.buttons.cancel")}
        </Button>
      </div>
    </div>
  );
}


export default ChangeMachineNameContainer;
