import { useAuth0 } from "@auth0/auth0-react";
import { IconButton, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { Skeleton } from "@material-ui/lab";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOwnerService } from "../../api/ServiceContext";
import { useDispatchAlert } from "../UI/Alert/alertHooks";

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(1),
  },
  addNewMachineIdButton: {
    marginTop: theme.spacing(1),
  },
  formWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
  },
  formItem: {
    flexGrow: 1,
  },
}));

export default function MissingAMachineButtonContainer({
  isLoading,
}: {
  isLoading: boolean;
}) {
  const service = useOwnerService();
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [missingMachineIds, setMissingMachineIds] = useState<
    Array<number | "">
  >([0]);
  const { user } = useAuth0();

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleOnDialogClose = () => {
    setDialogOpen(false);
  };

  const handleOnChangeMissingMachineIds = (newValue: string, index: number) => {
    if (newValue.match(/^(|\d)+$/)) {
      let valueAsNumber = newValue === "" ? 0 : parseInt(newValue);

      if (valueAsNumber < 0) {
        valueAsNumber = Math.abs(valueAsNumber);
      }

      let clone = [...missingMachineIds];
      clone[index] = valueAsNumber;
      setMissingMachineIds(clone);
    }
  };

  const handleOnClickAddMissingMachineId = () => {
    let clone = [...missingMachineIds];
    clone.push(0);
    setMissingMachineIds(clone);
  };

  const handleOnDeleteMachineId = (index: number) => {
    if (missingMachineIds.length <= 1) return;
    let clone = [...missingMachineIds];
    clone.splice(index, 1);
    setMissingMachineIds(clone);
  };

  const canSendMail = useMemo<boolean>(() => {
    return missingMachineIds.some((x) => x === 0);
  }, [missingMachineIds]);

  const handleSendMail = () => {
    handleOnDialogClose();
    service
      .sendMissingMachineMail(
        missingMachineIds.map<number>((x) => (x === "" ? 0 : +x)),
        user.email
      )
      .then(() => {
        dispatchAlert({
          message: t("ownerPage.missingAMachine.sendMailDialogSuccess"),
          messageType: "success",
        });
      })
      .catch(() => {
        dispatchAlert({
          message: t("generic.errorMessage"),
          messageType: "error",
        });
      })
      .finally(() => {
        setMissingMachineIds([]);
      });
  };

  return (
    <MissingAMachineButtonPresentation
      dialogOpen={dialogOpen}
      emailAddressToCc={user.email}
      missingMachineIds={missingMachineIds}
      canSendMail={canSendMail}
      onChangeMissingMachineIds={handleOnChangeMissingMachineIds}
      onClickAddMissingMachineId={handleOnClickAddMissingMachineId}
      onButtonClick={handleClickOpen}
      onDialogClose={handleOnDialogClose}
      onDeleteMachineId={handleOnDeleteMachineId}
      onSendMail={handleSendMail}
      isLoading={isLoading}
    />
  );
}

function MissingAMachineButtonPresentation({
  dialogOpen,
  emailAddressToCc,
  missingMachineIds,
  canSendMail,
  isLoading,
  onChangeMissingMachineIds,
  onClickAddMissingMachineId,
  onButtonClick,
  onDialogClose,
  onDeleteMachineId,
  onSendMail,
}: {
  dialogOpen: boolean;
  emailAddressToCc: string;
  missingMachineIds: Array<number | "">;
  canSendMail: boolean;
  isLoading: boolean;
  onChangeMissingMachineIds: (newValue: string, index: number) => void;
  onClickAddMissingMachineId: () => void;
  onButtonClick: () => void;
  onDialogClose: () => void;
  onDeleteMachineId: (index: number) => void;
  onSendMail: () => void;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <Skeleton
          color="primary"
          className={classes.button}
          variant="rect"
          width={200}
          height={35}
          animation="wave"
        />
      ) : (
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onButtonClick}
        >
          {t("ownerPage.missingAMachine.buttonLabel")}
        </Button>
      )}
      <div>
        <Dialog
          open={dialogOpen}
          onClose={onDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {t("ownerPage.missingAMachine.dialogTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("ownerPage.missingAMachine.dialogContentText")}
              <br />
              <br />
              {t("ownerPage.missingAMachine.dialogContentMail", {
                emailAddressToCc: emailAddressToCc,
              })}
            </DialogContentText>
            {missingMachineIds.map((x, index) => (
              <div key={index} className={classes.formWrapper}>
                <TextField
                  key={index}
                  autoFocus
                  margin="dense"
                  id="name"
                  label={t(
                    "ownerPage.missingAMachine.missingMachineIdInputLabel"
                  )}
                  type="number"
                  className={classes.formItem}
                  onChange={(event) =>
                    onChangeMissingMachineIds(event.target.value, index)
                  }
                  value={x || ""}
                />
                <IconButton
                  aria-label="delete"
                  onClick={() => onDeleteMachineId(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}

            <Button
              variant="contained"
              color="primary"
              onClick={onClickAddMissingMachineId}
              className={classes.addNewMachineIdButton}
            >
              {t("ownerPage.missingAMachine.addMachineButtonLabel")}
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={onDialogClose} color="primary">
              {t("ownerPage.missingAMachine.cancelButtonLabel")}
            </Button>
            <Button onClick={onSendMail} color="primary" disabled={canSendMail}>
              {t("ownerPage.missingAMachine.sendMailButtonLabel")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
