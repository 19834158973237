import { toISO8601DateStringLocal } from "../../utility/DateUtility";
import { IHttpClient } from "../IHttpClient";
import {
  IPerformanceProReportService,
  UpdateReportHours,
} from "./IPerformanceProReportService";

const baseUrl = "v1/PerformanceProReport";

export class PerformanceProReportService
  implements IPerformanceProReportService
{
  private httpclient: IHttpClient;

  constructor(httpclient: IHttpClient) {
    this.httpclient = httpclient;
  }

  getProductionReportView(machineId: MachineId, date: Date): Promise<any> {
    const dateString = toISO8601DateStringLocal(date);
    
    return this.get(
      `${baseUrl}/production-report?machineId=${machineId}&date=${dateString}`
    );
  }

  updateReportHours(reportHours: UpdateReportHours) {
    return this.httpclient.put(`${baseUrl}/production-report`, {
      machineId: reportHours.machineId,
      productionReportHours: reportHours.productionReportHours.map(
        (reportHour) => {
          return {
            dateTimeHour: reportHour.dateTimeHour,
            downTimeComment: reportHour.downTimeComment,
            downTimeInfo1: reportHour.downTimeInfo1,
            downTimeInfo2: reportHour.downTimeInfo2,
            downTimeInfo3: reportHour.downTimeInfo3,
            downTimeInfo4: reportHour.downTimeInfo4,
            downTimeInfo5: reportHour.downTimeInfo5,
            downTimeInfo6: reportHour.downTimeInfo6,
            characeristicInfo4: reportHour.characeristicInfo4,
            characeristicInfo5: reportHour.characeristicInfo5,
            characeristicInfo6: reportHour.characeristicInfo6,
            team: Number(reportHour.team),
          };
        }
      ),
    });
  }

  private put(url: string, body: any): Promise<any> {
    return this.httpclient.put(url, body);
  }

  get(url: string): Promise<any> {
    return this.httpclient.get(url);
  }
}
