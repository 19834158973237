import { ListItem, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  numberedListItem: {
    "& + $header": {
      marginTop: theme.spacing(2),
    },
    display: "block",
  },
  boldParagraph: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
  },
}));

export function BoldNumberedParagraph({ children }: any) {
  const classes = useStyles();
  return (
    <ListItem className={classes.numberedListItem}>
      <Typography
        component={"span"}
        className={classes.boldParagraph}
        variant="body1"
      >
        {children}
      </Typography>
    </ListItem>
  );
}
