import React from "react";
import {
  useMachineFromParamsOrRedirect,
  UsesMachineIdPage,
} from "../globalHooks/machineHooks";

export function PageWithMachineId({
  children,
  page,
}: {
  children: any;
  page: UsesMachineIdPage;
}) {
  var shouldRender = useMachineFromParamsOrRedirect(page);

  return shouldRender ? <>{children}</> : <></>;
}
