import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { AuthTokenGetter } from "../api/AxiosHttpClient";
import { useUserActivityService } from "../api/ServiceContext";
import config from "../config";
import { axiosHttpClient, useUserAction } from "../store/GlobalContext";
import { t } from "../utility/TranslateUtility";

export function useLogout() {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ returnTo: config.REACT_APP_AUTH0_CALLBACK_URL });
    sessionStorage.removeItem("last_login_date");
  }, [logout]);
}

export function useLoginWithRedirectEffect() {
  const { loginWithRedirect, isLoading, user, isAuthenticated } = useAuth0();
  const { updateIsUseLoggedIn } = useUserAction();

  const userActivity = useUserActivityService();

  useEffect(() => {
    (async function login() {
      if (!isLoading && (!user || !isAuthenticated)) {
        await loginWithRedirect({ redirectUri: window.location.href });
      } else {
        updateIsUseLoggedIn(true);
        const hasLastLoginDate = sessionStorage.getItem("last_login_date");
        if (user && hasLastLoginDate === null) {
          const lastLoginDate = new Date();
          userActivity.updateLastLoginDate(lastLoginDate);
          sessionStorage.setItem(
            "last_login_date",
            JSON.stringify(lastLoginDate)
          );
        }
      }
    })();
  }, [isLoading, loginWithRedirect, user]);
}

export function useAccessTokenGetter() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  return async () => {
    try {
      return await getAccessTokenSilently({
        audience: config.REACT_APP_AUTH0_AUDIENCE,
      });
    } catch (e: any) {
      if (e.error === "login_required") {
        alert(t("authentication.loginRequiredErrorMessage"));
        loginWithRedirect();
      }
      if (e.error === "consent_required") {
        alert(t("authentication.loginRequiredErrorMessage"));
        loginWithRedirect();
      }
      throw e;
    }
  };
}

export function useHttpClientPipelineInjectorEffect() {
  const getAccessToken: AuthTokenGetter = useAccessTokenGetter();
  const { isLoading, user, isAuthenticated } = useAuth0();

  useEffect(() => {
    axiosHttpClient.setTokenGetter(getAccessToken);
  }, [isLoading, user, isAuthenticated]);
}
