import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import { t } from "../../../utility/TranslateUtility";
import { OrderType } from "../../CommonUtil/OrderType";
import { StyledTableCell } from "../../UI/Table/Table";
import { DestinationProductDisplay } from "./Common";
import { DestinationProductRow } from "./DestinationProductRow";

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  noDestinationProducts: {
    textAlign: "center",
  },
}));

export default function DestinationProductPresentation({
  destinationProducts,
  order,
  orderBy,
  createSortHandler,
  renderLabels,
}: Props) {
  const classes = useStyles();

  let sortDirection =
    order === "desc" ? "sorted descending" : "sorted ascending";

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {renderLabels.map((label) => (
              <StyledTableCell key={label.key}>
                <StyledTableSortLabel
                  active={orderBy === label.key}
                  direction={orderBy === label.key ? order : "asc"}
                  onClick={createSortHandler(label.key)}
                >
                  {t(label.text)}
                  {orderBy === label.key ? (
                    <span className={classes.visuallyHidden}>
                      {sortDirection}
                    </span>
                  ) : null}
                </StyledTableSortLabel>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {destinationProducts.length === 0 && (
            <TableRow>
              <TableCell colSpan={6} className={classes.noDestinationProducts}>
                {t(
                  "performanceProQuality.supplierDetailPage.destinationProduct.noDestinationProducts"
                )}
              </TableCell>
            </TableRow>
          )}
          {destinationProducts.map((destinationProduct) => (
            <DestinationProductRow
              key={
                destinationProduct.productName +
                destinationProducts.indexOf(destinationProduct) // Prevents unique key to be violated (causing console errors) when productName is '<No product assigned>'.
              }
              destinationProduct={destinationProduct}
              columnsToDisplay={renderLabels.map((x) => x.key)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface Props {
  destinationProducts: DestinationProductDisplay[];
  order: OrderType;
  orderBy: keyof DestinationProductDisplay;
  createSortHandler: (property: keyof DestinationProductDisplay) => void;
  renderLabels: { key: keyof DestinationProductDisplay; text: string }[];
}

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  })
)(TableSortLabel);
