import React from "react";
import { useInformationBarData } from "./hooks";
import { InformationBarPresentation } from "./InformationBarPresentation";

export default function InformationBarContainer() {
  const {
    version,
    lastDataReceived,
    lastDataReceivedFound,
  } = useInformationBarData();

  return (
    <InformationBarPresentation
      lastDataReceived={lastDataReceived}
      version={version}
      noDate={lastDataReceivedFound}
    />
  );
}
