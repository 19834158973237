import { Grid, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DestinationProductContainer from "../../components/PerformancePro/DestinationProduct/DestinationProductContainer";
import PerformanceProGroupedView from "../../components/PerformancePro/Grouped/PerformanceProGroupedView";
import { usePerformanceGroupMeasureForSupplier } from "../../components/PerformancePro/Grouped/performanceProGroupHooks";
import { SupplierDetailLineChartView } from "../../components/PerformancePro/SupplierDetail/SupplierDetailLineChartView";
import { KpiMeasureGroupType } from "../../components/PerformancePro/Types";
import { PageHeaderCard } from "../../components/UI/PageHeaderCard/PageHeaderCard";
import { PerformanceProToolbarContainer } from "./PerformanceProToolbar";

export default function PerformanceProSupplierDetail() {
  const { supplierName, supplierShed } =
    useParams<{
      supplierName: string;
      supplierShed: string;
    }>();

  const decodedSupplierName = decodeURIComponent(supplierName);

  const { t } = useTranslation();
  const classes = useStyles();

  const { isInfeedBased, brownDetectionActive, shellStrengthActive } =
    usePerformanceGroupMeasureForSupplier(decodedSupplierName, supplierShed);

  return (
    <>
      <PerformanceProToolbarContainer
        enableEggTypeSelect={true}
        enableDatePeriod={true}
      />
      <div className={classes.content}>
        <PageHeaderCard
          title={t("performanceProQuality.supplierDetailPage.title", {
            supplierName: decodedSupplierName,
            shedNumber: supplierShed,
          })}
          buttonText={t(
            "performanceProQuality.supplierDetailPage.backToPerformanceProButtonLabel"
          )}
          redirectTo={"/performance-pro/quality/"}
        />
      </div>
      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <PerformanceProGroupedView
                measure={
                  isInfeedBased
                    ? KpiMeasureGroupType.InfeedGradeByDatePeriodForSupplier
                    : KpiMeasureGroupType.TakeawayGradeByDatePeriodForSupplier
                }
                supplierNameShed={decodedSupplierName + " " + supplierShed}
                chartName={"size-distribution-for-supplier"}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
      {shellStrengthActive && (
        <div className={classes.content}>
          <Paper>
            <Grid container>
              <Grid item xs={12} className={classes.gridContent}>
                <SupplierDetailLineChartView
                  measure={
                    KpiMeasureGroupType.ShellStrengthByDatePeriodForSupplier
                  }
                  supplierNameShed={decodedSupplierName + " " + supplierShed}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
      {brownDetectionActive && (
        <div className={classes.content}>
          <Paper>
            <Grid container>
              <Grid item xs={12} className={classes.gridContent}>
                <SupplierDetailLineChartView
                  measure={KpiMeasureGroupType.ColorByDatePeriodForSupplier}
                  supplierNameShed={decodedSupplierName + " " + supplierShed}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <PerformanceProGroupedView
                measure={
                  isInfeedBased
                    ? KpiMeasureGroupType.InfeedOffgradeByDatePeriodForSupplier
                    : KpiMeasureGroupType.TakeawayOffgradeByDatePeriodForSupplier
                }
                supplierNameShed={decodedSupplierName + " " + supplierShed}
                chartName={"offgrade-for-supplier"}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>

      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <DestinationProductContainer
                supplierName={decodedSupplierName}
                supplierShed={supplierShed}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  emptyContent: {
    padding: theme.spacing(3),
    height: "40vh",
  },
  gridContent: {
    paddingTop: 0,
  },
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));
