import { useEffect, useMemo, useState } from "react";
import { usePerformanceProService } from "../../../api/ServiceContext";
import {
  usePerformanceProFilterOptions,
  useSelectedMachine,
} from "../../../store/GlobalContext";
import { getFirstOfPeriod } from "../../../utility/DateUtility";
import { FormatPercentage } from "../../../utility/NumberFormatUtility";
import { t } from "../../../utility/TranslateUtility";
import { OrderType } from "../../CommonUtil/OrderType";
import { ChartCard } from "../../ProductionViews/Common";
import { useDispatchAlert } from "../../UI/Alert";
import { ConvertDatePeriod } from "../performanceProHooks";
import {
  DestinationProduct,
  DestinationProductDisplay,
  determineProductionTime,
  determineProductName,
} from "./Common";
import DestinationProductPresentation from "./DestinationProductPresentation";
import { DestinationProductSearch } from "./DestinationProductSearch";

export default function DestinationProductContainer({
  supplierName,
  supplierShed,
}: DestinationProductContainerArgs) {
  const [destinationProducts, setDestinationProducts] = useState<
    DestinationProductDisplay[]
  >([]);
  const [destinationProductsToDisplay, setDestinationProductsToDisplay] =
    useState<DestinationProductDisplay[]>(destinationProducts);

  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(true);
  const [searchString, setSearchString] = useState("");
  const options = usePerformanceProFilterOptions();
  const selectedMachine = useSelectedMachine();
  const dispatchAlert = useDispatchAlert();
  const performanceProService = usePerformanceProService();
  const [order, setOrder] = useState<OrderType>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof DestinationProductDisplay>("productName");

  const createSortHandler = (property: keyof DestinationProduct) => () => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  useEffect(() => {
    setAwaitingResponse(true);
    const periodType = ConvertDatePeriod(options.selectedDatePeriod);
    const fromDate = getFirstOfPeriod(
      options.selectedDate,
      options.selectedDatePeriod
    );

    performanceProService
      .getDestinationProductForSupplier({
        machineId: selectedMachine.machineId,
        supplierName: supplierName,
        supplierShed,
        fromDate: fromDate,
        periodType,
      })
      .then((response: IApiResponse<DestinationProductResponse>) => {
        const renamedDestinationProduct = response.data.destinationProducts.map(
          (destinationProduct) => {
            return {
              ...destinationProduct,
              productId: destinationProduct.productId,
              productName: determineProductName(destinationProduct.productName),
              productionTime: determineProductionTime(
                destinationProduct.productionTime
              ),
              percentageEggsToProduct: FormatPercentage(
                destinationProduct.percentageEggsToProduct
              ),
              percentageOffgradeContribution: FormatPercentage(
                destinationProduct.percentageOffgradeContribution
              ),
            };
          }
        );

        setDestinationProducts(renamedDestinationProduct);
        setAwaitingResponse(false);
      })
      .catch((reason) => {
        if (!reason.isCancelled) {
          dispatchAlert({
            message: t("generic.errorMessage"),
            messageType: "error",
          });
        }
      });
  }, [
    selectedMachine.machineId,
    supplierName,
    supplierShed,
    options.selectedDate,
    options.selectedDatePeriod,
  ]);

  useEffect(() => {
    createSortHandler(orderBy);
    const sortFunction = getComparator(order, orderBy);
    const destinationProductsToSort = destinationProducts.map((x) => {
      return {
        ...x,
        productName: x.productName,
        numberOfEggsInProduct: x.numberOfEggsInProduct,
        numberOfEggsToProduct: x.numberOfEggsToProduct,
        percentageEggsToProduct: x.percentageEggsToProduct,
        numberOfUpgradedEggsToProduct: x.numberOfUpgradedEggsToProduct,
        percentageOffgradeContribution: x.percentageOffgradeContribution,
        productionTime: x.productionTime,
      };
    });
    setDestinationProductsToDisplay(
      [...destinationProductsToSort].sort(sortFunction)
    );
  }, [destinationProducts, order, orderBy]);

  const rowsToDisplay: Array<keyof DestinationProduct> = [
    "productName",
    "numberOfEggsInProduct",
    "numberOfEggsToProduct",
    "percentageEggsToProduct",
    "numberOfUpgradedEggsToProduct",
    "percentageOffgradeContribution",
    "productionTime",
  ];
  const renderLabels = rowsToDisplay
    ? sortLabels.filter((x) => rowsToDisplay.find((y) => y === x.key))
    : sortLabels;

  useMemo(() => {
    if (searchString.length === 0) {
      setDestinationProductsToDisplay(destinationProducts);
    }

    setDestinationProductsToDisplay(
      destinationProducts.filter(
        (destinationProduct: DestinationProductDisplay) => {
          let values = Object.values(destinationProduct);
          values.push(destinationProduct.productName);
          values.push(destinationProduct.numberOfEggsInProduct);
          values.push(destinationProduct.numberOfEggsToProduct);
          values.push(destinationProduct.numberOfUpgradedEggsToProduct);
          values.push(destinationProduct.percentageEggsToProduct);
          values.push(destinationProduct.percentageOffgradeContribution);
          values.push(destinationProduct.productionTime);

          return values
            .join(" ")
            .toLowerCase()
            .includes(searchString.toLowerCase());
        }
      )
    );
  }, [searchString]);

  return (
    <>
      <ChartCard
        headerTitle={t(
          "performanceProQuality.supplierDetailPage.destinationProduct.title",
          {
            supplierName: supplierName,
            shedNumber: supplierShed,
          }
        )}
        isLoading={awaitingResponse}
      >
        <DestinationProductSearch
          searchString={searchString}
          onChange={setSearchString}
        />

        <DestinationProductPresentation
          destinationProducts={destinationProductsToDisplay}
          order={order}
          orderBy={orderBy}
          createSortHandler={createSortHandler}
          renderLabels={renderLabels}
        />
      </ChartCard>
    </>
  );
}

export interface DestinationProductContainerArgs {
  supplierName: string;
  supplierShed: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator<Key extends keyof any>(
  order: OrderType,
  orderBy: Key
): (
  a: { [key in Key]: number | string | Date },
  b: { [key in Key]: number | string | Date }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export interface DestinationProductResponse {
  destinationProducts: DestinationProduct[];
}

const sortLabels: { key: keyof DestinationProduct; text: string }[] = [
  {
    key: "productName",
    text: "performanceProQuality.supplierDetailPage.destinationProduct.productName",
  },
  {
    key: "numberOfEggsInProduct",
    text: "performanceProQuality.supplierDetailPage.destinationProduct.numberOfEggsInProduct",
  },
  {
    key: "numberOfEggsToProduct",
    text: "performanceProQuality.supplierDetailPage.destinationProduct.numberOfEggsToProduct",
  },
  {
    key: "percentageEggsToProduct",
    text: "performanceProQuality.supplierDetailPage.destinationProduct.percentageEggsToProduct",
  },
  {
    key: "numberOfUpgradedEggsToProduct",
    text: "performanceProQuality.supplierDetailPage.destinationProduct.numberOfUpgradedEggsToProduct",
  },
  {
    key: "percentageOffgradeContribution",
    text: "performanceProQuality.supplierDetailPage.destinationProduct.percentageOffgradeContribution",
  },
  {
    key: "productionTime",
    text: "performanceProQuality.supplierDetailPage.destinationProduct.productionTime",
  },
];
