import { makeStyles, Typography } from "@material-ui/core";
import { ReportSettings } from "../../../pages/management-settings/Common";
import { EggUnit } from "../../../utility/EggUnitUtility";
import { t } from "../../../utility/TranslateUtility";
import { EggCountInput } from "../Targets/Common";

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  errorMessage: {
    margin: theme.spacing(1),
  },
  select: {
    minWidth: theme.spacing(12),
  },
  label: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(20),
    marginLeft: 0,
    minWidth: theme.spacing(20),
  },
  target: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(5),
  },
}));

export function HourlyTargetsPresentation({
  settings,
  onSettingsChange,
  showError,
}: {
  settings: ReportSettings;
  onSettingsChange: (settings: ReportSettings) => void;
  showError: boolean;
}) {
  const classes = useStyles();

  const handleUnitChange = (unit: EggUnit) => {
    onSettingsChange({
      ...settings,
      hourlyTarget: {
        ...settings.hourlyTarget,
        eggUnit: unit,
      },
    });
  };

  const handleTargetChage = (value: number) => {
    onSettingsChange({
      ...settings,
      hourlyTarget: {
        ...settings.hourlyTarget,
        target: value,
      },
    });
  };

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.label}>
        {t("managementSettings.report.hourlyTargets")}
      </Typography>
      <EggCountInput
        value={settings.hourlyTarget.target}
        onChange={handleTargetChage}
        unit={settings.hourlyTarget.eggUnit}
        onUnitChange={handleUnitChange}
        label={t("managementSettings.report.target")}
        timeLabel={t("managementSettings.report.hour")}
        id={"hourly-target"}
        showError={showError}
      />
    </div>
  );
}
