import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { ReportSettings } from "../../../pages/management-settings/Common";
import { t } from "../../../utility/TranslateUtility";
import { SettingsCard } from "../Common";
import { CharacteristicsInfoContainer } from "./CharacteristicsInfo/CharacteristicsInfoContainer";
import ColorSchemaContainer from "./color-schema/ColorSchemasContainer";
import { DowntimeInfoLabelsPresentation } from "./DowntimeInfoLabelsPresentation";
import { HourlyTargetsPresentation } from "./HourlyTargetsPresentation";
import { TeamSizePresentation } from "./TeamSizePresentation";
import { TimeSlotsPresentation } from "./TimeSlotsPresentation";

const useStyles = makeStyles((theme: any) => ({
  contentWrapper: {
    display: "flex",
  },
}));

export type ReportSettingsPresentationProps = {
  settings: ReportSettings;
  onSettingsChange: (settings: ReportSettings) => void;
  showTimeSlotError: boolean;
  showTargetError: boolean;
  showDuplicateColorSchemaError: boolean;
};

export function ReportSettingsPresentation({
  settings,
  onSettingsChange,
  showTimeSlotError,
  showTargetError,
  showDuplicateColorSchemaError,
}: ReportSettingsPresentationProps) {
  const classes = useStyles();

  return (
    <>
      <SettingsCard title={t("managementSettings.report.timeSlots")}>
        <div className={classes.contentWrapper}>
          <TimeSlotsPresentation
            settings={settings}
            onSettingsChange={onSettingsChange}
            showError={showTimeSlotError}
          />
        </div>
      </SettingsCard>
      <SettingsCard title={t("managementSettings.report.hourlyTargets")}>
        <div className={classes.contentWrapper}>
          <HourlyTargetsPresentation
            settings={settings}
            onSettingsChange={onSettingsChange}
            showError={showTargetError}
          />
        </div>
      </SettingsCard>
      <SettingsCard title={t("managementSettings.report.colorSchemaTitle")}>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <ColorSchemaContainer
              settings={settings}
              onSettingsChange={onSettingsChange}
              showDuplicateColorSchemaError={showDuplicateColorSchemaError}
            />
          </Grid>
        </Grid>
      </SettingsCard>
      <SettingsCard
        title={t("managementSettings.report.downtimeInfoCustomLabels")}
      >
        <div className={classes.contentWrapper}>
          <DowntimeInfoLabelsPresentation
            settings={settings}
            onSettingsChange={onSettingsChange}
          />
        </div>
      </SettingsCard>
      <SettingsCard title={t("managementSettings.report.characteristicsTitle")}>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <CharacteristicsInfoContainer
              settings={settings}
              onSettingsChange={onSettingsChange}
            />
          </Grid>
        </Grid>
      </SettingsCard>
      <SettingsCard title={t("managementSettings.report.teamSizeTitle")}>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <TeamSizePresentation
              settings={settings}
              onSettingsChange={onSettingsChange}
            />
          </Grid>
        </Grid>
      </SettingsCard>
    </>
  );
}
