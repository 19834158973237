import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useState } from "react";
import { colors } from "../../../colors";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { t } from "../../../utility/TranslateUtility";

export type ToolbarProps = {
  children: any;
};

/**
 * The toolbar displays various components that provide settings to the current page.
 * When viewed on a mobile device, the toolbar will be rendered as a Floating Action Button (FAB),
 * which opens a new view that contains all the children components.
 *
 * @param children The components that will render inside the Toolbar
 */
function Toolbar({ children }: ToolbarProps) {
  const classes = useStyles();
  const isMobileSize = useIsMobileSize();

  return (
    <>
      {isMobileSize ? (
        <MobileToolbar>{children}</MobileToolbar>
      ) : (
        <div className={classes.root}>
          <div className={classes.wrapper}>
            {children.map((child: any, index: number) => (
              <div key={index} className={classes.child}>
                {child}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

type MobileToolbarProps = {
  children: any;
};

function MobileToolbar({ children }: MobileToolbarProps) {
  const classes = useStyles();
  const [filterViewOpen, setFilterViewOpen] = useState(false);

  const handleFabClick = () => {
    setFilterViewOpen(!filterViewOpen);
  };

  return (
    <>
      {filterViewOpen ? (
        <div className={classes.elevated}>
          <Typography variant="h4" className={classes.elevatedHeader}>
            {t("toolbar.mobileHeader")}
          </Typography>

          <div className={classes.mobileWrapper}>
            {children.map((child: any) => (
              <div className={classes.child}>{child}</div>
            ))}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFabClick}
            className={classes.bottomButton}
          >
            {t("toolbar.mobileDoneButtonLabel")}
          </Button>
        </div>
      ) : (
        <Fab
          variant="extended"
          className={classes.fab}
          onClick={handleFabClick}
        >
          <FilterListIcon className={classes.extendedIcon} />
          {t("toolbar.fabLabel")}
        </Fab>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.grey93,
    padding: theme.spacing(3),
  },
  wrapper: {
    display: "flex",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(2),
    backgroundColor: colors.mobaBlue,
    color: colors.white,
    zIndex: 1,
  },
  bottomButton: {
    position: "fixed",
    bottom: "0px",
    width: "100%",
    borderRadius: 0,
    padding: theme.spacing(2),
  },
  mobileWrapper: {
    padding: theme.spacing(2)
  },
  child: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(4),
    }
  },
  elevated: {
    zIndex: 3,
    backgroundColor: colors.white,
    position: "fixed",
    height: "100%",
    width: "100%",
    overflow: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  elevatedHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(9),
  },
}));

export default Toolbar;
