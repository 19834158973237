import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  minutesInAnHour,
  numberOfEggsInACase,
  numberOfEggsInADozen,
} from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import { SelectFormControl, SelectItem } from "../../UI/Form/Select";
import {
  AlarmCondition,
  AlarmEggsProdUnit,
  AlarmFrequency,
  AlarmStatus,
  AlarmThroughputUnit,
  AlarmTimeUnit,
  AlarmTopic,
} from "../Types";
import { IAlarm, PaperComponent, splitOnCapital } from "./Common";

const useStyles = makeStyles((theme) => ({
  rowItem: {
    borderTop: "1px solid #ddd",
    marginTop: "0.5rem",
    paddingTop: "0.5rem",
  },
  error: {
    color: "#f55",
  },
  fillWidth: {
    width: "100%",
  },
}));

interface SelectGridRowProps {
  label: string;
  value: any;
  options: any[];
  classes: any;
  onValueChange: (value: any) => void;
}

function SelectGridRow({
  label,
  value,
  options,
  classes,
  onValueChange,
}: SelectGridRowProps) {
  return (
    <Grid container className={classes.rowItem}>
      <Grid item md={4} xs={12}>
        <DialogContentText>
          {t(`alarmManagement.labels.${label}`)}
        </DialogContentText>
      </Grid>
      <Grid item md={8} xs={12}>
        <SelectFormControl
          label=""
          value={value}
          onValueChange={(newValue) => onValueChange(newValue)}
        >
          {options}
        </SelectFormControl>
      </Grid>
    </Grid>
  );
}

export interface AddOrUpdateAlarmPresentationProps {
  alarm: IAlarm;
  disableTopic: boolean;
  updateField: (field: string, value: any) => void;
  onSaveChanges: () => void;
  closeDialog: () => void;
}

function calculateLevel(topic: AlarmTopic, levelUnit: number, level: number) {
  switch (topic) {
    case AlarmTopic.TotalEggs:
    case AlarmTopic.Throughput:
      switch (levelUnit) {
        case AlarmEggsProdUnit.Eggs:
          return level;
        case AlarmEggsProdUnit.Dozens:
          return level / numberOfEggsInADozen;
        case AlarmEggsProdUnit.Cases:
          return level / numberOfEggsInACase;
      }
      break;
    case AlarmTopic.MachineConnection:
      switch (levelUnit) {
        case AlarmTimeUnit.Hours:
          return level / minutesInAnHour;
        case AlarmTimeUnit.Minutes:
          return level;
      }
      break;
  }
  return level;
}

function AddOrUpdateAlarmPresentation({
  alarm,
  disableTopic,
  updateField,
  onSaveChanges,
  closeDialog,
}: AddOrUpdateAlarmPresentationProps) {
  const classes = useStyles();

  const [showCondition, setShowCondition] = useState<boolean>(true);
  const [showStartTime, setShowStartTime] = useState<boolean>(true);
  const [showAlarmFrequency, setShowAlarmFrequency] = useState<boolean>(true);

  const [level, setLevel] = useState<number>(
    calculateLevel(alarm.topic, alarm.levelUnit, alarm.level)
  );

  const [alarmTopicOptions, setAlarmTopicOptions] = useState<JSX.Element[]>([]);
  const [alarmLevelUnitOptions, setAlarmLevelUnitOptions] = useState<
    JSX.Element[]
  >([]);
  const [alarmConditionOptions, setAlarmConditionOptions] = useState<
    JSX.Element[]
  >([]);
  const [alarmFrequencyOptions, setAlarmFrequencyOptions] = useState<
    JSX.Element[]
  >([]);

  const update = (field: string, value: any) => {
    updateField(field, value);
  };

  const selectItems = (enums: any[], values: any[]) => {
    return enums
      .filter((e) => !isNaN(Number(e)))
      .map((e: any) => (
        <SelectItem key={e} value={e}>
          {splitOnCapital(values[e])}
        </SelectItem>
      ));
  };

  const validate = () => {
    const errors: any = {};
    if (alarm.level < 0) {
      errors.level = t("alarmManagement.errors.level");
    }
    if (
      alarm.frequency == AlarmFrequency.Hourly &&
      alarm.startTimeUtc == undefined
    ) {
      errors.startTimeUtc = t("settings.user.requiredfield", {
        fieldname: t("alarmManagement.labels.start"),
      });
    }
    return errors;
  };

  const onSubmit = () => {
    onSaveChanges();
  };

  useEffect(() => {
    const topicAsNumber = Number(alarm.topic);
    switch (topicAsNumber) {
      case AlarmTopic.TotalEggs:
        switch (alarm.levelUnit) {
          case AlarmEggsProdUnit.Eggs:
            update("level", level);
            break;
          case AlarmEggsProdUnit.Dozens:
            update("level", level * numberOfEggsInADozen);
            break;
          case AlarmEggsProdUnit.Cases:
            update("level", level * numberOfEggsInACase);
            break;
        }
        break;
      case AlarmTopic.Throughput:
        switch (alarm.levelUnit) {
          case AlarmThroughputUnit.EpH:
            update("level", level);
            break;
          case AlarmThroughputUnit.DpH:
            update("level", level * numberOfEggsInADozen);
            break;
          case AlarmThroughputUnit.CpH:
            update("level", level * numberOfEggsInACase);
            break;
        }
        break;
      case AlarmTopic.MachineConnection:
        switch (alarm.levelUnit) {
          case AlarmTimeUnit.Hours:
            update("level", level * minutesInAnHour);
            break;
          case AlarmTimeUnit.Minutes:
            update("level", level);
            break;
        }
        break;
      case AlarmTopic.ProductionTime:
        update("level", level);
        break;
      default:
        update("level", level);
        break;
    }
  }, [level, alarm.levelUnit]);

  useEffect(() => {
    setAlarmTopicOptions(
      selectItems(Object.keys(AlarmTopic), Object.values(AlarmTopic))
    );
    setAlarmConditionOptions(
      selectItems(Object.keys(AlarmCondition), Object.values(AlarmCondition))
    );
    setAlarmFrequencyOptions(
      selectItems(Object.keys(AlarmFrequency), Object.values(AlarmFrequency))
    );
  }, []);

  useEffect(() => {
    const selectedTopicAsNumber: number = Number(alarm.topic);
    update("levelUnit", alarm.levelUnit);
    if (selectedTopicAsNumber === AlarmTopic.TotalEggs) {
      setAlarmLevelUnitOptions(
        selectItems(
          Object.keys(AlarmEggsProdUnit),
          Object.values(AlarmEggsProdUnit)
        )
      );
    } else if (selectedTopicAsNumber === AlarmTopic.Throughput) {
      setAlarmLevelUnitOptions(
        Object.keys(AlarmThroughputUnit)
          .filter((e) => !isNaN(Number(e)))
          .map((e: any) => (
            <SelectItem key={e} value={e}>
              {AlarmThroughputUnit[e]}
            </SelectItem>
          ))
      );
    } else if (selectedTopicAsNumber === AlarmTopic.ProductionTime) {
      const selectedAlarmFrequencyAsNumber = Number(alarm.frequency);
      if (selectedAlarmFrequencyAsNumber === AlarmFrequency.Hourly) {
        setAlarmLevelUnitOptions([
          <SelectItem key={0} value={AlarmTimeUnit.Minutes.valueOf()}>
            {AlarmTimeUnit[AlarmTimeUnit.Minutes]}
          </SelectItem>,
        ]);
      } else {
        setAlarmLevelUnitOptions(
          selectItems(Object.keys(AlarmTimeUnit), Object.values(AlarmTimeUnit))
        );
      }
    } else if (selectedTopicAsNumber === AlarmTopic.MachineConnection) {
      setAlarmLevelUnitOptions(
        selectItems(Object.keys(AlarmTimeUnit), Object.values(AlarmTimeUnit))
      );
      update("frequency", AlarmFrequency.Hourly);
      setShowCondition(false);
      setShowAlarmFrequency(false);
    } else {
      setAlarmLevelUnitOptions([
        <SelectItem key={0} value={0}>
          %
        </SelectItem>,
      ]);
      setShowCondition(true);
      setShowAlarmFrequency(true);
      update("levelUnit", 0);
    }
  }, [alarm.topic, alarm.frequency]);

  useEffect(() => {
    const selectedAlarmFrequencyAsNumber = Number(alarm.frequency);
    if (selectedAlarmFrequencyAsNumber === AlarmFrequency.Hourly) {
      setShowStartTime(true);
    } else {
      setShowStartTime(false);
      update("startTimeUtc", null);
    }
  }, [alarm.frequency]);

  return (
    <>
      <Dialog
        open={true}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {t("alarmManagement.titles.addedit")}
        </DialogTitle>

        <Formik
          initialValues={{ ...alarm }}
          validate={validate}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ errors }) => (
            <Form>
              <DialogContent>
                <Grid container className={classes.rowItem}>
                  <Grid item md={4} xs={12}>
                    <DialogContentText>
                      {t("alarmManagement.labels.topic")}
                    </DialogContentText>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <SelectFormControl
                      label=""
                      value={alarm.topic}
                      isDisabled={disableTopic}
                      onValueChange={(value) =>
                        update("topic", disableTopic ? alarm.topic : value)
                      }
                    >
                      {alarmTopicOptions}
                    </SelectFormControl>
                  </Grid>
                </Grid>

                <Grid container className={classes.rowItem}>
                  <Grid item md={4} xs={12}>
                    <DialogContentText>
                      {t("alarmManagement.labels.level")}
                    </DialogContentText>
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      value={level}
                      name="level"
                      type="number"
                      inputProps={{ step: "0.1" }}
                      onChange={(ev) => setLevel(Number(ev.target.value))}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Select
                      label=""
                      value={alarm.levelUnit}
                      onChange={(ev) =>
                        update("levelUnit", Number(ev.target.value))
                      }
                    >
                      {alarmLevelUnitOptions}
                    </Select>
                  </Grid>
                  {errors.level !== undefined && (
                    <Grid item md={12}>
                      <Typography className={classes.error}>
                        {errors.level}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                {showCondition && (
                  <SelectGridRow
                    label="condition"
                    value={alarm.condition}
                    onValueChange={(val) => update("condition", val)}
                    options={alarmConditionOptions}
                    classes={classes}
                  />
                )}

                {showAlarmFrequency && (
                  <SelectGridRow
                    label="frequency"
                    value={alarm.frequency}
                    onValueChange={(val) => update("frequency", val)}
                    options={alarmFrequencyOptions}
                    classes={classes}
                  />
                )}

                <Grid container className={classes.rowItem}>
                  <Grid item md={4} xs={12}>
                    <DialogContentText>
                      {t("alarmManagement.labels.time")}
                    </DialogContentText>
                  </Grid>
                  {showStartTime && (
                    <Grid item md={4} xs={12}>
                      <KeyboardTimePicker
                        mask="__:__ _M"
                        value={alarm.startTimeUtc}
                        ampm={false}
                        placeholder="08:00"
                        className={classes.fillWidth}
                        label={t("alarmManagement.labels.start")}
                        onChange={(date) =>
                          update("startTimeUtc", date || new Date())
                        }
                      />
                      {errors.startTimeUtc !== undefined && (
                        <Grid item md={12}>
                          <Typography className={classes.error}>
                            {errors.startTimeUtc}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <Grid item md={4} xs={12}>
                    <KeyboardTimePicker
                      mask="__:__ _M"
                      value={alarm.endTimeUtc}
                      ampm={false}
                      placeholder="08:00"
                      className={classes.fillWidth}
                      label={t("alarmManagement.labels.end")}
                      onChange={(date) =>
                        update("endTimeUtc", date || new Date())
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.rowItem}>
                  <Grid item md={4} xs={12}>
                    <DialogContentText>
                      {t("alarmManagement.labels.active")}
                    </DialogContentText>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <FormControlLabel
                      label=""
                      control={
                        <Switch
                          type="checkbox"
                          name="modules"
                          checked={alarm.status == AlarmStatus.Active}
                          color="primary"
                          onChange={() =>
                            update(
                              "status",
                              alarm.status == AlarmStatus.Active
                                ? AlarmStatus.Passive
                                : AlarmStatus.Active
                            )
                          }
                        />
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item md={12} xs={12}>
                    <DialogActions>
                      <Button onClick={() => closeDialog()}>
                        {t("alarmManagement.cancel")}
                      </Button>
                      <Button type="submit">{t("alarmManagement.save")}</Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default AddOrUpdateAlarmPresentation;
