import React, { useMemo } from "react";
import {
  useBenchmarkActions,
  useBenchmarkState,
} from "../../../../../store/GlobalContext";
import { t } from "../../../../../utility/TranslateUtility";
import { useMyMachines } from "../../machineMeasureComparisonHooks";
import { MachineIdFilter } from "../../Types";
import { HeaderWithButton } from "../MachineMeasureComparisonFilters";
import MachineMeasureMyMachinesSelect from "./MachineMeasureMyMachinesSelect";

function OnlyAverageOfMyMachinesSelected(array: Array<any>): boolean {
  return array.includes("AVG") && array.length === 1;
}
export default function MyMachinesSection() {
  const state = useBenchmarkState();
  const { updateSelectedMyMachines } = useBenchmarkActions();
  const { myMachines } = useMyMachines();

  const isEmptySelect = useMemo(() => {
    return OnlyAverageOfMyMachinesSelected(state.editingFilterState.myMachines);
  }, [state.editingFilterState.myMachines]);

  const handleClick = () => {
    if (isEmptySelect) {
      updateSelectedMyMachines(myMachines);
      return;
    }

    updateSelectedMyMachines(["AVG"]);
  };

  const handleMachinesSelectionChange = (
    newMachines: Array<MachineIdFilter>
  ) => {
    updateSelectedMyMachines(newMachines);
  };

  return (
    <MyMachinesSectionPresentation
      onClick={handleClick}
      allMyMachines={myMachines}
      selectedMyMachines={state.editingFilterState.myMachines}
      isEmptySelect={isEmptySelect}
      onMachinesSelectionChange={handleMachinesSelectionChange}
    />
  );
}

function MyMachinesSectionPresentation({
  onClick,
  allMyMachines,
  selectedMyMachines,
  isEmptySelect,
  onMachinesSelectionChange,
}: {
  onClick: () => void;
  allMyMachines: Array<MachineIdFilter>;
  selectedMyMachines: Array<MachineIdFilter>;
  isEmptySelect: boolean;
  onMachinesSelectionChange: (newMachines: Array<MachineIdFilter>) => void;
}) {
  return (
    <>
      <HeaderWithButton
        title={t("benchmark.myMachines")}
        buttonLabel={
          isEmptySelect
            ? t("benchmark.myMachinesSelectAll")
            : t("benchmark.myMachinesDeselectAll")
        }
        onClick={onClick}
      />
      <MachineMeasureMyMachinesSelect
        allMyMachines={allMyMachines}
        selectedMyMachines={selectedMyMachines}
        onMachinesSelectionChange={onMachinesSelectionChange}
      />
    </>
  );
}
