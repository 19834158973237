import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { t } from "../../../utility/TranslateUtility";
import {
  maxEmailLength,
  TextField,
} from "../AddOrUpdateUser/AddOrUpdateUserPresentation";
import { PaperComponent } from "../AddOrUpdateUser/Common";
import { AssignAdminFormFields } from "./AssignAdminContainer";

export interface AssignAdminDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  handleAssign: (assignAdminFields: AssignAdminFormFields) => void;
}

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1),
    marginLeft: 0,
    marginRight: theme.spacing(10),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
}));

export function AssignAdminDialog({
  isOpen,
  handleClose,
  handleAssign,
}: AssignAdminDialogProps) {
  const classes = useStyles();
  const [asssingAdminFields] = useState<AssignAdminFormFields>({ email: "" });
  const validate = (values: any): any => {
    const errors: any = {};
    if (!values.email) {
      errors.email = t("forms.isRequired");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = t("forms.emailInvalid");
    } else if (values.email.length > maxEmailLength) {
      errors.email = t("forms.emailTooLong");
    }

    return errors;
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {t("users.titles.assignAdmin")}
        </DialogTitle>

        <Formik
          initialValues={asssingAdminFields}
          onSubmit={handleAssign}
          validate={validate}
          enableReinitialize={true}
        >
          {({ isValid, isSubmitting, dirty }) => (
            <Form>
              <DialogContent className={classes.dialogContent}>
                <TextField
                  label={t("users.labels.email")}
                  name="email"
                  type="email"
                  className={classes.textField}
                  variant="filled"
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                  {t("users.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={!isValid || !dirty}
                >
                  {t("users.buttons.assign")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
