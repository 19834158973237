import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export default function ErrorPage() {
  const appInsights = useAppInsightsContext();
  appInsights.trackEvent({ name: "Displayed Error Page" });

  return (
    <p>
      Sorry for the inconvenience, iMoba has crashed. Please navigate back or
      contact Customer Care.
    </p>
  );
}
