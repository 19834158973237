import {
  Button,
  Chip,
  IconButton,
  makeStyles,
  TableCell,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import React, { useMemo } from "react";
import { colors } from "../../../colors";
import { t } from "../../../utility/TranslateUtility";
import { StyledTableRow } from "../../UI/Table/Table";
import {
  iMobaModule,
  iMobaUser,
  Role,
  Status,
} from "../AddOrUpdateUser/Common";
import { TableActions, UserTableData } from "./UserListPresentation";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1),
  },
  statusChip: {
    margin: theme.spacing(1),
    color: "white",
  },
}));

function determineColor(status: Status): string {
  switch (status) {
    case "Pending":
      return colors.pendingChip;
    case "Active":
      return colors.activeChip;
    case "Unknown":
      return colors.grey40;
  }
}

export interface UserRowProps {
  user: iMobaUser;
  onEdit: (user?: iMobaUser) => void;
  onDelete: (user: iMobaUser) => void;
  onReinvite: (user: iMobaUser) => void;
  onAssingModule: (user: iMobaUser, text?: string) => void;
  afterUpsertUser: () => void;
  columnsToDisplay: Array<keyof UserTableData>;
  actionsToDisplay: Array<TableActions>;
  selectedModule: iMobaModule;
  disableActions?: boolean;
}

export function UserRow({
  user,
  onEdit,
  onDelete,
  onReinvite,
  afterUpsertUser,
  onAssingModule,
  columnsToDisplay,
  actionsToDisplay,
  selectedModule,
  disableActions,
}: UserRowProps) {
  const showEditIcon = user.roles?.every((x) => x === "UserCustomerApp");

  const tableHtml = useMemo(() => {
    let result = [];

    for (const column of columnsToDisplay) {
      switch (column) {
        case "name":
          result.push(
            <NameCell
              key={column}
              firstName={user.firstName}
              lastName={user.lastName}
            />
          );
          break;
        case "email":
          result.push(<EmailCell key={column} email={user.email} />);
          break;
        case "phoneNumber":
          result.push(
            <PhoneNumberCell key={column} phoneNumber={user.phoneNumber} />
          );
          break;
        case "firstRole":
          result.push(
            <FirstRoleCell
              key={column}
              roles={user.roles}
            />
          );
          break;
        case "status":
          result.push(<StatusCell key={column} status={user.status} />);
          break;
        default:
          break;
      }
    }

    result.push(
      <ActionsCell
        key={"actions"}
        selectedModule={selectedModule}
        actionsToDisplay={actionsToDisplay}
        user={user}
        showEditIcon={showEditIcon}
        onEdit={onEdit}
        onDelete={onDelete}
        onReinvite={onReinvite}
        onAssingModule={onAssingModule}
        disableActions={disableActions}
      />
    );

    return result;
  }, [
    actionsToDisplay,
    columnsToDisplay,
    onEdit,
    showEditIcon,
    user,
  ]);

  return <StyledTableRow>{tableHtml}</StyledTableRow>;
}

function NameCell({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) {
  return (
    <TableCell component="th" scope="row">
      {`${firstName} ${lastName}`}
    </TableCell>
  );
}

function EmailCell({ email }: { email: string }) {
  return <TableCell align="left">{email}</TableCell>;
}

function PhoneNumberCell({ phoneNumber }: { phoneNumber: string }) {
  return <TableCell align="left">{phoneNumber}</TableCell>;
}

function FirstRoleCell({
  roles,
}: {
  roles?: Role[];
  showDeleteUserRole?: boolean;
}) {
  const classes = useStyles();

  return (
    <TableCell align="left">
      {roles?.map((role) => (
        <Chip
          label={t(`users.roles.${role}`)}
          className={classes.chip}
          key={role}
        />
      ))}
    </TableCell>
  );
}

function StatusCell({ status }: { status?: Status }) {
  const classes = useStyles();

  return (
    <TableCell align="left">
      <Chip
        label={t(`users.status.${status}`)}
        className={classes.statusChip}
        style={{
          backgroundColor: determineColor(status!),
        }}
      />
    </TableCell>
  );
}

function ActionsCell({
  actionsToDisplay,
  user,
  showEditIcon,
  onEdit,
  onDelete,
  onReinvite,
  onAssingModule,
  selectedModule,
  disableActions,
}: {
  actionsToDisplay: Array<TableActions>;
  user: iMobaUser;
  showEditIcon?: boolean;
  onEdit: (user: iMobaUser) => void;
  onDelete: (user: iMobaUser) => void;
  onReinvite: (user: iMobaUser) => void;
  onAssingModule: (user: iMobaUser, text?: string) => void;
  selectedModule: iMobaModule;
  disableActions?: boolean;
}) {
  return (
    <TableCell align="left">
      {actionsToDisplay.find((x) => x === "edit") && (
        <IconButton
          color="primary"
          onClick={() => onEdit(user)}
          disabled={!showEditIcon || disableActions}
        >
          <EditIcon />
        </IconButton>
      )}
      {actionsToDisplay.find((x) => x === "reinvite") &&
        user.status &&
        user.status === "Pending" && (
          <IconButton
            color="primary"
            onClick={() => {
              onReinvite(user);
            }}
          >
            <SendIcon />
          </IconButton>
        )}
      {/* TODO: Disabled until delete user is implemented in #323 */}
      {actionsToDisplay.find((x) => x === "delete") && (
        <IconButton
          color="primary"
          onClick={() => {
            onDelete(user);
          }}
          disabled={disableActions}
        >
          <DeleteIcon />
        </IconButton>
      )}
      {actionsToDisplay.find((x) => x === "assignModule") && (
        <>
          {user.modules.includes(selectedModule) ? (
            <Button
              variant={"contained"}
              color={"secondary"}
              onClick={() =>
                onAssingModule(
                  {
                    ...user,
                    modules: user.modules.filter((x) => x !== selectedModule),
                  },
                  t("users.messages.confirmRemoveModule", {
                    user: user.email,
                    module: t(`users.modules.${selectedModule}`),
                  })
                )
              }
              disabled={disableActions}
            >
              <DeleteIcon />
              {t("users.buttons.removeModule")}
            </Button>
          ) : (
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() =>
                onAssingModule(
                  {
                    ...user,
                    modules: [...user.modules, selectedModule],
                  },
                  t("users.messages.confirmAssignModule", {
                    user: user.email,
                    module: t(`users.modules.${selectedModule}`),
                  })
                )
              }
              disabled={disableActions}
            >
              <AddIcon />
              {t("users.buttons.assignModule")}
            </Button>
          )}
        </>
      )}
    </TableCell>
  );
}
