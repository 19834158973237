import { Button, Grid, makeStyles } from "@material-ui/core";
import NotificationIcon from "@material-ui/icons/Notifications";
import NotificationOffIcon from "@material-ui/icons/NotificationsOff";
import { useEffect, useMemo, useState } from "react";
import { IPerformanceProFilterOptions } from "../../../store/GlobalContext";
import { EggUnit } from "../../../utility/EggUnitUtility";
import {
  numberOfEggsInACase,
  numberOfEggsInADozen,
} from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import { IAlarm } from "../../Alarms/AddOrUpdateAlarm/Common";
import { AlarmCondition, AlarmStatus, AlarmTopic } from "../../Alarms/Types";
import { ChartCard } from "../../ProductionViews/Common";
import { TargetsView } from "../../Settings/Targets/Types";
import BarChartDisplayTarget, { HasTargets } from "../BarChartDisplayTarget";
import BarChartDisplayUnit, { HasUnits } from "../BarChartDisplayUnits";
import { ChartMapping } from "../ChartMapping";
import PeriodViewBarChart from "../PeriodViewBarChart";
import {
  PerformancePeriod,
  PerformancePeriodSummation,
  ValueType,
} from "../Types";
import { PerformanceProChartTitle } from "./PerformanceProChartTitle";

interface Props {
  chartMapping: ChartMapping;
  options: IPerformanceProFilterOptions;
  summation: PerformancePeriodSummation;
  periods: PerformancePeriod[] | never[];
  valueType: ValueType;
  targets: TargetsView | null;
  hasAlarmSet: boolean;
  showAlarm: boolean;
  alarm: IAlarm | undefined;
  onShowAlarmPopupClick: (showDialog: boolean) => void;
  awaitingResponse: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  targetsNodeContainer: {
    paddingBottom: theme.spacing(2),
  },
  floatRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export function PerformanceProViewPresentation({
  chartMapping,
  options,
  summation,
  periods,
  valueType,
  targets,
  hasAlarmSet,
  showAlarm,
  alarm,
  onShowAlarmPopupClick,
  awaitingResponse,
}: Props) {
  const classes = useStyles();
  const [alarmData, setAlarmData] = useState<any>();
  const chartEggUnit = chartMapping.useEggUnit ? getEggUnit(targets) : null;
  const targetEggUnit = getEggUnit(targets);

  const targetsNode = useMemo(() => {
    return HasTargets(chartMapping.performanceProChart, options, targets) ? (
      <BarChartDisplayTarget
        measure={chartMapping.performanceProChart}
        targets={targets!}
        selectedEggType={options.selectedEggType}
      />
    ) : undefined;
  }, [chartMapping.performanceProChart, options, targets]);

  const unitsNode = useMemo(() => {
    return HasUnits(chartMapping.performanceProChart, options) ? (
      <BarChartDisplayUnit
        summation={summation.numerator!}
        eggUnit={targetEggUnit}
      />
    ) : undefined;
  }, [chartMapping.performanceProChart, chartEggUnit, options, summation]);

  useEffect(() => {
    if (
      showAlarm &&
      (alarm?.status.toString() === AlarmStatus[AlarmStatus.Active] ||
        Number(alarm?.status) === 0)
    ) {
      const data = periods
        .filter((p) => p !== undefined)
        .map((p: any) => ({
          x: p.dateTime,
          y: calculateAlarmLevel(
            alarm?.topic,
            alarm?.level || 0,
            targets?.units.eggUnit || EggUnit.Eggs
          ),
        }));
      setAlarmData([
        {
          type: "area",
          data: data,
          fillColor: "#5c9cd533",
          threshold:
            alarm?.condition.toString() ===
              AlarmCondition[AlarmCondition.Above] ||
            Number(alarm?.condition) === 1
              ? Infinity
              : 0,
          zIndex: -2,
          marker: {
            enabled: false,
          },
          ...additionalChartProps,
        },
        {
          type: "line",
          name: "alarm threshhold",
          data: data,
          dashStyle: "longdash",
          color: "#5c9cd5",
          lineWidth: 5,
          zIndex: 2,
          marker: {
            enabled: false,
          },
          ...additionalChartProps,
        },
      ]);
    } else {
      setAlarmData(undefined);
    }
  }, [alarm, showAlarm, hasAlarmSet, periods]);

  return (
    <ChartCard
      headerTitle={t(chartMapping.headerTitle)}
      isLoading={awaitingResponse}
    >
      {periods && (
        <>
          <Grid container>
            <Grid item xs={10}>
              <PerformanceProChartTitle
                summation={summation.value}
                valueType={valueType}
                eggUnit={targetEggUnit}
              />
              {targetsNode && (
                <div className={classes.targetsNodeContainer}>
                  {targetsNode}
                </div>
              )}
              {unitsNode && (
                <div className={classes.targetsNodeContainer}>{unitsNode}</div>
              )}
            </Grid>

            <Grid item xs={2} className={classes.floatRight}>
              <div>
                <Button onClick={() => onShowAlarmPopupClick(true)}>
                  {hasAlarmSet &&
                    (alarm?.status === AlarmStatus.Active ||
                      alarm?.status.toString() === "Active") && (
                      <NotificationIcon />
                    )}
                  {(!hasAlarmSet ||
                    alarm?.status.toString() === "Passive" ||
                    alarm?.status === AlarmStatus.Passive) && (
                    <NotificationOffIcon />
                  )}
                </Button>
              </div>
            </Grid>
          </Grid>

          <PeriodViewBarChart
            periods={periods}
            options={options}
            chartMapping={chartMapping}
            eggUnit={chartEggUnit}
            additionalData={alarmData}
          />
        </>
      )}
    </ChartCard>
  );
}

function getEggUnit(targets: TargetsView | null): EggUnit | null {
  return targets?.units.eggUnit ?? null;
}

const additionalChartProps = {
  enableMouseTracking: false,
  states: {
    inactive: {
      opacity: 1,
    },
  },
};

function calculateAlarmLevel(
  topic: any,
  level: number,
  eggUnit: string
): number {
  switch (topic) {
    case "TotalEggs":
    case "Throughput":
    case AlarmTopic.TotalEggs:
    case AlarmTopic.Throughput:
      switch (eggUnit) {
        case EggUnit.Dozens:
          return level / numberOfEggsInADozen;
        case EggUnit.Cases:
          return level / numberOfEggsInACase;
      }
      break;
  }
  return level;
}
