import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
  withStyles,
} from "@material-ui/core";
import { addHours } from "date-fns";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ColorSchema } from "../../../pages/management-settings/Common";
import { usePerformanceProFilterOptions } from "../../../store/GlobalContext";
import { EggUnit } from "../../../utility/EggUnitUtility";
import { FormatEggs } from "../../../utility/EggUtility";
import {
  FormatSecondsToMinutesAndSeconds,
  FormatWithTwoDecimals,
} from "../../../utility/NumberFormatUtility";
import {
  TableCancelButton,
  TableExportButton,
  TableSaveButton,
} from "../../UI/Table/Table";
import { ProductionReportHourInfo, ReportView } from "./Common";

interface ReportPresentationProps {
  reportView: ReportView | undefined;
  labels: any;
  productionReportHoursList: ProductionReportHourInfo[] | undefined;
  hasPendingChanges: boolean;
  onSaveClick: Function;
  onResetClick: Function;
  onExportClick: Function;
  onChange: Function;
  unitType: EggUnit;
}

const BorderedTableCell = withStyles({
  root: {
    border: "1px solid lightgrey",
  },
})(TableCell);

const useStyles = makeStyles({
  header: {
    marginBottom: "10px",
  },
  table: {
    minWidth: 650,
  },
  noData: {
    textAlign: "center",
  },
});

export function ReportPerHourPresentation({
  reportView,
  labels,
  productionReportHoursList,
  hasPendingChanges,
  onSaveClick,
  onResetClick,
  onExportClick,
  onChange,
  unitType,
}: ReportPresentationProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const options = usePerformanceProFilterOptions();

  return (
    <>
      <Grid container className={classes.header}>
        <Grid item xs={12} sm={3}>
          <Typography style={{ flexGrow: 1 }} variant="h5">
            {t("performanceProReportPage.perHourHeader")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} container justify="flex-end" spacing={1}>
          {!isMobile && (
            <Grid item>
              <TableExportButton
                text={t("table.export")}
                onClick={() => onExportClick()}
                pendingChanges={true}
              ></TableExportButton>
            </Grid>
          )}
          <Grid item>
            <TableCancelButton
              text={t("table.cancel")}
              onCancel={() => onResetClick()}
              pendingChanges={hasPendingChanges}
            ></TableCancelButton>
          </Grid>
          <Grid item>
            <TableSaveButton
              text={t("table.save")}
              onSave={() => onSaveClick()}
              pendingChanges={hasPendingChanges}
            ></TableSaveButton>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow key={"topHeader"}>
              <BorderedTableCell colSpan={2} align="center">
                Time
              </BorderedTableCell>
              <BorderedTableCell colSpan={2} align="center">
                Hour
              </BorderedTableCell>
              <BorderedTableCell colSpan={2} align="center">
                Day
              </BorderedTableCell>
              <BorderedTableCell
                colSpan={labels.downTimeHeaderColSpan}
                align="center"
              >
                Downtime
              </BorderedTableCell>
              {labels.characteristicsInfoHeaderColSpan !== 0 && (
                <BorderedTableCell
                  colSpan={labels.characteristicsInfoHeaderColSpan}
                  align="center"
                >
                  Characteristics Info
                </BorderedTableCell>
              )}
              {reportView?.enableTeamSizeDataEntry && (
                <BorderedTableCell colSpan={2} align="center">
                  Team
                </BorderedTableCell>
              )}
            </TableRow>
            <TableRow key={"header"}>
              <BorderedTableCell>
                {t("performanceProReportPage.perHourFields.from")}
              </BorderedTableCell>
              <BorderedTableCell>
                {t("performanceProReportPage.perHourFields.until")}
              </BorderedTableCell>
              <BorderedTableCell>
                {t(
                  `performanceProReportPage.perHourFields.achieved${unitType}`
                )}
              </BorderedTableCell>
              <BorderedTableCell>
                {t("performanceProReportPage.perHourFields.percentageVsTarget")}
              </BorderedTableCell>
              <BorderedTableCell>
                {t(
                  `performanceProReportPage.perHourFields.accumulated${unitType}`
                )}
              </BorderedTableCell>
              <BorderedTableCell>
                {t("performanceProReportPage.perHourFields.percentageVsTarget")}
              </BorderedTableCell>
              {labels.downtimeInfoLabel1 &&
                labels.downtimeInfoLabel1.name !== "" && (
                  <BorderedTableCell>
                    {labels.downtimeInfoLabel1.name}
                  </BorderedTableCell>
                )}
              {labels.downtimeInfoLabel2 &&
                labels.downtimeInfoLabel2.name !== "" && (
                  <BorderedTableCell>
                    {labels.downtimeInfoLabel2.name}
                  </BorderedTableCell>
                )}
              {labels.downtimeInfoLabel3 &&
                labels.downtimeInfoLabel3.name !== "" && (
                  <BorderedTableCell>
                    {labels.downtimeInfoLabel3.name}
                  </BorderedTableCell>
                )}
              {labels.downtimeInfoLabel4 &&
                labels.downtimeInfoLabel4.name !== "" && (
                  <BorderedTableCell>
                    {labels.downtimeInfoLabel4.name}
                  </BorderedTableCell>
                )}
              {labels.downtimeInfoLabel5 &&
                labels.downtimeInfoLabel5.name !== "" && (
                  <BorderedTableCell>
                    {labels.downtimeInfoLabel5.name}
                  </BorderedTableCell>
                )}
              {labels.downtimeInfoLabel6 &&
                labels.downtimeInfoLabel6.name !== "" && (
                  <BorderedTableCell>
                    {labels.downtimeInfoLabel6.name}
                  </BorderedTableCell>
                )}
              <BorderedTableCell>
                {t("performanceProReportPage.perHourFields.downtime")}
              </BorderedTableCell>
              <BorderedTableCell>
                {t("performanceProReportPage.perHourFields.comments")}
              </BorderedTableCell>
              {labels.characteristicsInfoLabel1 &&
                labels.characteristicsInfoLabel1.label !== "None" && (
                  <BorderedTableCell>
                    {getCharacteristicsInfoTableHeader(
                      labels.characteristicsInfoLabel1.label,
                      t
                    )}
                  </BorderedTableCell>
                )}
              {labels.characteristicsInfoLabel2 &&
                labels.characteristicsInfoLabel2.label !== "None" && (
                  <BorderedTableCell>
                    {getCharacteristicsInfoTableHeader(
                      labels.characteristicsInfoLabel2.label,
                      t
                    )}
                  </BorderedTableCell>
                )}
              {labels.characteristicsInfoLabel3 &&
                labels.characteristicsInfoLabel3.label !== "None" && (
                  <BorderedTableCell>
                    {getCharacteristicsInfoTableHeader(
                      labels.characteristicsInfoLabel3.label,
                      t
                    )}
                  </BorderedTableCell>
                )}
              {labels.characteristicsInfoLabel4 &&
                labels.characteristicsInfoLabel4.label !== "" && (
                  <BorderedTableCell>
                    {labels.characteristicsInfoLabel4.label}
                  </BorderedTableCell>
                )}
              {labels.characteristicsInfoLabel5 &&
                labels.characteristicsInfoLabel5.label !== "" && (
                  <BorderedTableCell>
                    {labels.characteristicsInfoLabel5.label}
                  </BorderedTableCell>
                )}
              {labels.characteristicsInfoLabel6 &&
                labels.characteristicsInfoLabel6.label !== "" && (
                  <BorderedTableCell>
                    {labels.characteristicsInfoLabel6.label}
                  </BorderedTableCell>
                )}
              {reportView?.enableTeamSizeDataEntry && (
                <BorderedTableCell>
                  {t("performanceProReportPage.perHourFields.team")}
                </BorderedTableCell>
              )}
              {reportView?.enableTeamSizeDataEntry && (
                <BorderedTableCell>
                  {t("performanceProReportPage.perHourFields.teamThroughput")}
                </BorderedTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {productionReportHoursList?.length === 0 && (
              <TableRow>
                <BorderedTableCell colSpan={10} className={classes.noData}>
                  {t("performanceProReportPage.messages.noRows")}
                </BorderedTableCell>
              </TableRow>
            )}
            {reportView &&
              productionReportHoursList?.map((hourInfo, i) => (
                <TableRow key={hourInfo.dateTimeHour.toString()}>
                  <BorderedTableCell>
                    {getHoursAndMinutesFromDate(hourInfo.dateTimeHour)}
                  </BorderedTableCell>
                  <BorderedTableCell>
                    {getHoursAndMinutesFromDate(
                      addHours(hourInfo.dateTimeHour, 1)
                    )}
                  </BorderedTableCell>
                  {getColoredEggsAchievedCell(
                    hourInfo.eggsAchieved,
                    reportView?.colorSchemas,
                    unitType
                  )}
                  <BorderedTableCell>
                    {`${hourInfo.percentageVsTarget.toFixed(1)}%`}
                  </BorderedTableCell>
                  <BorderedTableCell>
                    {FormatEggs(hourInfo.accumelatedEggsAchieved, unitType)}
                  </BorderedTableCell>
                  {getColoredAccumulatedPercentageVsTargetCell(
                    hourInfo,
                    i,
                    reportView
                  )}
                  {labels.downtimeInfoLabel1 &&
                    labels.downtimeInfoLabel1.name !== "" &&
                    editableCell(
                      hourInfo.downTimeInfo1,
                      "downTimeInfo1",
                      i,
                      options.selectedDate,
                      onChange
                    )}
                  {labels.downtimeInfoLabel2 &&
                    labels.downtimeInfoLabel2.name !== "" &&
                    editableCell(
                      hourInfo.downTimeInfo2,
                      "downTimeInfo2",
                      i,
                      options.selectedDate,
                      onChange
                    )}
                  {labels.downtimeInfoLabel3 &&
                    labels.downtimeInfoLabel3.name !== "" &&
                    editableCell(
                      hourInfo.downTimeInfo3,
                      "downTimeInfo3",
                      i,
                      options.selectedDate,
                      onChange
                    )}
                  {labels.downtimeInfoLabel4 &&
                    labels.downtimeInfoLabel4.name !== "" &&
                    editableCell(
                      hourInfo.downTimeInfo4,
                      "downTimeInfo4",
                      i,
                      options.selectedDate,
                      onChange
                    )}
                  {labels.downtimeInfoLabel5 &&
                    labels.downtimeInfoLabel5.name !== "" &&
                    editableCell(
                      hourInfo.downTimeInfo5,
                      "downTimeInfo5",
                      i,
                      options.selectedDate,
                      onChange
                    )}
                  {labels.downtimeInfoLabel6 &&
                    labels.downtimeInfoLabel6.name !== "" &&
                    editableCell(
                      hourInfo.downTimeInfo6,
                      "downTimeInfo6",
                      i,
                      options.selectedDate,
                      onChange
                    )}
                  <BorderedTableCell>
                    {FormatSecondsToMinutesAndSeconds(hourInfo.downTimeSeconds)}
                  </BorderedTableCell>
                  {editableCell(
                    hourInfo.downTimeComment,
                    "downTimeComment",
                    i,
                    options.selectedDate,
                    onChange,
                    "commentInput"
                  )}
                  {labels.characteristicsInfoLabel1 &&
                    labels.characteristicsInfoLabel1.label !== "None" &&
                    getCharacteristicsInfoTableCell(
                      labels.characteristicsInfoLabel1.label,
                      hourInfo
                    )}
                  {labels.characteristicsInfoLabel2 &&
                    labels.characteristicsInfoLabel2.label !== "None" &&
                    getCharacteristicsInfoTableCell(
                      labels.characteristicsInfoLabel2.label,
                      hourInfo
                    )}
                  {labels.characteristicsInfoLabel3 &&
                    labels.characteristicsInfoLabel3.label !== "None" &&
                    getCharacteristicsInfoTableCell(
                      labels.characteristicsInfoLabel3.label,
                      hourInfo
                    )}
                  {labels.characteristicsInfoLabel4 &&
                    labels.characteristicsInfoLabel4.label !== "" &&
                    editableCell(
                      hourInfo.characeristicInfo4,
                      "characeristicInfo4",
                      i,
                      options.selectedDate,
                      onChange
                    )}
                  {labels.characteristicsInfoLabel5 &&
                    labels.characteristicsInfoLabel5.label !== "" &&
                    editableCell(
                      hourInfo.characeristicInfo5,
                      "characeristicInfo5",
                      i,
                      options.selectedDate,
                      onChange
                    )}
                  {labels.characteristicsInfoLabel6 &&
                    labels.characteristicsInfoLabel6.label !== "" &&
                    editableCell(
                      hourInfo.characeristicInfo6,
                      "characeristicInfo6",
                      i,
                      options.selectedDate,
                      onChange
                    )}
                  {reportView?.enableTeamSizeDataEntry === true &&
                    editableCell(
                      hourInfo.team.toString(),
                      "team",
                      i,
                      options.selectedDate,
                      onChange,
                      "number"
                    )}
                  {reportView?.enableTeamSizeDataEntry === true && (
                    <BorderedTableCell>
                      {hourInfo.teamThroughput}
                    </BorderedTableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function editableCell(
  cellValue: string,
  characteristic: string,
  index: number,
  selectedDate: Date,
  handleChange: Function,
  testId?: string,
  type?: string
) {
  if (selectedDate.toDateString() === new Date().toDateString()) {
    return (
      <BorderedTableCell>
        <TextareaAutosize
          data-testid={testId}
          onChange={(ev) =>
            handleChange(ev.target.value, index, characteristic)
          }
          value={cellValue}
          style={{ width: "100%" }}
        />
      </BorderedTableCell>
    );
  } else {
    return <BorderedTableCell>{cellValue}</BorderedTableCell>;
  }
}

function getCharacteristicsInfoTableHeader(label: string, t: any): any {
  switch (label) {
    case "Offgrades":
      return t("performanceProReportPage.characteristicsInfo.offgrades");
    case "Leakers":
      return t("performanceProReportPage.characteristicsInfo.leakers");
    case "FillRate":
      return t("performanceProReportPage.characteristicsInfo.fillRate");
    case "ProductChanges":
      return t("performanceProReportPage.characteristicsInfo.productChanges");
  }
}

function getCharacteristicsInfoTableCell(
  label: string,
  hourInfo: ProductionReportHourInfo
): any {
  switch (label) {
    case "Offgrades":
      return (
        <BorderedTableCell>
          {FormatWithTwoDecimals(hourInfo.offgradesPercentage)}
        </BorderedTableCell>
      );
    case "Leakers":
      return (
        <BorderedTableCell>
          {FormatWithTwoDecimals(hourInfo.leakersPercentage)}
        </BorderedTableCell>
      );
    case "FillRate":
      return (
        <BorderedTableCell>
          {FormatWithTwoDecimals(hourInfo.fillRatePercentage)}
        </BorderedTableCell>
      );
    case "ProductChanges":
      return (
        <BorderedTableCell>{hourInfo.numberOfProductChanges}</BorderedTableCell>
      );
  }
}

function getColoredEggsAchievedCell(
  eggsAchieved: number,
  colorSchemas: ColorSchema[],
  unitType: EggUnit
) {
  if (colorSchemas === undefined) {
    return (
      <BorderedTableCell>
        {FormatEggs(eggsAchieved, unitType)}
      </BorderedTableCell>
    );
  }

  let color = "#ffffff";
  colorSchemas.forEach((colorSchema) => {
    if (eggsAchieved < colorSchema.targetValue) {
      color = colorSchema.colorHex;
    }
  });

  return (
    <BorderedTableCell style={{ backgroundColor: color }}>
      {FormatEggs(eggsAchieved, unitType)}
    </BorderedTableCell>
  );
}

function getColoredAccumulatedPercentageVsTargetCell(
  hourInfo: ProductionReportHourInfo,
  index: number,
  reportView: ReportView
) {
  if (reportView.colorSchemas === undefined) {
    return (
      <BorderedTableCell>
        {`${hourInfo.accumelatedPercentageVsTarget.toFixed(1)}%`}
      </BorderedTableCell>
    );
  }

  let color = "#ffffff";
  reportView.colorSchemas.forEach((colorSchema) => {
    if (
      hourInfo.accumelatedEggsAchieved / index + 1 <
      colorSchema.targetValue
    ) {
      color = colorSchema.colorHex;
    }
  });

  return (
    <BorderedTableCell style={{ backgroundColor: color }}>
      {`${hourInfo.accumelatedPercentageVsTarget.toFixed(1)}%`}
    </BorderedTableCell>
  );
}

function getHoursAndMinutesFromDate(date?: Date): string {
  if (date === undefined) {
    return "";
  }

  let isoDate = date.toISOString();
  return `${isoDate.substr(11, 5)}`;
}
