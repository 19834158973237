import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Switch,
  matchPath,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { KpiMeasureType } from "../../components/PerformancePro";
import PerformanceProGroupedView from "../../components/PerformancePro/Grouped/PerformanceProGroupedView";
import { usePerformanceGroupMeasures } from "../../components/PerformancePro/Grouped/performanceProGroupHooks";
import { PerformanceProView } from "../../components/PerformancePro/PerformanceProView";
import ShellQualityViewContainer from "../../components/PerformancePro/ShellQuality/ShellQualityView";
import { KpiMeasureGroupType } from "../../components/PerformancePro/Types";
import RankingOfSuppliesContainer from "../../components/QualityViews/RankingOfSupplies/RankingOfSuppliesContainer";
import {
  NavigationItem,
  TabNavigation,
  TabNavigationVariant,
} from "../../components/UI/TabNavigation";
import { FeaturePolicyItems } from "../../globalHooks/authorizationHooks";
import {
  PolicyFeatureWrapper,
  usePolicyFeatures,
} from "../../globalHooks/usePolicyFeatures";
import { useSelectedMachine } from "../../store/GlobalContext";
import { PerformanceProRoute } from "../../utility/ProtectedRoute";
import { PerformanceProToolbarContainer } from "./PerformanceProToolbar";

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  emptyContent: {
    padding: theme.spacing(3),
    height: "40vh",
  },
  gridContent: {
    paddingTop: 0,
  },
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

export function PerformanceProQuality() {
  const classes = useStyles();
  let { path } = useRouteMatch();
  let location = useLocation();
  const machineId = useSelectedMachine().machineId;

  const subNavigationItems = usePerformanceProQualityNavigationItems();

  const selectedNavigationItem = useMemo<number>((): number => {
    const result = subNavigationItems.findIndex((item) =>
      matchPath(location.pathname, {
        path: item.link,
        exact: false,
        strict: false,
      })
    );

    return result < 0 ? 0 : result;
  }, [subNavigationItems, location]);

  const { isInfeedBased, shellStrengthActive, brownDetectionActive } =
    usePerformanceGroupMeasures();

  return (
    <>
      <PerformanceProToolbarContainer
        enableEggTypeSelect={true}
        enableDatePeriod={true}
      />
      <div className={classes.content}>
        <Paper>
          <TabNavigation
            navigationItems={subNavigationItems}
            variant={TabNavigationVariant.Secondary}
            selectedNavigationItem={selectedNavigationItem}
          />
          <Switch>
            <PerformanceProRoute path={`${path}/table-eggs`}>
              <PerformanceProView
                machineId={machineId}
                measure={KpiMeasureType.TableEggs}
              />
            </PerformanceProRoute>
            <PerformanceProRoute path={`${path}/input-offgrades`}>
              <PerformanceProView
                machineId={machineId}
                measure={KpiMeasureType.InputOffgrades}
              />
            </PerformanceProRoute>
            <PerformanceProRoute path={`${path}/upgrades`}>
              <PolicyFeatureWrapper
                policy={FeaturePolicyItems.EnableQualityUpgrades}
              >
                <PerformanceProView
                  machineId={machineId}
                  measure={KpiMeasureType.Upgrades}
                />
              </PolicyFeatureWrapper>
            </PerformanceProRoute>
            <PerformanceProRoute path={`${path}/output-offgrades`}>
              <PerformanceProView
                machineId={machineId}
                measure={KpiMeasureType.Outputoffgrades}
              />
            </PerformanceProRoute>
            <PerformanceProRoute path={`${path}/bypass`}>
              <PerformanceProView
                machineId={machineId}
                measure={KpiMeasureType.Bypass}
              />
            </PerformanceProRoute>
            <PerformanceProRoute path={`${path}/bucket-eggs`}>
              <PerformanceProView
                machineId={machineId}
                measure={KpiMeasureType.BucketEggs}
              />
            </PerformanceProRoute>
            <Redirect from={`${path}/`} to={`${path}/table-eggs`} />
          </Switch>
        </Paper>
      </div>

      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <PerformanceProGroupedView
                chartName="size-distribution"
                measure={
                  isInfeedBased
                    ? KpiMeasureGroupType.InfeedGradeByDatePeriod
                    : KpiMeasureGroupType.TakeawayGradeByDatePeriod
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </div>

      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <PerformanceProGroupedView
                chartName="size-per-supply"
                measure={
                  isInfeedBased
                    ? KpiMeasureGroupType.InfeedGradeBySupply
                    : KpiMeasureGroupType.TakeawayGradeBySupply
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </div>

      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <PerformanceProGroupedView
                chartName="offgrade-per-type"
                measure={
                  isInfeedBased
                    ? KpiMeasureGroupType.InfeedOffgradeByDatePeriod
                    : KpiMeasureGroupType.TakeawayOffgradeByDatePeriod
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </div>

      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <PerformanceProGroupedView
                chartName="offgrade-total"
                measure={
                  isInfeedBased
                    ? KpiMeasureGroupType.InfeedOffgradeBySupply
                    : KpiMeasureGroupType.TakeawayOffgradeBySupply
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </div>

      {brownDetectionActive && (
        <div className={classes.content}>
          <Paper>
            <Grid container>
              <Grid item xs={12} className={classes.gridContent}>
                <PerformanceProGroupedView
                  chartName="shell-color"
                  measure={KpiMeasureGroupType.ColorBySupply}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}

      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <ShellQualityViewContainer />
            </Grid>
          </Grid>
        </Paper>
      </div>

      {shellStrengthActive && (
        <div className={classes.content}>
          <Paper>
            <Grid container>
              <Grid item xs={12} className={classes.gridContent}>
                <PerformanceProGroupedView
                  chartName="shell-strength"
                  measure={KpiMeasureGroupType.ShellStrengthBySupply}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}

      <div className={classes.content}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.gridContent}>
              <RankingOfSuppliesContainer />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
}

function usePerformanceProQualityNavigationItems() {
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const { hasFeatureEnabled } = usePolicyFeatures();

  const subNavigationItems: Array<NavigationItem> = useMemo(
    () => [
      {
        link: `${url}/table-eggs`,
        title: t("qualityViews.navigationItems.tableEggs"),
        shouldRender: true,
      },
      {
        link: `${url}/input-offgrades`,
        title: t("qualityViews.navigationItems.inputOffgrades"),
        shouldRender: true,
      },
      {
        link: `${url}/upgrades`,
        title: t("qualityViews.navigationItems.upgrades"),
        shouldRender: hasFeatureEnabled(FeaturePolicyItems.EnableQualityUpgrades),
      },
      {
        link: `${url}/output-offgrades`,
        title: t("qualityViews.navigationItems.outputOffgrades"),
        shouldRender: true,
      },
      {
        link: `${url}/bypass`,
        title: t("qualityViews.navigationItems.bypass"),
        shouldRender: true,
      },
      {
        link: `${url}/bucket-eggs`,
        title: t("qualityViews.navigationItems.bucketEggs"),
        shouldRender: true,
      },
    ].filter((item) => item.shouldRender),
    [hasFeatureEnabled, t, url]
  );

  return subNavigationItems;
}
