import { Button, CircularProgress, makeStyles } from "@material-ui/core";

interface Props {
  disabled: boolean;
  onClick: () => void;
  title: string;
  isLoading: boolean;
}

export function LoadingButton({ title, disabled, onClick, isLoading }: Props) {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={onClick}
    >
      {title}
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    marginLeft: 5,
  },
}));
