import React, { useEffect, useState, useCallback } from "react";
import { useReportService } from "../../../api/ServiceContext";
import { EggType } from "../../../utility/EggUtility";
import useRefresh from "../../Dashboard/useRefresh";
import EggTypeWidgetPresentation, { EggTypeApiResponse } from "./EggType";

export type EggTypeWidgetContainerProps = {
  machineId: number;
  automaticRefresh: boolean;
};

/**
 * A simple widget that displays the egg type of the current supply.
 */
export default function EggTypeWidgetContainer({
  machineId,
  automaticRefresh,
}: EggTypeWidgetContainerProps) {
  const reportService = useReportService();

  const [awaitingResponse, setAwaitingResponse] = useState(true);
  const [eggType, setEggType] = useState(EggType.Unknown);
  const [retrievingForSupply, setRetrievingForSupply] = useState(false);
  const [currentSupplyId, setCurrentSupplyId] = useState<Nullable<number>>(
    null
  );

  const fetchData = useCallback(
    (newMachineId: number) => {
      reportService
        .getEggTypes(newMachineId)
        .then((response: EggTypeApiResponse) => {
          if (response.data.eggType === EggType.Unknown) {
            if (response.data.supplyId) {
              setRetrievingForSupply(true);
            }
          } else {
            setRetrievingForSupply(false);
          }

          setCurrentSupplyId(response.data.supplyId);
          setEggType(response.data.eggType);
          setAwaitingResponse(false);
        });
    },
    [reportService]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return (
    <EggTypeWidgetPresentation
      awaitingResponse={awaitingResponse}
      eggType={eggType}
      retrievingForSupply={retrievingForSupply}
      currentSupplyId={currentSupplyId}
    />
  );
}
