import { useEffect, useState } from "react";
import { EggUnit } from "../../utility/EggUnitUtility";
import { determineEggUnit } from "../../utility/EggUtility";
import { t } from "../../utility/TranslateUtility";
import LaneProductionChart from "../Charts/LaneProductionChart";
import { ChartCardEggType } from "../ProductionViews/Common";
import { useLaneProductionHook } from "./LaneProductionHook";

export default function LaneProductionContainer({
  machineId,
}: {
  machineId: MachineId;
}) {
  const { data, options, targets, awaitingResponse } = useLaneProductionHook({
    machineId: machineId,
  });
  const [eggUnit, setEggUnit] = useState<EggUnit>(targets.units.eggUnit);

  useEffect(() => {
    setEggUnit(targets.units.eggUnit);
  }, [targets]);

  const handleMenuItemClicked = (value: string) => {
    setEggUnit(determineEggUnit(value));
  };

  return (
    <ChartCardEggType
      headerTitle={t("uniqueViews.laneProduction.cardHeader")}
      defaultEggUnit={eggUnit}
      onMenuItemClicked={handleMenuItemClicked}
      isLoading={awaitingResponse}
    >
      {data && (
        <LaneProductionChart
          packingLanes={data.packingLanes}
          eggUnit={eggUnit}
          options={options}
        />
      )}
    </ChartCardEggType>
  );
}
