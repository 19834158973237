import { EggUnit } from "../../../utility/EggUnitUtility";
import { FormatEggs, getDisplayUnit } from "../../../utility/EggUtility";
import { t } from "../../../utility/TranslateUtility";
import { format, TooltipBuilder } from "../../ProductionViews/Tooltip";

export function EggProductionToolTipFormatter(units: EggUnit) {
  return new TooltipBuilder()
  .addXHourWithMinutes(t("widgets.eggProduction.tooltip.tooltipTime"))
  .add((data: any) => {
    if (data.series.options.custom.name === "set-machine-speed-yaxis") {
      return format(
        {
          label: t("widgets.eggProduction.tooltip.setMachineSpeedLabel"),
          unit: `${t(getDisplayUnit(units))} ${t("widgets.eggProduction.tooltip.setMachineSpeedUnit")}`,
        },
        data.point.y
      );
    }

    return `${format(
      {
        label: t("widgets.eggProduction.tooltip.eggProductionMinuteLabel"),
        unit: t(getDisplayUnit(units)).toLowerCase(),
      },
      `${FormatEggs(data.point.y / 60, units, false)}`
    )}${format(
      {
        label: t("widgets.eggProduction.tooltip.eggProductionHourLabel"),
        unit: t(getDisplayUnit(units)).toLowerCase(),
      },
      `${FormatEggs(data.point.y, EggUnit.Eggs)}`
    )}${format(
      {
        label: t("widgets.eggProduction.tooltip.eggTypeLabel"),
      },
      data.point.series.name
    )}`;
  })
  .buildFormatter();
} 
