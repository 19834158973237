import { format as formatDate } from "date-fns";
import { DatePeriod, normalizeDate } from "../../utility/DateUtility";
import { EggUnit } from "../../utility/EggUnitUtility";
import { FormatEggs } from "../../utility/EggUtility";
import { FormatWithTwoDecimals } from "../../utility/NumberFormatUtility";
import { t } from "../../utility/TranslateUtility";
import {
  TooltipBuilder,
  format,
  getHoursAndMinutesFromHours,
  getHoursAndMinutesLabel,
} from "../ProductionViews/Tooltip";
import { ChartMapping } from "./ChartMapping";

export function PerformanceProTooltipBuilder(
  datePeriod: DatePeriod,
  chartMapping: ChartMapping,
  unitLabel: string,
  eggUnit: EggUnit
): {
  formatter(data: any): string;
} {
  if (datePeriod === DatePeriod.Day) {
    return CreateDayTooltip(chartMapping, unitLabel, eggUnit);
  }

  return datePeriod === DatePeriod.Year
    ? CreateYearTooltip(chartMapping, unitLabel, eggUnit)
    : CreateWeekMonthTooltip(chartMapping, unitLabel, eggUnit);
}

/**
 * Format: November 2020
 */
function CreateYearTooltip(
  chartMapping: ChartMapping,
  unitLabel: string,
  eggUnit: EggUnit
): {
  formatter(data: any): string;
} {
  return new TooltipBuilder()
    .add((data: any) => {
      return format(
        {
          label: t("performancePro.tooltipMonth"),
          separator: false,
        },
        `${formatDate(normalizeDate(new Date(data.point.x)), "MMMM yyyy")}`
      );
    })
    .add((data: any) => {
      return format(
        {
          label: t(chartMapping.headerTitle),
          separator: true,
          unit: unitLabel,
        },
        FormatYValue(chartMapping, data.point.y, eggUnit)
      );
    })
    .buildFormatter();
}

/**
 * Format: Friday 1 May
 */
function CreateWeekMonthTooltip(
  chartMapping: ChartMapping,
  unitLabel: string,
  eggUnit: EggUnit
): {
  formatter(data: any): string;
} {
  return new TooltipBuilder()
    .add((data: any) => {
      return format(
        {
          label: t("performancePro.tooltipDate"),
          separator: false,
        },
        `${formatDate(new Date(data.point.x), "eeee d MMMM")}`
      );
    })
    .add((data: any) => {
      return format(
        {
          label: t(chartMapping.headerTitle),
          separator: true,
          unit: unitLabel,
        },
        FormatYValue(chartMapping, data.point.y, eggUnit)
      );
    })
    .buildFormatter();
}

function CreateDayTooltip(
  chartMapping: ChartMapping,
  unitLabel: string,
  eggUnit: EggUnit
): {
  formatter(data: any): string;
} {
  return new TooltipBuilder()
    .addXHourWithMinutesPeriod(t("performancePro.tooltipTime"))
    .add((data: any) => {
      if (chartMapping.valueType === "Time") {
        return format(
          {
            label: t(chartMapping.headerTitle),
            separator: true,
          },

          FormatYValue(chartMapping, data.point.y, eggUnit)
        );
      } else {
        return format(
          {
            label: t(chartMapping.headerTitle),
            separator: true,
            unit: unitLabel,
          },
          FormatYValue(chartMapping, data.point.y, eggUnit)
        );
      }
    })
    .buildFormatter();
}

function FormatYValue(
  chartMapping: ChartMapping,
  value: number,
  eggUnit: EggUnit
): string {
  switch (chartMapping.valueType) {
    case "Time":
      return getHoursAndMinutesLabel(getHoursAndMinutesFromHours(value));
    case "Percentage":
      return FormatWithTwoDecimals(value);
    default:
      return FormatEggs(value, eggUnit, false);
  }
}
