import { Box, Paper, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { t } from "../../../../utility/TranslateUtility";
import ProductChangesPerDayContainer from "./ChangesPerDay/ProductChanges/ProductChangesPerDayContainer";
import SupplyChangesPerDayContainer from "./ChangesPerDay/SupplyChanges/SupplyChangesPerDayContainer";
import UpgradingPercentagePerDayContainer from "./ChangesPerDay/UpgradingPercentage/UpgradingPercentagePerDayContainer";
import MachineMeasureCountrySelect from "./Country/MachineMeasureCountrySelect";
import EggTypeSelect from "./EggTypes/EggTypeSelect";
import MachineMeasureMachineTypeSelect from "./MachineType/MachineMeasureMachineTypeSelect";
import MyMachinesSection from "./MyMachines/MyMachinesSection";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      height: "30vh",
    },
    [theme.breakpoints.down('md')]: {
      height: "45vh",
    },
    [theme.breakpoints.up('lg')]: {
      height: "55vh",
      overflow: "scroll",
      overflowX: "hidden",
    }
  },
  benchmarkFiltersSide: {
    padding: theme.spacing(2),
    position: "fixed",
    top: 200,
    width: "30vw",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  benchmarkFiltersInToolbar: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  filterSeparator: {
    marginTop: theme.spacing(2),
  },
  lastFilterSeparator: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(15),
    },
  },
  typeText: {
    flexGrow: 1,
    lineHeight: "36px",
  },
  saveButton: {
    marginRight: theme.spacing(2),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
}));

export default function MachineMeasureComparisonFilters() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.filterSeparator}>
          <MyMachinesSection />
        </div>
        <div className={classes.filterSeparator}>
          <Header title={t("benchmark.filters.myMachinesComparedToLabel")} />
        </div>
        <div className={classes.filterSeparator}>
          <MachineMeasureMachineTypeSelect />
        </div>
        <div className={classes.filterSeparator}>
          <EggTypeSelect />
        </div>
        <div className={classes.filterSeparator}>
          <MachineMeasureCountrySelect />
        </div>
        <div className={classes.filterSeparator}>
          <SupplyChangesPerDayContainer />
        </div>
        <div className={classes.filterSeparator}>
          <ProductChangesPerDayContainer />
        </div>
        <div className={classes.lastFilterSeparator}>
          <UpgradingPercentagePerDayContainer />
        </div>
      </div>
    </>
  );
}

export function Header({ title }: { title: string }) {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="h6" className={classes.typeText}>
        {title}
      </Typography>
    </div>
  );
}

export function HeaderWithButton({
  title,
  buttonLabel,
  onClick,
}: {
  title: string;
  buttonLabel: string;
  onClick: () => void;
}) {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <Typography variant="h6" className={classes.typeText}>
        {title}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={onClick}
        className={classes.saveButton}
      >
        {buttonLabel}
      </Button>
    </div>
  );
}

export function FiltersSideWrapper({ children }: any): any {
  const classes = useStyles();
  return <Paper className={classes.benchmarkFiltersSide}>{children}</Paper>;
}

export function FiltersToolbarWrapper({ children }: any) {
  const classes = useStyles();
  return <Box className={classes.benchmarkFiltersInToolbar}>{children}</Box>;
}
