import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boldParagraph: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
  },
}));

export function BoldParagraph({ children }: any) {
  const classes = useStyles();
  return (
    <Typography className={classes.boldParagraph} variant="body1">
      {children}
    </Typography>
  );
}
