import {
  brownScaleColors,
  offgradeColors,
  shellStrengthColors,
} from "../../../colors";
import { KpiMeasureGroupType } from "../Types";

export enum XAxisType {
  DatePeriod,
  Supply,
}

export type StackedBarChartMapping = {
  measure: KpiMeasureGroupType;
  headerTitle: string;
  tooltipYLabel: string;
  tooltipYMeasure: string;
  groupMapping: StackedBarChartGroupMapping[];
  isMeasureInEggs: boolean;
  showTotal: boolean;
  xAxisType: XAxisType;
  supplierNameShed?: string;
};

export interface StackedBarChartGroupMapping {
  label: string;
  name: string;
  color?: string;
}

const brownScaleMapping = {
  headerTitle: "uniqueViews.brownScalePerSupply.cardHeader",
  tooltipXLabel: "performancePro.tooltipTime",
  tooltipYLabel: "performanceViews.totalEggs.tooltipMeasure",
  tooltipYMeasure: "eggUnits.singleEggs",
  groupMapping: [...Array.from(Array(10).keys())].map((i) => {
    return {
      name: `Color ${i}`,
      label: `uniqueViews.brownScalePerSupply.labels.color${i}`,
      color: brownScaleColors[i],
    };
  }),
  isMeasureInEggs: true,
  showTotal: true,
};

const shellStrengthMapping = {
  headerTitle: "uniqueViews.shellStrengthPerSupply.cardHeader",
  tooltipYLabel: "performanceViews.totalEggs.tooltipMeasure",
  tooltipYMeasure: "eggUnits.singleEggs",
  groupMapping: [...Array.from(Array(10).keys())].map((i) => {
    return {
      name: `Strength ${i}`,
      label: `uniqueViews.shellStrengthPerSupply.labels.Strength${i + 1}`,
      color: shellStrengthColors[i],
    };
  }),
  isMeasureInEggs: true,
  showTotal: true,
};

const offgradesMapping = {
  headerTitle: "uniqueViews.offgradesPerType.cardHeader",
  tooltipYLabel: "performanceViews.totalEggs.tooltipMeasure",
  tooltipYMeasure: "eggUnits.singleEggs",
  groupMapping: [
    {
      name: "Leaker",
      label: "uniqueViews.offgradesPerType.offgrades.leaker",
      color: offgradeColors.leaker,
    },
    {
      name: "Blood",
      label: "uniqueViews.offgradesPerType.offgrades.blood",
      color: offgradeColors.blood,
    },
    {
      name: "Crack",
      label: "uniqueViews.offgradesPerType.offgrades.crack",
      color: offgradeColors.crack,
    },
    {
      name: "Dirt",
      label: "uniqueViews.offgradesPerType.offgrades.dirt",
      color: offgradeColors.dirt,
    },
    {
      name: "Other",
      label: "uniqueViews.offgradesPerType.offgrades.other",
      color: offgradeColors.other,
    },
  ],
  isMeasureInEggs: true,
  showTotal: true,
};

const sizeMapping = {
  headerTitle: "uniqueViews.sizePerSupply.cardHeader",
  tooltipYLabel: "performanceViews.totalEggs.tooltipMeasure",
  tooltipYMeasure: "eggUnits.singleEggs",
  groupMapping: [],
  isMeasureInEggs: true,
  showTotal: true,
};

export const StackedBarChartMappings: Array<StackedBarChartMapping> = [
  {
    measure: KpiMeasureGroupType.InfeedOffgradeByDatePeriod,
    xAxisType: XAxisType.DatePeriod,
    ...offgradesMapping,
  },
  {
    measure: KpiMeasureGroupType.TakeawayOffgradeByDatePeriod,
    xAxisType: XAxisType.DatePeriod,
    ...offgradesMapping,
  },
  {
    measure: KpiMeasureGroupType.TakeawayOffgradeBySupply,
    xAxisType: XAxisType.Supply,
    ...offgradesMapping,
    headerTitle: "uniqueViews.offgradesPerSupply.cardHeader",
  },
  {
    measure: KpiMeasureGroupType.InfeedOffgradeBySupply,
    xAxisType: XAxisType.Supply,
    ...offgradesMapping,
    headerTitle: "uniqueViews.offgradesPerSupply.cardHeader",
  },
  {
    measure: KpiMeasureGroupType.InfeedGradeBySupply,
    xAxisType: XAxisType.Supply,
    ...sizeMapping,
  },
  {
    measure: KpiMeasureGroupType.TakeawayGradeBySupply,
    xAxisType: XAxisType.Supply,
    ...sizeMapping,
  },
  {
    measure: KpiMeasureGroupType.InfeedGradeByDatePeriod,
    xAxisType: XAxisType.DatePeriod,
    ...sizeMapping,
    headerTitle: "uniqueViews.sizePerDatePeriod.cardHeader",
  },
  {
    measure: KpiMeasureGroupType.TakeawayGradeByDatePeriod,
    xAxisType: XAxisType.DatePeriod,
    ...sizeMapping,
    headerTitle: "uniqueViews.sizePerDatePeriod.cardHeader",
  },
  {
    measure: KpiMeasureGroupType.ColorByDatePeriod,
    xAxisType: XAxisType.DatePeriod,
    ...brownScaleMapping,
    headerTitle: "uniqueViews.brownScalePerDatePeriod.cardHeader",
  },
  {
    measure: KpiMeasureGroupType.ColorBySupply,
    xAxisType: XAxisType.Supply,
    ...brownScaleMapping,
  },
  {
    measure: KpiMeasureGroupType.ShellStrengthBySupply,
    xAxisType: XAxisType.Supply,
    ...shellStrengthMapping,
  },
  {
    measure: KpiMeasureGroupType.ColorByDatePeriodForSupplier,
    ...brownScaleMapping,
    xAxisType: XAxisType.DatePeriod,
    headerTitle: "uniqueViews.lineChartPerSupplier.ColorTitle",
  },
  {
    measure: KpiMeasureGroupType.ShellStrengthByDatePeriodForSupplier,
    ...shellStrengthMapping,
    xAxisType: XAxisType.DatePeriod,
    headerTitle: "uniqueViews.lineChartPerSupplier.ShellStrengthTitle",
  },
  {
    measure: KpiMeasureGroupType.InfeedGradeByDatePeriodForSupplier,
    xAxisType: XAxisType.DatePeriod,
    ...sizeMapping,
  },
  {
    measure: KpiMeasureGroupType.TakeawayGradeByDatePeriodForSupplier,
    xAxisType: XAxisType.DatePeriod,
    ...sizeMapping,
  },
  {
    measure: KpiMeasureGroupType.InfeedOffgradeByDatePeriodForSupplier,
    xAxisType: XAxisType.DatePeriod,
    ...offgradesMapping,
  },
  {
    measure: KpiMeasureGroupType.TakeawayOffgradeByDatePeriodForSupplier,
    xAxisType: XAxisType.DatePeriod,
    ...offgradesMapping,
  },
];
