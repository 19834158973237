import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import * as Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import xrange from "highcharts/modules/xrange";
import { useMemo } from "react";
import { colors } from "../../../colors";
import { useIsMobileSize } from "../../../globalHooks/responsiveHooks";
import { getLastElement } from "../../../utility/ArrayUtility";
import { subtractMinutes } from "../../../utility/DateUtility";
import { t } from "../../../utility/TranslateUtility";
import { XRangeChartSettings } from "../../Charts/ChartSettings/XRangeChartSettings";
import { TooltipBuilder } from "../../ProductionViews/Tooltip";
import HighchartsFullscreenWrapper, {
  RenderPosition,
} from "../Wrappers/HighchartsFullscreenWrapper";

// Enable special charts
HC_more(Highcharts);
xrange(Highcharts);

export type MachineRunningWidgetPresentationProps = {
  awaitingResponse: boolean;
  runningMessage: string;
  isRunning: boolean;
  data: MachineRunningApiData;
};

export type MachineRunningApiResponse = {
  data: MachineRunningApiData;
};

export type MachineRunningApiData = {
  machineRunningPeriods: Array<MachineRunningPeriod>;
  stateDurationSeconds: number;
  isRunning: boolean;
};

export type MachineRunningPeriod = {
  isRunning: boolean;
  startTime: Date;
  endTime: Date;
};

const tooltipFormatter = new TooltipBuilder()
  .addXHourWithMinutesWithSecondsPeriod(
    t("performanceViews.fillRateProduction.tooltipTime")
  )
  .add(t("widgets.machineRunning.toolTipRunningMessage"))
  .buildFormatter();

const ChartSettings = {
  ...XRangeChartSettings,
  colors: [colors.machineRunning],
  yAxis: {
    ...XRangeChartSettings.yAxis,
    categories: ["Running"],
  },
  tooltip: tooltipFormatter,
};

function MapToChartData(input: MachineRunningApiData) {
  const newData = input.machineRunningPeriods.filter((period) => {
    return period.isRunning === true;
  });

  const last_element = getLastElement(input.machineRunningPeriods);

  const newMax = last_element.endTime;

  const result = newData.map((period: any) => ({
    x: period.startTime.getTime(),
    x2: period.endTime.getTime(),
    y: 0,
  }));

  const maxDate = newMax.getTime();
  const minDate = subtractMinutes(newMax, 30);

  return {
    series: result,
    maxDate: maxDate,
    minDate: minDate,
  };
}

export default function MachineRunningWidgetPresentation({
  awaitingResponse,
  isRunning,
  runningMessage,
  data,
}: MachineRunningWidgetPresentationProps) {
  const isMobileSize = useIsMobileSize();

  const newOptions = useMemo(() => {
    if (awaitingResponse || !data) return ChartSettings;
    const newChartData = MapToChartData(data);

    return {
      ...ChartSettings,
      chart: {
        ...ChartSettings?.chart,
        height: isMobileSize ? "220px" : "400px",
      },
      xAxis: {
        ...ChartSettings.xAxis,
        max: newChartData?.maxDate,
        min: newChartData?.minDate,
      },
      series: [
        {
          data: newChartData?.series,
        },
      ],
    };
  }, [data, awaitingResponse, isMobileSize]);

  return (
    <HighchartsFullscreenWrapper
      title={t("widgets.machineRunning.title")}
      awaitingResponse={awaitingResponse}
      highcharts={Highcharts}
      chartOptions={newOptions}
      customFullscreenHeight={"90vh"}
      customJsx={
        <MachineRunningText
          isRunning={isRunning}
          runningMessage={runningMessage}
        />
      }
      renderPosition={RenderPosition.Top}
    />
  );
}

interface MachineRunningTextProps {
  isRunning: boolean;
  runningMessage: string;
}

function MachineRunningText({
  isRunning,
  runningMessage,
}: MachineRunningTextProps) {
  const classes = useStyles();
  const isMobileSize = useIsMobileSize();
  const headingVariant = isMobileSize ? "h5" : "h4";
  const iconSize = isMobileSize ? "default" : "large";

  return (
    <div className={classes.runningText}>
      {isRunning === true ? (
        <PlayCircleFilledIcon fontSize={iconSize} className={classes.icon} />
      ) : (
        <PauseCircleFilledIcon fontSize={iconSize} className={classes.icon} />
      )}
      <Typography variant={headingVariant} id="runningMessage">
        {runningMessage}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingTop: 0,
    paddingBottom: "0px !important",
  },
  cardHeader: {
    paddingBottom: 0,
  },
  runningText: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));
