import * as Highcharts from "highcharts";
import React, { useMemo } from "react";
import { colors } from "../../../colors";
import { everyMinute } from "../../../utility/DateUtility";
import { FormatWithTwoDecimals } from "../../../utility/NumberFormatUtility";
import { t } from "../../../utility/TranslateUtility";
import { format, TooltipBuilder } from "../../ProductionViews/Tooltip";
import HighchartsFullscreenWrapper from "../Wrappers/HighchartsFullscreenWrapper";

const tooltipFormatter = new TooltipBuilder()
  .addXHourWithMinutes(t("widgets.offgrades.tooltipOffgradeTime"))
  .add((data) =>
    format(
      { label: t("widgets.offgrades.tooltipOffgradeSupplier") },
      data.point.supplyName
    )
  )
  .addYWithTransfomation({
    label: t("widgets.offgrades.tooltipOffgradePercentage"),
    unit: "%",
    transformation: FormatWithTwoDecimals,
  })
  .buildFormatter();

export type OffgradesApiResponse = {
  data: OffgradesApiData;
};

export type OffgradesApiData = {
  offgradePeriods: Array<OffgradesPeriod>;
};

export type OffgradesPeriod = {
  supplyId: number;
  supplyName: string;
  supplyOffgradePeriods: Array<SupplyOffgradePeriods>;
};

export type SupplyOffgradePeriods = {
  date: Date;
  offgradePercentage: number;
  supplyName: string;
};

const ChartSettings: any = {
  chart: {
    type: "column",
  },
  colors: [
    colors.mobaBlue,
    colors.cageEgg,
    colors.barnEgg,
    colors.freeRangeEgg,
    colors.organicEgg,
    colors.lightBlue,
    colors.dirtGreen,
    colors.leakerYellow,
    colors.crackBlue,
    colors.mobaOrange,
  ],
  plotOptions: {
    column: {
      groupPadding: 0,
      pointPadding: 0,
      borderWidth: 0,
      grouping: false,
    },
  },
  xAxis: {
    type: "datetime",
    minorTicks: true,
    showFirstLabel: true,
    showLastLabel: true,
    tickInterval: everyMinute,
    max: 0,
    minorGridLineWidth: 0,
  },
  yAxis: {
    title: {
      text: t("widgets.offgrades.yAxisTitle"),
    },
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  tooltip: tooltipFormatter,
  series: [
    {
      data: [],
    },
  ],
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
};

const determineOffgradePeriodName = (period: OffgradesPeriod) => {
  if (period.supplyName === "") {
    return period.supplyId.toString();
  }

  return period.supplyName;
};

function MapToChartData(input: OffgradesApiData) {
  const offGradesPeriodLength = input.offgradePeriods.length - 1;

  if (offGradesPeriodLength < 0) {
    return;
  }

  var allSupplyOffgradePeriods: SupplyOffgradePeriods[] = [];
  for (var i = 0; i < input.offgradePeriods.length; i++) {
    var supplyOffgradePeriods = input.offgradePeriods[i].supplyOffgradePeriods;
    supplyOffgradePeriods.forEach((period) =>
      allSupplyOffgradePeriods.push(period)
    );
  }

  const newMax = allSupplyOffgradePeriods.reduce(function (prev, current) {
    return prev.date > current.date ? prev : current;
  });

  const result = input.offgradePeriods.map((period: OffgradesPeriod) => ({
    supplyId: period.supplyId,
    supplyName: period.supplyName,
    data: period.supplyOffgradePeriods.map(
      (supplyPeriod: SupplyOffgradePeriods) => ({
        x: supplyPeriod.date.getTime(),
        y: supplyPeriod.offgradePercentage,
        supplyName: determineOffgradePeriodName(period),
      })
    ),
  }));

  return {
    series: result,
    maxDate: newMax.date.getTime(),
  };
}

export interface OffgradesWidgetPresentationProps {
  awaitingResponse: boolean;
  data: OffgradesApiData;
}

export default function OffgradesWidgetPresentation({
  awaitingResponse,
  data,
}: OffgradesWidgetPresentationProps) {
  const newOptions = useMemo(() => {
    if (awaitingResponse || !data) return null;

    const newChartData = MapToChartData(data);

    return {
      ...ChartSettings,
      xAxis: {
        ...ChartSettings.xAxis,
        max: newChartData?.maxDate,
      },
      series: newChartData?.series,
    };
  }, [data, awaitingResponse]);

  return (
    <HighchartsFullscreenWrapper
      title={t("widgets.offgrades.title")}
      awaitingResponse={awaitingResponse}
      highcharts={Highcharts}
      chartOptions={newOptions}
    />
  );
}
