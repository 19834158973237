import { AlarmTopic } from "../Alarms/Types";
import { KpiMeasureType } from "../PerformancePro";

export function MapToAlarmTopic(
  kpiMeasurement: KpiMeasureType
): AlarmTopic | undefined {
  switch (kpiMeasurement) {
    case KpiMeasureType.BucketEggs:
      return AlarmTopic.BucketEggs;
    case KpiMeasureType.Bypass:
      return AlarmTopic.Bypass;
    case KpiMeasureType.FillRate:
      return AlarmTopic.FillRate;
    case KpiMeasureType.InputOffgrades:
      return AlarmTopic.InputOffgrades;
    case KpiMeasureType.MachineStop:
      return AlarmTopic.MachineConnection;
    case KpiMeasureType.Outputoffgrades:
      return AlarmTopic.OutputOffgrades;
    case KpiMeasureType.ProductionAvailability:
      return AlarmTopic.Availability;
    case KpiMeasureType.ProductionTime:
      return AlarmTopic.ProductionTime;
    case KpiMeasureType.TableEggs:
      return AlarmTopic.TableEggs;
    case KpiMeasureType.Throughput:
      return AlarmTopic.Throughput;
    case KpiMeasureType.TotalEggs:
      return AlarmTopic.TotalEggs;
    case KpiMeasureType.Upgrades:
      return AlarmTopic.Upgrades;
    default:
      return undefined;
  }
}

export function MapToKpiMeasurement(
  alarmTopic: AlarmTopic
): KpiMeasureType | undefined {
  switch (alarmTopic) {
    case AlarmTopic.BucketEggs:
      return KpiMeasureType.BucketEggs;
    case AlarmTopic.Bypass:
      return KpiMeasureType.Bypass;
    case AlarmTopic.FillRate:
      return KpiMeasureType.FillRate;
    case AlarmTopic.InputOffgrades:
      return KpiMeasureType.InputOffgrades;
    case AlarmTopic.MachineConnection:
      return KpiMeasureType.MachineStop;
    case AlarmTopic.OutputOffgrades:
      return KpiMeasureType.Outputoffgrades;
    case AlarmTopic.Availability:
      return KpiMeasureType.ProductionAvailability;
    case AlarmTopic.ProductionTime:
      return KpiMeasureType.ProductionTime;
    case AlarmTopic.TableEggs:
      return KpiMeasureType.TableEggs;
    case AlarmTopic.Throughput:
      return KpiMeasureType.Throughput;
    case AlarmTopic.TotalEggs:
      return KpiMeasureType.TotalEggs;
    case AlarmTopic.Upgrades:
      return KpiMeasureType.Upgrades;
    default:
      return undefined;
  }
}
