import { FeaturePolicyItems } from "../../../globalHooks/authorizationHooks";
import { usePolicyFeatures } from "../../../globalHooks/usePolicyFeatures";
import { EggUnit } from "../../../utility/EggUnitUtility";
import useLaneProducts from "../LaneProducts/useLaneProducts";
import { LaneProductsProgress } from "./LaneProductsProgress";

export default function LaneProductsProgressContainer({
  machineId,
  automaticRefresh,
}: {
  machineId: MachineId;
  automaticRefresh: boolean;
}) {
  const { data, awaitingResponse, units, setUnits } = useLaneProducts({
    machineId,
    automaticRefresh,
  });

  const { hasFeatureEnabled } = usePolicyFeatures();

  const productRemainingEnabled = hasFeatureEnabled(
    FeaturePolicyItems.EnableRemainingLaneProductTargetsGraph
  );

  return (
    <LaneProductsProgress
      data={data}
      isRemainderEnabled={productRemainingEnabled}
      isAwaitingResponse={awaitingResponse}
      units={units}
      onUnitsChange={(u: EggUnit) => setUnits(u)}
    />
  );
}
