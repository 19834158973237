import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Selectbox } from "../Common";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  options: {
    display: "flex",
    flexDirection: "column",
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
}));

type CheckboxSelectionProps = {
  noOptionsTitle?: string;
  options: Array<any> | undefined;
  selectedOptions: Array<any>;
  onChange: (selection: Array<any>) => void;
  optionsLabelSelector?: (option: any) => string;
};

type CheckboxSelectionSectionProps = CheckboxSelectionProps & {
  title: string;
};

export default function CheckboxSelectionSection({
  title,
  noOptionsTitle,
  options,
  optionsLabelSelector,
  selectedOptions,
  onChange,
}: CheckboxSelectionSectionProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body1">
        {title}
      </Typography>
      <CheckboxSelection
        noOptionsTitle={noOptionsTitle}
        onChange={onChange}
        options={options}
        optionsLabelSelector={optionsLabelSelector}
        selectedOptions={selectedOptions}
      />
    </div>
  );
}

export function CheckboxSelection({
  noOptionsTitle,
  options,
  selectedOptions,
  onChange,
  optionsLabelSelector
}: CheckboxSelectionProps) {
  const classes = useStyles();

  const handleChange = (checked: boolean, option: string) => {
    let newSelectedOptions = checked
      ? [...selectedOptions, option]
      : selectedOptions.filter((x) => x !== option);

    onChange(newSelectedOptions);
  };

  const defaultLabelSelector = (option: any) => {
      return option as string;
  }
  const labelSelector = optionsLabelSelector || defaultLabelSelector;
  
  return options ? (
    <div className={classes.options}>
      {options &&
        options.map((o) => {
          return (
            <div key={o.toString()}>
              <Selectbox
                name={o.toString()}
                // eslint-disable-next-line eqeqeq
                isChecked={selectedOptions.findIndex((x) => x == o) !== -1}
                onChange={handleChange}
                value={o.toString()}
                label={labelSelector(o)}
              />
            </div>
          );
        })}
    </div>
  ) : (
    <Typography variant="caption">{noOptionsTitle}</Typography>
  );
}
