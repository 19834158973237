import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserService } from "../api/ServiceContext";
import { useDispatchAlert } from "../components/UI/Alert";
import {
  useMyUserState,
  useSelectedMachine,
  useSelectedMachineAction,
  useUpdateMyUser,
} from "../store/GlobalContext";
import { GetSelectedMachineIdFromLocalStorage } from "../utility/LocalStorageUtility";

export interface UserResult {
  machines: MachineDictionary;
  userSystemWideAuthorization: UserSystemWideAuthorization;
}

export interface MachineDictionary {
  [machineId: number]: UserMachineAuthorizations;
}

export interface UserMachineAuthorizations {
  machineName: string;
  machineId: MachineId;
  roles: string[];
  modules: string[];
  features: Features[];
  requiredApprovalVersion: number;
  isApproved: boolean;
  policy: FeaturePolicy;
}

export interface UserSystemWideAuthorization {
  systemRoles: string[];
  features: Features[];
  lockedMachines: MachineId[];
}

export interface FeaturePolicy {
  name: string;
  enabledFeatures: FeaturePolicyItems[];
}

export enum Features {
  Undefined = "Undefined",
  PerformancePro = "PerformancePro",
  Benchmark = "Benchmark",
  RealtimeDashboard = "RealtimeDashboard",
  ManageSettings = "ManageSettings",
  ManageUsers = "ManageUsers",
  ManageAlarms = "ManageAlarms",
  ManageMachine = "ManageMachine",
  Owner = "Owner",
  CustomerCareManageUsers = "CustomerCareManageUsers",
  CustomerCareManageMachines = "CustomerCareManageMachines",
}

export enum FeaturePolicyItems {
  Unknown = "Unknown",
  EnableQualityUpgrades = "EnableQualityUpgrades",
  EnableRemainingLaneProductTargetsGraph = "EnableRemainingLaneProductTargetsGraph",
  ShowBrownScaleAndShellStrength = "ShowBrownScaleAndShellStrength",
  ShowBenchmarkModule = "ShowBenchmarkModule",
}

export enum UserRole {
  UserCustomerApp = "UserCustomerApp",
  AdminCustomerApp = "AdminCustomerApp",
  OwnerCustomerApp = "OwnerCustomerApp",
}

export function useLoadMyUserIntoGlobalState(): boolean {
  const userService = useUserService();
  const { isLoading, user, isAuthenticated } = useAuth0();
  const [shouldRenderApp, setShouldRenderApp] = useState(false);

  const { updateMyUser } = useUpdateMyUser();
  const dispatch = useSelectedMachineAction();
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      userService
        .getMyUser()
        .then((response: IApiResponse<UserResult>) => {
          updateMyUser(response.data);

          const machines = response.data.machines;

          if (machines && Object.keys(machines).length > 0) {
            const machineId: number = Number.parseInt(
              GetSelectedMachineIdFromLocalStorage() ?? Object.keys(machines)[0]
            );
            const foundMachine = machines[machineId];
            const selectedMachine =
              foundMachine ?? machines[Object.keys(machines)[0] as any];
            const machine: Machine = {
              machineId: selectedMachine.machineId,
              machineName: selectedMachine.machineName,
            };

            dispatch(machine);
            setShouldRenderApp(true);
          }
        })
        .catch((reason) => {
          if (!reason.isCancelled) {
            dispatchAlert({
              message: t("generic.errorMessage"),
              messageType: "error",
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, user, isAuthenticated]);

  return shouldRenderApp;
}

export function useMyMachines() {
  const state = useMyUserState();

  return state.machines;
}

export function useMachineNames(): {
  machineId: number;
  machineName: string;
}[] {
  const machines = useMyMachines();

  let machineNames: { machineId: number; machineName: string }[] = [];
  for (const [machineId, value] of Object.entries(machines)) {
    machineNames.push({
      machineId: Number(machineId),
      machineName: value.machineName,
    });
  }

  return machineNames;
}

/**
 * Contains logic required to resolve authorization
 */
export interface AuthorizationScope {
  /**
   * Returns true if currentl selected machine is approved
   */
  hasApprovalForSelectedMachine(): boolean;

  /**
   * Returns true if user has given feature authorization for currently selected machine
   * @param feature
   */
  hasAuthorizationSpecificOnlyForSelectedMachine(feature: Features): boolean;

  /**
   * Returns true if user has given feature authorization for currently selected machine
   * @param feature
   */
  hasAuthorizationForSelectedMachine(feature: Features): boolean;

  /**
   * Returns true if user has any of the given required feature authorizations for currently selected machine
   * @param feature
   */
  hasAnyAuthorizationForSelectedMachine(requiredFeatures: Features[]): boolean;

  /**
   * All feature authorizations for currently selected machine
   */
  featuresForSelectedMachine: Features[];

  /**
   * All user machine authorizations for currently selected machine
   */
  userMachineAuthorizations: UserMachineAuthorizations;

  /**
   * Returns true if any of the user assigned machines contains the benchmark feature authorization.
   * System level authorizations are excluded.
   *
   * Use this function only if you want to explicitly exclude authorizations on system level.
   * Otherwise use `hasAuthorizationForSelectedMachine` or `hasAnyAuthorizationForSelectedMachine`
   * @param feature
   */
  hasAuthorizationOnAnyAssignedMachine(requiredFeature: Features): boolean;

  /**
   * Returns true if the user has the given feature authorization on system level.
   * @param requiredFeature
   */
  hasAuthorizationOnSystemLevel(requiredFeature: Features): boolean;
}

/**
 * Get Authorization scope for current user
 * @returns Authrozation scope for current user
 */
export function useAuthorizationScope(): AuthorizationScope {
  const selectedMachine = useSelectedMachine();
  const { userSystemWideAuthorization, machines } = useMyUserState();

  let features: Features[] = [];

  for (const [machineId, value] of Object.entries(machines)) {
    if (Number(machineId) === Number(selectedMachine.machineId)) {
      features = features.concat(value.features);
    }
  }

  return useMemo(
    () => ({
      featuresForSelectedMachine: features,
      userMachineAuthorizations: machines[Number(selectedMachine.machineId)],
      hasApprovalForSelectedMachine: () =>
        machines[Number(selectedMachine.machineId)].isApproved,
      hasAuthorizationSpecificOnlyForSelectedMachine: (
        requiredFeature: Features
      ) => features.includes(requiredFeature),
      hasAuthorizationForSelectedMachine: (requiredFeature: Features) =>
        features.includes(requiredFeature) ||
        userSystemWideAuthorization.features.includes(requiredFeature),
      hasAnyAuthorizationForSelectedMachine: (requiredFeatures: Features[]) =>
        requiredFeatures.some((rf) => features.includes(rf)),
      hasAuthorizationOnAnyAssignedMachine: (requiredFeature: Features) =>
        HasFeatureAuthorizationOnSpecificMachine(machines, requiredFeature),
      hasAuthorizationOnSystemLevel: (requiredFeature: Features) =>
        userSystemWideAuthorization.features.includes(requiredFeature),
    }),
    [selectedMachine.machineId, machines, userSystemWideAuthorization]
  );
}

function HasFeatureAuthorizationOnSpecificMachine(
  machines: MachineDictionary,
  feature: Features
) {
  var records = CreateMachineRecords(machines);

  return records.some((x) =>
    x.authorizations.features.some((f) => f === feature)
  );
}

function CreateMachineRecords(machines: MachineDictionary): MachineRecord[] {
  return Object.entries(machines).map<MachineRecord>((e) => ({
    machineId: Number(e[0]),
    authorizations: e[1],
  }));
}

type MachineRecord = {
  machineId: number;
  authorizations: UserMachineAuthorizations;
};
