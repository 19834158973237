export function minGreaterThanMaxError(min: number | null, max: number | null) {
  if (!min || !max) return false;
  return min >= max;
}

export function setNewValue(
  value: string,
  setFunction: React.Dispatch<React.SetStateAction<number | null>>
) {
  const newValue = parseInt(value);

  if (isNaN(newValue)) {
    setFunction(null);
    return;
  }

  setFunction(newValue < 0 ? 0 : newValue);
}
