import { useEffect, useRef } from "react";

const REFRESH_PERIOD_IN_MILLISECONDS = 60000;

/**
 * Calls the given function every 60 seconds.
 *
 * @param callback the function to call
 */
export default function useRefresh(
  callback: () => void,
  customTimeout?: number
) {
  const savedCallback = useRef<() => void>(() => {
    // Intentional blank override
  });

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let intervalFunction = setInterval(
      tick,
      customTimeout ? customTimeout : REFRESH_PERIOD_IN_MILLISECONDS
    );
    return () => clearInterval(intervalFunction);
  }, [callback]);
}
