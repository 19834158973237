
export interface EggTypeGroupApiData {
    eggTypeGroupItems: Array<EggTypeGroupMapping>,
    lastUpdatedAt: Date,
    state: EggTypeMappingSettingState
}

export enum EggTypeMappingSettingState {
    NoChangesPending = "NoChangesPending",
    ChangesPending = "ChangesPending",
    FailedSave = "FailedSave"
}

export interface EggTypeGroupMappingRequest {
    mappings: Array<EggTypeGroupMapping>;
}

export interface EggTypeGroupMapping {
    machineEggTypeName: string;
    eggType: EggGroupType;
}

export enum EggGroupType {
    Unmapped = 5,
    Cage = 1,
    Barn = 2,
    FreeRange = 3,
    Organic = 4,
}

export const AllTypes: EggGroupType[] = [
    EggGroupType.Unmapped,
    EggGroupType.Cage,
    EggGroupType.Barn,
    EggGroupType.FreeRange,
    EggGroupType.Organic,
];