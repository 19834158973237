import { Button, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useAlarmService } from "../../../api/ServiceContext";
import { useSelectedMachine } from "../../../store/GlobalContext";
import { t } from "../../../utility/TranslateUtility";
import { OrderType } from "../../CommonUtil/OrderType";
import { useDispatchAlert } from "../../UI/Alert/alertHooks";
import { IAlarm } from "../AddOrUpdateAlarm/Common";
import ConfirmActionDialog from "../ConfirmActionDialog";
import AlarmListPresentation from "./AlarmListPresentation";
import AlarmListSearch from "./AlarmListSearch";

export interface AlarmsApiResponse {
  alarms: IAlarm[];
}

export interface Props {
  isListUpToDate: boolean;
  timeZone: string;
  onAddClick: () => void;
  onListUpdated: (isListUpdated: boolean) => void;
  openDialog: (selectedAlarm: IAlarm) => void;
}

const useStyles = makeStyles((theme) => ({
  justifyBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    float: "right",
    [theme.breakpoints.down("xs")]: {
      float: "none",
    },
  },
}));

function AlarmListContainer({
  isListUpToDate,
  timeZone,
  onListUpdated,
  onAddClick,
  openDialog,
}: Props) {
  const classes = useStyles();

  const [alarms, setAlarms] = useState<IAlarm[]>([]);
  const [order, setOrder] = useState<OrderType>("desc");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [orderBy, setOrderBy] = useState<keyof IAlarm>("topic");
  const [alarmToDeleteId, setAlarmToDeleteId] = useState<number>(-1);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const alarmService = useAlarmService();
  const dispatchAlert = useDispatchAlert();
  const selectedMachine = useSelectedMachine();

  const createSortHandler = (property: keyof IAlarm) => () => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  const onDelete = (alarmId: number) => {
    setShowConfirmDelete(true);
    setAlarmToDeleteId(alarmId);
  };

  const onDeleteConfirm = () => {
    alarmService
      .removeAlarm(selectedMachine.machineId, alarmToDeleteId)
      .then(() => {
        dispatchAlert({
          message: t("alarmManagement.messages.deleteSuccess"),
          messageType: "success",
        });
        onListUpdated(true);
      })
      .catch((err) => {
        dispatchAlert({
          message: t("alarmManagement.messages.deleteFailed"),
          messageType: "error",
        });
      });
    setShowConfirmDelete(false);
  };

  useEffect(() => {
    if (!isListUpToDate) {
      alarmService
        .getAlarms(selectedMachine.machineId)
        .then((response: IApiResponse<AlarmsApiResponse>) => {
          setAlarms(response.data.alarms);
        });
      onListUpdated(false);
    }
  }, [isListUpToDate, onListUpdated]);

  const searchedAlarms =
    searchTerm.length === 0
      ? alarms
      : alarms.filter((alarm) => {
          return (
            alarm.topic
              .toString()
              .toLowerCase()
              .includes(searchTerm.toString().toLowerCase()) ||
            alarm.user.name
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toString().toLowerCase()) ||
            alarm.condition
              .toString()
              .toLowerCase()
              .includes(searchTerm.toString().toLowerCase()) ||
            alarm.frequency
              .toString()
              .toLowerCase()
              .includes(searchTerm.toString().toLowerCase()) ||
            alarm.endTimeUtc.toString().includes(searchTerm) ||
            alarm.level.toString().includes(searchTerm) ||
            alarm.status
              .toString()
              .toLowerCase()
              .includes(searchTerm.toString().toLowerCase()) ||
            alarm.startTimeUtc?.toString().includes(searchTerm)
          );
        });

  return (
    <>
      <Button
        onClick={onAddClick}
        className={classes.button}
        variant={"outlined"}
        color={"primary"}
      >
        {t("alarmManagement.buttons.add")}
      </Button>

      <AlarmListSearch searchTerm={searchTerm} onChange={setSearchTerm} />

      <AlarmListPresentation
        alarms={searchedAlarms}
        timeZone={timeZone}
        order={order}
        orderBy={orderBy}
        createSortHandler={createSortHandler}
        openDialog={openDialog}
        onDelete={onDelete}
      />

      <ConfirmActionDialog
        isOpen={showConfirmDelete}
        message={t("alarmManagement.messages.confirmation")}
        onConfirm={() => onDeleteConfirm()}
        onReject={() => setShowConfirmDelete(false)}
      />
    </>
  );
}

export default AlarmListContainer;
