import { useCallback, useEffect, useState } from "react";
import { useReportService } from "../../../api/ServiceContext";
import useRefresh from "../../Dashboard/useRefresh";
import SupplierShedWidget from "./SupplierShedWidget";

export type SupplierShedWidgetProps = {
  machineId: number;
  automaticRefresh: boolean;
};

export type SupplierShedApiResponse = {
  data: SupplierShedApiData;
};

export type SupplierShedApiData = {
  supplyId: Nullable<number>;
  supplierName: Nullable<string>;
  shedNumber: Nullable<string>;
};

/**
 * A simple widget that fetches and displays the current supplier of the machine.
 */
export default function SupplierShedWidgetContainer({
  machineId,
  automaticRefresh,
}: SupplierShedWidgetProps) {
  const reportService = useReportService();

  const [supplierName, setSupplierName] = useState<Nullable<string>>(null);
  const [shedNumber, setShedNumber] = useState<Nullable<string>>(null);
  const [supplyId, setSupplyId] = useState<Nullable<number>>(null);
  const [receivedAllData, setReceivedAllData] = useState(false);
  const [awaitingResponse, setAwaitingResponse] = useState(true);

  const fetchData = useCallback(
    (newMachineId: number) => {
      reportService
        .getSupplierShed(newMachineId)
        .then((response: SupplierShedApiResponse) => {
          if (response.data.supplierName && response.data.shedNumber) {
            setSupplierName(response.data.supplierName);
            setShedNumber(response.data.shedNumber);
            setReceivedAllData(true);
          } else if (response.data.supplyId) {
            setSupplyId(response.data.supplyId);
          }

          setAwaitingResponse(false);
        });
    },
    [reportService]
  );

  useRefresh(() => {
    if (!automaticRefresh) return;
    fetchData(machineId);
  });

  useEffect(() => fetchData(machineId), [machineId, fetchData]);

  return (
    <SupplierShedWidget
      supplyId={supplyId}
      supplierName={supplierName}
      shedNumber={shedNumber}
      awaitingResponse={awaitingResponse}
      receivedAllData={receivedAllData}
    />
  );
}
