import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CharacteristicsInfoLabel } from "../../../../pages/management-settings/Common";
import {
  CharacteristicsInfoPresentationProps,
  compareCharacteristicsInfoLabel,
  LabelSelect,
  LabelTextInput,
} from "./Common";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

export function CharacteristicsInfoContainer({
  settings,
  onSettingsChange,
}: CharacteristicsInfoPresentationProps) {
  const [staticLabels, setStaticLabels] = useState<CharacteristicsInfoLabel[]>(
    []
  );
  const [customLabels, setCustomLabels] = useState<CharacteristicsInfoLabel[]>(
    []
  );

  useEffect(() => {
    const orderedLabels = [...settings.characteristicsInfoLabels].sort(
      compareCharacteristicsInfoLabel
    );
    const listCenterIndex = settings.characteristicsInfoLabels.length / 2;
    setStaticLabels(orderedLabels.slice(0, listCenterIndex));
    setCustomLabels(
      orderedLabels.slice(
        listCenterIndex,
        settings.characteristicsInfoLabels.length + 1
      )
    );
  }, [settings.characteristicsInfoLabels]);

  const handleLabelChage = (
    currentLabel: CharacteristicsInfoLabel,
    newLabel: string
  ) => {
    onSettingsChange({
      ...settings,
      characteristicsInfoLabels: [
        ...settings.characteristicsInfoLabels.filter(
          (x) => x.labelNumber !== currentLabel.labelNumber
        ),
        { labelNumber: currentLabel.labelNumber, label: newLabel },
      ],
    });
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        {staticLabels.map((x) => (
          <LabelSelect
            key={x.labelNumber}
            label={x}
            onLabelChange={handleLabelChage}
          />
        ))}
      </div>
      <div>
        {customLabels.map((x) => (
          <LabelTextInput
            key={x.labelNumber}
            label={x}
            onLabelChange={handleLabelChage}
          />
        ))}
      </div>
    </div>
  );
}
