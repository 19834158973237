import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid/Grid";
import { useEffect, useMemo, useState } from "react";
import { useMachineService, useUserService } from "../../../api/ServiceContext";
import { useManagementMachinePageTracking } from "../../../globalHooks/applicationInsightsTrackingHooks";
import { FeaturePolicyItems } from "../../../globalHooks/authorizationHooks";
import { PolicyFeatureWrapper } from "../../../globalHooks/usePolicyFeatures";
import { useSelectedMachine } from "../../../store/GlobalContext";
import { t } from "../../../utility/TranslateUtility";
import { useDispatchAlert } from "../../UI/Alert";
import {
  emptyUser,
  iMobaModule,
  iMobaUser,
} from "../../Users/AddOrUpdateUser/Common";
import { AssingUserToModuleContainer } from "../../Users/AssignUserToModule/AssignUserToModuleContainer";
import { ConfirmActionDialog } from "../../Users/ConfirmActionDialog";
import {
  MachineSubscription,
  MachineSubscriptionApiData,
  SubscriptionType,
} from "./Common";
import SubscriptionCard from "./SubscriptionCard";

export interface SubscriptionCardsContainerProps {
  modulesUpdated: (machineId: MachineId) => void;
  subscriptionRenewalUpdated: (machineId: MachineId) => void;
  isLoading: boolean;
}

export enum DialogMode {
  ConfirmModule,
  ConfirmUser,
  Unknown,
}

export default function SubscriptionCardsContainer({
  modulesUpdated,
  subscriptionRenewalUpdated,
  isLoading,
}: SubscriptionCardsContainerProps) {
  const [renewSubscriptionDialogOpen, setRenewSubscriptionDialogOpen] =
    useState(false);
  const [activateSubscriptionDialogOpen, setActivateSubscriptionDialogOpen] =
    useState(false);
  const [isSubscriptionRenewed, setIsSubscriptionRenewed] = useState(false);
  const service = useMachineService();
  const machineId = useSelectedMachine().machineId;
  const [subscriptionsData, setSubscriptionsData] = useState<
    Array<MachineSubscription>
  >([]);
  const [selectedSubscription, setSelectedSubscription] =
    useState<SubscriptionType>(SubscriptionType.Unknown);
  const [assignUserDialogOpen, setAssignUserDialogOpen] = useState(false);
  const [selectedModule, setSelectedModule] =
    useState<iMobaModule>("PerformancePro");
  let updateModulesConfirmed = false;
  const [showConfirmUpdateModulesDialog, setShowConfirmUpdateModulesDialog] =
    useState(false);
  const [dialogText, setDialogText] = useState("");
  const [dialogMode, setDialogMode] = useState(DialogMode.Unknown);
  const [userToBeUpdated, setUserToBeUpdated] = useState(emptyUser);
  const userService = useUserService();
  const dispatchAlert = useDispatchAlert();
  const [usersUpToDate, setUsersUpToDate] = useState(false);
  const auth0 = useAuth0();
  const {
    trackSubscriptionActivated,
    trackAutoRenewActivated,
    trackAutoRenewDeactivated,
  } = useManagementMachinePageTracking();

  const handleConfirmUpdateModules = () => {
    setShowConfirmUpdateModulesDialog(false);
    switch (dialogMode) {
      case DialogMode.ConfirmUser:
        updateModulesConfirmed = true;

        handleAssign(userToBeUpdated);
        modulesUpdated(machineId);
        break;
      case DialogMode.ConfirmModule:
        handleSubscriptionActivation(selectedSubscription, true);
        break;
    }
    setDialogMode(DialogMode.Unknown);
  };

  const handleRejectUpdateModules = () => {
    setShowConfirmUpdateModulesDialog(false);
    switch (dialogMode) {
      case DialogMode.ConfirmUser:
        updateModulesConfirmed = false;
        break;
      case DialogMode.ConfirmModule:
        setSelectedSubscription(SubscriptionType.Unknown);
        break;
    }
    setDialogMode(DialogMode.Unknown);
  };

  const handleAssign = (user: iMobaUser, text?: string) => {
    setUserToBeUpdated(user);
    if (text) setDialogText(text);
    if (!updateModulesConfirmed) {
      setDialogMode(DialogMode.ConfirmUser);
      setShowConfirmUpdateModulesDialog(true);
      return;
    }

    userService
      .updateUser(user, machineId, auth0.user.email)
      .then(() => {
        setUsersUpToDate(false);
        dispatchAlert({
          message: t("users.messages.userSavedReload"),
          messageType: "success",
        });
        if (user.email === auth0.user.email) {
          window.location.reload(); // hard refresh so the changes are visible immediately
        }
      })
      .catch(() => {
        dispatchAlert({
          message: t("users.messages.userSaveFailed"),
          messageType: "error",
        });
      })
      .finally(() => {
        setUserToBeUpdated(emptyUser);
      });
  };

  const handleAssingUserClicked = (module: iMobaModule) => {
    setSelectedModule(module);
    setAssignUserDialogOpen(true);
  };

  const handleAssingUserClose = () => {
    setAssignUserDialogOpen(false);
  };

  const handleCloseRenewSubscriptionDialog = () => {
    setRenewSubscriptionDialogOpen(false);
  };

  const handleCloseActivateSubscriptionDialog = () => {
    setActivateSubscriptionDialogOpen(false);
    window.location.reload(); // hard refresh so the changes are visible immediately
  };

  const handleSubscriptionRenewalSwitch = (
    subscriptionType: SubscriptionType,
    newSubscriptionRenewalValue: boolean
  ) => {
    let copy = [...subscriptionsData];
    let found = copy.find(
      (x) => x.subscriptionType.toString() === subscriptionType.toString()
    );

    if (found) {
      found.autoRenew = newSubscriptionRenewalValue;

      newSubscriptionRenewalValue
        ? trackAutoRenewActivated()
        : trackAutoRenewDeactivated();

      setSubscriptionsData(copy);
      service.updateMachineSubscriptions(
        machineId,
        subscriptionType,
        newSubscriptionRenewalValue,
        found.isActive,
        auth0.user.email
      );

      setIsSubscriptionRenewed(newSubscriptionRenewalValue);
      subscriptionRenewalUpdated(machineId);
      setRenewSubscriptionDialogOpen(true);
    }
  };

  const handleSubscriptionActivatedConfirmationDialog = (
    subscriptionType: SubscriptionType
  ) => {
    let copy = [...subscriptionsData];
    let found = copy.find(
      (x) => x.subscriptionType.toString() === subscriptionType.toString()
    );

    if (found) {
      setSelectedSubscription(subscriptionType);
      setDialogMode(DialogMode.ConfirmModule);
      setDialogText(
        t("machineManagementPage.SubscriptionConfirmationDialogText")
      );
      setShowConfirmUpdateModulesDialog(true);
    }
  };

  const handleSubscriptionActivation = (
    subscriptionType: SubscriptionType,
    newValue: boolean
  ) => {
    let copy = [...subscriptionsData];
    let found = copy.find(
      (x) => x.subscriptionType.toString() === subscriptionType.toString()
    );

    if (found) {
      found.isActive = newValue;

      trackSubscriptionActivated();

      setSubscriptionsData(copy);
      service.updateMachineSubscriptions(
        machineId,
        subscriptionType,
        found.autoRenew,
        newValue,
        auth0.user.email
      );

      setIsSubscriptionRenewed(newValue);
      subscriptionRenewalUpdated(machineId);
      setActivateSubscriptionDialogOpen(true);
    }
  };

  const {
    performanceProActive,
    performanceProUntilUtc,
    performanceProAutoRenew,
    performanceProPricePerDay,
  } = useMemo(() => {
    const subscription = subscriptionsData.find(
      (x) =>
        x.subscriptionType.toString() ===
        SubscriptionType.PerformancePro.toString()
    );
    return {
      performanceProActive: subscription?.isActive,
      performanceProUntilUtc: subscription?.untilUtc,
      performanceProAutoRenew: subscription?.autoRenew,
      performanceProPricePerDay: subscription?.pricePerDay,
    };
  }, [subscriptionsData]);

  const {
    realtimeDashboardActive,
    realtimeDashboardUntilUtc,
    realtimeDashboardAutoRenew,
    realtimeDashboardPricePerDay,
  } = useMemo(() => {
    const subscription = subscriptionsData.find(
      (x) =>
        x.subscriptionType.toString() ===
        SubscriptionType.RealtimeDashboard.toString()
    );
    return {
      realtimeDashboardActive: subscription?.isActive,
      realtimeDashboardUntilUtc: subscription?.untilUtc,
      realtimeDashboardAutoRenew: subscription?.autoRenew,
      realtimeDashboardPricePerDay: subscription?.pricePerDay,
    };
  }, [subscriptionsData]);

  const {
    benchmarkActive,
    benchmarkUntilUtc,
    benchmarkAutoRenew,
    benchmarkPricePerDay,
  } = useMemo(() => {
    const subscription = subscriptionsData.find(
      (x) =>
        x.subscriptionType.toString() === SubscriptionType.Benchmark.toString()
    );

    return {
      benchmarkActive: subscription?.isActive,
      benchmarkUntilUtc: subscription?.untilUtc,
      benchmarkAutoRenew: subscription?.autoRenew,
      benchmarkPricePerDay: subscription?.pricePerDay,
    };
  }, [subscriptionsData]);

  useEffect(() => {
    service
      .getMachineSubscriptions(machineId)
      .then((response: IApiResponse<MachineSubscriptionApiData>) => {
        setTimeout(() => {
          setSubscriptionsData(response.data.machineSubscriptions);
        }, 1000);
      });
  }, [
    service,
    machineId,
    performanceProActive,
    performanceProAutoRenew,
    realtimeDashboardActive,
    realtimeDashboardAutoRenew,
    benchmarkActive,
    benchmarkAutoRenew,
  ]);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <PerformanceLiteSubscriptionCard
            onSubscriptionRenewalSwitch={handleSubscriptionRenewalSwitch}
            isSubscriptionAutomaticallyRenewed={true}
            onSubscriptionActivation={
              handleSubscriptionActivatedConfirmationDialog
            }
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PerformanceProSubscriptionCard
            handleAssignUserClicked={handleAssingUserClicked}
            onSubscriptionRenewalSwitch={handleSubscriptionRenewalSwitch}
            onSubscriptionActivation={
              handleSubscriptionActivatedConfirmationDialog
            }
            isSubscriptionAutomaticallyRenewed={
              performanceProAutoRenew ?? false
            }
            isActiveSubscription={performanceProActive ?? false}
            validUntilDate={performanceProUntilUtc}
            pricePerDay={performanceProPricePerDay}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RealtimeDashboardSubscriptionCard
            handleAssignUserClicked={handleAssingUserClicked}
            onSubscriptionRenewalSwitch={handleSubscriptionRenewalSwitch}
            onSubscriptionActivation={
              handleSubscriptionActivatedConfirmationDialog
            }
            isSubscriptionAutomaticallyRenewed={
              realtimeDashboardAutoRenew ?? false
            }
            isActiveSubscription={realtimeDashboardActive ?? false}
            validUntilDate={realtimeDashboardUntilUtc}
            pricePerDay={realtimeDashboardPricePerDay}
            isLoading={isLoading}
          />
        </Grid>
        <PolicyFeatureWrapper policy={FeaturePolicyItems.ShowBenchmarkModule}>
          <Grid item xs={12} md={6}>
            <BenchmarkSubscriptionCard
              handleAssignUserClicked={handleAssingUserClicked}
              onSubscriptionRenewalSwitch={handleSubscriptionRenewalSwitch}
              onSubscriptionActivation={
                handleSubscriptionActivatedConfirmationDialog
              }
              isSubscriptionAutomaticallyRenewed={benchmarkAutoRenew ?? false}
              isActiveSubscription={benchmarkActive ?? false}
              validUntilDate={benchmarkUntilUtc}
              pricePerDay={benchmarkPricePerDay}
              isLoading={isLoading}
            />
          </Grid>
        </PolicyFeatureWrapper>
      </Grid>
      <RenewSubscriptionDialog
        open={renewSubscriptionDialogOpen}
        isSubscriptionRenewed={isSubscriptionRenewed}
        onClose={handleCloseRenewSubscriptionDialog}
      />
      <ActivateSubscriptionDialog
        open={activateSubscriptionDialogOpen}
        onClose={handleCloseActivateSubscriptionDialog}
      />
      <AssingUserToModuleContainer
        selectedModule={selectedModule}
        isOpen={assignUserDialogOpen}
        onClose={handleAssingUserClose}
        handleAssign={handleAssign}
        setUsersUpToDate={setUsersUpToDate}
        usersUpToDate={usersUpToDate}
        isLoading={isLoading}
      />
      <ConfirmActionDialog
        message={dialogText}
        isOpen={showConfirmUpdateModulesDialog}
        onConfirm={handleConfirmUpdateModules}
        onReject={handleRejectUpdateModules}
      />
    </>
  );
}

function PerformanceLiteSubscriptionCard({
  onSubscriptionRenewalSwitch,
  onSubscriptionActivation,
  isSubscriptionAutomaticallyRenewed,
  isLoading,
}: {
  onSubscriptionRenewalSwitch: (
    subscriptionType: SubscriptionType,
    newSubscriptionRenewalValue: boolean
  ) => void;
  onSubscriptionActivation: (subscriptionType: SubscriptionType) => void;
  isSubscriptionAutomaticallyRenewed: boolean;
  isLoading: boolean;
}) {
  const handleSubscriptionRenewalSwitch = (
    newSubscriptionRenewalValue: boolean
  ) => {
    onSubscriptionRenewalSwitch(
      // Performance Lite is always enabled.
      SubscriptionType.Unknown,
      newSubscriptionRenewalValue
    );
  };

  const handleSubscriptionActivation = () => {
    // Performance Lite is always enabled.
    onSubscriptionActivation(SubscriptionType.Unknown);
  };

  return (
    <SubscriptionCard
      title={t("machineManagementPage.performanceLiteSubscriptionCard.title")}
      subtitle={t(
        "machineManagementPage.performanceLiteSubscriptionCard.subtitle"
      )}
      pricePerDay={0}
      isActiveSubscription={true}
      isSubscriptionAutomaticallyRenewed={isSubscriptionAutomaticallyRenewed}
      onSubscriptionRenewalSwitch={handleSubscriptionRenewalSwitch}
      onSubscriptionActivation={handleSubscriptionActivation}
      isLoading={isLoading}
    />
  );
}

function PerformanceProSubscriptionCard({
  onSubscriptionRenewalSwitch,
  onSubscriptionActivation,
  isSubscriptionAutomaticallyRenewed,
  isActiveSubscription,
  validUntilDate,
  pricePerDay,
  handleAssignUserClicked,
  isLoading,
}: {
  onSubscriptionRenewalSwitch: (
    subscriptionType: SubscriptionType,
    newSubscriptionRenewalValue: boolean
  ) => void;
  isSubscriptionAutomaticallyRenewed: boolean;
  isActiveSubscription: boolean;
  pricePerDay?: number;
  validUntilDate?: Date;
  onSubscriptionActivation: (subscriptionType: SubscriptionType) => void;
  handleAssignUserClicked: (module: iMobaModule) => void;
  isLoading: boolean;
}) {
  const handleSubscriptionRenewalSwitch = (
    newSubscriptionRenewalValue: boolean
  ) => {
    onSubscriptionRenewalSwitch(
      SubscriptionType.PerformancePro,
      newSubscriptionRenewalValue
    );
  };

  const handleSubscriptionActivation = () => {
    onSubscriptionActivation(SubscriptionType.PerformancePro);
  };

  return (
    <SubscriptionCard
      title={t("machineManagementPage.performanceProSubscriptionCard.title")}
      subtitle={t(
        "machineManagementPage.performanceProSubscriptionCard.subtitle"
      )}
      pricePerDay={pricePerDay}
      onAssingUserClicked={handleAssignUserClicked}
      module={"PerformancePro"}
      isActiveSubscription={isActiveSubscription}
      validUntilDate={validUntilDate}
      isSubscriptionAutomaticallyRenewed={isSubscriptionAutomaticallyRenewed}
      onSubscriptionRenewalSwitch={handleSubscriptionRenewalSwitch}
      onSubscriptionActivation={handleSubscriptionActivation}
      isLoading={isLoading}
    />
  );
}

function RealtimeDashboardSubscriptionCard({
  onSubscriptionRenewalSwitch,
  onSubscriptionActivation,
  isSubscriptionAutomaticallyRenewed,
  isActiveSubscription,
  validUntilDate,
  pricePerDay,
  handleAssignUserClicked,
  isLoading,
}: {
  onSubscriptionRenewalSwitch: (
    subscriptionType: SubscriptionType,
    newSubscriptionRenewalValue: boolean
  ) => void;
  isSubscriptionAutomaticallyRenewed: boolean;
  isActiveSubscription: boolean;
  validUntilDate?: Date;
  pricePerDay?: number;
  onSubscriptionActivation: (subscriptionType: SubscriptionType) => void;
  handleAssignUserClicked: (module: iMobaModule) => void;
  isLoading: boolean;
}) {
  const handleSubscriptionRenewalSwitch = (
    newSubscriptionRenewalValue: boolean
  ) => {
    onSubscriptionRenewalSwitch(
      SubscriptionType.RealtimeDashboard,
      newSubscriptionRenewalValue
    );
  };

  const handleSubscriptionActivation = () => {
    onSubscriptionActivation(SubscriptionType.RealtimeDashboard);
  };

  return (
    <SubscriptionCard
      title={t("machineManagementPage.realtimeDashboardSubscriptionCard.title")}
      subtitle={t(
        "machineManagementPage.realtimeDashboardSubscriptionCard.subtitle"
      )}
      pricePerDay={pricePerDay}
      onAssingUserClicked={handleAssignUserClicked}
      isActiveSubscription={isActiveSubscription}
      module={"RealtimeDashboard"}
      validUntilDate={validUntilDate}
      isSubscriptionAutomaticallyRenewed={isSubscriptionAutomaticallyRenewed}
      onSubscriptionRenewalSwitch={handleSubscriptionRenewalSwitch}
      onSubscriptionActivation={handleSubscriptionActivation}
      isLoading={isLoading}
    />
  );
}

function BenchmarkSubscriptionCard({
  onSubscriptionRenewalSwitch,
  onSubscriptionActivation,
  isSubscriptionAutomaticallyRenewed,
  isActiveSubscription,
  validUntilDate,
  pricePerDay,
  handleAssignUserClicked,
  isLoading,
}: {
  onSubscriptionRenewalSwitch: (
    subscriptionType: SubscriptionType,
    newSubscriptionRenewalValue: boolean
  ) => void;
  onSubscriptionActivation: (subscriptionType: SubscriptionType) => void;
  isSubscriptionAutomaticallyRenewed: boolean;
  isActiveSubscription: boolean;
  pricePerDay?: number;
  validUntilDate?: Date;
  handleAssignUserClicked: (module: iMobaModule) => void;
  isLoading: boolean;
}) {
  const handleSubscriptionRenewalSwitch = (
    newSubscriptionRenewalValue: boolean
  ) => {
    onSubscriptionRenewalSwitch(
      SubscriptionType.Benchmark,
      newSubscriptionRenewalValue
    );
  };

  const handleSubscriptionActivation = () => {
    onSubscriptionActivation(SubscriptionType.Benchmark);
  };

  return (
    <SubscriptionCard
      title={t("machineManagementPage.benchmarkSubscriptionCard.title")}
      subtitle={t("machineManagementPage.benchmarkSubscriptionCard.subtitle")}
      pricePerDay={pricePerDay}
      module={"Benchmark"}
      onAssingUserClicked={handleAssignUserClicked}
      isActiveSubscription={isActiveSubscription}
      validUntilDate={validUntilDate}
      isSubscriptionAutomaticallyRenewed={isSubscriptionAutomaticallyRenewed}
      onSubscriptionRenewalSwitch={handleSubscriptionRenewalSwitch}
      onSubscriptionActivation={handleSubscriptionActivation}
      isLoading={isLoading}
    />
  );
}

function RenewSubscriptionDialog({
  open,
  isSubscriptionRenewed,
  onClose,
}: {
  open: boolean;
  isSubscriptionRenewed: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">
        {isSubscriptionRenewed
          ? t(
              "machineManagementPage.genericSubscriptionCard.dialogTitleSubscriptionTurnedOn"
            )
          : t(
              "machineManagementPage.genericSubscriptionCard.dialogTitleSubscriptionTurnedOff"
            )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          {isSubscriptionRenewed
            ? t(
                "machineManagementPage.genericSubscriptionCard.dialogTitleDescriptionTurnedOn"
              )
            : t(
                "machineManagementPage.genericSubscriptionCard.dialogTitleDescriptionTurnedOff"
              )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {t("machineManagementPage.genericSubscriptionCard.dialogButtonLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ActivateSubscriptionDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">
        {t(
          "machineManagementPage.genericSubscriptionCard.dialogTitleSubscriptionActivated"
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          {t(
            "machineManagementPage.genericSubscriptionCard.dialogTitleDescriptionActivated"
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {t("machineManagementPage.genericSubscriptionCard.dialogButtonLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
