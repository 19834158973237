import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { t } from "../../utility/TranslateUtility";
import { PaperComponent } from "./AddOrUpdateUser/Common";

export interface ConfirmActionDialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
  message: string;
}

export function ConfirmActionDialog({
  isOpen,
  onConfirm,
  onReject,
  message,
}: ConfirmActionDialogProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={onReject}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {t("users.messages.warning")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onReject} color="primary">
          {t("users.buttons.no")}
        </Button>
        <Button autoFocus onClick={onConfirm} color="primary">
          {t("users.buttons.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
