import { Paper } from "@material-ui/core";
import Draggable from "react-draggable";

export function PaperComponent(props: any) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export type Role = "UserCustomerApp" | "AdminCustomerApp" | "OwnerCustomerApp";

export type Status = "Pending" | "Active" | "Unknown";

export interface iMobaUser {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  modules: iMobaModule[];
  machines?: string[];
  roles?: Role[];
  status?: Status;
  name?: string;
  systemRoles: string[];
}

export type iMobaModule = "RealtimeDashboard" | "PerformancePro" | "Benchmark";

export const AlliMobaModules: iMobaModule[] = [
  "RealtimeDashboard",
  "PerformancePro",
  "Benchmark",
];

export const emptyUser: iMobaUser = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  modules: [],
  systemRoles: [],
};
