import { colors } from "../../../colors";
import { IPerformanceProFilterOptions } from "../../../store/GlobalContext";
import { GetTooltipLabel } from "../../../utility/EggUnitUtility";
import { t } from "../../../utility/TranslateUtility";
import { format, TooltipBuilder } from "../../ProductionViews/Tooltip";
import { StackedBarChartMapping } from "./GroupsChartMapping";

export function DetermineTooltipFormatter(
  options: IPerformanceProFilterOptions,
  chartMapping: StackedBarChartMapping,
  isDateSeries: boolean,
  usePercentage: boolean,
  unitLabel: string
) {
  const tooltipBuilder = new TooltipBuilder();

  if (isDateSeries) {
    tooltipBuilder.addXProductionSubCharts(options.selectedDatePeriod);
  }

  if (!isDateSeries) {
    tooltipBuilder.add((data: any) => {
      return format({}, data.x);
    });
  }

  tooltipBuilder.add((data: any) => {
    let parts = "";
    if (data.points.length <= 0) return parts;
    for (let point of data.points) {
      parts += format(
        {
          label: `<span style="color:${point.color}">\u25CF</span> ${point.series.name}`,
          separator: true,
        },
        GetTooltipLabel(usePercentage, point.y, unitLabel)
      );
    }

    if (chartMapping.showTotal) {
      let value = data.points
        .map((x: any) => x.y)
        .reduce((a: number, b: number) => a + b);
      parts += format(
        {
          label: `<span style="color:${colors.priorityRed}">\u25CF</span> ${t(
            "uniqueViews.offgradesPerType.total"
          )}`,
          separator: true,
        },
        GetTooltipLabel(usePercentage, value, unitLabel)
      );
    }

    return parts;
  });

  return tooltipBuilder.buildSharedFormatter();
}
