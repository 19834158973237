import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useManagementSettingsService,
  usePerformanceProProductionService
} from "../../api/ServiceContext";
import { usePerformanceProFilterOptions } from "../../store/GlobalContext";
import { getFirstOfPeriod } from "../../utility/DateUtility";
import { EggUnit } from "../../utility/EggUnitUtility";
import { EggType } from "../../utility/EggUtility";
import { GetCancellationToken } from "../../utility/HttpServiceUtility";
import { TargetsView } from "../Settings/Targets/Types";
import { useDispatchAlert } from "../UI/Alert";
import { ConvertDatePeriod } from "./performanceProHooks";

export interface LaneProductionView {
  packingLanes: LaneProductionPackingLane[];
}

export type LaneProductionPackingLane = {
  packingLaneNumber: number;
  laneTotal: number;
  cageEggs: number;
  barnEggs: number;
  freeRangeEggs: number;
  organicEggs: number;
  unmappedEggs: number;
  offgradeEggs: number;
  bucketEggs: number;
};

export function useLaneProductionHook({ machineId }: { machineId: MachineId }) {
  const cancellationToken = GetCancellationToken();
  const productionService =
    usePerformanceProProductionService(cancellationToken);
  const settingsService = useManagementSettingsService(cancellationToken);
  const options = usePerformanceProFilterOptions();
  const dispatchAlert = useDispatchAlert();
  const { t } = useTranslation();

  const [data, setData] = useState<LaneProductionView | null>(null);
  const [targets, setTargets] = useState<TargetsView>({
    targets: {
      productionTime: 0,
      totalEggs: 0,
      totalEggsEggUnit: EggUnit.Eggs,
      availability: 0,
    },
    targetsPerEggtypes: [
      {
        eggType: EggType.AllEggTypes,
        setSpeed: 0,
        setSpeedEggUnit: EggUnit.Eggs,
        tableEggs: 0,
        inputOffgrades: 0,
        throughput: 0,
        throughputEggUnit: EggUnit.Eggs,
      },
    ],
    units: {
      eggUnit: EggUnit.Eggs,
      timezone: "",
    },
  });
  const [awaitingResponse, setAwaitingResponse] = useState(true);

  useEffect(() => {
    const periodType = ConvertDatePeriod(options.selectedDatePeriod);

    setAwaitingResponse(true);
    Promise.all([
      productionService.getLaneProduction(
        machineId,
        getFirstOfPeriod(options.selectedDate, options.selectedDatePeriod),
        periodType
      ),
      settingsService.getMachineTargetsSettings(
        machineId,
        getFirstOfPeriod(options.selectedDate, options.selectedDatePeriod)
      ),
    ])
      .then((response) => {
        setData(response[0].data);
        setTargets(response[1].data);
        setAwaitingResponse(false);
      })
      .catch((reason) => {
        if (!reason.isCancelled) {
          dispatchAlert({
            message: t("generic.errorMessage"),
            messageType: "error",
          });
        }
      });
    return () => {
      cancellationToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineId, options.selectedDate, options.selectedDatePeriod]);

  return {
    data: data,
    options: options,
    targets: targets,
    awaitingResponse: awaitingResponse,
  };
}
